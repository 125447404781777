import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./style.css";

class CertificationsTab extends Component {
    static propTypes = {
        onOkText: PropTypes.object.isRequired,
        onOkAction: PropTypes.func,
        onOkDisable: PropTypes.bool,
        onCancelText: PropTypes.object.isRequired,
        onCancelAction: PropTypes.func,
        onCancelDisable: PropTypes.bool,
        onMoreText: PropTypes.object.isRequired,
        onMoreAction: PropTypes.func,
        onMoreDisable: PropTypes.bool,
        tabContent: PropTypes.object
    };

    constructor() {
        super();
        this.state = this.initialState;
    }

    get initialState() {
        return {
            showOnLoading: false
        };
    }

    handleOk() {
        const { onOkAction } = this.props;
        this.setState({ showOnLoading: true });
        onOkAction && onOkAction();
    }

    handleCancel() {
        const { onCancelAction } = this.props;
        onCancelAction && onCancelAction();
    }

    handleMore() {
        const { onMoreAction } = this.props;
        onMoreAction && onMoreAction();
    }

    render() {
        const {
            onCancelDisable,
            onCancelText,
            onOkDisable,
            onOkText,
            onMoreText,
            onMoreDisable,
            tabContent
        } = this.props;
        const { showOnLoading } = this.state;

        return (
            <div className="c-upload-data-tab">
                {tabContent}
                <div className="button-box">
                    <Button
                        className="onCancelB"
                        color="white"
                        size="small"
                        onClick={() => this.handleMore()}
                        disabled={onMoreDisable ? onMoreDisable : false}
                    >
                        {onMoreText}
                    </Button>
                    <Button
                        className="onCancelB"
                        color="white"
                        size="small"
                        onClick={() => this.handleCancel()}
                        disabled={onCancelDisable ? onCancelDisable : false}
                    >
                        {onCancelText}
                    </Button>

                    <Button
                        type="primary"
                        className="onOkB"
                        size="small"
                        loading={showOnLoading}
                        onClick={() => this.handleOk()}
                        disabled={onOkDisable ? onOkDisable : false}
                    >
                        {onOkText}
                    </Button>
                </div>
            </div>
        );
    }
}

export default CertificationsTab;
