import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Table, Icon, Spin, Tooltip, Button } from "antd";
import { MeteringExpandedTableBody, MeteringExpandedTableSpinner } from "./styled";

export const MeteringExpandedTable = ({
    loaded,
    meteringConstraintsData,
    onAssignCredit,
    idPackage,
    credits,
    expireDate
}) => {
    const columns = [
        {
            title: <FormattedMessage id="c-meteringexpandedtable.id" />,
            dataIndex: "id",
            key: "id"
        },
        {
            title: <FormattedMessage id="c-meteringexpandedtable.maximumquantity" />,
            dataIndex: "limit",
            key: "limit"
        },
        {
            title: <FormattedMessage id="c-meteringexpandedtable.type" />,
            dataIndex: "type",
            key: "type"
        },
        {
            title: <FormattedMessage id="c-meteringtable.actions" />,
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    <Tooltip title={<FormattedMessage id="metering_credits_action" />}>
                        <Button
                            shape="circle"
                            size="small"
                            disabled={!credits || new Date(expireDate) < new Date()}
                            onClick={() => onAssignCredit(idPackage, text.id)}
                        >
                            <i className="fas fa-share-square" />
                        </Button>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <MeteringExpandedTableBody>
            <span className="a1">
                <FormattedMessage id="c-meteringexpandedtable.limites" />
            </span>
            <br />
            {loaded ? (
                <Table
                    rowKey={(record, index) => index}
                    dataSource={meteringConstraintsData != null ? meteringConstraintsData : []}
                    columns={columns}
                    size="small"
                    pagination={false}
                />
            ) : (
                <MeteringExpandedTableSpinner>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                </MeteringExpandedTableSpinner>
            )}

            <br />
        </MeteringExpandedTableBody>
    );
};

MeteringExpandedTable.propTypes = {
    idPackage: PropTypes.number.isRequired,
    meteringConstraintsData: PropTypes.array,
    loaded: PropTypes.bool.isRequired,
    onAssignCredit: PropTypes.func,
    credits: PropTypes.bool.isRequired,
    expireDate: PropTypes.string.isRequired
};
