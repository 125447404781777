import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ActionIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    cursor: pointer;
    color: #0090d1;
    margin: 0px 2px;
`;

export const ToolTipIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    color: #0090d1;
    margin: 0px 2px;
`;

export const ViewTitle = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SearchContainer = styled.div`
    width: 50%;
    margin-left: 20px;
    margin-bottom: 20px;
`;
