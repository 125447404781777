import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Select, Switch, Typography } from "antd";
import { InvoiceFooter } from "../../../components/general/invoice-footer";
import { CompanyEligibleInvoiceExpandedInfo } from "./expanded-info";

export const CompanyEligibleInvoice = ({
    loading,
    invoices,
    handleNextPage,
    currentPage,
    hasNextPage,
    handlePreviousPage,
    handlePageSizeChanged,
    defaultPageSize,
    expanded,
    handleExpandedChange,
    pageSize,
    companyId
}) => {
    const [query, setQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        listFilter.push("supplierAgyoId=" + companyId);
        listFilter.push("after=" + currentPage);
        listFilter.push("first=" + pageSize);
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQuery(query);
    }, [currentPage, pageSize, companyId]);

    let columns = [
        {
            title: <FormattedMessage id={"end_customer"} />,
            dataIndex: "customer",
            render: text => <span>{`${text.name} ${text.taxId}`}</span>
        },
        {
            title: <FormattedMessage id={"op_type"} />,
            dataIndex: "programTypology"
        },
        {
            title: <FormattedMessage id={"invoice_number"} />,
            dataIndex: "documentNumber"
        },
        {
            title: <FormattedMessage id={"invoice_date"} />,
            dataIndex: "documentDate"
        },
        {
            title: <FormattedMessage id={"expiry_date"} />,
            dataIndex: "dueDate"
        },
        {
            title: <FormattedMessage id={"amount"} />,
            dataIndex: "amount",
            render: text => <div>{`${text ? text + " €" : "N.D."}`}</div>
        },
        {
            title: <FormattedMessage id={"down_payment_amount"} />,
            dataIndex: "downPaymentAmount",
            render: text => <div>{`${text ? text + " €" : "N.D."}`}</div>
        }
    ];

    let pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    return (
        <div style={{ marginTop: 20 }}>
            <div style={{ textAlign: "right", padding: 8 }}>
                <Switch
                    className="notification-switch"
                    checked={expanded}
                    checkedChildren={<FormattedMessage id={"reduce"} />}
                    unCheckedChildren={<FormattedMessage id={"expand"} />}
                    onChange={handleExpandedChange}
                />
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={invoices}
                    loading={loading}
                    expandedRowRender={expanded ? record => <CompanyEligibleInvoiceExpandedInfo data={record} /> : null}
                    pagination={false}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize}
                disabled={false}
                handleInvoicePageSizeChanged={handlePageSizeChanged}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage || loading}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === "1" || loading}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

CompanyEligibleInvoice.propTypes = {
    loading: PropTypes.bool.isRequired,
    invoices: PropTypes.array.isRequired,
    currentPage: PropTypes.string.isRequired,
    pageSize: PropTypes.string.isRequired,
    handlePageSizeChanged: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired
};
