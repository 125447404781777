import { API_URL, NOTIFICATION_READ_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";

export function getNotifications(auth, taxId, pagination) {
    return async dispatch => {
        const urlParams = {
            page: pagination.page,
            size: pagination.items ? pagination.items : 10,
            recipientId: taxId
        };
        dispatch({
            type: GET_NOTIFICATIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${NOTIFICATION_READ_URL}/notifications`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_NOTIFICATIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_NOTIFICATIONS_ERROR,
                error: e
            });
        }
    };
}

export const GET_COMPANY_USERS_NOTIFICATIONS_START = "GET_COMPANY_USERS_NOTIFICATIONS_START";
export const GET_COMPANY_USERS_NOTIFICATIONS_SUCCESS = "GET_COMPANY_USERS_NOTIFICATIONS_SUCCESS";
export const GET_COMPANY_USERS_NOTIFICATIONS_ERROR = "GET_COMPANY_USERS_NOTIFICATIONS_ERROR";

export function getCompanyNcsSettings(auth, companyNcsId, pagination) {
    return async dispatch => {
        const urlParams = {
            page: pagination && pagination.page,
            size: pagination && pagination.size ? pagination.size : 10
        };

        dispatch({
            type: GET_COMPANY_USERS_NOTIFICATIONS_START,
            request: companyNcsId
        });

        try {
            const result = await getRestCall(
                `${API_URL}/notification/users/${companyNcsId}`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_COMPANY_USERS_NOTIFICATIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_COMPANY_USERS_NOTIFICATIONS_ERROR,
                error: e
            });
        }
    };
}
