import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setNewVersionAvailable } from "../../../actions/session";

class AutoReload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codeHasChanged: false,
            htmlHash: null
        };
    }
    static propTypes = {
        setNewVersionAvailable: PropTypes.func.isRequired
    };

    componentDidMount() {
        setInterval(() => this.fetchSource(), 3600000);
    }

    fetchSource() {
        const { setNewVersionAvailable } = this.props;
        const { htmlHash } = this.state;

        return fetch(this.props.url)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("offline");
                }
                return response.text();
            })
            .then(html => {
                const hash = this.hash(html);
                if (htmlHash === null) {
                    this.setState({ htmlHash: hash });
                    setNewVersionAvailable(false);
                }
                if (htmlHash !== null && htmlHash !== hash) {
                    this.setState({ htmlHash: hash });
                    setNewVersionAvailable(true);
                }
            })
            .catch(() => {});
    }

    hash(str) {
        const len = str.length;
        let hash = 0;
        if (len === 0) return hash;
        let i;
        for (i = 0; i < len; i++) {
            hash = (hash << 5) - hash + str.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    render() {
        return <div />;
    }
}

AutoReload.propTypes = {
    url: PropTypes.string.isRequired
};

AutoReload.defaultProps = {
    url: "/static-apps/backoffice/index.html"
};

const mapStateToProps = state => ({});

const actions = { setNewVersionAvailable };

export default connect(
    mapStateToProps,
    actions
)(AutoReload);
