import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Select, Typography, Tag, Tooltip, Dropdown, Menu, Button, Drawer } from "antd";
import { InvoiceFooter } from "../../../components/general/invoice-footer";
import { translateInvoiceStatusShort } from "../../../utils/translate";
import { EReceiptPreview } from "./preview";

export const CompanyEReceipt = ({
    loading,
    invoices,
    handleNextPage,
    currentPage,
    hasNextPage,
    handlePreviousPage,
    handlePageSizeChanged,
    defaultPageSize,
    pageSize,
    companyId,
    continuationToken,
    handleDownloadDocument,
    handlePreviewDocument,
    eReceiptDocument,
    eReceiptDocumentLoading
}) => {
    const [showPreview, setShowPreview] = useState(false);
    const [hubIdSelected, setHubIdSelected] = useState(null);

    const [query, setQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        listFilter.push("ownerId=" + companyId);
        listFilter.push("size=" + pageSize);
        if (continuationToken) {
            listFilter.push("continuationToken=" + continuationToken);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQuery(query);
    }, [currentPage, pageSize, companyId, continuationToken]);

    let columns = [
        {
            title: <FormattedMessage id={"progressive"} />,
            width: "10%",
            dataIndex: "progressiveId"
        },
        {
            title: <FormattedMessage id={"device_type"} />,
            width: "15%",
            dataIndex: "deviceType"
        },
        {
            title: <FormattedMessage id={"device_id"} />,
            width: "15%",
            dataIndex: "deviceId"
        },
        {
            title: <FormattedMessage id={"detection_date_time"} />,
            width: "25%",
            dataIndex: "lastHubStatus.timestamp",
            render: text => <span>{text ? new Date(text).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id={"format"} />,
            width: "20%",
            dataIndex: "format"
        },
        {
            title: <FormattedMessage id={"status"} />,
            dataIndex: "lastHubStatus.name",
            render: (text, record) => (
                <Tooltip title={text === "ERROR" || text === "SCARTATO" ? record.lastHubStatus.description : ""}>
                    <Tag color={getColorForEReceiptStatusTag(text)}>{translateInvoiceStatusShort(text)}</Tag>
                </Tooltip>
            )
        },
        {
            title: <FormattedMessage id="actions" />,
            key: "actions",
            width: "5%",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const renderActions = item => {
        return (
            <Menu>
                <Menu.Item
                    key="0"
                    onClick={() => {
                        setHubIdSelected(item.hubId);
                        handlePreviewDocument(item.hubId);
                        setShowPreview(true);
                    }}
                >
                    <i className="fas fa-eye" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"preview"} />
                </Menu.Item>
                <Menu.Item key="1" onClick={() => handleDownloadDocument(item.hubId)}>
                    <i className="fas fa-download" style={{ marginRight: 8 }} />
                    <FormattedMessage id="download_xml" />
                </Menu.Item>
            </Menu>
        );
    };

    let pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    const getColorForEReceiptStatusTag = value => {
        let color;
        switch (value) {
            case "A_DISPOSIZIONE":
                color = "green";
                break;
            case "SCARTATO":
            case "ERROR":
                color = "red";
                break;
            default:
                color = "gold";
        }
        return color;
    };

    return (
        <div style={{ marginTop: 20 }}>
            <Drawer
                title={<FormattedMessage id={"preview"} />}
                width={"50%"}
                onClose={() => setShowPreview(false)}
                visible={showPreview}
            >
                <EReceiptPreview
                    documentInfo={eReceiptDocument}
                    hubId={hubIdSelected}
                    loading={eReceiptDocumentLoading}
                    handleDownloadDocument={handleDownloadDocument}
                />
            </Drawer>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={invoices}
                    loading={loading}
                    pagination={false}
                    scroll={{ y: 560 }}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize.toString()}
                disabled={loading}
                handleInvoicePageSizeChanged={handlePageSizeChanged}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage || loading}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === 1 || loading}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

CompanyEReceipt.propTypes = {
    loading: PropTypes.bool.isRequired,
    invoices: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    handlePageSizeChanged: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.number.isRequired,
    companyId: PropTypes.string.isRequired,
    continuationToken: PropTypes.string,
    handleDownloadDocument: PropTypes.func.isRequired,
    handlePreviewDocument: PropTypes.func.isRequired,
    eReceiptDocument: PropTypes.string,
    eReceiptDocumentLoading: PropTypes.bool.isRequired
};
