import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { submit, isValid, isSubmitting } from "redux-form";

import TsModal from "../../../components/general/ts-modal";
import ApiKey from "../../../components/user/api-key";

const CreateNewKeyModal = ({ onCancel, onSubmit, formEnabled, submitForm, visible }) => {
    return (
        <div>
            <TsModal
                onCancel={onCancel}
                onOk={() => submitForm("apiKey")}
                onOkDisabled={!formEnabled("apiKey")}
                title={
                    <span>
                        <FormattedMessage id="create_api_key" />
                    </span>
                }
                visible={visible}
                cancelTextId="general.onCancel"
                okTextId="general.save"
                width="40%"
            >
                <div>
                    <p>
                        <FormattedMessage id="insert_data_of_user_you_want_to_create" />
                    </p>
                    <ApiKey onSubmit={onSubmit} />
                </div>
            </TsModal>
        </div>
    );
};

CreateNewKeyModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    formEnabled: formName => isValid(formName)(state) && !isSubmitting(formName)(state)
});

const actions = {
    submitForm: formName => submit(formName)
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    )
);

export default composedHoc(CreateNewKeyModal);
