import {
    GET_INVOICE_START,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_ERROR,
    GET_INVOICES_START,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_ERROR,
    GET_FAW_CONFIG_START,
    GET_FAW_CONFIG_SUCCESS,
    GET_FAW_CONFIG_ERROR,
    GET_ELIGIBLE_INVOICES_START,
    GET_ELIGIBLE_INVOICES_SUCCESS,
    GET_ELIGIBLE_INVOICES_ERROR
} from "../../../actions/invoice/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function invoices(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        /*        case GET_INVOICE_RESET:
            return {
                ...defaultState
            };*/
        default:
            return state;
    }
}

export function getInvoice(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export const getFAWConfig = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_FAW_CONFIG_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_FAW_CONFIG_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_FAW_CONFIG_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getEligibleInvoices = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_ELIGIBLE_INVOICES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ELIGIBLE_INVOICES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_ELIGIBLE_INVOICES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
