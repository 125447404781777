import { USER_CONSENT_READ_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_USER_CONSENTS_START = "GET_USER_CONSENTS_START";
export const GET_USER_CONSENTS_SUCCESS = "GET_USER_CONSENTS_SUCCESS";
export const GET_USER_CONSENTS_ERROR = "GET_USER_CONSENTS_ERROR";

export function getUserConsents(auth, userUuid) {
    return async dispatch => {
        dispatch({
            type: GET_USER_CONSENTS_START
        });

        try {
            const result = await getRestCall(
                `${USER_CONSENT_READ_API_URL}/consents/${userUuid}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_USER_CONSENTS_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_USER_CONSENTS_ERROR,
                error: e
            });
        }
    };
}
