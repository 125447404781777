import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { FormattedMessage } from "react-intl";
import { Tabs } from "antd";

import { errorBoundary } from "../../../components/general/error-boundary";

import { NotificationPreferences } from "../../../components/company/companyncs/notification-preferences";

import { getCompanyNcsSettings } from "../../../actions/company/notifications";

import { Container } from "./styled";

export const CompanyNotifications = ({ auth, company, getCompanyNcsSettings }) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        getCompanyNcsSettings(auth.loginAuth, company.info.base.ncsId, { page: page, size: pageSize });
    }, [getCompanyNcsSettings, auth.loginAuth, company.info.base.ncsId, page, pageSize]);

    return (
        <div>
            <Tabs tabPosition="left">
                <Tabs.TabPane
                    tab={<FormattedMessage id="v-company-users.user-preferences-tab" />}
                    key="group-preferences"
                >
                    <Container>
                        <NotificationPreferences
                            companyNcsId={company.info.base.ncsId}
                            ncsUsersPreferenceList={company.companyUsersNotification.users}
                            loading={company.companyUsersNotification.status.started}
                            handlePageChange={setPage}
                            totalItems={
                                company.companyUsersNotification.pagination &&
                                company.companyUsersNotification.pagination.totalItems
                            }
                            currentPage={page}
                            pageSize={pageSize}
                            handlePageSizeChange={setPageSize}
                        />
                    </Container>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company
});

const actions = {
    getCompanyNcsSettings
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyNotifications);
