import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import CctDataList from "../../../../components/certifications/cct-provisioning/list";
import PageHeader from "../../../../components/general/PageHeader/index";
import { ContractWrapper } from "../../styled";

import { errorBoundary } from "../../../../components/general/error-boundary";
import { getCctProvisioningRequest } from "../../../../actions/cct/read";
import { useQuery } from "../../../../utils/url-utils";

import { iVerde } from "../../../../utils/constant";

const CertificationCctListView = props => {
    const query = useQuery();

    const greenCompanies = query.get("greenCompanies");

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        itemsPerPage: 10,
        filter: {}
    });

    const [pageLoading, setPageLoading] = useState(true);

    const { getCctProvisioningRequest, auth, list, loadingStatus, total, match, history } = props;

    useEffect(() => {
        setPageLoading(loadingStatus.started);
    }, [loadingStatus]);

    useEffect(() => {
        const {
            params: { status, cfProduttore }
        } = match;
        let filters = {};
        if (cfProduttore === null || cfProduttore === undefined) {
            if (status && status.includes("suspended")) {
                filters = {
                    suspended: true,
                    ...pagination.filter
                };
            } else {
                filters = {
                    statusCode: status.toLowerCase().replace("-", "_"),
                    suspended: false,
                    ...pagination.filter
                };
            }
        } else {
            if (status && status.includes("suspended")) {
                filters = {
                    suspended: true,
                    "cf-produttore": cfProduttore,
                    ...pagination.filter
                };
            } else {
                filters = {
                    "cf-produttore": cfProduttore,
                    suspended: false,
                    ...pagination.filter
                };
            }
        }

        if (greenCompanies === "true") {
            if (!pagination.filter["cf-produttore"]) {
                if (!pagination.filter["cf-titolari"]) {
                    filters["cf-produttori"] = iVerde;
                }
            } else {
                filters["cf-produttore"] = iVerde.includes(pagination.filter["cf-produttore"])
                    ? pagination.filter["cf-produttore"]
                    : "0";
            }
            if (status && status.includes("suspended")) {
                filters["suspended"] = true;
            } else {
                filters["suspended"] = false;
                filters["statusCode"] = status.toLowerCase().replace("-", "_");
                filters["suspended"] = false;
            }
        }

        var cleanedFilters = Object.entries(filters).reduce(
            (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
            {}
        );

        getCctProvisioningRequest(auth.loginAuth, cleanedFilters, pagination.pageNumber - 1, pagination.itemsPerPage);
    }, [pagination, auth, getCctProvisioningRequest, match, greenCompanies]); //TODO CAPIRE COME FARE

    return (
        <ContractWrapper>
            <PageHeader history={history} labelId={`c-certifications.cct-contract-${match.params.status}`} />
            <CctDataList
                iVerdeTab={greenCompanies === "true"}
                status={match.params.status}
                key="list"
                handlePage={page => setPagination({ ...pagination, pageNumber: page })}
                handleShowSize={itemPerPage =>
                    setPagination({
                        ...pagination,
                        pageNumber: 1,
                        itemsPerPage: itemPerPage
                    })
                }
                handleFilter={(value, name) => {
                    let newFilter = pagination.filter;
                    if (name === "cf-produttori") {
                        if (!value) {
                            newFilter[name] = iVerde;
                        } else {
                            newFilter[name] = [value];
                        }
                    } else {
                        newFilter[name] = value;
                    }
                    setPagination({ ...pagination, pageNumber: 1, filter: newFilter });
                }}
                page={pagination.pageNumber}
                loading={pageLoading}
                list={list}
                total={total}
                itemPerPage={pagination.itemsPerPage}
                cfMain={match.params.cfProduttore}
            />
        </ContractWrapper>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    list: state.cct.read.cctProvRequest.provisioning,
    total: state.cct.read.cctProvRequest.totalItems,
    page: state.cct.read.cctProvRequest.page,
    loadingStatus: state.cct.read.cctProvRequest.status
});

const actions = {
    getCctProvisioningRequest
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

CertificationCctListView.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    list: PropTypes.array,
    loadingStatus: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    total: PropTypes.number
};

export default composedHoc(CertificationCctListView);
