import {
    FETCH_ACTIVE_AGYO_SERVICE_START,
    FETCH_ACTIVE_AGYO_SERVICE_ERROR,
    FETCH_ACTIVE_AGYO_SERVICE_SUCCESS
} from "../../actions/services/list";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function list(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FETCH_ACTIVE_AGYO_SERVICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FETCH_ACTIVE_AGYO_SERVICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                services: payload
            };
        case FETCH_ACTIVE_AGYO_SERVICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
