import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tabs, Drawer } from "antd";
import { InvoicePreview } from "./preview";
import { InvoiceInfoDetails } from "./details";
import { InvoiceInfoIdentity } from "./identity";
import { InvoiceInfoChronology } from "./chronology";
import { onParsingXML } from "../../utils/documents/parsing-xml";

export const InvoiceInfo = ({
    admin,
    auth,
    document,
    deleteInvoiceUsage,
    displayErrorToast,
    displaySuccessToast,
    reassignInvoiceUsage,
    invoiceUsagePackage,
    reassignInvoiceUsagePackage,
    replayInvoiceNotificationUsage
}) => {
    const [lstAttachments, setLstAttachments] = useState(null);
    const [messagePreview, setMessagePreview] = useState(false);
    const [messageSelected, setMessageSelected] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);

    useEffect(() => {
        if (document.content.payload) {
            setLstAttachments(onParsingXML(document.content.payload));
        }
    }, [document.content.payload]);

    const removeUsageInvoice = () => {
        deleteInvoiceUsage(auth.loginAuth, document.infoD.hubId);
    };

    const reassignUsageInvoice = ownerId => {
        reassignInvoiceUsage(auth.loginAuth, document.infoD.hubId, ownerId);
    };

    const getUsageInvoicePackage = owner => {
        invoiceUsagePackage(auth.loginAuth, owner);
    };

    const reassignUsageInvoicePackage = newPackage => {
        reassignInvoiceUsagePackage(auth.loginAuth, document.infoD.hubId, newPackage);
    };

    const replayUsageInvoiceNotification = () => {
        replayInvoiceNotificationUsage(auth.loginAuth, document.infoD.sdiId);
    };

    const showMessage = element => {
        setMessageSelected(element);
        setMessagePreview(true);
        setPreviewVisible(true);
    };

    return (
        <div>
            {previewVisible && (
                <Drawer
                    title={<span className="drawer-title">Anteprima</span>}
                    width={820}
                    onClose={() => setPreviewVisible(false)}
                    visible={previewVisible}
                >
                    <InvoicePreview
                        displayErrorToast={displayErrorToast}
                        documentInfo={document.infoD}
                        base64={messagePreview ? window.atob(messageSelected.content) : document.content.payload}
                        isInvoice={messagePreview ? false : true}
                        messageSelected={messageSelected}
                    />
                </Drawer>
            )}
            <Tabs variant="secondary" onChange={() => setPreviewVisible(false)}>
                <Tabs.TabPane tab={<FormattedMessage id={"informations"} />} key="informations">
                    <div>
                        <InvoiceInfoDetails
                            document={document}
                            handlePreview={() => setPreviewVisible(true)}
                            displaySuccessToast={displaySuccessToast}
                            admin={admin}
                            lstAttachments={lstAttachments}
                            replayUsageInvoiceNotification={replayUsageInvoiceNotification}
                            showMessage={showMessage}
                            displayErrorToast={displayErrorToast}
                            getUsageInvoicePackage={getUsageInvoicePackage}
                            reassignUsageInvoice={reassignUsageInvoice}
                            removeUsageInvoice={removeUsageInvoice}
                            reassignUsageInvoicePackage={reassignUsageInvoicePackage}
                        />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id={"details"} />} key="details">
                    <InvoiceInfoIdentity
                        admin={admin}
                        document={document}
                        getUsageInvoicePackage={getUsageInvoicePackage}
                        reassignUsageInvoice={reassignUsageInvoice}
                        removeUsageInvoice={removeUsageInvoice}
                        reassignUsageInvoicePackage={reassignUsageInvoicePackage}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id={"chronology"} />} key="chronology">
                    <InvoiceInfoChronology document={document} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

InvoiceInfo.propTypes = {
    admin: PropTypes.object,
    auth: PropTypes.object,
    document: PropTypes.object,
    deleteInvoiceUsage: PropTypes.func.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    displaySuccessToast: PropTypes.func,
    reassignInvoiceUsage: PropTypes.func.isRequired,
    invoiceUsagePackage: PropTypes.func.isRequired,
    reassignInvoiceUsagePackage: PropTypes.func.isRequired,
    replayInvoiceNotificationUsage: PropTypes.func.isRequired
};
