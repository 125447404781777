import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Alert, Empty } from "antd";
import { Container, ContainerTitle } from "./styled";

export const DashboardAlarm = ({ title, company, cctProvRequest, packageSubscriptions }) => {
    const [alarm, setAlarm] = useState([]);

    useEffect(() => {
        let alarm = [];
        if (company.info.base.status.status !== "VALIDATED") {
            alarm.push(
                <Alert key="1" message={<FormattedMessage id="company_not_validated" />} showIcon type="warning" />
            );
        }
        if (
            (company.infoMetering.fiscalCode &&
                company.infoMetering.fiscalCode !== company.info.base.identifier.taxId) ||
            (company.infoMetering.vatNumber &&
                company.infoMetering.vatNumber !== company.info.base.identifier.vatNumber)
        ) {
            alarm.push(
                <Alert
                    key="2"
                    message={
                        <FormattedMessage
                            id="dashboard_alarm_msg_misaligned_metering"
                            values={{
                                fiscalCode: company.infoMetering.fiscalCode,
                                vatNumber: company.infoMetering.vatNumber
                            }}
                        />
                    }
                    showIcon
                    type="error"
                />
            );
        }

        let numOwners = company.users.users
            ? company.users.users.filter(x => x.roles[0].actionKey === "OWNER").length
            : -1;
        if (numOwners === 0) {
            alarm.push(
                <Alert
                    key="3"
                    message={<FormattedMessage id="dashboard_alarm_msg_without_user" />}
                    showIcon
                    type={company.info.base.details.classifier !== "COMPANY" ? "error" : "warning"}
                />
            );
        }
        let checkPackageSubscriptions = false;
        if (cctProvRequest.provisioning == null) {
            Object.values(packageSubscriptions).forEach(element => {
                let family = String(element.family);
                if (["FATTURE_ATTIVE_PASSIVE", "FICAGYO"].includes(family)) {
                    checkPackageSubscriptions = true;
                }
            });
        }

        if (checkPackageSubscriptions) {
            alarm.push(
                <Alert
                    key="4"
                    message={<FormattedMessage id="dashboard_alarm_msg_not_active_package" />}
                    showIcon
                    type={"warning"}
                />
            );
        }

        if (
            cctProvRequest.provisioning != null &&
            cctProvRequest.provisioning.find(state => state.statusCode !== "validated")
        ) {
            alarm.push(
                <Alert
                    key="5"
                    message={<FormattedMessage id="dashboard_alarm_msg_cct_validated" />}
                    showIcon
                    type={"warning"}
                />
            );
        }
        setAlarm(alarm);
    }, [cctProvRequest, company, packageSubscriptions]);

    let statusCompany = company.info.base.status.status;
    let numOwners = company.users.users ? company.users.users.filter(x => x.roles[0].actionKey === "OWNER").length : -1;
    return (
        <Container>
            <ContainerTitle>{title}</ContainerTitle>
            {statusCompany === "VALIDATED" &&
            numOwners !== 0 &&
            alarm.length === 0 &&
            (!company.infoMetering.fiscalCode ||
                (company.infoMetering.fiscalCode &&
                    company.infoMetering.fiscalCode === company.info.base.identifier.taxId &&
                    company.infoMetering.vatNumber === company.info.base.identifier.vatNumber)) ? (
                <Empty description={<FormattedMessage id="no_warning_present" />} />
            ) : null}
            {alarm}
        </Container>
    );
};

DashboardAlarm.propTypes = {
    company: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    cctProvRequest: PropTypes.object.isRequired,
    packageSubscriptions: PropTypes.object.isRequired
};
