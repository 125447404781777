import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { Row, Col, Button, Layout } from "antd";
import { LOGO_COLOR } from "../../config";

import packageInfo from "../../../package.json";
import { resetResetAuthPassword, resetUpdateAuthPassword } from "../../actions/user/write";

class UpdatePasswordSuccessView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        resetResetAuthPassword: PropTypes.func.isRequired,
        resetUpdateAuthPassword: PropTypes.func.isRequired
    };

    resetPasswordState() {
        const { resetUpdateAuthPassword, resetResetAuthPassword } = this.props;
        resetResetAuthPassword();
        resetUpdateAuthPassword();
    }

    render() {
        const { admin } = this.props;
        return admin.password.updatePassword.status.ended || admin.password.resetPassword.status.ended ? (
            <div className="loginBackground">
                <div className="Login">
                    <Row justify="center" type="flex">
                        <Col>
                            <div className="Login-header">
                                <img src={LOGO_COLOR} className="Login-header-img" alt="logo" />
                            </div>
                        </Col>
                    </Row>
                    <Row justify="center" type="flex">
                        <div style={{ width: "80%" }}>
                            <span>{"Complimenti, password modificata con successo!"}</span>
                            <br />
                            <br />
                            <Link to="/">
                                <Button
                                    className="root-button"
                                    type="primary"
                                    onClick={() => this.resetPasswordState()}
                                >
                                    {"Prosegui"}
                                </Button>
                            </Link>
                        </div>
                    </Row>
                    <Layout.Footer className="root-footer">
                        <p>{"TS Digital BackOffice 2020 - v" + packageInfo.version}</p>
                    </Layout.Footer>
                </div>
            </div>
        ) : (
            <Redirect to="/" />
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin
});

const actions = {
    resetResetAuthPassword,
    resetUpdateAuthPassword
};

const composedHoc = connect(
    mapStateToProps,
    actions
);
export default composedHoc(UpdatePasswordSuccessView);
