import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { CompanyDefaultSerives } from "../../../components/company/defaut-services";
import { CompanyTsPaySerive } from "../../../components/company/ts-pay-service";
import { searchItems, searchCompany } from "../../../actions/search/company";
import { getServicesForCompanySeached } from "../../../actions/search/services";
import { activateService, disableService } from "../../../actions/company/services";
import { getTsPayConfig } from "../../../actions/ts-pay/read";

import { useCompanyTsPaySeriveView } from "./view-model";

const CompanyTsPaySeriveView = ({
    auth,
    admin,
    company,
    history,
    searchItems,
    searchCompany,
    getServicesForCompanySeached,
    activateService,
    disableService,
    getTsPayConfig,
    services
}) => {
    const viewModel = useCompanyTsPaySeriveView(
        auth.loginAuth,
        admin,
        company,
        company.active.services,
        searchItems,
        activateService,
        disableService,
        services,
        getTsPayConfig
    );

    return (
        <ServiceCard
            history={history}
            content={
                <div>
                    <CompanyDefaultSerives
                        auth={auth}
                        company={company}
                        serviceId={"TSPAY"}
                        service={viewModel.service}
                        loading={viewModel.loading}
                        configuration={viewModel.configuration}
                        searchCompany={searchCompany}
                        getServicesForCompanySeached={getServicesForCompanySeached}
                        handleEnableDisableService={viewModel.handleEnableDisableService}
                        isHavePermission={viewModel.isHavePermission}
                        services={viewModel.services}
                    />
                    <CompanyTsPaySerive loading={viewModel.tsPayLoading} configuration={viewModel.tsPayConfiguration} />
                </div>
            }
            serviceInfo={{ name: <FormattedMessage id="service" />, category: "TSPAY" }}
            statisticsEnable={false}
        />
    );
};

CompanyTsPaySeriveView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    searchItems: PropTypes.func.isRequired,
    services: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    company: state.company,
    services: state.services
});

const actions = {
    searchItems,
    searchCompany,
    getServicesForCompanySeached,
    activateService,
    disableService,
    getTsPayConfig
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyTsPaySeriveView);
