import React from "react";
import PropTypes from "prop-types";

import TsCard from "../general/ts-card";
import { FormattedMessage } from "react-intl";
import {
    CertificationSectionTitle,
    CardTsWrapper,
    CertificationViewBlock,
    InnerCertificationSectionTitle
} from "./styled.js";
import CertificationsSearch from "../tool/certification-search";

const Certifications = ({ history }) => {
    return (
        <div>
            <CertificationViewBlock>
                <CertificationSectionTitle>
                    DOCUMENTI{" "}
                    <InnerCertificationSectionTitle>
                        <CertificationsSearch history={history} />
                    </InnerCertificationSectionTitle>
                </CertificationSectionTitle>
                <CardTsWrapper>
                    <TsCard
                        title={<FormattedMessage id="c-certifications.pending" />}
                        icon="fas fa-file-contract"
                        description={<FormattedMessage id="c-certifications.pending-desc" />}
                        action={() => history.push("/certifications/list/pending")}
                    />
                    <TsCard
                        title={<FormattedMessage id="c-certifications.reloaded" />}
                        icon="fas fa-file-contract"
                        description={<FormattedMessage id="c-certifications.reloaded-desc" />}
                        disabled={true}
                    />
                    <TsCard
                        title={<FormattedMessage id="c-certifications.rejected" />}
                        icon="fas fa-file-contract"
                        description={<FormattedMessage id="c-certifications.rejected-desc" />}
                        action={() => history.push("/certifications/list/rejected")}
                    />
                    <TsCard
                        title={<FormattedMessage id="c-certifications-paused" />}
                        icon="fas fa-stopwatch"
                        description={<FormattedMessage id="c-certifications-paused-desc" />}
                        action={() => history.push("/certifications/list/pending?paused=true")}
                    />
                </CardTsWrapper>
                <br />
                <CertificationSectionTitle>CERTIFICAZIONI</CertificationSectionTitle>
                <CardTsWrapper>
                    <TsCard
                        title={<FormattedMessage id="c-certifications.item-certify" />}
                        icon="fas fa-shield-alt"
                        description={<FormattedMessage id="c-certifications.item-certify-desc" />}
                        action={() => history.push("/certifications/process")}
                    />
                    <TsCard
                        title={<FormattedMessage id="c-certifications.certified" />}
                        icon="fas fa-file-contract"
                        description={<FormattedMessage id="c-certifications.certified-desc" />}
                        action={() => history.push("/certifications/list/certified")}
                    />
                    <TsCard
                        title={<FormattedMessage id="c-certifications.awaiting-upload" />}
                        icon="fas fa-file-contract"
                        description={<FormattedMessage id="c-certifications.awaiting-upload-desc" />}
                        action={() => history.push("/certifications/list/awaiting-upload")}
                    />
                    <TsCard
                        title={<FormattedMessage id="c-certifications-paused" />}
                        icon="fas fa-stopwatch"
                        description={<FormattedMessage id="c-certifications-paused-desc" />}
                        action={() => history.push("/certifications/process?paused=true")}
                    />
                </CardTsWrapper>
            </CertificationViewBlock>
        </div>
    );
};
Certifications.propTypes = {
    history: PropTypes.object.isRequired
};
export default Certifications;
