import styled from "styled-components";

export const SectionContainer = styled.div``;

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;

export const ViewSection = styled.span`
    font-size: 16px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
    padding: 16px;
    display: flex;
    justify-content: space-between;
`;
