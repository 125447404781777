import {
    GET_METERING_READ_COMPANIES_START,
    GET_METERING_READ_COMPANIES_SUCCESS,
    GET_METERING_READ_COMPANIES_ERROR,
    GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_START,
    GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_SUCCESS,
    GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_ERROR,
    GET_METERING_READ_COMPANIES_PACKAGES_START,
    GET_METERING_READ_COMPANIES_PACKAGES_SUCCESS,
    GET_METERING_READ_COMPANIES_PACKAGES_ERROR
} from "../../actions/usage/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const getCompanies = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_METERING_READ_COMPANIES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_METERING_READ_COMPANIES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_METERING_READ_COMPANIES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getPackageSubscriptions = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                companySubscription: payload
            };
        case GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getCompanyPackages = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_METERING_READ_COMPANIES_PACKAGES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_METERING_READ_COMPANIES_PACKAGES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                companyPackages: payload
            };
        case GET_METERING_READ_COMPANIES_PACKAGES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
