import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { contains } from "ramda";
import { connect } from "react-redux";
import { isTablet } from "react-device-detect";
import "./style.css";

import { Card, Avatar, Modal, Switch, Badge, Popover } from "antd";
import EnableDisableService from "../../services/enable-disable";
import { displayErrorToast } from "../../../actions/toast";
import { getServiceInfo } from "../../../utils/get-service";
import { serviceDecode } from "../../../utils/decode-services";
import { userIsAuthorized } from "../../../utils/get-roles";
import { activateService, disableService, fetchActiveServices } from "../../../actions/company/services";
import { activatePeopleAppService } from "../../../actions/services/peopleapp";
import { getSdiConfig } from "../../../actions/services/sdi";
import { getB2bPassiveConfig } from "../../../actions/services/b2b-passive";
import { getCctProvisioningRequest } from "../../../actions/cct/read";
import { searchCompany, searchItems } from "../../../actions/search/company";
import {
    searchSdiConfig,
    searchB2bPassiveConfig,
    getServicesForCompanySeached
} from "../../../actions/search/services";

const { Meta } = Card;

class ServiceCard extends Component {
    constructor() {
        super();
        this.state = {
            visible: false,
            ownerId: null,
            isCompanyOwner: true,
            consoleIva: "false",
            signatureIva: "false",
            multiSendIva: "false",
            fra: "false",
            fga: "false",
            frq: "false",
            fmq: "false",
            signatureConsole: "false",
            signatureErp: "false",
            rtl: "false",
            tem: "false",
            fatturaSmart: "false",
            serviceMultiOwner: []
        };
    }
    static propTypes = {
        activateService: PropTypes.func.isRequired,
        activatePeopleAppService: PropTypes.func.isRequired,
        agyoService: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        admin: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        cctProvRequest: PropTypes.object,
        disableService: PropTypes.func.isRequired,
        displayErrorToast: PropTypes.func.isRequired,
        fetchActiveServices: PropTypes.func.isRequired,
        getB2bPassiveConfig: PropTypes.func.isRequired,
        getCctProvisioningRequest: PropTypes.func.isRequired,
        getSdiConfig: PropTypes.func.isRequired,
        searchB2bPassiveConfig: PropTypes.func.isRequired,
        getServicesForCompanySeached: PropTypes.func.isRequired,
        searchCompany: PropTypes.func.isRequired,
        searchItems: PropTypes.func.isRequired,
        searchSdiConfig: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        meteringExtension: PropTypes.bool
    };

    componentDidMount() {
        const { agyoService, auth, company, getCctProvisioningRequest, services } = this.props;
        let filters = "";
        if (agyoService.id === "CCT-FLOW") {
            if (company.info.base.details.classifier === "STUDIO") {
                filters = {
                    "cf-produttore": company.info.base.identifier.taxId
                };
            } else {
                filters = {
                    "cf-titolari": company.info.base.identifier.taxId
                };
            }
            getCctProvisioningRequest(auth.loginAuth, filters);
        }
        if (services && services.list && services.list.services && services.list.services.length) {
            let serviceMultiOwner = services.list.services
                .filter(element => element.maxActiveConnections && element.maxActiveConnections > 0)
                .map(filteredElement => {
                    return filteredElement.id;
                });
            this.setState({ serviceMultiOwner: serviceMultiOwner || [] });
        }
    }
    handleSetExtraData(fieldName, fieldValue) {
        this.setState({ [fieldName]: fieldValue.toString() });
    }

    handleSetOwnerId(ownerIdValue) {
        this.setState({ ownerId: ownerIdValue });
    }

    handleActive(serviceSelect) {
        const { auth, company, activateService } = this.props;
        const {
            ownerId,
            consoleIva,
            signatureIva,
            multiSendIva,
            fra,
            frq,
            fga,
            fmq,
            signatureConsole,
            signatureErp,
            rtl,
            tem,
            fatturaSmart
        } = this.state;

        let extraData =
            serviceSelect === "SIGNATURE-FLOW"
                ? {
                      fra,
                      frq,
                      fga,
                      fmq,
                      signatureConsole,
                      signatureErp
                  }
                : serviceSelect === "A-FISCALI-IVA-FLOW"
                ? { consoleIva, signatureIva, multiSendIva }
                : serviceSelect === "HBLE-ESM"
                ? { RTL: rtl, TEM: tem }
                : serviceSelect === "SDI-FLOW"
                ? { fatturaSmart }
                : null;
        if (serviceSelect === "PEOPLEAPP") {
            this.enablePeopleApp();
        } else {
            activateService(
                auth.loginAuth,
                company.info.base.id,
                serviceSelect,
                extraData,
                ownerId === null ? company.info.base.id : ownerId
            ).then(() => {
                this.setState({ visible: false });
            });
        }
    }

    async enablePeopleApp() {
        const { auth, company, activatePeopleAppService } = this.props;
        let type = company.info.base.details.classifier === "COMPANY" ? "AZIENDA" : "STUDIO";
        let result = await activatePeopleAppService(auth.loginAuth, company.info.base.id, type);
        if (result) {
            activateService(auth.loginAuth, company.info.base.id, "PEOPLEAPP", null, null).then(() => {
                this.setState({ visible: true });
            });
        } else {
            this.setState({ visible: false });
        }
    }
    handleDisableService(serviceSelect) {
        const { auth, company, disableService, fetchActiveServices, services } = this.props;
        let serviceToDisable = getServiceInfo(serviceSelect, company, services);
        disableService(auth.loginAuth, serviceToDisable.id, serviceSelect, "", serviceToDisable.ownerId).then(() => {
            this.setState({ visible: false });
            fetchActiveServices(auth.loginAuth, company.info.base.id);
        });
    }

    render() {
        const {
            agyoService,
            auth,
            admin,
            company,
            cctProvRequest,
            companyService,
            getB2bPassiveConfig,
            getSdiConfig,
            searchB2bPassiveConfig,
            searchCompany,
            searchItems,
            searchSdiConfig,
            getServicesForCompanySeached,
            services,
            meteringExtension
        } = this.props;
        const { isCompanyOwner, ownerId, serviceMultiOwner } = this.state;
        let colorCCT = false;

        let appId = agyoService.appId;
        if (
            agyoService.id === "CCT-FLOW" &&
            cctProvRequest.cctProvRequest.status.ended &&
            cctProvRequest.cctProvRequest.totalItems !== 0
        ) {
            colorCCT = cctProvRequest.cctProvRequest.provisioning.find(
                state =>
                    state.statusCode === "initialized" ||
                    state.statusCode === "downloaded" ||
                    state.statusCode === "uploaded" ||
                    state.statusCode === "rejected"
            );
        }
        return (
            <div className={isTablet ? "c-service-card-tablet" : "c-service-card"}>
                <Card
                    actions={[
                        <span className="status">
                            {companyService && !companyService.readonly ? (
                                <Badge status="success" text="Attivo" />
                            ) : companyService && companyService.readonly ? (
                                <Badge status="processing" text="Sola lettura" />
                            ) : colorCCT ? (
                                <Badge status="warning" text="In attivazione" />
                            ) : (
                                <Badge status="error" text="Disattivo" />
                            )}
                        </span>,
                        (companyService && companyService.agyoService.active) || agyoService.id === "CCT-FLOW" ? (
                            <div>
                                <Link to={agyoService.id}>
                                    <i className="fas fa-cog" />
                                </Link>
                            </div>
                        ) : (
                            <i className="fas fa-cog settings-disable" />
                        ),
                        <div>
                            {!meteringExtension ? (
                                <div>
                                    {contains(agyoService.id, serviceMultiOwner) ? (
                                        userIsAuthorized("enable-disable-service", admin.adminInfo.permits) ? (
                                            <div onClick={() => this.setState({ visible: true })}>
                                                <i className="fas fa-plus-circle" />
                                            </div>
                                        ) : (
                                            <div>
                                                <i className="fas fa-plus-circle settings-disable" />
                                            </div>
                                        )
                                    ) : (
                                        <Switch
                                            checked={companyService ? true : false}
                                            size="small"
                                            onChange={() => this.setState({ visible: true })}
                                            disabled={
                                                !userIsAuthorized("enable-disable-service", admin.adminInfo.permits) ||
                                                (agyoService.id === "SIGNATURE-FLOW" && !companyService) ||
                                                (agyoService.id === "PRIVACY-FLOW" && !companyService) ||
                                                (agyoService.id === "DIGITALBOX" &&
                                                    company.info.base.details.classifier !== "STUDIO" &&
                                                    !companyService)
                                            }
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    ]}
                >
                    <Meta
                        avatar={
                            <Avatar
                                style={{
                                    background: serviceDecode(appId).background,
                                    color: serviceDecode(appId).color,
                                    border: "1px solid" + serviceDecode(appId).color
                                }}
                            >
                                <i className={serviceDecode(appId).icon} />
                            </Avatar>
                        }
                        description={serviceDecode(appId).title}
                        title={agyoService.description}
                    />
                    {companyService && !companyService.owner && (
                        <Popover content="Servizio condiviso">
                            <i className="fas fa-share-alt share-icon" />
                        </Popover>
                    )}
                    {agyoService.id === "DIGITALBOX" && !companyService && (
                        <Popover content="Servizio attivabile solamente per commercialisti con eRicevi attivo">
                            <i className="fas fa-exclamation-triangle alert-icon" />
                        </Popover>
                    )}

                    <Modal
                        okText={
                            userIsAuthorized("c-display-warning-enable-disable-services", admin.adminInfo.permits)
                                ? "Forza attivazione"
                                : "Si"
                        }
                        cancelText="No"
                        centered
                        onOk={() =>
                            contains(agyoService.id, serviceMultiOwner)
                                ? this.handleActive(agyoService.id)
                                : companyService
                                ? this.handleDisableService(agyoService.id)
                                : this.handleActive(agyoService.id)
                        }
                        onCancel={() => this.setState({ visible: false })}
                        okButtonProps={{
                            disabled: !isCompanyOwner && !ownerId && !companyService
                        }}
                        title={
                            contains(agyoService.id, serviceMultiOwner)
                                ? "Attiva servizio " + agyoService.description
                                : companyService
                                ? "Disattiva servizio " + agyoService.description
                                : "Attiva servizio " + agyoService.description
                        }
                        visible={this.state.visible}
                        width={640}
                    >
                        <EnableDisableService
                            admin={admin}
                            auth={auth}
                            company={company}
                            serviceSelect={agyoService.id}
                            isToEnable={
                                contains(agyoService.id, serviceMultiOwner) ? true : companyService ? false : true
                            }
                            getB2bPassiveConfig={getB2bPassiveConfig}
                            getSdiConfig={getSdiConfig}
                            searchB2bPassiveConfig={searchB2bPassiveConfig}
                            searchCompany={searchCompany}
                            searchItems={searchItems}
                            setOwnerId={value => this.handleSetOwnerId(value)}
                            setExtraData={(name, value) => this.handleSetExtraData(name, value)}
                            setCompanyOnwer={value => this.setState({ isCompanyOwner: value })}
                            searchSdiConfig={searchSdiConfig}
                            getServicesForCompanySeached={getServicesForCompanySeached}
                            services={services}
                        />
                    </Modal>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    cctProvRequest: state.cct.read,
    company: state.company,
    services: state.services
});

const actions = {
    activateService,
    activatePeopleAppService,
    disableService,
    displayErrorToast,
    fetchActiveServices,
    getB2bPassiveConfig,
    getCctProvisioningRequest,
    getSdiConfig,
    searchB2bPassiveConfig,
    searchCompany,
    searchItems,
    searchSdiConfig,
    getServicesForCompanySeached
};

const composedHoc = connect(
    mapStateToProps,
    actions
);
export default composedHoc(ServiceCard);
