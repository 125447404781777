import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { isNil } from "ramda";
import { Card, Select, Typography, message } from "antd";
import { saveAs } from "file-saver";
import { injectIntl } from "react-intl";

import { getAdminPaymentTransactions, getBankLinkDownloadFile } from "../../actions/bank-link/read";
import { errorBoundary } from "../../components/general/error-boundary";
import PageHeader from "../../components/general/PageHeader/index";
import { BankLinkFileTransactions } from "../../components/bank-link/file-transactions";

import { ContractWrapper } from "./styled";
import base64 from "react-native-base64";

const BankLinkFileTransactionsView = ({
    auth,
    history,
    intl,
    getAdminPaymentTransactions,
    getBankLinkDownloadFile,
    match: {
        params: { hubId }
    }
}) => {
    const viewModel = useBankLinkFileTransactionsView(
        auth.loginAuth,
        history,
        intl,
        hubId,
        getAdminPaymentTransactions,
        getBankLinkDownloadFile
    );

    return (
        <div>
            <Card style={{ height: "90vh" }}>
                <ContractWrapper>
                    <PageHeader history={history} labelId={`movements`} />
                    <BankLinkFileTransactions
                        loading={viewModel.loadingTransactions}
                        paymentTransactionEntityList={viewModel.paymentTransactionEntityList}
                        handleNextPage={viewModel.handleNextPageTransactions}
                        handlePageSizeChanged={viewModel.handlePageSizeChangedTransactions}
                        handlePreviousPage={viewModel.handlePreviousPageTransactions}
                        hasNextPage={viewModel.hasNextPageTransactions}
                        currentPage={viewModel.currentPageTransactions}
                        defaultPageSize={viewModel.defaultPageSize}
                        pageSizeOptions={viewModel.pageSizeOptions}
                        query={viewModel.queryTransactions}
                        expanded={viewModel.expandedTransactions}
                        handleExpandedChange={viewModel.handleExpandedChangeTransactions}
                        handleAddRoules={viewModel.handleAddRoulesTransactions}
                        handleDownloadFile={viewModel.handleDownloadFileTransactions}
                        filter={viewModel.paymentTransactionFilter}
                        handleFilterChange={viewModel.handleFilterChangeTransactions}
                    />
                </ContractWrapper>
            </Card>
        </div>
    );
};

BankLinkFileTransactionsView.propTypes = {
    auth: PropTypes.object.isRequired,
    getAdminPaymentTransactions: PropTypes.func.isRequired,
    getBankLinkDownloadFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = {
    getAdminPaymentTransactions,
    getBankLinkDownloadFile
};

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(BankLinkFileTransactionsView);

const useBankLinkFileTransactionsView = (
    auth,
    history,
    intl,
    hubId,
    getAdminPaymentTransactions,
    getBankLinkDownloadFile
) => {
    let defaultPageSize = "25";
    let pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];
    /// Transactions
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [paymentTransactionEntityList, setPaymentTransactionEntityList] = useState([]);
    const [currentPageTransactions, setCurrentPageTransactions] = useState(1);
    const [pageSizeTransactions, setPageSizeTransactions] = useState(defaultPageSize);
    const [hasNextPageTransactions, setHasNextPageTransactions] = useState(false);
    const [continuationTokenTransactions, setContinuationTokenTransactions] = useState([null]);
    const [expandedTransactions, setExpandedTransactions] = useState(false);
    const [paymentTransactionFilter, setPaymentTransactionFilter] = useState(null);
    const [queryTransactions, setQueryTransactions] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        if (paymentTransactionFilter && paymentTransactionFilter.cbiReason) {
            listFilter.push("cbiReason=" + paymentTransactionFilter.cbiReason);
        }
        listFilter.push("uploadId=" + hubId);
        listFilter.push("size=" + pageSizeTransactions);
        if (continuationTokenTransactions && continuationTokenTransactions[currentPageTransactions - 1]) {
            listFilter.push("continuationToken=" + continuationTokenTransactions[currentPageTransactions - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQueryTransactions(query);
    }, [currentPageTransactions, pageSizeTransactions, hubId, continuationTokenTransactions, paymentTransactionFilter]);

    useEffect(() => {
        const getPaymentTransactionsInfo = async () => {
            let filter = {
                uploadId: hubId,
                continuationToken: continuationTokenTransactions[currentPageTransactions - 1],
                ...(paymentTransactionFilter &&
                    paymentTransactionFilter.cbiReason && { cbiReasons: [paymentTransactionFilter.cbiReason] })
            };
            let resultPaymentTransactions = await getAdminPaymentTransactions(auth, filter, pageSizeTransactions);
            if (resultPaymentTransactions) {
                if (
                    resultPaymentTransactions._embedded &&
                    resultPaymentTransactions._embedded.paymentTransactionEntityList &&
                    resultPaymentTransactions._embedded.paymentTransactionEntityList.length
                ) {
                    setPaymentTransactionEntityList(resultPaymentTransactions._embedded.paymentTransactionEntityList);
                }
                setHasNextPageTransactions(resultPaymentTransactions.page.hasNext);
                if (continuationTokenTransactions.length <= currentPageTransactions) {
                    continuationTokenTransactions.push(resultPaymentTransactions.page.continuationToken);
                }
                setContinuationTokenTransactions(continuationTokenTransactions);
            }
            setLoadingTransactions(false);
        };

        setLoadingTransactions(true);
        setPaymentTransactionEntityList([]);
        setHasNextPageTransactions(false);
        if (hubId !== "bank-link-rules") {
            getPaymentTransactionsInfo();
        }
    }, [
        auth,
        hubId,
        continuationTokenTransactions,
        currentPageTransactions,
        getAdminPaymentTransactions,
        pageSizeTransactions,
        paymentTransactionFilter
    ]);

    const handleFilterChangeTransactions = filters => {
        let filter = {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "cbiReason") {
                        filter.cbiReason = filters[key][0];
                    }
                }
            }
        }
        setCurrentPageTransactions(1);
        setContinuationTokenTransactions([null]);
        setPaymentTransactionFilter(filter);
    };

    const handlePageSizeChangedTransactions = pageSize => {
        if (pageSize) {
            setCurrentPageTransactions(1);
            setPageSizeTransactions(parseInt(pageSize));
            setContinuationTokenTransactions([null]);
        }
    };

    const handleNextPageTransactions = () => {
        if (hasNextPageTransactions) {
            setCurrentPageTransactions(currentPageTransactions + 1);
        }
    };

    const handlePreviousPageTransactions = () => {
        if (currentPageTransactions !== 1) {
            setCurrentPageTransactions(currentPageTransactions - 1);
        }
    };

    const handleExpandedChangeTransactions = value => {
        setExpandedTransactions(value);
    };

    const handleAddRoulesTransactions = item => {
        history.push(`bank-link-rules/new/${base64.encode(item.description)}/${base64.encode(item.abi)}`);
    };

    const handleDownloadFileTransactions = async item => {
        if (item && item.uploadId) {
            let resultFile = await getBankLinkDownloadFile(auth, item.uploadId, item.source === "XLS_FILE");
            if (resultFile) {
                try {
                    if (item.source === "CBI_FILE") {
                        var download = document.createElement("a");
                        download.setAttribute(
                            "href",
                            "data:application/octet-stream;charset=utf-16le;base64," + window.btoa(resultFile)
                        );
                        download.setAttribute("download", `BankLinK_File_${item.uploadId}.txt`);
                        download.click();
                    } else {
                        let blob = new Blob([resultFile], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        saveAs(blob, `BankLinK_File_${item.uploadId}.xls`);
                    }
                    message.success(
                        intl.formatMessage({
                            id: "the_file_downloaded_successfully"
                        })
                    );
                } catch (e) {
                    message.error(
                        intl.formatMessage({
                            id: "can_not_download_file"
                        })
                    );
                }
            } else {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        }
    };

    return {
        defaultPageSize,
        pageSizeOptions,
        /// Transactions
        loadingTransactions,
        paymentTransactionEntityList,
        handleNextPageTransactions,
        currentPageTransactions,
        hasNextPageTransactions,
        handlePreviousPageTransactions,
        handlePageSizeChangedTransactions,
        queryTransactions,
        expandedTransactions,
        handleExpandedChangeTransactions,
        handleAddRoulesTransactions,
        handleDownloadFileTransactions,
        paymentTransactionFilter,
        handleFilterChangeTransactions
    };
};
