import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { contains } from "ramda";
import { FormattedMessage } from "react-intl";
import { Spin, Icon, Tabs } from "antd";

import { searchCompany, searchItems } from "../../actions/search/company";
import { findOwnManagedConnections, findOwnManagerConnections } from "../../actions/connections/read";
import { searchUser } from "../../actions/search/user";
import { getServicesForCompanySeached } from "../../actions/search/services";
import {
    addRoles,
    addRolesWithPolicy,
    removeRoles,
    enableUser,
    disableUser,
    resendWelcomEmail
} from "../../actions/user/write";
import { getUser } from "../../actions/user/read";
import { getPreferences } from "../../actions/notification/preferences";
import { getActivites } from "../../actions/notification/read";
import { getUserConsents } from "../../actions/user-consent/read";
import { deleteTSID } from "../../actions/tsid/delete";

import { errorBoundary } from "../../components/general/error-boundary";
import UserInfo from "../../components/user/info";
import { UserDashboard } from "../../components/user/user-dashboard";
import { UserRoles } from "../../components/user/user-roles";
import { UserNotification } from "../../components/user/user-notification";
import { UserConsents } from "../../components/user/user-consents";

import { translateUserConsents } from "../../utils/translate";

import { ViewTitle, SpinContainer, Body, InfoCard, DataCard } from "./styled";

const UserView = ({
    addRoles,
    addRolesWithPolicy,
    admin,
    auth,
    enableUser,
    disableUser,
    deleteTSID,
    getServicesForCompanySeached,
    getUser,
    history,
    removeRoles,
    resendWelcomEmail,
    searchCompany,
    searchItems,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchUser,
    user,
    getPreferences,
    getActivites,
    getUserConsents,
    match: {
        params: { userId }
    }
}) => {
    const viewModel = useUserView(
        auth.loginAuth,
        admin,
        history,
        userId,
        getUser,
        getPreferences,
        getActivites,
        disableUser,
        deleteTSID,
        enableUser,
        resendWelcomEmail,
        removeRoles,
        addRoles,
        addRolesWithPolicy,
        searchCompany,
        searchItems,
        findOwnManagedConnections,
        findOwnManagerConnections,
        getServicesForCompanySeached,
        searchUser,
        getUserConsents
    );

    if (viewModel.loading) {
        return (
            <SpinContainer>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </SpinContainer>
        );
    }

    return (
        <div>
            <ViewTitle>
                <FormattedMessage id="general.user" />
            </ViewTitle>
            <Body>
                <InfoCard>
                    <UserInfo
                        refreshUser={viewModel.handleRefreshUser}
                        isAdmPage={viewModel.isAdmPage}
                        onlyInfo={false}
                        userInfo={viewModel.userInfo}
                    />
                </InfoCard>
                <DataCard>
                    <Tabs
                        size={"large"}
                        onChange={e => viewModel.handleChangeUserTab(e)}
                        defaultActiveKey={viewModel.userTab}
                    >
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <i className="fas fa-columns" style={{ marginRight: 8 }} />
                                    <FormattedMessage id="c-company.dashboard" />
                                </span>
                            }
                            key="dashboard"
                        >
                            <div style={{ padding: 15 }}>
                                <UserDashboard
                                    admin={viewModel.admin}
                                    isAdmPage={viewModel.isAdmPage}
                                    userInfo={viewModel.userInfo}
                                    handleAddRoles={viewModel.handleAddRoles}
                                    handleAddRolesWithPolicy={viewModel.handleAddRolesWithPolicy}
                                    handleRemoveRoles={viewModel.handleRemoveRoles}
                                    handleChangeStatus={viewModel.handleChangeStatus}
                                    handleGetUserInfo={viewModel.handleGetUserInfo}
                                    handleAddRolesWithRefresh={viewModel.handleAddRolesWithRefresh}
                                    handleRemoveAllRoles={viewModel.handleRemoveAllRoles}
                                    handleRefreshAllRoles={viewModel.handleRefreshAllRoles}
                                    handleSendWelcomEmail={viewModel.handleSendWelcomEmail}
                                    handleDeleteTSID={viewModel.handleDeleteTSID}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <i className="fas fa-users-cog" style={{ marginRight: 8 }} />
                                    <FormattedMessage id="roles" />
                                </span>
                            }
                            key="roles"
                        >
                            <div style={{ padding: 15 }}>
                                <UserRoles
                                    admin={viewModel.admin}
                                    isAdmPage={viewModel.isAdmPage}
                                    userInfo={viewModel.userInfo}
                                    handleAddRoles={viewModel.handleAddRoles}
                                    handleAddRolesWithPolicy={viewModel.handleAddRolesWithPolicy}
                                    handleRemoveRoles={viewModel.handleRemoveRoles}
                                    handleRemoveSelectedRoles={viewModel.handleRemoveSelectedRoles}
                                    handleGetCompanyAndServiceInfo={viewModel.handleGetCompanyAndServiceInfo}
                                    companyInfo={viewModel.companyInfo}
                                    loadingGetCompanyInfo={viewModel.loadingGetCompanyInfo}
                                    handleAddRolesWithRefresh={viewModel.handleAddRolesWithRefresh}
                                    handleGetCompanyInfo={viewModel.handleGetCompanyInfo}
                                    handleGetServices={viewModel.handleGetServices}
                                    handleGetConnectionInfo={viewModel.handleGetConnectionInfo}
                                    handleChangeStatus={viewModel.handleChangeStatus}
                                    handleGetUserInfo={viewModel.handleGetUserInfo}
                                    handleSelectedRoles={viewModel.handleSelectedRoles}
                                    handleRemoveAllRoles={viewModel.handleRemoveAllRoles}
                                    handleRefreshAllRoles={viewModel.handleRefreshAllRoles}
                                    handleSendWelcomEmail={viewModel.handleSendWelcomEmail}
                                    handleDeleteTSID={viewModel.handleDeleteTSID}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <i className="fas fa-bell" style={{ marginRight: 8 }} />
                                    <FormattedMessage id="c-menu.admNotifications" />
                                </span>
                            }
                            key="notifications"
                        >
                            <div style={{ padding: 15 }}>
                                <UserNotification
                                    userInfo={viewModel.userInfo}
                                    ncsPersonalPreferenceList={viewModel.ncsPersonalPreferenceList}
                                    ncsGroupPreferenceList={viewModel.ncsGroupPreferenceList}
                                    ncsChannelsList={viewModel.ncsChannelsList}
                                    ncsActivityList={viewModel.ncsActivityList}
                                    ncsActivityListLoading={viewModel.ncsActivityListLoading}
                                    handlePageSizeChangeNcsActivity={viewModel.handlePageSizeChangeNcsActivity}
                                    pageSizeNcsActivity={viewModel.pageSizeNcsActivity}
                                    groupId={viewModel.groupId}
                                    handleChangeGroupId={viewModel.handleChangeGroupId}
                                    ncsGroupPreferenceLoading={viewModel.ncsGroupPreferenceLoading}
                                    lazyLoadCompanyOptions={viewModel.lazyLoadCompanyOptions}
                                    initialCompanyOptions={viewModel.initialCompanyOptions}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <i className="fas fa-user-shield" style={{ marginRight: 8 }} />
                                    <FormattedMessage id="consents" />
                                </span>
                            }
                            key="consents"
                        >
                            <div style={{ padding: 15 }}>
                                <UserConsents userConsentsList={viewModel.userConsentsList} />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </DataCard>
            </Body>
        </div>
    );
};
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    user: state.user
});

const actions = {
    addRoles,
    addRolesWithPolicy,
    disableUser,
    enableUser,
    deleteTSID,
    getUser,
    getServicesForCompanySeached,
    removeRoles,
    resendWelcomEmail,
    searchCompany,
    searchItems,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchUser,
    getPreferences,
    getActivites,
    getUserConsents
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
UserView.propTypes = {
    addRoles: PropTypes.func.isRequired,
    addRolesWithPolicy: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    enableUser: PropTypes.func.isRequired,
    disableUser: PropTypes.func.isRequired,
    deleteTSID: PropTypes.func.isRequired,
    getServicesForCompanySeached: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    removeRoles: PropTypes.func.isRequired,
    resendWelcomEmail: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    searchItems: PropTypes.func.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    searchUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    getPreferences: PropTypes.func.isRequired,
    getActivites: PropTypes.func.isRequired,
    getUserConsents: PropTypes.func.isRequired
};

export default composedHoc(UserView);

const useUserView = (
    auth,
    admin,
    history,
    userId,
    getUser,
    getPreferences,
    getActivites,
    disableUser,
    deleteTSID,
    enableUser,
    resendWelcomEmail,
    removeRoles,
    addRoles,
    addRolesWithPolicy,
    searchCompany,
    searchItems,
    findOwnManagedConnections,
    findOwnManagerConnections,
    getServicesForCompanySeached,
    searchUser,
    getUserConsents
) => {
    const [loading, setLoading] = useState(true);
    const [isAdmPage, setIsAdmPage] = useState(false);
    const [ncsPersonalPreferenceList, setNcsPersonalPreferenceList] = useState([]);
    const [ncsGroupPreferenceList, setNcsGroupPreferenceList] = useState([]);
    const [ncsGroupPreferenceLoading, setNcsGroupPreferenceLoading] = useState(false);
    const [ncsChannelsList, setNcsChannelsList] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [refreshUser, setRefreshUser] = useState(false);
    const [loadingGetCompanyInfo, setLoadingGetCompanyInfo] = useState(true);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [userTab, setUserTab] = useState("dashboard");
    const [groupId, setGroupId] = useState(null);
    const [userConsentsList, setUserConsentsList] = useState([]);
    const [companyIds, setCompanyIds] = useState([]);
    const [initialCompanyOptions, setInitialCompanyOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [ncsActivityList, setNcsActivityList] = useState([]);
    const [ncsActivityListLoading, setNcsActivityListLoading] = useState(true);
    const [userNcsId, setUserNcsId] = useState(null);
    const [pageSizeNcsActivity, setPageSizeNcsActivity] = useState(25);

    useEffect(() => {
        var path = history.location.pathname;
        if (contains("/control-pannel-users-backoffice/", path)) {
            setIsAdmPage(true);
        } else {
            setIsAdmPage(false);
        }
    }, [history.location]);

    useEffect(() => {
        const getUserInfo = async () => {
            let resultUser = await getUser(auth, userId);
            let groupId = null;
            let initialCompanyOptions = [];
            if (resultUser) {
                setUserInfo(resultUser);
                if (resultUser && resultUser.roles && resultUser.roles.length) {
                    let companyIds = [
                        ...new Set(
                            resultUser.roles
                                .map(role => role.resourceId)
                                .filter(r => r !== "BACKOFFICE" && r !== "REGISTERED")
                        )
                    ];
                    let companyUuids = [
                        ...new Set(
                            resultUser.roles
                                .map(role => role.resourceUuid)
                                .filter(r => r !== "BACKOFFICE" && r !== "REGISTERED")
                        )
                    ];
                    setCompanyIds(companyIds);
                    if (companyIds && companyIds.length) {
                        let slicedCompanyUuids = companyUuids.slice(0, 10);
                        let resultSearchItems = await searchItems(auth, slicedCompanyUuids, null, 10, true);

                        if (resultSearchItems && resultSearchItems.items && resultSearchItems.items.length) {
                            let item = resultSearchItems.items[0];
                            groupId = {
                                value: item.item.base.ncsId,
                                label: `${item.item.base.details.description} (${item.item.base.identifier.taxId})`
                            };
                            setGroupId(groupId);
                            resultSearchItems.items.forEach((item, index) =>
                                initialCompanyOptions.push({
                                    value: item.item.base.ncsId,
                                    label: `${item.item.base.details.description} (${item.item.base.identifier.taxId})`
                                })
                            );
                        }
                        setInitialCompanyOptions(initialCompanyOptions);
                        setCompanyOptions(initialCompanyOptions);
                    }
                }

                let ncsId = resultUser.profile ? resultUser.profile.ncsId : null;
                let userUuid = resultUser.profile ? resultUser.profile.uuid : null;

                if (ncsId) {
                    setUserNcsId(ncsId);
                    if (groupId && groupId.value) {
                        let resultGroupPreferences = await getPreferences(auth, ncsId, groupId.value);
                        if (resultGroupPreferences) {
                            setNcsGroupPreferenceList(resultGroupPreferences);
                        }
                    }

                    let resultPersonalPreferences = await getPreferences(auth, ncsId, null);
                    if (resultPersonalPreferences) {
                        setNcsPersonalPreferenceList(resultPersonalPreferences);
                    }
                }
                if (userUuid) {
                    let userConsents = await getUserConsents(auth, userUuid);
                    let data = [];
                    if (userConsents) {
                        Object.keys(userConsents).forEach(key => {
                            data.push({
                                type: key,
                                description: translateUserConsents(key),
                                version: userConsents[key].version,
                                latestVersion: userConsents[key].latestVersion,
                                accepted: userConsents[key].accepted,
                                timestamp: userConsents[key].timestamp
                            });
                        });
                        setUserConsentsList(data);
                    }
                }
            }
            setLoading(false);
        };

        if (userId) {
            setLoading(true);
            setUserInfo(null);
            setUserNcsId(null);
            setNcsGroupPreferenceList([]);
            setNcsPersonalPreferenceList([]);
            setNcsChannelsList([]);
            setUserConsentsList([]);
            setInitialCompanyOptions([]);
            setGroupId(null);
            getUserInfo();
        }
    }, [auth, getUser, userId, refreshUser, getPreferences, searchItems, getUserConsents]);

    useEffect(() => {
        const getNcsActivites = async ncsId => {
            let result = await getActivites(auth, ncsId, pageSizeNcsActivity);
            if (result) {
                setNcsActivityList(result);
            }
            setNcsActivityListLoading(false);
        };

        if (userNcsId) {
            setNcsActivityListLoading(true);
            getNcsActivites(userNcsId);
        }
    }, [auth, userNcsId, getActivites, pageSizeNcsActivity]);

    const handleChangeUserTab = tab => {
        setUserTab(tab);
    };

    const handleRefreshUser = () => {
        setRefreshUser(!refreshUser);
    };

    const handleChangeGroupId = async item => {
        setNcsGroupPreferenceLoading(true);
        setNcsGroupPreferenceList([]);
        setGroupId(item);
        let resultGroupPreferences = await getPreferences(auth, userInfo.profile.ncsId, item.value);
        setNcsGroupPreferenceList(resultGroupPreferences);
        setNcsGroupPreferenceLoading(false);
    };

    const handleChangeStatus = userStatus => {
        if (userStatus) {
            disableUser(auth, userId).then(() => setRefreshUser(!refreshUser));
        } else {
            enableUser(auth, userId).then(() => setRefreshUser(!refreshUser));
        }
    };

    const handleSendWelcomEmail = () => {
        resendWelcomEmail(auth, userId);
    };
    const handleDeleteTSID = () => {
        deleteTSID(auth, userInfo.profile.id);
    };
    const handleRefreshAllRoles = () => {
        setRefreshUser(!refreshUser);
    };

    const handleRemoveAllRoles = roles => {
        removeRoles(auth, userId, roles);
    };

    const handleAddRoles = roles => {
        addRoles(auth, userId, roles);
    };

    const handleRemoveSelectedRoles = () => {
        removeRoles(auth, userId, selectedRoles).then(() => setRefreshUser(!refreshUser));
    };

    const handleSelectedRoles = roles => {
        setSelectedRoles(roles);
    };

    const handleAddRolesWithPolicy = roles => {
        addRolesWithPolicy(auth, userId, roles).then(() => setRefreshUser(!refreshUser));
    };

    const handleRemoveRoles = roles => {
        removeRoles(auth, userId, roles).then(() => setRefreshUser(!refreshUser));
    };

    const handleGetCompanyAndServiceInfo = async (id, uuid) => {
        setLoadingGetCompanyInfo(true);
        let itemInfo = await searchCompany(auth, uuid, "BASE", true);
        let itemServices = await getServicesForCompanySeached(auth, id);
        setCompanyInfo({
            ...itemInfo.item,
            active: { services: itemServices }
        });
        setLoadingGetCompanyInfo(false);
    };

    const handleAddRolesWithRefresh = (roles, toast) => {
        addRoles(auth, userId, roles, toast).then(() => setRefreshUser(!refreshUser));
    };

    const handleGetCompanyInfo = async id => {
        return await searchCompany(auth, id, "BASE", true);
    };

    const handleGetServices = async id => {
        return await getServicesForCompanySeached(auth, id);
    };

    const handleGetConnectionInfo = async (type, filter) => {
        let params = { page: filter.page, items: filter.size };
        if (type === "manager") {
            return await findOwnManagedConnections(auth, filter, params);
        } else {
            return await findOwnManagerConnections(auth, filter, params);
        }
    };

    const handleGetUserInfo = async id => {
        return await searchUser(auth, id);
    };

    const lazyLoadCompanyOptions = async (search, prevOptions, { page }) => {
        let options = [];
        let oldCompanyOption = companyOptions;

        if (!search && companyIds && companyIds.length) {
            let slicedCompanyIds = companyIds.slice(prevOptions.length, prevOptions.length + 10);
            if (page !== 0) {
                let resultSearchItems = await searchItems(auth, slicedCompanyIds, null, 10, false);
                if (resultSearchItems && resultSearchItems.items && resultSearchItems.items.length) {
                    resultSearchItems.items.forEach((item, index) =>
                        options.push({
                            value: item.item.base.ncsId,
                            label: `${item.item.base.details.description} (${item.item.base.id})`
                        })
                    );
                    oldCompanyOption.push(...options);
                }
            } else {
                options = initialCompanyOptions;
            }
            setCompanyOptions(oldCompanyOption);
        }

        let searchCompanyOptions = [];
        if (search && oldCompanyOption.length) {
            searchCompanyOptions = oldCompanyOption.filter(({ label }) => label.toLowerCase().includes(search));
        }

        return {
            options: search ? searchCompanyOptions : options,
            hasMore: search
                ? searchCompanyOptions.length > prevOptions.length + 10
                : companyIds.length > prevOptions.length + 10,
            additional: {
                page: page + 1
            }
        };
    };

    const handlePageSizeChangeNcsActivity = page => {
        setPageSizeNcsActivity(parseInt(page));
    };

    return {
        admin,
        loading,
        isAdmPage,
        userTab,
        handleChangeUserTab,
        userInfo,
        ncsPersonalPreferenceList,
        ncsGroupPreferenceList,
        ncsGroupPreferenceLoading,
        ncsChannelsList,
        handleRefreshUser,
        handleChangeStatus,
        handleSendWelcomEmail,
        handleDeleteTSID,
        handleRefreshAllRoles,
        handleRemoveAllRoles,
        handleAddRoles,
        handleAddRolesWithPolicy,
        handleRemoveRoles,
        handleRemoveSelectedRoles,
        handleGetCompanyAndServiceInfo,
        handleSelectedRoles,
        companyInfo,
        loadingGetCompanyInfo,
        handleAddRolesWithRefresh,
        handleGetCompanyInfo,
        handleGetConnectionInfo,
        handleGetServices,
        handleGetUserInfo,
        handleChangeGroupId,
        groupId,
        userConsentsList,
        lazyLoadCompanyOptions,
        initialCompanyOptions,
        ncsActivityList,
        ncsActivityListLoading,
        handlePageSizeChangeNcsActivity,
        pageSizeNcsActivity: pageSizeNcsActivity.toString()
    };
};
