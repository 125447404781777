import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { isTablet } from "react-device-detect";
import { errorBoundary } from "../../components/general/error-boundary";
import "./style.css";

import { Route, Switch } from "react-router-dom";
import { Card, Col, Row, Spin, Icon } from "antd";
import { ViewTitle, SpinContainer } from "../../styled";
import { FormattedMessage } from "react-intl";

import CompanyInfo from "../../components/company/info";
import CompanyContactView from "../contract";
import CompanyEditView from "./edit";
import CompanyInvoicesView from "./invoices";
import CompanyordersView from "./orders";
import CompanyDbxView from "./dbx";
import CompanySignatureView from "./signature";
import CompanyDataView from "./data";
import CompanyObligationsView from "./obligations-tax";
import ItemSpidView from "./item-spid";

import InvoiceInfoView from "../invoice-info";
import PrivacyServiceView from "../services/privacy";
import SignatureServiceView from "../services/signature";
import AFiscaliServiceView from "../services/a-fiscali";
import B2bPassiveServiceView from "../services/b2b-passive";
import GdprLogServiceView from "../services/gdpr-log";
import SdiServiceView from "../services/sdi";
import DigitalboxServiceView from "../services/digitalbox";
import CctFlowView from "./cct-flow";
import ArcFlowView from "./archive";
import { MeteringLynfaTabView } from "./metering-lynfa";
import CompanyInvoiceTradingView from "./invoice-trading";
import CompanyDefaultSerivesView from "./default-services";
import CompanyEligibleInvoiceView from "./eligible-invoice";
import CompanyEReceiptView from "./e-receipt";
import CompanyBankLinkView from "./bank-link";
import BankLinkInfoView from "../bank-link-info";
import BankLinkFileTransactionsView from "../../views/bank-link-file";
import BankLinkRuleView from "../../views/bank-link-rule";
import PaymentView from "./payment";
import { getCompany, resetGetCompany } from "../../actions/company/info";
import { getCompanyMetering } from "../../actions/company/infoMetering";

import { listUsers } from "../../actions/user/read";
import { fetchActiveServices } from "../../actions/company/services";
import { MeteringPackageInfo } from "../company/metering";

// import { getPrivacyData } from "../../actions/certification";
import { getImports, getTotalImports } from "../../actions/usage/lynfa";
import { fetchActiveAgyoServices } from "../../actions/services/list";

import { getNotifications } from "../../actions/company/notifications";

import { findOwnManagedConnections, findOwnManagerConnections } from "../../actions/connections/read";
import SignatureInfoView from "../../views/signature-info";
import CompanyTsPaySeriveView from "../../views/company/ts-pay-service";

class CompanyView extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        contract: PropTypes.object,
        cct: PropTypes.object,
        fetchActiveServices: PropTypes.func.isRequired,
        fetchActiveAgyoServices: PropTypes.func.isRequired,
        getCompany: PropTypes.func.isRequired,
        getCompanyMetering: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        resetGetCompany: PropTypes.func.isRequired,
        getImports: PropTypes.func.isRequired,
        getTotalImports: PropTypes.func.isRequired,
        findOwnManagedConnections: PropTypes.func.isRequired,
        findOwnManagerConnections: PropTypes.func.isRequired,
        getNotifications: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { company, match, getImports, auth, getTotalImports } = this.props;
        if (!company.info.base && match.params.companyId) {
            this.handleReloadItemData(match.params.companyId);
        } else {
            getImports(auth.loginAuth, company.info.base.id);
            getTotalImports(auth.loginAuth, company.info.base.id);
        }
    }

    async handleReloadItemData(itemId) {
        const {
            auth,
            fetchActiveAgyoServices,
            fetchActiveServices,
            getCompany,
            listUsers,
            getImports,
            getTotalImports,
            getCompanyMetering,
            findOwnManagedConnections,
            findOwnManagerConnections,
            getNotifications
        } = this.props;
        const item = await getCompany(auth.loginAuth, itemId, "FULL");
        if (item) {
            let vatNumber = item.item.base.identifier.vatNumber;
            let uuid = item.item.base.uuid;
            let params = { page: 0, items: 10 };
            let meteringDetails = await getCompanyMetering(auth.loginAuth, item.item.base.id);
            if (!meteringDetails && itemId !== vatNumber) getCompanyMetering(auth.loginAuth, vatNumber);
            findOwnManagedConnections(auth.loginAuth, { managerIds: uuid, active: true, deleted: false }, params);
            findOwnManagerConnections(auth.loginAuth, { managedIds: uuid, active: true, deleted: false }, params);
            getNotifications(auth.loginAuth, itemId, { page: 0 });
            await fetchActiveAgyoServices(auth.loginAuth);
            await fetchActiveServices(auth.loginAuth, itemId);
            listUsers(auth.loginAuth, uuid);
            getImports(auth.loginAuth, itemId);
            getTotalImports(auth.loginAuth, itemId);
        }
    }

    render() {
        const { company, history, resetGetCompany, cct } = this.props;
        let matchPath = "/item/:companyId";

        return company.info.base && !history.location.pathname.includes("create") ? (
            <div className="c-companyview">
                <ViewTitle>
                    <FormattedMessage id="general.companyInfo" />
                </ViewTitle>
                <Row gutter={12}>
                    <Col span={isTablet ? 24 : 18} push={isTablet ? 0 : 6}>
                        <Switch>
                            <Route exact path={`${matchPath}/contract/:id`} component={CompanyContactView} />
                            <Route exact path={`${matchPath}/edit`} component={CompanyEditView} />
                            <Route exact path={`${matchPath}/orders`} component={CompanyordersView} />
                            <Route exact path={`${matchPath}/orders/:type`} component={CompanyordersView} />
                            <Route exact path={`${matchPath}/invoices`} component={CompanyInvoicesView} />
                            <Route exact path={`${matchPath}/obligations-tax`} component={CompanyObligationsView} />
                            <Route exact path={`${matchPath}/signature`} component={CompanySignatureView} />
                            <Route exact path={`${matchPath}/signature/:hubId`} component={SignatureInfoView} />
                            <Route exact path={`${matchPath}/dbx`} component={CompanyDbxView} />
                            <Route exact path={`${matchPath}/invoices/:type`} component={CompanyInvoicesView} />
                            <Route exact path={`${matchPath}/invoices/:hubId`} component={InvoiceInfoView} />
                            <Route exact path={`${matchPath}/invoices/received/:hubId`} component={InvoiceInfoView} />
                            <Route exact path={`${matchPath}/invoices/send/:hubId`} component={InvoiceInfoView} />
                            <Route exact path={`${matchPath}/PRIVACY-FLOW`} component={PrivacyServiceView} />
                            <Route exact path={`${matchPath}/SIGNATURE-FLOW`} component={SignatureServiceView} />
                            <Route exact path={`${matchPath}/A-FISCALI-IVA-FLOW`} component={AFiscaliServiceView} />
                            <Route exact path={`${matchPath}/B2B-PASSIVE-FLOW`} component={B2bPassiveServiceView} />
                            <Route exact path={`${matchPath}/GDPR-LOG`} component={GdprLogServiceView} />
                            <Route exact path={`${matchPath}/SDI-FLOW`} component={SdiServiceView} />
                            <Route exact path={`${matchPath}/DIGITALBOX`} component={DigitalboxServiceView} />
                            <Route exact path={`${matchPath}/CCT-FLOW`} component={CctFlowView} />
                            <Route exact path={`${matchPath}/ARC`} component={ArcFlowView} />
                            <Route exact path={`${matchPath}/EIT`} component={CompanyInvoiceTradingView} />
                            <Route exact path={`${matchPath}/metering/:family/info`} component={MeteringPackageInfo} />
                            <Route exact path={`${matchPath}/IMPORT-FIRST-NOTE`} component={MeteringLynfaTabView} />
                            <Route exact path={`${matchPath}/item-spid`} component={ItemSpidView} />
                            <Route
                                exact
                                path={`${matchPath}/ELIGIBLE-INVOICE`}
                                component={CompanyEligibleInvoiceView}
                            />
                            <Route exact path={`${matchPath}/ERECEIPTS`} component={CompanyEReceiptView} />
                            <Route exact path={`${matchPath}/BANK-LINK`} component={CompanyBankLinkView} />
                            <Route exact path={`${matchPath}/payment`} component={PaymentView} />
                            <Route exact path={`${matchPath}/BANK-LINK/:hubId`} component={BankLinkInfoView} />
                            <Route exact path={`${matchPath}/TSPAY`} component={CompanyTsPaySeriveView} />

                            <Route
                                exact
                                path={`${matchPath}/bank-link/bank-link-files/bank-link-rules/:hubId/:description/:abi`}
                                component={BankLinkRuleView}
                            />
                            <Route
                                exact
                                path={`${matchPath}/bank-link/bank-link-files/:hubId`}
                                component={BankLinkFileTransactionsView}
                            />
                            <Route
                                exact
                                path={[
                                    `${matchPath}/dashboard`,
                                    `${matchPath}/services`,
                                    `${matchPath}/metering`,
                                    `${matchPath}/users`,
                                    `${matchPath}/layers`,
                                    `${matchPath}/connections`,
                                    `${matchPath}/documents`,
                                    `${matchPath}/companyncs`
                                ]}
                                component={CompanyDataView}
                            />
                            <Route exact path={`${matchPath}/:serviceId`} component={CompanyDefaultSerivesView} />
                        </Switch>
                    </Col>
                    <Col span={isTablet ? 24 : 6} pull={isTablet ? 0 : 18}>
                        <Card>
                            {company.info.base && (
                                <CompanyInfo
                                    cct={cct}
                                    company={company}
                                    onlyInfo={false}
                                    reloadItem={val => this.handleReloadItemData(val)}
                                    resetGetCompany={resetGetCompany}
                                />
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        ) : (
            !history.location.pathname.includes("create") && (
                <SpinContainer>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                </SpinContainer>
            )
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    contract: state.contract,
    cct: state.cct
});

const actions = {
    listUsers,
    fetchActiveAgyoServices,
    fetchActiveServices,
    getCompany,
    getCompanyMetering,
    // getCctProvisioningRequest,
    resetGetCompany,
    getImports,
    getTotalImports,
    findOwnManagedConnections,
    findOwnManagerConnections,
    getNotifications
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyView);
