import styled from "styled-components";

export const CertificationSectionTitle = styled.h1`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
`;

export const InnerCertificationSectionTitle = styled.div`
    float: right;
    width: 35%;
`;

export const CardTsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const CertificationViewBlock = styled.div`
    margin-bottom: 50px;
`;
