import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Badge } from "antd";
import {
    translateServiceName,
    translateConnectionStatus,
    translateConnectionActive
} from "../../../../utils/translate";

export const CCTManagedExpanded = ({ dataSource }) => {
    const columns = [
        {
            title: <FormattedMessage id="service" />,
            dataIndex: "serviceId",
            key: "serviceId",
            render: text => translateServiceName(text)
        },
        {
            title: <FormattedMessage id="general.active" />,
            dataIndex: "status.active",
            key: "status.active",
            render: text => (
                <span>
                    <Badge
                        status={translateConnectionActive(text).status}
                        text={translateConnectionActive(text).title}
                    />
                </span>
            )
        },
        {
            title: <FormattedMessage id="general.status" />,
            dataIndex: "status.status",
            key: "status.status",
            render: text => (
                <Badge status={translateConnectionStatus(text).status} text={translateConnectionStatus(text).title} />
            )
        }
    ];

    return (
        <div>
            <Table
                rowKey={(record, index) => index}
                dataSource={dataSource}
                columns={columns}
                size="small"
                pagination={false}
                align="left"
            />
        </div>
    );
};

CCTManagedExpanded.propTypes = {
    dataSource: PropTypes.array.isRequired
};
