import React from "react";
import PropTypes from "prop-types";

import TsCard from "../general/ts-card";
import { FormattedMessage } from "react-intl";
import {
    CertificationSectionTitle,
    CardTsWrapper,
    CertificationViewBlock,
    InnerCertificationSectionTitle
} from "./styled.js";
import CctProvisioningSearch from "../tool/cctProvisioning-search";

import { userIsAuthorized } from "../../utils/get-roles";
import { useFeature } from "../../utils/hook/use-feature";

const CctProvisioning = ({ history, admin }) => {
    const newArchiveEnabled = useFeature("New_Archive");

    return (
        <div>
            {newArchiveEnabled &&
            userIsAuthorized("c-validate-cct", admin.adminInfo.permits) &&
            userIsAuthorized("c-cct-contract-rejected", admin.adminInfo.permits) &&
            userIsAuthorized("c-cct-contract-validated", admin.adminInfo.permits) ? (
                <CertificationViewBlock>
                    <CertificationSectionTitle>
                        <FormattedMessage id="c-new-archive.section-title" />
                    </CertificationSectionTitle>

                    <CardTsWrapper className="content">
                        {userIsAuthorized("c-validate-cct", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-new-archive.inzialized-title" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-new-archive.inzialized-description" />}
                                action={() => history.push("/cct-new-archive/list/INITIALIZED")}
                            />
                        )}

                        {userIsAuthorized("c-validate-cct", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-new-archive.tobecertified-title" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-new-archive.tobecertified-description" />}
                                action={() => history.push("/cct-new-archive/list/TOBE_CERTIFIED")}
                            />
                        )}

                        {userIsAuthorized("c-cct-contract-rejected", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-new-archive.rejected-title" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-new-archive.rejected-description" />}
                                action={() => history.push("/cct-new-archive/list/REJECTED")}
                            />
                        )}
                    </CardTsWrapper>
                </CertificationViewBlock>
            ) : null}

            {userIsAuthorized("c-validate-cct", admin.adminInfo.permits) &&
            userIsAuthorized("c-cct-contract-rejected", admin.adminInfo.permits) &&
            userIsAuthorized("c-cct-contract-validated", admin.adminInfo.permits) ? (
                <CertificationViewBlock>
                    <CertificationSectionTitle>
                        CCT - Utenti{" "}
                        <InnerCertificationSectionTitle>
                            <CctProvisioningSearch history={history} />
                        </InnerCertificationSectionTitle>
                    </CertificationSectionTitle>

                    <CardTsWrapper className="content">
                        {userIsAuthorized("c-validate-cct", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.validate-cct" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-certifications.validate-cct-desc" />}
                                action={() => history.push("/cct-provisioning/list/uploaded")}
                            />
                        )}

                        {userIsAuthorized("c-cct-contract-rejected", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-rejected" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-certifications.cct-contract-rejected-desc" />}
                                action={() => history.push("/cct-provisioning/list/rejected")}
                            />
                        )}
                        {userIsAuthorized("c-cct-contract-validated", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-validated" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-certifications.cct-contract-validated-desc" />}
                                action={() => history.push("/cct-provisioning/list/validated")}
                            />
                        )}
                        {userIsAuthorized("c-cct-contract-suspended", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-suspended" />}
                                icon="fas fa-archive"
                                description={<FormattedMessage id="c-certifications.cct-contract-suspended-desc" />}
                                action={() => history.push("/cct-provisioning/list/suspended")}
                            />
                        )}
                    </CardTsWrapper>
                </CertificationViewBlock>
            ) : null}

            {userIsAuthorized("c-validate-cct", admin.adminInfo.permits) &&
            userIsAuthorized("c-cct-contract-rejected", admin.adminInfo.permits) &&
            userIsAuthorized("c-cct-contract-validated", admin.adminInfo.permits) ? (
                <CertificationViewBlock>
                    <CertificationSectionTitle>CCT - Imprese verdi</CertificationSectionTitle>
                    <CardTsWrapper className="content">
                        {userIsAuthorized("c-cct-contract-uploaded", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-uploaded" />}
                                icon="fas fa-leaf"
                                description={<FormattedMessage id="c-certifications.cct-contract-uploaded-desc" />}
                                action={() => history.push("/cct-provisioning/list/uploaded?greenCompanies=true")}
                            />
                        )}
                        {userIsAuthorized("c-cct-contract-rejected", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-rejected" />}
                                icon="fas fa-leaf"
                                description={<FormattedMessage id="c-certifications.cct-contract-rejected-desc" />}
                                action={() => history.push("/cct-provisioning/list/rejected?greenCompanies=true")}
                            />
                        )}
                        {userIsAuthorized("c-cct-contract-validated", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-validated" />}
                                icon="fas fa-leaf"
                                description={<FormattedMessage id="c-certifications.cct-contract-validated-desc" />}
                                action={() => history.push("/cct-provisioning/list/validated?greenCompanies=true")}
                            />
                        )}
                        {userIsAuthorized("c-cct-contract-suspended", admin.adminInfo.permits) && (
                            <TsCard
                                title={<FormattedMessage id="c-certifications.cct-contract-suspended" />}
                                icon="fas fa-leaf"
                                description={<FormattedMessage id="c-certifications.cct-contract-suspended-desc" />}
                                action={() => history.push("/cct-provisioning/list/suspended?greenCompanies=true")}
                            />
                        )}
                    </CardTsWrapper>
                </CertificationViewBlock>
            ) : null}
        </div>
    );
};
CctProvisioning.propTypes = {
    admin: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};
export default CctProvisioning;
