import { combineReducers } from "redux";

import { findOwnManagedConnections, findOwnManagerConnections } from "./read";
import { deleteLink } from "./write";

const read = combineReducers({
    findOwnManagedConnections,
    findOwnManagerConnections
});

const write = combineReducers({
    deleteLink
});

export default combineReducers({ read, write });
