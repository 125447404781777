import React, { Component } from "react";
import PropTypes from "prop-types";
import { isUUID } from "validator";

import "./style.css";

import { Link } from "react-router-dom";
import { Button, Card, Input, List, Spin, Avatar, Badge } from "antd";

import CompanyStatusTag from "../general/tags/company-status";
import { translateClassifier, getIconClassifier } from "../../utils/translate";
import { userIsAuthorized } from "../../utils/get-roles";
import { ViewTitle } from "../../styled";
import { FormattedMessage } from "react-intl";

const Search = Input.Search;
const locale = {
    emptyText: " "
};

class Items extends Component {
    constructor() {
        super();
        this.state = {
            noResult: false
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        admin: PropTypes.object.isRequired,
        company: PropTypes.object,
        displayErrorToast: PropTypes.func.isRequired,
        fetchActiveServices: PropTypes.func.isRequired,
        findItems: PropTypes.func.isRequired,
        findOwnManagedConnections: PropTypes.func.isRequired,
        findOwnManagerConnections: PropTypes.func.isRequired,
        getCompany: PropTypes.func.isRequired,
        getCompanyMetering: PropTypes.func.isRequired,
        getNotifications: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        listUsers: PropTypes.func.isRequired,
        resetGetCompany: PropTypes.func.isRequired,
        getImports: PropTypes.func.isRequired,
        getTotalImports: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.itemId) {
            this.doSearch(history.location.state.itemId);
        }
    }

    doSearch(itemSearched) {
        const { auth, displayErrorToast, findItems, getCompany, admin } = this.props;
        if (itemSearched.length >= 4) {
            if (isUUID(itemSearched)) {
                this.getCompany(itemSearched);
            } else {
                if (userIsAuthorized("c-dashboard-items", admin.adminInfo.permits)) {
                    findItems(auth.loginAuth, itemSearched, "BASE").then(res => {
                        if (res && res.items.length === 1) {
                            let company = res.items[0].item;
                            this.getCompany(company.base.id, company.base.identifier.vatNumber);
                            this.setState({ noResult: false });
                        }

                        if (res && res.items.length === 0) {
                            this.setState({ noResult: true });
                        }

                        if (res && res.items.length > 1) {
                            this.setState({ noResult: false });
                        }
                    });
                } else {
                    getCompany(auth.loginAuth, itemSearched, "BASE").then(res => {
                        if (res && res.item) {
                            let company = res.item;
                            this.getCompany(company.base.id, company.base.identifier.vatNumber);
                            this.setState({ noResult: false });
                        } else {
                            this.setState({ noResult: true });
                        }
                    });
                }
            }
        } else {
            displayErrorToast("Il valore di ricerca deve almeno essere di 4 caratteri", true);
        }
    }

    async getCompany(companyId, vatNumber) {
        const {
            auth,
            fetchActiveServices,
            getCompany,
            getCompanyMetering,
            getNotifications,
            findOwnManagedConnections,
            findOwnManagerConnections,
            listUsers,
            history,
            resetGetCompany,
            getImports,
            getTotalImports
        } = this.props;
        this.setState({ isMoreRes: false });
        resetGetCompany();
        let itemDetails = await getCompany(auth.loginAuth, companyId, "FULL");
        if (itemDetails) {
            let id = itemDetails.item.base.id;
            let uuid = itemDetails.item.base.uuid;
            history.push(`/item/${uuid}/dashboard`);
            let params = { page: 0, items: 10 };
            let meteringDetails = getCompanyMetering(auth.loginAuth, id);
            if (!meteringDetails && id !== vatNumber) getCompanyMetering(auth.loginAuth, vatNumber);
            findOwnManagedConnections(auth.loginAuth, { managerIds: uuid, active: true, deleted: false }, params);
            findOwnManagerConnections(auth.loginAuth, { managedIds: uuid, active: true, deleted: false }, params);
            fetchActiveServices(auth.loginAuth, uuid);
            listUsers(auth.loginAuth, uuid);
            getNotifications(auth.loginAuth, id, { page: 0 });
            getImports(auth.loginAuth, id);
            getTotalImports(auth.loginAuth, id);
        } else {
            this.setState({ noResult: true });
        }
    }

    renderMoreThanOneResult(company) {
        return (
            company.find.items &&
            company.find.items.length > 1 && (
                <List
                    itemLayout="horizontal"
                    dataSource={company.find.items}
                    locale={locale}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Button onClick={() => this.getCompany(item.item.base.id)}>{"Visualizza"}</Button>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    <Badge
                                        count={
                                            <i
                                                className="fas fa-circle avatar-status"
                                                style={{
                                                    color:
                                                        item.item.base.status.active &&
                                                        item.item.base.status.status === "VALIDATED"
                                                            ? "#52c41a"
                                                            : item.item.base.status.deleted
                                                            ? "#f44336"
                                                            : "#FFC107"
                                                }}
                                            />
                                        }
                                    >
                                        <Avatar className="avatar">
                                            <i className={getIconClassifier(item.item.base.details.classifier)} />
                                        </Avatar>
                                    </Badge>
                                }
                                title={
                                    <div>
                                        <span className="title">
                                            {item.item.base.details.classifier === "PERSON"
                                                ? item.item.base.details.firstName +
                                                  " " +
                                                  item.item.base.details.lastName
                                                : item.item.base.details.description}
                                        </span>
                                        <CompanyStatusTag
                                            companyStatus={
                                                item.item.base.status.certificationStatus
                                                    ? item.item.base.status.certificationStatus
                                                    : item.item.base.status.status
                                            }
                                        />
                                    </div>
                                }
                                description={
                                    <div>
                                        <span>Tipologia: {translateClassifier(item.item.base.details.classifier)}</span>
                                        <br />
                                        <span>Codice Fiscale: {item.item.base.identifier.taxId}</span>
                                        <br />
                                        <span>Partita Iva: {item.item.base.identifier.vatNumber}</span>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            )
        );
    }

    render() {
        const { company } = this.props;
        const { noResult } = this.state;
        return (
            <div>
                <ViewTitle>
                    <FormattedMessage id="general.searchItems" />
                </ViewTitle>
                <Card className="c-items">
                    <div className="search-box">
                        <span className="text-search">{"Ricerca azienda"}</span>
                        <Search
                            placeholder={"Inserisci almeno 4 caratteri per iniziare la ricerca tra le aziende."}
                            onSearch={val => this.doSearch(val.trim())}
                            enterButton
                        />
                    </div>

                    {company.find.status.started ? (
                        <div className="spinner">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div>
                            {noResult ? (
                                <div className="noResult">
                                    <br />
                                    <p>{"Nessuna azienda trovata"}</p>
                                    <Link to="/item/create">
                                        <Button type="primary" ghost>
                                            Crea azienda
                                        </Button>
                                    </Link>
                                </div>
                            ) : company.info.status.started ? (
                                <div className="spinner">
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div>{this.renderMoreThanOneResult(company)}</div>
                            )}
                        </div>
                    )}
                </Card>
            </div>
        );
    }
}

export default Items;
