import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { Button } from "@mondora/vrc";

const TsModal = ({
    onCancel,
    onOk,
    onCancelDisabled,
    onOkDisabled,
    cancelTextId,
    okTextId,
    visible,
    title,
    children,
    width
}) => (
    <Modal
        onCancel={onCancel}
        title={title}
        visible={visible}
        centered
        footer={[
            cancelTextId && (
                <Button kind="secondary" size="small" key="cancel" onClick={onCancel} disabled={onCancelDisabled}>
                    <FormattedMessage id={cancelTextId} />
                </Button>
            ),
            okTextId && (
                <Button kind="primary" size="small" key="ok" onClick={onOk} disabled={onOkDisabled}>
                    <FormattedMessage id={okTextId} />
                </Button>
            )
        ]}
        width={width}
    >
        {children}
    </Modal>
);

TsModal.propTypes = {
    cancelTextId: PropTypes.string,
    children: PropTypes.any,
    okTextId: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onCancelDisabled: PropTypes.bool,
    onOk: PropTypes.func,
    onOkDisabled: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    title: PropTypes.object,
    width: PropTypes.string
};

export default TsModal;
