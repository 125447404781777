import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";

import { CompanyOrders } from "../../../components/company/orders";
import { getOrders } from "../../../actions/search/order";
import { serviceDecode } from "../../../utils/decode-services";
import { Avatar } from "antd";
import { Container, TitleContainer, TitleAction } from "./styled";

const CompanyOrdersView = ({ admin, auth, company, history, orders, getOrders }) => {
    return (
        <Container>
            <TitleContainer>
                <div>
                    <Avatar
                        style={{
                            background: serviceDecode("ARC").background,
                            color: serviceDecode("ARC").color,
                            border: "1px solid" + serviceDecode("ARC").color,
                            marginRight: "8px"
                        }}
                    >
                        <i className={serviceDecode("ARC").icon} />
                    </Avatar>
                    <span className="a1">{"Ordini"}</span>
                    <span className="a1">{" (Gestione Ordini)"}</span>
                </div>
                <TitleAction onClick={() => history.push(`/item/${company.info.base.uuid}/dashboard`)}>
                    <i className="far fa-times-circle" />
                </TitleAction>
            </TitleContainer>
            <div style={{ marginTop: "20px" }}>
                <CompanyOrders
                    admin={admin}
                    auth={auth}
                    company={company}
                    history={history}
                    orders={orders}
                    getOrders={getOrders}
                />
            </div>
        </Container>
    );
};

CompanyOrdersView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getOrders: PropTypes.func.isRequired,
    orders: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    orders: state.order.read.getOrders
});

const actions = {
    getOrders
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyOrdersView);
