import React from "react";
import { Skeleton } from "antd";
import ContractInfoCompany from "./company";
import ContractInfoConnection from "./connection";
import PropTypes from "prop-types";

const ContractInfo = ({ loading, type, data, info }) => {
    if (loading) {
        return <Skeleton active={true} />;
    }
    if (type === "CONNECTION") {
        return <ContractInfoConnection data={data} info={info} />;
    }

    return <ContractInfoCompany data={data} info={info} />;
};

ContractInfo.propTypes = {
    loading: PropTypes.bool.isRequired,
    type: PropTypes.string,
    data: PropTypes.object,
    info: PropTypes.object
};

export default ContractInfo;
