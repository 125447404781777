import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { restrictToPermits } from "../../../components/general/hoc";
import { errorBoundary } from "../../../components/general/error-boundary";
import { FormattedMessage } from "react-intl";

import { Searchbar } from "@mondora/arc/antd/Searchbar";

import SpidListComponent from "../../../components/spid/list";

import { getSpid, resetSpid } from "../../../actions/spid";

import { SearchBarContainer, SectionHeaderContainer, SpidSectionTitle } from "./styled";

const SpidList = ({ admin, auth, getSpid, history, resetSpid, spid }) => {
    const [filters, setFilters] = useState({ page: 1, pageSize: 10 });

    useEffect(() => {
        resetSpid();
    }, [resetSpid]);

    const handleFilter = (filterValue, filterField) => {
        let newFilters = { ...filters };
        newFilters[filterField] = filterValue;

        if (filterField !== "page") {
            newFilters["page"] = 1;
        }

        setFilters(newFilters);
        if (newFilters.itemId && newFilters.itemId !== "") {
            getSpid(auth.loginAuth, newFilters);
        }
    };

    return (
        <div>
            <SectionHeaderContainer>
                <SpidSectionTitle>
                    <FormattedMessage id="c-spid.spid-list.view-title" />
                </SpidSectionTitle>

                <SearchBarContainer>
                    <Searchbar
                        placeholder={<FormattedMessage id="c-spid.spid-list.item-search" />}
                        onSearch={v => handleFilter(v.value.trim(), "itemId")}
                        enterButton
                    />
                </SearchBarContainer>
            </SectionHeaderContainer>
            <SpidListComponent
                admin={admin}
                history={history}
                spidList={spid.spidlist.payload && spid.spidlist.payload.content}
                handleFilter={handleFilter}
                pagination={{
                    currentPage: filters.page,
                    totalElements: spid.spidlist.payload && spid.spidlist.payload.totalElements
                }}
                loading={spid.spidlist.status.started}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    spid: state.spid
});

const actions = { getSpid, resetSpid };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-sideMenu-spid")
);
export default composedHoc(SpidList);
