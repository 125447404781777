//AUTH
export const AUTH_REST_URL = process.env.REACT_APP_AUTH_REST_URL || "http://localhost";
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const API_CAPTCHA_SIGNUP_URL = process.env.REACT_APP_API_CAPTCHA_SIGNUP_URL;
export const COMMON_LOGIN_URL = process.env.REACT_APP_COMMON_LOGIN_URL || "http://localhost:3001";

//ANAGRAFICA
export const REGISTRY_BO_READ_URL = process.env.REACT_APP_REGISTRY_BO_READ_URL || "http://localhost";
export const REGISTRY_READ_URL = process.env.REACT_APP_REGISTRY_READ_URL || "http://localhost";
export const REGISTRY_WRITE_URL = process.env.REACT_APP_REGISTRY_WRITE_URL || "http://localhost";
export const REGISTRY_CORRECTOR_URL = process.env.REACT_APP_REGISTRY_CORRECTOR_URL || "http://localhost";

export const COMPANY_CERTIFICATION_URL = process.env.REACT_APP_COMPANY_CERTIFICATION_URL || "http://localhost";
export const COMPANY_CERTIFICATION_DOCUMENT_URL =
    process.env.REACT_APP_COMPANY_CERTIFICATION_DOCUMENT_URL || "http://localhost";

// USER
export const USER_READ = process.env.REACT_APP_USER_READ || "http://localhost";
export const USER_WRITE = process.env.REACT_APP_USER_WRITE || "http://localhost";

export const WEBHOOK_URL = process.env.REACT_APP_WEBHOOK_URL || "http://localhost";

//FATTURAZIONE
export const B2B_READ_API_URL = process.env.REACT_APP_B2B_READ_API_URL || "http://localhost";
export const B2B_WRITE_API_URL = process.env.REACT_APP_B2B_WRITE_API_URL || "http://localhost";
export const INVOICES_URL = process.env.REACT_APP_INVOICES || "http://localhost";

//ADEMPIMENTI
export const OBLIGATIONS_TAX_URL = process.env.REACT_APP_OBLIGATIONS_TAX_URL || "http://localhost";
//FIRMA
export const SIGNATURE_URL = process.env.REACT_APP_SIGNATURE_URL || "https://signature-read-api-dev.agyo.io/api/v1";
//SERVIZI
export const SERVICES_SUBSCRIPTION_URL = process.env.REACT_APP_SERVICES_SUBSCRIPTION_URL || "http://localhost";
export const SERVICE_SUBSCRIPTION_API_URL = process.env.REACT_APP_SERVICE_SUBSCRIPTION_API_URL || "http://localhost";
export const A_FISCALI_IVA_CONFIG = process.env.REACT_APP_A_FISCALI_IVA_CONFIG || "http://localhost";
export const SIGNATURE_CONFIG = process.env.REACT_APP_SIGNATURE_CONFIG || "http://localhost";
export const DIGITALBOX_CONFIG = process.env.REACT_APP_DIGITALBOX_CONFIG || "http://localhost";
export const B2B_PASSIVE_CONFIG = process.env.REACT_APP_B2B_PASSIVE_CONFIG || "http://localhost";
export const SDI_CONFIG = process.env.REACT_APP_SDI_CONFIG || "http://localhost";
export const SIGNER_READ_URL = process.env.REACT_APP_SIGNER_READ_URL || "http://localhost";
export const SIGNER_WRITE_URL = process.env.REACT_APP_SIGNER_WRITE_URL || "http://localhost";
export const GDPR_LOG_URL = process.env.REACT_APP_GDPR_LOG_URL || "http://localhost";

//CONNESSIONI
export const CONNECTION_READ_API = process.env.REACT_APP_CONNECTION_READ_API || "http://localhost";
export const CONNECTION_WRITE_API = process.env.REACT_APP_CONNECTION_WRITE_API || "http://localhost";
export const NOTIFICATION_READ_URL = process.env.REACT_APP_NOTIFICATION_READ_URL || "http://localhost";

//HOME
export const UPTIME_ROBOT_URL = process.env.REACT_APP_UPTIME_ROBOT_URL || "http://localhost";
export const BUS_MONITOR_URL = process.env.REACT_APP_BUS_MONITOR_URL || "http://localhost";
export const CERVED_URL = process.env.REACT_APP_CERVED_URL || "http://localhost";
export const ELASTIC_INVOICE_URL = process.env.REACT_APP_ELASTIC_INVOICE_URL || "http://localhost";
export const ELASTIC_TSID_URL = process.env.REACT_APP_ELASTIC_TSID_URL || "http://localhost";
export const AZURE_STATUS_PAGE_URL = process.env.REACT_APP_AZURE_STATUS_URL || "http://localhost";

// FEATURE FLAG
export const ENV = process.env.REACT_APP_ENV || "dev";
export const BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME || "dev";
export const DOMAIN = process.env.REACT_APP_DOMAIN || "-dev.agyo.io";

//BO
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost";

//USAGE
export const METERING_USAGE_PRIVACY = process.env.REACT_APP_METERING_USAGE_PRIVACY || "http://localhost";
export const METERING_USAGE_INVOICING = process.env.REACT_APP_METERING_USAGE_INVOICING || "http://localhost";
export const METERING_USAGE = process.env.REACT_APP_METERING_USAGE || "http://localhost";
export const METERING_READ_API_URL = process.env.REACT_APP_METERING_READ_API_URL || "http://localhost";
export const METERING_PACKAGE_READ_API_URL = process.env.REACT_APP_METERING_PACKAGE_READ_API_URL || "http://localhost";
export const METERING_EXTENSIONS_API_URL = process.env.REACT_APP_METERING_EXTENSIONS_API_URL || "http://localhost";
export const METERING_REALTIME_API_URL = process.env.REACT_APP_METERING_REALTIME_API_URL || "http://localhost";
export const METERING_LYNFA_IMPORT_API_URL = process.env.REACT_APP_METERING_LYNFA_IMPORT_API_URL || "http://localhost";

//CCT
export const LCA_CCT_PROVISIONING_API_URL = process.env.REACT_APP_LCA_CCT_PROVISIONING_API_URL || "http://localhost";
export const CCT_CONFIG_API_URL = process.env.REACT_APP_CCT_CONFIG_API_URL || "http://localhost";
// IMAGES
export const LOGO_TS = "https://b2bstaticdev.blob.core.windows.net/static-apps/images/logo-ts.png";
export const LOGO_COLOR = "https://b2bstaticdev.blob.core.windows.net/static-apps/images/ts-digital/logo-color.svg";
export const LOGO_TS_DIGITAL = "https://b2bstaticdev.blob.core.windows.net/static-apps/images/TSDigital_logo.png";
//METERING PEOPLEAPP
export const METERING_PEOPLEAPP_URL = process.env.REACT_APP_METERING_PEOPLEAPP || "http://localhost";

//SIGNATURE
export const SIGNATURE_READ_URL = process.env.REACT_APP_SIGNATURE_READ_URL || "http://localhost";
export const SIGNATURE_WRITE_URL = process.env.REACT_APP_SIGNATURE_WRITE_URL || "http://localhost";
export const SIGNER_V2_READ_URL = process.env.REACT_APP_SIGNER_V2_READ_URL || "http://localhost";
export const SIGNER_V2_WRITE_URL = process.env.REACT_APP_SIGNER_V2_WRITE_URL || "http://localhost";
export const DIGITAL_SIGNER_READ_URL = process.env.REACT_APP_DIGITAL_SIGNER_READ_URL || "http://localhost";

export const CONTRACT_API_URL = process.env.REACT_APP_CONTRACT_API_URL || "http://localhost";

//WAITING_LIST
export const WAITING_LIST_READ_URL = process.env.REACT_APP_WAITING_LIST_READ_URL || "http://localhost";

//OTC_INVOICE_TRADING
export const OTC_INVOICE_TRADING_API_URL = process.env.REACT_APP_OTC_INVOICE_TRADING_API_URL || "http://localhost";

//DIGITALBOX
export const DIGITALBOX_BATCH_API_URL = process.env.REACT_APP_DIGITALBOX_BATCH_API_URL || "http://localhost";

//USER_CONSENT
export const USER_CONSENT_READ_API_URL = process.env.REACT_APP_USER_CONSENT_READ_API_URL || "http://localhost";

//TS_PAY
export const TS_PAY_CONFIG_READ_API_URL = process.env.REACT_APP_TS_PAY_CONFIG_READ_API_URL || "http://localhost";

//METERING
export const METERING_URL = process.env.REACT_APP_METERING || "http://localhost";

//REPORT IV
export const REPORT_URL = process.env.REACT_APP_DOWNLOAD_REPORT || "http://localhost";
export const GENERATED_REPORT_URL =
    process.env.REACT_APP_ENV === "master" || process.env.REACT_APP_ENV === "preprod"
        ? process.env.REACT_APP_DOWNLOAD_GENERATED_REPORT
        : null;
export const GENERATED_SEMPLIFIED_REPORT_URL =
    process.env.REACT_APP_ENV === "master" || process.env.REACT_APP_ENV === "preprod"
        ? process.env.REACT_APP_DOWNLOAD_GENERATED_REPORT_SEMPLIFIED
        : null;

//METERING CONSUMPTION
export const METERING_ARCHIVER_READ_URL = process.env.REACT_APP_METERING_ARCHIVER_READ_URL || "http://localhost";

//PAYMENTS
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL || "http://localhost";

export const TSBACKOFFICE = process.env.REACT_APP_TSBACKOFFICE || "http://localhost";

//TSIHBO
export const TS_IH_BO_URL = process.env.REACT_APP_TS_IH_BO_URL || "http://localhost";
