import {
    GET_COMPANY_METERING_START,
    GET_COMPANY_METERING_ERROR,
    GET_COMPANY_METERING_SUCCESS
} from "../../actions/company/infoMetering";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function companyMetering(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_COMPANY_METERING_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_COMPANY_METERING_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        case GET_COMPANY_METERING_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };

        default:
            return state;
    }
}
