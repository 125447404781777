import {
    GET_METERING_LYNFA_IMPORT_START,
    GET_METERING_LYNFA_IMPORT_SUCCESS,
    GET_METERING_LYNFA_IMPORT_ERROR,
    GET_METERING_LYNFA_IMPORT_TOTAL_START,
    GET_METERING_LYNFA_IMPORT_TOTAL_SUCCESS,
    GET_METERING_LYNFA_IMPORT_TOTAL_ERROR
} from "../../actions/usage/lynfa";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const getImports = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_METERING_LYNFA_IMPORT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_METERING_LYNFA_IMPORT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_METERING_LYNFA_IMPORT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getTotalImports = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_METERING_LYNFA_IMPORT_TOTAL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_METERING_LYNFA_IMPORT_TOTAL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_METERING_LYNFA_IMPORT_TOTAL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
