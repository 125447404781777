import React from "react";
import PropTypes from "prop-types";
import { Divider } from "antd";

import { DashboardAlarm } from "./dashboard-alarm";
import { DashboardSummary } from "./dashboard-summary";

export const UserDashboard = ({ userInfo }) => {
    return (
        <div style={{ height: "70vh", overflow: "auto" }}>
            <div>
                <div style={{ display: "flex" }}>
                    <DashboardSummary userInfo={userInfo} />
                    <DashboardAlarm />
                </div>
                <Divider orientation="left" />
            </div>
        </div>
    );
};

UserDashboard.propTypes = {
    userInfo: PropTypes.object
};
