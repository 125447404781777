import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";

export const WaitingListTable = ({ dataSource, loading }) => {
    const columns = [
        {
            title: <FormattedMessage id="service" />,
            dataIndex: "serviceId"
        },
        {
            title: <FormattedMessage id="created_by" />,
            dataIndex: "created_by"
        },
        {
            title: <FormattedMessage id="created_on" />,
            dataIndex: "created_at",
            render: value => <span>{new Date(value).toLocaleDateString()}</span>
        },
        {
            title: <FormattedMessage id="status" />,
            dataIndex: "status"
        }
    ];

    return (
        <div style={{ backgroundColor: "white" }}>
            <Table
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                size="small"
                pagination={false}
                locale={{
                    emptyText: <FormattedMessage id="no_service" />
                }}
            />
        </div>
    );
};

WaitingListTable.propTypes = {
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};
