import React, { useState } from "react";
import { compose } from "redux";
import { form } from "@mondora/conv-redux-form";
import { injectIntl, FormattedMessage } from "react-intl";
import TextField from "@mondora/arc/antd/TextField";
import ISO8601DayField from "@mondora/arc/antd/ISO8601DayField";
import { Divider, Row, Col, Spin, message } from "antd";
import { Button } from "@mondora/arc/antd";

import { cedenteForm } from "../../../../utils/form-schema/cedente-prestatore";
import * as normalizeField from "../../../../utils/normalize-form";
import { countryValueWithCodice } from "@mondora/agyo-validations/lib/common/constants";
import { SelectStringField } from "@mondora/arc/antd/SelectField";
import { Field } from "redux-form";
import { CctForm, FormStatus, BoldSpan, Title } from "./styled";

import PropTypes from "prop-types";
import TsModal from "../../../general/ts-modal";

const SetCedenteForm = ({
    balanceSheetDateFormField,
    confirmModal,
    handleSubmit,
    history,
    companyData,
    updateItem,
    updateItemError,
    updateItemStarted,
    enableForm,
    setCedentePrestatoreError,
    setCedentePrestatoreEnded,
    setCedentePrestatoreStarted,
    setConfirmModal,
    formEnabled,
    handleCertify,
    intl
}) => {
    const [isSent, setIsSent] = useState(false);
    const [showCertifyModal, setShowCertifyModal] = useState(false);
    const [loadingCertify, setLoadingCertify] = useState(false);

    if (!companyData) {
        return null;
    }

    if (setCedentePrestatoreStarted || loadingCertify) {
        return (
            <FormStatus>
                <Spin tip={<FormattedMessage id="c-certifications.cct-sending-to-cct" />} />
            </FormStatus>
        );
    }

    const {
        item: {
            base: {
                id,
                details: {
                    description,
                    economics: { balanceSheetDate }
                }
            }
        }
    } = companyData;

    let disableField = !enableForm && balanceSheetDate === 0;

    const onCloseModal = () => {
        setConfirmModal(false);
        if (setCedentePrestatoreEnded) {
            setIsSent(true);
        }
    };

    const certify = async () => {
        setShowCertifyModal(false);
        setLoadingCertify(true);
        let result = await handleCertify();
        setLoadingCertify(false);
        if (result) {
            message.success(
                intl.formatMessage({
                    id: "c-certifications.cct-contract-certify-success"
                })
            );
            history.push("/cct-provisioning");
        } else {
            message.error(
                intl.formatMessage({
                    id: "c-certifications.cct-contract-certify-error"
                })
            );
        }
    };

    return (
        <div>
            <CctForm onSubmit={handleSubmit}>
                <Title>Upload Documento</Title>
                <div>
                    <BoldSpan>{description}</BoldSpan> - Codice fiscale: {id}
                </div>
                <Row gutter={12}>
                    <Col span={12}>
                        <ISO8601DayField
                            name="balanceSheetDate"
                            displayFormat="DD/MM/YYYY"
                            placeholder="Data di fine esercizio"
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            style={{ marginTop: "3px" }}
                            size="small"
                            variant="primary"
                            onClick={() => updateItem()}
                            disabled={updateItemStarted || !balanceSheetDateFormField}
                        >
                            {updateItemError ? "Riprova" : "Invia all'anagrafica"}
                        </Button>
                    </Col>
                </Row>
                <Divider />

                <br />
                <Row gutter={24}>
                    <Col span={24}>
                        <Title>
                            <FormattedMessage id="c-certifications.rc-info" />
                        </Title>
                    </Col>
                    <Col span={12}>
                        <TextField name="nome" placeholder="Nome" disabled={disableField} />
                    </Col>
                    <Col span={12}>
                        <TextField name="cognome" placeholder="Cognome" disabled={disableField} />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="cf"
                            placeholder="Codice fiscale"
                            disabled={disableField}
                            normalize={normalizeField.normalizeFiscalCode}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField name="luogoNascita" placeholder="Luogo di nascita" disabled={disableField} />
                    </Col>
                    <Col span={12}>
                        <ISO8601DayField
                            name="dataNascita"
                            displayFormat="DD/MM/YYYY"
                            placeholder="Data di nascita"
                            disabled={disableField}
                        />
                    </Col>
                    <Col span={10}>
                        <TextField name="email" placeholder="Mail" disabled={disableField} />
                    </Col>
                    <Col span={2}>
                        <BoldSpan> PEC </BoldSpan>
                        <Field name="isPec" id="isPec" component="input" type="checkbox" />
                    </Col>
                    <Col span={12}>
                        <SelectStringField
                            getOptionKeyFromValue={value => (value === "" ? undefined : value)}
                            label="Nazione"
                            name="nazione"
                            options={countryValueWithCodice.map(c => ({
                                value: c.id,
                                label: c.name,
                                searchMatcherString: c.name
                            }))}
                            showSearch={true}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectStringField
                            getOptionKeyFromValue={value => (value === "" ? undefined : value)}
                            label="Sesso"
                            name="sesso"
                            options={[{ value: "M", label: "Maschio" }, { value: "F", label: "Femmina" }]}
                        />
                    </Col>
                </Row>
                <Button
                    disabled={disableField || !formEnabled("cedente-data-form")}
                    style={{ marginTop: "3px", marginRight: "20px" }}
                    size="small"
                    variant="primary"
                    type="submit"
                >
                    Invia alla cct
                </Button>
                <Button
                    disabled={!isSent}
                    style={{ marginTop: "3px" }}
                    size="small"
                    variant="primary"
                    type="button"
                    onClick={() => setShowCertifyModal(true)}
                >
                    Certifica
                </Button>
            </CctForm>
            <TsModal
                title={<FormattedMessage id="c-certifications.modalTitle" />}
                visible={confirmModal}
                onCancel={() => onCloseModal()}
                cancelTextId="general.close"
            >
                <div>
                    {setCedentePrestatoreError && <FormattedMessage id="c-certifications.cct-contract-send-error" />}
                    {setCedentePrestatoreEnded && <FormattedMessage id="c-certifications.cct-contract-send-success" />}
                </div>
            </TsModal>

            <TsModal
                title={<FormattedMessage id="c-certifications.title" />}
                visible={showCertifyModal}
                onCancel={() => setShowCertifyModal(false)}
                cancelTextId="general.close"
                okTextId="general.certify"
                onOk={() => certify()}
            >
                <div>
                    <FormattedMessage id="c-certifications.modalCertify" />
                </div>
            </TsModal>
        </div>
    );
};

SetCedenteForm.propTypes = {
    handleSubmit: PropTypes.func,
    companyData: PropTypes.object,
    updateItem: PropTypes.func.isRequired,
    setCedentePrestatoreError: PropTypes.bool,
    setCedentePrestatoreEnded: PropTypes.bool,
    setCedentePrestatoreStarted: PropTypes.bool,
    updateItemError: PropTypes.bool,
    updateItemEnded: PropTypes.bool,
    updateItemStarted: PropTypes.bool,
    enableForm: PropTypes.bool,
    handleCertify: PropTypes.func.isRequired,
    intl: PropTypes.object
};

const composedHoc = compose(
    injectIntl,
    form(cedenteForm)
);

export default composedHoc(SetCedenteForm);
