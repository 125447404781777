import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { usePrevious } from "../../../utils/hook";

import { Input, Skeleton, Modal, Alert, Select } from "antd";
import { Button } from "@mondora/vrc";
import { ButtonWrapper, ButtonAction, ActionsWrapper, StatusWrapper } from "./styled";
import { InfoTitle } from "../contract-info/styled";
import { FormattedMessage, injectIntl } from "react-intl";
import Message from "./result-message";
import ItemPrivacyDataForm from "../../certifications/forms/privacy";

import { getContractPermits, userIsAuthorized } from "../../../utils/get-roles";
import { translateCertificationRejectOption } from "../../../utils/translate";
import { certificationRejectMessages } from "../../../utils/constant";

const { TextArea } = Input;
const { confirm } = Modal;
const { Option, OptGroup } = Select;

const ContractActions = ({
    admin,
    auth,
    data,
    formEnabled,
    type,
    info,
    id,
    loading,
    privacyPrefereces,
    reject,
    validate,
    validateStatus,
    rejectStatus,
    setPrivacy,
    history,
    showListCon,
    submitForm,
    pauseContract,
    pauseStatus,
    intl
}) => {
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState(null);
    const [showAction, setShowAction] = useState(true);
    const [actionPermits, setActionPermits] = useState({ reject: false, certify: false, readOnly: false });

    const prevValidateStatus = usePrevious(validateStatus);
    const prevRejectStatus = usePrevious(rejectStatus);
    const prevPauseStatus = usePrevious(pauseStatus);

    useEffect(() => {
        if (info) {
            if (
                userIsAuthorized("c-cert-reject-message", admin.adminInfo.permits) &&
                !certificationRejectMessages.map(message => message.group).includes("OTHER")
            ) {
                certificationRejectMessages.push({ group: "OTHER", type: "OTHER" });
            }
            info.message &&
                translateCertificationRejectOption(intl, info.message) === "OTHER" &&
                setMessageType("OTHER");
        }
    }, [info, admin, intl]);

    useEffect(() => {
        if (info) {
            let actions = getContractPermits(info, admin.adminInfo.permits, type, privacyPrefereces, data);
            setActionPermits(actions);
        }
    }, [info, admin, type, privacyPrefereces, setActionPermits, data]);

    const showValidate = name => {
        confirm({
            centered: true,
            title: "Attenzione!",
            content: intl.formatMessage({ id: "c-certifications.modal.validateConfirm" }, { name: name }),
            cancelText: "Indietro",
            onOk: () => {
                validate();
                setShowAction(false);
            }
        });
    };

    const showDeleteConfirm = name => {
        confirm({
            centered: true,
            title: "Attenzione!",
            content: intl.formatMessage({ id: "c-certifications.modal.rejectConfirm" }, { name: name }),
            okText: "Si",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                reject(
                    messageType === "OTHER"
                        ? `${intl.formatMessage({
                              id: `c-certifications.rejectMessage.${messageType}`
                          })}: ${message}`
                        : `${intl.formatMessage({
                              id: `c-certifications.rejectMessage.${messageType.split(".")[0]}`
                          })}: ${intl.formatMessage({
                              id: `c-certifications.rejectMessage.${messageType}`
                          })}`
                );
                setShowAction(false);
            }
        });
    };

    const showPauseConfirm = name => {
        confirm({
            centered: true,
            title: "Attenzione!",
            content: intl.formatMessage({ id: "c-certifications.modal.pausedConfirm" }, { name: name }),
            okText: "Si",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                setShowAction(false);
                pauseContract();
            }
        });
    };
    const savePrivacyData = async value => {
        await setPrivacy(auth.loginAuth, id, { ...value, documentDate: value.documentDate + "T00:00:00.000Z" });
    };

    if (loading) {
        return <Skeleton active />;
    }
    let isStudioOK = data && data.item && data.item.base.details.classifier === "STUDIO" && info.status === "CERTIFIED";

    const rejectOption = () => {
        let group = [];
        let result = [];
        certificationRejectMessages.forEach(message => {
            if (!group.includes(message.group)) {
                group.push(message.group);
                result.push(
                    <OptGroup
                        key={message.group + "group"}
                        label={<FormattedMessage id={`c-certifications.rejectMessage.${message.group}`} />}
                    >
                        {certificationRejectMessages
                            .filter(x => x.group === message.group)
                            .map(groupMessage => (
                                <Option
                                    key={groupMessage.group + groupMessage.type}
                                    value={`${groupMessage.group}.${groupMessage.type}`}
                                >
                                    <FormattedMessage
                                        id={`c-certifications.rejectMessage.${groupMessage.group}.${groupMessage.type}`}
                                    />
                                </Option>
                            ))}
                    </OptGroup>
                );
            }
        });

        return result;
    };

    return (
        <ActionsWrapper>
            <br />
            <div>
                {!actionPermits.readOnly ? (
                    <div>
                        <InfoTitle>
                            <FormattedMessage id="general.privacy" />:{" "}
                        </InfoTitle>
                        {privacyPrefereces ? (
                            <span>
                                <FormattedMessage id="c-certifications.privacyDataExist" /> <br /> <br />
                            </span>
                        ) : (
                            <div>
                                <Alert message={<FormattedMessage id="c-certifications.warningPrivacy" />} banner />
                                <ItemPrivacyDataForm initialValues={null} onSubmit={val => savePrivacyData(val)} />
                                <div style={{ textAlign: "right" }}>
                                    <Button
                                        kind="primary"
                                        size="small"
                                        onClick={async () => await submitForm("item-privacy-data-form")}
                                        disabled={!formEnabled("item-privacy-data-form")}
                                    >
                                        <FormattedMessage id="general.save" />
                                    </Button>
                                </div>
                            </div>
                        )}

                        {isStudioOK && (
                            <div>
                                <InfoTitle>
                                    <FormattedMessage id="general.managedList" />
                                </InfoTitle>
                                <br />
                                <FormattedMessage id="c-certifications.showManaged" />
                                <br />
                                <ButtonAction kind="secondary" onClick={() => showListCon(true)} size="small">
                                    <FormattedMessage id="general.show" />
                                </ButtonAction>
                                <br /> <br />
                            </div>
                        )}

                        <InfoTitle>
                            <FormattedMessage id="general.documentAction" />
                        </InfoTitle>
                        {showAction && (
                            <div>
                                <FormattedMessage id="c-certifications.addNoteForReject" />
                                <Select
                                    defaultValue={
                                        info.message && translateCertificationRejectOption(intl, info.message)
                                    }
                                    style={{ width: "100%" }}
                                    onChange={o => (o.includes("OTHER") ? setMessageType("OTHER") : setMessageType(o))}
                                    disabled={!actionPermits.reject}
                                >
                                    {rejectOption()}
                                </Select>
                                <br /> <br />
                                {messageType === "OTHER" && (
                                    <TextArea
                                        placeholder={info.message}
                                        disabled={!actionPermits.reject}
                                        autosize={{ minRows: 3, maxRows: 3 }}
                                        onChange={v => setMessage(v.target.value)}
                                    />
                                )}
                                <ButtonWrapper>
                                    <ButtonAction
                                        kind="secondary"
                                        onClick={() => showPauseConfirm(info.description)}
                                        size="small"
                                        disabled={
                                            info.paused || (info.status !== "VALIDATED" && info.status !== "PENDING")
                                        }
                                    >
                                        <FormattedMessage id="general.pausedAction" />
                                    </ButtonAction>
                                    <ButtonAction
                                        kind="secondary"
                                        disabled={(message.length === 0 && !messageType) || !actionPermits.reject}
                                        onClick={() => showDeleteConfirm(info.description)}
                                        size="small"
                                    >
                                        <FormattedMessage id="general.reject" />
                                    </ButtonAction>

                                    <ButtonAction
                                        kind="primary"
                                        disabled={!actionPermits.certify}
                                        onClick={() => showValidate(info.description)}
                                        size="small"
                                    >
                                        <FormattedMessage id="general.certify" />
                                    </ButtonAction>
                                </ButtonWrapper>
                            </div>
                        )}
                    </div>
                ) : (
                    <Alert message={<FormattedMessage id="c-certifications.warningExtValidation" />} banner />
                )}
            </div>

            {prevValidateStatus && prevRejectStatus && (
                <StatusWrapper>
                    <Message
                        renderSuccess={!prevPauseStatus.ended && pauseStatus.ended}
                        renderError={!prevPauseStatus.error && pauseStatus.error}
                        history={history}
                        messageError={<FormattedMessage id="c-certifications.pauseError" />}
                        messageSuccess={<FormattedMessage id="c-certifications.pauseSuccess" />}
                    />
                    <Message
                        renderSuccess={!prevValidateStatus.ended && validateStatus.ended}
                        renderError={!prevValidateStatus.error && validateStatus.error}
                        history={history}
                        path="certified"
                        messageError={<FormattedMessage id="c-certifications.certifyError" />}
                        messageSuccess={<FormattedMessage id="c-certifications.certifySuccess" />}
                    />
                    <Message
                        renderSuccess={!prevRejectStatus.ended && rejectStatus.ended}
                        renderError={!prevRejectStatus.error && rejectStatus.error}
                        history={history}
                        path="rejected"
                        messageError={<FormattedMessage id="c-certifications.rejectError" />}
                        messageSuccess={<FormattedMessage id="c-certifications.rejectSuccess" />}
                    />
                </StatusWrapper>
            )}
        </ActionsWrapper>
    );
};

ContractActions.propTypes = {
    type: PropTypes.string,
    info: PropTypes.object,
    loading: PropTypes.bool,
    reject: PropTypes.func,
    validate: PropTypes.func,
    validateStatus: PropTypes.object,
    rejectStatus: PropTypes.object,
    history: PropTypes.object.isRequired
};

export default injectIntl(ContractActions);
