import React from "react";
import { InfoContainer, InfoTitle, StatusContainer } from "../styled";
import { FormattedMessage } from "react-intl";
import { translateitemStatus } from "../../../../utils/translate";
import { Tag } from "antd";

const ContractInfoConnection = ({ data, info }) => {
    let manager = data.itemsDetails.find(v => v.id === data.managerId);
    let managed = data.itemsDetails.find(v => v.id === data.managedId);

    return (
        <InfoContainer>
            <div>
                <InfoTitle>Info documento</InfoTitle>
                <li>
                    <FormattedMessage id="general.type" /> <span>: {info.policyDescription}</span>
                </li>
                <li>
                    <FormattedMessage id="general.extValidate" />
                    <span>: {info.externallyValidated ? "Si" : "No"}</span>
                </li>
                <li>
                    <FormattedMessage id="general.uploadBy" /> <span>: {info.user}</span>
                </li>
                <li>
                    <FormattedMessage id="general.date" /> <span>: {new Date(info.updatedAt).toLocaleString()}</span>
                </li>
                <br />
                <InfoTitle>
                    <FormattedMessage id="general.registry" /> Gestore
                </InfoTitle>
                <li>
                    <FormattedMessage id="general.itemDescription" />
                    <span>: {manager.details.description}</span>
                </li>
                <li>
                    <FormattedMessage id="general.taxId" /> <span>: {manager.identifier.taxId}</span>
                </li>
                <li>
                    <FormattedMessage id="general.vatNumber" /> <span>: {manager.identifier.vatNumber}</span>
                </li>
                <li>
                    <FormattedMessage id="general.status" />
                    <span>: {translateitemStatus(manager.status.certificationStatus)}</span>
                </li>

                <br />
            </div>
            <StatusContainer>
                <InfoTitle>Stato certificazione: </InfoTitle>
                <span>
                    {translateitemStatus(info.status)}
                    {info.paused ? (
                        <div style={{ marginLeft: 20 }}>
                            <Tag color="#f50">In pausa</Tag>
                        </div>
                    ) : null}
                </span>
                <br />
                <InfoTitle>Info connessione: </InfoTitle>
                <li>
                    <span>Applicazione:</span> <span>{data.connections[0].appId}</span>
                </li>
                <li>
                    <span>Feature Code:</span> <span>{data.connections[0].featureCode}</span>
                </li>
                <li>
                    <span>Permessi:</span> <span>{data.connections[0].permission}</span>
                </li>
                <br />
                <div style={{ marginTop: "-3px" }}>
                    <InfoTitle>
                        <FormattedMessage id="general.registry" /> Gestita
                    </InfoTitle>
                    <li>
                        <FormattedMessage id="general.itemDescription" />
                        <span>: {managed.details.description}</span>
                    </li>
                    <li>
                        <FormattedMessage id="general.taxId" /> <span>: {managed.identifier.taxId}</span>
                    </li>
                    <li>
                        <FormattedMessage id="general.vatNumber" /> <span>: {managed.identifier.vatNumber}</span>
                    </li>
                    <li>
                        <FormattedMessage id="general.status" />
                        <span>: {translateitemStatus(managed.status.certificationStatus)}</span>
                    </li>
                </div>
            </StatusContainer>
        </InfoContainer>
    );
};

export default ContractInfoConnection;
