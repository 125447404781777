import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { isNil } from "ramda";
import { Redirect } from "react-router-dom";

import CompanyCard from "../../../components/general/CompanyCard";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getObligationsTax, obligationsTaxDownload, resetObligationsTax } from "../../../actions/obligations-tax";
import CompanyObligationsTax from "../../../components/company/obligations-tax";
import { setDocument } from "../../../actions/document/info";

import { displayErrorToast } from "../../../actions/toast";

class CompanyObligationsView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        document: PropTypes.object,
        filterDocumets: PropTypes.object,
        getObligationsTax: PropTypes.func,
        history: PropTypes.object.isRequired,
        location: PropTypes.object,
        obligationsTax: PropTypes.object,
        obligationsTaxDownload: PropTypes.func.isRequired,
        resetObligationsTax: PropTypes.func.isRequired,
        setDocument: PropTypes.func.isRequired
    };

    render() {
        const {
            auth,
            admin,
            document,
            company,
            getObligationsTax,
            history,
            obligationsTax,
            obligationsTaxDownload,
            resetObligationsTax,
            setDocument
        } = this.props;

        return !isNil(company.info.base) ? (
            <CompanyCard
                company={company}
                content={
                    <CompanyObligationsTax
                        auth={auth}
                        admin={admin}
                        document={document}
                        company={company}
                        getObligationsTax={getObligationsTax}
                        history={history}
                        obligationsTax={obligationsTax}
                        obligationsTaxDownload={obligationsTaxDownload}
                        resetObligationsTax={resetObligationsTax}
                        setDocument={setDocument}
                    />
                }
                history={history}
            />
        ) : (
            <Redirect to="/item" />
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    document: state.document,
    filterDocumets: state.form.filterDocumets,
    obligationsTax: state.obligationsTax
});

const actions = {
    setDocument,
    getObligationsTax,
    obligationsTaxDownload,
    resetObligationsTax,
    displayErrorToast
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyObligationsView);
