import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Row, Col, Card, Icon, Badge, Avatar, Switch } from "antd";

import { Link } from "react-router-dom";

export const MeteringCard = ({
    family,
    icon,
    color,
    background,
    title,
    description,
    status,
    onSettings,
    onChangeStatus
}) => {
    return (
        <div>
            <Card
                style={{ width: 380, margin: 15 }}
                title={
                    <Row>
                        <Col span={4}>
                            <Avatar
                                style={{
                                    background: background,
                                    color: color,
                                    border: color
                                }}
                            >
                                <i className={icon} />
                            </Avatar>
                        </Col>
                        <Col span={20}>{title}</Col>
                    </Row>
                }
                actions={[
                    <span>
                        {status ? (
                            <Badge status="success" text={<FormattedMessage id="c-meteringcard.active" />} />
                        ) : (
                            <Badge status="error" text={<FormattedMessage id="general.disabled" />} />
                        )}
                    </span>,
                    <Link to={`metering/${family}/info`}>
                        <Icon type="setting" key="setting" onClick={onSettings} />
                    </Link>,
                    <Switch disabled={true} checked={status} onChange={onChangeStatus} />
                ]}
            >
                <Card.Meta
                    description={
                        <div>
                            <FormattedMessage id="c-meteringcard.numberpackage" />
                            {description.length}
                        </div>
                    }
                />
            </Card>
        </div>
    );
};

MeteringCard.propTypes = {
    family: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    description: PropTypes.array.isRequired,
    status: PropTypes.bool.isRequired,
    onSettings: PropTypes.func,
    onChangeStatus: PropTypes.func
};
