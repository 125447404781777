import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

import { Switch, Card, Alert, Icon, Modal } from "antd";
import { restrictToRoleForComponent } from "../general/hoc";

class ControlPannelTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }
    static propTypes = {
        admin: PropTypes.object,
        auth: PropTypes.object,
        portal: PropTypes.object,
        getPortalState: PropTypes.func.isRequired,
        putPortalInMaintenance: PropTypes.func.isRequired
    };

    async handleEnableDisablePortal() {
        const { auth, getPortalState, putPortalInMaintenance } = this.props;
        const { maintenanceValue } = this.state;

        putPortalInMaintenance(auth.loginAuth, {
            maintenance: !maintenanceValue
        });
        this.setState({ visible: false });
        getPortalState(auth.loginAuth);
    }

    render() {
        const { portal } = this.props;
        return (
            portal.getStatus && (
                <div className="c-control-pannel-tools">
                    <Card className="portal-state">
                        <Alert
                            className="portal-state-title"
                            message={!portal.getStatus.maintenance ? "Attivo" : "In manutenzione"}
                            type={!portal.getStatus.maintenance ? "success" : "error"}
                        />
                        <br />
                        <Switch
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            checked={!portal.getStatus.maintenance}
                            onChange={e =>
                                this.setState({
                                    visible: true,
                                    maintenanceValue: e
                                })
                            }
                        />
                    </Card>
                    <Modal
                        centered
                        title={portal.getStatus.maintenance ? "Attiva" : "Disattiva"}
                        visible={this.state.visible}
                        okText="Si"
                        cancelText="No"
                        onOk={() => this.handleEnableDisablePortal()}
                        onCancel={() => this.setState({ visible: false })}
                    >
                        <div>
                            {!portal.getStatus.maintenance && (
                                <Alert
                                    type="error"
                                    message="Attenzione: L'accesso al portale non sarà più possibile per nessun utente"
                                    banner
                                    style={{
                                        margin: "-24px",
                                        marginBottom: "20px"
                                    }}
                                />
                            )}
                            {"Sei sicuro di voler " +
                                (portal.getStatus.maintenance ? "attivare" : "disattivare") +
                                " il portale AGYO?"}
                        </div>
                    </Modal>
                </div>
            )
        );
    }
}

export default restrictToRoleForComponent(true)(ControlPannelTools);
