import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Row, Col } from "antd";
import { compose } from "redux";

import TextField from "@mondora/arc/antd/TextField";
import DataAddress from "../data-address";
import { SelectStringField } from "@mondora/arc/antd/SelectField";

import { form } from "@mondora/conv-redux-form";
import { injectIntl } from "react-intl";

import { companyCreateSchema } from "../../../../utils/form-schema/company-create";
import { taxRegimeValues, classifierItemTypes } from "../../../../utils/constant";

export class CompanyCreateForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired
    };

    render() {
        const { handleSubmit } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <TextField name="ownerId" placeholder="Email owner azienda" />
                <Divider orientation="left">Dati anagrafici</Divider>
                <SelectStringField
                    getOptionKeyFromValue={value => {
                        return value === "" ? undefined : value;
                    }}
                    name="classifier"
                    placeholder="Tipologia"
                    options={classifierItemTypes.map(p => {
                        return {
                            value: p.id,
                            label: p.name,
                            searchMatcherString: p.name
                        };
                    })}
                />
                <Row gutter={16}>
                    <Col span={4}>
                        <SelectStringField
                            disabled={true}
                            getOptionKeyFromValue={value => {
                                return value === "" ? undefined : value;
                            }}
                            name="country"
                            options={[{ value: "IT", label: "Italia" }]}
                            placeholder="Nazione"
                        />
                    </Col>
                    <Col span={10}>
                        <TextField name="taxId" placeholder="Codice fiscale" />
                    </Col>
                    <Col span={10}>
                        <TextField name="vatNumber" placeholder="Partita iva" />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField name="description" placeholder="Ragione sociale" />
                    </Col>
                    <Col span={12}>
                        <SelectStringField
                            getOptionKeyFromValue={value => {
                                return value === "" ? undefined : value;
                            }}
                            name="taxRegime"
                            placeholder="Regime fiscale"
                            options={taxRegimeValues.map(p => {
                                return {
                                    value: p.id,
                                    label: p.name,
                                    searchMatcherString: p.name
                                };
                            })}
                            showSearch={true}
                        />
                    </Col>
                </Row>
                <Divider orientation="left">Indirizzo principale</Divider>
                <DataAddress disabled={true} />
                <div style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </form>
        );
    }
}

const formDefinition = {
    asyncFields: ["taxId", "vatNumber"],
    form: "company-create-form",
    schema: companyCreateSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(CompanyCreateForm);
