import {
    GET_INVOICE_USAGE_START,
    GET_INVOICE_USAGE_ERROR,
    GET_INVOICE_USAGE_SUCCESS,
    GET_INVOICE_DEADLETTER_START,
    GET_INVOICE_DEADLETTER_ERROR,
    GET_INVOICE_DEADLETTER_SUCCESS,
    DELETE_INVOICE_USAGE_START,
    DELETE_INVOICE_USAGE_ERROR,
    DELETE_INVOICE_USAGE_SUCCESS,
    PUT_INVOICE_USAGE_START,
    PUT_INVOICE_USAGE_ERROR,
    PUT_INVOICE_USAGE_SUCCESS,
    GET_INVOICE_USAGE_PACKAGE_START,
    GET_INVOICE_USAGE_PACKAGE_SUCCESS,
    GET_INVOICE_USAGE_PACKAGE_ERROR,
    PUT_INVOICE_USAGE_PACKAGE_START,
    PUT_INVOICE_USAGE_PACKAGE_SUCCESS,
    PUT_INVOICE_USAGE_PACKAGE_ERROR,
    POST_INVOICE_NOTIFICATION_USAGE_START,
    POST_INVOICE_NOTIFICATION_USAGE_ERROR,
    POST_INVOICE_NOTIFICATION_USAGE_SUCCESS
} from "../../actions/usage/invoice";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function invoiceUsage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICE_USAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICE_USAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICE_USAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function invoiceDeadletter(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICE_DEADLETTER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICE_DEADLETTER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICE_DEADLETTER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function deleteInvoiceUsage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DELETE_INVOICE_USAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DELETE_INVOICE_USAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DELETE_INVOICE_USAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function reassignInvoiceUsage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case PUT_INVOICE_USAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case PUT_INVOICE_USAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case PUT_INVOICE_USAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function invoiceUsagePackage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICE_USAGE_PACKAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICE_USAGE_PACKAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICE_USAGE_PACKAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function reassignInvoiceUsagePackage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case PUT_INVOICE_USAGE_PACKAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case PUT_INVOICE_USAGE_PACKAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case PUT_INVOICE_USAGE_PACKAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function replayInvoiceNotificationUsage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case POST_INVOICE_NOTIFICATION_USAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_INVOICE_NOTIFICATION_USAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_INVOICE_NOTIFICATION_USAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
