import styled from "styled-components";
import { Avatar } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UserAvatar = styled(Avatar)`
    background-color: ${props => props.background}!important;
`;
export const UserIcon = styled(FontAwesomeIcon)`
    font-size: 25px;
    margin-top: 15px;
`;
export const UserTitle = styled.div`
    font-size: 30px;
    color: #005075;
`;
export const TokenCopy = styled(CopyToClipboard)`
    float: right;
`;
export const Title = styled.div`
    font-size: 20px;
    color: #005075;
    margin-top: 30px;
`;
export const SubTitle = styled.div`
    font-size: 16px;
    color: #005075;
`;
export const RightIcon = styled.div`
    float: right;
`;

export const Cards = styled.div`
    background: white;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 1 1 400px;
`;

export const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
