import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PdfHandler } from "../../../components/general/pdf-handler";
import { FormattedMessage } from "react-intl";
import { Tabs } from "antd";
import { PdfWrapper, Container } from "./styled";
import { findOwnManagerConnections } from "../../../actions/connections/read";
import { getConnectionDocument, getDocument } from "../../../actions/documents";
import ConnectionDocumentTab from "./connection-doc";
import { useAsync } from "react-async";
import { translateConnectionFeatureCode } from "../../../utils/translate";

const { TabPane } = Tabs;

const CompanyDocuments = ({ auth, company, findOwnManagerConnections, getConnectionDocument, getDocument }) => {
    //init only once
    const downloadContract = useCallback((nameFile, file) => {
        var download = document.createElement("a");
        download.setAttribute("href", "data:application/octet-stream;charset=utf-8;base64," + file);
        download.setAttribute("download", nameFile);
        download.click();
    }, []);

    const managerConnectionsFn = useCallback(async () => {
        if (company.info.base.details.classifier !== "COMPANY") {
            return null;
        }
        const filter = {
            managedIds: company.info.base.uuid,
            appId: ["EIP", "SIG"],
            active: true,
            deleted: false,
            status: ["VALIDATED"]
        };
        const res = await findOwnManagerConnections(auth.loginAuth, filter, { page: 0, items: 2000 });
        return res;
    }, [findOwnManagerConnections, auth.loginAuth, company.info.base.uuid, company.info.base.details.classifier]);

    const managerConnectionsCall = useAsync({
        promiseFn: managerConnectionsFn
    });

    const getDocumentCall = useCallback(() => getDocument(auth.loginAuth, company.info.base.id), [
        auth.loginAuth,
        getDocument,
        company.info.base.id
    ]);

    useEffect(() => {
        getDocumentCall();
    }, [getDocumentCall]);

    const getAllConnections = useCallback(links => {
        let connections = [];
        links.forEach(link => {
            if (link.connections && link.connections.length > 0) {
                connections = link.connections.map(connection => connection);
            }
        });
        return connections;
    }, []);

    return (
        <Container>
            <span className="a1">
                <FormattedMessage id="c-companydocuments-title" />
            </span>
            {company.documents.getDocument.status.error &&
                managerConnectionsCall &&
                managerConnectionsCall.data &&
                managerConnectionsCall.data.content.length === 0 &&
                company.info.base.details.classifier === "COMPANY" && (
                    <span className="a1">
                        <FormattedMessage id="c-companydocuments-empty" />
                    </span>
                )}
            <Tabs defaultActiveKey="0">
                {!company.documents.getDocument.status.error &&
                    company.documents.getDocument &&
                    company.documents.getDocument.content && (
                        <TabPane tab="Documento di sottoscrizione" key="0">
                            <PdfWrapper>
                                <PdfHandler
                                    content={company.documents.getDocument.content}
                                    loading={company.documents.getDocument.status.started}
                                    fileName={"documento_di_sottoscrizione.pdf"}
                                />
                            </PdfWrapper>
                        </TabPane>
                    )}
                {company.info.base.details.classifier === "COMPANY" &&
                managerConnectionsCall.data &&
                managerConnectionsCall.data.content.length > 0
                    ? getAllConnections(managerConnectionsCall.data.content).map((connection, i) => {
                          return (
                              <TabPane
                                  tab={`Atto di affidamento ${translateConnectionFeatureCode(connection.featureCode)}`}
                                  key={connection.id}
                              >
                                  <ConnectionDocumentTab
                                      auth={auth}
                                      company={company}
                                      connection={connection}
                                      downloadContract={downloadContract}
                                      getConnectionDocument={getConnectionDocument}
                                  />
                              </TabPane>
                          );
                      })
                    : null}
            </Tabs>
        </Container>
    );
};

CompanyDocuments.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    getConnectionDocument: PropTypes.func.isRequired,
    getDocument: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});

const actions = { findOwnManagerConnections, getConnectionDocument, getDocument };

export default connect(
    mapStateToProps,
    actions
)(CompanyDocuments);
