import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Badge, Modal, Menu, Dropdown, Button, Tag } from "antd";
import EnableDisableService from "../enable-disable";
import moment from "moment";
import "./style.css";
import { Bar } from "../../general/usage-bar";

const locale = {
    emptyText: "Nessun configurazione disponibile"
};

class B2bPassiveService extends Component {
    constructor() {
        super();
        this.state = {
            mEnableDisable: false,
            mAddExemptionFlow: false,
            mReloadConsumption: false,
            ownerId: null,
            serviceConsumptions: 0,
            serviceCapacity: 0
        };
    }
    static propTypes = {
        active: PropTypes.object.isRequired,
        admin: PropTypes.object,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        disableService: PropTypes.func.isRequired,
        getB2bPassiveConfig: PropTypes.func.isRequired,
        searchB2bPassiveConfig: PropTypes.func.isRequired,
        searchCompany: PropTypes.func.isRequired,
        searchItems: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        usage: PropTypes.object.isRequired,
        getServicesForCompanySeached: PropTypes.func.isRequired,
        b2bConfig: PropTypes.array.isRequired,
        b2bConfigLoaded: PropTypes.bool.isRequired,
        b2bShared: PropTypes.array.isRequired,
        b2bSharedLoaded: PropTypes.bool.isRequired,
        handleChangeB2bSharedPage: PropTypes.func.isRequired,
        b2bSharedCurrentPage: PropTypes.number.isRequired,
        b2bSharedTotalItems: PropTypes.number.isRequired,
        b2bSharedItemForPage: PropTypes.number.isRequired,
        isHavePermission: PropTypes.bool.isRequired,
        isExemptionFlowPermission: PropTypes.bool.isRequired,
        meteringExtension: PropTypes.bool
    };

    componentDidMount() {
        const { usage } = this.props;
        const subscriptions = usage.invoicingUsage.subscriptions;
        if (subscriptions && subscriptions.length > 0) {
            this.updateServiceConsumptionsAndCapacity(subscriptions);
        }
    }

    componentDidUpdate(prevProps) {
        const { usage } = this.props;
        const { usage: previousUsage } = prevProps;
        const previousSubscriptions =
            previousUsage && previousUsage.invoicingUsage && previousUsage.invoicingUsage.subscriptions;
        const subscriptions = usage && usage.invoicingUsage && usage.invoicingUsage.subscriptions;
        if (previousSubscriptions !== subscriptions && subscriptions) {
            this.updateServiceConsumptionsAndCapacity(subscriptions);
        }
    }

    updateServiceConsumptionsAndCapacity(subscriptions) {
        this.setState(
            subscriptions.reduce(
                (accumulator, { received, capacity }) => {
                    accumulator.serviceConsumptions += received;
                    accumulator.serviceCapacity += capacity;
                    return accumulator;
                },
                {
                    serviceConsumptions: 0,
                    serviceCapacity: 0
                }
            )
        );
    }

    handleSetOwnerId(ownerIdValue) {
        this.setState({ ownerId: ownerIdValue });
    }

    handleExemptionFlow() {
        const { auth, addExemptionFlow, postReassignments } = this.props;
        const { b2bPassConfigSelected } = this.state;
        let date_ob = new Date();
        let year = date_ob.getFullYear();
        let startDate = year + "-01-11T00:00:00.000Z";
        const result = addExemptionFlow(
            auth.loginAuth,
            b2bPassConfigSelected.itemId,
            b2bPassConfigSelected.ownerId,
            startDate
        );
        this.setState({ mAddExemptionFlow: false });
        if (result) postReassignments(auth.loginAuth, b2bPassConfigSelected.ownerUuid);
    }

    handleReloadConsumtpion() {
        const { auth, postReassignments } = this.props;
        const { b2bPassConfigSelected } = this.state;

        this.setState({ mReloadConsumption: false });
        postReassignments(auth.loginAuth, b2bPassConfigSelected.ownerUuid);
    }

    handleEnableDisableService() {
        const { activateService, auth, company, disableService } = this.props;
        const { b2bPassConfigSelected, isToEnable, ownerId } = this.state;
        if (isToEnable) {
            activateService(
                auth.loginAuth,
                company.info.base.id,
                "B2B-PASSIVE-FLOW",
                "",
                ownerId === null ? company.info.base.id : ownerId
            );
        } else {
            disableService(
                auth.loginAuth,
                b2bPassConfigSelected.itemId,
                "B2B-PASSIVE-FLOW",
                "",
                b2bPassConfigSelected.ownerId
            );
        }

        this.setState({ mEnableDisable: false, isToEnable: false });
    }

    renderAction(configValue) {
        const { isHavePermission, isExemptionFlowPermission, meteringExtension } = this.props;

        return (
            <Menu>
                {!meteringExtension && (
                    <Menu.Item
                        key="0"
                        disabled={!isHavePermission}
                        onClick={() =>
                            this.setState({
                                mEnableDisable: true,
                                isToEnable: false,
                                b2bPassConfigSelected: configValue
                            })
                        }
                    >
                        <i className="fas fa-times-circle" /> Disattiva
                    </Menu.Item>
                )}

                {configValue.exemption ? (
                    <Menu.Item
                        key="1"
                        disabled={!isExemptionFlowPermission}
                        onClick={() =>
                            this.setState({
                                mReloadConsumption: true,
                                b2bPassConfigSelected: configValue
                            })
                        }
                    >
                        <i className="fas fa-play-circle" />
                        <FormattedMessage id="c-metering-action-reload-consumption" />
                    </Menu.Item>
                ) : (
                    <Menu.Item
                        key="1"
                        disabled={!isExemptionFlowPermission}
                        onClick={() =>
                            this.setState({
                                mAddExemptionFlow: true,
                                b2bPassConfigSelected: configValue
                            })
                        }
                    >
                        <i className="fas fa-play-circle" /> Aggiungi al flusso di esenzione IMPEXP
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    render() {
        const {
            auth,
            admin,
            company,
            getB2bPassiveConfig,
            searchB2bPassiveConfig,
            searchCompany,
            getServicesForCompanySeached,
            b2bConfig,
            b2bConfigLoaded,
            b2bShared,
            b2bSharedLoaded,
            handleChangeB2bSharedPage,
            b2bSharedCurrentPage,
            b2bSharedTotalItems,
            b2bSharedItemForPage,
            isHavePermission,
            services,
            meteringExtension
        } = this.props;
        const {
            mEnableDisable,
            isToEnable,
            isCompanyOwner,
            ownerId,
            mAddExemptionFlow,
            mReloadConsumption
        } = this.state;

        const columns = [
            {
                title: "Stato",
                dataIndex: "status.active",
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? "Attivo" : "Disattivo"}
                    </span>
                )
            },
            {
                title: "Owner servizio",
                dataIndex: "ownerId",
                key: "ownerId",
                render: (text, record) => <span>{record.ownerDesc + "(" + text + ")"}</span>
            },
            {
                title: <FormattedMessage id="type_of_package" />,
                dataIndex: "status.readonly",
                key: "readonly",
                render: text => (
                    <span>
                        <Badge status={text ? "error" : "success"} />
                        {text ? <FormattedMessage id="read_only" /> : <FormattedMessage id="read_and_write" />}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="exemption_flow" />,
                dataIndex: "exemption",
                key: "exemption",
                render: text => (
                    <span>
                        {text ? (
                            <Tag color="green">
                                {<FormattedMessage id="company_added_exemption_flow" />}
                                {moment(text)
                                    .utc()
                                    .format("DD/MM/YYYY")}
                            </Tag>
                        ) : (
                            <Tag color="red">{<FormattedMessage id="company_no_exemption_flow" />}</Tag>
                        )}
                    </span>
                )
            },
            {
                title: "Azioni",
                render: (text, record) => (
                    <div style={{ textAlign: "center" }}>
                        <Dropdown overlay={this.renderAction(record)} trigger={["click"]} placement="bottomRight">
                            <Button shape="circle" size="small">
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </div>
                )
            }
        ];
        const columnsShared = [
            {
                title: <FormattedMessage id="status" />,
                dataIndex: "status.active",
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? <FormattedMessage id="active" /> : <FormattedMessage id="disabled" />}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="package_receiver" />,
                dataIndex: "itemId",
                key: "itemId",
                render: (text, record) => <span>{record.itemDesc + "(" + text + ")"}</span>
            },
            {
                title: <FormattedMessage id="exemption_flow" />,
                dataIndex: "exemption",
                key: "exemption",
                render: text => (
                    <span>
                        {text ? (
                            <Tag color="green">
                                {<FormattedMessage id="company_added_exemption_flow" />}
                                {moment(text)
                                    .utc()
                                    .format("DD/MM/YYYY")}
                            </Tag>
                        ) : (
                            <Tag color="red">{<FormattedMessage id="company_no_exemption_flow" />}</Tag>
                        )}
                    </span>
                )
            },

            {
                title: <FormattedMessage id="actions" />,
                render: (text, record) => (
                    <div style={{ textAlign: "center" }}>
                        <Button shape="circle" size="small" disabled={true}>
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </div>
                )
            }
        ];
        const { serviceConsumptions, serviceCapacity } = this.state;
        return (
            <div className="service-b2bPassive">
                <span className="a1">Consumi</span>
                <br />
                {serviceConsumptions && serviceCapacity ? (
                    <Bar
                        capacity={serviceCapacity}
                        totalConsumptions={serviceConsumptions}
                        passiveConsumptions={serviceConsumptions}
                        supportsPassiveInvoicing
                        showPackageType={false}
                    />
                ) : (
                    <span>Nessun dato disponibile.</span>
                )}
                <br /> <br />
                <span className="a1">{"Configurazioni"}</span>
                <Table
                    title={() =>
                        !meteringExtension && (
                            <div className="new-config-button">
                                <Button
                                    disabled={!isHavePermission}
                                    onClick={() =>
                                        this.setState({
                                            mEnableDisable: true,
                                            isToEnable: true
                                        })
                                    }
                                >
                                    Nuova configurazione
                                </Button>
                            </div>
                        )
                    }
                    rowKey={(record, index) => record.itemId + record.ownerId + index}
                    locale={locale}
                    dataSource={b2bConfig}
                    columns={columns}
                    size="middle"
                    pagination={false}
                    loading={b2bConfigLoaded}
                    expandedRowRender={config => (
                        <div>
                            <span className="label">Codice fiscale owner: </span>
                            {config.ownerTaxId}
                            <br />
                            <span className="label">Partita iva owner: </span>
                            {config.ownerVatNumber}
                            <br />
                            <span className="label">Attivato il: </span>
                            {config.status.startDate
                                ? new Date(Number(config.status.startDate)).toLocaleString()
                                : "Dato non disponibile"}
                            <br />
                            <span className="label">Attivato da:</span>{" "}
                            {config.status.createdBy ? config.status.createdBy : "Dato non disponibile"}
                            <br />
                            <span className="label">Modificato il: </span>
                            {config.status.modifiedAt
                                ? new Date(Number(config.status.modifiedAt)).toLocaleString()
                                : "Dato non disponibile"}
                            <br />
                            <span className="label">Modificato da:</span>{" "}
                            {config.status.modifiedBy ? config.status.modifiedBy : "Dato non disponibile"} <br />
                            <span className="label">Terminato il: </span>
                            {config.status.endDate
                                ? new Date(Number(config.status.endDate)).toLocaleString()
                                : "Dato non disponibile"}
                            <br />
                        </div>
                    )}
                />
                <br />
                <br />
                <span className="a1">
                    <FormattedMessage id="shared_configurations" />
                </span>
                <Table
                    rowKey={record => record.itemId + record.ownerId}
                    locale={locale}
                    dataSource={b2bShared}
                    columns={columnsShared}
                    size="small"
                    pagination={{
                        current: b2bSharedCurrentPage + 1,
                        total: b2bSharedTotalItems,
                        hideOnSinglePage: false,
                        pageSize: b2bSharedItemForPage,
                        size: "small",
                        position: "top"
                    }}
                    onChange={pagination => handleChangeB2bSharedPage(pagination.current - 1)}
                    loading={b2bSharedLoaded}
                    expandedRowRender={config => (
                        <div>
                            <span className="label">
                                <FormattedMessage id="receiver_tax_code" />
                            </span>
                            {config.itemTaxId}
                            <br />
                            <span className="label">
                                <FormattedMessage id="receiver_vat_number" />
                            </span>
                            {config.itemVatNumber}
                            <br />
                            <span className="label">
                                <FormattedMessage id="activated_on" />
                            </span>
                            {config.status.startDate ? (
                                new Date(Number(config.status.startDate)).toLocaleString()
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                            <span className="label">
                                <FormattedMessage id="activated_by" />
                            </span>{" "}
                            {config.status.createdBy ? (
                                config.status.createdBy
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                            <span className="label">
                                <FormattedMessage id="modified_on" />
                            </span>
                            {config.status.modifiedAt ? (
                                new Date(Number(config.status.modifiedAt)).toLocaleString()
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                            <span className="label">
                                <FormattedMessage id="modified_by" />
                            </span>{" "}
                            {config.status.modifiedBy ? (
                                config.status.modifiedBy
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}{" "}
                            <br />
                            <span className="label">
                                <FormattedMessage id="finished_on" />
                            </span>
                            {config.status.endDate ? (
                                new Date(Number(config.status.endDate)).toLocaleString()
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                        </div>
                    )}
                />
                <br />
                <Modal
                    centered
                    title={isToEnable ? "Attiva configurazione" : "Disattiva configurazione"}
                    visible={mEnableDisable}
                    onOk={() => this.handleEnableDisableService()}
                    okText="Conferma"
                    cancelText="Annulla"
                    onCancel={() =>
                        this.setState({
                            mEnableDisable: false
                        })
                    }
                    okButtonProps={{
                        disabled: !isCompanyOwner && !ownerId && isToEnable
                    }}
                    width={640}
                >
                    <div>
                        <EnableDisableService
                            admin={admin}
                            auth={auth}
                            company={company}
                            serviceSelect={"B2B-PASSIVE-FLOW"}
                            isToEnable={isToEnable}
                            getB2bPassiveConfig={getB2bPassiveConfig}
                            searchB2bPassiveConfig={searchB2bPassiveConfig}
                            searchCompany={searchCompany}
                            setCompanyOnwer={value => this.setState({ isCompanyOwner: value })}
                            setOwnerId={value => this.handleSetOwnerId(value)}
                            getServicesForCompanySeached={getServicesForCompanySeached}
                            services={services}
                        />
                    </div>
                </Modal>
                <Modal
                    centered
                    title={"Attiva flusso di esenzione"}
                    visible={mAddExemptionFlow}
                    onOk={() => this.handleExemptionFlow()}
                    okText="Conferma"
                    cancelText="Annulla"
                    onCancel={() =>
                        this.setState({
                            mAddExemptionFlow: false
                        })
                    }
                    width={640}
                >
                    <div>
                        <span>{"Sei sicuro di voler aggiungere l'azienda al flusso di esenzione?"}</span>
                    </div>
                </Modal>
                <Modal
                    centered
                    title={<FormattedMessage id="c-metering-reload-consumtpion" />}
                    visible={mReloadConsumption}
                    onOk={() => this.handleReloadConsumtpion()}
                    okText={<FormattedMessage id="general.confirm" />}
                    cancelText={<FormattedMessage id="general.cancel" />}
                    onCancel={() =>
                        this.setState({
                            mReloadConsumption: false
                        })
                    }
                    width={640}
                >
                    <div>
                        <span>{<FormattedMessage id="c-metering-popup-consumption" />}</span>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default B2bPassiveService;
