import styled from "styled-components";
import { Pagination } from "antd";

export const CustomPagination = styled(Pagination)`
    background: white;
    text-align: right;
`;
export const ActionCel = styled.div`
    text-align: right;
`;
