import {
    GET_OBLIGATIONS_TAX_START,
    GET_OBLIGATIONS_TAX_SUCCESS,
    GET_OBLIGATIONS_TAX_ERROR,
    GET_OBLIGATIONS_TAX_RESET
} from "../actions/obligations-tax";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function obligationsTax(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_OBLIGATIONS_TAX_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_OBLIGATIONS_TAX_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_OBLIGATIONS_TAX_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_OBLIGATIONS_TAX_RESET:
            return {
                ...defaultState
            };

        default:
            return state;
    }
}
