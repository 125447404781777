import { API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_NOTIFICATION_ACTIVITES_START = "GET_NOTIFICATION_ACTIVITES_START";
export const GET_NOTIFICATION_ACTIVITES_SUCCESS = "GET_NOTIFICATION_ACTIVITES_SUCCESS";
export const GET_NOTIFICATION_ACTIVITES_ERROR = "GET_NOTIFICATION_ACTIVITES_ERROR";

export const getActivites = (auth, ncsId, limit = 50, startingAfter) => async dispatch => {
    let urlParams = {
        limit,
        startingAfter
    };

    dispatch({
        type: GET_NOTIFICATION_ACTIVITES_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${API_URL}/activities/${ncsId}`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_NOTIFICATION_ACTIVITES_SUCCESS,
            payload: result
        });

        return result;
    } catch (e) {
        dispatch({
            type: GET_NOTIFICATION_ACTIVITES_ERROR,
            error: e
        });
    }
};
