import { postRestCall } from "../utils/rest-api-call";
import { API_URL } from "../config";

export const UPLOAD_ADA_START = "UPLOAD_ADA_START";
export const UPLOAD_ADA_SUCCESS = "UPLOAD_ADA_SUCCESS";
export const UPLOAD_ADA_ERROR = "UPLOAD_ADA_ERROR";

export const uploadADA = (auth, policyId, description, itemId, content) => async dispatch => {
    let body = {
        policyId: policyId,
        policyType: "CONNECTION",
        description: description,
        itemId: itemId,
        content: content
    };
    dispatch({
        type: UPLOAD_ADA_START,
        request: body
    });
    const toast = {
        onOk: true,
        onOkText: "Atto di affidamento caricato con successo ",
        onError: true,
        onErrorText: "Atto di affidamento non caricato",
        infoReq: { authId: auth.id, req: body }
    };
    try {
        const result = await postRestCall(
            `${API_URL}/certifications/uploadADA`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: UPLOAD_ADA_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: UPLOAD_ADA_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};
