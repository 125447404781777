import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";

class SignerStatusTag extends Component {
    static propTypes = {
        signerStatus: PropTypes.string
    };

    render() {
        const { signerStatus } = this.props;
        switch (signerStatus) {
            case "FINAL_SUCCESS":
                return <Tag color="green">{"ATTIVO"}</Tag>;
            case "ASSIGNED_SUCCESS":
                return <Tag color="green">{"ASSEGNATO"}</Tag>;
            case "SUBMISSION_SUCCESS":
                return <Tag color="orange">{"IN ATTESA"}</Tag>;
            case "PDF_GENERATED":
                return <Tag color="yellow">{"GENERATO"}</Tag>;
            case "WITHDRAW_SUCCESS":
                return <Tag color="grey">{"REVOCATO"}</Tag>;
            case "ASSIGNED_WITHDRAW_SUCCESS":
                return <Tag color="grey">{"REVOCATO"}</Tag>;
            case "WITHDRAW_SUBMISSION_SUCCESS":
                return <Tag color="orange">{"IN ATTESA DI REVOCA"}</Tag>;
            case signerStatus.includes("SUBMISSION_ERROR"):
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "ERROR":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_E276":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_E275":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_E205":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_E273":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_EG001":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_E204":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "SUBMISSION_ERROR_E278":
                return <Tag color="red">{"ERRORE"}</Tag>;
            case "WITHDRAW_ERROR_E428":
                return <Tag color="orange">{"CERTIFICATO SCADUTO"}</Tag>;

            default:
                return signerStatus;
        }
    }
}

export default SignerStatusTag;
