import React from "react";
import PropTypes from "prop-types";

import { SpidViewBlock } from "./styled.js";
import { Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { userIsAuthorized } from "../../../utils/get-roles";

import SpidTable from "../spidtable";
import SpidComponentUsage from "../slots";

const Spid = ({ history, admin, filterDisabled, handleFilter, loading, spidList, spidUsage, pagination }) => {
    return (
        <div>
            {userIsAuthorized("c-sideMenu-spid", admin.adminInfo.permits) ? (
                <SpidViewBlock>
                    {spidUsage ? (
                        <Tabs variant="secondary" defaultActiveKey="1">
                            <Tabs.TabPane tab={<FormattedMessage id="c-spid.spid-list.tab-title" />} key="spidlist">
                                <SpidTable
                                    spidList={spidList}
                                    handleFilter={handleFilter}
                                    loading={loading}
                                    pagination={pagination}
                                ></SpidTable>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<FormattedMessage id="usages" />} key="usage">
                                <SpidComponentUsage spidUsage={spidUsage}></SpidComponentUsage>
                            </Tabs.TabPane>
                        </Tabs>
                    ) : (
                        <SpidTable
                            spidList={spidList}
                            handleFilter={handleFilter}
                            filterDisabled={filterDisabled}
                            loading={loading}
                            pagination={pagination}
                        ></SpidTable>
                    )}
                </SpidViewBlock>
            ) : null}
        </div>
    );
};

Spid.propTypes = {
    admin: PropTypes.object.isRequired,
    filterDisabled: PropTypes.bool,
    history: PropTypes.object.isRequired,
    spidUsage: PropTypes.object
};

export default Spid;
