import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

const RadioForm = ({ input, label }) => (
    <div>
        {label}{" "}
        <Radio.Group {...input}>
            <Radio value={true}>Sì, acconsento</Radio>
            <Radio value={false}>No, non acconsento</Radio>
        </Radio.Group>
    </div>
);

RadioForm.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.object
};

export default RadioForm;
