import {
    VALIDATING_CONTRACT_START,
    VALIDATING_CONTRACT_SUCCESS,
    VALIDATING_CONTRACT_ERROR,
    REJECTING_CONTRACT_START,
    REJECTING_CONTRACT_SUCCESS,
    REJECTING_CONTRACT_ERROR,
    GET_DOCUMENTS_START,
    GET_DOCUMENTS_SUCCESS,
    GET_DOCUMENTS_ERROR,
    GET_FULL_INFO_DOCUMENT_START,
    GET_FULL_INFO_DOCUMENT_SUCCESS,
    GET_FULL_INFO_DOCUMENT_ERROR,
    PAUSE_CONTRACT_START,
    PAUSE_CONTRACT_SUCCESS,
    PAUSE_CONTRACT_ERROR
} from "../actions/contract";

import {
    SEARCH_CONNECTION_START,
    SEARCH_CONNECTION_SUCCESS,
    SEARCH_CONNECTION_ERROR
} from "../actions/search/connection";

import { isNil } from "ramda";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

function mappingData(payload) {
    if (payload.documents !== undefined) {
        return payload.documents.map(values => {
            return {
                ...values,
                description: values.description.toUpperCase(),
                policyDescription: isNil(values.policyType) ? "Atto di Validazione" : "Atto di Affidamento",
                policyCode: isNil(values.policyType) ? "AdV" : "AdA",
                policyType: isNil(values.policyType) ? "COMPANY" : values.policyType
            };
        });
    }
}

export function validating(state = defaultState, { type, payload, error }) {
    switch (type) {
        case VALIDATING_CONTRACT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case VALIDATING_CONTRACT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case VALIDATING_CONTRACT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function rejecting(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REJECTING_CONTRACT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case REJECTING_CONTRACT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REJECTING_CONTRACT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function getDocuments(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_DOCUMENTS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_DOCUMENTS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload,
                documents: mappingData(payload)
            };
        case GET_DOCUMENTS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function getFullInfoDocument(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_FULL_INFO_DOCUMENT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_FULL_INFO_DOCUMENT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                info: {
                    ...payload,
                    documentInfo: {
                        ...payload.documentInfo,
                        description: payload.documentInfo.description.toUpperCase(),
                        policyDescription: isNil(payload.documentInfo.policyType)
                            ? "Atto di Validazione"
                            : "Atto di Affidamento",
                        policyCode: isNil(payload.documentInfo.policyType) ? "AdV" : "AdA",
                        policyType: isNil(payload.documentInfo.policyType) ? "COMPANY" : payload.documentInfo.policyType
                    }
                }
            };
        case GET_FULL_INFO_DOCUMENT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export const pauseContract = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case PAUSE_CONTRACT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case PAUSE_CONTRACT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case PAUSE_CONTRACT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};

export function getConnections(state = defaultState, { type, payload, error }) {
    switch (type) {
        case SEARCH_CONNECTION_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case SEARCH_CONNECTION_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                info: {
                    ...payload
                }
            };
        case SEARCH_CONNECTION_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
