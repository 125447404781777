import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { BarChart, Bar, Legend, ResponsiveContainer } from "recharts";

import "./style.css";

class PrivacyService extends Component {
    static propTypes = {
        active: PropTypes.object.isRequired,

        usage: PropTypes.object.isRequired
    };

    render() {
        const {
            usage: { privacyUsage }
        } = this.props;

        let dataCompany = [
            {
                capacity: 0,
                used: 0,
                locked: 0,
                coolDown: 0
            }
        ];
        let dataDocument = [
            {
                capacity: 0,
                used: 0,
                locked: 0,
                coolDown: 0
            }
        ];
        const privacySlot = privacyUsage.status.ended && privacyUsage.constraints ? privacyUsage.constraints : null;
        if (privacySlot !== null) {
            privacySlot.forEach(value => {
                if (value.type === "PRIVACY_COMPANIES") {
                    dataCompany = [
                        {
                            capacity: value.capacity,
                            used: value.used,
                            locked: value.locked,
                            coolDown: value.coolDown
                        }
                    ];
                } else {
                    dataDocument = [
                        {
                            capacity: value.capacity,
                            used: value.used,
                            locked: value.locked,
                            coolDown: value.coolDown
                        }
                    ];
                }
            });
        }

        return (
            <div className="service-privacy">
                <Row>
                    <Col span={12}>
                        <span className="a1">{"Info Pacchetto"}</span>
                        {privacySlot !== null ? (
                            <div>
                                <span>Id pacchetto: </span> {privacyUsage.id ? privacyUsage.id : "Dato non disponibile"}
                                <br />
                                <span>Tipo licenza: </span>
                                {privacyUsage.type ? privacyUsage.type : "Dato non disponibile"}
                                <br />
                                <span>Livello: </span>
                                {privacyUsage.level ? privacyUsage.level : "Dato non disponibile"}
                                <br />
                                <span>Data scadenza: </span>
                                {privacyUsage.expiredDate
                                    ? new Date(privacyUsage.expiredDate).toLocaleString()
                                    : "Dato non disponibile"}
                                <br />
                            </div>
                        ) : null}
                    </Col>
                </Row>
                <br />
                <Row gutter={16}>
                    <Col span={12}>
                        <span className="a1">{"Consumi aziende"}</span> <br />
                        {privacySlot === null || privacyUsage.status.error ? (
                            <span>Nessun dato disponibile</span>
                        ) : (
                            <div className="usage">
                                <ResponsiveContainer>
                                    <BarChart data={dataCompany}>
                                        <Bar dataKey="capacity" fill="#61caf9" label={{ position: "top" }} />
                                        <Bar dataKey="used" fill="#9e9e9e" label={{ position: "top" }} />
                                        <Bar dataKey="coolDown" fill="#80e084" label={{ position: "top" }} />
                                        <Bar dataKey="locked" fill="	#FF0000" label={{ position: "top" }} />
                                        <Legend verticalAlign="top" height={36} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </Col>
                    <Col span={12}>
                        <span className="a1">{"Consumi documenti"}</span>
                        <br />
                        {privacySlot === null || privacyUsage.status.error ? (
                            <span>Nessun dato disponibile</span>
                        ) : (
                            <div className="usage">
                                <ResponsiveContainer>
                                    <BarChart data={dataDocument}>
                                        <Bar dataKey="capacity" fill="#61caf9" label={{ position: "top" }} />
                                        <Bar dataKey="used" fill="#9e9e9e" label={{ position: "top" }} />
                                        <Bar dataKey="coolDown" fill="#80e084" label={{ position: "top" }} />
                                        <Bar dataKey="locked" fill="	#FF0000" label={{ position: "top" }} />
                                        <Legend verticalAlign="top" height={36} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PrivacyService;
