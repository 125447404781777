import styled from "styled-components";
import { Button } from "@mondora/vrc";

export const ButtonWrapper = styled.div`
    margin-top: 10px;
    text-align: right;
`;
export const ButtonAction = styled(Button)`
    margin-left: 10px;
`;

export const ActionsWrapper = styled.div`
    padding: 0px 12px;
`;
export const StatusWrapper = styled.div`
    margin-top: 20px;
`;

export const CustomLi = styled.li`
    color: #4291f7;
    cursor: pointer;
`;
