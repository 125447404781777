import { USER_READ } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_ERROR = "SEARCH_USER_ERROR";

export function searchUser(auth, userId) {
    return async dispatch => {
        dispatch({
            type: SEARCH_USER_START
        });

        try {
            const result = await getRestCall(`${USER_READ}/users/${userId}`, auth, null, dispatch, auth.refreshToken);
            dispatch({
                type: SEARCH_USER_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: SEARCH_USER_ERROR,
                error: e
            });
        }
    };
}
