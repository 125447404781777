import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Searchbar } from "@mondora/arc/antd/Searchbar";

import { getSignDocument } from "../../../actions/signature/read";
import { displayErrorToast } from "../../../actions/toast";

const SignHubIdSearch = ({ auth, history, displayErrorToast, getSignDocument }) => {
    const onSearch = async hubIdSearched => {
        if (hubIdSearched.length === 24 || hubIdSearched.length === 36) {
            getSignDocument(auth.loginAuth, hubIdSearched.trim())
                .then(res => {
                    history.push(`/dashboard/sign/${hubIdSearched.trim()}/`);
                })
                .catch(err => {
                    displayErrorToast("HubId inserito non trovato.", true);
                });
        } else {
            displayErrorToast("HubId inserito non valido.", true);
        }
    };

    return <Searchbar placeholder="Inserisci HubId" onSearch={v => onSearch(v.value.trim())} enterButton />;
};

const mapStateToProps = state => ({
    auth: state.auth
});
const actions = { displayErrorToast, getSignDocument };

SignHubIdSearch.propTypes = {
    auth: PropTypes.object.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    getSignDocument: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(SignHubIdSearch);
