import styled from "styled-components";

export const ConfirmModalText = styled.div`
    max-height: 150px;
    overflow-y: auto;
`;

export const IconContainer = styled.span`
    margin-right: 3px;
`;

export const TagContainer = styled.div`
    padding: 15px;
    background-color: white;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid rgb(217, 217, 217);

    margin-bottom: 10px;

    max-height: 150px;
    overflow-y: auto;
`;

export const GroupSelectContainer = styled.div`
    padding-left: 20px;
`;

export const SectionContainer = styled.div``;

export const SendButtonContainer = styled.div`
    text-align: center;
`;

export const SelectLabel = styled.span`
    font-size: 12px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 15px;
`;

export const SpinnerContainer = styled.div`
    position: absolute;
    right: 50%;
    top: 50%;
`;

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;

export const ViewSection = styled.span`
    font-size: 16px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 15px;
`;
