import React, { Component } from "react";
import PropTypes from "prop-types";

import { Table, Badge } from "antd";

class GdprLogService extends Component {
    static propTypes = {
        active: PropTypes.object.isRequired,
        services: PropTypes.object.isRequired
    };

    renderGdprLog() {
        const {
            services: { gdprLog }
        } = this.props;

        const columns = [
            {
                title: "Stato",
                dataIndex: "confirmed",
                key: "confirmed",
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? "Attivo" : "Disattivo"}
                    </span>
                )
            },
            {
                title: "AgyoId",
                dataIndex: "agyoId",
                key: "agyoId"
            },
            {
                title: "Nome",
                dataIndex: "firstName",
                key: "firstName"
            },
            {
                title: "Cognome",
                dataIndex: "lastName",
                key: "lastName"
            }
        ];

        return gdprLog.gdprLogUsers ? (
            <div>
                <span className="a1">{"Utenti Abilitati"}</span>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={gdprLog.gdprLogUsers}
                    columns={columns}
                    rowKey={value => value.agyoId}
                    loading={gdprLog.status.started}
                    size="small"
                    expandedRowRender={record => (
                        <div>
                            <p>{"TsId: " + record.tsid}</p>
                            <p>{"Pec: " + record.pec}</p>
                        </div>
                    )}
                />
            </div>
        ) : (
            <div>
                <span>{"Nessun utente abilitato"}</span>
            </div>
        );
    }

    render() {
        return <div>{this.renderGdprLog()}</div>;
    }
}

export default GdprLogService;
