import {
    GET_CONFIG_ARCHIVE_START,
    GET_CONFIG_ARCHIVE_SUCCESS,
    GET_CONFIG_ARCHIVE_ERROR,
    GET_CONFIG_FOR_HOLDER_ARCHIVE_START,
    GET_CONFIG_FOR_HOLDER_ARCHIVE_SUCCESS,
    GET_CONFIG_FOR_HOLDER_ARCHIVE_ERROR,
    GET_DOWNLOAD_DRAFT_RDC_START,
    GET_DOWNLOAD_DRAFT_RDC_SUCCESS,
    GET_DOWNLOAD_DRAFT_RDC_ERROR,
    GET_INFO_DRAFT_RDC_START,
    GET_INFO_DRAFT_RDC_SUCCESS,
    GET_INFO_DRAFT_RDC_ERROR,
    GET_LIST_DRAFT_RDC_START,
    GET_LIST_DRAFT_RDC_SUCCESS,
    GET_LIST_DRAFT_RDC_ERROR,
    GET_CONFIG_FOR_SUBMITTER_ARCHIVE_START,
    GET_CONFIG_FOR_SUBMITTER_ARCHIVE_SUCCESS,
    GET_CONFIG_FOR_SUBMITTER_ARCHIVE_ERROR
} from "../../actions/archive/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function newArchiveConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CONFIG_ARCHIVE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CONFIG_ARCHIVE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_CONFIG_ARCHIVE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function newArchiveHolderConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CONFIG_FOR_HOLDER_ARCHIVE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CONFIG_FOR_HOLDER_ARCHIVE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_CONFIG_FOR_HOLDER_ARCHIVE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function newArchiveSubmitterConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CONFIG_FOR_SUBMITTER_ARCHIVE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CONFIG_FOR_SUBMITTER_ARCHIVE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_CONFIG_FOR_SUBMITTER_ARCHIVE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function newArchiveDownloadRDC(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_DOWNLOAD_DRAFT_RDC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_DOWNLOAD_DRAFT_RDC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_DOWNLOAD_DRAFT_RDC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function newArchiveInfoRDC(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INFO_DRAFT_RDC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INFO_DRAFT_RDC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INFO_DRAFT_RDC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function newArchiveListRDC(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_LIST_DRAFT_RDC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_LIST_DRAFT_RDC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_LIST_DRAFT_RDC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
