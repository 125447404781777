import styled from "styled-components";
import { Select } from "antd";
import { AsyncPaginate } from "react-select-async-paginate";

export const OrderFilterContent = styled.div`
    background: #f7f7f7;
    border-radius: 5px;
    padding: 10px 10px 5px;
`;

export const OrderFilterSelect = styled(Select)`
    width: 400px;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
`;

export const OrderFilterAsyncSelect = styled(AsyncPaginate)`
    width: 450px;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
`;

export const OrderFilterItems = styled.span`
    margin-right: 10px;
    margin-bottom: 5px;
`;
