import { B2B_PASSIVE_CONFIG } from "../../config";
import { callWithRetry } from "../../utils/callWithRetry";
import cuid from "cuid";
import uuid from "uuid/v4";

import B2bPassiveConfig from "../../assets/thrift/b2b-passive/B2BPassiveFlowConfig";
import B2bPassiveTypes from "../../assets/thrift/b2b-passive/v2_types";

import thrift from "browser-thrift";

const b2bPassiveUrl = new URL(B2B_PASSIVE_CONFIG);
const b2bPassiveClient = thrift.createXHRClient(
    B2bPassiveConfig,
    thrift.createXHRConnection(b2bPassiveUrl.hostname, 443, {
        useCors: true,
        path: b2bPassiveUrl.pathname,
        https: b2bPassiveUrl.protocol === "https:"
    })
);

export const GET_B2B_PASSIVE_CONFIG_START = "GET_B2B_PASSIVE_CONFIG_START";
export const GET_B2B_PASSIVE_CONFIG_ERROR = "GET_B2B_PASSIVE_CONFIG_ERROR";
export const GET_B2B_PASSIVE_CONFIG_SUCCESS = "GET_B2B_PASSIVE_CONFIG_SUCCESS";

export function getB2bPassiveConfig(auth, fiscalCode, page = 0, items = 10) {
    return async dispatch => {
        const authRegistry = new B2bPassiveTypes.Header({
            ...auth,
            accessToken: auth.securityToken,
            correlationId: uuid(),
            requestId: cuid()
        });

        let pagination = new B2bPassiveTypes.Pagination({
            page,
            items
        });
        let request = new B2bPassiveTypes.ListConfigsRequest({
            itemIds: [fiscalCode],
            pagination
        });
        dispatch({
            type: GET_B2B_PASSIVE_CONFIG_START,
            request: request
        });

        try {
            let response = await callWithRetry(
                b2bPassiveClient,
                b2bPassiveClient.listConfigs,
                authRegistry,
                request,
                auth.refreshToken,
                dispatch
            );

            let res = {
                configs: response.configs,
                totalElements: parseInt(response.totalElements) ? parseInt(response.totalElements) : 0,
                totalPages: parseInt(response.totalPages) ? parseInt(response.totalPages) : 0
            };

            dispatch({
                type: GET_B2B_PASSIVE_CONFIG_SUCCESS,
                payload: res
            });
            return res;
        } catch (e) {
            dispatch({
                type: GET_B2B_PASSIVE_CONFIG_ERROR,
                error: e
            });
        }
    };
}

export const GET_B2B_PASSIVE_CONFIG_RESET = "GET_B2B_PASSIVE_CONFIG_RESET";

export function resetB2bPassiveConfig() {
    return {
        type: GET_B2B_PASSIVE_CONFIG_RESET
    };
}
