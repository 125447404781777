import { isNil } from "ramda";

export function decodeItemSchema(itemData) {
    let dataToInsert;
    if (!isNil(itemData)) {
        dataToInsert = {
            city: itemData.base.details.addresses != null ? itemData.base.details.addresses[0].city : "",
            classifier: itemData.base.details.classifier,
            country: itemData.base.details.addresses != null ? itemData.base.details.addresses[0].country : "",
            description: itemData.base.details.description,
            province: itemData.base.details.addresses != null ? itemData.base.details.addresses[0].province : "",
            streetName: itemData.base.details.addresses != null ? itemData.base.details.addresses[0].streetName : "",
            streetNumber:
                itemData.base.details.addresses != null ? itemData.base.details.addresses[0].streetNumber : "",
            taxId: itemData.base.identifier.taxId,
            taxRegion: itemData.base.identifier.taxRegion,
            govCode: itemData.base.identifier.govCode != null ? itemData.base.identifier.govCode : null,
            vatNumber: itemData.base.identifier.vatNumber,
            zipCode: itemData.base.details.addresses != null ? itemData.base.details.addresses[0].zipCode : "",
            taxRegime: itemData.base.details.economics.taxRegime,
            balanceSheetDate:
                itemData.base.details.economics.balanceSheetDate != null &&
                itemData.base.details.economics.balanceSheetDate !== 0
                    ? new Date(itemData.base.details.economics.balanceSheetDate).toISOString()
                    : ""
        };
    }

    return dataToInsert;
}
