import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, Spin, Icon, message } from "antd";
import { injectIntl } from "react-intl";
import { saveAs } from "file-saver";

import { displaySuccessToast } from "../../actions/toast";
import { errorBoundary } from "../../components/general/error-boundary";
import { getPaymentTransactionInfo, getBankLinkDownloadFile } from "../../actions/bank-link/read";
import { BankLinkInfo } from "../../components/bank-link-info";

import { SpinContainer } from "./styled";

const BankLinkInfoView = ({
    auth,
    history,
    intl,
    getPaymentTransactionInfo,
    displaySuccessToast,
    getBankLinkDownloadFile,
    match: {
        params: { hubId }
    }
}) => {
    const viewModel = useBankLinkInfoView(
        auth.loginAuth,
        hubId,
        intl,
        getPaymentTransactionInfo,
        getBankLinkDownloadFile
    );

    return (
        <div>
            <Card style={{ height: "90vh", width: "100%" }}>
                <div onClick={() => history.goBack()} className="close">
                    <i className="far fa-times-circle close-icon" />
                </div>
                {viewModel.loading ? (
                    <SpinContainer>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                    </SpinContainer>
                ) : (
                    <BankLinkInfo
                        paymentTransactionInfo={viewModel.paymentTransactionInfo}
                        displaySuccessToast={displaySuccessToast}
                        handleDownloadFile={viewModel.handleDownloadFile}
                    />
                )}
            </Card>
        </div>
    );
};

BankLinkInfoView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getPaymentTransactionInfo: PropTypes.func.isRequired,
    displaySuccessToast: PropTypes.func.isRequired,
    getBankLinkDownloadFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company
});

const actions = { getPaymentTransactionInfo, displaySuccessToast, getBankLinkDownloadFile };

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(BankLinkInfoView);

const useBankLinkInfoView = (auth, hubId, intl, getPaymentTransactionInfo, getBankLinkDownloadFile) => {
    const [loading, setLoading] = useState(true);
    const [paymentTransactionInfo, setPaymentTransactionInfo] = useState(null);

    useEffect(() => {
        const getPaymentTransaction = async () => {
            let resultPaymentTransactionInfo = await getPaymentTransactionInfo(auth, hubId);
            if (resultPaymentTransactionInfo) {
                setPaymentTransactionInfo(resultPaymentTransactionInfo);
            }
            setLoading(false);
        };

        setLoading(true);
        setPaymentTransactionInfo(null);
        getPaymentTransaction();
    }, [auth, getPaymentTransactionInfo, hubId]);

    const handleDownloadFile = async () => {
        if (paymentTransactionInfo && paymentTransactionInfo.uploadId) {
            let resultFile = await getBankLinkDownloadFile(
                auth,
                paymentTransactionInfo.uploadId,
                paymentTransactionInfo.source === "XLS_FILE"
            );
            if (resultFile) {
                try {
                    if (paymentTransactionInfo.source === "CBI_FILE") {
                        var download = document.createElement("a");
                        download.setAttribute(
                            "href",
                            "data:application/octet-stream;charset=utf-16le;base64," + window.btoa(resultFile)
                        );
                        download.setAttribute("download", `BankLinK_File_${paymentTransactionInfo.uploadId}.txt`);
                        download.click();
                    } else {
                        let blob = new Blob([resultFile], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        saveAs(blob, `BankLinK_File_${paymentTransactionInfo.uploadId}.xls`);
                    }
                    message.success(
                        intl.formatMessage({
                            id: "the_file_downloaded_successfully"
                        })
                    );
                } catch (e) {
                    message.error(
                        intl.formatMessage({
                            id: "can_not_download_file"
                        })
                    );
                }
            } else {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        }
    };

    return {
        loading,
        paymentTransactionInfo,
        handleDownloadFile
    };
};
