import {
    SET_DOCUMENT,
    DOWNLOAD_CONTENT_ERROR,
    DOWNLOAD_CONTENT_START,
    DOWNLOAD_CONTENT_SUCCESS
} from "../../actions/document/info";
import { DOCUMENT_VIEW_START, DOCUMENT_VIEW_ERROR, DOCUMENT_VIEW_SUCCESS } from "../../actions/obligations-tax";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function infoD(state = defaultState, { type, payload, error }) {
    switch (type) {
        case SET_DOCUMENT:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        default:
            return state;
    }
}

export function content(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DOWNLOAD_CONTENT_START:
        case DOCUMENT_VIEW_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DOWNLOAD_CONTENT_ERROR:
        case DOCUMENT_VIEW_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        case DOWNLOAD_CONTENT_SUCCESS:
        case DOCUMENT_VIEW_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        default:
            return state;
    }
}
