import styled from "styled-components";

export const BodyContainer = styled.div`
    height: 450px;
`;

export const CenterContainer = styled.div`
    position: absolute;
    right: 50%;
    top: 50%;
`;
