import { GET_ORDERS_START, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR } from "../../../actions/order/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const getOrders = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_ORDERS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ORDERS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_ORDERS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
