import styled from "styled-components";

export const SpidSlotsBlock = styled.div`
    background: white;
    width: 50%;
    padding: 12px 24px;
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
`;
