import {
    REPLACE_TAX_ID_START,
    REPLACE_TAX_ID_SUCCESS,
    REPLACE_TAX_ID_ERROR,
    REPLACE_VAT_NUMBER_START,
    REPLACE_VAT_NUMBER_SUCCESS,
    REPLACE_VAT_NUMBER_ERROR,
    REPLACE_CLASSIFIER_START,
    REPLACE_CLASSIFIER_SUCCESS,
    REPLACE_CLASSIFIER_ERROR,
    REPLACE_IDENTIFIER_START,
    REPLACE_IDENTIFIER_SUCCESS,
    REPLACE_IDENTIFIER_ERROR
} from "../actions/corrections";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function taxId(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REPLACE_TAX_ID_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case REPLACE_TAX_ID_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REPLACE_TAX_ID_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function vatNumber(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REPLACE_VAT_NUMBER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case REPLACE_VAT_NUMBER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REPLACE_VAT_NUMBER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function classifier(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REPLACE_CLASSIFIER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case REPLACE_CLASSIFIER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REPLACE_CLASSIFIER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function identifier(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REPLACE_IDENTIFIER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case REPLACE_IDENTIFIER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REPLACE_IDENTIFIER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
