import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { OrderFilter } from "../../../../components/general/order-filter";
import { OrderFooter } from "../../../../components/general/order-footer";
import { OrdersTable } from "./orders-table";

export const OrdersTabs = ({
    company,
    loading,
    filter,
    handleFilterChange,
    expanded,
    dataSource,
    pageSize,
    pageSizeOptions,
    handlePageSizeChange,
    query,
    companyOptions,
    companySelected,
    handleCompanyChange
}) => {
    const getCheckFilter = useCheckFilter(filter, companyOptions, companySelected);

    const handleTableChange = (page, filters, sorter) => {
        let filter = {};
        if (filters) {
            let keys = Object.keys(filters);
            for (let key of keys) {
                if (filters[key] != null && filters[key].length > 0) {
                    if (key === "sellerSupplier.name") {
                        filter.sellerSupplier = filters[key][0];
                    } else if (key === "buyerCustomer.name") {
                        filter.buyerCustomer = filters[key][0];
                    } else if (key === "order.orderDetail.id") {
                        filter.orderId = filters[key][0];
                    } else if (key === "order.orderDetail.issuedAt") {
                        filter.orderDateFrom = filters[key][0][0].format("YYYY-MM-DD");
                        filter.orderDateTo = filters[key][0][1].format("YYYY-MM-DD");
                    } else if (key === "type") {
                        filter.orderType = filters[key][0];
                    } else if (key === "currentStatus.status") {
                        filter.currentStatus = filters[key][0];
                    } else if (key === "order.orderDetail.refersToOrderId.originalOrderDocumentReference") {
                        filter.orderDocumentReference = filters[key][0];
                    }
                }
            }
        }
        handleFilterChange(filter);
    };

    return (
        <div style={{ minHeight: 600 }}>
            <OrderFilter
                companyOptions={companyOptions}
                companySelected={companySelected}
                handleCompanyChange={handleCompanyChange}
                disabledCompanyOptions={!company.info.layers}
                disabledClearFilter={getCheckFilter.disabled}
                handleClearFilter={() => {
                    handleFilterChange({});
                    handleCompanyChange(companyOptions[0].key);
                }}
            />
            <OrdersTable
                dataSource={dataSource}
                loading={loading}
                handleTableChange={handleTableChange}
                expanded={expanded}
                filter={filter}
            />
            <OrderFooter
                pageSize={pageSize}
                disabled={dataSource == null || dataSource.length === 0}
                handlePageSizeChange={handlePageSizeChange}
                pageSizeOptions={pageSizeOptions}
                query={query}
            />
        </div>
    );
};

OrdersTabs.propTypes = {
    company: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    filter: PropTypes.object,
    handleFilterChange: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    dataSource: PropTypes.array.isRequired,
    pageSize: PropTypes.string.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    handlePageSizeChange: PropTypes.func.isRequired,
    query: PropTypes.any,
    companyOptions: PropTypes.array,
    companySelected: PropTypes.any,
    handleCompanyChange: PropTypes.func.isRequired
};

/*
 Custom hooks for this component
*/
const useCheckFilter = (filter, companyOptions, companySelected) => {
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        let disabled = true;
        if (filter != null) {
            if (
                filter.sellerSupplier == null &&
                filter.buyerCustomer == null &&
                filter.orderId == null &&
                filter.orderDateFrom == null &&
                filter.orderDateTo == null &&
                filter.orderType == null &&
                filter.currentStatus == null &&
                filter.orderDocumentReference == null &&
                (companyOptions.length <= 0 || companyOptions[0].key === companySelected)
            ) {
                disabled = true;
            } else {
                disabled = false;
            }
        } else {
            if (companyOptions.length <= 0 || companyOptions[0].key === companySelected) {
                disabled = true;
            } else {
                disabled = false;
            }
        }
        setDisabled(disabled);
    }, [filter, companyOptions, companySelected]);

    return {
        disabled
    };
};
