import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { Table, Badge, Button, Dropdown, Menu } from "antd";
import { MeteringTableBody } from "./styled";
import { useFeature } from "../../../../utils/hook/use-feature";
import { translateStatusPackage } from "../../../../utils/translate";
import { MeteringExpandedTable } from "../metering-expanded-table";

export const MeteringTable = ({
    meteringPackagesData,
    constraintsLoaded,
    meteringConstraintsData,
    extensible = false,
    extract = false,
    credits = false,
    share = false,
    refresh = false,
    disable = false,
    onExtensionsClick,
    onAssignCredit,
    onDownloadConsumption,
    onSharePackage,
    onRefreshPackage,
    onDisablePackage
}) => {
    const startCycle = "01-11-" + (new Date().getFullYear() - 1);
    const metering_extension = useFeature("Metering_Extension");
    const columns = [
        {
            title: <FormattedMessage id="c-meteringtable.state" />,
            dataIndex: "expireDate",
            key: "enabled",
            render: (text, record) => {
                const successBadge = (
                    <span>
                        <Badge status={"success"} />
                        <FormattedMessage id="c-meteringtable.active" />
                    </span>
                );

                const errorBadge = (
                    <span>
                        <Badge status={"error"} />
                        <FormattedMessage id="general.disabled" />
                    </span>
                );

                const readOnlyBadge = (
                    <span>
                        <Badge status={"processing"} />
                        <FormattedMessage id="c-meteringtable.readonly" />
                    </span>
                );

                switch (record.status) {
                    case "ACTIVE":
                        return successBadge;
                    case "EXPIRED":
                        return errorBadge;
                    case "READONLY_EXPIRE_DATE_REACHED":
                    case "READONLY_CONSTRAINT_LIMIT_REACHED":
                    case "READONLY_ADMIN_BLOCK":
                        return readOnlyBadge;
                    default:
                        return text && new Date(text) > new Date() ? successBadge : errorBadge;
                }
            }
        },
        {
            title: <FormattedMessage id="c-meteringtable.detail.state" />,
            dataIndex: "status",
            key: "status",
            render: text => translateStatusPackage(text)
        },
        {
            title: <FormattedMessage id="c-meteringtable.id" />,
            dataIndex: "id",
            key: "id"
        },
        {
            title: <FormattedMessage id="c-meteringtable.bbsid" />,
            dataIndex: "bbsId",
            key: "bbsId"
        },
        {
            title: <FormattedMessage id="c-meteringtable.autorenewal" />,
            dataIndex: "autoRenewal",
            key: "autoRenewal",
            render: (text, record) => (
                <span>
                    <Badge status={Boolean(text) ? "success" : "error"} />
                    {Boolean(text) ? (
                        <FormattedMessage id="c-meteringtable.yes" />
                    ) : (
                        <FormattedMessage id="c-meteringtable.no" />
                    )}
                </span>
            )
        },
        {
            title: <FormattedMessage id="c-meteringtable.insertiondate" />,
            dataIndex: "createDate",
            key: "createDate",
            render: value => <span>{new Date(value).toLocaleDateString()}</span>
        },
        {
            title: <FormattedMessage id="c-meteringtable.updatedate" />,
            dataIndex: "updateDate",
            key: "updateDate",
            render: value => <span>{new Date(value).toLocaleDateString()}</span>
        },
        {
            title: <FormattedMessage id="c-meteringtable.updatedby" />,
            dataIndex: "updatedBy",
            key: "updatedBy"
        },
        {
            title: <FormattedMessage id="c-meteringtable.expiredate" />,
            dataIndex: "expireDate",
            key: "expireDate",
            render: value => <span>{new Date(value).toLocaleDateString()}</span>
        },

        {
            title: "Azioni",
            align: "center",
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item
                    disabled={!extract}
                    key="0"
                    onClick={() => onDownloadConsumption(record.id, record.createDate, record.expireDate, true)}
                >
                    <i className="fas fa-download" />
                    <FormattedMessage id="c-meteringtable.dowloadCurrentConsumption" />
                </Menu.Item>
                {metering_extension &&
                    record.ownerId === record.itemId &&
                    record.status === "ACTIVE" &&
                    (record.family === "FATTURE_ATTIVE_PASSIVE" ||
                        record.family === "CRISI_IMPRESA" ||
                        record.family === "CRISI_IMPRESA_PRO" ||
                        record.family === "TSPAY" ||
                        record.family === "TSPAY_PLUS") && (
                        <Menu.Item disabled={!share} key="1" onClick={() => onSharePackage(record.ownerId)}>
                            <i className="fas fa-share-alt" />
                            <FormattedMessage id="c-meteringtable.share-service" />
                        </Menu.Item>
                    )}

                {metering_extension && (
                    <Menu.Item disabled={!refresh} key="2" onClick={() => onRefreshPackage(record.id)}>
                        <i className="fas fa-sync-alt refresh-icon" />
                        <FormattedMessage id="c-metering-action.refresh-package" />
                    </Menu.Item>
                )}

                {record.status === "ACTIVE" && record.ownerId !== record.itemId && (
                    <Menu.Item disabled={!disable} key="3" onClick={() => onDisablePackage(record.id)}>
                        <i className="fas fa-power-off" />
                        <FormattedMessage id="c-metering-action.disable-service" />
                    </Menu.Item>
                )}
                {moment(record.createDate).isBefore(moment(startCycle)) && (
                    <Menu.Item
                        disabled={!extract}
                        key="3"
                        onClick={() => onDownloadConsumption(record.id, record.createDate, record.expireDate, false)}
                    >
                        <i className="fas fa-cloud-download-alt" />

                        <FormattedMessage id="c-meteringtable.dowloadArchivedConsumption" />
                    </Menu.Item>
                )}
            </Menu>
        );
    };
    return (
        <MeteringTableBody>
            <span className="a1">
                <FormattedMessage id="c-meteringtable.title" />
            </span>
            <br />
            <Table
                rowKey={(record, index) => index}
                dataSource={meteringPackagesData}
                locale={{}}
                columns={columns}
                size="midell"
                pagination={false}
                expandedRowRender={(record, index) => (
                    <MeteringExpandedTable
                        idPackage={record.id}
                        expireDate={record.expireDate}
                        key={index}
                        loaded={constraintsLoaded}
                        meteringConstraintsData={meteringConstraintsData[index]}
                        onAssignCredit={onAssignCredit}
                        credits={credits}
                    />
                )}
            />
            <br />
        </MeteringTableBody>
    );
};

MeteringTable.propTypes = {
    meteringPackagesData: PropTypes.array.isRequired,
    meteringConstraintsData: PropTypes.array.isRequired,
    constraintsLoaded: PropTypes.bool.isRequired,
    extensible: PropTypes.bool,
    onExtensionsClick: PropTypes.func,
    onAssignCredit: PropTypes.func,
    onDownloadConsumption: PropTypes.func,
    onSharePackage: PropTypes.func
};
