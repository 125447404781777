import {
    GET_PORTAL_STATE_START,
    GET_PORTAL_STATE_SUCCESS,
    GET_PORTAL_STATE_ERROR,
    PUT_PORTAL_IN_MAINTNANCE_START,
    PUT_PORTAL_IN_MAINTNANCE_SUCCESS,
    PUT_PORTAL_IN_MAINTNANCE_ERROR
} from "../actions/portal.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function getStatus(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_PORTAL_STATE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_PORTAL_STATE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_PORTAL_STATE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function putInMaintenance(state = defaultState, { type, payload, error }) {
    switch (type) {
        case PUT_PORTAL_IN_MAINTNANCE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case PUT_PORTAL_IN_MAINTNANCE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case PUT_PORTAL_IN_MAINTNANCE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
