import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isNil, contains } from "ramda";
import { Select, Typography, message } from "antd";

import { userIsAuthorized } from "../../../../utils/get-roles";
import { InvoiceFilter } from "../../../../components/general/invoice-filter";
import { InvoicesSendTable } from "./invoices-send-table";
import { Container } from "./styled";
export const InvoicesSend = ({
    auth,
    admin,
    company,
    invoices,
    getInvoices,
    getInvoice,
    setDocument,
    history,
    untrashInvoice,
    trashInvoice,
    deleteInvoice,
    expanded,
    invoiceSendFilter,
    handleChangeInvoiceSendFilter,
    toggleSendTabs,
    invoiceSendSort,
    handleChangeInvoiceSendSort
}) => {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [allLayers, setAllLayers] = useState(null);
    const [companySelected, setCompanySelected] = useState(null);
    const [sdiId, setSdiId] = useState("");
    const [invoiceAuthorized, setInvoiceAuthorized] = useState(false);
    const [invoiceDataSource, setInvoiceDataSource] = useState([]);
    const [invoiceSendPageSize, setInvoiceSendPageSize] = useState("25");
    const [invoiceSendQuery, setInvoiceSendQuery] = useState("");
    const [invoiceSendRended, setInvoiceSendRended] = useState(false);
    const [invoiceSendFilterChanged, setInvoiceSendFilterChanged] = useState(false);
    const pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    useEffect(() => {
        if (admin.adminInfo != null) {
            let invoiceAuthorized = userIsAuthorized("c-companyInvoices-actions-column", admin.adminInfo.permits);
            setInvoiceAuthorized(invoiceAuthorized);
        } else {
            setInvoiceAuthorized(false);
        }
    }, [admin]);

    useEffect(() => {
        let arr_layers = [company.info.base.id];
        if (company.info.layers !== null) {
            for (var node of company.info.layers) {
                arr_layers.push(node.base.hierarchyId);
            }
        }
        arr_layers.sort();
        setAllLayers(arr_layers.join(","));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (invoices.status.ended && invoices._embedded != null) {
            setInvoiceDataSource(invoices._embedded.invoiceList);
        } else {
            setInvoiceDataSource([]);
        }
    }, [invoices]);

    useEffect(() => {
        if (allLayers) {
            let companyOptions = [];
            if (company.info.layers) {
                companyOptions.push(
                    <Select.Option value={allLayers} key={allLayers}>
                        {"Tutti gli uffici"}
                    </Select.Option>
                );
                companyOptions.push(
                    <Select.Option value={company.info.base.id} key={company.info.base.id}>
                        {company.info.base.details.description + " (" + company.info.base.id + ")"}
                    </Select.Option>
                );
                var sortedresult = company.info.layers.sort((a, b) =>
                    a.base.hierarchyId > b.base.hierarchyId ? 1 : b.base.hierarchyId > a.base.hierarchyId ? -1 : 0
                );
                sortedresult.forEach(layer => {
                    companyOptions.push(
                        <Select.Option value={layer.base.hierarchyId} key={layer.base.hierarchyId}>
                            {layer.base.details.description + " (" + layer.base.hierarchyId + ")"}
                        </Select.Option>
                    );
                });
            } else {
                companyOptions.push(
                    <Select.Option value={allLayers} key={allLayers}>
                        {company.info.base.details.description + " (" + company.info.base.id + ")"}
                    </Select.Option>
                );
            }
            setCompanyOptions(companyOptions);
            if (isNil(invoiceSendFilter)) {
                setCompanySelected(allLayers);
                let newFilters = {
                    ownerId: [allLayers],
                    active: [true],
                    trashed: [false]
                };
                handleGetDocuments(0, newFilters, invoiceSendSort);
            } else {
                setCompanySelected(invoiceSendFilter.ownerId);
                setSdiId(invoiceSendFilter.sdiId);
                handleGetDocuments(0, null, invoiceSendSort);
            }
            setInvoiceSendRended(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company.info, allLayers]);

    // Get documents for each navigation except first time
    useEffect(() => {
        if (invoiceSendRended) {
            handleGetDocuments(0, null, invoiceSendSort);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleSendTabs]);

    const handleInvoiceSendPageSize = value => {
        setInvoiceSendPageSize(value);
        getQueries(invoiceSendFilter, value, invoiceSendSort);
        let sort = sorterToSort(invoiceSendSort);
        getInvoices(auth.loginAuth, invoiceSendFilter, 0, value, sort);
        setInvoiceSendFilterChanged(invoiceSendFilterChanged ? false : true);
    };

    const handleLoadItem = item => {
        if (userIsAuthorized("c-companyInvoices-getInvoice", admin.adminInfo.permits)) {
            getInvoice(auth.loginAuth, { hubId: item.hubId }, 0, 10).then(result => {
                setDocument(result);
                history.push(`send/${item.hubId}`);
            });
        }
    };

    const managetrashInvoice = item => {
        if (invoiceSendFilter.trashed) {
            untrashInvoice(auth.loginAuth, item.hubId);
        } else {
            trashInvoice(auth.loginAuth, item.hubId);
        }
    };

    const managedeleteInvoice = item => {
        deleteInvoice(auth.loginAuth, item.hubId);
    };

    const handleGetDocuments = (page, filters, sorter) => {
        let filter = invoiceSendFilter || {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "currentStatusName") {
                        filter.statusName = filters[key][0];
                    } else if (key === "ownerId") {
                        filter.ownerId = filters[key][0];
                    } else if (key === "active") {
                        filter.active = filters[key][0];
                    } else if (key === "trashed") {
                        filter.trashed = filters[key][0];
                    } else if (key === "recipientName") {
                        filter.recipientName = filters[key][0];
                    } else if (key === "docId") {
                        filter.docId = filters[key][0];
                    } else if (key === "flowType") {
                        filter.flowTypes = filters[key][0];
                    } else if (key === "sdiId") {
                        filter.sdiId = filters[key][0];
                    }
                }
            }
        }
        let sort = sorterToSort(sorter);
        getQueries(filter, null, sorter);
        handleChangeInvoiceSendFilter(filter);
        handleChangeInvoiceSendSort(sorter);
        getInvoices(auth.loginAuth, filter, 0, invoiceSendPageSize, sort);
        setInvoiceSendFilterChanged(invoiceSendFilterChanged ? false : true);
    };

    const sorterToSort = sorter => {
        let sort = {};
        if (sorter) {
            if (sorter.columnKey === "docDate") {
                if (sorter.order === "ascend") {
                    sort = { sort: "invoiceDetail.dataDocumento,asc" };
                } else {
                    sort = { sort: "invoiceDetail.dataDocumento,desc" };
                }
            } else if (sorter.columnKey === "sdiDeliveryDate") {
                if (sorter.order === "ascend") {
                    sort = { sort: "sdiDeliveryDate,asc" };
                } else {
                    sort = { sort: "sdiDeliveryDate,desc" };
                }
            }
        }
        return sort;
    };

    const getQueries = (currentFilters, pageSize, currentSort) => {
        let size = pageSize || invoiceSendPageSize;
        let listFilter = [];
        if (currentFilters) {
            let keys = Object.keys(currentFilters);
            for (let key of keys) {
                if (currentFilters[key] != null && currentFilters[key] !== "") {
                    if (contains("ownerId", key) && contains(",", currentFilters[key])) {
                        listFilter.push(key + "=ALL");
                    } else {
                        if (key === "statusName") {
                            let value =
                                currentFilters[key] === "CARICATO"
                                    ? "IN_ELABORAZIONE"
                                    : currentFilters[key] === "INVIATO"
                                    ? "EMESSA"
                                    : currentFilters[key];
                            listFilter.push(key + "=" + value);
                        } else {
                            listFilter.push(key + "=" + currentFilters[key]);
                        }
                    }
                }
            }
            listFilter.push("size=" + size);
            if (currentSort && currentSort.columnKey) {
                listFilter.push("sort=" + currentSort.columnKey + "," + currentSort.order);
            }
        }
        let nueva = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setInvoiceSendQuery(nueva);
    };

    const handleClearFilter = () => {
        invoiceSendFilter.recipientName = null;
        invoiceSendFilter.docId = null;
        invoiceSendFilter.sdiId = null;
        invoiceSendFilter.flowTypes = null;
        invoiceSendFilter.statusName = null;
        invoiceSendFilter.ownerId = allLayers;
        invoiceSendFilter.active = true;
        invoiceSendFilter.trashed = false;
        setCompanySelected(allLayers);
        setSdiId("");
        handleChangeInvoiceSendFilter(invoiceSendFilter);
        // let sort = { columnKey: "docDate", order: "descend" };
        handleChangeInvoiceSendSort(null);
        handleGetDocuments(0, null, null);
        setInvoiceSendFilterChanged(invoiceSendFilterChanged ? false : true);
    };

    const handleCompanyChanged = value => {
        setCompanySelected(value);
        invoiceSendFilter.ownerId = value;
        handleChangeInvoiceSendFilter(invoiceSendFilter);
        handleGetDocuments(0, null, invoiceSendSort);
        setInvoiceSendFilterChanged(invoiceSendFilterChanged ? false : true);
    };

    const handleSdiIdChanging = e => {
        setSdiId(e.target.value);
    };

    const handleSdiIdChanged = e => {
        let searchValue = e.target.value;
        invoiceSendFilter.sdiId = null;
        handleChangeInvoiceSendFilter(invoiceSendFilter);
        if (searchValue !== "") {
            if (searchValue.length >= 6) {
                getInvoice(auth.loginAuth, { sdiId: searchValue.trim() }, 0, 10).then(response => {
                    if (response == null || response.page == null || response.page.size === 0) {
                        message.error("SdiId inserito non trovato.");
                    } else {
                        let result = response._embedded.invoiceList.find(
                            x => x.senderId === company.info.base.id && x.active === true
                        );
                        if (result) {
                            handleLoadItem(result);
                        } else {
                            message.error("SdiId inserito non trovato.");
                        }
                    }
                });
            } else {
                message.error("SdiId inserito non valido.");
            }
        } else {
            message.error("SdiId inserito non valido.");
        }
    };

    const handleInvoiceTrashedChanged = value => {
        invoiceSendFilter.trashed = value;
        handleChangeInvoiceSendFilter(invoiceSendFilter);
        handleGetDocuments(0, null, invoiceSendSort);
        setInvoiceSendFilterChanged(invoiceSendFilterChanged ? false : true);
    };

    return (
        <Container>
            <InvoiceFilter
                disabledClearFilter={
                    invoiceSendFilter == null ||
                    (invoiceSendFilter.recipientName == null &&
                        invoiceSendFilter.docId == null &&
                        invoiceSendFilter.sdiId == null &&
                        invoiceSendFilter.flowTypes == null &&
                        invoiceSendFilter.statusName == null &&
                        invoiceSendFilter.ownerId === allLayers &&
                        invoiceSendFilter.active === true &&
                        invoiceSendFilter.trashed === false)
                }
                handleClearFilter={handleClearFilter}
                companyOptions={companyOptions}
                disabledCompanyOptions={!company.info.layers}
                companySelected={companySelected}
                handleCompanyChanged={handleCompanyChanged}
                sdiId={sdiId}
                handleSdiIdChanging={handleSdiIdChanging}
                handleSdiIdChanged={handleSdiIdChanged}
                invoiceTrashed={invoiceSendFilter && invoiceSendFilter.trashed}
                handleInvoiceTrashedChanged={handleInvoiceTrashedChanged}
            />
            <InvoicesSendTable
                admin={admin}
                invoiceSendFilter={invoiceSendFilter}
                invoiceSendSort={invoiceSendSort}
                handleLoadItem={handleLoadItem}
                managetrashInvoice={managetrashInvoice}
                managedeleteInvoice={managedeleteInvoice}
                invoiceSendPageSize={invoiceSendPageSize}
                handleInvoiceSendPageSize={handleInvoiceSendPageSize}
                pageSizeOptions={pageSizeOptions}
                invoiceSendQuery={invoiceSendQuery}
                invoiceDataSource={invoiceDataSource}
                expanded={expanded}
                invoiceLoading={invoices.status.started && !invoices.status.error}
                handleGetDocuments={handleGetDocuments}
                invoiceAuthorized={invoiceAuthorized}
                invoiceSendFilterChanged={invoiceSendFilterChanged}
            />
        </Container>
    );
};

InvoicesSend.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getInvoices: PropTypes.func.isRequired,
    getInvoice: PropTypes.func.isRequired,
    setDocument: PropTypes.func.isRequired,
    trashInvoice: PropTypes.func.isRequired,
    deleteInvoice: PropTypes.func.isRequired,
    untrashInvoice: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    invoices: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    invoiceSendFilter: PropTypes.object,
    handleChangeInvoiceSendFilter: PropTypes.func.isRequired,
    toggleSendTabs: PropTypes.bool.isRequired,
    invoiceSendSort: PropTypes.object,
    handleChangeInvoiceSendSort: PropTypes.func.isRequired
};
