import { fieldRequired } from "@mondora/agyo-validations";

export const itemPrivacyDataSchema = {
    type: "object",
    properties: {
        firstName: {
            type: "string",
            validate: firstName => fieldRequired("Campo obbligatorio")(firstName)
        },
        lastName: {
            type: "string",
            validate: lastName => fieldRequired("Campo obbligatorio")(lastName)
        },
        role: {
            type: "string",
            validate: role => fieldRequired("Campo obbligatorio")(role)
        },
        documentPlace: {
            type: "string",
            validate: documentPlace => fieldRequired("Campo obbligatorio")(documentPlace)
        },
        documentDate: {
            type: "string",
            validate: documentDate => fieldRequired("Campo obbligatorio")(documentDate)
        },
        notificationConsent: {
            type: "string",
            validate: value => {
                if (value === undefined) {
                    return "Campo obbigatorio";
                }
            }
        },
        thirdPartyNotificationConsent: {
            type: "string",
            validate: value => {
                if (value === undefined) {
                    return "Campo obbigatorio";
                }
            }
        },
        analyticsConsent: {
            type: "string"
        }
    }
};
