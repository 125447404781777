import {
    FIND_OWN_MANAGED_CONNECTIONS_START,
    FIND_OWN_MANAGED_CONNECTIONS_ERROR,
    FIND_OWN_MANAGED_CONNECTIONS_SUCCESS,
    FIND_OWN_MANAGER_CONNECTIONS_START,
    FIND_OWN_MANAGER_CONNECTIONS_ERROR,
    FIND_OWN_MANAGER_CONNECTIONS_SUCCESS
} from "../../actions/connections/read";
import { DELETE_LINK_SUCCESS } from "../../actions/connections/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function findOwnManagedConnections(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FIND_OWN_MANAGED_CONNECTIONS_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FIND_OWN_MANAGED_CONNECTIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case FIND_OWN_MANAGED_CONNECTIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                }
            };
        case DELETE_LINK_SUCCESS:
            if (payload && payload.deleteActionContext === "MANAGED") {
                return {
                    ...state,
                    content: getConnectionsUpdatedContent(state, payload)
                };
            }
            return state;

        default:
            return state;
    }
}

export function findOwnManagerConnections(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FIND_OWN_MANAGER_CONNECTIONS_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FIND_OWN_MANAGER_CONNECTIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case FIND_OWN_MANAGER_CONNECTIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                }
            };

        case DELETE_LINK_SUCCESS:
            if (payload && payload.deleteActionContext === "MANAGER") {
                return {
                    ...state,
                    content: getConnectionsUpdatedContent(state, payload)
                };
            }
            return state;
        default:
            return state;
    }
}

/**
 * Otmistic update when user invoke deleteLink action
 * @param {*} state
 * @param {*} payload
 */

const getConnectionsUpdatedContent = (state, payload) => {
    const linkIndex = state.content.findIndex(
        link => link.connections.findIndex(c => c.id === payload.connectionId) !== -1
    );
    let link = { ...state.content[linkIndex] };

    const deletedConnectionIndex = link.connections.findIndex(c => c.id === payload.connectionId);
    let deletedConnection = {
        ...link.connections[deletedConnectionIndex],
        status: {
            ...link.connections[deletedConnectionIndex].status,
            active: false,
            deleted: true
        }
    };

    link.connections[deletedConnectionIndex] = deletedConnection;

    let updatedContent = [...state.content];
    updatedContent[linkIndex] = link;
    return updatedContent;
};
