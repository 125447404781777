import React from "react";
import { FormattedMessage } from "react-intl";

import { Modal, Form, Input, Alert, Tooltip, Icon } from "antd";

export const MeteringExtensionsModal = Form.create({ name: "form_in_modal" })(
    // eslint-disable-next-line
    class extends React.Component {
        render() {
            const {
                visible,
                onCancel,
                onCreate,
                form,
                title,
                okText = <FormattedMessage id="c-meteringextensionsmodal.extension" />,
                cancelText = <FormattedMessage id="c-meteringextensionsmodal.cancel" />,
                message = null
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    cancelText={cancelText}
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    {message != null ? (
                        <Alert message={message} banner style={{ margin: "-24px", marginBottom: "20px" }} />
                    ) : null}
                    <Form layout="vertical">
                        <Form.Item
                            label={
                                <span>
                                    <FormattedMessage id="c-meteringextensionsmodal.companycode" />
                                    <Tooltip
                                        title={<FormattedMessage id="c-meteringextensionsmodal.companycode-tooltip" />}
                                    >
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("id", {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="c-meteringextensionsmodal.companycode-error" />
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
