import styled from "styled-components";

export const Container = styled.div`
    width: 30%;
    margin: 0px 10px;
`;

export const ContainerTitle = styled.p`
    font-size: 15px;
    font-weight: bold;
    color: #595959;
`;

export const ValueContainer = styled.span`
    font-size: 50px;
`;

export const IconContainer = styled.i`
    position: absolute;
    left: 20px;
    font-size: 20px;
    margin-top: 15px;
`;
