import React from "react";
import { FormattedMessage } from "react-intl";

export function meteringPackageDecode(value) {
    let background;
    let icon;
    let title;
    let color;
    let description;

    switch (value) {
        case "ADEMPIMENTI":
            background = "#e6f7ff";
            color = "#00bcd4";
            icon = "fas fa-archive";
            title = <FormattedMessage id="c-meteringpackagedecode.fulfillments" />;
            description = "";
            return { background, icon, title, color, description };

        case "FICAGYO":
            background = "#ebf0f6";
            color = "#607D8B";
            icon = "fas fa-archive";
            title = <FormattedMessage id="c-meteringpackagedecode.ficagyo" />;
            description = "";
            return { background, icon, title, color, description };

        case "PEOPLEAPP":
            background = "#ebf0f6";
            color = "#607D8B";
            icon = "fas fa-archive";
            title = <FormattedMessage id="c-meteringpackagedecode.peopleapp" />;
            description = "";
            return { background, icon, title, color, description };

        case "OCR":
            background = "#ebf0f6";
            color = "#607D8B";
            icon = "fas fa-archive";
            title = <FormattedMessage id="c-meteringpackagedecode.ocr" />;
            description = "";
            return { background, icon, title, color, description };

        case "ADEMPIMENTI_MULTI":
            background = "#ebf0f6";
            color = "#607D8B";
            icon = "fas fa-archive";
            title = <FormattedMessage id="c-meteringpackagedecode.fulfillments-multi" />;
            description = "";
            return { background, icon, title, color, description };

        case "ADEMPIMENTI_CBCR":
            background = "#ebf0f6";
            color = "#607D8B";
            icon = "fas fa-archive";
            title = <FormattedMessage id="c-meteringpackagedecode.fulfillments-cbcr" />;
            description = "";
            return { background, icon, title, color, description };

        case "FATTURE_ATTIVE":
            background = "#fff2e8";
            color = "#f7ac1e";
            icon = "fas fa-file-alt";
            title = <FormattedMessage id="c-meteringpackagedecode.activeinvoice" />;
            description = "";
            return { background, icon, title, color, description };

        case "PRIVACY":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-key";
            title = <FormattedMessage id="c-meteringpackagedecode.privacy" />;
            description = "";
            return { background, icon, title, color, description };

        case "NOTE_SPESE":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-sticky-note";
            title = <FormattedMessage id="c-meteringpackagedecode.expensereports" />;
            description = "";
            return { background, icon, title, color, description };

        case "FIRMA_FAQ_DOCUMENTI":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-pencil-alt";
            title = <FormattedMessage id="c-meteringpackagedecode.signature-fga-doc" />;
            description = "";
            return { background, icon, title, color, description };

        case "FIRMA_FGA":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-pencil-alt";
            title = <FormattedMessage id="c-meteringpackagedecode.signature-fga" />;
            description = "";
            return { background, icon, title, color, description };

        case "FIRMA_FRQ":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-pencil-alt";
            title = <FormattedMessage id="c-meteringpackagedecode.signature-frq" />;
            description = "";
            return { background, icon, title, color, description };

        case "FIRMA_FAQ":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-pencil-alt";
            title = <FormattedMessage id="c-meteringpackagedecode.signature-faq" />;
            description = "";
            return { background, icon, title, color, description };

        case "FIRMA_PRO_SK":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-pencil-alt";
            title = <FormattedMessage id="c-meteringpackagedecode.signature-pro-sk" />;
            description = "";
            return { background, icon, title, color, description };

        case "DG_SIGN_QUAL":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-signature";
            title = <FormattedMessage id="c-meteringpackagedecode.digital-signature-qual" />;
            description = "";
            return { background, icon, title, color, description };

        case "DG_SIGN_MASS":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-signature";
            title = <FormattedMessage id="c-meteringpackagedecode.digital-signature-mass" />;
            description = "";
            return { background, icon, title, color, description };

        case "DG_SIGN_GRAF":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-signature";
            title = <FormattedMessage id="c-meteringpackagedecode.digital-signature-graf" />;
            description = "";
            return { background, icon, title, color, description };

        case "DG_SIGN_CONN":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-signature";
            title = <FormattedMessage id="c-meteringpackagedecode.digital-signature-conn" />;
            description = "";
            return { background, icon, title, color, description };

        case "DG_SIGN_DOC":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-signature";
            title = <FormattedMessage id="c-meteringpackagedecode.digital-signature-doc" />;
            description = "";
            return { background, icon, title, color, description };

        case "DG_SIGN_PRM":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-signature";
            title = <FormattedMessage id="c-meteringpackagedecode.digital-signature-prm" />;
            description = "";
            return { background, icon, title, color, description };

        case "FATTURE_PASSIVE":
            background = "#fff0f6";
            color = "#e9397a";
            icon = "fas fa-paste";
            title = <FormattedMessage id="c-meteringpackagedecode.passiveinvoice" />;
            description = "";
            return { background, icon, title, color, description };

        case "FATTURE_ATTIVE_PASSIVE":
            background = "#e6f7ff";
            color = "#00bcd4";
            icon = "fas fa-paper-plane";
            title = <FormattedMessage id="c-meteringpackagedecode.activepassiveinvoice" />;
            description = "";
            return { background, icon, title, color, description };

        default:
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-archive";
            title = (
                <div>
                    <FormattedMessage id="c-meteringpackagedecode.empty" />
                    {String(value).replace(/_/gi, " ")}
                </div>
            );
            description = "";
            return { background, icon, title, color, description };
    }
}
