import styled from "styled-components";

export const SpinnerContainer = styled.div`
    position: absolute;
    right: 50%;
    top: 50%;
`;

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;
