import styled from "styled-components";
import { Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ActionContainer = styled.div`
    text-align: right;
    margin: 20px 10px;
    margin-right: 30px;
`;

export const ActionEnable = styled.span`
    width: 32px;
    font-size: 16px;
    color: #0090d1;
    cursor: pointer;
    margin-right: 20px;
`;

export const ActionDisabled = styled(ActionEnable)`
    color: #c9d9e8;
    cursor: default;
`;

export const EventTitle = styled.p`
    font-size: 20px;
    color: #005075;
`;
export const RowWithValues = styled(Row)`
    color: #000000;
`;

export const StepIcon = styled(FontAwesomeIcon)`
    color: #0090d1;
`;

export const EventList = styled.div`
    overflow: auto;
    max-height: 35vh;
    margin-bottom: 30px;
`;
