import React, { useState, useCallback, useEffect } from "react";

import { Modal, DatePicker } from "antd";
import { saveAs } from "file-saver";
import { FormattedMessage } from "react-intl";
import moment from "moment";
const { RangePicker } = DatePicker;

export const ConsumptionModal = ({
    auth,
    company,
    downloadModal,
    onLineConsumptions,
    family,
    packageId,
    title,
    startDate,
    expireDate,
    onCancel,
    getFileConsumptions
}) => {
    const [filter, setFilter] = useState({
        from: null,
        to: null
    });
    const [typeList, setTypeList] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFilter({
            from: moment.utc(startDate),
            to: moment().isAfter(moment(expireDate))
                ? moment.utc(expireDate)
                : moment()
                      .utc()
                      .endOf("day")
        });
    }, [startDate, expireDate]);

    useEffect(() => {
        if (family === "FATTURE_ATTIVE_PASSIVE") setTypeList(["INVOICE", "ORDER", "RECEIPT"]);
        else if (family === "TSPAY") setTypeList("TRANSACTION");
        else if (family === "CRISI_IMPRESA") setTypeList(["RISK_ASSESSMENT", "TROUBLED_COMPANIES"]);
        else if (family.includes("SIGN")) setTypeList("SIGNABLE_DOCUMENTS");
    }, [family]);

    const handlePeriodChange = useCallback(
        (dates, period) => {
            if (!period || period.length < 2) {
                return;
            }
            const newFrom = moment.utc(period[0], "DD/M/YYYY").startOf("day");
            const newTo = moment.utc(period[1], "DD/M/YYYY").endOf("day");
            if (newFrom.isSame(filter.from, "day") && newTo.isSame(filter.to, "day")) {
                return;
            }
            setFilter({
                ...filter,
                from: newFrom,
                to: newTo
            });
        },
        [filter]
    );

    const handleExtractZip = async packageId => {
        setLoading(true);
        let result = await getFileConsumptions(
            auth.loginAuth,
            packageId,
            filter.from.format(),
            filter.to.format(),
            company.info.base.uuid,
            typeList,
            onLineConsumptions,
            0,
            10
        );
        if (result !== null) {
            setLoading(false);
            var blob = new Blob([result], { type: "application/octet-stream" });
            saveAs(blob, "Consumi_" + company.info.base.id + "_" + packageId + ".zip");
        }
    };
    return (
        <Modal
            visible={downloadModal}
            title={title}
            okText={<FormattedMessage id="metering_extract_consumtpion_ok_button" />}
            cancelText={<FormattedMessage id="delete" />}
            onCancel={onCancel}
            onOk={() => handleExtractZip(packageId)}
            okButtonProps={{
                loading: loading
            }}
        >
            {onLineConsumptions ? (
                <p>
                    <FormattedMessage id="metering_extract_consumtpion_range_date" />
                </p>
            ) : (
                <FormattedMessage id="metering_extract_consumtpion_extract_file" />
            )}
            {onLineConsumptions ? (
                <RangePicker
                    value={[filter.from, filter.to]}
                    size="large"
                    onChange={handlePeriodChange}
                    format="DD/MM/YYYY"
                    disabledDate={current => {
                        return current && (current > filter.to || current < filter.from);
                    }}
                />
            ) : null}
        </Modal>
    );
};
