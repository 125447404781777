import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon } from "../../../styled";
import { CustomPagination, ActionCel } from "./styled";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

import { translateitemStatus } from "../../../utils/translate";

const locale = {
    emptyText: <FormattedMessage id="general.no-result" />
};
const ListConnection = ({ auth, id, history, findOwnManagedConnections }) => {
    const [connections, setConnections] = useState({ totalElements: 0 });
    const [pagination, setPagination] = useState({ pageNumber: 0, totalElements: 0, loading: true });

    const columns = [
        {
            title: <FormattedMessage id="general.itemDescription" />,
            dataIndex: "managedDescription"
        },
        {
            title: <FormattedMessage id="general.id" />,
            dataIndex: "managedId"
        },
        {
            title: <FormattedMessage id="general.status" />,
            dataIndex: "connections[0].status.certificationStatus",
            render: text => <span>{translateitemStatus(text)}</span>
        },
        {
            title: "",
            render: (text, record) => (
                <ActionCel>
                    {record.connections[0].status.certificationStatus === "AWAITING_UPLOAD" &&
                    record.connections[0].status.status !== "PENDING_VALIDATION" ? (
                        <FontAwesomeIcon icon={faArrowRight} />
                    ) : (
                        <ActionIcon
                            icon={faArrowRight}
                            onClick={() => history.push(`/certifications/contract/${record.connections[0].id}`)}
                        />
                    )}
                </ActionCel>
            )
        }
    ];

    useEffect(() => {
        const getStudioConnections = async () => {
            const filter = {
                managerIds: id,
                featureCode: "SDI",
                active: true,
                deleted: false,
                status: ["PENDING_VALIDATION", "VALIDATED", "VALIDATION_REJECTED"]
            };

            let connectedItems = await findOwnManagedConnections(auth.loginAuth, filter, {
                page: 0,
                items: 10
            });

            if (connectedItems.totalElements !== 0) {
                setConnections(connectedItems);
                setPagination({ pageNumber: 0, totalElements: connectedItems.totalElements, loading: false });
            } else {
                setConnections({ totalElements: 0 });
                setPagination({ loading: false });
            }
        };
        getStudioConnections();
    }, [auth.loginAuth, id, findOwnManagedConnections]);

    const changePage = async page => {
        setPagination({
            ...pagination,
            loading: true
        });
        const filter = {
            managerIds: id,
            featureCode: "SDI",
            active: true,
            deleted: false,
            status: ["PENDING_VALIDATION", "VALIDATED", "VALIDATION_REJECTED"]
        };

        let connectedItems = await findOwnManagedConnections(auth.loginAuth, filter, {
            page: page - 1,
            items: 10
        });

        if (connectedItems.totalElements !== 0) {
            setConnections(connectedItems);
            setPagination({
                pageNumber: connectedItems.number,
                totalElements: connectedItems.totalElements,
                loading: false
            });
        }
    };

    return (
        <div>
            <Table
                columns={columns}
                dataSource={connections.content}
                rowKey={record => record.id}
                pagination={false}
                loading={pagination.loading}
                locale={locale}
                footer={() => (
                    <div>
                        <CustomPagination
                            defaultCurrent={pagination.pageNumber + 1}
                            total={pagination.totalElements}
                            onChange={page => changePage(page)}
                        />
                    </div>
                )}
            />
        </div>
    );
};
export default ListConnection;
