import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tag, Dropdown, Menu, Button, Select, Typography, Tooltip } from "antd";

import { translateNCSAppCode } from "../../../../utils/translate";
import { OrderFooter } from "../../../../components/general/order-footer";
import { OrderFilter } from "../../../../components/general/order-filter";

export const NotificationPreferences = ({
    isPersonal,
    ncsPreferenceList,
    groupId,
    handleChangeGroupId,
    handleViewChannel,
    loading,
    lazyLoadCompanyOptions,
    initialCompanyOptions
}) => {
    const [pageSize, setPageSize] = useState("25");
    const [data, setData] = useState([]);

    const [query, setQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let data = [];
        if (ncsPreferenceList) {
            if (ncsPreferenceList.length + 1 > pageSize) {
                data = ncsPreferenceList.slice(0, pageSize);
            } else {
                data = ncsPreferenceList;
            }
        }
        setData(data);
    }, [ncsPreferenceList, pageSize]);

    useEffect(() => {
        let listFilter = [];
        if (groupId) {
            listFilter.push("groupId=" + groupId.value);
        }
        listFilter.push("size=" + pageSize);
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQuery(query);
    }, [pageSize, groupId]);

    let columns = [
        {
            title: <FormattedMessage id={"app"} />,
            dataIndex: "appCode",
            render: text => <span>{translateNCSAppCode(text)}</span>
        },
        {
            title: <FormattedMessage id={"notification_type"} />,
            dataIndex: "name"
        },
        {
            title: <FormattedMessage id={"channels"} />,
            dataIndex: "channels",
            render: (text, record) => <span>{renderChannelsTag(text, record.availableChannels)}</span>
        },
        {
            title: <FormattedMessage id="actions" />,
            width: "5%",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="0" onClick={() => handleViewChannel(record)}>
                                    <i className="fas fa-info-circle" /> <FormattedMessage id={"show"} />
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    const renderChannelsTag = (channels, availableChannels) => {
        let items = [];
        if (channels && channels.length) {
            channels.forEach((item, index) =>
                items.push(
                    <Tag color="green" key={"channels" + index} style={{ marginBottom: 8 }}>
                        {item}
                    </Tag>
                )
            );
        }

        if (availableChannels && availableChannels.length) {
            availableChannels.forEach((item, index) => {
                if (!channels.includes(item)) {
                    items.push(
                        <Tooltip
                            key={"tooltip-availableChannels" + index}
                            title={<FormattedMessage id={"it_is_available_but_not_active"} />}
                        >
                            <Tag color="gray" key={"availableChannels" + index} style={{ marginBottom: 8 }}>
                                {item}
                            </Tag>
                        </Tooltip>
                    );
                }
            });
        }

        if (!items.length) {
            items.push(
                <Tag color="red" key={"1"} style={{ marginBottom: 8 }}>
                    <FormattedMessage id={"not_available"} />
                </Tag>
            );
        }
        return items;
    };

    return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
            {!isPersonal && (
                <OrderFilter
                    companySelected={groupId}
                    handleCompanyChange={handleChangeGroupId}
                    disabledCompanyOptions={loading}
                    hideClearFilter={true}
                    lazyMode={true}
                    handleLoadOptionsLazy={lazyLoadCompanyOptions}
                    initialCompanyOptions={initialCompanyOptions}
                />
            )}

            <div style={{ marginTop: 20, marginBottom: 10, overflow: "auto", height: "52vh" }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    loading={loading}
                    dataSource={data || []}
                    expandedRowRender={record => (
                        <div>
                            <b>
                                <FormattedMessage id={"id"} />:{" "}
                            </b>
                            {record.id ? record.id : <FormattedMessage id={"not_available"} />}
                        </div>
                    )}
                    pagination={false}
                />
            </div>
            <OrderFooter
                pageSize={pageSize}
                disabled={!data || !data.length || loading}
                handlePageSizeChange={value => setPageSize(value)}
                pageSizeOptions={pageSizeOptions}
                query={query}
            />
        </div>
    );
};

NotificationPreferences.propTypes = {
    ncsPreferenceList: PropTypes.array.isRequired,
    isPersonal: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    groupId: PropTypes.any,
    handleChangeGroupId: PropTypes.func,
    handleViewChannel: PropTypes.func,
    lazyLoadCompanyOptions: PropTypes.func,
    initialCompanyOptions: PropTypes.array
};
