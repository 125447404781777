import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

import { Button, Drawer, Table, Modal, Input, Switch, Dropdown, Menu } from "antd";
import { techniciansRoles } from "../../../utils/constant";
import { userIsAuthorized } from "../../../utils/get-roles";

import CompanyInfo from "../../company/info";
const Search = Input.Search;
const locale = {
    emptyText: "Nessun ruolo presente"
};

class UserRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mDelete: false,
            itemDrawer: false,
            roleSelect: "",
            selectedRoles: []
        };
    }
    static propTypes = {
        admin: PropTypes.object.isRequired,
        isAdmPage: PropTypes.bool,
        openAddRole: PropTypes.func,
        toggleRegistered: PropTypes.bool.isRequired,
        handleAddRoles: PropTypes.func.isRequired,
        handleRemoveRoles: PropTypes.func.isRequired,
        handleSelectedRoles: PropTypes.func.isRequired,
        handleGetCompanyAndServiceInfo: PropTypes.func,
        companyInfo: PropTypes.object,
        loadingGetCompanyInfo: PropTypes.bool,
        userInfo: PropTypes.object
    };

    handleSelect(roleSelect) {
        this.setState({ mDelete: true, roleSelect: roleSelect });
    }

    handleLoadItem(role) {
        const { handleGetCompanyAndServiceInfo } = this.props;
        this.setState({ itemDrawer: true });
        handleGetCompanyAndServiceInfo(role.resourceId, role.resourceUuid);
    }

    handleDelete() {
        const { handleRemoveRoles } = this.props;
        const { roleSelect } = this.state;
        let roleToDelete = {
            appId: roleSelect.appId,
            resourceId: roleSelect.resourceId,
            actionKey: roleSelect.actionKey,
            featureCode: roleSelect.featureCode
        };
        handleRemoveRoles([roleToDelete]);
        this.setState({ mDelete: false });
    }

    enableDisableTechniciansRole(isToEnable, roleValues) {
        const { handleAddRoles, handleRemoveRoles } = this.props;
        let role = {
            appId: roleValues.appId,
            resourceId: roleValues.resourceId,
            actionKey: roleValues.actionKey,
            featureCode: roleValues.featureCode
        };
        if (isToEnable) {
            handleAddRoles([role]);
        } else {
            handleRemoveRoles([role]);
        }
    }

    getFilteredRoles() {
        const { isAdmPage, toggleRegistered, userInfo } = this.props;
        const { resourceIdVal } = this.state;
        if (userInfo.roles) {
            if (toggleRegistered) return userInfo.roles.filter(x => x["resourceId"] === "REGISTERED");
            let filtered_roles = userInfo.roles.filter(
                x =>
                    x["resourceId"] !== "REGISTERED" &&
                    (isAdmPage ? x["resourceId"] === "BACKOFFICE" : x["resourceId"] !== "BACKOFFICE")
            );
            if (resourceIdVal !== undefined) {
                return filtered_roles.filter(x => x["resourceId"].includes(resourceIdVal));
            } else {
                return filtered_roles;
            }
        }
    }

    renderAction(roleValue) {
        const {
            admin: {
                adminInfo: { permits }
            }
        } = this.props;
        return (
            <Menu>
                <Menu.Item
                    disabled={!userIsAuthorized("c-userRoles-delete-role", permits)}
                    key="1"
                    onClick={() => this.handleSelect(roleValue)}
                >
                    <i className="fas fa-trash" /> Elimina ruolo
                </Menu.Item>
            </Menu>
        );
    }

    render() {
        const {
            isAdmPage,
            openAddRole,
            toggleRegistered,
            userInfo,
            loadingGetCompanyInfo,
            companyInfo,
            handleSelectedRoles
        } = this.props;
        const { mDelete, roleSelect } = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                handleSelectedRoles(selectedRows);
            }
        };

        const columns = [
            {
                title: "AppId",
                dataIndex: "appId"
            },
            {
                title: "FeatureCode",
                dataIndex: "featureCode"
            },
            {
                title: "Risorsa",
                dataIndex: "resourceId"
            },
            {
                title: "Azione",
                dataIndex: "actionKey"
            },
            {
                title: "",
                align: "center",
                render: (text, record) => (
                    <div style={{ textAlign: "right" }}>
                        <Dropdown.Button
                            disabled={isAdmPage}
                            overlay={this.renderAction(record)}
                            trigger={["click"]}
                            placement="bottomRight"
                            onClick={() => this.handleLoadItem(record)}
                        >
                            Visualizza
                        </Dropdown.Button>
                    </div>
                )
            }
        ];
        const techniciansColumns = [
            {
                title: "Descrizione",
                dataIndex: "description"
            },
            {
                title: "",
                render: (text, record) => (
                    <div className="c-user-roles-action-button">
                        <Switch
                            checked={
                                userInfo &&
                                userInfo.roles &&
                                userInfo.roles.some(role => role.actionKey === record.actionKey)
                            }
                            onChange={value => this.enableDisableTechniciansRole(value, record)}
                        />
                    </div>
                )
            }
        ];

        return (
            <div className="c-user-roles">
                {toggleRegistered ? (
                    <Table
                        rowKey={(record, index) => index}
                        columns={techniciansColumns}
                        dataSource={techniciansRoles}
                        size="small"
                        pagination={false}
                    />
                ) : (
                    <Table
                        rowKey={(record, index) => index}
                        columns={columns}
                        dataSource={this.getFilteredRoles()}
                        rowSelection={rowSelection}
                        size="small"
                        title={() =>
                            !isAdmPage && (
                                <div className="table-title">
                                    <Search
                                        placeholder="Inserisci l'indentificativo della risorsa che stai cercando..."
                                        onSearch={val =>
                                            this.setState({
                                                resourceIdVal: val
                                            })
                                        }
                                        enterButton
                                    />
                                    <div className="add-button">
                                        <Button type="primary" onClick={openAddRole}>
                                            {"Aggiungi"}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                        locale={locale}
                        pagination={false}
                    />
                )}

                <Modal
                    centered
                    okText="Si"
                    cancelText="No"
                    onCancel={() => this.setState({ mDelete: false })}
                    onOk={() => this.handleDelete()}
                    className="modal"
                    title={"Elimina ruolo " + roleSelect.actionKey}
                    visible={mDelete}
                >
                    {"Sei sicuro di voler eliminare il ruolo " +
                        roleSelect.actionKey +
                        " sulla risorsa " +
                        roleSelect.resourceId +
                        " con featureCode " +
                        roleSelect.featureCode +
                        " per questo utente?"}
                </Modal>
                <Drawer
                    width={550}
                    placement="right"
                    closable={false}
                    onClose={() => this.setState({ itemDrawer: false })}
                    visible={this.state.itemDrawer}
                >
                    <div style={{ margin: "20px" }}>
                        <CompanyInfo company={companyInfo} onlyInfo={true} loading={loadingGetCompanyInfo} />
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default UserRoles;
