import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerIdentity = styled.div`
    padding-left: 15px;
    padding-top: 15px;
    min-width: 400px;
    width: 50%;
`;

export const ContainerUsage = styled.div`
    padding-top: 15px;
    min-width: 400px;
    padding-right: 15px;
    width: 50%;
`;

export const ContainerTitle = styled.span`
    font-weight: bold;
    font-size: 24px;
`;

export const ContainerSubTitle = styled.span`
    font-weight: bold;
    font-size: 18px;
`;
export const ContainerField = styled.span`
    font-weight: bold;
`;

export const DivContainer = styled.div``;
