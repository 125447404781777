import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { getFAWConfig } from "../../../actions/invoice/read";
import { InvoiceTrading } from "../../../components/company/invoice-trading";

const CompanyInvoiceTradingView = ({ auth, company, history, getFAWConfig, fawConfig }) => {
    useEffect(() => {
        if (company.info.base.id) {
            getFAWConfig(auth.loginAuth, company.info.base.id);
        }
    }, [company.info.base, auth, getFAWConfig]);

    return (
        <ServiceCard
            history={history}
            content={<InvoiceTrading fawConfig={fawConfig} />}
            serviceInfo={{ name: "Invoice Trading", category: "EIT" }}
            statisticsEnable={false}
        />
    );
};

CompanyInvoiceTradingView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    fawConfig: PropTypes.object.isRequired,
    getFAWConfig: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company,
    fawConfig: state.invoice.read.getFAWConfig
});

const actions = {
    getFAWConfig
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyInvoiceTradingView);
