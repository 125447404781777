import { invoices, getInvoice, getFAWConfig, getEligibleInvoices } from "./read";
import { combineReducers } from "redux";

const read = combineReducers({
    invoices,
    getFAWConfig,
    getInvoice,
    getEligibleInvoices
});

export const invoice = combineReducers({
    read
});
