import { API_URL } from "../../config";
import { postRestCall } from "../../utils/rest-api-call";

export const ADD_CREDITS_START = "ADD_CREDITS_START";
export const ADD_CREDITS_SUCCESS = "ADD_CREDITS_SUCCESS";
export const ADD_CREDITS_ERROR = "ADD_CREDITS_ERROR";

export const addCredits = (auth, packageId, constraintId, quantity) => {
    return async dispatch => {
        const urlParams = {
            quantity: quantity
        };
        const toast = {
            onOk: true,
            onOkText: "I crediti sono stati assegnati con successo",
            onError: true,
            infoReq: { authId: auth.id, req: urlParams }
        };
        dispatch({
            type: ADD_CREDITS_START,
            request: urlParams
        });

        try {
            const result = await postRestCall(
                `${API_URL}/packageId/${packageId}/constraints/${constraintId}`,
                auth,
                urlParams,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: ADD_CREDITS_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: ADD_CREDITS_ERROR,
                error: e
            });
        }
    };
};
