import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Spin, Drawer, Select, Button } from "antd";

import CardConnections from "./card";
import ConnectionInfo from "./info";
import CompanyInfo from "../../company/info";
import { ConnectionFilter } from "./filter";

export const CompanyConnections = ({
    admin,
    linkType,
    loading,
    connectionList,
    totalItem,
    currentPage,
    handlePageChange,
    handleFilterChange,
    connectionFilter,
    serviceList,
    companyId,
    handleGetCompanyInfo,
    handleDeleteLink,
    handleGetConnectionDocument,
    handleDownloadCSVFile,
    pageSize,
    handleUploadADA
}) => {
    const [showPdf, setShowPdf] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const [showItem, setShowItem] = useState(false);
    const [servicesForFilterOption, setServicesForFilterOption] = useState([]);
    const [contractContent, setContractContent] = useState(null);
    const [connectionService, setConnectionService] = useState(null);
    const [connectionSelected, setConnectionSelected] = useState(null);

    useEffect(() => {
        let servicesForFilterOption = [];
        let servicesForFilter = [];

        if (serviceList.length) {
            serviceList.forEach(service => {
                if (service.connectable) {
                    if (servicesForFilter.findIndex(x => x.appId === service.appId) === -1) {
                        servicesForFilter.push({
                            appId: service.appId,
                            description: service.appDescription
                        });
                    }
                }
            });

            servicesForFilterOption = servicesForFilter.map((service, index) => (
                <Select.Option key={index} value={service.appId}>
                    {service.description}
                </Select.Option>
            ));

            servicesForFilterOption.push(
                <Select.Option key="Tutti i servizi" value={null}>
                    Tutti i servizi
                </Select.Option>
            );
        }
        setServicesForFilterOption(servicesForFilterOption);
    }, [serviceList]);

    const handleOpenInfo = async (connectionService, connectionSelected) => {
        const showInfoWithPdf = content => {
            setContractContent(content);
            setShowPdf(true);
            setConnectionService(connectionService);
            setConnectionSelected(connectionSelected);
            setShowInfo(true);
        };
        const showInfo = () => {
            setContractContent(null);
            setShowPdf(false);
            setConnectionService(connectionService);
            setConnectionSelected(connectionSelected);
            setShowInfo(true);
        };
        if (connectionService.featureCode === "SDI") {
            let infoContract = await handleGetConnectionDocument(connectionService.id);
            if (infoContract && infoContract.content) {
                showInfoWithPdf(infoContract.content);
            } else {
                showInfo();
            }
        } else {
            showInfo();
        }
    };

    const handleLoadItem = async rec => {
        const itemId = linkType === "MANAGED" ? rec.managedId : rec.managerId;
        let result = await handleGetCompanyInfo(itemId);
        if (result) {
            setItemSelected(result);
            setShowItem(true);
        }
    };
    const columns = [
        {
            title: "Codice Fiscale",
            dataIndex: linkType === "MANAGED" ? "managedCf" : "managerCf"
        },
        {
            title: "Ragione Sociale",
            dataIndex: linkType === "MANAGED" ? "managedDescription" : "managerDescription"
        },
        {
            title: "",
            align: "center",
            render: (text, record) => (
                <div style={{ textAlign: "right" }}>
                    <Button trigger={["click"]} placement="bottomRight" onClick={() => handleLoadItem(record)}>
                        Visualizza
                    </Button>
                </div>
            )
        }
    ];

    if (loading) {
        return (
            <div style={{ textAlign: "center" }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div>
            {!showInfo ? (
                <div>
                    <Table
                        rowKey={(record, index) => index}
                        columns={columns}
                        dataSource={connectionList}
                        locale={{
                            emptyText: "Nessun connessione presente"
                        }}
                        bordered
                        onChange={page => handlePageChange(page.current)}
                        title={() => (
                            <ConnectionFilter
                                servicesForFilterOption={servicesForFilterOption}
                                handleFilterChange={handleFilterChange}
                                connectionFilter={connectionFilter}
                                hideDownloadCsv={linkType !== "MANAGED"}
                                csvFileName={`${companyId}_gestite.csv`}
                                disabledDownloadCsv={!connectionList || !connectionList.length}
                                downloadCSVFile={handleDownloadCSVFile}
                            />
                        )}
                        pagination={{
                            total: totalItem,
                            current: currentPage + 1,
                            pageSize: pageSize
                        }}
                        expandedRowRender={record => (
                            <CardConnections
                                connectionSelected={record}
                                admin={admin}
                                handleDeleteLink={handleDeleteLink}
                                openInfo={connectionService => handleOpenInfo(connectionService, record)}
                                handleUploadADA={handleUploadADA}
                            />
                        )}
                        size="small"
                    />
                    {itemSelected && (
                        <Drawer
                            width={550}
                            placement="right"
                            closable={false}
                            onClose={() => setShowItem(false)}
                            visible={showItem}
                        >
                            <div style={{ margin: "20px" }}>
                                <CompanyInfo company={itemSelected} onlyInfo={true} />
                            </div>
                        </Drawer>
                    )}
                </div>
            ) : (
                <ConnectionInfo
                    contractContent={contractContent}
                    connectionService={connectionService}
                    connectionSelected={connectionSelected}
                    closeInfo={() => setShowInfo(false)}
                    showPdf={showPdf}
                />
            )}
        </div>
    );
};

CompanyConnections.propTypes = {
    admin: PropTypes.object.isRequired,
    linkType: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    connectionList: PropTypes.array.isRequired,
    totalItem: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    connectionFilter: PropTypes.object,
    serviceList: PropTypes.array.isRequired,
    companyId: PropTypes.string.isRequired,
    handleGetCompanyInfo: PropTypes.func.isRequired,
    handleDeleteLink: PropTypes.func.isRequired,
    handleGetConnectionDocument: PropTypes.func.isRequired,
    handleDownloadCSVFile: PropTypes.func,
    handleUploadADA: PropTypes.func
};
