import {
    VALIDATE_RDC_ERROR,
    VALIDATE_RDC_START,
    VALIDATE_RDC_SUCCESS,
    REJECT_RDC_ERROR,
    REJECT_RDC_START,
    REJECT_RDC_SUCCESS,
    UPDATE_ARCHIVE_DOC_ERROR,
    UPDATE_ARCHIVE_DOC_START,
    UPDATE_ARCHIVE_DOC_SUCCESS,
    UPDATE_SUBMITTER_MAIL_ERROR,
    UPDATE_SUBMITTER_MAIL_START,
    UPDATE_SUBMITTER_MAIL_SUCCESS,
    UPLOAD_FRONTEND_ERROR,
    UPLOAD_FRONTEND_START,
    UPLOAD_FRONTEND_SUCCESS
} from "../../actions/archive/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function validateRDC(state = defaultState, { type, payload, error }) {
    switch (type) {
        case VALIDATE_RDC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case VALIDATE_RDC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case VALIDATE_RDC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function rejectRDC(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REJECT_RDC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case REJECT_RDC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REJECT_RDC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function archiveDocs(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_ARCHIVE_DOC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_ARCHIVE_DOC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_ARCHIVE_DOC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function submitterMail(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_SUBMITTER_MAIL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_SUBMITTER_MAIL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_SUBMITTER_MAIL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function frontend(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPLOAD_FRONTEND_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPLOAD_FRONTEND_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPLOAD_FRONTEND_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
