import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, Tabs } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";

import { errorBoundary } from "../../components/general/error-boundary";
import {
    getBankLinkRules,
    getBankLinkAssociation,
    getBankLinkAssociations,
    getBankLinkBanks,
    getBankLinkFiles,
    getBankLinkDownloadFile
} from "../../actions/bank-link/read";
import { BankLinkRules } from "../../components/bank-link/rules";
import { BankLinkAssociations } from "../../components/bank-link/associations";
import { BankLinkBanks } from "../../components/bank-link/banks";
import { BankLinkFiles } from "../../components/bank-link/files";

import { useBankLinkView } from "./view-model";
import { Container } from "./styled";
import { ViewTitle } from "../../styled";

const BankLinkView = ({
    auth,
    intl,
    history,
    getBankLinkRules,
    getBankLinkAssociation,
    getBankLinkAssociations,
    getBankLinkBanks,
    getBankLinkFiles,
    getBankLinkDownloadFile
}) => {
    const viewModel = useBankLinkView(
        auth.loginAuth,
        intl,
        history,
        getBankLinkRules,
        getBankLinkAssociation,
        getBankLinkAssociations,
        getBankLinkBanks,
        getBankLinkFiles,
        getBankLinkDownloadFile
    );

    return (
        <div>
            <ViewTitle>
                <FormattedMessage id="bank_link" />
            </ViewTitle>
            <Card style={{ height: "87vh" }}>
                <div>
                    <Tabs defaultActiveKey="bank-link-rules">
                        <Tabs.TabPane tab={<FormattedMessage id={"rules"} />} key="bank-link-rules">
                            <Container>
                                <BankLinkRules
                                    defaultPageSize={viewModel.defaultPageSize}
                                    pageSizeOptions={viewModel.pageSizeOptions}
                                    loading={viewModel.rulesListLoading}
                                    rulesList={viewModel.rulesList}
                                    currentPage={viewModel.rulesListCurrentPage}
                                    expanded={viewModel.rulesListExpanded}
                                    handleExpandedChange={viewModel.rulesListHandleExpandedChange}
                                    handlePageSizeChange={viewModel.rulesListHandlePageSizeChange}
                                    hasNextPage={viewModel.rulesListHasNextPage}
                                    handleNextPage={viewModel.rulesListHandleNextPage}
                                    handlePreviousPage={viewModel.rulesListHandlePreviousPage}
                                    query={viewModel.rulesListQuery}
                                    handleFilterChange={viewModel.rulesListHandleFilterChange}
                                    filter={viewModel.rulesListFilter}
                                    handleEditRule={viewModel.rulesListHandleEditRule}
                                    handleNewRule={viewModel.rulesListHandleNewRule}
                                    handleDeleteRule={viewModel.rulesListHandleDeleteRule}
                                />
                            </Container>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id={"consortium"} />} key="bank-link-associations">
                            <Container>
                                <BankLinkAssociations
                                    defaultPageSize={viewModel.defaultPageSize}
                                    pageSizeOptions={viewModel.pageSizeOptions}
                                    loading={viewModel.associationsListLoading}
                                    associationsList={viewModel.associationsList}
                                    currentPage={viewModel.associationsListCurrentPage}
                                    handlePageSizeChange={viewModel.associationsListHandlePageSizeChange}
                                    hasNextPage={viewModel.associationsListHasNextPage}
                                    handleNextPage={viewModel.associationsListHandleNextPage}
                                    handlePreviousPage={viewModel.associationsListHandlePreviousPage}
                                    query={viewModel.associationsListQuery}
                                    handleFilterChange={viewModel.associationsListHandleFilterChange}
                                    filter={viewModel.associationsListFilter}
                                    handleEditAssociation={viewModel.associationsListHandleEditAssociation}
                                    handleNewAssociation={viewModel.associationsListHandleNewAssociation}
                                    handleDeleteAssociation={viewModel.associationsListHandleDeleteRule}
                                />
                            </Container>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id={"bank"} />} key="bank-link-bank">
                            <Container>
                                <BankLinkBanks
                                    defaultPageSize={viewModel.defaultPageSize}
                                    pageSizeOptions={viewModel.pageSizeOptions}
                                    loading={viewModel.banksListLoading}
                                    banksList={viewModel.banksList}
                                    currentPage={viewModel.banksListCurrentPage}
                                    handlePageSizeChange={viewModel.banksListHandlePageSizeChange}
                                    hasNextPage={viewModel.banksListHasNextPage}
                                    handleNextPage={viewModel.banksListHandleNextPage}
                                    handlePreviousPage={viewModel.banksListHandlePreviousPage}
                                    query={viewModel.banksListQuery}
                                    handleFilterChange={viewModel.banksListHandleFilterChange}
                                    filter={viewModel.banksListFilter}
                                    handleEditBank={viewModel.banksListHandleEditBank}
                                    handleNewBank={viewModel.banksListHandleNewBank}
                                    handleDeleteBank={viewModel.banksListHandleDeleteRule}
                                />
                            </Container>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id={"files"} />} key="bank-link-files">
                            <Container>
                                <BankLinkFiles
                                    defaultPageSize={viewModel.defaultPageSize}
                                    pageSizeOptions={viewModel.pageSizeOptions}
                                    loading={viewModel.filesListLoading}
                                    filesList={viewModel.filesList}
                                    currentPage={viewModel.filesListCurrentPage}
                                    handlePageSizeChange={viewModel.filesListHandlePageSizeChange}
                                    hasNextPage={viewModel.filesListHasNextPage}
                                    handleNextPage={viewModel.filesListHandleNextPage}
                                    handlePreviousPage={viewModel.filesListHandlePreviousPage}
                                    query={viewModel.filesListQuery}
                                    handleFilterChange={viewModel.filesListHandleFilterChange}
                                    filter={viewModel.filesListFilter}
                                    handleDeleteFile={viewModel.filesListHandleDelete}
                                    handleDownloadFile={viewModel.handleDownloadFile}
                                    handleUploadTxtFile={viewModel.handleUploadTxtFile}
                                    handleUploadXlsFile={viewModel.handleUploadXlsFile}
                                    handleViewFileTransaction={viewModel.handleViewFileTransaction}
                                />
                            </Container>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </Card>
        </div>
    );
};

BankLinkView.propTypes = {
    auth: PropTypes.object.isRequired,
    getBankLinkRules: PropTypes.func.isRequired,
    getBankLinkAssociations: PropTypes.func.isRequired,
    getBankLinkBanks: PropTypes.func.isRequired,
    getBankLinkFiles: PropTypes.func.isRequired,
    getBankLinkDownloadFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = {
    getBankLinkRules,
    getBankLinkAssociation,
    getBankLinkAssociations,
    getBankLinkBanks,
    getBankLinkFiles,
    getBankLinkDownloadFile
};

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(BankLinkView);
