import React, { useEffect, useState } from "react";
import { formValueSelector, isValid, isSubmitting } from "redux-form";
import { getFullInfoDocumentCall } from "../../../actions/contract";
import { getOneCctProvisioningRequest } from "../../../actions/cct/read";
import { setCedentePrestatore, putCctProvisioningRequest } from "../../../actions/cct/write";
import { findOwnManagerConnections } from "../../../actions/connections/read";

import { usePrevious } from "../../../utils/hook";

import { connect } from "react-redux";
import { Row, Col } from "antd";
import { searchCompany, searchItems } from "../../../actions/search/company";
import { PdfHandler } from "../../../components/general/pdf-handler";

import CompanyInfo from "../../../components/certifications/cct-provisioning/company-info";
import { AutoUploadSection, PdfWrapper } from "./styled";
import Form from "../../../components/certifications/cct-provisioning/set-cedente-form";
import { updateItem } from "../../../actions/item";
import { emailIVerde, iVerde } from "../../../utils/constant";

import PropTypes from "prop-types";
import PageHeader from "../../../components/general/PageHeader/index";

export const MANAGED = "MANAGED";
export const MANAGER = "MANAGER";

const CctAutoUploadView = ({
    history,
    match: {
        params: { cfMain, cfAzienda, idCoupon }
    },
    getOneCctProvisioningRequest,
    auth,
    provisioningRequest,
    provisioingRequestStatus,
    searchCompany,
    searchItems,
    getFullInfoDocument,
    getFullInfoDocumentCall,
    findOwnManagerConnections,
    getManagerConnectionsStatus,
    updateItem,
    updateItemStatus: { error: updateItemError, ended: updateItemEndend, started: updateItemStarted },
    balanceSheetDateFormField,
    setCedentePrestatore,
    setCedentePrestatoreStatus: {
        error: setCedentePrestatoreError,
        ended: setCedentePrestatoreEnded,
        started: setCedentePrestatoreStarted
    },
    formEnabled,
    putCctProvisioningRequest
}) => {
    const [cfMainDetail, setCfMainDetail] = useState(null);
    const [cfAziendaDetail, setCfAziendaDetail] = useState(null);
    const [enableForm, setEnableForm] = useState(false);
    const [cfAziendaInfoDoc, setCfAziendaGetInfodoc] = useState(null);
    const [cfMainInfoDoc, setCfMainInfoDoc] = useState(null);
    const [tabSelected, setTabSelected] = useState(MANAGER);
    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        if (provisioningRequest && provisioningRequest.codiceCoupon === "1004") {
            setTabSelected(MANAGED);
        }
    }, [provisioningRequest]);

    useEffect(() => {
        if (updateItemEndend) {
            setEnableForm(true);
        }
    }, [updateItemEndend]);

    const prevProvisioingRequestStatus = usePrevious(provisioingRequestStatus);

    useEffect(() => {
        getOneCctProvisioningRequest(auth, idCoupon, { "cf-produttore": cfMain, "cf-titolari": cfAzienda });
    }, [getOneCctProvisioningRequest, auth, cfAzienda, cfMain, idCoupon]);

    useEffect(() => {
        const asyncSetCfAzienda = async itemId => {
            const result = await searchCompany(auth, itemId, "BASE", true);
            if (result) {
                setCfAziendaDetail(result);
            }
        };

        const asyncSetCfMain = async itemId => {
            const result = await searchCompany(auth, itemId, "BASE", true);
            if (result) {
                setCfMainDetail(result);
            }
        };

        const asyncGetFullInfoDocumentCfAzienda = async (managerId, managedId) => {
            const managerData = await searchCompany(auth, managerId, "BASE", true);
            const managedData = await searchCompany(auth, managedId, "BASE", true);
            let filter = {
                managedIds: managedData.item.base.uuid,
                managerIds: managerData.item.base.uuid,
                appId: ["EIP", "SIG"],
                certificationStatus: ["CERTIFIED"]
            };
            let connResponse = await findOwnManagerConnections(auth, filter, { page: 0, items: 2000 });

            if (connResponse.content.length !== 0) {
                const result = await getFullInfoDocumentCall(auth, connResponse.content[0].connections[0].id);
                setCfAziendaGetInfodoc(result);
            }
        };

        const asyncGetFullInfoDocumentCfMain = async itemId => {
            const result = await getFullInfoDocumentCall(auth, itemId);
            setCfMainInfoDoc(result);
        };

        if (prevProvisioingRequestStatus && !prevProvisioingRequestStatus.ended && provisioingRequestStatus.ended) {
            asyncGetFullInfoDocumentCfMain(cfMain);
            asyncSetCfMain(cfMain);
            if (cfMain !== cfAzienda) {
                asyncSetCfAzienda(cfAzienda);
                asyncGetFullInfoDocumentCfAzienda(cfMain, cfAzienda);
            }
        }
    }, [
        auth,
        prevProvisioingRequestStatus,
        provisioingRequestStatus,
        getManagerConnectionsStatus,
        cfMain,
        cfAzienda,
        searchCompany,
        searchItems,
        getFullInfoDocumentCall,
        findOwnManagerConnections
    ]);

    const balanceSheetDate =
        cfAziendaDetail && cfAziendaDetail.item.base.details.economics.balanceSheetDate > 0
            ? new Date(cfAziendaDetail.item.base.details.economics.balanceSheetDate).toISOString()
            : null;

    const getDocument = type => {
        if (type === MANAGER && cfMainInfoDoc) {
            return cfMainInfoDoc.content.content;
        }
        if (type === MANAGED && cfAziendaInfoDoc) {
            return cfAziendaInfoDoc.content.content;
        }
        return null;
    };

    const setCedente = async data => {
        await setCedentePrestatore(auth, cfAzienda, data, cfMain);
        setConfirmModal(true);
    };

    const handleCertify = async () => {
        var filters = {
            "cf-produttore": cfMain,
            "cf-titolare": cfAzienda,
            "status-code": "validated"
        };
        return await putCctProvisioningRequest(auth, filters);
    };

    let defaultEmail = "";
    if (iVerde.includes(cfMain)) {
        const el = emailIVerde.find(x => x.id === cfMain);
        defaultEmail = el !== undefined ? el.email : null;
    }

    return (
        <AutoUploadSection>
            <PageHeader labelId="general.goPageBack" history={history} />

            <Row gutter={24}>
                <Col span={13}>
                    <CompanyInfo
                        manager={cfMainDetail}
                        managed={cfAziendaDetail}
                        renderManaged={cfMain !== cfAzienda}
                        cfAziendaDoc={cfAziendaInfoDoc}
                        cfMainDoc={cfMainInfoDoc}
                        onChangeTab={() => {
                            if (tabSelected === MANAGED) {
                                setTabSelected(MANAGER);
                            } else {
                                setTabSelected(MANAGED);
                            }
                        }}
                        tabSelected={tabSelected}
                    />
                    <Form
                        balanceSheetDateFormField={balanceSheetDateFormField}
                        confirmModal={confirmModal}
                        formEnabled={formEnabled}
                        history={history}
                        setCedentePrestatoreError={setCedentePrestatoreError}
                        setCedentePrestatoreEnded={setCedentePrestatoreEnded}
                        setCedentePrestatoreStarted={setCedentePrestatoreStarted}
                        setConfirmModal={value => setConfirmModal(value)}
                        enableForm={enableForm}
                        render={cfAziendaDetail !== null}
                        enableReinitialize={true}
                        initialValues={{
                            balanceSheetDate: balanceSheetDate,
                            taxRegion: "IT",
                            nome:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.nomeRC
                                    : "",
                            cognome:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.cognomeRC
                                    : "",
                            cf:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.cfRC
                                    : "",
                            luogoNascita:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.luogoNascita
                                    : "",
                            dataNascita:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.dataNascita
                                    : null,
                            email:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.email
                                    : defaultEmail,
                            nazione:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.nazione
                                    : null,
                            sesso:
                                provisioningRequest && provisioningRequest.cedentePrestatore
                                    ? provisioningRequest.cedentePrestatore.sesso
                                    : null
                        }}
                        updateItemError={updateItemError}
                        updateItemEndend={updateItemEndend}
                        updateItemStarted={updateItemStarted}
                        companyData={cfAziendaDetail}
                        onSubmit={data => setCedente(data)}
                        updateItem={async () => {
                            let item = cfAziendaDetail.item;
                            item.base.details.economics.balanceSheetDate = new Date(
                                balanceSheetDateFormField
                            ).getTime();
                            updateItem(auth, item);
                        }}
                        handleCertify={handleCertify}
                    />
                </Col>
                <Col span={11}>
                    <PdfWrapper>
                        <PdfHandler
                            loading={tabSelected === MANAGER ? !cfMainInfoDoc : !cfAziendaInfoDoc}
                            content={getDocument(tabSelected)}
                            fileName={"documento_certificato.pdf"}
                        />
                    </PdfWrapper>
                </Col>
            </Row>
        </AutoUploadSection>
    );
};

CctAutoUploadView.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    getOneCctProvisioningRequest: PropTypes.func,
    auth: PropTypes.object.isRequired,
    provisioingRequestStatus: PropTypes.object.isRequired,
    searchCompany: PropTypes.func,
    searchItems: PropTypes.func,
    getFullInfoDocument: PropTypes.object,
    getFullInfoDocumentCall: PropTypes.func,
    updateItem: PropTypes.func,
    updateItemStatus: PropTypes.object.isRequired,
    balanceSheetDateFormField: PropTypes.string,
    setCedentePrestatore: PropTypes.func.isRequired,
    setCedentePrestatoreStatus: PropTypes.object.isRequired,
    findOwnManagerConnections: PropTypes.func,
    getManagerConnectionsStatus: PropTypes.object.isRequired
};

const selector = formValueSelector("cedente-data-form");

const mapStateToProps = state => ({
    auth: state.auth.loginAuth,
    balanceSheetDateFormField: selector(state, "balanceSheetDate"),
    formEnabled: formName => isValid(formName)(state) && !isSubmitting(formName)(state),
    getManagerConnectionsStatus: state.connections.read.findOwnManagerConnections.status,
    provisioningRequest: state.cct.read.cctOneProvRequest.cctProvisoingRequest,
    provisioingRequestStatus: state.cct.read.cctOneProvRequest.status,
    setCedentePrestatoreStatus: state.cct.write.setCedentePrestatore.status,
    updateItemStatus: state.item.updateItem.status
});

export default connect(
    mapStateToProps,
    {
        searchCompany,
        searchItems,
        getOneCctProvisioningRequest,
        updateItem,
        setCedentePrestatore,
        findOwnManagerConnections,
        getFullInfoDocumentCall,
        putCctProvisioningRequest
    }
)(CctAutoUploadView);
