import { DIGITALBOX_BATCH_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_DIGITALBOX_INVOICE_IMPORT_START = "GET_DIGITALBOX_INVOICE_IMPORT_START";
export const GET_DIGITALBOX_INVOICE_IMPORT_SUCCESS = "GET_DIGITALBOX_INVOICE_IMPORT_SUCCESS";
export const GET_DIGITALBOX_INVOICE_IMPORT_ERROR = "GET_DIGITALBOX_INVOICE_IMPORT_ERROR";

export function getInvoiceImport(auth, itemId) {
    return async dispatch => {
        const urlParams = {
            itemId
        };
        dispatch({
            type: GET_DIGITALBOX_INVOICE_IMPORT_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${DIGITALBOX_BATCH_API_URL}/invoices/import`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_DIGITALBOX_INVOICE_IMPORT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_DIGITALBOX_INVOICE_IMPORT_ERROR,
                error: e
            });
        }
    };
}
