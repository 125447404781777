import React from "react";
import PropTypes from "prop-types";

import { MenuItems } from "./menu-items";
import { Drawer, Layout } from "antd";

import { LOGO_TS, LOGO_COLOR } from "../../config";
import { DrawerImg, IconContainer, IconImage } from "./styled";

export const Menu = ({ admin, drawerState, isConsoleAdmin, isOpen, isTablet, menuItem, setDrawerState }) => {
    if (isTablet) {
        return (
            <Drawer
                title={<DrawerImg alt="" src={LOGO_COLOR} />}
                placement="left"
                closable={false}
                onClose={() => setDrawerState(false)}
                visible={drawerState}
                bodyStyle={{
                    background: !isConsoleAdmin ? "#1e2427" : "white",
                    overflow: "hidden",
                    height: "91vh"
                }}
            >
                <MenuItems
                    admin={admin}
                    menuItem={menuItem}
                    onClose={() => setDrawerState(false)}
                    isConsoleAdmin={isConsoleAdmin}
                />
            </Drawer>
        );
    }

    return (
        <Layout.Sider
            collapsible
            trigger={null}
            collapsed={isOpen}
            style={{
                background: !isConsoleAdmin ? "#1e2427" : "white",
                height: "100vh"
            }}
        >
            <IconContainer isConsoleAdmin={isConsoleAdmin}>
                <IconImage
                    alt=""
                    isOpen={isOpen}
                    src={
                        !isOpen && isConsoleAdmin
                            ? LOGO_COLOR
                            : isOpen && !isConsoleAdmin
                            ? LOGO_TS
                            : isConsoleAdmin
                            ? LOGO_TS
                            : LOGO_COLOR
                    }
                />
            </IconContainer>
            <MenuItems
                admin={admin}
                isConsoleAdmin={isConsoleAdmin}
                menuItem={menuItem}
                onClose={() => setDrawerState(false)}
            />
        </Layout.Sider>
    );
};

Menu.propTypes = {
    admin: PropTypes.object.isRequired,
    drawerState: PropTypes.bool.isRequired,
    isConsoleAdmin: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    menuItem: PropTypes.string,
    setDrawerState: PropTypes.func.isRequired
};
