import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Button, Tooltip, Badge, Modal, Dropdown, Menu } from "antd";
import { CSVLink } from "react-csv";

import { cctStatusCodeFilter, cctWariningCodeFilter } from "../../../../utils/constant";
import { CCTProvisioningExpanded } from "../cct-provisioning-expanded";
import { ColumnSearchProps } from "../../../general/column-search-props";

export const CCTProvisioning = ({
    companyCf,
    dataSource,
    button1000Disabled,
    rowDetailsDisabled,
    button1000Loading,
    handleActiveCoupon1000,
    loading,
    cctOwnerList,
    cctOwnerListLoading,
    handleRemoveRdcTbf,
    actionRemoveRdcTbfDisabled
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordModal, setModalTaskId] = useState();
    const [activeFilters, setActiveFilters] = useState(false);
    const [filteredData, setFilteredData] = useState();

    const showModal = record => {
        setModalTaskId(record);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const setFilters = (pagination, filters, sorter, extra, cctOwnerList) => {
        setFilteredData(extra.currentDataSource);
        setActiveFilters(true);
    };

    function getCsvName() {
        const d = new Date();
        return (
            "cct_gestite_" +
            companyCf +
            "_" +
            d.getFullYear() +
            "_" +
            d.getMonth() +
            "_" +
            d.getDate() +
            "_" +
            d.getHours() +
            "_" +
            d.getMinutes()
        );
    }

    function getColumns(showModal) {
        const renderActions = record => {
            return (
                <Menu>
                    <Menu.Item key="0" onClick={() => handleRemoveRdcTbf(record)}>
                        <FormattedMessage id="delete_cct_rdctbf" />
                    </Menu.Item>
                </Menu>
            );
        };

        const checkActionEnabled = (record, actionRemoveRdcTbfDisabled) => {
            if (record.rdcToBeFixed === 1 || actionRemoveRdcTbfDisabled) {
                return false;
            } else {
                return true;
            }
        };

        return [
            {
                title: <FormattedMessage id="c-cctconfigservice.couponcode" />,
                dataIndex: "codiceCoupon",
                key: "codiceCoupon"
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.id" />,
                dataIndex: "id",
                key: "id"
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.cfazienda" />,
                dataIndex: "cfAzienda",
                key: "cfAzienda"
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.cfmain" />,
                dataIndex: "cfMain",
                key: "cfMain"
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.rdc_short" />,
                key: "cedentePrestatore",
                render: record => (
                    <span>
                        <Button type="link" size="small" onClick={() => showModal(record)}>
                            Visualizza dati
                        </Button>
                        <Modal
                            title={<FormattedMessage id="c-cctconfigservice.rdc" />}
                            key={recordModal}
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            okText="Chiudi"
                            cancelText="Chiudi"
                            maskClosable={true}
                            cancelButtonProps={{ style: { display: "none" } }}
                            footer={null}
                        >
                            <CCTProvisioningExpanded description={recordModal} />
                        </Modal>
                    </span>
                )
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.statuscode" />,
                dataIndex: "statusCode",
                key: "statusCode",
                render: (text, record) => (
                    <span>
                        <Badge status={text === "validated" ? "success" : text === "blocked" ? "error" : "warning"} />
                        {cctStatusCodeFilter.find(x => x.value === text)
                            ? cctStatusCodeFilter.find(x => x.value === text).text
                            : text}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="general.warning" />,
                dataIndex: "exceptionDesc",
                key: "exceptionDesc",
                render: (text, record) =>
                    record.suspended === 1 || record.hasError === 1 ? (
                        <span>
                            <Tooltip
                                title={
                                    text !== undefined
                                        ? text
                                        : record.description === undefined
                                        ? "Errore - Nessun messaggio"
                                        : record.description
                                }
                            >
                                <i className="fas fa-info-circle" style={{ color: "red" }} /> In errore
                            </Tooltip>
                        </span>
                    ) : record.rdcToBeFixed === 1 ? (
                        <span>
                            <Tooltip
                                title={
                                    record.rdcToBeFixedDesc !== undefined
                                        ? record.rdcToBeFixedDesc
                                        : record.description === undefined
                                        ? "RDC Errato - Nessun messaggio"
                                        : record.description
                                }
                            >
                                <i className="fas fa-info-circle" style={{ color: "orange" }} /> Responsabile da
                                correggere
                            </Tooltip>
                        </span>
                    ) : null
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.actions" />,
                key: "actions",
                render: (text, record) => (
                    <div>
                        <Tooltip title={<FormattedMessage id="delete_cct_rdctbf" />}>
                            <Dropdown
                                overlay={renderActions(record)}
                                trigger={["click"]}
                                placement="bottomRight"
                                disabled={checkActionEnabled(record, actionRemoveRdcTbfDisabled)}
                            >
                                <Button shape="circle" size="small" type={"primary"}>
                                    <i className="fas fa-ellipsis-v" />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </div>
                )
            }
        ];
    }

    const expandedRowRender = (record2, coupons) => {
        const columnsCoupon1100 = [
            {
                title: <FormattedMessage id="c-cctconfigservice.couponcode" />,
                dataIndex: "codiceCoupon",
                key: "codiceCoupon"
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.id" />,
                dataIndex: "id",
                key: "id"
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.updated_by" />,
                dataIndex: "status",
                key: "status_1100_user",
                render: record => (
                    <span>
                        {record.status && record.boUpdatedAt > record.updatedAt ? record.boUpdatedBy : record.updatedBy}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.statuscode" />,
                dataIndex: "statusCode",
                key: "statusCode",
                render: (text, record) => (
                    <span>
                        <Badge status={text === "validated" ? "success" : text === "blocked" ? "error" : "warning"} />
                        {cctStatusCodeFilter.find(x => x.value === text)
                            ? cctStatusCodeFilter.find(x => x.value === text).text
                            : text}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="general.warning" />,
                dataIndex: "exceptionDesc",
                key: "exceptionDesc",
                render: (text, record) =>
                    record.suspended === 1 || record.hasError === 1 ? (
                        <span>
                            <Tooltip
                                title={
                                    text !== undefined
                                        ? text
                                        : record.description === undefined
                                        ? "Errore - Nessun messaggio"
                                        : record.description
                                }
                            >
                                <i className="fas fa-info-circle" style={{ color: "red" }} /> In errore
                            </Tooltip>
                        </span>
                    ) : record.rdcToBeFixed === 1 ? (
                        <span>
                            <Tooltip
                                title={
                                    record.rdcToBeFixedDesc !== undefined
                                        ? record.rdcToBeFixedDesc
                                        : record.description === undefined
                                        ? "RDC Errato - Nessun messaggio"
                                        : record.description
                                }
                            >
                                <i className="fas fa-info-circle" style={{ color: "orange" }} /> Responsabile da
                                correggere
                            </Tooltip>
                        </span>
                    ) : null
            },
            {
                title: <FormattedMessage id="c-cctconfigservice.updated_at" />,
                dataIndex: "status",
                key: "status_1100_date",
                render: record => (
                    <span>
                        {record.status && record.boUpdatedAt > record.updatedAt
                            ? new Date(Number(record.boUpdatedAt)).toLocaleString()
                            : new Date(Number(record.updatedAt)).toLocaleString()}
                    </span>
                )
            }
        ];

        return (
            <div>
                {record2.contractType === "PROVISIONING_SMART" ? (
                    <div>
                        <p style={{ display: "inline-block", paddingRight: "1%", fontWeight: "bold" }}>Coupon Smart</p>
                    </div>
                ) : null}
                <div>
                    <div>
                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                            <b>
                                <FormattedMessage id="c-cctconfigservice.createdAt" />
                            </b>
                            {new Date(Number(record2.status.createdAt)).toLocaleString()}
                        </p>
                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                            <b>
                                <FormattedMessage id="c-cctconfigservice.createdBy" />
                            </b>
                            {record2.status.createdBy}
                        </p>
                    </div>
                    <div>
                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                            <b>
                                <FormattedMessage id="c-cctconfigservice.updatedAt" />
                            </b>
                            {record2.status && record2.status.boUpdatedAt > record2.status.updatedAt
                                ? new Date(Number(record2.status.boUpdatedAt)).toLocaleString()
                                : new Date(Number(record2.status.updatedAt)).toLocaleString()}
                        </p>
                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                            <b>
                                <FormattedMessage id="c-cctconfigservice.updatedBy" />
                            </b>
                            {record2.status && record2.status.boUpdatedAt > record2.status.updatedAt
                                ? record2.status.boUpdatedBy
                                : record2.status.updatedBy}
                        </p>
                    </div>
                </div>
                {coupons && coupons.length > 0 ? (
                    <Table
                        title={() => <FormattedMessage id="c-cctconfigservice.1100" />}
                        columns={columnsCoupon1100}
                        dataSource={coupons}
                        pagination={false}
                        rowKey={(coupons, index) => index + "coupons"}
                        align="left"
                        size="small"
                    />
                ) : (
                    <FormattedMessage id="c-cctconfigservice.null1100" />
                )}
            </div>
        );
    };

    const columnsOwnerList = [
        {
            title: <FormattedMessage id="c-cctconfigservice.cfazienda" />,
            dataIndex: "cfAzienda",
            key: "cfAzienda",
            onFilter: (value, record) =>
                record["cfAzienda"]
                    ? record["cfAzienda"]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : "",
            ...ColumnSearchProps("codice fiscale")
        },
        {
            title: <FormattedMessage id="general.itemDescription" />,
            dataIndex: "companyDescription",
            key: "companyDescription",
            onFilter: (value, record) =>
                record["companyDescription"]
                    ? record["companyDescription"]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : "",
            ...ColumnSearchProps("ragione sociale")
        },
        {
            title: <FormattedMessage id="cf_responsible" />,
            dataIndex: "cedentePrestatore.cfRC",
            key: "cedentePrestatore.cfRC"
        },
        {
            title: <FormattedMessage id="cct_responsible" />,
            dataIndex: "cedentePrestatore.nomeRC",
            key: "cedentePrestatore.nomeRC",
            render: (text, record) => (
                <span>
                    {text} {record.cedentePrestatore ? record.cedentePrestatore.cognomeRC : ""}
                </span>
            )
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.statuscode" />,
            dataIndex: "statusCode",
            key: "statusCode",
            filters: cctStatusCodeFilter,
            filterMultiple: true,
            onFilter: (value, record) => record.statusCode.includes(value),
            render: text => (
                <span>
                    <Badge status={text === "validated" ? "success" : text === "blocked" ? "error" : "warning"} />
                    {cctStatusCodeFilter.find(x => x.value === text)
                        ? cctStatusCodeFilter.find(x => x.value === text).text
                        : text}
                </span>
            )
        },
        {
            title: <FormattedMessage id="general.warning" />,
            dataIndex: ["rdcToBeFixed", "suspended"],
            key: "exceptionDesc",
            filters: cctWariningCodeFilter,
            filterMultiple: false,
            onFilter: (value, record) =>
                value === "rdcToBeFixed"
                    ? record["rdcToBeFixed"] === 1
                    : value === "suspended"
                    ? record["suspended"] === 1
                    : "",

            render: (text, record) =>
                record.suspended === 1 || record.hasError === 1 ? (
                    <span>
                        <Tooltip
                            title={
                                text !== undefined
                                    ? text
                                    : record.description === undefined
                                    ? "Errore - Nessun messaggio"
                                    : record.description
                            }
                        >
                            <i className="fas fa-info-circle" style={{ color: "red" }} /> In errore
                        </Tooltip>
                    </span>
                ) : record.rdcToBeFixed === 1 ? (
                    <span>
                        <Tooltip
                            title={
                                record.rdcToBeFixedDesc !== undefined
                                    ? record.rdcToBeFixedDesc
                                    : record.description === undefined
                                    ? "RDC Errato - Nessun messaggio"
                                    : record.description
                            }
                        >
                            <i className="fas fa-info-circle" style={{ color: "orange" }} /> Responsabile da correggere
                        </Tooltip>
                    </span>
                ) : null
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.actions" />,
            key: "actions",
            render: (text, record) => (
                <div>
                    <Tooltip title={<FormattedMessage id="delete_cct_rdctbf" />}>
                        <Dropdown
                            overlay={renderActions(record)}
                            trigger={["click"]}
                            placement="bottomRight"
                            disabled={checkActionEnabled(record, actionRemoveRdcTbfDisabled)}
                        >
                            <Button shape="circle" size="small" type={"primary"}>
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </Tooltip>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleRemoveRdcTbf(record)}>
                    <FormattedMessage id="delete_cct_rdctbf" />
                </Menu.Item>
            </Menu>
        );
    };

    const checkActionEnabled = (record, actionRemoveRdcTbfDisabled) => {
        if (record.rdcToBeFixed === 1 || actionRemoveRdcTbfDisabled) {
            return false;
        } else {
            return true;
        }
    };

    const csvHeaders = [
        { label: "CF Azienda Titolare", key: "cfAzienda" },
        {
            label: "Ragione sociale",
            key: "companyDescription"
        },
        { label: "CF Responsabile", key: "cedentePrestatore.cfRC" },
        {
            label: "Nome Responsabile",
            key: "cedentePrestatore.nomeRC"
        },
        {
            label: "Congnome Responsabile",
            key: "cedentePrestatore.cognomeRC"
        },
        {
            label: "Stato",
            key: "statusCode"
        },
        {
            label: "Avvisi RDC",
            key: "rdcToBeFixedDesc"
        },
        {
            label: "Avvisi errore",
            key: "exceptionDesc"
        }
    ];

    return (
        <div>
            <div>
                <div className={"a1"} style={{ marginBottom: 20 }}>
                    <FormattedMessage id="c-cctconfigservice.titolare" />
                    <div style={{ float: "right" }}>
                        <Button
                            type="primary"
                            disabled={button1000Disabled}
                            loading={button1000Loading}
                            onClick={handleActiveCoupon1000}
                        >
                            <FormattedMessage id="c-cctconfigservice.enable-coupon-1000" />
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={(record, index) => index + "provisioningList"}
                    locale={{
                        emptyText: <FormattedMessage id="c-cctconfigservice.nullprovisioning" />
                    }}
                    dataSource={dataSource}
                    columns={getColumns(showModal)}
                    pagination={false}
                    loading={loading}
                    expandedRowRender={record =>
                        !rowDetailsDisabled
                            ? expandedRowRender(record, Object.values(record.coupons), button1000Disabled)
                            : null
                    }
                    expandIconAsCell={!rowDetailsDisabled}
                    expandIconColumnIndex={!rowDetailsDisabled ? 0 : -1}
                    expandRowByClick={!rowDetailsDisabled}
                />
            </div>
            {cctOwnerList.length > 0 ? (
                <div>
                    <div className={"a1"} style={{ marginTop: 30, marginBottom: 20 }}>
                        <FormattedMessage id="c-cctconfigservice.produttore" />
                        {!cctOwnerList || !cctOwnerList.length || cctOwnerListLoading ? null : (
                            <div style={{ float: "right" }}>
                                <CSVLink
                                    filename={getCsvName()}
                                    data={activeFilters ? filteredData : cctOwnerList}
                                    headers={csvHeaders}
                                    className="btn btn-primary"
                                >
                                    <Button
                                        disabled={!cctOwnerList || !cctOwnerList.length || cctOwnerListLoading}
                                        style={{ marginLeft: 5 }}
                                    >
                                        <i className="fas fa-download" />
                                    </Button>
                                </CSVLink>
                            </div>
                        )}
                    </div>
                    <Table
                        rowKey={(record, index) => index + "OwnerList"}
                        locale={{
                            emptyText: <FormattedMessage id="no_other_coupons_connected" />
                        }}
                        dataSource={cctOwnerList}
                        columns={columnsOwnerList}
                        loading={cctOwnerListLoading}
                        onChange={(pagination, filters, sorter, extra) =>
                            setFilters(pagination, filters, sorter, extra)
                        }
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

CCTProvisioning.propTypes = {
    companyCf: PropTypes.string.isRequired,
    dataSource: PropTypes.array.isRequired,
    button1000Disabled: PropTypes.bool.isRequired,
    rowDetailsDisabled: PropTypes.bool.isRequired,
    button1000Loading: PropTypes.bool.isRequired,
    handleActiveCoupon1000: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    cctOwnerList: PropTypes.array.isRequired,
    cctOwnerListLoading: PropTypes.bool.isRequired,
    handleRemoveRdcTbf: PropTypes.func.isRequired,
    actionRemoveRdcTbfDisabled: PropTypes.bool.isRequired
};
