import React from "react";
import PropTypes from "prop-types";
import { CSVDownload } from "react-csv";
import { ActionIcon } from "./styled";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

import { FormattedMessage } from "react-intl";

import { Dropdown, Menu, Table, Select, Input, Button } from "antd";

export const AppNameTable = ({
    appNames,
    currentPage,
    currentPageSize,
    handleFilter,
    downloadCSVFile,
    appNamesExport,
    loading,
    onDelete,
    onEdit,
    setPage,
    setPageSize,
    totalItems
}) => {
    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => onEdit(record)}>
                    <i className="fas fa-edit" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"edit"} />
                </Menu.Item>
                <Menu.Item key="1" onClick={() => onDelete(record)}>
                    <i className="fas fa-trash" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"delete"} />
                </Menu.Item>
            </Menu>
        );
    };

    const tableConfig = ({ handleFilter }) => {
        const columns = [
            {
                title: (
                    <Input
                        onChange={v => {
                            if (v.target.value !== "") {
                                handleFilter(v.target.value, "app_name");
                            } else {
                                handleFilter(null, "app_name");
                            }
                        }}
                        placeholder="App Name"
                    />
                ),

                dataIndex: "app_name"
            },
            {
                title: (
                    <Input
                        onChange={v => {
                            if (v.target.value !== "") {
                                handleFilter(v.target.value, "software_house");
                            } else {
                                handleFilter(null, "software_house");
                            }
                        }}
                        placeholder="Software House"
                    />
                ),
                dataIndex: "software_house"
            },
            {
                title: "Software",
                dataIndex: "software"
            },
            {
                title: "Contact Name",
                dataIndex: "contact_name"
            },
            {
                title: (
                    <Input
                        onChange={v => {
                            if (v.target.value !== "") {
                                handleFilter(v.target.value, "contact_mail");
                            } else {
                                handleFilter(null, "contact_mail");
                            }
                        }}
                        placeholder="Contact Mail"
                    />
                ),
                dataIndex: "contact_mail"
            },
            {
                title: "Contact Phone",
                dataIndex: "contact_phone"
            },
            {
                title: "Application",
                dataIndex: "application"
            },
            {
                title: (
                    <Select
                        placeholder="Type"
                        style={{ width: "200px" }}
                        onChange={v => {
                            if (v === "INT" || v === "EXT") {
                                handleFilter(v, "type");
                            } else {
                                handleFilter(null, "type");
                            }
                        }}
                    >
                        <Select.Option value="Tutti">Tutti</Select.Option>
                        <Select.Option value="INT">Interno</Select.Option>
                        <Select.Option value="EXT">Esterno</Select.Option>
                    </Select>
                ),
                dataIndex: "type"
            },
            {
                title: "Actions",
                dataIndex: "action",
                key: "action",
                render: (text, record) => (
                    <Dropdown overlay={renderActions(record)}>
                        <ActionIcon icon={faEllipsisV} />
                    </Dropdown>
                )
            }
        ];

        return columns;
    };

    const data = appNames || [];

    return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Table
                title={() => (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="table-title">
                            <FormattedMessage id={"c-app-name.title.appname"} /> {totalItems}
                        </div>
                        <Button onClick={downloadCSVFile} style={{ marginLeft: 5 }}>
                            <i className="fas fa-download" />
                            <FormattedMessage id={"c-app-name.download.csv"} />
                        </Button>
                        {appNamesExport.status.ended ? (
                            <CSVDownload data={appNamesExport.description} target="_blank" />
                        ) : null}
                    </div>
                )}
                columns={tableConfig({
                    handleFilter: (value, name) => handleFilter(value, name)
                })}
                dataSource={data}
                pagination={{
                    total: totalItems,
                    current: currentPage,
                    pageSize: currentPageSize,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "15"],
                    defaultPageSize: 10,
                    onShowSizeChange: (current, size) => {
                        setPageSize(size);
                    }
                }}
                onChange={page => setPage(page.current)}
                loading={loading}
                rowKey={"app_name"}
            />
        </div>
    );
};

AppNameTable.propTypes = {
    currentPage: PropTypes.number,
    currentPageSize: PropTypes.number,
    loading: PropTypes.bool,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    setPage: PropTypes.func,
    setPageSize: PropTypes.func,
    totalItems: PropTypes.number,
    downloadCSVFile: PropTypes.func
};
