import React from "react";
import { CompanyInfoWrapper, CompanyInfoBlock } from "./styled";
import { Skeleton } from "antd";
import ContractInfoCompany from "../../../contract/contract-info/company";
import PropTypes from "prop-types";
import { StandardTabs, TabPane } from "@mondora/arc/antd";

const CompanyInfo = ({ manager, managed, renderManaged, cfAziendaDoc, cfMainDoc, onChangeTab, tabSelected }) => {
    return (
        <CompanyInfoWrapper>
            <StandardTabs variant="secondary" onChange={() => onChangeTab()} activeKey={tabSelected}>
                <TabPane tab="Produttore" key="MANAGER">
                    <CompanyInfoBlock>
                        {manager && cfMainDoc ? (
                            <ContractInfoCompany data={manager} info={cfMainDoc.documentInfo} />
                        ) : (
                            <div style={{ width: "100%" }}>
                                <Skeleton active />
                            </div>
                        )}
                    </CompanyInfoBlock>
                </TabPane>
                {renderManaged && (
                    <TabPane tab="Titolare" key="MANAGED">
                        <CompanyInfoBlock>
                            {managed && cfAziendaDoc ? (
                                <ContractInfoCompany data={managed} info={cfAziendaDoc.documentInfo} />
                            ) : (
                                <Skeleton active />
                            )}
                        </CompanyInfoBlock>
                    </TabPane>
                )}
            </StandardTabs>
        </CompanyInfoWrapper>
    );
};

CompanyInfo.propTypes = {
    manager: PropTypes.object,
    managed: PropTypes.object,
    renderManaged: PropTypes.bool.isRequired
};

export default CompanyInfo;
