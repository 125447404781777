import styled from "styled-components";

export const CardTsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const SpidViewBlock = styled.div`
    margin-bottom: 50px;
`;
