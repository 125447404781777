import { API_URL } from "../../../config";
import { postRestCall } from "../../../utils/rest-api-call";

export const SHARE_PACKAGE_START = "SHARE_PACKAGE_START";
export const SHARE_PACKAGE_SUCCESS = "SHARE_PACKAGE_SUCCESS";
export const SHARE_PACKAGE_ERROR = "SHARE_PACKAGE_ERROR";

export const sharePackage = (auth, ownerId, itemId, family) => {
    return async dispatch => {
        const request = {
            ownerId: ownerId,
            itemId: itemId,
            family: family
        };
        const toast = {
            onOk: true,
            onOkText: "Il servizio è stato condiviso",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: SHARE_PACKAGE_START,
            request: request
        });

        try {
            const result = await postRestCall(
                `${API_URL}/share/${ownerId}/${itemId}/${family}`,
                auth,
                null,
                {},
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: SHARE_PACKAGE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: SHARE_PACKAGE_ERROR,
                error: e
            });
        }
    };
};
