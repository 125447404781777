import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import {
    Badge,
    Button,
    Checkbox,
    Drawer,
    Dropdown,
    Input,
    Menu,
    Modal,
    Pagination,
    Progress,
    Select,
    Table
} from "antd";
import { SignatureServiceDetails } from "./signature-details";
import { SignatureServicePreview } from "./signature-preview";
import { SlotContainer, ProgressContainer, UsageText, ExtraDataContainer, ExtraDataItem } from "./styled";

import { userIsAuthorized } from "../../../utils/get-roles";
import "./style.css";

const { Search } = Input;

const SignatureService = ({
    admin,
    auth,
    addFilter,
    company,
    loadMore,
    services: { signature },
    signersList,
    slots,
    handleSignerDetalis,
    activateService,
    signerDetails,
    handleDownloadSigner,
    handleUpdateCertificate,
    extraData,
    handleShowSigner,
    loadingShowSigner,
    base64ShowSigner
}) => {
    const [showDetails, setShowDetails] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [filter, setFilter] = useState(null);
    const [documentType, setDocumentType] = useState("FILLED");
    const [activeConfigId, setActiveConfigId] = useState(null);
    const [activeConfig, setActiveConfig] = useState({});
    const [configsOption, setConfigOptions] = useState([]);

    useEffect(() => {
        if (signature && signature.sConfig && signature.sConfig.configs) {
            const activeConfig = signature.sConfig.configs.find(config => config.active);
            setActiveConfigId(activeConfig ? activeConfig.id : null);
        }
    }, [setActiveConfigId, signature]);

    useEffect(() => {
        if (activeConfigId && signature.sConfig.configs) {
            setActiveConfig(signature.sConfig.configs.find(config => config.id === activeConfigId));
        }
    }, [setActiveConfig, activeConfigId, signature]);

    useEffect(() => {
        if (signature && signature.sConfig && signature.sConfig.configs) {
            let options = [];

            signature.sConfig.configs.map(config =>
                options.push(
                    <Select.Option key={config.id} value={config.id}>
                        Id config: {config.id} {config.active ? " - Config ATTIVO" : ""}
                    </Select.Option>
                )
            );

            setConfigOptions(options);
        }
    }, [setActiveConfigId, signature]);

    const columns = [
        {
            title: "Stato",
            key: "enabled",
            render: (value, r) => (
                <div>{r.enabled ? <Badge color="green" text="Attivo" /> : <Badge color="red" text="Disattivo" />}</div>
            )
        },
        {
            title: "Nome/Cognome",
            key: (value, r) => r.id,
            render: (value, r) => <div key={r.id}>{r.firstName + " " + r.lastName}</div>
        },
        {
            title: "Codice fiscale",
            dataIndex: "fiscalCode",
            key: "fiscalCode"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Numero telefono",
            dataIndex: "cellNumber",
            key: "cellNumber"
        },
        {
            title: <FormattedMessage id="actions" />,
            width: "5%",
            dataIndex: "actions",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="0" onClick={() => handleLoadItem(record)}>
                                    <i className="fas fa-info-circle" /> <FormattedMessage id={"show"} />
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const handleLoadItem = item => {
        setShowDetails(true);
        handleSignerDetalis(item.id);
    };

    const handleRiactivation = config => {
        let extraData = {
            fga: "" + config.fga,
            fra: "" + config.fra,
            frq: "" + config.frq,
            fmq: "" + config.fmq,
            erp: "" + config.erp
        };
        activateService(auth.loginAuth, company.info.base.id, "SIGNATURE-FLOW", extraData, company.info.base.id);
    };
    const showExtraDetail = record => {
        const columns = [
            { title: "Tipologia", dataIndex: "type", key: "type" },
            {
                title: "Stato",
                key: "enabled",
                render: (key, r) => <div>{r.enabled ? "Attivo" : "Disattivo"}</div>
            },
            {
                title: "Descrizione stato",
                dataIndex: "status",
                key: "status"
            },
            {
                title: <FormattedMessage id="actions" />,
                key: "actions",
                align: "center",
                render: (text, record) => (
                    <div>
                        <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                            <Button shape="circle" size="small">
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </div>
                )
            }
        ];

        const renderActions = item => {
            let filter = {
                id: record.id,
                type: item.type,
                certificateId: item.id
            };
            return (
                <Menu>
                    <Menu.Item
                        key="0"
                        onClick={() => {
                            filter.documentType = documentType;
                            setFilter(filter);
                            handleShowSigner(filter);
                            setShowPreview(true);
                        }}
                    >
                        <i className="fas fa-eye" style={{ marginRight: 8 }} />
                        <FormattedMessage id={"preview"} />
                    </Menu.Item>
                    <Menu.Item key="1" onClick={() => handleDownloadSigner(filter, "SIGNED")}>
                        <i className="fas fa-download" style={{ marginRight: 8 }} />
                        <FormattedMessage id="download_signed_document" />
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => handleDownloadSigner(filter, "FILLED")}>
                        <i className="fas fa-download" style={{ marginRight: 8 }} />
                        <FormattedMessage id="download_filled_document" />
                    </Menu.Item>
                    {filter.type === "FRM" && userIsAuthorized("c-cert-signer-update", admin.adminInfo.permits) ? (
                        <Menu.Item key="3" onClick={() => handleUpdateCertificate(filter, "FILLED")}>
                            <i className="fas fa-sync-alt" style={{ marginRight: 8 }} />
                            <FormattedMessage id="update_frm_certificate" />
                        </Menu.Item>
                    ) : null}
                </Menu>
            );
        };

        if (record.certificates.length === 0) {
            return <span>Nessuna tipologia di firma per questo firmatario</span>;
        }

        return (
            <Table
                columns={columns}
                dataSource={record.certificates}
                pagination={false}
                rowKey={r => r.id}
                size="small"
                bordered={false}
            />
        );
    };

    const handleDocumentTypeChange = value => {
        setDocumentType(value);
        let newFilter = filter;
        newFilter.documentType = value;
        handleShowSigner(filter);
    };

    return (
        <div className="c-signature-service">
            <Drawer
                title={<FormattedMessage id={"preview"} />}
                width={"50%"}
                onClose={() => setShowPreview(false)}
                visible={showPreview}
            >
                <SignatureServicePreview
                    loading={loadingShowSigner}
                    base64={base64ShowSigner}
                    documentType={documentType}
                    handleDocumentTypeChange={handleDocumentTypeChange}
                />
            </Drawer>
            <Modal
                centered
                footer={null}
                onCancel={() => setShowDetails(false)}
                title={"Info firmatario "}
                visible={showDetails}
            >
                <SignatureServiceDetails signer={signerDetails} />
            </Modal>

            <span className="a1">{"Config disponibili"}</span>
            <div className="info">
                <Select value={activeConfigId} style={{ width: 300 }} onChange={value => setActiveConfigId(value)}>
                    {configsOption}
                </Select>
            </div>
            <br />
            <span className="a1">{"Info Pacchetto"}</span>
            <br />
            <div className="info">
                <div className="info-divided">
                    <span>{"Acquistato il: " + new Date(activeConfig.createdAt).toLocaleString()}</span>
                </div>
                <div className="info-divided">
                    <span>{"Aggiornato il: " + new Date(activeConfig.updatedAt).toLocaleString()}</span>
                </div>
            </div>
            <div className="info">
                <div className="info-divided">
                    <span>{"OwnerId: " + activeConfig.ownerId}</span>
                </div>
                <div className="info-divided">
                    <span>{"ItemId: " + activeConfig.itemId}</span>
                </div>
            </div>
            <div className="info">
                <div className="info-divided">
                    <span>{"Owner CF: " + activeConfig.ownerCf}</span>
                </div>
                <div className="info-divided">
                    <span>{"Item CF: " + activeConfig.itemCf}</span>
                </div>
            </div>
            <div className="info">
                <div className="info-divided">
                    <span>{"Pacchetto readonly: " + (activeConfig.readOnly === true ? "Si" : "No")}</span>
                </div>
                <div className="info-divided">
                    {activeConfig.readOnly === true &&
                    userIsAuthorized("c-activate-readonly-signature", admin.adminInfo.permits) ? (
                        <Button onClick={() => handleRiactivation(activeConfig)} type="primary">
                            Riattiva
                        </Button>
                    ) : null}
                </div>
            </div>
            <br />
            <span className="a1">{"Sigillo personale"}</span>
            <br />
            <div className="info">
                <div className="info-divided">
                    <span>{"Sigillo personale: " + (activeConfig.personalSeal === true ? "Si" : "No")}</span>
                </div>
                <div className="info-divided">
                    <span>
                        {"Cf firmatario: " +
                            (activeConfig.personalSealDefault && activeConfig.personalSealDefault !== "null"
                                ? activeConfig.personalSealDefault
                                : "Non impostato")}
                    </span>
                </div>
            </div>
            <br />
            <span className="a1">{"Pacchetti disponibili"}</span>
            <br />
            <div className="info">
                <Checkbox checked={activeConfig.fra} disabled>
                    {"Firma Remota Avanzata"}
                </Checkbox>

                <Checkbox checked={activeConfig.fga} disabled>
                    {"Firma Grafometrica Avanzata"}
                </Checkbox>
                <Checkbox checked={activeConfig.frq} disabled>
                    {"Firma Remota Qualificata"}
                </Checkbox>
            </div>
            <div style={{ display: "flex" }}>
                <br />
                <Checkbox checked={activeConfig.fmq} disabled>
                    {"Firma Massiva Qualificata"}
                </Checkbox>
                <br />
                <Checkbox checked={activeConfig.erp} disabled>
                    {"Firma da Applicativo"}
                </Checkbox>
            </div>
            <br />
            <span className="a1">
                <FormattedMessage id="additional_data" />
            </span>
            <ExtraDataContainer>
                <ExtraDataItem>
                    <span>
                        <FormattedMessage id="policy_number" />:
                    </span>
                    <Input
                        readOnly={true}
                        disabled={true}
                        value={extraData && extraData.policyNumber ? extraData.policyNumber : ""}
                    />
                </ExtraDataItem>
                <ExtraDataItem>
                    <span>
                        <FormattedMessage id="email" />:
                    </span>
                    <Input
                        readOnly={true}
                        disabled={true}
                        value={extraData && extraData.email ? extraData.email : ""}
                    />
                </ExtraDataItem>
                <ExtraDataItem>
                    <span>
                        <FormattedMessage id="web_site" />:
                    </span>
                    <Input
                        readOnly={true}
                        disabled={true}
                        value={extraData && extraData.webSite ? extraData.webSite : ""}
                    />
                </ExtraDataItem>
            </ExtraDataContainer>
            <ExtraDataContainer>
                <ExtraDataItem>
                    <span>
                        <FormattedMessage id="jurisdiction_place" />:
                    </span>
                    <Input
                        placeholder="Non e' disponibile"
                        readOnly={true}
                        disabled={true}
                        value={extraData && extraData.jurisdictionPlace ? extraData.jurisdictionPlace : ""}
                    />
                </ExtraDataItem>
                <ExtraDataItem>
                    <span>
                        <FormattedMessage id="cell_number" />:
                    </span>
                    <Input
                        readOnly={true}
                        disabled={true}
                        value={extraData && extraData.cellNumber ? extraData.cellNumber : ""}
                    />
                </ExtraDataItem>
            </ExtraDataContainer>
            <span className="a1">{"Riepilogo consumi certificati"}</span>
            <br />
            {slots && (
                <SlotContainer>
                    <ProgressContainer>
                        Certificati FRM
                        <Progress
                            percent={((slots.totalFRM - slots.freeFRM) * 100) / slots.totalFRM}
                            showInfo={false}
                        />
                        <UsageText>{"Utilizzati " + (slots.totalFRM - slots.freeFRM) + "/" + slots.totalFRM}</UsageText>
                    </ProgressContainer>
                    <ProgressContainer>
                        Certificati FSM
                        <Progress
                            percent={((slots.totalFSM - slots.freeFSM) * 100) / slots.totalFSM}
                            showInfo={false}
                        />
                        <UsageText>{"Utilizzati " + (slots.totalFSM - slots.freeFSM) + "/" + slots.totalFSM}</UsageText>
                    </ProgressContainer>
                </SlotContainer>
            )}

            <div>
                <span className="a1">{"Lista certificati"}</span>
                <Search
                    placeholder="Inserisci almeno 3 caratteri per iniziare la ricerca"
                    onSearch={value => addFilter(value)}
                    enterButton
                />
                <br /> <br />
                <Table
                    dataSource={signersList.signers}
                    columns={columns}
                    size="small"
                    rowKey={(r, i) => i}
                    expandedRowRender={record => showExtraDetail(record)}
                    pagination={false}
                    locale={{
                        emptyText: "Nessun firmatario"
                    }}
                    loading={signersList.status.started}
                    footer={() => (
                        <div style={{ textAlign: "right" }}>
                            <Pagination
                                size="small"
                                defaultPageSize={5}
                                defaultCurrent={1}
                                total={signersList.totalItems}
                                onChange={page => loadMore(page)}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default SignatureService;
