import {
    GET_COMPANY_START,
    GET_COMPANY_ERROR,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_RESET
} from "../../actions/company/info";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function company(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_COMPANY_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_COMPANY_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        case GET_COMPANY_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_COMPANY_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
