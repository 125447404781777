import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import CertificationsTab from "../tab";
import CompanyInfo from "../../company/info";

import { Alert, Card, Col, Row, Spin, Skeleton, Tabs, Input, Select } from "antd";
import { PdfHandler } from "../../../components/general/pdf-handler";
import { checkTaxId } from "../../../utils/check-taxId";
import { iVerde } from "../../../utils/constant";
import { cctProvisioningRejectMessages } from "../../../utils/constant";
import { userIsAuthorized } from "../../../utils/get-roles";
import { translateRejectOption } from "../../../utils/translate";
import { ContractWrapper, PdfWrapper, CenteredDiv, InfoContainer, InfoTitle } from "./styled";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

class CertificationsCct extends Component {
    constructor() {
        super();
        this.state = this.initialState;
    }

    get initialState() {
        return {
            itemData: null,
            companyData: null,
            tab: null,
            isValidationOk: null,
            typeValidation: "",
            pdfDelega: null,
            pdfContract: null,
            isLoading: false,
            cfRcValid: true,
            message: "",
            messageType: "",
            adminPermissions: false,
            isVerde: false,
            contractId: null
        };
    }

    static propTypes = {
        intl: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        admin: PropTypes.object.isRequired,
        statusCct: PropTypes.string.isRequired,
        cfProduttore: PropTypes.string.isRequired,
        cfTitolare: PropTypes.string.isRequired,
        displayErrorToast: PropTypes.func.isRequired,
        getDocument: PropTypes.func.isRequired,
        getCctProvisioningRequest: PropTypes.func.isRequired,
        searchCompany: PropTypes.func.isRequired,
        findOwnManagerConnections: PropTypes.func.isRequired,
        putCctProvisioningRequest: PropTypes.func.isRequired,
        downloadDelega: PropTypes.func.isRequired,
        postSuspendCctProvisioningRequest: PropTypes.func.isRequired,
        rejectingContract: PropTypes.func
    };

    componentDidMount() {
        this.startCertification();
    }

    prepareDataCf(data) {
        var node = {};
        node.birthDate = data.dataNascita;
        node.firstName = data.nomeRC;
        node.birthCountry = data.nazione;
        node.lastName = data.cognomeRC;
        node.gender = data.sesso;
        return node;
    }

    downloadFile(data, filename = "file") {
        var download = document.createElement("a");
        download.setAttribute("href", "data:application/octet-stream;charset=utf-8;base64," + data);
        download.setAttribute("download", filename + ".pdf");
        download.click();
    }

    async getConnected(cfProduttore, cfTitolare, active) {
        const { auth, findOwnManagerConnections } = this.props;
        let filter = {
            managedIds: cfTitolare,
            managerIds: cfProduttore,
            appId: ["EIP", "SIG"],
            active: active,
            deleted: !active,
            status: ["VALIDATED"]
        };
        return await findOwnManagerConnections(auth.loginAuth, filter, { page: 0, items: 2000 });
    }

    async startCertification() {
        const {
            admin,
            auth,
            displayErrorToast,
            getCctProvisioningRequest,
            getDocument,
            downloadDelega,
            searchCompany,
            cfProduttore,
            cfTitolare,
            statusCct
        } = this.props;

        this.setState({ isLoading: true });
        try {
            let documentId;
            var filters = {
                "cf-produttore": cfProduttore,
                "cf-titolari": cfTitolare,
                statusCode: statusCct
            };
            let itemData = await getCctProvisioningRequest(auth.loginAuth, filters);
            var pdfDelega = null,
                companyData = null,
                infoContract = null,
                managerData = null;

            if (itemData.provisioning && itemData.provisioning.length !== 0) {
                let cedentePrestatore = itemData.provisioning[0].cedentePrestatore;
                companyData = await searchCompany(auth.loginAuth, cfTitolare, "BASE", true);
                let uuidTitolare = companyData.item.base.uuid;
                if (cfTitolare !== cfProduttore) {
                    managerData = await searchCompany(auth.loginAuth, cfProduttore, "BASE", true);
                    let uuidProduttore = managerData.item.base.uuid;
                    let connResponse = await this.getConnected(uuidProduttore, uuidTitolare, true);
                    if (!(connResponse && connResponse.content && connResponse.content.length)) {
                        connResponse = await this.getConnected(uuidProduttore, uuidTitolare, false);
                    }

                    if (connResponse && connResponse.content && connResponse.content.length) {
                        let item = connResponse.content[0].connections.find(
                            element => element.status.certificationStatus === "CERTIFIED"
                        );
                        if (item && item.id) {
                            documentId = item.id;
                            infoContract = await getDocument(auth.loginAuth, item.id);
                        }
                    }
                } else {
                    documentId = cfTitolare;
                    infoContract = await getDocument(auth.loginAuth, cfTitolare);
                }

                let isVerde = iVerde.includes(cfProduttore);
                pdfDelega = await downloadDelega(auth.loginAuth, itemData.provisioning[0].id);

                const data = itemData.provisioning[0];
                this.setState({
                    pdfContract: infoContract !== null && infoContract ? infoContract.content : null, //gestisce errore da get_document
                    contractId: documentId,
                    isVerde: isVerde,
                    itemData: data,
                    cfRcValid: checkTaxId(cedentePrestatore.cfRC, this.prepareDataCf(cedentePrestatore)) === undefined,
                    pdfDelega: pdfDelega !== null && pdfDelega ? pdfDelega : null,
                    companyData: companyData && companyData.item ? companyData.item : null,
                    managerData: managerData && managerData.item ? managerData.item : null
                });
                const adminPermissions = userIsAuthorized("c-cert-reject-message", admin.adminInfo.permits);
                if (adminPermissions) {
                    this.setState({ adminPermissions: true });
                }
                if (data) {
                    let message =
                        data.exceptionDesc !== undefined
                            ? data.exceptionDesc && translateRejectOption(data.exceptionDesc)
                            : data.description && translateRejectOption(data.description);
                    if (adminPermissions && !cctProvisioningRejectMessages.includes("OTHER")) {
                        cctProvisioningRejectMessages.push("OTHER");
                    } else if (!adminPermissions && cctProvisioningRejectMessages.includes("OTHER")) {
                        cctProvisioningRejectMessages.filter(e => e !== "OTHER");
                    }
                    data && message === "OTHER" && statusCct !== "uploaded" && this.setState({ messageType: "OTHER" });
                }
            } else {
                displayErrorToast("Item da validare non trovato", true);
                this.setState({ itemData: null, pdfDelega: null, companyData: null, managerData: null });
            }
        } catch (e) {
            displayErrorToast(e ? e : "", true);
        }
        this.setState({ isLoading: false });
    }

    async handleReject(itemId) {
        const { auth, putCctProvisioningRequest, intl } = this.props;
        const { message, messageType } = this.state;
        var filters = {
            "cf-produttore": itemId.cfMain,
            "cf-titolare": itemId.cfAzienda,
            "status-code": "rejected"
        };
        const rejectMessage =
            messageType === "OTHER"
                ? message
                : intl.formatMessage({
                      id: `c-cct-provisioning.rejectMessage.${messageType}`
                  });
        var responseValidation = await putCctProvisioningRequest(auth.loginAuth, filters, rejectMessage);
        this.setState({ tab: "end", isValidationOk: responseValidation, typeValidation: "rigettato" });
    }

    async handleCertify(itemId) {
        const { auth, putCctProvisioningRequest } = this.props;
        var filters = {
            "cf-produttore": itemId.cfMain,
            "cf-titolare": itemId.cfAzienda,
            "status-code": "validated"
        };
        this.setState({ waitToCertify: false });
        var responseValidation = await putCctProvisioningRequest(auth.loginAuth, filters);
        this.setState({ tab: "end", isValidationOk: responseValidation, typeValidation: "validato" });
    }

    async handlePause(itemId) {
        const { auth, postSuspendCctProvisioningRequest } = this.props;
        let response = await postSuspendCctProvisioningRequest(auth.loginAuth, itemId.id);
        this.setState({ tab: "end", isValidationOk: response, typeValidation: "sospeso" });
    }

    renderRdC() {
        const { itemData, cfRcValid } = this.state;
        let rdcData = null;

        if (itemData) {
            if (
                itemData.coupons.length > 0 &&
                itemData.coupons[itemData.coupons.length - 1].statusCode === "uploaded"
            ) {
                rdcData = itemData.coupons[itemData.coupons.length - 1].cedentePrestatore;
            } else {
                rdcData = itemData.cedentePrestatore;
            }
        }

        return rdcData ? (
            <div>
                <InfoTitle>
                    <FormattedMessage id="c-certifications.rc-info" />
                </InfoTitle>
                {!cfRcValid && (
                    <Alert message={<FormattedMessage id="c-certifications.warningCFValidation" />} banner />
                )}
                <li>
                    <FormattedMessage id="c-cctconfigservice.lendername" />{" "}
                    <span> {rdcData ? rdcData.nomeRC : <FormattedMessage id="not_available" />}</span>
                </li>
                <li>
                    <FormattedMessage id="c-cctconfigservice.lastname" />{" "}
                    <span> {rdcData ? rdcData.cognomeRC : <FormattedMessage id="not_available" />}</span>
                </li>
                <li>
                    <FormattedMessage id="c-cctconfigservice.sesso" />{" "}
                    <span>
                        {" "}
                        {rdcData ? (
                            rdcData.sesso === "M" ? (
                                "Maschio"
                            ) : (
                                "Femmina"
                            )
                        ) : (
                            <FormattedMessage id="not_available" />
                        )}
                    </span>
                </li>

                <li>
                    <FormattedMessage id="c-cctconfigservice.lendercfcompany" />{" "}
                    <span> {rdcData ? rdcData.cfRC : <FormattedMessage id="not_available" />}</span>
                </li>
                <li>
                    <FormattedMessage id="c-cctconfigservice.lenderbirthplace" />{" "}
                    <span> {rdcData ? rdcData.luogoNascita : <FormattedMessage id="not_available" />}</span>
                </li>
                <li>
                    <FormattedMessage id="c-cctconfigservice.lendernationplace" />{" "}
                    <span>
                        {" "}
                        {rdcData ? (
                            rdcData.nazione === "ZXXX" ? (
                                "Italia"
                            ) : (
                                rdcData.nazione
                            )
                        ) : (
                            <FormattedMessage id="not_available" />
                        )}
                    </span>
                </li>
                <li>
                    <FormattedMessage id="c-cctconfigservice.lenderbirthday" />{" "}
                    <span> {rdcData ? rdcData.dataNascita : <FormattedMessage id="not_available" />}</span>
                </li>

                <li>
                    <FormattedMessage id="c-cctconfigservice.lenderemail" />{" "}
                    <span> {rdcData ? rdcData.email : <FormattedMessage id="not_available" />}</span>
                </li>
                <li>
                    <FormattedMessage id="c-cctconfigservice.lenderpec" />{" "}
                    <span> {rdcData ? rdcData.pec === 0 ? "No" : "Si" : <FormattedMessage id="not_available" />}</span>
                </li>
            </div>
        ) : null;
    }

    render() {
        const {
            tab,
            isValidationOk,
            typeValidation,
            itemData,
            pdfDelega,
            pdfContract,
            isLoading,
            companyData,
            managerData,
            waitToCertify,
            messageType,
            adminPermissions
        } = this.state;
        const { statusCct } = this.props;
        const defaultValue =
            statusCct !== "uploaded" && itemData
                ? itemData.exceptionDesc !== undefined
                    ? itemData.exceptionDesc && translateRejectOption(itemData.exceptionDesc)
                    : itemData.description && translateRejectOption(itemData.description)
                : "";

        const rejectOption = cctProvisioningRejectMessages.map(m => (
            <Option key={m} value={m}>
                <FormattedMessage id={`c-cct-provisioning.rejectMessage.${m}`} />
            </Option>
        ));
        return !isLoading ? (
            <div className="c-cct-provisioning">
                {!itemData ? (
                    <div>
                        <Skeleton active paragraph={{ rows: 10 }} />
                    </div>
                ) : (
                    <div>
                        {tab === "end" ? (
                            <div>
                                <Card className="onlyTabs">
                                    <p className="registry-title">
                                        <FormattedMessage id="c-certifications.title-end" />
                                    </p>
                                    {isValidationOk.code === true ? (
                                        <Alert
                                            type="success"
                                            message={
                                                <div>
                                                    <span>
                                                        Certificato CCT {typeValidation} correttamente. Torna alla
                                                    </span>
                                                    <span
                                                        onClick={() => this.setState(this.initialState)}
                                                        className="link"
                                                    >
                                                        {" "}
                                                        ricerca
                                                    </span>
                                                    <span> per certificare un altra azienda.</span>
                                                </div>
                                            }
                                            banner
                                        />
                                    ) : (
                                        <Alert
                                            type="error"
                                            message={
                                                <div>
                                                    <span>
                                                        Errore: Certificato CCT non {typeValidation} correttamente.
                                                    </span>
                                                    <br />
                                                    <span>Messaggio: {isValidationOk.message}</span>
                                                    <span
                                                        onClick={() => this.setState(this.initialState)}
                                                        className="link"
                                                    >
                                                        {" "}
                                                        Torna alla ricerca e riprovare.
                                                    </span>
                                                </div>
                                            }
                                            banner
                                        />
                                    )}
                                </Card>
                            </div>
                        ) : (
                            <ContractWrapper>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <CertificationsTab
                                            onMoreText={<FormattedMessage id="general.pausedAction" />}
                                            onMoreAction={() => this.handlePause(itemData)}
                                            onMoreDisable={itemData == null || itemData.id == null}
                                            onCancelText={<FormattedMessage id="general.reject" />}
                                            onCancelAction={() => this.handleReject(itemData)}
                                            onCancelDisable={statusCct !== "uploaded" || messageType.length === 0}
                                            onOkText={<FormattedMessage id="general.certify" />}
                                            onOkAction={() => this.handleCertify(itemData)}
                                            onOkDisable={statusCct !== "uploaded" && !waitToCertify}
                                            tabContent={
                                                <InfoContainer>
                                                    <div>
                                                        <Row gutter={24}>
                                                            <Col span={managerData ? 12 : 24}>
                                                                <InfoTitle>
                                                                    <FormattedMessage id="c-certifications.managed-info" />
                                                                </InfoTitle>
                                                                <div className="registry-content">
                                                                    <div className="registry-info">
                                                                        <CompanyInfo
                                                                            company={companyData}
                                                                            onlyInfo={true}
                                                                            noAction={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col span={12}>
                                                                {managerData && (
                                                                    <div>
                                                                        <InfoTitle>
                                                                            <FormattedMessage id="c-certifications.manager-info" />
                                                                        </InfoTitle>
                                                                        <div className="registry-info">
                                                                            <CompanyInfo
                                                                                company={managerData}
                                                                                onlyInfo={true}
                                                                                noAction={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row gutter={24}>
                                                            <Col span={24}>{this.renderRdC()}</Col>
                                                        </Row>
                                                        <br />
                                                        <Row gutter={24}>
                                                            <Col span={24}>
                                                                <div>
                                                                    <FormattedMessage id="c-certifications.addNoteForReject" />
                                                                    <Select
                                                                        defaultValue={
                                                                            defaultValue === "OTHER"
                                                                                ? "Altro"
                                                                                : defaultValue
                                                                        }
                                                                        style={{ width: "100%" }}
                                                                        onChange={o =>
                                                                            this.setState({ messageType: o })
                                                                        }
                                                                        disabled={statusCct !== "uploaded"}
                                                                    >
                                                                        {rejectOption}
                                                                    </Select>
                                                                    <br /> <br />
                                                                    {((messageType === "OTHER" &&
                                                                        adminPermissions &&
                                                                        statusCct === "uploaded") ||
                                                                        (statusCct !== "uploaded" &&
                                                                            messageType === "OTHER")) && (
                                                                        <TextArea
                                                                            placeholder={
                                                                                statusCct !== "uploaded" &&
                                                                                (messageType === "OTHER" ||
                                                                                    defaultValue === "OTHER")
                                                                                    ? itemData.exceptionDesc !==
                                                                                      undefined
                                                                                        ? itemData.exceptionDesc
                                                                                        : itemData.description
                                                                                    : ""
                                                                            }
                                                                            disabled={statusCct !== "uploaded"}
                                                                            autosize={{ minRows: 3, maxRows: 3 }}
                                                                            onChange={v =>
                                                                                this.setState({
                                                                                    message: v.target.value
                                                                                })
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </InfoContainer>
                                            }
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab="Contratto / Atto di affidamento" key="1">
                                                <PdfWrapper>
                                                    <PdfHandler
                                                        loading={!itemData}
                                                        content={!itemData ? null : pdfContract}
                                                        fileName={"contratto_atto_di_affidamento.pdf"}
                                                    />
                                                </PdfWrapper>
                                            </TabPane>
                                            <TabPane tab="Allegato RC" key="2">
                                                <PdfWrapper>
                                                    <PdfHandler
                                                        loading={!itemData}
                                                        content={!itemData ? null : pdfDelega}
                                                        fileName={"allegato_rc.pdf"}
                                                    />
                                                </PdfWrapper>
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </ContractWrapper>
                        )}
                    </div>
                )}
            </div>
        ) : (
            <CenteredDiv>
                <div className="c-cct-provisioning-spinner">{<Spin size="large" />}</div>
            </CenteredDiv>
        );
    }
}
export default injectIntl(CertificationsCct);
