import { useEffect, useState } from "react";
import { userIsAuthorized } from "../../../utils/get-roles";

export const useCompanyTsPaySeriveView = (
    auth,
    admin,
    company,
    companyServices,
    searchItems,
    activateService,
    disableService,
    services,
    getTsPayConfig
) => {
    const [loading, setLoading] = useState(true);
    const [configuration, setConfiguration] = useState([]);
    const [isHavePermission, setIsHavePermission] = useState(false);

    const [tsPayLoading, setTsPayLoading] = useState(true);
    const [tsPayConfiguration, setTsPayConfiguration] = useState([]);

    useEffect(() => {
        let permission = userIsAuthorized("enable-disable-service", admin.adminInfo.permits);
        setIsHavePermission(permission);
    }, [admin.adminInfo.permits]);

    useEffect(() => {
        let configuration = [];
        if (companyServices) {
            let service = companyServices.find(x => x.agyoService.id === "TSPAY");
            if (service && service.ownerId) {
                searchItems(auth, service.ownerId).then(res => {
                    res.items.map(
                        item =>
                            item.item &&
                            configuration.push({
                                ownerId: service.ownerId,
                                ownerDesc: item.item.base.details.description,
                                ownerTaxId: item.item.base.identifier.taxId,
                                ownerVatNumber: item.item.base.identifier.vatNumber,
                                status: item.item.base.status
                            })
                    );
                    setConfiguration(configuration);
                    setLoading(false);
                });
            } else {
                setConfiguration(configuration);
                setLoading(false);
            }
        }
    }, [auth, searchItems, companyServices]);

    useEffect(() => {
        const getConfig = async () => {
            if (company.info && company.info.base && company.info.base.id) {
                let result = await getTsPayConfig(auth, company.info.base.id);
                if (result) {
                    setTsPayConfiguration(result);
                }
            }
            setTsPayLoading(false);
        };

        setTsPayLoading(true);
        setTsPayConfiguration([]);
        getConfig();
    }, [auth, company, getTsPayConfig]);

    const handleEnableDisableService = (isToEnable, ownerId, extraData) => {
        if (isToEnable) {
            activateService(auth, company.info.base.id, "TSPAY", extraData, !ownerId ? company.info.base.id : ownerId);
        } else {
            disableService(auth, company.info.base.id, "TSPAY", extraData, !ownerId ? company.info.base.id : ownerId);
        }
    };

    return {
        configuration,
        loading,
        isHavePermission,
        handleEnableDisableService,
        services,
        tsPayLoading,
        tsPayConfiguration
    };
};
