import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";

import {
    getNewArchiveConfigForHolder,
    getNewArchiveConfigForSubmitter,
    getNewArchiveConfigForHolderSearch
} from "../../../actions/archive/read";

import { uploadFrontend, updateArchiveDocs, updateSubmitterMail } from "../../../actions/archive/write";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";

import { NewArchiveConfig } from "../../../components/company/archive";

import { ArcContent } from "./styled";

const ArcFlowView = ({
    admin,
    auth,
    archiveConfig,
    archiveSubmitterConfig,
    archiveSubmitterTotalConfig,
    archiveConfigLoading,
    archiveSubmitterConfigLoading,
    company,
    getNewArchiveConfigForHolder,
    getNewArchiveConfigForSubmitter,
    getNewArchiveConfigForHolderSearch,
    history,
    intl,
    updateArchiveDocs,
    updateSubmitterMail,
    uploadFrontend
}) => {
    const viewModel = useArcFlowView(
        auth.loginAuth,
        company,
        getNewArchiveConfigForHolder,
        getNewArchiveConfigForSubmitter,
        getNewArchiveConfigForHolderSearch
    );

    const handleArchiveDocs = async record => {
        await updateArchiveDocs(auth.loginAuth, record.submitter.uuid, record.holder.uuid, !record.no_use_archive);
    };

    const handleSubmitterMail = async (record, email, isPec) => {
        await updateSubmitterMail(auth.loginAuth, record.submitter.uuid, record.holder.uuid, email, isPec);
    };

    const handleUpdateFrontEnd = async record => {
        await uploadFrontend(auth.loginAuth, record.submitter.uuid, record.holder.uuid, !record.upload_frontend_active);
    };

    return (
        <ServiceCard
            history={history}
            content={
                <ArcContent>
                    <NewArchiveConfig
                        admin={admin}
                        intl={intl}
                        archiveConfig={archiveConfig}
                        archiveSubmitterConfig={archiveSubmitterConfig}
                        loading={archiveConfigLoading}
                        loadingSubmitter={archiveSubmitterConfigLoading}
                        handleSubmitterPageChange={viewModel.setArchiveSubmitterPage}
                        onSubmitterSearch={viewModel.setCfSubmitterSearch}
                        submitterPage={viewModel.archiveSubmitterPage}
                        archiveSubmitterTotalConfig={archiveSubmitterTotalConfig}
                        archiveSubmitterPageSize={viewModel.archiveSubmitterPageSize}
                        changeArchiveDocs={handleArchiveDocs}
                        changeSubmitterMail={handleSubmitterMail}
                        changeLoadFrontend={handleUpdateFrontEnd}
                        changeFilter={viewModel.setFilterRdC}
                    />
                </ArcContent>
            }
            serviceInfo={{ name: "ARCConfig", category: "ARC" }}
            statisticsEnable={false}
        />
    );
};

ArcFlowView.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    company: PropTypes.object,
    getNewArchiveConfigForHolder: PropTypes.func.isRequired,
    getNewArchiveConfigForSubmitter: PropTypes.func.isRequired,
    getNewArchiveConfigForHolderSearch: PropTypes.func.isRequired,
    intl: PropTypes.object,
    updateArchiveDocs: PropTypes.func.isRequired,
    updateSubmitterMail: PropTypes.func.isRequired,
    uploadFrontend: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    archiveConfig: state.newArchive.readNewArchive.newArchiveHolderConfig.hits,
    archiveSubmitterConfig: state.newArchive.readNewArchive.newArchiveSubmitterConfig.hits,
    archiveSubmitterTotalConfig: state.newArchive.readNewArchive.newArchiveSubmitterConfig.totalHits,
    archiveConfigLoading: state.newArchive.readNewArchive.newArchiveHolderConfig.status.started,
    archiveSubmitterConfigLoading: state.newArchive.readNewArchive.newArchiveSubmitterConfig.status.started,
    archiveDocs: state.newArchive.writeNewArchive.archiveDocs,
    frontend: state.newArchive.writeNewArchive.frontend,
    submitterMail: state.newArchive.writeNewArchive.submitterMail
});
const actions = {
    getNewArchiveConfigForHolder,
    getNewArchiveConfigForSubmitter,
    getNewArchiveConfigForHolderSearch,
    updateArchiveDocs,
    updateSubmitterMail,
    uploadFrontend
};

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(ArcFlowView);

const useArcFlowView = (
    auth,
    company,
    getNewArchiveConfigForHolder,
    getNewArchiveConfigForSubmitter,
    getNewArchiveConfigForHolderSearch
) => {
    const [archiveSubmitterPage, setArchiveSubmitterPage] = useState(1);
    const [archiveSubmitterPageSize] = useState(5);
    const [cfSubmitterSearch, setCfSubmitterSearch] = useState("");
    const [filterRdC, setFilterRdC] = useState(null);

    useEffect(() => {
        getNewArchiveConfigForHolder(auth, company.info.base.uuid, 0, 25);
    }, [getNewArchiveConfigForHolder, auth, company.info.base.uuid]);

    useEffect(() => {
        if (!cfSubmitterSearch || cfSubmitterSearch === "") {
            getNewArchiveConfigForSubmitter(
                auth,
                company.info.base.uuid,
                archiveSubmitterPage - 1,
                archiveSubmitterPageSize,
                filterRdC
            );
        }
    }, [
        getNewArchiveConfigForSubmitter,
        auth,
        company.info.base.uuid,
        archiveSubmitterPage,
        archiveSubmitterPageSize,
        cfSubmitterSearch,
        filterRdC
    ]);

    useEffect(() => {
        if (cfSubmitterSearch && cfSubmitterSearch !== "") {
            getNewArchiveConfigForHolderSearch(auth, company.info.base.uuid, cfSubmitterSearch, 0, 25, filterRdC);
        }
    }, [cfSubmitterSearch, company.info.base.uuid, getNewArchiveConfigForHolderSearch, auth, filterRdC]);

    return {
        archiveSubmitterPage,
        setArchiveSubmitterPage,
        setCfSubmitterSearch,
        setFilterRdC,
        archiveSubmitterPageSize
    };
};
