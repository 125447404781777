import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { contains, isNil } from "ramda";
import { Redirect } from "react-router-dom";

import ServiceCard from "../../../components/general/ServiceCard/info";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getAdempimentiConfig, getAdempimentiSigner } from "../../../actions/services/a-fiscali";
import AFiscaliService from "../../../components/services/a-fiscali";

class AFiscaliServiceView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        getAdempimentiConfig: PropTypes.func.isRequired,
        getAdempimentiSigner: PropTypes.func.isRequired,
        location: PropTypes.object,
        services: PropTypes.object
    };

    componentDidMount() {
        const { auth, company, getAdempimentiConfig, getAdempimentiSigner } = this.props;

        if (company.info.base) {
            company.active.services.forEach(service => {
                if (contains("A-FISCALI-IVA-FLOW", service.agyoService.id)) {
                    getAdempimentiConfig(auth.loginAuth, company.info.base.id);
                    getAdempimentiSigner(auth.loginAuth, company.info.base.id);
                }
            });
        }
    }

    render() {
        const { company, history, services } = this.props;
        return !isNil(company.info.base) ? (
            <ServiceCard
                history={history}
                content={<AFiscaliService active={company.active} services={services} />}
                serviceInfo={{ name: "Adempimenti", category: "TEL" }}
                statisticsEnable={false}
            />
        ) : (
            <Redirect to="/item" />
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    services: state.services
});

const actions = { getAdempimentiConfig, getAdempimentiSigner };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(AFiscaliServiceView);
