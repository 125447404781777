import { API_URL } from "../../../config";
import { putRestCall } from "../../../utils/rest-api-call";

export const REFRESH_PACKAGE_START = "REFRESH_PACKAGE_START";
export const REFRESH_PACKAGE_SUCCESS = "REFRESH_PACKAGE_SUCCESS";
export const REFRESH_PACKAGE_ERROR = "REFRESH_PACKAGE_ERROR";

export const refreshPackage = (auth, id) => {
    return async dispatch => {
        dispatch({
            type: REFRESH_PACKAGE_START
        });

        const toast = {
            onOk: true,
            onOkText: "Pacchetto ricaricato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: id }
        };

        try {
            const result = await putRestCall(
                `${API_URL}/refresh/${id}`,
                auth,
                null,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: REFRESH_PACKAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: REFRESH_PACKAGE_ERROR,
                error: e
            });
        }
    };
};
