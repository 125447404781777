import React, { useState } from "react";
import moment from "moment";
import { Modal, Input, Form } from "antd";
import { DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
const { RangePicker } = DatePicker;
export const CreditsModal = ({ auth, creditsModal, packageId, idConstraint, title, onCancel, addCredits }) => {
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(null);

    const dateFormat = "DD/MM/YYYY";
    let date_ob = new Date();
    let year = date_ob.getFullYear();
    let startDate = "11-01-" + year;
    let endDate = "10-01-" + (year + 1);

    const handleAssignCredits = async (packageId, idConstraint) => {
        setLoading(true);

        let result = await addCredits(auth.loginAuth, packageId, idConstraint, quantity);
        if (result !== null) {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={creditsModal}
            title={title}
            okText={<FormattedMessage id="metering_credits_ok_button" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onCancel={onCancel}
            onOk={() => handleAssignCredits(packageId, idConstraint)}
            okButtonProps={{
                loading: loading
            }}
        >
            <Form.Item
                label={<FormattedMessage id="metering_credits_quantity" />}
                name="quantity"
                rules={[{ required: true }]}
            >
                <Input defaultValue="1" onChange={e => setQuantity(e.target.value)} />
            </Form.Item>
            <p>
                <FormattedMessage id="metering_credits_range_date" />
            </p>
            <RangePicker
                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                size="large"
                format="DD/MM/YYYY"
                disabled={[true, true]}
            />
        </Modal>
    );
};
