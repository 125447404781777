import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button, Select, Tooltip } from "antd";
import { CSVDownload } from "react-csv";

import {
    FilterContent,
    CompanyCodeFilterSelect,
    CategoryFilterSelect,
    ActiveFilterSelect,
    CertificationStatusFilterSelect,
    ClearFilterItems
} from "./styled";

export const ConnectionFilter = ({
    hideClearFilter,
    servicesForFilterOption,
    handleFilterChange,
    connectionFilter,
    hideDownloadCsv,
    disabledDownloadCsv,
    downloadCSVFile
}) => {
    const [taxId, setTaxId] = useState(null);
    const [disabledClearFilter, setDisabledClearFilter] = useState(true);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        setTaxId(connectionFilter && connectionFilter.fullText ? connectionFilter.fullText : "");
        if (
            connectionFilter &&
            (connectionFilter.fullText ||
                connectionFilter.appId ||
                connectionFilter.statusKey ||
                connectionFilter.certificationStatusKey)
        ) {
            setDisabledClearFilter(false);
        } else {
            setDisabledClearFilter(true);
        }
    }, [connectionFilter]);

    let activeOtions = [
        <Select.Option key="Attive" state={{ active: true, deleted: false }}>
            Attive
        </Select.Option>,
        <Select.Option key="In attesa" state={{ active: false, deleted: false }}>
            In attesa
        </Select.Option>,
        <Select.Option key="Cancellate" state={{ deleted: true }}>
            Cancellate
        </Select.Option>,
        <Select.Option key="Tutti gli stati certificazione" state={null}>
            Tutti gli stati certificazione
        </Select.Option>
    ];

    let certificationStatusOptions = [
        <Select.Option key="AWAITING_UPLOAD" state={"AWAITING_UPLOAD"}>
            In attesa caricamento
        </Select.Option>,
        <Select.Option key="AWAITING_APPROVAL" state={"AWAITING_APPROVAL"}>
            In attesa approvazione
        </Select.Option>,
        <Select.Option key="CERTIFIED" state={"CERTIFIED"}>
            Certificata
        </Select.Option>,
        <Select.Option key="Tutte gli stati certificazione" state={null}>
            Tutte gli stati certificazione
        </Select.Option>
    ];

    return (
        <FilterContent>
            <span>
                <Tooltip title={"Codice fiscale"}>
                    <CompanyCodeFilterSelect
                        placeholder={"Inserisci il codice fiscale completo dell'azienda"}
                        onChange={e => setTaxId(e.target.value)}
                        value={taxId}
                        onPressEnter={() => {
                            let filter = {
                                ...connectionFilter,
                                fullText: taxId
                            };
                            handleFilterChange(filter);
                        }}
                    />
                </Tooltip>
                <Tooltip title={"Categoria"}>
                    <CategoryFilterSelect
                        value={
                            connectionFilter && connectionFilter.appId
                                ? connectionFilter.appId
                                : servicesForFilterOption.length
                                ? servicesForFilterOption[servicesForFilterOption.length - 1].key
                                : null
                        }
                        onChange={value => {
                            let filter = {
                                ...connectionFilter
                            };
                            if (value) {
                                filter.appId = value;
                            } else {
                                filter.appId = null;
                            }
                            handleFilterChange(filter);
                        }}
                    >
                        {servicesForFilterOption}
                    </CategoryFilterSelect>
                </Tooltip>

                <Tooltip title={"Attiva"}>
                    <ActiveFilterSelect
                        value={
                            connectionFilter && connectionFilter.statusKey
                                ? connectionFilter.statusKey
                                : activeOtions[activeOtions.length - 1].key
                        }
                        onChange={(value, option) => {
                            let filter = {
                                ...connectionFilter
                            };
                            if (option) {
                                filter.statusKey = option.key;
                                filter.status = option.props.state;
                            } else {
                                filter.statusKey = null;
                                filter.status = null;
                            }
                            handleFilterChange(filter);
                        }}
                    >
                        {activeOtions}
                    </ActiveFilterSelect>
                </Tooltip>

                <Tooltip title={"Stato certificazione"}>
                    <CertificationStatusFilterSelect
                        value={
                            connectionFilter && connectionFilter.certificationStatusKey
                                ? connectionFilter.certificationStatusKey
                                : certificationStatusOptions[certificationStatusOptions.length - 1].key
                        }
                        onChange={(value, option) => {
                            let filter = {
                                ...connectionFilter
                            };
                            if (option) {
                                filter.certificationStatusKey = option.key;
                                filter.certificationStatus = option.props.state;
                            } else {
                                filter.certificationStatusKey = null;
                                filter.certificationStatus = null;
                            }
                            handleFilterChange(filter);
                        }}
                    >
                        {certificationStatusOptions}
                    </CertificationStatusFilterSelect>
                </Tooltip>
                <ClearFilterItems>
                    {!hideClearFilter && (
                        <Button
                            type="primary"
                            shape="round"
                            icon="filter"
                            disabled={disabledClearFilter}
                            onClick={() => {
                                setTaxId("");
                                handleFilterChange();
                            }}
                        >
                            <FormattedMessage id="reset_filters" />
                        </Button>
                    )}
                    <Tooltip title={"Scarica CSV"}>
                        {!hideDownloadCsv && (
                            <Button
                                disabled={disabledDownloadCsv}
                                onClick={() => {
                                    setCsvData([]);
                                    downloadCSVFile().then(value => {
                                        setCsvData(value);
                                    });
                                }}
                                style={{ marginLeft: 5 }}
                            >
                                <i className="fas fa-download" />
                            </Button>
                        )}
                        {csvData && csvData.length ? (
                            <CSVDownload data={csvData} target="_blank" filename={"codiceFiscaleStudio_gestite.csv"} />
                        ) : null}
                    </Tooltip>
                </ClearFilterItems>
            </span>
        </FilterContent>
    );
};

ConnectionFilter.propTypes = {
    hideClearFilter: PropTypes.bool,
    servicesForFilterOption: PropTypes.array,
    handleFilterChange: PropTypes.func.isRequired,
    connectionFilter: PropTypes.object,
    hideDownloadCsv: PropTypes.bool,
    disabledDownloadCsv: PropTypes.bool,
    downloadCSVFile: PropTypes.func
};
