import { GET_TEMPLATE_START, GET_TEMPLATE_SUCCESS, GET_TEMPLATE_ERROR } from "../../../actions/notification/templates";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const getTemplate = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_TEMPLATE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_TEMPLATE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case GET_TEMPLATE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};
