import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";
import { errorBoundary } from "../../../../components/general/error-boundary";
import { getPackageSubscriptions, getCompanyPackages } from "../../../../actions/usage/read";
import { postExtensions } from "../../../../actions/usage/extensions";
import { getFileConsumptions } from "../../../../actions/consumptions";
import { addCredits } from "../../../../actions/credits";
import { sharePackage } from "../../../../actions/metering/share";
import { refreshPackage } from "../../../../actions/metering/refresh";
import { disablePackage } from "../../../../actions/metering/disable";
import {
    MeteringInfoCard,
    MeteringTable,
    MeteringExtensionsModal,
    ConsumptionModal,
    CreditsModal,
    SharePackageModal,
    RefreshPackageModal,
    DisablePackageModal
} from "../../../../components/company/metering";

import { meteringPackageDecode } from "../../../../utils/decode-metering-package";

import { message } from "antd";

import { userIsAuthorized } from "../../../../utils/get-roles";

export const MeteringPackageInfo = ({
    auth,
    admin,
    addCredits,
    history,
    meteringPackageSubscriptions,
    meteringExtensions,
    meteringCompanyPackages,
    postExtensions,
    getFileConsumptions,
    company,
    getPackageSubscriptions,
    getCompanyPackages,
    sharePackage,
    refreshPackage,
    disablePackage,
    share,
    refresh,
    disable,
    match: {
        params: { family }
    }
}) => {
    const [meteringPackagesData, setMeteringPackagesData] = useState([]);
    const [meteringConstraintsData, setMeteringConstraintsData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [visible, setVisible] = useState(false);
    const [creditsModal, setCreditsModal] = useState(false);
    const [sharePackageModal, setSharePackageModal] = useState(false);
    const [refreshPackageModal, setRefreshPackageModal] = useState(false);
    const [disablePackageModal, setDisablePackageModal] = useState(false);
    const [formRef, setFormRef] = useState(null);
    const [packageId, setPackageId] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [idConstraint, setIdConstraint] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const [downloadModal, setDownloadModal] = useState(false);
    const [onLineConsumptions, setOnLineConsumptions] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPackageSubscriptions(auth.loginAuth, company.info.base.uuid, false);
        if (company.infoMetering.id !== undefined) getCompanyPackages(auth.loginAuth, company.infoMetering.id);
    }, [auth, getPackageSubscriptions, getCompanyPackages, company.info.base.uuid, company.infoMetering.id]);

    useEffect(() => {
        let meteringItem = [];
        setMeteringConstraintsData([]);

        let list = [];
        let bbsId = "Dato non disponbile";
        if (meteringPackageSubscriptions.status.ended) {
            Object.values(meteringPackageSubscriptions.companySubscription).forEach(element => {
                if (meteringCompanyPackages.companyPackages !== undefined) {
                    let row = meteringCompanyPackages.companyPackages.find(x => x.id === element.id);
                    if (row !== undefined) bbsId = row.bbsId;
                }
                if (element.family === family) {
                    meteringItem.push({ ...element, bbsId });
                    list.push(element.packageConstraintModelList.filter(item => item.id != null));
                }
                setMeteringConstraintsData(list);
            });
        }

        setMeteringPackagesData(meteringItem);
        setLoaded(true);
    }, [meteringPackageSubscriptions, meteringCompanyPackages, family, auth]);

    const saveFormRef = formRef => {
        setFormRef(formRef);
    };

    const setExtensions = async id => {
        let result = await postExtensions(auth.loginAuth, company.info.base.id, packageId, id);
        if (result != null) {
            message.success("Successo");
        } else {
            message.error("Errore");
        }
    };

    const handleShareService = async (itemId, serviceId) => {
        setLoading(true);
        await sharePackage(auth.loginAuth, ownerId, itemId, serviceId);
        if (!share.status.started) {
            setLoading(false);
            setSharePackageModal(false);
        }
    };
    const handleRefreshPackage = async () => {
        setLoading(true);
        await refreshPackage(auth.loginAuth, packageId);
        if (!refresh.status.started) {
            setLoading(false);
            setRefreshPackageModal(false);
        }
    };
    const handleDisablePackage = async () => {
        setLoading(true);
        await disablePackage(auth.loginAuth, packageId);
        if (!disable.status.started) {
            setLoading(false);
            setDisablePackageModal(false);
        }
    };
    return (
        <MeteringInfoCard
            history={history}
            color={meteringPackageDecode(family).color}
            icon={meteringPackageDecode(family).icon}
            title={meteringPackageDecode(family).title}
            background={meteringPackageDecode(family).background}
            content={
                <div>
                    <MeteringTable
                        meteringPackagesData={meteringPackagesData}
                        meteringConstraintsData={meteringConstraintsData}
                        constraintsLoaded={loaded}
                        onExtensionsClick={id => {
                            setPackageId(id);
                            setVisible(true);
                        }}
                        onAssignCredit={(idPackage, id) => {
                            setPackageId(idPackage);
                            setIdConstraint(id);
                            setCreditsModal(true);
                        }}
                        onDownloadConsumption={(id, createDate, expireDate, online) => {
                            setPackageId(id);
                            setDownloadModal(true);
                            setStartDate(createDate);
                            setExpireDate(expireDate);
                            setOnLineConsumptions(online);
                        }}
                        onSharePackage={ownerId => {
                            setOwnerId(ownerId);
                            setSharePackageModal(true);
                        }}
                        refresh={userIsAuthorized("c-metering-refresh-package", admin.adminInfo.permits)}
                        onRefreshPackage={idPackage => {
                            setPackageId(idPackage);
                            setRefreshPackageModal(true);
                        }}
                        disable={userIsAuthorized("c-metering-disable-package", admin.adminInfo.permits)}
                        onDisablePackage={idPackage => {
                            setPackageId(idPackage);
                            setDisablePackageModal(true);
                        }}
                        share={userIsAuthorized("c-metering-share-package", admin.adminInfo.permits)}
                        extract={
                            userIsAuthorized("c-download-consumptions", admin.adminInfo.permits) &&
                            (family === "FATTURE_ATTIVE_PASSIVE" ||
                                family === "TSPAY" ||
                                family === "CRISI_IMPRESA" ||
                                family.includes("SIGN"))
                        }
                        extensible={
                            userIsAuthorized("m-share-package", admin.adminInfo.permits) &&
                            family === "FATTURE_ATTIVE_PASSIVE"
                        }
                        credits={
                            userIsAuthorized("m-assign-credits", admin.adminInfo.permits) &&
                            family === "FATTURE_ATTIVE_PASSIVE"
                        }
                    />
                    <MeteringExtensionsModal
                        wrappedComponentRef={saveFormRef}
                        visible={visible}
                        onCancel={() => {
                            setVisible(false);
                        }}
                        onCreate={() => {
                            try {
                                const { form } = formRef.props;
                                form.validateFields((err, values) => {
                                    if (!err) {
                                        form.resetFields();
                                        setVisible(false);
                                        setExtensions(values.id);
                                    }
                                });
                            } catch (e) {}
                        }}
                        title={
                            <div>
                                <FormattedMessage id="c-meteringpackageinfo.title" /> {packageId}
                            </div>
                        }
                        message={<FormattedMessage id="c-meteringpackageinfo.message" />}
                    />
                    <ConsumptionModal
                        auth={auth}
                        company={company}
                        downloadModal={downloadModal}
                        startDate={startDate}
                        expireDate={expireDate}
                        packageId={packageId}
                        getFileConsumptions={getFileConsumptions}
                        onLineConsumptions={onLineConsumptions}
                        family={family}
                        onCancel={() => {
                            setDownloadModal(false);
                        }}
                        title={
                            <div>
                                <FormattedMessage id="metering_extract_consumtpion_packageId" /> {packageId}
                            </div>
                        }
                    />
                    <CreditsModal
                        auth={auth}
                        company={company}
                        creditsModal={creditsModal}
                        packageId={packageId}
                        idConstraint={idConstraint}
                        addCredits={addCredits}
                        onCancel={() => {
                            setCreditsModal(false);
                        }}
                        title={
                            <div>
                                <FormattedMessage id="metering_credits_packageId" /> {packageId}
                            </div>
                        }
                    />
                    <SharePackageModal
                        sharePackageModal={sharePackageModal}
                        onCancel={() => {
                            setSharePackageModal(false);
                        }}
                        family={family}
                        handleShareService={handleShareService}
                        loading={loading}
                    />
                    <RefreshPackageModal
                        refreshPackageModal={refreshPackageModal}
                        onCancel={() => {
                            setRefreshPackageModal(false);
                        }}
                        handleRefreshPackage={handleRefreshPackage}
                        loading={loading}
                    />
                    <DisablePackageModal
                        disablePackageModal={disablePackageModal}
                        onCancel={() => {
                            setDisablePackageModal(false);
                        }}
                        handleDisablePackage={handleDisablePackage}
                        loading={loading}
                    />
                </div>
            }
            loaded={loaded}
        />
    );
};

MeteringPackageInfo.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    addCredits: PropTypes.func.isRequired,
    sharePackage: PropTypes.func.isRequired,
    refreshPackage: PropTypes.func.isRequired,
    disablePackage: PropTypes.func.isRequired,
    meteringPackageSubscriptions: PropTypes.object.isRequired,
    meteringExtensions: PropTypes.object.isRequired,
    meteringCompanyPackages: PropTypes.object.isRequired,
    postExtensions: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    getFileConsumptions: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    getPackageSubscriptions: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    meteringPackageSubscriptions: state.usage.getPackageSubscriptions,
    meteringCompanyPackages: state.usage.getCompanyPackages,
    meteringExtensions: state.usage.postExtensions,
    company: state.company,
    share: state.metering.packageMetering.sharePackage,
    refresh: state.metering.packageMetering.refreshPackage,
    disable: state.metering.packageMetering.disablePackage
});

const actions = {
    addCredits,
    sharePackage,
    refreshPackage,
    disablePackage,
    postExtensions,
    getPackageSubscriptions,
    getCompanyPackages,
    getFileConsumptions
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(MeteringPackageInfo);
