import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";

export const DigitalBoxInvoices = ({ subscriptionsList }) => {
    const columns = [
        {
            title: <FormattedMessage id="id" />,
            dataIndex: "id"
        },

        {
            title: <FormattedMessage id={"executed_at"} />,
            dataIndex: "executedAt",
            render: text => <span>{text ? new Date(text).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id={"scheduled_at"} />,
            dataIndex: "scheduledAt",
            render: text => <span>{text ? new Date(text).toLocaleString() : ""}</span>
        }
    ];

    return (
        <div>
            <Table
                title={() => (
                    <div style={{ marginTop: 20, fontWeight: "bold" }}>
                        <FormattedMessage id={"syncronize_list"} />:
                    </div>
                )}
                rowKey={(record, index) => index}
                locale={{
                    emptyText: <FormattedMessage id="not_available" />
                }}
                dataSource={subscriptionsList}
                columns={columns}
                size="middle"
                pagination={false}
            />
        </div>
    );
};

DigitalBoxInvoices.propTypes = {
    subscriptionsList: PropTypes.array.isRequired
};
