import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Modal, Tooltip } from "antd";
import "./style.css";

import SignerStatusTag from "../general/tags/signer-status";
import InfoSigner from "./info";
const locale = {
    emptyText: "Nessun firmatario presente"
};

class SignersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mInfo: false,
            mWithDraw: false,
            mDelete: false,
            signerSelect: {}
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        cancel: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        downloadFilledDocument: PropTypes.func.isRequired,
        getAdempimentiSigner: PropTypes.func.isRequired,
        getData: PropTypes.func.isRequired,
        getSignatureSigner: PropTypes.func.isRequired,
        signer: PropTypes.object.isRequired,
        signers: PropTypes.object,
        update: PropTypes.func.isRequired,
        withdraw: PropTypes.func.isRequired
    };

    handleInfo(signerSelect) {
        const { auth, getData } = this.props;
        getData(auth.loginAuth, Number(signerSelect.id));
        this.setState({ mInfo: true, signerSelect: signerSelect });
    }
    async handleDownload(signerSelect) {
        const { auth, downloadFilledDocument } = this.props;
        let doc = await downloadFilledDocument(auth.loginAuth, Number(signerSelect.id));
        let element = document.createElement("a");
        element.setAttribute("href", `data:application/pdf;base64,${doc.document.content}`);
        element.setAttribute("target", "_blank");
        element.setAttribute("download", "Pdf_compilato.pdf");
        document.body.appendChild(element);
        element.click();
    }

    async handleUpdate(signerSelect) {
        const { auth, company, getAdempimentiSigner, getSignatureSigner, update } = this.props;

        let updateCert = await update(auth.loginAuth, Number(signerSelect.id));
        if (updateCert) {
            if (signerSelect.serviceName === "SIGNATURE-FLOW") {
                getSignatureSigner(auth.loginAuth, company.info.base.id);
            } else {
                getAdempimentiSigner(auth.loginAuth, company.info.base.id);
            }
        }
    }

    async handleWithDraw() {
        const { auth, company, withdraw, getAdempimentiSigner, getSignatureSigner } = this.props;
        const { signerSelect } = this.state;
        let signerId = Number(signerSelect.id);
        let withDrawCert = await withdraw(auth.loginAuth, signerId);
        if (withDrawCert) {
            if (signerSelect.serviceName === "SIGNATURE-FLOW") {
                getSignatureSigner(auth.loginAuth, company.info.base.id);
            } else {
                getAdempimentiSigner(auth.loginAuth, company.info.base.id);
            }
        }
        this.setState({ mWithDraw: false, signerSelect: null });
    }

    handleDelete() {
        const { auth, company, cancel, getAdempimentiSigner, getSignatureSigner } = this.props;
        const { signerSelect } = this.state;
        let signerId = Number(signerSelect.id);
        cancel(auth.loginAuth, signerId).then(res => {
            if (res) {
                if (signerSelect.serviceName === "SIGNATURE-FLOW") {
                    getSignatureSigner(auth.loginAuth, company.info.base.id);
                } else {
                    getAdempimentiSigner(auth.loginAuth, company.info.base.id);
                }
            }
        });

        this.setState({ mDelete: false, signerSelect: null });
    }

    renderInfo() {
        const { mInfo, signerSelect } = this.state;
        const { signer } = this.props;
        return (
            <Modal
                centered
                footer={null}
                onCancel={() => this.setState({ mInfo: false })}
                className="modal"
                title={"Info firmatario " + signerSelect.cfUtente}
                visible={mInfo}
            >
                <InfoSigner signerSelect={signerSelect} signer={signer} />
            </Modal>
        );
    }
    renderWithDraw() {
        const { mWithDraw, signerSelect } = this.state;
        //const { signer } = this.props;
        return (
            <Modal
                centered
                okText="Si"
                cancelText="No"
                onCancel={() => this.setState({ mWithDraw: false })}
                onOk={() => this.handleWithDraw()}
                className="modal"
                title={"Revoca certificato " + signerSelect.cfUtente}
                visible={mWithDraw}
            >
                {"Sei sicuro di voler revocare il certificato di " + signerSelect.cfUtente + "?"}
            </Modal>
        );
    }
    renderDelete() {
        const { mDelete, signerSelect } = this.state;
        //const { signer } = this.props;
        return (
            <Modal
                centered
                okText="Si"
                cancelText="No"
                onCancel={() => this.setState({ mDelete: false })}
                onOk={() => this.handleDelete()}
                className="modal"
                title={"Elimina certificato " + signerSelect.cfUtente}
                visible={mDelete}
            >
                {"Sei sicuro di voler eliminare il certificato di " + signerSelect.cfUtente + "?"}
            </Modal>
        );
    }

    render() {
        const { signers } = this.props;
        const columns = [
            {
                title: "Tipologia",
                dataIndex: "certType"
            },
            {
                title: "Firmatario",
                dataIndex: "cfUtente"
            },
            {
                title: "Stato",
                dataIndex: "extradata.status",
                render: text => <SignerStatusTag signerStatus={text} />
            },
            {
                title: "Azioni",
                render: (text, record) => (
                    <div className="section-action">
                        <Tooltip title="Info">
                            <i
                                className="fas fa-info-circle con-action"
                                onClick={() => this.handleInfo(record)}
                                style={{
                                    cursor: "pointer"
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Download">
                            <i
                                className="fas fa-download icon-action"
                                onClick={() => this.handleDownload(record)}
                                style={{
                                    cursor: "pointer"
                                }}
                            />
                        </Tooltip>
                        {record.extradata.status === "FINAL_SUCCESS" ||
                        record.extradata.status === "ASSIGNED_SUCCESS" ? (
                            <Tooltip title="Revoca">
                                <i
                                    className="fas fa-unlink icon-action"
                                    onClick={() =>
                                        this.setState({
                                            mWithDraw: true,
                                            signerSelect: record
                                        })
                                    }
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <i
                                className="fas fa-unlink icon-action"
                                style={{
                                    color: "grey"
                                }}
                            />
                        )}
                        {record.serviceName === "SIGNATURE-FLOW" ? null : (
                            <span>
                                {record.extradata.status === "SUBMISSION_SUCCESS " ||
                                record.extradata.status === "FINAL_SUCCESS" ||
                                record.extradata.status === "ASSIGNED_SUCCESS" ? (
                                    <i
                                        className="fas fa-trash icon-action"
                                        style={{
                                            color: "grey"
                                        }}
                                    />
                                ) : (
                                    <Tooltip title="Elimina">
                                        <i
                                            className="fas fa-trash icon-action"
                                            onClick={() =>
                                                this.setState({
                                                    mDelete: true,
                                                    signerSelect: record
                                                })
                                            }
                                            style={{
                                                cursor: "pointer"
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </span>
                        )}

                        {record.extradata.status === "FINAL_SUCCESS" ||
                        record.extradata.status === "WITHDRAW_ERROR_E428" ? (
                            <Tooltip title="Sincronizza Certificato">
                                <i
                                    className="fas fa-sync-alt"
                                    onClick={() => this.handleUpdate(record)}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <i
                                className="fas fa-sync-alt"
                                style={{
                                    color: "grey"
                                }}
                            />
                        )}
                    </div>
                )
            }
        ];

        return (
            <div className="c-signers-table">
                <Table
                    columns={columns}
                    dataSource={signers.signers}
                    locale={locale}
                    rowKey={record => record.cfUtente + record.certType}
                    size="small"
                />
                {this.renderInfo()}
                {this.renderWithDraw()}
                {this.renderDelete()}
            </div>
        );
    }
}

export default SignersTable;
