import styled from "styled-components";

export const ContractWrapper = styled.div`
    background-color: white;
    padding: 10px;
    width: 100%;
    height: 95%;
`;
export const PdfWrapper = styled.div`
    border: 1px dashed #0090d1;
    height: 85vh;
    margin: 0px 15px 15px 15px;
`;
export const DownloadButton = styled.div`
    position: absolute;
    right: 15px;
`;

export const SpinContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
`;

export const EmptyContainer = styled.div`
    background: white;
    height: 88vh;
`;

export const GreenCompanyContainer = styled.div`
    color: green;
    text-align: right;
    margin-right: 15px;
    font-size: 18px;
`;
