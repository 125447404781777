import { REGISTRY_WRITE_URL } from "../../config";

import { postRestCall, deleteRestCall, putRestCall } from "../../utils/rest-api-call";

export const CREATE_LAYER_START = "CREATE_LAYER_START";
export const CREATE_LAYER_SUCCESS = "CREATE_LAYER_SUCCESS";
export const CREATE_LAYER_ERROR = "CREATE_LAYER_ERROR";

export function createLayer(auth, layerData, parentId) {
    return async dispatch => {
        dispatch({
            type: CREATE_LAYER_START
        });

        let address = {
            streetName: layerData.streetName,
            streetNumber: layerData.streetNumber,
            city: layerData.city,
            province: layerData.province,
            country: layerData.country,
            zipCode: layerData.zipCode,
            types: ["REGISTERED_OFFICE"]
        };

        let details = {
            classifier: layerData.classifier,
            description: layerData.description,
            addresses: [address]
        };

        let identifier = {
            govCode: layerData.govCode
        };

        let base = {
            identifier: identifier,
            details: details
        };

        let office = {
            base: base
        };

        let request = {
            item: office,
            parentId: parentId
        };

        const toast = {
            onOk: true,
            onOkText: "Ufficio creato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await postRestCall(
                `${REGISTRY_WRITE_URL}/layers`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: CREATE_LAYER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: CREATE_LAYER_ERROR,
                error: e
            });
        }
    };
}
export const DELETE_LAYER_START = "DELETE_LAYER_START";
export const DELETE_LAYER_SUCCESS = "DELETE_LAYER_SUCCESS";
export const DELETE_LAYER_ERROR = "DELETE_LAYER_ERROR";

export function deleteLayer(auth, id) {
    return async dispatch => {
        dispatch({
            type: DELETE_LAYER_START,
            payload: id
        });

        const toast = {
            onOk: true,
            onOkText: "Ufficio eliminato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: id }
        };
        try {
            const result = await deleteRestCall(
                `${REGISTRY_WRITE_URL}/layers/${id}`,
                auth,
                null,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: DELETE_LAYER_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: DELETE_LAYER_ERROR,
                error: e
            });
        }
    };
}
export const UPDATE_LAYER_START = "UPDATE_LAYER_START";
export const UPDATE_LAYER_SUCCESS = "UPDATE_LAYER_SUCCESS";
export const UPDATE_LAYER_ERROR = "UPDATE_LAYER_ERROR";

export function updateLayer(auth, layerData, layerId) {
    return async dispatch => {
        dispatch({
            type: UPDATE_LAYER_START
        });

        let address = {
            streetName: layerData.streetName,
            streetNumber: layerData.streetNumber,
            city: layerData.city,
            province: layerData.province,
            country: layerData.country,
            zipCode: layerData.zipCode,
            types: ["REGISTERED_OFFICE"]
        };

        let details = {
            classifier: "OFFICE",
            description: layerData.description,
            addresses: [address]
        };

        let identifier = {
            govCode: layerData.govCode
        };

        let base = {
            identifier: identifier,
            details: details
        };

        let request = {
            base: base
        };

        const toast = {
            onOk: true,
            onOkText: "Ufficio modificato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await putRestCall(
                `${REGISTRY_WRITE_URL}/layers/${layerId}`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: UPDATE_LAYER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: UPDATE_LAYER_ERROR,
                error: e
            });
        }
    };
}
export const ENABLE_LAYER_START = "ENABLE_LAYER_START";
export const ENABLE_LAYER_SUCCESS = "ENABLE_LAYER_SUCCESS";
export const ENABLE_LAYER_ERROR = "ENABLE_LAYER_ERROR";

export function enableLayer(auth, id) {
    return async dispatch => {
        dispatch({
            type: ENABLE_LAYER_START
        });

        const toast = {
            onOk: true,
            onOkText: "Ufficio attivato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: id }
        };

        try {
            const result = await putRestCall(
                `${REGISTRY_WRITE_URL}/items/${id}/enable`,
                auth,
                null,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: ENABLE_LAYER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: ENABLE_LAYER_ERROR,
                error: e
            });
        }
    };
}

export const DISABLE_LAYER_START = "DISABLE_LAYER_START";
export const DISABLE_LAYER_SUCCESS = "DISABLE_LAYER_SUCCESS";
export const DISABLE_LAYER_ERROR = "DISABLE_LAYER_ERROR";

export function disableLayer(auth, id) {
    return async dispatch => {
        dispatch({
            type: DISABLE_LAYER_START
        });

        const toast = {
            onOk: true,
            onOkText: "Ufficio disattivato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: id }
        };

        try {
            const result = await putRestCall(
                `${REGISTRY_WRITE_URL}/items/${id}/disable`,
                auth,
                null,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: DISABLE_LAYER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DISABLE_LAYER_ERROR,
                error: e
            });
        }
    };
}
