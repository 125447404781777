import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Carousel } from "antd";
import { Container, ContainerTitle, ValueContainer, IconContainer } from "./styled";

export const DashboardSummary = ({ title, company, connections }) => {
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        let summary = [
            <div key={"1"}>
                <IconContainer className={"fas fa-briefcase"} />
                <ValueContainer>{company.active.services ? company.active.services.length : 0} </ValueContainer>
                <span>{<FormattedMessage id="active_services" />}</span>
            </div>,
            <div key={"2"}>
                <IconContainer className={"fas fa-users"} />
                <ValueContainer>{company.users.users ? company.users.users.length : 0} </ValueContainer>
                <span>{<FormattedMessage id="active_services" />}</span>
            </div>,

            <div key={"3"}>
                <IconContainer className={"fas fa-layer-group"} />
                <ValueContainer> {company.info.layers ? company.info.layers.length : 0} </ValueContainer>
                <span>{<FormattedMessage id="offices_created" />}</span>
            </div>
        ];
        if (connections.findOwnManagedConnections.content) {
            summary.push(
                <div key={"4"}>
                    <IconContainer className={"fas fa-share-alt"} />
                    <ValueContainer>{connections.findOwnManagedConnections.totalElements} </ValueContainer>
                    <span>{<FormattedMessage id="managed_companies" />}</span>
                </div>
            );
        }
        if (connections.findOwnManagerConnections.content) {
            summary.push(
                <div key={"5"}>
                    <IconContainer className={"fas fa-share-alt"} />
                    <ValueContainer>{connections.findOwnManagerConnections.totalElements} </ValueContainer>
                    <span>{<FormattedMessage id="managers_companies" />}</span>
                </div>
            );
        }
        setSummary(summary);
    }, [
        company.active.services,
        company.info.layers,
        company.users.users,
        connections.findOwnManagedConnections.content,
        connections.findOwnManagedConnections.totalElements,
        connections.findOwnManagerConnections.content,
        connections.findOwnManagerConnections.totalElements
    ]);

    return (
        <Container>
            <ContainerTitle>{title}</ContainerTitle>
            <Carousel autoplay dotPosition="left">
                {summary}
            </Carousel>
        </Container>
    );
};

DashboardSummary.propTypes = {
    company: PropTypes.object.isRequired,
    connections: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};
