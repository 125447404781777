import styled from "styled-components";

export const ContainerField = styled.span`
    font-weight: bold;
`;

export const ContainerCopyIcon = styled.span`
    cursor: pointer;
    margin: 0px 5px;
`;

export const ContainerItem = styled.div`
    margin-top: 10px;
`;
