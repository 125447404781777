import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export const CCTProvisioningExpanded = ({ description }) => {
    return (
        <div>
            <div>
                <p>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lendercf" />{" "}
                    </b>
                    {description.cedentePrestatore && description.cedentePrestatore.cfRC ? (
                        description.cedentePrestatore.cfRC
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
            </div>
            <div>
                <p style={{ display: "inline-block" }}>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lendername" />
                    </b>
                    {description.cedentePrestatore && description.cedentePrestatore.nomeRC ? (
                        description.cedentePrestatore.nomeRC
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
                <p style={{ float: "right" }}>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lastname" />
                    </b>
                    {description.cedentePrestatore && description.cedentePrestatore.cognomeRC ? (
                        description.cedentePrestatore.cognomeRC
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
            </div>
            <div>
                <p style={{ display: "inline-block" }}>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lenderbirthday" />
                    </b>
                    {description.cedentePrestatore && description.cedentePrestatore.dataNascita ? (
                        description.cedentePrestatore.dataNascita
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
                <p style={{ float: "right" }}>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.sesso" />
                    </b>
                    {description.cedentePrestatore ? (
                        description.cedentePrestatore.sesso
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
            </div>
            <div>
                <p style={{ display: "inline-block" }}>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lenderbirthplace" />
                    </b>
                    {description.cedentePrestatore ? (
                        description.cedentePrestatore.luogoNascita
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
                <p style={{ float: "right" }}>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lendernationplace" />
                    </b>
                    {description.cedentePrestatore && description.cedentePrestatore.nazione ? (
                        description.cedentePrestatore.nazione
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
            </div>
            {description.cedentePrestatore && description.cedentePrestatore.pec ? (
                <p>
                    <b>
                        <FormattedMessage id="c-cctconfigservice.lenderpec" />
                    </b>
                    {description.cedentePrestatore.email ? (
                        description.cedentePrestatore.email
                    ) : (
                        <FormattedMessage id="not_available" />
                    )}
                </p>
            ) : (
                <p>
                    <span>
                        <b>
                            <FormattedMessage id="c-cctconfigservice.lenderemail" />
                        </b>
                        {description.cedentePrestatore && description.cedentePrestatore.email ? (
                            description.cedentePrestatore.email
                        ) : (
                            <FormattedMessage id="not_available" />
                        )}{" "}
                    </span>
                </p>
            )}
            <p>
                <b>
                    <FormattedMessage id="c-cctconfigservice.lenderidcct" />
                </b>
                {description.cedentePrestatore && description.cedentePrestatore.idCct ? (
                    description.cedentePrestatore.idCct
                ) : (
                    <FormattedMessage id="not_available" />
                )}
            </p>
        </div>
    );
};

CCTProvisioningExpanded.propTypes = {
    description: PropTypes.object
};
