import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "antd";
import ItalianProvinceField from "@mondora/arc/antd/ItalianProvinceField";
import TextField from "@mondora/arc/antd/TextField";
import { SelectStringField } from "@mondora/arc/antd/SelectField";

class DataAddress extends Component {
    static propTypes = {
        disabled: PropTypes.bool
    };

    render() {
        const { disabled } = this.props;

        return (
            <Fragment>
                <Row gutter={16}>
                    <Col span={14}>
                        <TextField name="streetName" label="Indirizzo" />
                    </Col>
                    <Col span={4}>
                        <TextField name="streetNumber" label="N. civico" />
                    </Col>
                    <Col span={6}>
                        <TextField name="city" label="Città" />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={14}>
                        <ItalianProvinceField disabled={false} name="province" showSearch={true} label="Provincia" />
                    </Col>
                    <Col span={4}>
                        <TextField name="zipCode" label="CAP" />
                    </Col>
                    <Col span={6}>
                        <SelectStringField
                            disabled={disabled}
                            getOptionKeyFromValue={value => {
                                return value === "" ? undefined : value;
                            }}
                            name="country"
                            label="Paese"
                            options={[{ value: "IT", label: "Italia" }]}
                        />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default DataAddress;
