import { DELETE_LINK_START, DELETE_LINK_ERROR, DELETE_LINK_SUCCESS } from "../../actions/connections/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {}
    }
};

export function deleteLink(previousState = defaultState, { type, payload }) {
    switch (type) {
        case DELETE_LINK_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case DELETE_LINK_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: payload.error
                }
            };
        case DELETE_LINK_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                }
            };
        default:
            return previousState;
    }
}
