import React from "react";

import { compose } from "redux";
import { injectIntl } from "react-intl";
import { errorBoundary } from "../../components/general/error-boundary";
import PageHeader from "../../components/general/PageHeader/index";

import { HackerMonitorIframe } from "../../components/hacker-monitor";

import { IframeContainer } from "./styled";

const HackerMonitorView = ({ history }) => {
    return (
        <div>
            <PageHeader history={history} labelId={`hacker-monitor`} />
            <IframeContainer>
                <HackerMonitorIframe />
            </IframeContainer>
        </div>
    );
};

const composedHoc = compose(
    injectIntl,
    errorBoundary
);
export default composedHoc(HackerMonitorView);
