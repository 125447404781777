import { fieldLength, fieldRequired } from "@mondora/agyo-validations";

import combineValidators from "@mondora/agyo-validations/lib/utils/combineValidators";

import { addressSchema } from "./address";

export const companyEditSchema = {
    type: "object",
    properties: {
        ...addressSchema.properties,
        description: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        taxRegime: {
            type: "string"
        },
        balanceSheetDate: {
            type: "string"
            //validate: desc => combineValidators(fieldRequired("Campo obbligatorio"))(desc)
        }
    }
};
