import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { Row } from "antd";
import { FormattedMessage } from "react-intl";

import ResetPasswordForm from "../reset-password/form";

class ExpiredPassword extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        resetGetAuthInfo: PropTypes.func.isRequired,
        resetLogin: PropTypes.func.isRequired,
        updateAuthPsw: PropTypes.func.isRequired
    };

    componentWillUnmount() {
        const { resetGetAuthInfo, resetLogin } = this.props;
        resetLogin();
        resetGetAuthInfo();
    }

    updatePassword(e) {
        const { auth, updateAuthPsw } = this.props;
        updateAuthPsw(auth, auth.loginAuth.id, e.password);
    }

    render() {
        const { admin } = this.props;

        return admin.password.updatePassword.status &&
            admin.password.updatePassword.status.ended &&
            !admin.password.updatePassword.status.error ? (
            <Redirect to="/resetPassword/success" />
        ) : (
            <Row justify="center" type="flex">
                <div style={{ width: "80%" }}>
                    <ResetPasswordForm
                        errorMsg={
                            admin.password.updatePassword.status.errorInfo
                                ? admin.password.updatePassword.status.errorInfo.message
                                : undefined
                        }
                        onSubmit={e => this.updatePassword(e)}
                        title={<FormattedMessage id={"c-expired-password.title"} />}
                    />
                </div>
            </Row>
        );
    }
}
export default ExpiredPassword;
