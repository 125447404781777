import { getRestCall } from "../utils/rest-api-call";
import { CONTRACT_API_URL } from "../config";

export const GET_DOCUMENT_START = "GET_DOCUMENT_START";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR";

export const GET_CONNECTION_DOCUMENT_START = "GET_CONNECTION_DOCUMENT_START";
export const GET_CONNECTION_DOCUMENT_SUCCESS = "GET_CONNECTION_DOCUMENT_SUCCESS";
export const GET_CONNECTION_DOCUMENT_ERROR = "GET_CONNECTION_DOCUMENT_ERROR";

export function getDocument(auth, id) {
    return async dispatch => {
        dispatch({
            type: GET_DOCUMENT_START,
            request: id
        });

        try {
            const result = await getRestCall(
                `${CONTRACT_API_URL}/agyoDocument/${id}`,
                auth,
                {},
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_DOCUMENT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_DOCUMENT_ERROR,
                error: e
            });
        }
    };
}

export function getConnectionDocument(auth, idDocument) {
    return async dispatch => {
        dispatch({
            type: GET_CONNECTION_DOCUMENT_START,
            request: idDocument
        });
        try {
            const result = await getRestCall(
                `${CONTRACT_API_URL}/connectionDocument/${idDocument}`,
                auth,
                {},
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_CONNECTION_DOCUMENT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_CONNECTION_DOCUMENT_ERROR,
                error: e
            });
        }
    };
}
