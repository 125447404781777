import { SIGNATURE_WRITE_URL } from "../../../config";
import { postRestCall } from "../../../utils/rest-api-call";

export const POST_SIGN_CCT_SEND_START = "POST_SIGN_CCT_SEND_START";
export const POST_SIGN_CCT_SEND_SUCCESS = "POST_SIGN_CCT_SEND_SUCCESS";
export const POST_SIGN_CCT_SEND_ERROR = "POST_SIGN_CCT_SEND_ERROR";

export const postCctSend = (auth, hubId) => async dispatch => {
    const body = {
        hubId: hubId
    };

    dispatch({
        type: POST_SIGN_CCT_SEND_START,
        request: body
    });

    try {
        const result = await postRestCall(
            `${SIGNATURE_WRITE_URL}/cct/send`,
            auth,
            null,
            body,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: POST_SIGN_CCT_SEND_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: POST_SIGN_CCT_SEND_ERROR,
            error: e
        });
    }
};
