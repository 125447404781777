import React from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import { FormattedMessage } from "react-intl";

export const SignaturePermitsDetails = ({ signersPermits }) => {
    return (
        <span style={{ fontWeight: "bold" }}>
            <FormattedMessage id={"view_selected_signers"} />
            <List size="large" dataSource={signersPermits} renderItem={item => <List.Item>{item}</List.Item>} />
        </span>
    );
};

SignaturePermitsDetails.propTypes = {
    signersPermits: PropTypes.array.isRequired
};
