import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Searchbar } from "@mondora/arc/antd/Searchbar";
import { getCctProvisioningRequest } from "../../../actions/cct/read";
import { iVerde } from "../../../utils/constant";

import { displayErrorToast } from "../../../actions/toast";

const CctProvisioningSearch = ({ auth, history, displayErrorToast, getCctProvisioningRequest }) => {
    const onSearch = async itemSearched => {
        itemSearched = itemSearched.toUpperCase();
        if (itemSearched.length === 11 || itemSearched.length === 16) {
            var filters = {
                "cf-produttore": itemSearched
            };
            let itemData = await getCctProvisioningRequest(auth.loginAuth, filters);
            if (!itemData.provisioning) {
                filters = {
                    "cf-titolari": itemSearched
                };
                itemData = await getCctProvisioningRequest(auth.loginAuth, filters);
            }
            if (itemData.provisioning && itemData.provisioning.length !== 0) {
                let node = itemData.provisioning[0];
                if (itemData.provisioning.length === 1) {
                    if (iVerde.includes(itemSearched) && node.statusCode === "initialized") {
                        history.push(
                            `/cct-provisioning/autoupload/cfMain=${node.cfMain}&cfAzienda=${node.cfAzienda}&idCoupon=${node.id}`
                        );
                    } else {
                        history.push(`/cct-provisioning/detail/${node.statusCode}/${node.cfMain}/${node.cfAzienda}`);
                    }
                } else {
                    history.push(`/cct-provisioning/all/${itemSearched}`);
                }
            } else {
                displayErrorToast("Identificativo assente", true);
            }
        } else {
            displayErrorToast("Il valore di ricerca non è corretto", true);
        }
    };
    return (
        <Searchbar
            placeholder="Inserisci l'id dell'azienda da cercare, configurazione 'vecchia'"
            onSearch={v => onSearch(v.value.trim())}
            enterButton
        />
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});
const actions = { displayErrorToast, getCctProvisioningRequest };

CctProvisioningSearch.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(CctProvisioningSearch);
