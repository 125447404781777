import React, { Component } from "react";
import PropTypes from "prop-types";

import { Descriptions, Table, Collapse, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import "./style.css";

class TemplateCard extends Component {
    static propTypes = {
        notification: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired
    };

    render() {
        const { notification, columns, data } = this.props;
        return (
            <div>
                <Collapse>
                    <Collapse.Panel header={`${notification.name} (${notification.type})`} style={{ marginBottom: 10 }}>
                        <span>
                            <Table columns={columns} dataSource={data} />
                            <Descriptions>
                                <Descriptions.Item label={<FormattedMessage id="c-templatecard.appCode" />}>
                                    {notification.appCode}
                                </Descriptions.Item>
                                {notification.tags != null ? (
                                    <Descriptions.Item label={<FormattedMessage id="c-templatecard.tag" />}>
                                        {notification.tags.map((tag, index) => {
                                            return (
                                                <Tag key={index} color={"blue"}>
                                                    {tag}
                                                </Tag>
                                            );
                                        })}
                                    </Descriptions.Item>
                                ) : null}
                                <Descriptions.Item label={<FormattedMessage id="c-templatecard.update" />}>
                                    {new Date(notification.updatedDate).toLocaleString()}
                                </Descriptions.Item>
                            </Descriptions>
                        </span>
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    }
}

export default TemplateCard;
