import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmail } from "validator";

import { Searchbar } from "@mondora/arc/antd/Searchbar";

import { getUser } from "../../../actions/user/read";
import { displayErrorToast } from "../../../actions/toast";

const UsersSearch = ({ auth, displayError, history, displayErrorToast, getUser }) => {
    const onSearch = async userSearched => {
        if (isEmail(userSearched) || userSearched.length >= 36) {
            let user = await getUser(auth.loginAuth, userSearched);
            if (user) {
                history.push(`/user/${userSearched}`);
            } else {
                displayErrorToast("Id utente inserito non trovato.", displayError);
            }
        } else {
            displayErrorToast("Id utente inserito non valido.", true);
        }
    };

    return <Searchbar placeholder="Inserisci l'id dell'utente" onSearch={v => onSearch(v.value.trim())} enterButton />;
};

const mapStateToProps = state => ({
    auth: state.auth
});
const actions = { displayErrorToast, getUser };

UsersSearch.propTypes = {
    auth: PropTypes.object.isRequired,
    displayError: PropTypes.bool.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(UsersSearch);
