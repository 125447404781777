import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

import { Card } from "antd";

import CompanyUsers from "./users";
import CompanyLayers from "./layers";
import CompanyDashboard from "./dashboard";
import CompanyDocuments from "./documents";
import { CompanyServices } from "./services";
import CompanyConnectionsView from "../../views/company/connections";
import { MeteringTabView } from "../../views/company/metering";
import CompanyNotificationsView from "../../views/company/company-notifications";

import { FormattedMessage } from "react-intl";

class Company extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        connections: PropTypes.object,
        createLayer: PropTypes.func.isRequired,
        deleteLayer: PropTypes.func.isRequired,
        disableLayer: PropTypes.func.isRequired,
        enableLayer: PropTypes.func.isRequired,
        getUser: PropTypes.func.isRequired,
        getWebhook: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        removeRoles: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        updateLayer: PropTypes.func.isRequired,
        meteringImports: PropTypes.object.isRequired,
        cctProvRequest: PropTypes.object.isRequired,
        packageSubscriptions: PropTypes.object.isRequired,
        getPackageSubscriptions: PropTypes.func.isRequired,
        getCctProvisioningRequest: PropTypes.func.isRequired,
        getItemWaitingList: PropTypes.func.isRequired,
        itemWaitingList: PropTypes.object.isRequired
    };

    renderMenu(company) {
        const {
            admin,
            admin: {
                adminInfo: { permits }
            },
            auth,
            createLayer,
            connections,
            deleteLayer,
            disableLayer,
            enableLayer,
            history,
            removeRoles,
            services,
            getUser,
            updateLayer,
            meteringImports,
            cctProvRequest,
            packageSubscriptions,
            getPackageSubscriptions,
            getCctProvisioningRequest,
            getItemWaitingList,
            itemWaitingList
        } = this.props;
        let path = history.location.pathname;

        return (
            <div>
                <div className="menu-body">
                    {path.includes("dashboard") && (
                        <CompanyDashboard
                            admin={admin}
                            auth={auth}
                            connections={connections}
                            company={company}
                            history={history}
                            cctProvRequest={cctProvRequest}
                            packageSubscriptions={packageSubscriptions}
                            getPackageSubscriptions={getPackageSubscriptions}
                            getCctProvisioningRequest={getCctProvisioningRequest}
                        />
                    )}
                    <div>
                        {path.includes("services") && (
                            <div>
                                <CompanyServices
                                    auth={auth}
                                    company={company}
                                    services={services}
                                    connections={connections}
                                    meteringImports={meteringImports}
                                    getItemWaitingList={getItemWaitingList}
                                    itemWaitingList={itemWaitingList}
                                />
                            </div>
                        )}
                        {path.includes("metering") && (
                            <div>
                                <MeteringTabView auth={auth} />
                            </div>
                        )}
                        {path.includes("users") && (
                            <CompanyUsers
                                auth={auth}
                                company={company}
                                history={history}
                                removeRoles={removeRoles}
                                getUser={getUser}
                                permits={permits}
                            />
                        )}
                        {path.includes("documents") && <CompanyDocuments auth={auth} company={company} />}
                        {path.includes("layers") && (
                            <CompanyLayers
                                auth={auth}
                                admin={admin}
                                createLayer={createLayer}
                                deleteLayer={deleteLayer}
                                disableLayer={disableLayer}
                                enableLayer={enableLayer}
                                company={company}
                                updateLayer={updateLayer}
                            />
                        )}
                        {path.includes("connections") && <CompanyConnectionsView history={history} />}
                        {path.includes("companyncs") && <CompanyNotificationsView history={history} />}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { company, history } = this.props;
        let keyPath = "/item/" + company.info.base.uuid;

        const tabList = [
            {
                key: keyPath + "/dashboard",
                tab: (
                    <span>
                        <i className="fas fa-columns" style={{ marginRight: 8 }} />
                        <FormattedMessage id="c-company.dashboard" />
                    </span>
                )
            },
            {
                key: keyPath + "/services",
                tab: (
                    <span>
                        <i className="fas fa-briefcase" style={{ marginRight: 8 }} />
                        Servizi
                    </span>
                )
            },
            {
                key: keyPath + "/metering",
                tab: (
                    <span>
                        <i className="fas fa-tachometer-alt" style={{ marginRight: 8 }} />
                        <FormattedMessage id="c-company.metering" />
                    </span>
                )
            },
            {
                key: keyPath + "/users",
                tab: (
                    <span>
                        <i className="fas fa-users" style={{ marginRight: 8 }} />
                        Utenti
                    </span>
                )
            },
            {
                key: keyPath + "/layers",
                tab: (
                    <span>
                        <i className="fas fa-layer-group" style={{ marginRight: 8 }} />
                        Uffici
                    </span>
                )
            },
            {
                key: keyPath + "/connections",
                tab: (
                    <span>
                        <i className="fas fa-link" style={{ marginRight: 8 }} />
                        <FormattedMessage id="connections" />
                    </span>
                )
            },
            {
                key: keyPath + "/documents",
                tab: (
                    <span>
                        <i className="fas fa-folder-open" style={{ marginRight: 8 }} />
                        Documenti
                    </span>
                )
            },
            {
                key: keyPath + "/companyncs",
                tab: (
                    <span>
                        <i className="fas fa-bell" style={{ marginRight: 8 }} />
                        <FormattedMessage id="notification" />
                    </span>
                )
            }
        ];

        return company.info.base ? (
            <div className="c-company">
                <Card tabList={tabList} activeTabKey={history.location.pathname} onTabChange={key => history.push(key)}>
                    <div>{this.renderMenu(company)}</div>
                </Card>
            </div>
        ) : null;
    }
}

export default Company;
