import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Row, Col } from "antd";
import { compose } from "redux";

import TextField from "@mondora/arc/antd/TextField";
import { SelectStringField } from "@mondora/arc/antd/SelectField";
import ISO8601DayField from "@mondora/arc/antd/ISO8601DayField";
import DataAddress from "../data-address";

import { form } from "@mondora/conv-redux-form";
import { injectIntl } from "react-intl";

import { companyEditSchema } from "../../../../utils/form-schema/company-edit";
import { taxRegimeValues } from "../../../../utils/constant";

export class CompanyEditForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Divider orientation="left">Dati Anagrafici</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField name="description" placeholder="Ragione sociale" />
                    </Col>
                    <Col span={12}>
                        <SelectStringField
                            getOptionKeyFromValue={value => {
                                return value === "" ? undefined : value;
                            }}
                            name="taxRegime"
                            options={taxRegimeValues.map(p => {
                                return {
                                    value: p.id,
                                    label: p.name,
                                    searchMatcherString: p.name
                                };
                            })}
                            showSearch={true}
                        />
                    </Col>
                </Row>
                <Divider orientation="left">Indirizzo Principale</Divider>
                <DataAddress disabled={false} />
                <Divider orientation="left">Stato Patrimoniale</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <ISO8601DayField
                            label="Data Chiusura Bilancio"
                            name="balanceSheetDate"
                            displayFormat="DD/MM/YYYY"
                            allowClear={false}
                        ></ISO8601DayField>
                    </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </form>
        );
    }
}

const formDefinition = {
    form: "company-edit-form",
    schema: companyEditSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(CompanyEditForm);
