import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { Button, Input, Popconfirm, Select } from "antd";

import { ButtonContainer, ErrorLabelContainer, InputContainer } from "./styled";

export const AppNameForm = ({ initParams, onConfirm, onCancel, loading }) => {
    /*
        parametri da api relativi ad appname:
            app_name
            application
            contact_mail
            contact_name
            contact_phone
            software
            software_house
            type
    */
    const [appName, setAppName] = useState((initParams && initParams.appName) || null);
    const [application, setApplication] = useState((initParams && initParams.application) || null);
    const [contactMail, setContactMail] = useState((initParams && initParams.contactMail) || null);
    const [contactName, setContactName] = useState((initParams && initParams.contactName) || null);
    const [contactPhone, setContactPhone] = useState((initParams && initParams.contactPhone) || null);
    const [software, setSoftware] = useState((initParams && initParams.software) || null);
    const [softwareHouse, setSoftwareHouse] = useState((initParams && initParams.softwareHouse) || null);
    const [type, setType] = useState((initParams && initParams.type) || null);
    const [validateFormError, setValidateFormError] = useState(false);

    useEffect(() => {
        setAppName(initParams && initParams.appName);
        setApplication(initParams && initParams.application);
        setContactMail(initParams && initParams.contactMail);
        setContactName(initParams && initParams.contactName);
        setContactPhone(initParams && initParams.contactPhone);
        setSoftware(initParams && initParams.software);
        setSoftwareHouse(initParams && initParams.softwareHouse);
        setType(initParams && initParams.type);
    }, [initParams]);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const showPopconfirm = () => {
        setConfirmOpen(true);
    };

    const validateForm = () => {
        if (
            !appName ||
            appName === "" ||
            !application ||
            application === "" ||
            !contactMail ||
            contactMail === "" ||
            !contactName ||
            contactName === "" ||
            !contactPhone ||
            contactPhone === "" ||
            !software ||
            software === "" ||
            !softwareHouse ||
            softwareHouse === "" ||
            !type ||
            type === ""
        ) {
            return false;
        } else {
            return true;
        }
    };
    const handleTypeSelectChange = value => {
        setType(value);
    };

    return (
        <>
            <FormattedMessage id="c-app-name.app-modal-text" />
            <br />
            <InputContainer>
                <Input
                    value={appName}
                    addonBefore={"AppName *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setAppName(e.target.value);
                    }}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input
                    value={application}
                    addonBefore={"Application *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setApplication(e.target.value);
                    }}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input
                    value={contactMail}
                    addonBefore={"Contact Mail *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setContactMail(e.target.value);
                    }}
                    placeholder={"Separatore ';' per mail multiple"}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input
                    value={contactName}
                    addonBefore={"Contact Name *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setContactName(e.target.value);
                    }}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input
                    value={contactPhone}
                    addonBefore={"Contact Phone *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setContactPhone(e.target.value);
                    }}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input
                    value={software}
                    addonBefore={"Software *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setSoftware(e.target.value);
                    }}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input
                    value={softwareHouse}
                    addonBefore={"Software House *"}
                    onChange={e => {
                        setValidateFormError(false);
                        setSoftwareHouse(e.target.value);
                    }}
                />
            </InputContainer>
            <br />
            <InputContainer>
                <Input prefix={<FormattedMessage id="c-app-name.form.type" />} disabled style={{ width: "30%" }} />
                <Select
                    value={type}
                    style={{ width: "70%" }}
                    onChange={value => handleTypeSelectChange(value)}
                    allowClear={true}
                >
                    <Select.Option value="INT">
                        <FormattedMessage id="c-app-name.option.int" />
                    </Select.Option>
                    <Select.Option value="EXT">
                        <FormattedMessage id="c-app-name.option.ext" />
                    </Select.Option>
                </Select>
            </InputContainer>
            <br />
            <ButtonContainer>
                <Button key="0" type="secondary" onClick={onCancel} loading={loading}>
                    <FormattedMessage id="general.cancel" />
                </Button>

                <Popconfirm
                    title={<FormattedMessage id="c-app-name.pop-confirm-text" />}
                    open={confirmOpen}
                    onConfirm={() => {
                        if (validateForm()) {
                            onConfirm({
                                appName,
                                application,
                                contactMail,
                                contactName,
                                contactPhone,
                                software,
                                softwareHouse,
                                type
                            });
                        } else {
                            setValidateFormError(true);
                        }
                    }}
                    okButtonProps={{
                        loading: loading
                    }}
                    onCancel={() => setConfirmOpen(false)}
                    okText={<FormattedMessage id="general.confirm" />}
                    cancelText={<FormattedMessage id="general.cancel" />}
                >
                    <Button key="1" type="primary" onClick={showPopconfirm} loading={loading}>
                        <FormattedMessage id="general.confirm" />
                    </Button>
                </Popconfirm>
            </ButtonContainer>
            {validateFormError && (
                <ErrorLabelContainer>
                    <FormattedMessage id="c-app-name.app-modal-required" />
                </ErrorLabelContainer>
            )}
        </>
    );
};

AppNameForm.propTypes = {
    initParams: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool
};
