import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { contains, isNil } from "ramda";
import { Redirect } from "react-router-dom";

import ServiceCard from "../../../components/general/ServiceCard/info";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getPrivacyUsage } from "../../../actions/usage/privacy";
import PrivacyService from "../../../components/services/privacy";

class PrivacyServiceView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        getPrivacyUsage: PropTypes.func.isRequired,
        location: PropTypes.object,
        services: PropTypes.object,
        usage: PropTypes.object
    };

    componentDidMount() {
        const { auth, company, getPrivacyUsage } = this.props;
        if (company.info.base) {
            company.active.services.forEach(service => {
                if (contains("PRIVACY-FLOW", service.agyoService.id)) {
                    getPrivacyUsage(auth.loginAuth, company.info.base.id);
                }
            });
        }
    }

    render() {
        const { company, history, services, usage } = this.props;
        return !isNil(company.info.base) ? (
            <ServiceCard
                history={history}
                content={<PrivacyService active={company.active} services={services} usage={usage} />}
                serviceInfo={{ name: "Privacy", category: "PRIVACY" }}
                statisticsEnable={false}
            />
        ) : (
            <Redirect to="/item" />
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    services: state.services,
    usage: state.usage
});

const actions = { getPrivacyUsage };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(PrivacyServiceView);
