import {
    GET_SENTINEL_MAILINGLIST_START,
    GET_SENTINEL_MAILINGLIST_SUCCESS,
    GET_SENTINEL_MAILINGLIST_ERROR,
    DELETE_FROM_SENTINEL_MAILINGLIST_START,
    DELETE_FROM_SENTINEL_MAILINGLIST_SUCCESS,
    DELETE_FROM_SENTINEL_MAILINGLIST_ERROR,
    ADD_TO_SENTINEL_MAILINGLIST_START,
    ADD_TO_SENTINEL_MAILINGLIST_SUCCESS,
    ADD_TO_SENTINEL_MAILINGLIST_ERROR,
    NOTIFY_TO_SENTINEL_START,
    NOTIFY_TO_SENTINEL_SUCCESS,
    NOTIFY_TO_SENTINEL_ERROR,
    GET_SENTINEL_GROUPS_START,
    GET_SENTINEL_GROUPS_SUCCESS,
    GET_SENTINEL_GROUPS_ERROR,
    SINGLE_NOTIFY_TO_SENTINEL_START,
    SINGLE_NOTIFY_TO_SENTINEL_SUCCESS,
    SINGLE_NOTIFY_TO_SENTINEL_ERROR
} from "../../../actions/notification/sentinel";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const getMailingList = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SENTINEL_MAILINGLIST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SENTINEL_MAILINGLIST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                mailingList: payload
            };
        case GET_SENTINEL_MAILINGLIST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};

export const deleteFromMailingList = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case DELETE_FROM_SENTINEL_MAILINGLIST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                email: payload
            };
        case DELETE_FROM_SENTINEL_MAILINGLIST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case DELETE_FROM_SENTINEL_MAILINGLIST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};

export const addToMailingList = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case ADD_TO_SENTINEL_MAILINGLIST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                ncsId: payload
            };
        case ADD_TO_SENTINEL_MAILINGLIST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case ADD_TO_SENTINEL_MAILINGLIST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};

export const notifyToSentinel = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case NOTIFY_TO_SENTINEL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                ncsId: payload
            };
        case NOTIFY_TO_SENTINEL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case NOTIFY_TO_SENTINEL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};

export const getSentinelGroups = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SENTINEL_GROUPS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SENTINEL_GROUPS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                groups: payload
            };
        case GET_SENTINEL_GROUPS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};

export const singleNotifyToSentinel = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case SINGLE_NOTIFY_TO_SENTINEL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                ncsId: payload
            };
        case SINGLE_NOTIFY_TO_SENTINEL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case SINGLE_NOTIFY_TO_SENTINEL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        default:
            return state;
    }
};
