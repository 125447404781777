import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { PointerIcon, CopyToClip } from "../../../styled";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

import { Button, Badge, Divider, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";

class UserInfo extends Component {
    static propTypes = {
        isAdmPage: PropTypes.bool.isRequired,
        history: PropTypes.object,
        onlyInfo: PropTypes.bool.isRequired,
        userInfo: PropTypes.object,
        refreshUser: PropTypes.func
    };

    handleGoToUser(userId) {
        const { history } = this.props;
        if (userId) {
            history.push(`/user/${userId}`);
        }
    }

    render() {
        const { refreshUser, isAdmPage, onlyInfo, userInfo } = this.props;
        let companiesRolesValue = userInfo
            ? userInfo.roles
                ? [
                      ...new Set(
                          userInfo.roles
                              .map(role => role.resourceId)
                              .filter(r => r !== "BACKOFFICE" && r !== "REGISTERED")
                      )
                  ].length
                : [].length
            : [].length;

        const columns = [
            {
                title: "AppId",
                dataIndex: "appId"
            },
            {
                title: "FeatureCode",
                dataIndex: "featureCode"
            },
            {
                title: "Ruolo",
                dataIndex: "actionKey"
            }
        ];

        return (
            <div className="c-user-info">
                <div style={{ padding: 15 }}>
                    {!onlyInfo ? (
                        <div className="actions">
                            <Tooltip title="Ricarica dati">
                                <i className=" fas fa-sync-alt refresh-icon" onClick={refreshUser} />
                            </Tooltip>
                            <Link to={isAdmPage ? "/control-pannel-users-backoffice" : "/user"}>
                                <Tooltip title="Torna alla ricerca">
                                    <i className="far fa-times-circle edit-icon" />
                                </Tooltip>
                            </Link>
                            <br />
                        </div>
                    ) : (
                        <div className="actions">
                            <span className="user-info-title">Scheda utente</span>
                            <Button
                                className="user-info-button"
                                onClick={() => this.handleGoToUser(userInfo.profile.id)}
                                disabled={!userInfo}
                            >
                                Vai all'utente
                            </Button>

                            <br />
                        </div>
                    )}
                    {!userInfo ? (
                        <div className="user-empty">Non disponibile</div>
                    ) : (
                        <div>
                            <div style={{ textAlign: "center" }}>
                                {userInfo.profile.type === "PERSONALE" ? (
                                    <i className={"fas fa-user user-icon"} />
                                ) : (
                                    <i className={"fas fa-robot user-icon"} />
                                )}
                                <br />
                                <br />
                                <p className="user-details">{userInfo.profile.id}</p>
                            </div>
                            <span className="field-labels">Identificativo:</span> {userInfo.profile.id}
                            <CopyToClip text={userInfo.profile.id}>
                                <PointerIcon icon={faCopy} />
                            </CopyToClip>
                            <br />
                            <span className="field-labels">UUID:</span> {userInfo.profile.uuid}
                            <CopyToClip text={userInfo.profile.uuid}>
                                <PointerIcon icon={faCopy} />
                            </CopyToClip>
                            <br />
                            <span className="field-labels">NCS ID:</span> {userInfo.profile.ncsId}
                            <CopyToClip text={userInfo.profile.ncsId}>
                                <PointerIcon icon={faCopy} />
                            </CopyToClip>
                            <br />
                            <span className="field-labels">TSID:</span>{" "}
                            {userInfo.profile.tsid ? userInfo.profile.tsid : "Non disponibile"}
                            <br />
                            {userInfo.profile.type === "PERSONALE" && (
                                <span>
                                    <span className="field-labels">Nome:</span> {userInfo.profile.firstName}
                                    <br />
                                    <span className="field-labels">Cognome:</span> {userInfo.profile.lastName}
                                    <br />
                                </span>
                            )}
                            <br />
                            <span className="field-labels">Status:</span>{" "}
                            {userInfo.status.active ? (
                                <Badge status="success" text="Attivo" />
                            ) : (
                                <Badge status="error" text="Disattivo" />
                            )}
                            <br />
                            <span className="field-labels">Numero aziende collegate:</span> {companiesRolesValue}
                            <br />
                            <span className="field-labels">Descrizione:</span> {userInfo.profile.description}
                            <br />
                            <span className="field-labels">Tipologia:</span> {userInfo.profile.type}
                            <br />
                            <span className="field-labels">Data Attivazione:</span>{" "}
                            {userInfo.status.activatedAt
                                ? new Date(userInfo.status.activatedAt).toLocaleString()
                                : "N.D."}
                            <br />
                            <span className="field-labels">Data Creazione:</span>{" "}
                            {userInfo.status.createdAt ? new Date(userInfo.status.createdAt).toLocaleString() : "N.D."}
                            <br />
                            <span className="field-labels">Data Modifica:</span>{" "}
                            {userInfo.status.modifiedAt
                                ? new Date(userInfo.status.modifiedAt).toLocaleString()
                                : "N.D."}
                            <br />
                            <span className="field-labels">Modificato da:</span>{" "}
                            {userInfo.status.modifiedBy ? userInfo.status.modifiedBy : "N.D."}
                            {onlyInfo && (
                                <div>
                                    <Divider />
                                    <Table
                                        rowKey={record => record.actionKey + record.resourceId + record.appId}
                                        columns={columns}
                                        locale={{ emptyText: "Nessun ruolo presente" }}
                                        dataSource={userInfo.roles}
                                        bordered
                                        pagination={false}
                                        size="small"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default UserInfo;
