import { GET_SPID_START, GET_SPID_SUCCESS, GET_SPID_ERROR, GET_SPID_RESET } from "../../actions/spid";
import { GET_TS_SPID_ID_START, GET_TS_SPID_ID_SUCCESS, GET_TS_SPID_ID_ERROR } from "../../actions/spid/spidId";
import {
    GET_TS_SPID_SLOTS_ERROR,
    GET_TS_SPID_SLOTS_START,
    GET_TS_SPID_SLOTS_SUCCESS
} from "../../actions/spid/slots/index.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function spidlist(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_SPID_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SPID_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };
        case GET_SPID_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case GET_SPID_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export const singleSpid = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_TS_SPID_ID_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_TS_SPID_ID_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case GET_TS_SPID_ID_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };
        default:
            return state;
    }
};

export const spidUsage = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_TS_SPID_SLOTS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_TS_SPID_SLOTS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case GET_TS_SPID_SLOTS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
