import { METERING_USAGE, INVOICES_URL } from "../../config";
import { getRestCall, putRestCall, deleteRestCall, postRestCall } from "../../utils/rest-api-call";

export const GET_INVOICE_USAGE_START = "GET_INVOICE_USAGE_START";
export const GET_INVOICE_USAGE_SUCCESS = "GET_INVOICE_USAGE_SUCCESS";
export const GET_INVOICE_USAGE_ERROR = "GET_INVOICE_USAGE_ERROR";

export function getInvoiceUsage(auth, hubId) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id
        };
        dispatch({
            type: GET_INVOICE_USAGE_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${METERING_USAGE}/invoices/${hubId}`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICE_USAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICE_USAGE_ERROR,
                error: e
            });
        }
    };
}

export const GET_INVOICE_DEADLETTER_START = "GET_INVOICE_DEADLETTER_START";
export const GET_INVOICE_DEADLETTER_SUCCESS = "GET_INVOICE_DEADLETTER_SUCCESS";
export const GET_INVOICE_DEADLETTER_ERROR = "GET_INVOICE_DEADLETTER_ERROR";

export function invoiceDeadletter(auth, hubId) {
    return async dispatch => {
        const urlParams = {
            hubId: hubId
        };
        dispatch({
            type: GET_INVOICE_DEADLETTER_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${INVOICES_URL}/deadletter/find`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICE_DEADLETTER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICE_DEADLETTER_ERROR,
                error: e
            });
        }
    };
}

export const DELETE_INVOICE_USAGE_START = "DELETE_INVOICE_USAGE_START";
export const DELETE_INVOICE_USAGE_SUCCESS = "DELETE_INVOICE_USAGE_SUCCESS";
export const DELETE_INVOICE_USAGE_ERROR = "DELETE_INVOICE_USAGE_ERROR";

export function deleteInvoiceUsage(auth, hubId) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id
        };
        dispatch({
            type: DELETE_INVOICE_USAGE_START,
            request: urlParams
        });

        const toast = {
            onOk: true,
            onOkText: "Consumo eliminato correttamente",
            onError: true
        };

        try {
            const result = await deleteRestCall(
                `${METERING_USAGE}/invoices/${hubId}/owner`,
                auth,
                urlParams,
                "",
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: DELETE_INVOICE_USAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DELETE_INVOICE_USAGE_ERROR,
                error: e
            });
        }
    };
}

export const PUT_INVOICE_USAGE_START = "PUT_INVOICE_USAGE_START";
export const PUT_INVOICE_USAGE_SUCCESS = "PUT_INVOICE_USAGE_SUCCESS";
export const PUT_INVOICE_USAGE_ERROR = "PUT_INVOICE_USAGE_ERROR";

export function reassignInvoiceUsage(auth, hubId, updatedOwner) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id
        };
        dispatch({
            type: PUT_INVOICE_USAGE_START,
            request: urlParams
        });

        var data = { newOwner: updatedOwner, hubIdList: [hubId] };
        const toast = {
            onOk: true,
            onOkText: "Consumo aggiornato correttamente",
            onError: true
        };

        try {
            const result = await putRestCall(
                `${METERING_USAGE}/invoices/owner`,
                auth,
                urlParams,
                data,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: PUT_INVOICE_USAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: PUT_INVOICE_USAGE_ERROR,
                error: e
            });
        }
    };
}

export const GET_INVOICE_USAGE_PACKAGE_START = "GET_INVOICE_USAGE_PACKAGE_START";
export const GET_INVOICE_USAGE_PACKAGE_SUCCESS = "GET_INVOICE_USAGE_PACKAGE_SUCCESS";
export const GET_INVOICE_USAGE_PACKAGE_ERROR = "GET_INVOICE_USAGE_PACKAGE_ERROR";

export function invoiceUsagePackage(auth, itemId) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id
        };
        dispatch({
            type: GET_INVOICE_USAGE_PACKAGE_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${METERING_USAGE}/packages/${itemId}`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICE_USAGE_PACKAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICE_USAGE_PACKAGE_ERROR,
                error: e
            });
        }
    };
}

export const PUT_INVOICE_USAGE_PACKAGE_START = "PUT_INVOICE_USAGE_PACKAGE_START";
export const PUT_INVOICE_USAGE_PACKAGE_SUCCESS = "PUT_INVOICE_USAGE_PACKAGE_SUCCESS";
export const PUT_INVOICE_USAGE_PACKAGE_ERROR = "PUT_INVOICE_USAGE_PACKAGE_ERROR";

export function reassignInvoiceUsagePackage(auth, hubId, updatedPackage) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id
        };
        dispatch({
            type: PUT_INVOICE_USAGE_PACKAGE_START,
            request: urlParams
        });

        var data = { newPackage: updatedPackage };
        const toast = {
            onOk: true,
            onOkText: "Pacchetto aggiornato correttamente",
            onError: true
        };

        try {
            const result = await putRestCall(
                `${METERING_USAGE}/invoices/${hubId}/package`,
                auth,
                urlParams,
                data,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: PUT_INVOICE_USAGE_PACKAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: PUT_INVOICE_USAGE_PACKAGE_ERROR,
                error: e
            });
        }
    };
}

export const POST_INVOICE_NOTIFICATION_USAGE_START = "POST_INVOICE_NOTIFICATION_USAGE_START";
export const POST_INVOICE_NOTIFICATION_USAGE_SUCCESS = "POST_INVOICE_NOTIFICATION_USAGE_SUCCESS";
export const POST_INVOICE_NOTIFICATION_USAGE_ERROR = "POST_INVOICE_NOTIFICATION_USAGE_ERROR";

export function replayInvoiceNotificationUsage(auth, sdiId) {
    return async dispatch => {
        const urlParams = {
            sdiId: sdiId
        };
        dispatch({
            type: POST_INVOICE_NOTIFICATION_USAGE_START,
            request: urlParams
        });

        const toast = {
            onOk: true,
            onOkText: "Notifiche ricaricate correttamente",
            onError: true
        };

        try {
            const result = await postRestCall(
                `${INVOICES_URL}/notifications/replay`,
                auth,
                urlParams,
                "",
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: POST_INVOICE_NOTIFICATION_USAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: POST_INVOICE_NOTIFICATION_USAGE_ERROR,
                error: e
            });
        }
    };
}
