import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, message } from "antd";

import { errorBoundary } from "../../components/general/error-boundary";
import { getBankLinkAssociation } from "../../actions/bank-link/read";
import { createBankLinkAssociation, updateBankLinkAssociation } from "../../actions/bank-link/write";
import PageHeader from "../../components/general/PageHeader/index";
import { BankLinkAssociationInfo } from "../../components/bank-link/association-info";
import { ContractWrapper } from "./styled";

const BankLinkAssociationView = ({
    auth,
    history,
    getBankLinkAssociation,
    createBankLinkAssociation,
    updateBankLinkAssociation,
    match: {
        params: { hubId }
    }
}) => {
    const viewModel = useBankLinkAssociationView(
        auth.loginAuth,
        hubId,
        getBankLinkAssociation,
        createBankLinkAssociation,
        updateBankLinkAssociation
    );

    return (
        <div>
            <Card style={{ height: "90vh" }}>
                <ContractWrapper>
                    <PageHeader history={history} labelId={`consortium`} />
                    <BankLinkAssociationInfo
                        loading={viewModel.loading}
                        isNewAssociation={viewModel.isNewAssociation}
                        associationInfo={viewModel.associationInfo}
                        handleUpdateAssociationInfo={viewModel.handleUpdateAssociation}
                        handleDeleteAssociationInfo={viewModel.handleDeleteAssociation}
                        handleCreateAssociationInfo={viewModel.handleCreateAssociation}
                    />
                </ContractWrapper>
            </Card>
        </div>
    );
};

BankLinkAssociationView.propTypes = {
    auth: PropTypes.object.isRequired,
    getBankLinkAssociation: PropTypes.func.isRequired,
    createBankLinkAssociation: PropTypes.func.isRequired,
    updateBankLinkAssociation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = { getBankLinkAssociation, createBankLinkAssociation, updateBankLinkAssociation };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(BankLinkAssociationView);

const useBankLinkAssociationView = (
    auth,
    hubId,
    getBankLinkAssociation,
    createBankLinkAssociation,
    updateBankLinkAssociation
) => {
    const [loading, setLoading] = useState(true);
    const [associationInfo, setAssociationInfo] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const getAssociation = async () => {
            let result = await getBankLinkAssociation(auth, hubId);
            if (result) {
                setAssociationInfo(result);
            }
            setLoading(false);
        };

        setLoading(true);
        setAssociationInfo(null);
        if (hubId !== "new") {
            getAssociation();
        } else {
            setLoading(false);
        }
    }, [auth, getBankLinkAssociation, hubId, refresh]);

    const handleUpdateAssociation = async item => {
        setLoading(true);
        let data = {
            description: item.description,
            name: item.name
        };
        let result = await updateBankLinkAssociation(auth, data, item.id);
        if (result.code) {
            message.success("Documento è aggiornato correttamente");
        } else {
            message.error(`Documento non è aggiornato correttamente. ${result.message ? result.message : ""}`);
        }
        setRefresh(!refresh);
    };

    const handleCreateAssociation = async item => {
        setLoading(true);
        let data = {
            description: item.description,
            name: item.name
        };

        let result = await createBankLinkAssociation(auth, data);
        if (result.code) {
            message.success("Documento è creato correttamente");
        } else {
            message.error(`Documento non è creato correttamente. ${result.message ? result.message : ""}`);
        }
        setLoading(false);
    };

    const handleDeleteAssociation = item => {};

    return {
        loading,
        associationInfo,
        handleUpdateAssociation,
        handleCreateAssociation,
        handleDeleteAssociation,
        isNewAssociation: hubId === "new" ? true : false
    };
};
