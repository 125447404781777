import { FIND_COMPANY_START, FIND_COMPANY_ERROR, FIND_COMPANY_SUCCESS } from "../../actions/company/info";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function find(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FIND_COMPANY_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FIND_COMPANY_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };

        case FIND_COMPANY_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        default:
            return state;
    }
}
