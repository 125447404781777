import React from "react";
import PropTypes from "prop-types";
import { Table, Dropdown, Button, Menu } from "antd";
import { FormattedMessage } from "react-intl";

import { InvoiceFooter } from "../../general/invoice-footer";
import { ColumnSearchProps } from "../../general/column-search-props";

export const BankLinkBanks = ({
    loading,
    banksList,
    currentPage,
    defaultPageSize,
    handlePageSizeChange,
    pageSizeOptions,
    hasNextPage,
    handleNextPage,
    handlePreviousPage,
    handleFilterChange,
    handleNewBank,
    handleEditBank,
    handleDeleteBank,
    query,
    filter
}) => {
    let columns = [
        {
            title: <FormattedMessage id={"name"} />,
            dataIndex: "name",
            width: "15%",
            filteredValue: filter && filter.name ? [filter.name] : null,
            ...ColumnSearchProps("Nome")
        },
        {
            title: <FormattedMessage id={"abi"} />,
            dataIndex: "abi",
            width: "15%",
            filteredValue: filter && filter.abi ? [filter.abi] : null,
            ...ColumnSearchProps("ABI")
        },
        {
            title: <FormattedMessage id={"consortium_id"} />,
            dataIndex: "associationId",
            width: "10%",
            filteredValue: filter && filter.associationId ? [filter.associationId] : null,
            ...ColumnSearchProps("ID Consorzio")
        },
        {
            title: <FormattedMessage id={"actions"} />,
            width: "5%",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleEditBank(record)}>
                    <i className="fas fa-edit" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"c-templatedrawer.edit"} />
                </Menu.Item>
                <Menu.Item key="1" onClick={() => handleDeleteBank(record)} disabled={true}>
                    <i className="fas fa-trash" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"delete"} />
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div>
            <div style={{ textAlign: "right", padding: 8 }}>
                <Button type="primary" size="small" shape="round" onClick={handleNewBank}>
                    <FormattedMessage id={"add"} />
                </Button>
            </div>
            <div style={{ marginTop: 15, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: (
                            <div style={{ height: "50vh" }}>
                                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                                    <FormattedMessage id={"no_result"} />
                                </div>
                            </div>
                        )
                    }}
                    dataSource={banksList}
                    loading={loading}
                    pagination={false}
                    scroll={{ y: 540 }}
                    onChange={(page, filter, sorter) => handleFilterChange(filter)}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize}
                disabled={loading}
                handleInvoicePageSizeChanged={handlePageSizeChange}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === 1}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

BankLinkBanks.propTypes = {
    loading: PropTypes.bool.isRequired,
    banksList: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    defaultPageSize: PropTypes.string.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    filter: PropTypes.object,
    query: PropTypes.object,
    handlePageSizeChange: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    handleNewBank: PropTypes.func.isRequired,
    handleEditBank: PropTypes.func.isRequired,
    handleDeleteBank: PropTypes.func.isRequired
};
