import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Divider, Tag } from "antd";
import AppCard from "../../general/app-card";

import { serviceDecode } from "../../../utils/decode-services";
import { userIsAuthorized } from "../../../utils/get-roles";
import { DashboardSummary } from "./dashboard-summary";
import { DashboardAlarm } from "./dashboard-alarm";

import { useFeature } from "../../../utils/hook/use-feature";

import "./style.css";

const CompanyDashboard = ({
    admin,
    auth,
    company,
    connections,
    history,
    cctProvRequest,
    packageSubscriptions,
    getPackageSubscriptions,
    getCctProvisioningRequest
}) => {
    const spidEnabled = useFeature("Spid_Enabled");

    useEffect(() => {
        let filters = {
            "cf-titolari": company.info.base.identifier.taxId
        };
        getCctProvisioningRequest(auth.loginAuth, filters);
        getPackageSubscriptions(auth.loginAuth, company.info.base.uuid, false);
    }, [auth, company.info.base, getCctProvisioningRequest, getPackageSubscriptions]);

    return (
        <div className="c-company-dashboard">
            <div style={{ display: "flex" }}>
                <DashboardSummary company={company} connections={connections} title={"Riepilogo"} />
                <DashboardAlarm
                    company={company}
                    title={"Avvisi"}
                    cctProvRequest={cctProvRequest.cctProvRequest}
                    packageSubscriptions={packageSubscriptions}
                />
            </div>
            <Divider orientation="left" style={{ paddingBottom: 15 }}>
                <p className="a1">{"Servizi attivi"}</p>
            </Divider>
            {company.active.services && company.active.services.length > 0 ? (
                company.active.services
                    .sort((a, b) => {
                        if (a.agyoService.appId === b.agyoService.appId) return 0;
                        return a.agyoService.appId > b.agyoService.appId ? 1 : -1;
                    })
                    .map(service => (
                        <Tag
                            key={service.agyoService.id}
                            className="service-tag"
                            onClick={() => history.push(`/item/${company.info.base.uuid}/${service.agyoService.id}`)}
                            style={{
                                cursor: "pointer",
                                color: serviceDecode(service.agyoService.appId).color,
                                background: serviceDecode(service.agyoService.appId).background,
                                border: "1px solid" + serviceDecode(service.agyoService.appId).color
                            }}
                        >
                            {service.agyoService.description}
                        </Tag>
                    ))
            ) : (
                <span>Nessun servizio attivo.</span>
            )}
            <Divider orientation="left" style={{ paddingBottom: 15 }}>
                <p className="a1">{"App"}</p>
            </Divider>
            <div className="app">
                <AppCard
                    app={{
                        title: "Fatturazione",
                        description: "Gestione Fatture",
                        id: "EIP",
                        path: "invoices/usage",
                        disabled: false
                    }}
                    company={company}
                    history={history}
                />
                <AppCard
                    app={{
                        title: "Ordini",
                        description: "Gestione Ordini",
                        id: "EIP",
                        path: "orders/active",
                        disabled: false
                    }}
                    company={company}
                    history={history}
                />
                <AppCard
                    app={{
                        title: "Corrispettivi",
                        description: "Gestione Corrispettivi",
                        id: "EIP",
                        path: "ERECEIPTS"
                    }}
                    company={company}
                    history={history}
                />
                <AppCard
                    app={{
                        title: "Pagamenti",
                        description: "Gestione Pagamenti",
                        id: "EIP",
                        path: "payment"
                    }}
                    company={company}
                    history={history}
                />
                {userIsAuthorized("c-company-dashboard-eligible-invoice", admin.adminInfo.permits) && (
                    <AppCard
                        app={{
                            title: "Incassa Subito",
                            description: "Gestione Fatture Eleggibili",
                            id: "EIT",
                            path: "ELIGIBLE-INVOICE"
                        }}
                        company={company}
                        history={history}
                    />
                )}
                <AppCard
                    app={{
                        title: "Adempimenti",
                        description: "Invio di DF e LI",
                        id: "TEL",
                        path: "obligations-tax"
                    }}
                    company={company}
                    history={history}
                />
                <AppCard
                    app={{
                        title: "Firma",
                        description: "Firma dei documenti",
                        id: "SIG",
                        path: "signature"
                    }}
                    company={company}
                    history={history}
                />
                <AppCard
                    app={{
                        title: "DigitalBox",
                        description: "Gestione Documenti",
                        id: "DBX",
                        path: "dbx"
                    }}
                    company={company}
                    history={history}
                />
                {userIsAuthorized("c-company-dashboard-bank-link", admin.adminInfo.permits) && (
                    <AppCard
                        app={{
                            title: "Movimenti Bancari",
                            description: "Gestione Movimenti Bancari",
                            id: "BANK-LINK",
                            path: "BANK-LINK"
                        }}
                        company={company}
                        history={history}
                    />
                )}
                {spidEnabled && userIsAuthorized("c-company-dashboard-spid", admin.adminInfo.permits) && (
                    <AppCard
                        app={{
                            title: "SPID",
                            description: "Riepilogo spid associati all'azienda",
                            id: "SPID",
                            path: "item-spid"
                        }}
                        company={company}
                        history={history}
                    />
                )}
            </div>
        </div>
    );
};

CompanyDashboard.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    connections: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    cctProvRequest: PropTypes.object.isRequired,
    packageSubscriptions: PropTypes.object.isRequired,
    getPackageSubscriptions: PropTypes.func.isRequired,
    getCctProvisioningRequest: PropTypes.func.isRequired
};

export default CompanyDashboard;
