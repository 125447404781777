import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { CompanyDefaultSerives } from "../../../components/company/defaut-services";
import { searchItems, searchCompany } from "../../../actions/search/company";
import { getServicesForCompanySeached } from "../../../actions/search/services";
import { activateService, disableService } from "../../../actions/company/services";
import { userIsAuthorized } from "../../../utils/get-roles";

const CompanyDefaultSerivesView = ({
    auth,
    admin,
    match: {
        params: { serviceId }
    },
    company,
    history,
    searchItems,
    searchCompany,
    getServicesForCompanySeached,
    activateService,
    disableService,
    services
}) => {
    const viewModel = useCompanyDefaultSerivesView(
        auth.loginAuth,
        admin,
        company,
        serviceId,
        company.active.services,
        searchItems,
        activateService,
        disableService,
        services
    );

    return (
        <ServiceCard
            history={history}
            content={
                <CompanyDefaultSerives
                    admin={admin}
                    auth={auth}
                    company={company}
                    serviceId={serviceId}
                    service={viewModel.service}
                    loading={viewModel.loading}
                    configuration={viewModel.configuration}
                    searchCompany={searchCompany}
                    getServicesForCompanySeached={getServicesForCompanySeached}
                    handleEnableDisableService={viewModel.handleEnableDisableService}
                    isHavePermission={viewModel.isHavePermission}
                    services={viewModel.services}
                />
            }
            serviceInfo={{ name: <FormattedMessage id="service" />, category: serviceId }}
            statisticsEnable={false}
        />
    );
};

CompanyDefaultSerivesView.propTypes = {
    admin: PropTypes.object,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    searchItems: PropTypes.func.isRequired,
    services: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    company: state.company,
    services: state.services
});

const actions = { searchItems, searchCompany, getServicesForCompanySeached, activateService, disableService };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyDefaultSerivesView);

const useCompanyDefaultSerivesView = (
    auth,
    admin,
    company,
    serviceId,
    companyServices,
    searchItems,
    activateService,
    disableService,
    services
) => {
    const [loading, setLoading] = useState(true);
    const [configuration, setConfiguration] = useState([]);
    const [isHavePermission, setIsHavePermission] = useState(false);

    useEffect(() => {
        let permission = userIsAuthorized("enable-disable-service", admin.adminInfo.permits);
        setIsHavePermission(permission);
    }, [admin.adminInfo.permits]);

    useEffect(() => {
        let configuration = [];
        if (companyServices) {
            let service = companyServices.find(x => x.agyoService.id === serviceId);
            if (service && service.ownerId) {
                searchItems(auth, service.ownerId, false, null, true).then(res => {
                    res.items.map(
                        item =>
                            item.item &&
                            configuration.push({
                                ownerId: service.ownerId,
                                ownerDesc: item.item.base.details.description,
                                ownerTaxId: item.item.base.identifier.taxId,
                                ownerVatNumber: item.item.base.identifier.vatNumber,
                                status: item.item.base.status
                            })
                    );
                    setConfiguration(configuration);
                    setLoading(false);
                });
            } else {
                setConfiguration(configuration);
                setLoading(false);
            }
        }
    }, [auth, searchItems, serviceId, companyServices]);

    const handleEnableDisableService = (isToEnable, ownerId, extraData) => {
        if (isToEnable) {
            activateService(
                auth,
                company.info.base.id,
                serviceId,
                extraData,
                !ownerId ? company.info.base.id : ownerId
            );
        } else {
            disableService(auth, company.info.base.id, serviceId, extraData, !ownerId ? company.info.base.id : ownerId);
        }
    };

    return {
        configuration,
        loading,
        isHavePermission,
        handleEnableDisableService,
        services
    };
};
