import { callWithRetry, getAuthWithAppName } from "../../utils/callWithRetry";
import { GDPR_LOG_URL } from "../../config";

import gdprLogConfig from "../../assets/thrift/gdprlog/GdprLogConfig";
import gdprLogTypes from "../../assets/thrift/gdprlog/gdprlog_types";

import thrift from "browser-thrift";

const gdprLogUrl = new URL(GDPR_LOG_URL);

const gdprLogClient = thrift.createXHRClient(
    gdprLogConfig,
    thrift.createXHRConnection(gdprLogUrl.hostname, 443, {
        useCors: true,
        path: gdprLogUrl.pathname,
        https: gdprLogUrl.protocol === "https:"
    })
);

export const GET_GDPR_LOG_CONFIG_START = "GET_GDPR_LOG_CONFIG_START";
export const GET_GDPR_LOG_CONFIG_ERROR = "GET_GDPR_LOG_CONFIG_ERROR";
export const GET_GDPR_LOG_CONFIG_SUCCESS = "GET_GDPR_LOG_CONFIG_SUCCESS";

export function getGdprLogConfig(auth, itemId) {
    return dispatch => {
        const request = new gdprLogTypes.RequestFlowId({
            companyId: itemId
        });
        dispatch({
            type: GET_GDPR_LOG_CONFIG_START,
            request: request
        });

        callWithRetry(
            gdprLogClient,
            gdprLogClient.getRoleAssociationList,
            getAuthWithAppName(auth),
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: GET_GDPR_LOG_CONFIG_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: GET_GDPR_LOG_CONFIG_SUCCESS,
                        payload: response
                    });
                }
            }
        );
    };
}
