import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Row, Col } from "antd";

import CertificationsCct from "../../../../components/certifications/cct-provisioning";
import PageHeader from "../../../../components/general/PageHeader/index";

import { displayErrorToast } from "../../../../actions/toast";
import { getCctProvisioningRequest, downloadDelega } from "../../../../actions/cct/read";
import { getDocument } from "../../../../actions/documents";
import { putCctProvisioningRequest, postSuspendCctProvisioningRequest } from "../../../../actions/cct/write";
import { searchCompany } from "../../../../actions/search/company";
import { findOwnManagerConnections } from "../../../../actions/connections/read";
import { rejectingContract } from "../../../../actions/contract";

import { ContractWrapper } from "../../styled";

import PropTypes from "prop-types";

const CctUploadedView = ({
    admin,
    auth,
    history,
    match: {
        params: { statusCct, produttoreId, titolareId }
    },
    getCctProvisioningRequest,
    getDocument,
    putCctProvisioningRequest,
    downloadDelega,
    searchCompany,
    findOwnManagerConnections,
    displayErrorToast,
    postSuspendCctProvisioningRequest,
    rejectingContract
}) => {
    return (
        <ContractWrapper>
            <PageHeader history={history} labelId="c-certifications.validate-cct" />
            <Row gutter={24}>
                <Col span={24}>
                    <CertificationsCct
                        auth={auth}
                        admin={admin}
                        statusCct={statusCct}
                        cfProduttore={produttoreId}
                        cfTitolare={titolareId}
                        displayErrorToast={displayErrorToast}
                        searchCompany={searchCompany}
                        findOwnManagerConnections={findOwnManagerConnections}
                        getDocument={getDocument}
                        getCctProvisioningRequest={getCctProvisioningRequest}
                        putCctProvisioningRequest={putCctProvisioningRequest}
                        downloadDelega={downloadDelega}
                        postSuspendCctProvisioningRequest={postSuspendCctProvisioningRequest}
                        rejectingContract={rejectingContract}
                    />
                </Col>
            </Row>
        </ContractWrapper>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth
});
const actions = {
    displayErrorToast,
    getCctProvisioningRequest,
    putCctProvisioningRequest,
    getDocument,
    downloadDelega,
    searchCompany,
    findOwnManagerConnections,
    postSuspendCctProvisioningRequest,
    rejectingContract
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    )
);

CctUploadedView.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getCctProvisioningRequest: PropTypes.func.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    putCctProvisioningRequest: PropTypes.func.isRequired,
    downloadDelega: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    rejectingContract: PropTypes.func.isRequired
};

export default composedHoc(CctUploadedView);
