import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ActionIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    cursor: pointer;
    color: #0090d1;
    margin: 0px 2px;
`;
