import React from "react";
import PropTypes from "prop-types";
import { Divider } from "antd";
import { FormattedMessage } from "react-intl";
import { Bar } from "../../../../general/usage-bar";

export const UsageConsole = ({ usage }) => {
    return (
        <div>
            <span className="a1">
                <FormattedMessage id="c-invoice.usage.console" />
            </span>
            <br />
            <FormattedMessage
                id={
                    usage != null && usage.usagePerms != null && usage.usagePerms.invoicing
                        ? "general.enable"
                        : "general.notEnable"
                }
            />
            <br />
            <br />
            <span className="a1">I consumi riguardano i servizi Sdi e eRicevi</span>
            <br />
            {usage != null &&
            usage.invoicingUsage != null &&
            usage.invoicingUsage.subscriptions &&
            usage.invoicingUsage.subscriptions.length > 0 ? (
                usage.invoicingUsage.subscriptions
                    .map(
                        (
                            {
                                capacity,
                                total,
                                sent,
                                received,
                                send: supportsActiveInvoicing,
                                receive: supportsPassiveInvoicing,
                                fic
                            },
                            index
                        ) => (
                            <Bar
                                key={index}
                                capacity={capacity}
                                totalConsumptions={total}
                                activeConsumptions={sent}
                                passiveConsumptions={received}
                                fic={fic}
                                supportsActiveInvoicing={supportsActiveInvoicing}
                                supportsPassiveInvoicing={supportsPassiveInvoicing}
                            />
                        )
                    )
                    .reduce(
                        (accumulator, bar) =>
                            !accumulator ? [bar] : [accumulator, <Divider key={accumulator} />, bar],
                        null
                    )
            ) : (
                <span>Nessun consumo disponibile</span>
            )}
        </div>
    );
};

UsageConsole.propTypes = {
    usage: PropTypes.object.isRequired
};
