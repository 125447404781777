import {
    GET_INVOICING_USAGE_START,
    GET_INVOICING_USAGE_ERROR,
    GET_INVOICING_USAGE_SUCCESS,
    GET_INVOICING_DETAILS_START,
    GET_INVOICING_DETAILS_ERROR,
    GET_INVOICING_DETAILS_SUCCESS,
    GET_INVOICING_STATISTICS_START,
    GET_INVOICING_STATISTICS_ERROR,
    GET_INVOICING_STATISTICS_SUCCESS,
    POST_INVOICE_CONSERVATION_START,
    POST_INVOICE_CONSERVATION_SUCCESS,
    POST_INVOICE_CONSERVATION_ERROR
} from "../../actions/usage/invoices";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function invoicingUsage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICING_USAGE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICING_USAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICING_USAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function invoicingDetailsUsage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICING_DETAILS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICING_DETAILS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICING_DETAILS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
export function invoicingStatistics(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_INVOICING_STATISTICS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_INVOICING_STATISTICS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_INVOICING_STATISTICS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export const postInvoiceConservation = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_INVOICE_CONSERVATION_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_INVOICE_CONSERVATION_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_INVOICE_CONSERVATION_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
