import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmail } from "validator";
import "./style.css";

import { Form, Input, Button, Icon } from "antd";
const FormItem = Form.Item;

class FormLogin extends Component {
    static propTypes = {
        buttonType: PropTypes.string.isRequired,
        isResetEnable: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func,
        textSummit: PropTypes.element.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            emailValue: "",
            email: {
                help: "",
                status: ""
            },
            passwordValue: "",
            password: {
                help: "",
                status: ""
            }
        };
    }

    onChange(event, valueToUptade) {
        this.setState({
            [valueToUptade]: event.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { emailValue, passwordValue } = this.state;

        let emailErr = !isEmail(emailValue)
            ? {
                  action: this.setState({
                      email: {
                          status: "error",
                          help: "Inserisci una mail valida"
                      }
                  }),
                  status: false
              }
            : {
                  action: this.setState({ email: { status: "", help: "" } }),
                  status: true
              };
        let passwordErr =
            passwordValue === ""
                ? {
                      action: this.setState({
                          password: {
                              status: "error",
                              help: "Campo obbligatorio"
                          }
                      }),
                      status: false
                  }
                : {
                      action: this.setState({
                          password: { status: "", help: "" }
                      }),
                      status: true
                  };

        if (emailErr.status && passwordErr.status) {
            this.handleContinue();
        }
    }

    handleContinue() {
        const { onSubmit } = this.props;
        const { emailValue, passwordValue } = this.state;

        onSubmit("BACKOFFICE", emailValue, passwordValue);
    }

    render() {
        const { customSubmit, textSummit, isResetEnable, reset, buttonType } = this.props;

        return (
            <div className="c-form-login">
                <Form onSubmit={e => this.handleSubmit(e)}>
                    <FormItem validateStatus={this.state.email.status} help={this.state.email.help}>
                        <Input
                            addonBefore={<Icon type="user" />}
                            placeholder="Email"
                            value={this.state.emailValue}
                            onChange={e => this.onChange(e, "emailValue")}
                        />
                    </FormItem>
                    <FormItem validateStatus={this.state.password.status} help={this.state.password.help}>
                        <Input
                            addonBefore={<Icon type="lock" />}
                            type="password"
                            placeholder="Password"
                            value={this.state.passwordValue}
                            onChange={e => this.onChange(e, "passwordValue")}
                        />
                    </FormItem>
                    <FormItem>
                        {isResetEnable ? (
                            <span className="button-forgot-psw" onClick={reset}>
                                Password dimenticata?
                            </span>
                        ) : null}
                        {customSubmit ? (
                            customSubmit(e => this.handleSubmit(e))
                        ) : (
                            <Button className="root-button" type={buttonType} htmlType="submit">
                                {textSummit}
                            </Button>
                        )}
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default FormLogin;
