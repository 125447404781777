import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge } from "antd";

class CompanyStatusTag extends Component {
    static propTypes = {
        companyStatus: PropTypes.string,
        tagAction: PropTypes.func
    };

    render() {
        const { companyStatus, tagAction } = this.props;
        let onClickAction = tagAction === undefined ? () => {} : () => tagAction();
        switch (companyStatus) {
            case "VERIFIED":
                return <Badge status="success" text="Verificata" onClick={onClickAction} />;
            case "VALIDATED":
                return <Badge status="success" text="Validata" onClick={onClickAction} />;
            case "UNVERIFIABLE":
                return <Badge status="error" text="Non validata" onClick={onClickAction} />;
            case "PENDING_VERIFY":
            case "UNVERIFIABLE_PENDING_VALIDATE":
            case "REJECTED_PENDING_VALIDATE":
            case "VERIFIED_PENDING_VALIDATE":
                return <Badge status="warning" text="In attesa di validazione" onClick={onClickAction} />;
            case "AWAITING_APPROVAL":
                return <Badge status="warning" text="In attesa di certificazione" onClick={onClickAction} />;
            case "REJECTED":
                return <Badge status="error" text="Rigettata" onClick={onClickAction} />;
            case "CERTIFIED":
                return <Badge status="success" text="Certificata" onClick={onClickAction} />;
            case "AWAITING_UPLOAD":
                return <Badge status="warning" text="In attesa di caricamento" onClick={onClickAction} />;
            default:
                return companyStatus;
        }
    }
}

export default CompanyStatusTag;
