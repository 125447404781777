import { callWithRetry, getAuthWithAppNameForAdempimenti } from "../../utils/callWithRetry";
import { A_FISCALI_IVA_CONFIG, SIGNER_READ_URL } from "../../config";

import aFiscaliConfig from "../../assets/thrift/a-fiscali-iva/AFiscaliIvaFlowConfig";
import aFiscaliTypes from "../../assets/thrift/a-fiscali-iva/a-fiscali-iva-flow-config_types";

import thrift from "browser-thrift";
import { getRestCall } from "../../utils/rest-api-call";

const aFiscaliUrl = new URL(A_FISCALI_IVA_CONFIG);
const aFiscaliClient = thrift.createXHRClient(
    aFiscaliConfig,
    thrift.createXHRConnection(aFiscaliUrl.hostname, 443, {
        useCors: true,
        path: aFiscaliUrl.pathname,
        https: aFiscaliUrl.protocol === "https:"
    })
);

export const GET_ADEMPIMENTI_CONFIG_START = "GET_ADEMPIMENTI_CONFIG_START";
export const GET_ADEMPIMENTI_CONFIG_ERROR = "GET_ADEMPIMENTI_CONFIG_ERROR";
export const GET_ADEMPIMENTI_CONFIG_SUCCESS = "GET_ADEMPIMENTI_CONFIG_SUCCESS";

export function getAdempimentiConfig(auth, companyId) {
    return dispatch => {
        const request = new aFiscaliTypes.AFiscaliIvaData({
            id: companyId
        });
        dispatch({
            type: GET_ADEMPIMENTI_CONFIG_START,
            request: request
        });

        callWithRetry(
            aFiscaliClient,
            aFiscaliClient.getConfig,
            getAuthWithAppNameForAdempimenti(auth),
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: GET_ADEMPIMENTI_CONFIG_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: GET_ADEMPIMENTI_CONFIG_SUCCESS,
                        payload: response
                    });
                }
            }
        );
    };
}

export const GET_ADEMPIMENTI_CONFIG_RESET = "GET_ADEMPIMENTI_CONFIG_RESET";

export function resetAdempimentiConfig() {
    return {
        type: GET_ADEMPIMENTI_CONFIG_RESET
    };
}

export const GET_ADEMPIMENTI_SIGNER_START = "GET_ADEMPIMENTI_SIGNER_START";
export const GET_ADEMPIMENTI_SIGNER_SUCCESS = "GET_ADEMPIMENTI_SIGNER_SUCCESS";
export const GET_ADEMPIMENTI_SIGNER_ERROR = "GET_ADEMPIMENTI_SIGNER_ERROR";

export function getAdempimentiSigner(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_ADEMPIMENTI_SIGNER_START
        });

        const urlParameters = {
            serviceName: "A-FISCALI-IVA-FLOW",
            companyFiscalCode: itemId
        };

        try {
            const result = await getRestCall(
                `${SIGNER_READ_URL}/signers/listSigners`,
                auth,
                urlParameters,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_ADEMPIMENTI_SIGNER_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_ADEMPIMENTI_SIGNER_ERROR,
                error: e
            });
        }
    };
}
