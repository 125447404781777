import * as config from "../../../config";
import { getRestCall, postRestCall } from "../../../utils/rest-api-call";

export const GET_SIGNERS_START = "GET_SIGNERS_START";
export const GET_SIGNERS_SUCCESS = "GET_SIGNERS_SUCCESS";
export const GET_SIGNERS_ERROR = "GET_SIGNERS_ERROR";

export function getSigners(auth, transmitterId, fullText = "", page = 0, size = 5) {
    return async dispatch => {
        try {
            dispatch({ type: GET_SIGNERS_START, payload: transmitterId });

            const response = await postRestCall(
                `${config.SIGNER_V2_READ_URL}/signers/search?itemId=${transmitterId}&page=${page}&size=${size}&fullText=${fullText}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_SIGNERS_SUCCESS,
                payload: response
            });
            return response;
        } catch (err) {
            dispatch({ type: GET_SIGNERS_ERROR, error: err });
        }
    };
}

export const GET_SLOT_START = "GET_SLOT_START";
export const GET_SLOT_SUCCESS = "GET_SLOT_SUCCESS";
export const GET_SLOT_ERROR = "GET_SLOT_ERROR";

export function getSlot(auth, itemId) {
    return async dispatch => {
        try {
            dispatch({ type: GET_SLOT_START, payload: { itemId } });

            const response = await getRestCall(
                `${config.SIGNER_V2_READ_URL}/certificates/slotUsage/${itemId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_SLOT_SUCCESS,
                payload: response
            });
            return response;
        } catch (err) {
            dispatch({ type: GET_SLOT_ERROR, error: err });
        }
    };
}

export const GET_SIGNERS_DETAILS_START = "GET_SIGNERS_DETAILS_START";
export const GET_SIGNERS_DETAILS_SUCCESS = "GET_SIGNERS_DETAILS_SUCCESS";
export const GET_SIGNERS_DETAILS_ERROR = "GET_SIGNERS_DETAILS_ERROR";

/**
 * 
    Get details of signer documents.
 *
 *
 * @name getSignDetails
 * @alias Details of signer documents
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {integer} `id` pass id of document.
 * @return {undefined}
 */
export const getSignerDetails = (auth, id) => async dispatch => {
    dispatch({
        type: GET_SIGNERS_DETAILS_START
    });

    try {
        const result = await getRestCall(
            `${config.SIGNER_V2_READ_URL}/signers/${id}`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_SIGNERS_DETAILS_SUCCESS,
            payload: { data: result }
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGNERS_DETAILS_ERROR,
            error: e
        });
    }
};

export const DOWNLOAD_SIGNERS_START = "DOWNLOAD_SIGNERS_TART";
export const DOWNLOAD_SIGNERS_SUCCESS = "DOWNLOAD_SIGNERS_SUCCESS";
export const DOWNLOAD_SIGNERS_ERROR = "DOWNLOAD_SIGNERS_ERROR";

/**
 * 
    Download signer documents.
 *
 *
 * @name downloadSigner
 * @alias Download signer documents.
 * @param {Object} `auth` auth.loginAuth for authentication.
 * @param {integer} `signerId` id of document.
 * @param {String} `certType` type of certification.
 * @param {String} `documentType` type of document.
 * @return {undefined}
 */
export const downloadSigner = (auth, signerId, certType, documentType = "SIGNED") => async dispatch => {
    const urlParams = {
        signerId,
        certType,
        documentType
    };

    dispatch({
        type: DOWNLOAD_SIGNERS_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${config.SIGNER_V2_READ_URL}/utils/download/document`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: DOWNLOAD_SIGNERS_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: DOWNLOAD_SIGNERS_ERROR,
            error: e
        });
    }
};

export const GET_SIGNERS_EXTRA_DATA_START = "GET_SIGNERS_EXTRA_DATA_START";
export const GET_SIGNERS_EXTRA_DATA_SUCCESS = "GET_SIGNERS_EXTRA_DATA_SUCCESS";
export const GET_SIGNERS_EXTRA_DATA_ERROR = "GET_SIGNERS_EXTRA_DATA_ERROR";

/**
 * 
    Get extra data of signer.
 *
 *
 * @name getSignerExtraData
 * @alias Extra data of signer
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `itemId` pass itemId.
 * @return {undefined}
 */
export const getSignerExtraData = (auth, itemId) => async dispatch => {
    let urlParams = {
        itemId
    };

    dispatch({
        type: GET_SIGNERS_EXTRA_DATA_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${config.DIGITAL_SIGNER_READ_URL}/registry/extra-data`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_SIGNERS_EXTRA_DATA_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGNERS_EXTRA_DATA_ERROR,
            error: e
        });
    }
};
