import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../../../components/general/error-boundary";

import { MeteringLynfaService } from "../../../../components/company/metering-lynfa";
import ServiceCard from "../../../../components/general/ServiceCard/info";

const MeteringLynfaTabView = ({ history, meteringImports, meteringTotalImports }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (meteringImports.imports != null) {
            let data = [];
            let index = 0;
            Object.values(meteringImports.imports).forEach(element => {
                let object = {
                    item: element.item,
                    amount: element.amount,
                    key: index
                };
                data.push(object);
                index++;
            });
            setData(data);
        }
    }, [meteringImports]);

    return (
        <ServiceCard
            history={history}
            content={<MeteringLynfaService data={data} importsAmount={meteringTotalImports.importsAmount} />}
            serviceInfo={{ name: <FormattedMessage id="c-meteringlynfatabview.importfirstnote" />, category: "IMPORT" }}
            statisticsEnable={false}
        />
    );
};

MeteringLynfaTabView.propTypes = {
    meteringImports: PropTypes.object.isRequired,
    meteringTotalImports: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    meteringImports: state.usage.getImports,
    meteringTotalImports: state.usage.getTotalImports
});

const actions = {};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(MeteringLynfaTabView);
