import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Spin, Icon } from "antd";

import { getInvoiceImport } from "../../../actions/digitalbox/batch";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { errorBoundary } from "../../../components/general/error-boundary";
import { DigitalBoxInvoices } from "../../../components/company/digitalbox";

import { SpinContainer } from "./styled";

const CompanyDbxView = ({
    auth,
    history,
    company,
    getInvoiceImport,
    match: {
        params: { companyId }
    }
}) => {
    const viewModel = useCompanyDbxView(auth.loginAuth, companyId, getInvoiceImport);

    return (
        <div>
            <ServiceCard
                history={history}
                content={
                    viewModel.loading ? (
                        <SpinContainer>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                        </SpinContainer>
                    ) : (
                        <DigitalBoxInvoices subscriptionsList={viewModel.subscriptionsList} />
                    )
                }
                serviceInfo={{ name: "Digital Box", category: "DBX" }}
                statisticsEnable={false}
            />
        </div>
    );
};

CompanyDbxView.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    company: PropTypes.object,
    getInvoiceImport: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company
});

const actions = { getInvoiceImport };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyDbxView);

const useCompanyDbxView = (auth, companyId, getInvoiceImport) => {
    const [loading, setLoading] = useState(true);
    const [subscriptionsList, setSubscriptionsList] = useState([]);

    useEffect(() => {
        const getInvoiceImportInfo = async () => {
            let resultInvoiceImport = await getInvoiceImport(auth, companyId);
            if (
                resultInvoiceImport &&
                resultInvoiceImport._embedded &&
                resultInvoiceImport._embedded.subscriptions &&
                resultInvoiceImport._embedded.subscriptions.length
            ) {
                setSubscriptionsList(resultInvoiceImport._embedded.subscriptions);
            }
            setLoading(false);
        };

        if (companyId) {
            setLoading(true);
            setSubscriptionsList([]);
            getInvoiceImportInfo();
        }
    }, [auth, companyId, getInvoiceImport]);

    return {
        loading,
        subscriptionsList
    };
};
