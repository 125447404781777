import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { restrictToPermits } from "../../components/general/hoc";
import { errorBoundary } from "../../components/general/error-boundary";
import { getPortalState, putPortalInMaintenance } from "../../actions/portal";

import AdmTools from "../../components/control-pannel-tools";
import { ViewTitle } from "../../styled";
import { FormattedMessage } from "react-intl";

class ControlPannelToolsView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        getPortalState: PropTypes.func.isRequired,
        putPortalInMaintenance: PropTypes.func.isRequired,
        portal: PropTypes.object
    };

    componentDidMount() {
        const { auth, getPortalState } = this.props;
        getPortalState(auth.loginAuth);
    }

    render() {
        const { admin, auth, portal, getPortalState, putPortalInMaintenance } = this.props;
        return (
            <div>
                <ViewTitle>
                    <FormattedMessage id="general.admTools" />
                </ViewTitle>
                <AdmTools
                    admin={admin}
                    auth={auth}
                    portal={portal}
                    getPortalState={getPortalState}
                    putPortalInMaintenance={putPortalInMaintenance}
                />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    portal: state.portal
});

const actions = { getPortalState, putPortalInMaintenance };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-control-pannel-tools-view")
);
export default composedHoc(ControlPannelToolsView);
