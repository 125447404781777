import { B2B_READ_API_URL, OTC_INVOICE_TRADING_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_INVOICES_START = "GET_INVOICES_START";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR";

export function getInvoices(auth, filter, page, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size: size
        };
        dispatch({
            type: GET_INVOICES_START,
            request: urlParams
        });
        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v2/invoices/search`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICES_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICES_ERROR,
                error: e
            });
        }
    };
}

export const GET_INVOICE_START = "GET_INVOICE_START";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";

export const getInvoice = (auth, data) => {
    return async dispatch => {
        let url = "";
        if (data.sdiId) {
            url = `${B2B_READ_API_URL}/v2/invoices/sdiId/` + data.sdiId;
        } else {
            url = `${B2B_READ_API_URL}/v2/invoices/` + data.hubId;
        }

        const urlParams = {};

        dispatch({
            type: GET_INVOICE_START,
            request: urlParams
        });
        try {
            const result = await getRestCall(url, auth, urlParams, dispatch, auth.refreshToken);

            dispatch({
                type: GET_INVOICE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_INVOICE_ERROR,
                error: e
            });
            return null;
        }
    };
};

export const DOWNLOAD_CONTENT_START = "DOWNLOAD_CONTENT_START";
export const DOWNLOAD_CONTENT_SUCCESS = "DOWNLOAD_CONTENT_SUCCESS";
export const DOWNLOAD_CONTENT_ERROR = "DOWNLOAD_CONTENT_ERROR";

export function downloadContent(auth, hubId) {
    return async dispatch => {
        const urlParams = {};
        dispatch({
            type: DOWNLOAD_CONTENT_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v2/invoices/` + hubId + "/download?format=XML",
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            //let decoded = window.atob(result);
            dispatch({
                type: DOWNLOAD_CONTENT_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DOWNLOAD_CONTENT_ERROR,
                error: e
            });
        }
    };
}

export const GET_FAW_CONFIG_START = "GET_FAW_CONFIG_START";
export const GET_FAW_CONFIG_SUCCESS = "GET_FAW_CONFIG_SUCCESS";
export const GET_FAW_CONFIG_ERROR = "GET_FAW_CONFIG_ERROR";

export const getFAWConfig = (auth, companyId) => async dispatch => {
    dispatch({
        type: GET_FAW_CONFIG_START
    });

    try {
        const result = await getRestCall(
            `${B2B_READ_API_URL}/v1/companyConfigs/${companyId}/configs/faw`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_FAW_CONFIG_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_FAW_CONFIG_ERROR,
            error: e
        });
    }
};

export const GET_ELIGIBLE_INVOICES_START = "GET_ELIGIBLE_INVOICES_START";
export const GET_ELIGIBLE_INVOICES_SUCCESS = "GET_ELIGIBLE_INVOICES_SUCCESS";
export const GET_ELIGIBLE_INVOICES_ERROR = "GET_ELIGIBLE_INVOICES_ERROR";

export const getEligibleInvoices = (auth, companyId, page = 0, size = 20) => async dispatch => {
    const urlParams = {
        after: page,
        first: size
    };
    dispatch({
        type: GET_ELIGIBLE_INVOICES_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${OTC_INVOICE_TRADING_API_URL}/v1/${companyId}/eligibleInvoices`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_ELIGIBLE_INVOICES_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_ELIGIBLE_INVOICES_ERROR,
            error: e
        });
    }
};
