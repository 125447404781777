import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvoiceInfoAttached } from "./../attached";
import { InvoiceInfoIdentity } from "./../identity";
import { translateInvoicesTitleAndStatus } from "../../../utils/translate";
import { InvoiceAlerts } from "../alerts";
import { Alert } from "antd";

import {
    Container,
    ContainerInfo,
    ContainerAlerts,
    ContainerTitle,
    ContainerSubTitle,
    ContainerField,
    ContainerCopyIcon,
    ActionContainer,
    ActionEnable,
    Status,
    StatusField,
    InfoSection
} from "./styled";

export const InvoiceInfoDetails = ({
    document,
    handlePreview,
    displaySuccessToast,
    admin,
    lstAttachments,
    replayUsageInvoiceNotification,
    showMessage,
    displayErrorToast,
    getUsageInvoicePackage,
    reassignUsageInvoice,
    removeUsageInvoice,
    reassignUsageInvoicePackage
}) => {
    const [invoiceDate, setInvoiceDate] = useState("");
    const [cctStatusId, setCctStatusId] = useState(null);

    const checkIsManaged = history => {
        if (history !== undefined && history.length !== 0) {
            for (var value of history) {
                if (value.name === "IN_GESTIONE") {
                    return true;
                }
            }
        }
        return false;
    };
    const [titleHUB, statusHUB] = document.infoD.status.HUB
        ? translateInvoicesTitleAndStatus(document.infoD.currentStatus.name, checkIsManaged(document.infoD.status.HUB))
        : ["Nessun messaggio", "success"];
    const [titleSDI, statusSDI] = document.infoD.status.SDI
        ? translateInvoicesTitleAndStatus(
              document.infoD.status.SDI[document.infoD.status.SDI.length - 1].name,
              checkIsManaged(document.infoD.status.SDI)
          )
        : ["Nessun messaggio", "success"];
    const [titleCCT, statusCCT] = document.infoD.status.CCT
        ? translateInvoicesTitleAndStatus(
              document.infoD.status.CCT[document.infoD.status.CCT.length - 1].name,
              checkIsManaged(document.infoD.status.CCT)
          )
        : ["Nessun messaggio", "success"];
    useEffect(() => {
        let invoiceDate = "";
        if (document.infoD.status.HUB !== undefined && document.infoD.status.HUB.length !== 0) {
            document.infoD.status.HUB.forEach(value => {
                if (value.description === "Fattura Ricevuta") {
                    invoiceDate = new Date(Number(value.timestamp)).toLocaleString();
                }
            });
        }
        setInvoiceDate(invoiceDate);
    }, [document.infoD.status.HUB]);

    useEffect(() => {
        let cctStatusId = null;
        if (
            document.infoD &&
            document.infoD.status &&
            document.infoD.status.CCT &&
            document.infoD.status.CCT.length > 0
        ) {
            document.infoD.status.CCT.forEach(element => {
                if (element.name === "Conservato in PdA" && element.type === "FINAL_OK") {
                    cctStatusId = element.statusId;
                }
            });
        }
        setCctStatusId(cctStatusId);
    }, [document.infoD]);

    return (
        <Container>
            <ContainerInfo>
                <InfoSection>
                    <ContainerTitle>
                        {document.infoD.active ? (
                            <FormattedMessage id={"active_invoice"} />
                        ) : (
                            <FormattedMessage id={"passive_invoice"} />
                        )}
                    </ContainerTitle>
                    <div>
                        <ContainerField>{<FormattedMessage id={"hubid"} />}:</ContainerField> {document.infoD.hubId}
                        <ContainerCopyIcon>
                            <CopyToClipboard
                                text={document.infoD.hubId}
                                onCopy={() => displaySuccessToast("HubId copiato correttamente", true)}
                            >
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </ContainerCopyIcon>
                    </div>
                    <div>
                        <ContainerField>{<FormattedMessage id={"sdiid"} />}:</ContainerField>{" "}
                        {document.infoD.sdiId ? document.infoD.sdiId : "Dato non presente"}
                        {document.infoD.sdiId ? (
                            <ContainerCopyIcon>
                                <CopyToClipboard
                                    text={document.infoD.sdiId}
                                    onCopy={() => displaySuccessToast("SdiId copiato correttamente", true)}
                                >
                                    <i className="far fa-copy" />
                                </CopyToClipboard>
                            </ContainerCopyIcon>
                        ) : (
                            " "
                        )}
                    </div>

                    <div>
                        <ContainerField>ArchiveId:</ContainerField>{" "}
                        {document.infoD.status &&
                        document.infoD.status.CCT &&
                        document.infoD.status.CCT.length &&
                        document.infoD.status.CCT[document.infoD.status.CCT.length - 1].statusId
                            ? document.infoD.status.CCT[document.infoD.status.CCT.length - 1].statusId
                            : "Dato non presente"}
                        {document.infoD.status &&
                        document.infoD.status.CCT &&
                        document.infoD.status.CCT.length &&
                        document.infoD.status.CCT[document.infoD.status.CCT.length - 1].statusId ? (
                            <ContainerCopyIcon>
                                <CopyToClipboard
                                    text={document.infoD.status.CCT[document.infoD.status.CCT.length - 1].statusId}
                                    onCopy={() => displaySuccessToast("ArchiveId copiato correttamente", true)}
                                >
                                    <i className="far fa-copy" />
                                </CopyToClipboard>
                            </ContainerCopyIcon>
                        ) : (
                            " "
                        )}
                    </div>
                    {cctStatusId && (
                        <div>
                            <ContainerField>{<FormattedMessage id={"cct_status_id"} />}:</ContainerField> {cctStatusId}
                        </div>
                    )}
                    <div>
                        <ContainerField>{<FormattedMessage id={"recipient"} />}:</ContainerField>{" "}
                        {(document.infoD.recipientName ? document.infoD.recipientName : "") +
                            " (" +
                            document.infoD.recipientId +
                            ")"}
                    </div>
                    <div>
                        <ContainerField>{<FormattedMessage id={"sender"} />}:</ContainerField>{" "}
                        {(document.infoD.senderName ? document.infoD.senderName : "") +
                            " (" +
                            document.infoD.senderId +
                            ")"}
                    </div>
                    <div>
                        <ContainerField>{<FormattedMessage id={"transmitter"} />}:</ContainerField>{" "}
                        {document.infoD.transmitterId !== null ? (
                            document.infoD.transmitterId
                        ) : (
                            <FormattedMessage id={"missing_data"} />
                        )}
                    </div>
                </InfoSection>
                <InfoSection>
                    <ContainerSubTitle>{<FormattedMessage id={"invoice_data"} />}</ContainerSubTitle>
                    <div>
                        <ContainerField>{<FormattedMessage id={"flow"} />}:</ContainerField> {document.infoD.flowType}
                    </div>
                    <div>
                        <ContainerField>{<FormattedMessage id={"number"} />}:</ContainerField>{" "}
                        {document.infoD.docId !== null ? (
                            document.infoD.docId
                        ) : (
                            <FormattedMessage id={"missing_data"} />
                        )}
                    </div>
                    <div>
                        <ContainerField>{<FormattedMessage id={"application"} />}:</ContainerField>{" "}
                        {document.infoD.currentStatus.appName !== null ? (
                            document.infoD.currentStatus.appName
                        ) : (
                            <FormattedMessage id={"missing_data"} />
                        )}
                        {!document.infoD.active && (
                            <div>
                                <ContainerField>
                                    <FormattedMessage id={"c-invoice.info.is-read"} />:
                                </ContainerField>{" "}
                                {document.infoD.isRead !== undefined && document.infoD.isRead === true ? (
                                    <span>
                                        <FormattedMessage id={"general.yes"}></FormattedMessage> (
                                        {document.infoD.currentStatus.userId})
                                    </span>
                                ) : (
                                    <FormattedMessage id={"general.no"} />
                                )}
                                <br />{" "}
                            </div>
                        )}
                    </div>
                    <div>
                        <ContainerField>{<FormattedMessage id={"invoice_received"} />}:</ContainerField>{" "}
                        {invoiceDate !== "" ? invoiceDate : <FormattedMessage id={"missing_date"} />}s
                    </div>
                </InfoSection>

                <InvoiceInfoIdentity
                    admin={admin}
                    document={document}
                    fromInformations={true}
                    getUsageInvoicePackage={getUsageInvoicePackage}
                    reassignUsageInvoice={reassignUsageInvoice}
                    removeUsageInvoice={removeUsageInvoice}
                    reassignUsageInvoicePackage={reassignUsageInvoicePackage}
                />
                <InvoiceInfoAttached
                    admin={admin}
                    documentInfo={document}
                    lstAttachments={lstAttachments}
                    replayUsageInvoiceNotification={replayUsageInvoiceNotification}
                    showMessage={showMessage}
                    displayErrorToast={displayErrorToast}
                />
            </ContainerInfo>
            <ContainerAlerts>
                <ActionContainer>
                    <ActionEnable onClick={handlePreview}>
                        <FontAwesomeIcon icon={faEye} style={{ marginRight: "8px" }} />
                        <FormattedMessage id={"preview"} />
                    </ActionEnable>
                </ActionContainer>
                <ContainerSubTitle>
                    <FormattedMessage id={"notifications"} />
                </ContainerSubTitle>
                <InvoiceAlerts document={document} />

                <ContainerSubTitle>
                    <FormattedMessage id={"document_state"} />
                </ContainerSubTitle>

                <Status>
                    <StatusField>
                        <FormattedMessage id={"ts_Digital"} />
                    </StatusField>
                    <Alert style={{ flexBasis: "70%" }} showIcon type={statusHUB} message={titleHUB} />
                </Status>
                <Status>
                    <StatusField>
                        <FormattedMessage id={"cct_state"} />
                    </StatusField>
                    <Alert style={{ flexBasis: "70%" }} showIcon type={statusCCT} message={titleCCT} />
                </Status>
                <Status>
                    <StatusField>
                        <FormattedMessage id={"sdi_state"} />
                    </StatusField>
                    <Alert style={{ flexBasis: "70%" }} showIcon type={statusSDI} message={titleSDI} />
                </Status>
            </ContainerAlerts>
        </Container>
    );
};

InvoiceInfoDetails.propTypes = {
    document: PropTypes.object.isRequired,
    handlePreview: PropTypes.func.isRequired,
    displaySuccessToast: PropTypes.func.isRequired
};
