export const countryValues = [
    { id: "IT", name: "Italia" },
    { id: "FR", name: "Francia" },
    { id: "GB", name: "Regno Unito" },
    { id: "DE", name: "Germania" }
];

export const statusCompany = {
    NO_STATUS: null,
    UNVERIFIABLE: "UNVERIFIABLE",
    PENDING_VERIFY: "PENDING_VERIFY",
    VERIFIED: "VERIFIED",
    VERIFIED_PENDING_VALIDATE: "VERIFIED_PENDING_VALIDATE",
    UNVERIFIABLE_PENDING_VALIDATE: "UNVERIFIABLE_PENDING_VALIDATE",
    VALIDATED: "VALIDATED",
    REJECTED: "REJECTED",
    REJECTED_PENDING_VALIDATE: "REJECTED_PENDING_VALIDATE"
};

export const monthForTaxIdCodeControl = ["A", "B", "C", "D", "E", "H", "L", "M", "P", "R", "S", "T"];

export const signatureStatus = {
    PDF_GENERATED: "PDF_GENERATED",
    SUBMISSION_SUCCESS: "SUBMISSION_SUCCESS",
    FINAL_SUCCESS: "FINAL_SUCCESS",
    ASSIGNED_SUCCESS: "ASSIGNED_SUCCESS",
    ASSIGNED_WITHDRAW_SUCCESS: "ASSIGNED_WITHDRAW_SUCCESS",
    WITHDRAW_SUCCESS: "WITHDRAW_SUCCESS",
    FINAL_ERROR: "FINAL_ERROR",
    SUBMISSION_ERROR: "SUBMISSION_ERROR"
};

export const signerDocumentTypes = [
    { id: "1", name: "Carta d'identità" },
    { id: "2", name: "Patente di guida" },
    { id: "3", name: "Passaporto" },
    { id: "4", name: "Porto d'armi" },
    { id: "5", name: "Tessera di riconoscimento postale" },
    { id: "6", name: "Tessera rilasciata da PA" }
];

export const monthForFiscalCodeControl = ["A", "B", "C", "D", "E", "H", "L", "M", "P", "R", "S", "T"];

export const taxRegimeValues = [
    { id: "RF01", name: " RF01 - Regime Ordinario" },
    {
        id: "RF02",
        name: " RF02 - Regime contribuenti minimi (art.1, c.96-117, L. 244/07)"
    },
    {
        id: "RF03",
        name: " RF03 - Regime delle nuove iniziative produttive (art.13, L. 388/00)"
    },
    {
        id: "RF04",
        name: " RF04 - Agricoltura e attività connesse e pesca (artt.34 e 34-bis, DPR 633/72)"
    },
    {
        id: "RF05",
        name: " RF05 - Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)"
    },
    {
        id: "RF06",
        name: " RF06 - Commercio dei fiammiferi (art.74, c.1, DPR  633/72)"
    },
    { id: "RF07", name: " RF07 - Editoria (art.74, c.1, DPR  633/72)" },
    {
        id: "RF08",
        name: " RF08 - Gestione di servizi di telefonia pubblica (art.74, c.1, DPR 633/72)"
    },
    {
        id: "RF09",
        name: " RF09 - Rivendita di documenti di trasporto pubblico e di sosta (art.74, c.1, DPR  633/72)"
    },
    {
        id: "RF10",
        name:
            " RF10 - Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR 633/72)"
    },
    {
        id: "RF11",
        name: " RF11 - Agenzie di viaggi e turismo (art.74-ter, DPR 633/72)"
    },
    { id: "RF12", name: " RF12 - Agriturismo (art.5, c.2, L. 413/91)" },
    {
        id: "RF13",
        name: " RF13 - Vendite a domicilio (art.25-bis, c.6, DPR  600/73)"
    },
    {
        id: "RF14",
        name: " RF14 - Rivendita di beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)"
    },
    {
        id: "RF15",
        name:
            " RF15 - Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)"
    },
    { id: "RF16", name: " RF16 - IVA per cassa P.A. (art.6, c.5, DPR 633/72)" },
    { id: "RF17", name: " RF17 - IVA per cassa (art. 32-bis, DL 83/2012)" },
    { id: "RF18", name: " RF18 - Altro" },
    {
        id: "RF19",
        name: " RF19 - Regime forfettario (art.1, c.54-89, L. 190/2014)"
    }
];

export const provinceValues = [
    { id: "AG", name: "Agrigento" },
    { id: "AL", name: "Alessandria" },
    { id: "AN", name: "Ancona" },
    { id: "AO", name: "Aosta" },
    { id: "AR", name: "Arezzo" },
    { id: "AP", name: "Ascoli Piceno" },
    { id: "AT", name: "Asti" },
    { id: "AV", name: "Avellino" },
    { id: "BA", name: "Bari" },
    { id: "BT", name: "Barletta-Andria-Trani" },
    { id: "BL", name: "Belluno" },
    { id: "BN", name: "Benevento" },
    { id: "BG", name: "Bergamo" },
    { id: "BI", name: "Biella" },
    { id: "BO", name: "Bologna" },
    { id: "BZ", name: "Bolzano" },
    { id: "BS", name: "Brescia" },
    { id: "BR", name: "Brindisi" },
    { id: "CA", name: "Cagliari" },
    { id: "CL", name: "Caltanissetta" },
    { id: "CB", name: "Campobasso" },
    { id: "CI", name: "Carbonia-Iglesias" },
    { id: "CE", name: "Caserta" },
    { id: "CT", name: "Catania" },
    { id: "CZ", name: "Catanzaro" },
    { id: "CH", name: "Chieti" },
    { id: "CO", name: "Como" },
    { id: "CS", name: "Cosenza" },
    { id: "CR", name: "Cremona" },
    { id: "KR", name: "Crotone" },
    { id: "CN", name: "Cuneo" },
    { id: "EN", name: "Enna" },
    { id: "FM", name: "Fermo" },
    { id: "FE", name: "Ferrara" },
    { id: "FI", name: "Firenze" },
    { id: "FG", name: "Foggia" },
    { id: "FC", name: "Forlì-Cesena" },
    { id: "FR", name: "Frosinone" },
    { id: "GE", name: "Genova" },
    { id: "GO", name: "Gorizia" },
    { id: "GR", name: "Grosseto" },
    { id: "IM", name: "Imperia" },
    { id: "IS", name: "Isernia" },
    { id: "SP", name: "La Spezia" },
    { id: "AQ", name: "L'Aquila" },
    { id: "LT", name: "Latina" },
    { id: "LE", name: "Lecce" },
    { id: "LC", name: "Lecco" },
    { id: "LI", name: "Livorno" },
    { id: "LO", name: "Lodi" },
    { id: "LU", name: "Lucca" },
    { id: "MC", name: "Macerata" },
    { id: "MN", name: "Mantova" },
    { id: "MS", name: "Massa-Carrara" },
    { id: "MT", name: "Matera" },
    { id: "ME", name: "Messina" },
    { id: "MI", name: "Milano" },
    { id: "MO", name: "Modena" },
    { id: "MB", name: "Monza e della Brianza" },
    { id: "NA", name: "Napoli" },
    { id: "NO", name: "Novara" },
    { id: "NU", name: "Nuoro" },
    { id: "OT", name: "Olbia-Tempio" },
    { id: "OR", name: "Oristano" },
    { id: "PD", name: "Padova" },
    { id: "PA", name: "Palermo" },
    { id: "PR", name: "Parma" },
    { id: "PV", name: "Pavia" },
    { id: "PG", name: "Perugia" },
    { id: "PU", name: "Pesaro e Urbino" },
    { id: "PE", name: "Pescara" },
    { id: "PC", name: "Piacenza" },
    { id: "PI", name: "Pisa" },
    { id: "PT", name: "Pistoia" },
    { id: "PN", name: "Pordenone" },
    { id: "PZ", name: "Potenza" },
    { id: "PO", name: "Prato" },
    { id: "RG", name: "Ragusa" },
    { id: "RA", name: "Ravenna" },
    { id: "RC", name: "Reggio Calabria" },
    { id: "RE", name: "Reggio Emilia" },
    { id: "RI", name: "Rieti" },
    { id: "RN", name: "Rimini" },
    { id: "RM", name: "Roma" },
    { id: "RO", name: "Rovigo" },
    { id: "SA", name: "Salerno" },
    { id: "VS", name: "Medio Campidano" },
    { id: "SS", name: "Sassari" },
    { id: "SV", name: "Savona" },
    { id: "SI", name: "Siena" },
    { id: "SR", name: "Siracusa" },
    { id: "SO", name: "Sondrio" },
    { id: "TA", name: "Taranto" },
    { id: "TE", name: "Teramo" },
    { id: "TR", name: "Terni" },
    { id: "TO", name: "Torino" },
    { id: "OG", name: "Ogliastra" },
    { id: "TP", name: "Trapani" },
    { id: "TN", name: "Trento" },
    { id: "TV", name: "Treviso" },
    { id: "TS", name: "Trieste" },
    { id: "UD", name: "Udine" },
    { id: "VA", name: "Varese" },
    { id: "VE", name: "Venezia" },
    { id: "VB", name: "Verbano-Cusio-Ossola" },
    { id: "VC", name: "Vercelli" },
    { id: "VR", name: "Verona" },
    { id: "VV", name: "Vibo Valentia" },
    { id: "VI", name: "Vicenza" },
    { id: "VT", name: "Viterbo" }
];

export const classifierLayerTypes = [
    { value: "OFFICE", label: "Ufficio" },
    { value: "INTERMEDIARY", label: "Intermediario" }
];

export const classifierItemTypes = [{ id: 2, name: "Studio" }, { id: 1, name: "Azienda" }];

export const taxRegionValue = [
    { id: "ZXXX", code: "IT" }
    // { id: "Z133", code: "CH" }
];

export const techniciansRoles = [
    {
        id: "REGISTERED:createItem",
        resourceId: "REGISTERED",
        actionKey: "createItem",
        description: "Ruolo per la creazione di item"
    },
    {
        id: "REGISTERED:createUser",
        resourceId: "REGISTERED",
        actionKey: "createUser",
        description: "Ruolo per la creazione di utenti"
    },
    {
        id: "REGISTERED:createCompany",
        resourceId: "REGISTERED",
        actionKey: "createCompany",
        description: "Ruolo per la creazione di aziende"
    },
    {
        id: "REGISTERED:createBusinessGroup",
        resourceId: "REGISTERED",
        actionKey: "createBusinessGroup",
        description: "Ruolo per la creazione di createBusinessGroup"
    }
];

export const backOfficeRoles = [
    {
        appId: null,
        id: "BACKOFFICE:ADMIN",
        resourceId: "BACKOFFICE",
        actionKey: "ADMIN",
        description: "Ruolo di BackOffice base"
    },
    {
        appId: null,
        id: "BACKOFFICE:OWNER",
        resourceId: "BACKOFFICE",
        actionKey: "OWNER",
        description: "Ruolo admin di BackOffice"
    },
    {
        appId: "DBX",
        id: "BACKOFFICE:ADMIN",
        resourceId: "BACKOFFICE",
        actionKey: "ADMIN",
        description: "Utenza BackOffice Digital Box"
    },
    {
        appId: "DBX",
        id: "BACKOFFICE:correct",
        resourceId: "BACKOFFICE",
        actionKey: "correct",
        description: "Utenza BackOffice Digital Box L1"
    },
    {
        appId: "DBX",
        id: "BACKOFFICE:validate",
        resourceId: "BACKOFFICE",
        actionKey: "validate",
        description: "Utenza BackOffice Digital Box L2"
    }
];

export const currentStatusActiveNameFilter = [
    {
        text: "A disposizione",
        value: "A_DISPOSIZIONE"
    },
    {
        text: "Accettato",
        value: "ACCETTATO"
    },
    {
        text: "In Elaborazione",
        value: "CARICATO"
    },
    {
        text: "Conservato",
        value: "CONSERVATO"
    },
    {
        text: "Controllato",
        value: "CONTROLLATO"
    },
    {
        text: "In gestione",
        value: "IN_GESTIONE"
    },
    {
        text: "Errore",
        value: "ERRORE"
    },
    {
        text: "Emessa",
        value: "INVIATO"
    },
    {
        text: "Nessuna risposta",
        value: "NESSUNA_RISPOSTA"
    },
    {
        text: "Non recapitato",
        value: "NON_RECAPITATO"
    },
    {
        text: "Registrato",
        value: "REGISTRATO"
    },
    {
        text: "Ricevuto",
        value: "RICEVUTO"
    },
    {
        text: "Rifiutato",
        value: "RIFIUTATO"
    },
    {
        text: "Scartato",
        value: "SCARTATO"
    }
];

export const obligationTaxStatusFilter = [
    {
        text: "Con ricevuta(ES01), Inviato OK",
        value: "ES01"
    },
    {
        text: "Con ricevuta(ES02), Inviato con avvisi",
        value: "ES02"
    },
    {
        text: "Con ricevuta(ES03), Inviato con errore",
        value: "ES03"
    },
    {
        text: "In errore, Non inviato",
        value: "KO"
    },
    {
        text: "Con ricevuta non disponibile, Per sito in manutenzione",
        value: "OK"
    },
    {
        text: "In elaborazione, non ancora inviato",
        value: "W"
    }
];

export const obligationTaxTypeFilter = [
    {
        text: "LI",
        value: "LI"
    },
    {
        text: "DF/DTE",
        value: "DF/DTE"
    }
];

export const obligationYearFilter = [
    {
        text: "2015",
        value: "2015"
    },
    {
        text: "2016",
        value: "2016"
    },
    {
        text: "2017",
        value: "2017"
    },
    {
        text: "2018",
        value: "2018"
    },
    {
        text: "2019",
        value: "2019"
    },
    {
        text: "2020",
        value: "2020"
    }
];

export const obligationPeriodsFilter = [
    {
        text: "Gen/Mar(1° Trimestre)",
        value: "1"
    },
    {
        text: "Apr/Giu(2° Trimestre)",
        value: "2"
    },
    {
        text: "Lug/Set(3° Trimestre)",
        value: "3"
    },
    {
        text: "Ott/Dic(4° Trimestre)",
        value: "4"
    },
    {
        text: "Gen/Giu(1° Semestre)",
        value: "11"
    },
    {
        text: "Lug/Dic(2° Semestre)",
        value: "12"
    }
];

export const currentStatusPassiveNameFilter = [
    {
        text: "A disposizione",
        value: "A_DISPOSIZIONE"
    },
    {
        text: "Accettato",
        value: "ACCETTATO"
    },
    {
        text: "In Elaborazione",
        value: "CARICATO"
    },
    {
        text: "Conservato",
        value: "CONSERVATO"
    },
    {
        text: "Controllato",
        value: "CONTROLLATO"
    },
    {
        text: "In gestione",
        value: "IN_GESTIONE"
    },
    {
        text: "Errore",
        value: "ERRORE"
    },
    {
        text: "Emessa",
        value: "INVIATO"
    },
    {
        text: "Nessuna risposta",
        value: "NESSUNA_RISPOSTA"
    },
    {
        text: "Non recapitato",
        value: "NON_RECAPITATO"
    },
    {
        text: "Registrato",
        value: "REGISTRATO"
    },
    {
        text: "Ricevuto",
        value: "RICEVUTO"
    },
    {
        text: "Rifiutato",
        value: "RIFIUTATO"
    },
    {
        text: "Scartato",
        value: "SCARTATO"
    }
];

export const flowTypeFilter = [
    {
        text: "SDIPA",
        value: "SDIPA"
    },
    {
        text: "SDIPR",
        value: "SDIPR"
    },
    {
        text: "STORE",
        value: "STORE"
    },
    {
        text: "AUTO FATTURA",
        value: "SELFINV"
    },
    {
        text: "AUTO INVIO",
        value: "SELFSEND"
    },
    {
        text: "OCR",
        value: "OCR"
    },
    {
        text: "IMPEXP",
        value: "IMPEXP"
    },
    {
        text: "B2B *",
        value: "B2B"
    },
    {
        text: "EMAIL *",
        value: "EMAIL"
    },
    {
        text: "AUTOINVIO *",
        value: "AUTOINVIO"
    },
    {
        text: "SDI *",
        value: "SDI"
    }
];

export const b2bCode = [
    { id: "0ZCQR4A", name: "0ZCQR4A" },
    { id: "32C3O8A", name: "32C3O8A" },
    { id: "3B100M4", name: "3B100M4" },
    { id: "47DXY98", name: "47DXY98" },
    { id: "5K65D7Y", name: "5K65D7Y" },
    { id: "7PHNQ6A", name: "7PHNQ6A" },
    { id: "85GHHN7", name: "85GHHN7" },
    { id: "8C9X0IZ", name: "8C9X0IZ" },
    { id: "94QLT54", name: "94QLT54" },
    { id: "9OLXUZV", name: "9OLXUZV" },
    { id: "BYPX6BP", name: "BYPX6BP" },
    { id: "CPMRDH0", name: "CPMRDH0" },
    { id: "GUNWXG7", name: "GUNWXG7" },
    { id: "GXREHPP", name: "GXREHPP" },
    { id: "HNLUZ94", name: "HNLUZ94" },
    { id: "IAGHDRP", name: "IAGHDRP" },
    { id: "JOCOG96", name: "JOCOG96" },
    { id: "KNKW6RR", name: "KNKW6RR" },
    { id: "KT40LX8", name: "KT40LX8" },
    { id: "LOR6W44", name: "LOR6W44" },
    { id: "M2DC2YS", name: "M2DC2YS" },
    { id: "MABLSEE", name: "MABLSEE" },
    { id: "OHHROL9", name: "OHHROL9" },
    { id: "P7E1N9A", name: "P7E1N9A" },
    { id: "QCE7L4G", name: "QCE7L4G" },
    { id: "URJOD79", name: "URJOD79" },
    { id: "V4MGS7T", name: "V4MGS7T" },
    { id: "XT9NYC1", name: "XT9NYC1" },
    { id: "YPNDW0N", name: "YPNDW0N" }
];

export const iVerde = [
    "01972320020",
    "03489380265",
    "00309480705",
    "02168620546",
    "02742490168",
    "03477530400",
    "12969080154",
    "01142360294",
    "02968900239",
    "02051370423",
    "06080851006",
    "00996080529",
    "03414780753",
    "02137120305",
    "02102610348",
    "01771450200",
    "01283730339",
    "02613050364",
    "01364860997",
    "01945310249",
    "02897130270",
    "03534090174",
    "01065780197",
    "01635220518",
    "04138500378",
    "01856140064",
    "01125640621",
    "01676700220",
    "02313690782",
    "00652260779",
    "02216580130",
    "07900230017",
    "01641740681",
    "01087200919",
    "01238160053",
    "04887640821",
    "02572890925",
    "00675910384",
    "00524190477",
    "03478930286",
    "06093930482",
    "01881000903",
    "01814550180",
    "01005200959",
    "01205130535",
    "01715860506",
    "01895640355",
    "02544000124",
    "01004120075",
    "02730130040"
];

export const cctStatuses = [
    { value: "Non_Conservato", text: "Non conservato", color: "#f7ac1e" },
    { value: "Conservato", text: "Conservato", color: "#48b74d" },
    { value: "In_Conservazione", text: "In Conservazione", color: "#0083c3" },
    { value: "Inviato_In_Conservazione", text: "Inviato in Conservazione", color: "#0083c3" },
    { value: "Errore_Conservazione", text: "Errore", color: "#e9397a" }
];

export const cctCodiceCoupon = [
    { value: "1000", text: "Coupon 1000", color: "#0083c3" },
    { value: "1004", text: "Coupon 1004", color: "#48b74d" },
    { value: "1100", text: "Coupon 1100", color: "#e9397a" }
];

export const couponList = ["1100", "1000", "1004"];

export const certificationRejectMessages = [
    { group: "NOT_COMPLIANT", type: "format" },
    { group: "NOT_COMPLIANT", type: "atto" },
    { group: "NOT_COMPLIANT", type: "tsdigital" },

    { group: "ERROR_DATA", type: "cf" },
    { group: "ERROR_DATA", type: "cf-piva" },
    { group: "ERROR_DATA", type: "signatory" },
    { group: "ERROR_DATA", type: "piva" },

    { group: "NOT_COMPLETE", type: "place-date" },
    { group: "NOT_COMPLETE", type: "cf" },
    { group: "NOT_COMPLETE", type: "social-name" },
    { group: "NOT_COMPLETE", type: "signatory-name-lastname" },
    { group: "NOT_COMPLETE", type: "signatory-name-lastname2" },
    { group: "NOT_COMPLETE", type: "piva" },
    { group: "NOT_COMPLETE", type: "head-office" },
    { group: "NOT_COMPLETE", type: "privacy" },
    { group: "NOT_COMPLETE", type: "signatory" },
    { group: "NOT_COMPLETE", type: "place" },
    { group: "NOT_COMPLETE", type: "date" },
    { group: "NOT_COMPLETE", type: "cf-green-company" },
    { group: "NOT_COMPLETE", type: "social-name-green-company" },
    { group: "NOT_COMPLETE", type: "signatory-name-lastname3" },
    { group: "NOT_COMPLETE", type: "piva-green-company" },
    { group: "NOT_COMPLETE", type: "head-office-green-company" },
    { group: "NOT_COMPLETE", type: "section-company" },

    { group: "ATTACHED_2", type: "absent" },
    { group: "ATTACHED_2", type: "upload" },
    { group: "ATTACHED_2", type: "data-error" },
    { group: "ATTACHED_2", type: "date" },
    { group: "ATTACHED_2", type: "place-date" },
    { group: "ATTACHED_2", type: "place" },
    { group: "ATTACHED_2", type: "signature" }
];

export const cctProvisioningRejectMessages = [
    "SIGNATURE",
    "UPLOAD_PAGES",
    "NOT_COMPLIANT",
    "NOT_COMPLETE",
    "DATE_EMPTY",
    "PLACE_EMPTY",
    "NOT_CORRECT"
];

export const emailIVerde = [
    { name: "IV ABRUZZO", id: "01641740681", email: "FE_IV_ABRUZZO@coldiretti.it" },
    { name: "IV ALESSANDRIA", id: "01856140064", email: "FE_IV_ALESSANDRIA@coldiretti.it" },
    { name: "IV AREZZO", id: "01635220518", email: "FE_IV_AREZZO@coldiretti.it" },
    { name: "IV ASTI", id: "01238160053", email: "FE_IV_ASTI@coldiretti.it" },
    { name: "IV BASILICATA", id: "00652260779", email: "FE_IV_BASILICATA@coldiretti.it" },
    { name: "IV BERGAMO", id: "02742490168", email: "FE_IV_BERGAMO@coldiretti.it" },
    { name: "IV BOLOGNA", id: "04138500378", email: "FE_IV_BOLOGNA@coldiretti.it" },
    { name: "IV BRESCIA", id: "03534090174", email: "FE_IV_BRESCIA@coldiretti.it" },
    { name: "IV CAGLIARI", id: "02572890925", email: "FE_IV_CAGLIARI@coldiretti.it" },
    { name: "IV CALABRIA", id: "02313690782", email: "FE_IV_CALABRIA@coldiretti.it" },
    { name: "IV CAMPANIA", id: "01125640621", email: "FE_IV_CAMPANIA@coldiretti.it" },
    { name: "IV COLDIRETTI AOSTA", id: "01004120075", email: "FE_IV_AOSTA@coldiretti.it" },
    { name: "IV COMO-LECCO-SONDRIO", id: "2216580130 ", email: "FE_IV_COMO-LECCO-SONDRIO@coldiretti.it" },
    { name: "IV CREMONA", id: "01065780197", email: "FE_IV_CREMONA@coldiretti.it" },
    { name: "IV CUNEO", id: "02730130040", email: "FE_IV_CUNEO@coldiretti.it" },
    { name: "IV FERRARA", id: "00675910384", email: "FE_IV_FERRARA@coldiretti.it" },
    { name: "IV FRIULI VENEZIA GIULIA", id: "02137120305", email: "FE_IV_FRIULIVG@coldiretti.it" },
    { name: "IV GROSSETO", id: "01205130535", email: "FE_IV_GROSSETO@coldiretti.it" },
    { name: "IV LAZIO", id: "06080851006", email: "FE_IV_LAZIO@coldiretti.it" },
    { name: "IV LIGURIA", id: "01364860997", email: "FE_IV_LIGURIA@coldiretti.it" },
    { name: "IV MANTOVA", id: "01771450200", email: "FE_IV_MANTOVA@coldiretti.it" },
    { name: "IV MARCHE", id: "02051370423", email: "FE_IV_MARCHE@coldiretti.it" },
    {
        name: "IV MILANO LODI E MONZA BRIANZA",
        id: "12969080154",
        email: "FE_IV_MILANO-LODI-MONZABRIANZA@coldiretti.it"
    },
    { name: "IV MODENA", id: "02613050364", email: "FE_IV_MODENA@coldiretti.it" },
    { name: "IV MOLISE", id: "00309480705", email: "FE_IV_MOLISE@coldiretti.it" },
    { name: "IV NORD SARDEGNA - SASSARI", id: "01881000903", email: "FE_IV_NORDSARDEGNA@coldiretti.it" },
    { name: "IV NUORO", id: "01087200919", email: "FE_IV_NUORO@coldiretti.it" },
    { name: "IV ORISTANO", id: "01005200959", email: "FE_IV_ORISTANO@coldiretti.it" },
    { name: "IV PADOVA", id: "03478930286", email: "FE_IV_PADOVA@coldiretti.it" },
    { name: "IV PARMA", id: "02102610348", email: "FE_IV_PARMA@coldiretti.it" },
    { name: "IV PAVIA", id: "01814550180", email: "FE_IV_PAVIA@coldiretti.it" },
    { name: "IV PIACENZA", id: "01283730339", email: "FE_IV_PIACENZA@coldiretti.it" },
    { name: "IV PIEMONTE ORIENTALE", id: "01972320020", email: "FE_IV_PIEMONTEORIENTALE@coldiretti.it" },
    { name: "IV PISA - LIVORNO", id: "01715860506", email: "FE_IV_PISA-LIVORNO@coldiretti.it" },
    { name: "IV PISTOIA", id: "00524190477", email: "FE_IV_PISTOIA@coldiretti.it" },
    { name: "IV PREALPI VARESE", id: "02544000124", email: "FE_IV_PREALPIVARESE@coldiretti.it" },
    { name: "IV PUGLIA", id: "03414780753", email: "FE_IV_PUGLIA@coldiretti.it" },
    { name: "IV REGGIO EMILIA", id: "01895640355", email: "FE_IV_REGGIOEMILIA@coldiretti.it" },
    { name: "IV ROMAGNA", id: "03477530400", email: "FE_IV_ROMAGNA@coldiretti.it" },
    { name: "IV ROVIGO", id: "01142360294", email: "FE_IV_ROVIGO@coldiretti.it" },
    { name: "IV SICILIA", id: "04887640821", email: "FE_IV_SICILIA@coldiretti.it" },
    { name: "IV SIENA", id: "00996080529", email: "FE_IV_SIENA@coldiretti.it" },
    { name: "IV TORINO", id: "07900230017", email: "FE_IV_TORINO@coldiretti.it" },
    { name: "IV TOSCANA", id: "06093930482", email: "FE_IV_TOSCANA@coldiretti.it" },
    { name: "IV TRENTINO ALTO ADIGE", id: "01676700220", email: "FE_IV_TRENTINO@coldiretti.it" },
    { name: "IV TREVISO - BELLUNO", id: "03489380265", email: "FE_IV_TREVISO-BELLUNO@coldiretti.it" },
    { name: "IV UMBRIA", id: "02168620546", email: "FE_IV_UMBRIA@coldiretti.it" },
    { name: "IV VENEZIA", id: "2897130270 ", email: "FE_IV_VENEZIA@coldiretti.it" },
    { name: "IV VERONA", id: "02968900239", email: "FE_IV_VERONA@coldiretti.it" },
    { name: "IV VICENZA", id: "01945310249", email: "FE_IV_VICENZA@coldiretti.it" }
];

export const ordersOrdinationsFilter = [
    {
        text: "SEMPLICE",
        value: "ORDER_ONLY"
    },
    {
        text: "COMPLETA",
        value: "ORDERING"
    },
    {
        text: "PRE-CONCORDATA",
        value: "ORDER_AGREEMENT"
    }
];

export const ordersCurrentStatusFilter = [
    {
        text: "CARICATO",
        value: "CARICATO"
    },
    {
        text: "CONTROLLATO",
        value: "CONTROLLATO"
    },
    {
        text: "IN ERRORE",
        value: "ERRORE"
    },
    {
        text: "A DISPOSIZIONE",
        value: "A_DISPOSIZIONE"
    },
    {
        text: "SCARTATO",
        value: "SCARTATO"
    },
    {
        text: "IN GESTIONE",
        value: "IN_GESTIONE"
    },
    {
        text: "RECAPITATO",
        value: "RECAPITATO"
    },
    {
        text: "NON RECAPITATO",
        value: "NON_RECAPITATO"
    },
    {
        text: "ACCETTATO",
        value: "ACCETTATO"
    },
    {
        text: "RIFIUTATO",
        value: "RIFIUTATO"
    },
    {
        text: "REVOCATO",
        value: "REVOCATO"
    },
    {
        text: "MODIFICATO",
        value: "MODIFICATO"
    },
    {
        text: "SOSTITUITO",
        value: "SOSTITUITO"
    },
    {
        text: "SCONOSCIUTO",
        value: "UNDEFINED"
    }
];

export const flowTypeConservation = [
    {
        text: "AUTOINVIO *",
        value: "AUTOINVIO"
    },
    {
        text: "SDI *",
        value: "SDI"
    },
    {
        text: "SDIPA",
        value: "SDIPA"
    },
    {
        text: "SDIPR",
        value: "SDIPR"
    },
    {
        text: "AUTO FATTURA",
        value: "SELFINV"
    },
    {
        text: "AUTO INVIO",
        value: "SELFSEND"
    },
    {
        text: "STORE",
        value: "STORE"
    }
];

export const cctStatusCodeFilter = [
    {
        text: "Inizializzato",
        value: "initialized",
        field: "status"
    },
    {
        text: "In attesa documento",
        value: "downloaded",
        field: "status"
    },
    {
        text: "In validazione",
        value: "uploaded",
        field: "status"
    },
    {
        text: "Rifiutato",
        value: "rejected",
        field: "status"
    },
    {
        text: "In Attesa",
        value: "pending",
        field: "status"
    },
    {
        text: "Bloccato",
        value: "blocked",
        field: "status"
    },
    {
        text: "Validato",
        value: "validated",
        field: "status"
    }
];

export const cctWariningCodeFilter = [
    {
        text: "Responsabile da rettificare",
        value: "rdcToBeFixed",
        field: "rdc"
    },
    {
        text: "In errore",
        value: "suspended",
        field: "suspended"
    }
];

export const bankLinkCbiReason = [
    { value: "REASON_01", text: "01", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_02", text: "02", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_03", text: "03", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_04", text: "04", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_05", text: "05", description: "Prelevamento a mezzo nostro sportello automatico" },
    { value: "REASON_06", text: "06", description: "Accredito per incassi con addebito in conto preautorizzato" },
    {
        value: "REASON_07",
        text: "07",
        description: "Accredito per incassi con addebito in conto non preautorizzato o per cassa"
    },
    { value: "REASON_08", text: "08", description: "Disposizioni di incasso preautorizzate impagate" },
    { value: "REASON_09", text: "09", description: "Incasso tramite P.O.S." },
    { value: "REASON_10", text: "10", description: "Emissione assegni circolari o vaglia" },
    { value: "REASON_11", text: "11", description: "Pagamento utenze (servizi pubblici, luce, gas, telefono, ecc.)" },
    { value: "REASON_13", text: "13", description: "Vostro assegno bancario n." },
    { value: "REASON_14", text: "14", description: "Cedole, dividendi e premi estratti" },
    { value: "REASON_15", text: "15", description: "Rimborso finanziamenti (mutui, prestiti personali, ecc.)" },
    { value: "REASON_16", text: "16", description: "Commissioni" },
    { value: "REASON_17", text: "17", description: "Locazioni (fitto, leasing ecc.) e premi assicurativi" },
    { value: "REASON_18", text: "18", description: "Interessi e competenze (conti correnti, conti di deposito ecc.)" },
    { value: "REASON_19", text: "19", description: "Imposte e tasse" },
    { value: "REASON_20", text: "20", description: "Canone cassette di sicurezza e custodia valori" },
    { value: "REASON_21", text: "21", description: "Contributi assistenziali e previdenziali" },
    { value: "REASON_22", text: "22", description: "Diritti di custodia e amministrazione titoli" },
    { value: "REASON_24", text: "24", description: "Pagamento o incasso di documenti sull'Italia" },
    { value: "REASON_26", text: "26", description: "Vostra disposizione a favore di" },
    { value: "REASON_27", text: "27", description: "Accredito per emolumenti (stipendi, pensioni, ecc.)" },
    { value: "REASON_28", text: "28", description: "Acquisto e vendita divise e/o banconote estere" },
    { value: "REASON_29", text: "29", description: "Accrediti RI.BA." },
    { value: "REASON_30", text: "30", description: "Accrediti effetti s.b.f." },
    { value: "REASON_31", text: "31", description: "Effetti ritirati (pagati)" },
    { value: "REASON_32", text: "32", description: "Effetti richiamati" },
    { value: "REASON_34", text: "34", description: "Disposizioni di giro conto (stessa banca) " },
    { value: "REASON_35", text: "35", description: "Storni RI.BA." },
    { value: "REASON_37", text: "37", description: "Insoluti RI.BA." },
    { value: "REASON_39", text: "39", description: "Vostra disposizione per emolumenti (stipendi, pensioni ecc.)" },
    { value: "REASON_41", text: "41", description: "Pagamento o disposizione a mezzo sportello automatico" },
    { value: "REASON_42", text: "42", description: "Effetti insoluti o protestati" },
    { value: "REASON_43", text: "43", description: "Pagamento tramite POS" },
    { value: "REASON_44", text: "44", description: "Addebito per utilizzo di credito documentario su estero" },
    { value: "REASON_45", text: "45", description: "Pagamento per utilizzo carte di credito" },
    { value: "REASON_46", text: "46", description: "Mandati di pagamento" },
    { value: "REASON_47", text: "47", description: "Accredito per utilizzo credito documentario sull'Italia" },
    { value: "REASON_48", text: "48", description: "Bonifico a Vostro favore (per ordine e conto)" },
    { value: "REASON_49", text: "49", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_50", text: "50", description: "Pagamenti diversi" },
    { value: "REASON_51", text: "51", description: "Assegni turistici" },
    { value: "REASON_52", text: "52", description: "Prelevamento con moduli di sportello" },
    { value: "REASON_53", text: "53", description: "Addebito per utilizzo di credito documentario sull'Italia" },
    { value: "REASON_55", text: "55", description: "Assegni bancari insoluti o protestati" },
    {
        value: "REASON_56",
        text: "56",
        description: "Ricavo effetti o assegni in lire e/o valuta estera al dopo incasso (sull'Italia)"
    },
    { value: "REASON_57", text: "57", description: "Restituzione di assegni o vaglia irregolari" },
    { value: "REASON_58", text: "58", description: "Reversali d'incasso" },
    { value: "REASON_60", text: "60", description: "Rettifica valuta" },
    { value: "REASON_62", text: "62", description: "Saldo" },
    { value: "REASON_63", text: "63", description: "Accrediti sconto effetti diretti" },
    { value: "REASON_64", text: "64", description: "Accrediti effetti presentati allo sconto (sull'Italia)" },
    { value: "REASON_65", text: "65", description: "Competenze di sconto" },
    { value: "REASON_66", text: "66", description: "Spese" },
    { value: "REASON_68", text: "68", description: "Storno scritture" },
    { value: "REASON_70", text: "70", description: "Acquisto/vendita a pronti titoli e diritti di opzione" },
    { value: "REASON_72", text: "72", description: "Accredito per utilizzo di credito documentario da estero" },
    { value: "REASON_74", text: "74", description: "Valori bollati" },
    { value: "REASON_78", text: "78", description: "Versamento di contante o valori assimilati" },
    { value: "REASON_79", text: "79", description: "Disposizione di giro conto (da/a altra banca))" },
    { value: "REASON_80", text: "80", description: "Acquisto/Vendita a termine titoli" },
    { value: "REASON_81", text: "81", description: "Accensione riporto titoli" },
    { value: "REASON_82", text: "82", description: "Estinzione riporto titoli" },
    { value: "REASON_83", text: "83", description: "Sottoscrizione titoli e/o fondi comuni" },
    { value: "REASON_84", text: "84", description: "Titoli scaduti o estratti" },
    { value: "REASON_85", text: "85", description: "Aumento di capitale o operazioni societarie" },
    { value: "REASON_86", text: "86", description: "Finanziamenti import" },
    { value: "REASON_87", text: "87", description: "Anticipazioni export" },
    { value: "REASON_88", text: "88", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_90", text: "90", description: "Pagamento o incasso di rimesse documentate da o per l'estero" },
    { value: "REASON_91", text: "91", description: "Prelevamento a mezzo sportello automatico di altra banca" },
    { value: "REASON_92", text: "92", description: "Costo libretto assegni" },
    { value: "REASON_93", text: "93", description: "Valore ammesso per accordo tra le parti" },
    { value: "REASON_94", text: "94", description: "Commissioni e spese su operazioni in titoli" },
    { value: "REASON_95", text: "95", description: "Commissioni e spese su operazioni con l'estero" },
    { value: "REASON_96", text: "96", description: "Rettifica importo" },
    { value: "REASON_99", text: "99", description: "Accredito per rimborsi imposte conto fiscale" },
    { value: "REASON_Y1", text: "Y1", description: "Anticipazioni su fatture Italia" },
    { value: "REASON_Z1", text: "Z1", description: "Disposizioni di giro di cash pooling" },
    { value: "REASON_Z2", text: "Z2", description: "Versamento di assegni bancari" },
    { value: "REASON_Z3", text: "Z3", description: "Versamento di assegni circolari emessi da altre banche" },
    { value: "REASON_Z4", text: "Z4", description: "Versamento di assegni postali non standardizzati " },
    { value: "REASON_Z5", text: "Z5", description: "Versamento indiretto" },
    { value: "REASON_Z6", text: "Z6", description: "Prelevamento a valere su credito semplice" },
    { value: "REASON_Z7", text: "Z7", description: "Accredito RID" },
    { value: "REASON_Z8", text: "Z8", description: "Accredito MAV" },
    { value: "REASON_Z9", text: "Z9", description: "Insoluto/storno RID" },
    { value: "REASON_ZA", text: "ZA", description: "Insoluto MAV" },
    { value: "REASON_ZB", text: "ZB", description: "Incasso certificati conformità" },
    { value: "REASON_ZC", text: "ZC", description: "Pagamento per fornitura elettrica" },
    { value: "REASON_ZD", text: "ZD", description: "Pagamento per servizio telefonico" },
    { value: "REASON_ZE", text: "ZE", description: "Pagamento per servizi acqua/gas" },
    { value: "REASON_ZF", text: "ZF", description: "Pagamento per operazioni su prodotti derivati" },
    { value: "REASON_ZG", text: "ZG", description: "Accredito per operazioni su prodotti derivati" },
    { value: "REASON_ZH", text: "ZH", description: "Rimborso titoli e/o fondi comuni" },
    { value: "REASON_ZI", text: "ZI", description: "Bonifico dall'estero" },
    { value: "REASON_ZL", text: "ZL", description: "Bonifico sull'estero" },
    { value: "REASON_ZM", text: "ZM", description: "Sconto effetti sull'estero" },
    { value: "REASON_ZN", text: "ZN", description: "Negoziazione assegni sull'estero" },
    { value: "REASON_ZP", text: "ZP", description: "Commissioni e spese su fideiussioni" },
    { value: "REASON_ZQ", text: "ZQ", description: "Commissioni e spese su crediti documentari" },
    { value: "REASON_ZR", text: "ZR", description: "Penali" },
    { value: "REASON_ZS", text: "ZS", description: "Erogazione prestiti personali e finanziamenti diversi" },
    { value: "REASON_ZT", text: "ZT", description: "Pagamento/incasso bollettino bancario" },
    { value: "REASON_ZU", text: "ZU", description: "Bonifico per previdenza complementare" },
    { value: "REASON_ZX", text: "ZX", description: "Bonifico oggetto di oneri deducibili o detrazioni di imposta" }
];

export const bankLinkCbiReasonFilter = [
    {
        text: "XD",
        value: "XD"
    },
    {
        text: "XA",
        value: "XA"
    }
];

export const applicationDefaultOptions = [
    {
        service_id: "ACCG",
        service: "TS Digital Accounting PreCog",
        disabled: false
    },
    {
        service_id: "AGRO",
        service: "Adempimenti Agroalimentare",
        disabled: false
    },
    {
        service_id: "ANA",
        service: "TS Digital Analytics",
        disabled: false
    },
    {
        service_id: "ARC",
        service: "Archiviazione",
        disabled: false
    },
    {
        service_id: "BDG",
        service: "TS Bilancio",
        disabled: false
    },
    {
        service_id: "BIS",
        service: "Business Information",
        disabled: false
    },
    {
        service_id: "CRAM",
        service: "Check Up Impresa",
        disabled: false
    },
    {
        service_id: "DBX",
        service: "DigitalBox",
        disabled: false
    },
    {
        service_id: "EBS",
        service: "TS Pay - Banklink",
        disabled: false
    },
    {
        service_id: "EIP",
        service: "Digital Invoice",
        disabled: false
    },
    {
        service_id: "EIT",
        service: "Incassa Subito",
        disabled: false
    },
    {
        service_id: "ESM",
        service: "Cost Management",
        disabled: false
    },
    {
        service_id: "EXP",
        service: "Note spese",
        disabled: false
    },
    {
        service_id: "FISC",
        service: "Fiscali SaaS",
        disabled: false
    },
    {
        service_id: "HR",
        service: "HR Paghe",
        disabled: false
    },
    {
        service_id: "INSIGHTS",
        service: "TS Digital Insights",
        disabled: false
    },
    {
        service_id: "NCS",
        service: "Notification Center",
        disabled: false
    },
    {
        service_id: "NIUMABONUS",
        service: "TeamSystem Ecobonus",
        disabled: false
    },
    {
        service_id: "OCA",
        service: "One Click Analysis",
        disabled: false
    },
    {
        service_id: "OCA",
        service: "One Click Analysis",
        disabled: false
    },
    {
        service_id: "PPL",
        service: "HR App Mobile",
        disabled: false
    },
    {
        service_id: "PRIVACY",
        service: "Privacy",
        disabled: false
    },
    {
        service_id: "RES",
        service: "Real Estate Services",
        disabled: false
    },
    {
        service_id: "SIG",
        service: "Digital Signature",
        disabled: false
    },
    {
        service_id: "TEL",
        service: "Digital Fiscal Link",
        disabled: false
    },
    {
        service_id: "TSPAY",
        service: "TS Pay",
        disabled: false
    }
];

export const rolesDefaultOptions = [
    {
        role: "OWNER",
        groupId: "OWNER",
        disabled: false
    },
    {
        role: "ADMIN",
        groupId: "ADMIN",
        disabled: false
    },
    {
        role: "MANAGER",
        groupId: "READ_WRITE",
        disabled: false
    },
    {
        role: "WRITE",
        groupId: "READ_WRITE",
        disabled: false
    },
    {
        role: "READ",
        groupId: "READ_WRITE",
        disabled: false
    }
];

export const invoicesConfigValues = [
    { id: "uploadInvoices", functionality: "Invio e caricamento fatture", status: "enabled" },

    { id: "manageActiveInvoices", functionality: "Gestione fatture attive", status: "enabled" },

    { id: "readActiveInvoices", functionality: "Lettura fatture attive", status: "enabled" },

    { id: "managePassiveInvoices", functionality: "Gestione fatture passive", status: "enabled" },

    { id: "readPassiveInvoices", functionality: "Lettura fatture passive", status: "enabled" }
];
