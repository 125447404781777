import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { FormattedMessage } from "react-intl";
import { Table, Button, Menu, Avatar, Badge, Dropdown } from "antd";

import { ColumnSearchProps } from "../../../../../components/general/column-search-props";
import { InvoiceFooter } from "../../../../../components/general/invoice-footer";
import { translateInvoiceStatusShort, translateInvoiceFlowType } from "../../../../../utils/translate";
import { currentStatusPassiveNameFilter, flowTypeFilter } from "../../../../../utils/constant";
import { InvoiceTableContent } from "./styled";
import { userIsAuthorized } from "../../../../../utils/get-roles";

export const InvoicesSendTable = ({
    admin,
    invoiceSendFilter,
    invoiceSendSort,
    handleLoadItem,
    managetrashInvoice,
    managedeleteInvoice,
    invoiceSendPageSize,
    handleInvoiceSendPageSize,
    pageSizeOptions,
    invoiceSendQuery,
    invoiceDataSource,
    expanded,
    invoiceLoading,
    handleGetDocuments,
    invoiceAuthorized,
    invoiceSendFilterChanged
}) => {
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        let columns = [
            {
                title: <FormattedMessage id={"type"} />,
                dataIndex: "category",
                align: "center",
                width: "5%",
                render: (text, record) => (
                    <Avatar
                        style={{
                            backgroundColor: record.category === "FATTURA" ? "#f7ac1e" : "#gray"
                        }}
                    >
                        {record.category}
                    </Avatar>
                )
            },
            {
                title: <FormattedMessage id={"recipient"} />,
                dataIndex: "recipientName",
                filteredValue:
                    invoiceSendFilter && invoiceSendFilter.recipientName ? [invoiceSendFilter.recipientName] : null,
                render: (text, record) => (
                    <span
                        style={!record.active && !record.isRead ? { fontWeight: "bolder" } : { fontWeight: "normal" }}
                    >
                        {isNil(record.recipientName) ? "ID:" + record.recipientId : record.recipientName}
                    </span>
                ),
                ...ColumnSearchProps("destinatario")
            },
            {
                title: <FormattedMessage id={"number"} />,
                align: "center",
                width: "10%",
                dataIndex: "docId",
                filteredValue: invoiceSendFilter && invoiceSendFilter.docId ? [invoiceSendFilter.docId] : null,
                render: (text, record) => (
                    <span
                        style={!record.active && !record.isRead ? { fontWeight: "bolder" } : { fontWeight: "normal" }}
                    >
                        {text}
                    </span>
                ),
                ...ColumnSearchProps("numero")
            },
            {
                title: <FormattedMessage id={"date_doc"} />,
                align: "center",
                width: "12%",
                dataIndex: "docDate",
                sortOrder: invoiceSendSort && invoiceSendSort.columnKey === "docDate" && invoiceSendSort.order,
                sorter: true,
                render: (text, record) => (
                    <span
                        style={!record.active && !record.isRead ? { fontWeight: "bolder" } : { fontWeight: "normal" }}
                    >
                        {text}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="sdi_sending_date" />,
                align: "center",
                width: "10%",
                dataIndex: "sdiDeliveryDate",
                // sortOrder: invoiceSendSort && invoiceSendSort.columnKey === "sdiDeliveryDate" && invoiceSendSort.order,
                // sorter: true,
                render: (text, record) => (
                    <span
                        style={!record.active && !record.isRead ? { fontWeight: "bolder" } : { fontWeight: "normal" }}
                    >
                        {text}
                    </span>
                )
            },
            {
                title: <FormattedMessage id={"flow"} />,
                align: "center",
                width: "12%",
                dataIndex: "flowType",
                filters: flowTypeFilter,
                filteredValue: invoiceSendFilter && invoiceSendFilter.flowTypes ? [invoiceSendFilter.flowTypes] : null,
                filterMultiple: false,
                onFilter: (value, record) => record.flowType.includes(value),
                render: (text, record) => (
                    <span
                        style={!record.active && !record.isRead ? { fontWeight: "bolder" } : { fontWeight: "normal" }}
                    >
                        {translateInvoiceFlowType(record.flowType)}
                    </span>
                )
            },
            {
                title: <FormattedMessage id={"state"} />,
                align: "center",
                width: "12%",
                dataIndex: "currentStatusName",
                filters: currentStatusPassiveNameFilter,
                filteredValue:
                    invoiceSendFilter && invoiceSendFilter.statusName ? [invoiceSendFilter.statusName] : null,
                filterMultiple: false,
                onFilter: (value, record) => record.currentStatusName.includes(value),
                render: (text, record) => (
                    <Badge
                        status={
                            record.currentStatusName === "SCARTATO" || record.currentStatusName === "ERRORE"
                                ? "error"
                                : "processing"
                        }
                        text={translateInvoiceStatusShort(record.currentStatusName)}
                    />
                )
            }
        ];
        if (invoiceAuthorized) {
            columns.push({
                title: <FormattedMessage id={"actions"} />,
                width: "5%",
                dataIndex: "actions",
                align: "center",
                render: (text, record) => (
                    <div>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="0" onClick={() => handleLoadItem(record)}>
                                        <i className="fas fa-info-circle" /> <FormattedMessage id={"show"} />
                                    </Menu.Item>
                                    {invoiceSendFilter == null || invoiceSendFilter.trashed === false ? (
                                        <Menu.Item key="1" onClick={() => managetrashInvoice(record)}>
                                            <i className="fas fa-trash" /> <FormattedMessage id={"trash"} />
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item key="1" onClick={() => managetrashInvoice(record)}>
                                            <i className="fas fa-undo" /> <FormattedMessage id={"untrash"} />
                                        </Menu.Item>
                                    )}
                                    {record.flowType === "SELFSEND" &&
                                    userIsAuthorized("c-company-invoices-delete-button", admin.adminInfo.permits) ? (
                                        <Menu.Item key="2" onClick={() => managedeleteInvoice(record)}>
                                            <i className="fas fa-trash-alt" /> <FormattedMessage id={"deleteInvoice"} />
                                        </Menu.Item>
                                    ) : null}
                                </Menu>
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button shape="circle" size="small">
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </div>
                )
            });
        }
        setColumns(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceAuthorized, invoiceSendFilterChanged]);

    return (
        <div>
            <InvoiceTableContent>
                <Table
                    pagination={false}
                    size="middle"
                    rowKey={record => record.hubId}
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={invoiceDataSource}
                    expandedRowRender={
                        expanded
                            ? record => (
                                  <p>
                                      <b>HubId:</b> {record.hubId}
                                      <br />
                                      <b>SdiId:</b> {record.sdiId ? record.sdiId : "N.D."}
                                  </p>
                              )
                            : null
                    }
                    loading={invoiceLoading}
                    onChange={handleGetDocuments}
                    scroll={{ y: 460 }}
                />
            </InvoiceTableContent>
            <InvoiceFooter
                invoicedDefaultPageSize={invoiceSendPageSize}
                disabled={invoiceDataSource == null || invoiceDataSource.length === 0}
                handleInvoicePageSizeChanged={item => handleInvoiceSendPageSize(item)}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={invoiceSendQuery}
            />
        </div>
    );
};

InvoicesSendTable.propTypes = {
    admin: PropTypes.object.isRequired,
    invoiceSendFilter: PropTypes.object,
    invoiceSendSort: PropTypes.object,
    handleLoadItem: PropTypes.func.isRequired,
    managetrashInvoice: PropTypes.func.isRequired,
    managedeleteInvoice: PropTypes.func.isRequired,
    invoiceSendPageSize: PropTypes.any.isRequired,
    handleInvoiceSendPageSize: PropTypes.func.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    invoiceSendQuery: PropTypes.any.isRequired,
    invoiceDataSource: PropTypes.array.isRequired,
    invoiceLoading: PropTypes.bool.isRequired,
    handleGetDocuments: PropTypes.func.isRequired,
    invoiceAuthorized: PropTypes.bool.isRequired,
    invoiceSendFilterChanged: PropTypes.bool.isRequired
};
