import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerInfo = styled.div`
    padding-left: 15px;
    padding-top: 15px;
    min-width: 400px;
    width: 50%;
`;

export const ContainerAlerts = styled.div`
    padding-top: 15px;
    min-width: 400px;
    padding-right: 15px;
    width: 50%;
`;

export const ContainerTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
`;

export const ContainerSubTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 5px;
`;

export const Status = styled(ContainerSubTitle)`
    margin-top: 10px;
    display: flex;
`;

export const StatusField = styled.span`
    margin-right: 20px;
    flex-basis: 30%;
`;

export const ContainerField = styled.span`
    font-weight: bold;
`;

export const ContainerCopyIcon = styled.span`
    cursor: pointer;
    margin: 0px 5px;
`;

export const ContainerHistoryTitle = styled.span`
    font-weight: bold;
`;

export const ContainerHistoryDescription = styled.span`
    font-size: 11px;
`;

export const ActionContainer = styled.div`
    text-align: right;
    margin-right: 15px;
`;

export const ActionEnable = styled.span`
    font-size: 16px;
    color: #0090d1;
    cursor: pointer;
`;

export const ActionDisabled = styled(ActionEnable)`
    color: #c9d9e8;
    cursor: default;
`;
