import { API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_SIGNATURE_USER_CONFIG_START = "GET_SIGNATURE_USER_CONFIG_START";
export const GET_SIGNATURE_USER_CONFIG_SUCCESS = "GET_SIGNATURE_USER_CONFIG_SUCCESS";
export const GET_SIGNATURE_USER_CONFIG_ERROR = "GET_SIGNATURE_USER_CONFIG_ERROR";

export const getSignatureUserConfig = (auth, itemId, userId) => async dispatch => {
    const urlParams = {
        userId
    };
    dispatch({
        type: GET_SIGNATURE_USER_CONFIG_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(`${API_URL}/permits/${itemId}`, auth, urlParams, dispatch, auth.refreshToken);

        dispatch({
            type: GET_SIGNATURE_USER_CONFIG_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGNATURE_USER_CONFIG_ERROR,
            error: e
        });
    }
};
export const GET_SIGNERS_PERMISSION_START = "GET_SIGNERS_PERMISSION_START";
export const GET_SIGNERS_PERMISSION_SUCCESS = "GET_SIGNERS_PERMISSION_SUCCESS";
export const GET_SIGNERS_PERMISSION_ERROR = "GET_SIGNERS_PERMISSION_ERROR";

export const getSignerPermission = (auth, itemId, userId) => async dispatch => {
    const urlParams = {
        userId
    };
    dispatch({
        type: GET_SIGNERS_PERMISSION_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${API_URL}/permits/${itemId}/signers`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_SIGNERS_PERMISSION_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGNERS_PERMISSION_ERROR,
            error: e
        });
    }
};
export const GET_DOCUMENT_TYPES_PERMISSION_START = "GET_DOCUMENT_TYPES_PERMISSION_START";
export const GET_DOCUMENT_TYPES_PERMISSION_SUCCESS = "GET_DOCUMENT_TYPES_PERMISSION_SUCCESS";
export const GET_DOCUMENT_TYPES_PERMISSION_ERROR = "GET_DOCUMENT_TYPES_PERMISSION_ERROR";

export const getDocumentTypePermission = (auth, itemId, userId) => async dispatch => {
    const urlParams = {
        userId
    };
    dispatch({
        type: GET_DOCUMENT_TYPES_PERMISSION_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${API_URL}/permits/${itemId}/documentTypes`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_DOCUMENT_TYPES_PERMISSION_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_DOCUMENT_TYPES_PERMISSION_ERROR,
            error: e
        });
    }
};
