import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { errorBoundary } from "../../components/general/error-boundary";

import { ServiceStatus } from "../../components/service-status";

const ServiceStatusView = ({ history, match }) => {
    return (
        <div>
            <ServiceStatus history={history} match={match} />
        </div>
    );
};

ServiceStatusView.propTypes = {
    match: PropTypes.object.isRequired
};

const composedHoc = compose(
    injectIntl,
    errorBoundary
);
export default composedHoc(ServiceStatusView);
