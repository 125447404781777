import { CHECK_NEW_VERSION_AVAILABLE } from "../actions/session";

const defaultState = false;

export function newVersionAvailable(state = defaultState, { type, payload }) {
    if (type === CHECK_NEW_VERSION_AVAILABLE) {
        return payload;
    } else {
        return defaultState;
    }
}
