import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../components/general/error-boundary";
import { displaySuccessToast, displayErrorToast } from "../../actions/toast";
import { getSignDocument, getSignDocumentDownload } from "../../actions/signature/read";
import { postCctSend } from "../../actions/signature/write";
import { getAppNameDescription } from "../../actions/app-name";

import { Spin, message } from "antd";
import { Button } from "@mondora/arc/antd";
import { SignatureInfo } from "../../components/signature-info";
import { userIsAuthorized } from "../../utils/get-roles";

import { CenterContainer, Container, NoData } from "./styled";

const SignatureInfoView = ({
    auth,
    admin,
    documentInfo,
    documentDownloaded,
    getSignDocument,
    getSignDocumentDownload,
    getAppNameDescription,
    postCctSend,
    history,
    appNameDesc,
    match: {
        params: { hubId }
    }
}) => {
    const [isPermittedForCctSend, setIsPermittedForCctSend] = useState(false);

    useEffect(() => {
        const getSignDocInfoInfo = async () => {
            if (hubId) {
                await getSignDocument(auth.loginAuth, hubId);
            }
        };
        getSignDocInfoInfo();
    }, [getSignDocument, auth, hubId]);

    useEffect(() => {
        const getAppNameInfoDesc = async () => {
            if (documentInfo.appName) {
                await getAppNameDescription(auth.loginAuth, documentInfo.appName);
            }
        };
        getAppNameInfoDesc();
    }, [getAppNameDescription, auth, documentInfo.appName]);

    useEffect(() => {
        let isPermittedForCctSend = userIsAuthorized("c-companySignature-actions-send-cct", admin.adminInfo.permits);
        setIsPermittedForCctSend(isPermittedForCctSend);
    }, [admin]);

    const handleGetSignDocumentDownload = signed => {
        getSignDocumentDownload(auth.loginAuth, hubId, signed);
    };

    const handleCctSend = async hubId => {
        let result = await postCctSend(auth.loginAuth, hubId);
        if (result) {
            message.success("Il documento reinviato con successo");
        } else {
            message.error("Il documento non è reinviato correttamente");
        }
    };

    return (
        <Container>
            <div style={{ textAlign: "right" }}>
                <Button onClick={() => history.goBack()}>x</Button>
            </div>
            {documentInfo.status.started ||
            (!documentInfo.status.started && !documentInfo.status.ended && !documentInfo.status.error) ||
            appNameDesc.status.started ||
            (!appNameDesc.status.started && !appNameDesc.status.ended && !appNameDesc.status.error) ? (
                <CenterContainer>
                    <Spin size="large" />
                </CenterContainer>
            ) : documentInfo.status.ended && appNameDesc.status.ended ? (
                <SignatureInfo
                    documentInfo={documentInfo}
                    documentDownloaded={documentDownloaded}
                    handleGetSignDocumentDownload={handleGetSignDocumentDownload}
                    handleCctSend={handleCctSend}
                    isPermittedForCctSend={isPermittedForCctSend}
                    appNameDesc={appNameDesc}
                />
            ) : (
                <NoData>
                    <FormattedMessage id="no_result" />
                </NoData>
            )}
        </Container>
    );
};

SignatureInfoView.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getSignDocument: PropTypes.func.isRequired,
    getSignDocumentDownload: PropTypes.func.isRequired,
    getAppNameDescription: PropTypes.func.isRequired,
    postCctSend: PropTypes.func.isRequired,
    documentInfo: PropTypes.object.isRequired,
    documentDownloaded: PropTypes.object.isRequired,
    appNameDesc: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    documentInfo: state.signatureNew.read.document,
    documentDownloaded: state.signatureNew.read.getSignDocumentDownload,
    appNameDesc: state.appNames.getAppNameDescription
});

const actions = {
    getSignDocument,
    getSignDocumentDownload,
    postCctSend,
    displaySuccessToast,
    displayErrorToast,
    getAppNameDescription
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(SignatureInfoView);
