import { isTaxId, isVatNumber, taxIdExists } from "@mondora/agyo-validations";
import { DOMAIN } from "../../config";
import orValidators from "@mondora/agyo-validations/lib/utils/orValidators";
import { translateValiationError } from "../translate";

export const taxIdSchema = {
    type: "object",
    properties: {
        newTaxId: {
            type: "string",
            validate: orValidators(
                isVatNumber("taxRegion", error => translateValiationError(error)),
                isTaxId("taxRegion", "classifier", error => translateValiationError(error))
            ),
            asyncValidate: async (taxId, formValues) => {
                if (taxId !== formValues.vatNumber) {
                    let formValuesWithOutVatNumber = {
                        ...formValues,
                        vatNumber: null
                    };
                    return await taxIdExists("Codice fiscale già registrato", DOMAIN, "BACKOFFICE")(
                        taxId,
                        formValuesWithOutVatNumber
                    );
                }
            }
        },
        taxRegion: {
            type: "string"
        }
    }
};
