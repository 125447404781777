import { getRestCall } from "../../utils/rest-api-call";
import { B2B_READ_API_URL } from "../../config";

export const GET_MESSAGE_START = "GET_MESSAGE_START";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_ERROR = "GET_MESSAGE_ERROR";

export function getMessage(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: GET_MESSAGE_START,
            request: hubId
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v2/invoices/${hubId}/downloadAllMessages`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_MESSAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_MESSAGE_ERROR,
                error: e
            });
        }
    };
}
