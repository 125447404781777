import {
    GET_ADEMPIMENTI_CONFIG_START,
    GET_ADEMPIMENTI_CONFIG_ERROR,
    GET_ADEMPIMENTI_CONFIG_SUCCESS,
    GET_ADEMPIMENTI_CONFIG_RESET,
    GET_ADEMPIMENTI_SIGNER_START,
    GET_ADEMPIMENTI_SIGNER_ERROR,
    GET_ADEMPIMENTI_SIGNER_SUCCESS
} from "../../actions/services/a-fiscali";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function aConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_ADEMPIMENTI_CONFIG_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ADEMPIMENTI_CONFIG_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_ADEMPIMENTI_CONFIG_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_ADEMPIMENTI_CONFIG_RESET:
            return defaultState;

        default:
            return state;
    }
}
export function aSigner(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_ADEMPIMENTI_SIGNER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ADEMPIMENTI_SIGNER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_ADEMPIMENTI_SIGNER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
