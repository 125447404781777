import React from "react";
import PropTypes from "prop-types";
import "./style.css";

import { Select, Form } from "antd";

const FormSelect = ({
    input: { value, ...otherInputs },
    disabled,
    label,
    meta: { touched, error },
    children,
    showSearch,
    ...custom
}) => {
    const val = value === "" ? {} : { value: value };

    return (
        <Form.Item hasFeedback={true} help={touched && error} validateStatus={touched && error ? "error" : ""}>
            <Select
                placeholder={label}
                disabled={disabled}
                children={children}
                showSearch={showSearch}
                className="selectInput"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                {...custom}
                {...otherInputs}
                {...val}
            />
        </Form.Item>
    );
};

FormSelect.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    showSearch: PropTypes.bool
};

FormSelect.defaultProps = {
    showSearch: true
};

export default FormSelect;
