import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Tooltip, Badge, Tag, Spin, Icon } from "antd";
import { Link } from "react-router-dom";
import "./style.css";
import { PointerIcon, CopyToClip } from "../../../styled";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

import CompanyStatusTag from "../../general/tags/company-status";

import { translateClassifier, translateTaxRegion, getIconClassifier } from "../../../utils/translate";
import { serviceDecode } from "../../../utils/decode-services";

class CompanyInfo extends Component {
    static propTypes = {
        company: PropTypes.object,
        //cct: PropTypes.object,
        onlyInfo: PropTypes.bool.isRequired,
        noAction: PropTypes.bool,
        reloadItem: PropTypes.func,
        resetGetCompany: PropTypes.func,
        loading: PropTypes.bool
    };

    render() {
        const { company, onlyInfo, noAction, reloadItem, resetGetCompany, loading } = this.props;

        if (loading) {
            return (
                <div className="c-company-info">
                    <div className="company-empty">
                        {" "}
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                    </div>
                </div>
            );
        }

        if (!company) {
            return (
                <div className="c-company-info">
                    <div className="company-empty">Non disponibile</div>
                </div>
            );
        }

        let info = onlyInfo ? company.base : company.info.base;
        let infoMetering = company.infoMetering;
        let hidden = onlyInfo ? company.preferences.hidden : company.info.preferences.hidden;

        return (
            <div className="c-company-info">
                {!noAction && (
                    <div style={{ textAlign: "center" }}>
                        {!onlyInfo ? (
                            <div className="actions">
                                <Link to={`/item/${info.uuid}/edit`}>
                                    <Tooltip title="Modifica">
                                        <i className="fas fa-edit edit-icon" />
                                    </Tooltip>
                                </Link>
                                <Tooltip title="Ricarica dati">
                                    <i
                                        className=" fas fa-sync-alt refresh-icon"
                                        onClick={() => reloadItem(info.uuid)}
                                    />
                                </Tooltip>
                                <Link to="/item" onClick={() => resetGetCompany()}>
                                    <Tooltip title="Torna alla ricerca">
                                        <i className="far fa-times-circle edit-icon" />
                                    </Tooltip>
                                </Link>
                                <br />
                            </div>
                        ) : (
                            <div className="actions">
                                <span className="item-info-title">Scheda azienda</span>
                                <Link
                                    to={{
                                        pathname: "/item",
                                        state: { itemId: info.id }
                                    }}
                                >
                                    <Button className="item-info-button">Vai all'azienda</Button>
                                </Link>
                                <br />
                            </div>
                        )}
                        <Badge
                            count={info.status.status !== "VALIDATED" ? 1 : 0}
                            style={{
                                backgroundColor: "#ffc107",
                                marginTop: "10px"
                            }}
                        >
                            {info.details.logoUrl ? (
                                <img
                                    src={`${info.details.logoUrl}?${performance.now()}`}
                                    alt="avatar"
                                    style={{ maxHeight: "150px" }}
                                />
                            ) : (
                                <i className={getIconClassifier(info.details.classifier)} />
                            )}
                        </Badge>
                        <br />
                        <br />
                        <p className="company-details">
                            {info.details.classifier === "PERSON"
                                ? info.details.firstName + " " + info.details.lastName
                                : info.details.description}
                        </p>
                    </div>
                )}
                {noAction && info.details.classifier !== "PERSON" && (
                    <span>
                        <span className="field-labels">Ragione sociale: </span>
                        {info.details.description}
                        <br />
                    </span>
                )}
                {noAction && info.details.classifier === "PERSON" && (
                    <span>
                        <span className="field-labels">Nome: </span>
                        {info.details.firstName}
                        <br />
                        <span className="field-labels">Cognome: </span>
                        {info.details.lastName}
                        <br />
                    </span>
                )}
                <span className="field-labels">Identificativo:</span> {info.id}
                <CopyToClip text={info.id}>
                    <PointerIcon icon={faCopy} />
                </CopyToClip>
                <br />
                <span className="field-labels">UUID:</span> {info.uuid}
                <CopyToClip text={info.uuid}>
                    <PointerIcon icon={faCopy} />
                </CopyToClip>
                <br />
                <span className="field-labels">NCS ID:</span> {info.ncsId}
                <CopyToClip text={info.ncsId}>
                    <PointerIcon icon={faCopy} />
                </CopyToClip>
                <br />
                <span className="field-labels">BBSID: </span>
                {infoMetering ? infoMetering.bbsId : "Dato non disponibile"}
                <CopyToClip text={infoMetering ? infoMetering.bbsId : "Dato non disponibile"}>
                    <PointerIcon icon={faCopy} />
                </CopyToClip>
                <br />
                <div style={{ display: "flex" }}>
                    <span className="field-labels">Stato validazione: </span>{" "}
                    <CompanyStatusTag
                        companyStatus={
                            info.status.certificationStatus ? info.status.certificationStatus : info.status.status
                        }
                    />
                </div>
                <span className="field-labels">Validata dal sistema: </span>
                <Badge
                    status={info.status.externallyValidated ? "success" : "error"}
                    text={info.status.externallyValidated ? "Si" : "No"}
                />
                <br />
                <span className="field-labels">Regione fiscale: </span>
                {translateTaxRegion(info.identifier.taxRegion)}
                <br />
                <span className="field-labels">Codice fiscale: </span>
                {info.identifier.taxId}
                <br />
                <span className="field-labels">Partita iva: </span>
                {info.identifier.vatNumber}
                <br />
                <span className="field-labels">Tipologia: </span>
                {translateClassifier(info.details.classifier)}
                <br />
                <span className="field-labels">Regime fiscale: </span>
                {info.details.economics.taxRegime}
                <br />
                <span className="field-labels">Data fine esercizio: </span>
                {info.status.createdAt !== null
                    ? new Date(Number(info.details.economics.balanceSheetDate)).toLocaleString()
                    : "Dato non disponibile"}
                <br />
                <span className="field-labels">Indirizzo: </span>
                {info.details.addresses ? info.details.addresses[0].fullAddress : "Dato non disponibile"}
                {!noAction && (
                    <div>
                        <Divider />
                        <span className="field-labels">Privacy: </span>
                        {hidden ? "Si" : "No"}
                        <br />
                        {hidden
                            ? "L'azienda non è visibile nelle ricerche agyo"
                            : "L'azienda è visibile nelle ricerche agyo"}
                        <br />

                        <Divider />
                        <span className="field-labels">Data creazione: </span>
                        {info.status.createdAt !== null
                            ? new Date(Number(info.status.createdAt)).toLocaleString()
                            : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Creata da: </span>
                        {info.status.createdBy !== null ? info.status.createdBy : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Stato corrente: </span>
                        {info.status.active ? (
                            <Badge status="success" text="Attiva" />
                        ) : info.status.deleted ? (
                            <Badge status="error" text="Cancellata" />
                        ) : (
                            <Badge status="warning" text="Non attiva" />
                        )}
                        <br />
                        {/* <span className="field-labels">Stato CCT: </span>
                        {cct.read.cctProvRequest.status.ended && cct.read.cctProvRequest ? (
                            cct.read.cctProvRequest.totalItems !== 0 ? (
                                <FormattedMessage id={"general." + cct.read.cctProvRequest.provisioning[0].statusCode} />
                            ) : (
                                "Dato non disponibile"
                            )
                        ) : null}
                        <br /> */}
                        <span className="field-labels">Data attivazione: </span>
                        {info.status.activatedAt !== null
                            ? new Date(Number(info.status.activatedAt)).toLocaleString()
                            : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Attivata da: </span>
                        {info.status.activatedBy !== null ? info.status.activatedBy : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Data modifica: </span>
                        {info.status.modifiedAt !== null
                            ? new Date(Number(info.status.modifiedAt)).toLocaleString()
                            : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Modificata da: </span>
                        {info.status.modifiedBy !== null ? info.status.modifiedBy : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Data cancellazione: </span>
                        {info.status.deletedAt !== null
                            ? new Date(Number(info.status.deletedAt)).toLocaleString()
                            : "Dato non disponibile"}
                        <br />
                        <span className="field-labels">Cancellata da: </span>
                        {info.status.deletedBy !== null ? info.status.deletedBy : "Dato non disponibile"}
                        <br />
                    </div>
                )}
                {onlyInfo && !noAction && (
                    <div style={{ textAlign: "center" }}>
                        <Divider />
                        {company.active.services && company.active.services.length > 0 ? (
                            company.active.services
                                .sort((a, b) => {
                                    if (a.agyoService.appId === b.agyoService.appId) return 0;
                                    return a.agyoService.appId > b.agyoService.appId ? 1 : -1;
                                })
                                .map(service => (
                                    <Tag
                                        key={service.agyoService.id}
                                        className="service-tag"
                                        style={{
                                            cursor: "default",
                                            color: serviceDecode(service.agyoService.appId).color,
                                            background: serviceDecode(service.agyoService.appId).background,
                                            border: "1px solid" + serviceDecode(service.agyoService.appId).color
                                        }}
                                    >
                                        {service.agyoService.description}
                                    </Tag>
                                ))
                        ) : (
                            <span>Nessun servizio attivo.</span>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default CompanyInfo;
