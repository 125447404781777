import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Select } from "antd";
// import { FormattedMessage } from "react-intl";

import { UsageConsole } from "./usage-console";
import { UsageConsumptionBar } from "./usage-consumption-bar";
import { UsageConsumptionChart } from "./usage-consumption-chart";

import "./style.css";

export const InvoiceUsageDetails = ({
    auth,
    company,
    getInvoicingDetails,
    getInvoicingStatistics,
    findOwnManagedConnections,
    usage
}) => {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [managedItem, setManagedItem] = useState(null);
    const [grapType, setGrapType] = useState("year");
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [dataType, setDataType] = useState("grap");
    const [dataForChart, setDataForChart] = useState([]);

    useEffect(() => {
        if (company.info.base != null) {
            let companyOptions = [];
            if (company.info.base.details.classifier === "STUDIO") {
                let pagination = {
                    page: 0,
                    items: 2000
                };
                findOwnManagedConnections(
                    auth.loginAuth,
                    { managerIds: company.info.base.uuid, appId: ["EIP"] },
                    pagination
                ).then(invoicesConnections => {
                    invoicesConnections.content.forEach(connection => {
                        companyOptions.push(
                            <Select.Option value={connection.managedId} key={connection.id}>
                                {connection.managedDescription + "(" + connection.managedId + ")"}
                            </Select.Option>
                        );
                    });
                    companyOptions.push(
                        <Select.Option value={null} key="0">
                            {company.info.base.details.description + "(" + company.info.base.id + ")"}
                        </Select.Option>
                    );
                });
                setCompanyOptions(companyOptions);
            }
        } else {
            setCompanyOptions([]);
        }
    }, [auth.loginAuth, company.info.base, findOwnManagedConnections]);

    useEffect(() => {
        let dataForChart = [];
        if (usage.invoicingDetailsUsage != null) {
            if (usage.invoicingDetailsUsage.details) {
                if (grapType === "year") {
                    const range = {
                        min:
                            ("start",
                            new Date(
                                moment()
                                    .startOf("year")
                                    .format("YYYY-MM-DD")
                            )),
                        max:
                            ("end",
                            new Date(
                                moment()
                                    .endOf("year")
                                    .format("YYYY-MM-DD")
                            ))
                    };
                    let invoiceUsage = usage.invoicingDetailsUsage.details.filter(e => {
                        const date = new Date(e.timestamp);
                        return date >= range.min && date <= range.max;
                    });
                    let monthName = [
                        "Gen",
                        "Feb",
                        "Mar",
                        "Apr",
                        "Mag",
                        "Giu",
                        "Lug",
                        "Ago",
                        "Set",
                        "Ott",
                        "Nov",
                        "Dic"
                    ];
                    for (let i = 0; i < 12; i++) {
                        const usageSentForDayFic = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getMonth() === i && usage.sent && usage.fic
                        );
                        const usageSendForDay = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getMonth() === i && usage.sent && !usage.fic
                        );
                        const usageReceivedForDayFic = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getMonth() === i && !usage.sent && usage.fic
                        );
                        const usageReciedForDay = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getMonth() === i && !usage.sent && !usage.fic
                        );

                        dataForChart.push({
                            name: monthName[i],
                            Emesse: usageSendForDay.filter(s => {
                                return s === true;
                            }).length,
                            Ricevute: usageReciedForDay.filter(r => {
                                return r === true;
                            }).length,
                            EmesseFic: usageSentForDayFic.filter(s => {
                                return s === true;
                            }).length,
                            RicevuteFic: usageReceivedForDayFic.filter(r => {
                                return r === true;
                            }).length
                        });
                    }
                }
                if (grapType === "month") {
                    const range = {
                        min: from
                            ? from
                            : ("start",
                              new Date(
                                  moment()
                                      .startOf("month")
                                      .format("YYYY-MM-DD")
                              )),
                        max: to
                            ? to
                            : ("end",
                              new Date(
                                  moment()
                                      .endOf("month")
                                      .format("YYYY-MM-DD")
                              ))
                    };
                    let invoiceUsage = usage.invoicingDetailsUsage.details.filter(e => {
                        const date = new Date(e.timestamp);
                        return date >= range.min && date <= range.max;
                    });
                    let month = from ? from.getMonth() + 1 : new Date().getMonth() + 1;
                    const date = new Date("2019", month, "0").getDate();
                    for (let i = 0; i < date; i++) {
                        const usageSentForDayFic = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getDate() === i + 1 && usage.sent && usage.fic
                        );
                        const usageSendForDay = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getDate() === i + 1 && usage.sent && !usage.fic
                        );
                        const usageReceivedForDayFic = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getDate() === i + 1 && !usage.sent && usage.fic
                        );
                        const usageReciedForDay = invoiceUsage.map(
                            usage => new Date(usage.timestamp).getDate() === i + 1 && !usage.sent && !usage.fic
                        );
                        dataForChart.push({
                            name: i + 1,
                            Emesse: usageSendForDay.filter(s => {
                                return s === true;
                            }).length,
                            Ricevute: usageReciedForDay.filter(r => {
                                return r === true;
                            }).length,
                            EmesseFic: usageSentForDayFic.filter(s => {
                                return s === true;
                            }).length,
                            RicevuteFic: usageReceivedForDayFic.filter(r => {
                                return r === true;
                            }).length
                        });
                    }
                }
            }
        }
        setDataForChart(dataForChart);
    }, [from, grapType, to, usage]);

    const onChangeDataType = dataType => {
        setDataType(dataType);
    };

    const onChangeChartType = typeChart => {
        let from;
        let to;
        if (typeChart === "year") {
            from = moment()
                .startOf("year")
                .format("YYYY-MM-DD");
            to = moment()
                .endOf("year")
                .format("YYYY-MM-DD");
        } else {
            from = moment(new Date())
                .startOf("month")
                .format("YYYY-MM-DD");
            to = moment(new Date())
                .endOf("month")
                .format("YYYY-MM-DD");
        }
        getInvoicingDetails(auth.loginAuth, company.info.base.id, from, to, managedItem);
        getInvoicingStatistics(
            auth.loginAuth,
            company.info.base.id,
            from,
            to,
            managedItem ? managedItem : company.info.base.id
        );
        setGrapType(typeChart);
    };

    const onChangeManaged = managedValue => {
        setManagedItem(managedItem);
        setGrapType("year");
        let from = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        let to = moment()
            .endOf("year")
            .format("YYYY-MM-DD");
        getInvoicingDetails(auth.loginAuth, company.info.base.id, from, to, managedValue);
        getInvoicingStatistics(
            auth.loginAuth,
            company.info.base.id,
            from,
            to,
            managedValue ? managedValue : company.info.base.id
        );
    };

    const onChangeMonth = (dates, dateStrings) => {
        if (dateStrings !== "") {
            let from = moment(dateStrings)
                .startOf("month")
                .format("YYYY-MM-DD");
            let to = moment(dateStrings)
                .endOf("month")
                .format("YYYY-MM-DD");
            getInvoicingDetails(auth.loginAuth, company.info.base.id, from, to, managedItem);
            getInvoicingStatistics(
                auth.loginAuth,
                company.info.base.id,
                from,
                to,
                managedItem ? managedItem : company.info.base.id
            );
            setFrom(new Date(from));
            setTo(new Date(to));
        }
    };

    return (
        <div className="c-usage">
            <UsageConsole usage={usage} />
            <UsageConsumptionBar
                company={company}
                onChangeManaged={onChangeManaged}
                onChangeChartType={onChangeChartType}
                onChangeMonth={onChangeMonth}
                onChangeDataType={onChangeDataType}
                companyOptions={companyOptions}
                grapType={grapType}
                dataType={dataType}
            />
            <br />
            <UsageConsumptionChart usage={usage} dataType={dataType} dataForChart={dataForChart} />
        </div>
    );
};

InvoiceUsageDetails.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getInvoicingDetails: PropTypes.func.isRequired,
    getInvoicingStatistics: PropTypes.func.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    usage: PropTypes.object.isRequired
};
