import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { Tooltip, Button } from "antd";

import {
    Container,
    ContainerAction,
    ContainerTitle,
    ContainerField,
    ContainerCopyIcon,
    InfoSection,
    SpinContainer
} from "./styled";

export const BankLinkInfo = ({ paymentTransactionInfo, displaySuccessToast, handleDownloadFile }) => {
    if (!paymentTransactionInfo) {
        return (
            <SpinContainer>
                <FormattedMessage id="not_available" />
            </SpinContainer>
        );
    }

    return (
        <Container>
            <InfoSection>
                <ContainerTitle>
                    <FormattedMessage id={"details"} />
                </ContainerTitle>
                <ContainerAction>
                    <Tooltip
                        title={
                            paymentTransactionInfo.uploadId ? (
                                <FormattedMessage id="download" />
                            ) : (
                                <FormattedMessage id="not_available" />
                            )
                        }
                    >
                        <Button
                            onClick={handleDownloadFile}
                            style={{ marginLeft: 10 }}
                            disabled={!paymentTransactionInfo.uploadId}
                        >
                            <i className="fas fa-download" />
                        </Button>
                    </Tooltip>
                </ContainerAction>
                <div>
                    <ContainerField>{<FormattedMessage id={"hubid"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.id ? paymentTransactionInfo.id : "Dato non presente"}
                    {paymentTransactionInfo.id && (
                        <ContainerCopyIcon>
                            <CopyToClipboard
                                text={paymentTransactionInfo.id}
                                onCopy={() => displaySuccessToast("Il valore copiato correttamente", true)}
                            >
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </ContainerCopyIcon>
                    )}
                </div>
                <div>
                    <ContainerField>{<FormattedMessage id={"upload_id"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.uploadId ? paymentTransactionInfo.uploadId : "Dato non presente"}
                    {paymentTransactionInfo.uploadId && (
                        <ContainerCopyIcon>
                            <CopyToClipboard
                                text={paymentTransactionInfo.uploadId}
                                onCopy={() => displaySuccessToast("Il valore copiato correttamente", true)}
                            >
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </ContainerCopyIcon>
                    )}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"owner_id"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.ownerId ? paymentTransactionInfo.ownerId : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"description"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.description ? paymentTransactionInfo.description : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"amount"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.amount ? paymentTransactionInfo.amount : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"value_date"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.valueDate ? paymentTransactionInfo.valueDate : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"sia"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.sia ? paymentTransactionInfo.sia : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"source"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.source ? paymentTransactionInfo.source : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"transaction_date"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.transactionDate
                        ? paymentTransactionInfo.transactionDate
                        : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"iban"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.iban ? paymentTransactionInfo.iban : "Dato non presente"}
                </div>

                <div>
                    <ContainerField>{<FormattedMessage id={"date"} />}:</ContainerField>{" "}
                    {paymentTransactionInfo.lastTimestamp
                        ? new Date(paymentTransactionInfo.lastTimestamp).toLocaleString()
                        : "Dato non presente"}
                </div>
            </InfoSection>
        </Container>
    );
};

BankLinkInfo.propTypes = {
    paymentTransactionInfo: PropTypes.object,
    displaySuccessToast: PropTypes.func.isRequired,
    handleDownloadFile: PropTypes.func.isRequired
};
