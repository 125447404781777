import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Badge, Dropdown, Button, Menu, Tooltip } from "antd";

export const CCTConfig = ({ dataSource, loading, handleResetPassword, actionResetPwdDisabled }) => {
    const columns = [
        {
            title: <FormattedMessage id="c-cctconfigservice.cctuser" />,
            dataIndex: "cctUser",
            key: "cctUser"
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.created_at" />,
            dataIndex: "passwordCreatedAt",
            key: "passwordCreatedAt",
            render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.expiring_at" />,
            dataIndex: "passwordExpDate",
            key: "passwordExpDate",
            render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.updated_at" />,
            dataIndex: "passwordUpdatedAt",
            key: "passwordUpdatedAt",
            render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.state" />,
            dataIndex: "active",
            key: "active",
            render: text => (
                <span>
                    <Badge status={text ? "success" : "error"} />
                    {text ? (
                        <FormattedMessage id="c-cctconfigservice.active" />
                    ) : (
                        <FormattedMessage id="c-cctconfigservice.disabled" />
                    )}
                </span>
            )
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.actions" />,
            key: "actions",
            render: (text, record) => (
                <div>
                    <Tooltip title={<FormattedMessage id="reset_cct_password" />}>
                        <Dropdown
                            overlay={renderActions(record)}
                            trigger={["click"]}
                            placement="bottomRight"
                            disabled={actionResetPwdDisabled}
                        >
                            <Button shape="circle" size="small" type={"primary"}>
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </Tooltip>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleResetPassword(record)}>
                    <FormattedMessage id="reset_cct_password" />
                </Menu.Item>
            </Menu>
        );
    };

    const columns2 = [
        {
            title: <FormattedMessage id="c-cctconfigservice.servicename" />,
            dataIndex: "name",
            key: "name"
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.state" />,
            dataIndex: "active",
            key: "active_spec",
            render: text => (
                <span>
                    <Badge status={text ? "success" : "error"} />
                    {text ? (
                        <FormattedMessage id="c-cctconfigservice.active" />
                    ) : (
                        <FormattedMessage id="c-cctconfigservice.disabled" />
                    )}
                </span>
            )
        }
    ];

    return (
        <div>
            <Table
                rowKey={(record, index) => index}
                locale={{
                    emptyText: <FormattedMessage id="c-cctconfigservice.nullconfiguration" />
                }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={loading}
                expandedRowRender={record =>
                    record.specs ? (
                        <Table
                            size="small"
                            rowKey="name"
                            columns={columns2}
                            dataSource={Object.values(record.specs)}
                            align="left"
                            pagination={false}
                            style={{ marginBottom: "2vh" }}
                        />
                    ) : (
                        ""
                    )
                }
                rowExpandable={record => (record.specs ? false : true)}
            />
        </div>
    );
};

CCTConfig.propTypes = {
    dataSource: PropTypes.array.isRequired,
    handleResetPassword: PropTypes.func.isRequired,
    actionResetPwdDisabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired
};
