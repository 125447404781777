import React from "react";
import { Table, Empty, Input, Avatar, Select } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ActionIcon, DisabledActionIcon } from "../../../../styled";
import { faFileUpload, faSpellCheck } from "@fortawesome/pro-solid-svg-icons";
import { iVerde } from "../../../../utils/constant";
import { cctCodiceCoupon, couponList } from "../../../../utils/constant";

const tableConfig = ({ handleFilter, status, iVerdeTab, cfMain }) => {
    let options = [
        <Select.Option key="all" value="all">
            Tutti
        </Select.Option>
    ];
    cctCodiceCoupon.map(coupon =>
        options.push(
            <Select.Option key={coupon.value} value={coupon.value}>
                {coupon.text}
            </Select.Option>
        )
    );

    let colums = [
        {
            title: (
                <Select
                    defaultValue="Tutti"
                    style={{ width: 130 }}
                    onChange={value => {
                        if (value !== "all") {
                            handleFilter(value, "codiceCoupon");
                        } else {
                            handleFilter(null, "codiceCoupon");
                        }
                    }}
                >
                    {options}
                </Select>
            ),
            dataIndex: "codiceCoupon",
            key: "codiceCoupon",
            render: text => (
                <Avatar
                    style={{
                        backgroundColor: couponList.includes(text)
                            ? cctCodiceCoupon.find(x => x.value === text).color
                            : "#555"
                    }}
                >
                    {text}
                </Avatar>
            )
        },

        {
            title: (
                <Input
                    onChange={v => {
                        if (v.target.value !== "") {
                            handleFilter(v.target.value, "cf-produttore");
                        } else {
                            handleFilter(null, "cf-produttore");
                        }
                    }}
                    placeholder="CF Produttore"
                />
            ),
            dataIndex: "cfMain",
            key: "cfMain"
        },
        {
            title: (
                <Input
                    onChange={v => {
                        if (v.target.value !== "") {
                            handleFilter(v.target.value, "cf-titolari");
                        } else {
                            handleFilter(null, "cf-titolari");
                        }
                    }}
                    placeholder="CF Titolare"
                />
            ),
            dataIndex: "cfAzienda",
            key: "cfAzienda"
        },
        {
            dataIndex: "info",
            key: "info",
            render: (text, record) => (
                <span>
                    {record.statusCode !== "initialized" ? (
                        <Link to={`/cct-provisioning/detail/${record.statusCode}/${record.cfMain}/${record.cfAzienda}`}>
                            <ActionIcon icon={faSpellCheck} />
                        </Link>
                    ) : (
                        <DisabledActionIcon icon={faSpellCheck} />
                    )}
                </span>
            )
        }
    ];

    if (iVerdeTab || iVerde.includes(cfMain)) {
        colums.push({
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <span>
                    {record.statusCode === "initialized" ? (
                        <Link
                            to={`/cct-provisioning/autoupload/cfMain=${record.cfMain}&cfAzienda=${record.cfAzienda}&idCoupon=${record.id}`}
                        >
                            <ActionIcon icon={faFileUpload} />
                        </Link>
                    ) : (
                        <DisabledActionIcon icon={faFileUpload} />
                    )}
                </span>
            )
        });
    }
    return colums;
};

const CctDataList = ({
    iVerdeTab,
    status,
    handleShowSize,
    handlePage,
    handleFilter,
    total,
    list,
    loading,
    page,
    cfMain
}) => (
    <Table
        rowKey="id"
        locale={{
            emptyText: <Empty description="Nessun dato da mostrare" />
        }}
        style={{ backgroundColor: "white" }}
        pagination={{
            current: page,
            total: total,
            onChange: p => handlePage(p),
            onShowSizeChange: (c, s) => handleShowSize(s),
            pageSizeOptions: ["3", "10", "25", "50"],
            showSizeChanger: true
        }}
        loading={loading}
        columns={tableConfig({
            handleFilter: (value, name) => handleFilter(value, name),
            status,
            iVerdeTab,
            cfMain
        })}
        dataSource={list}
    />
);

CctDataList.propTypes = {
    handleShowSize: PropTypes.func.isRequired,
    handlePage: PropTypes.func.isRequired,
    list: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    status: PropTypes.string,
    total: PropTypes.number,
    cfMain: PropTypes.string
};

export default CctDataList;
