import { API_URL } from "../../config";
import { deleteRestCall } from "../../utils/rest-api-call";

export const DELETE_TSID_START = "DELETE_TSID_START";
export const DELETE_TSID_SUCCESS = "DELETE_TSID_SUCCESS";
export const DELETE_TSID_ERROR = "DELETE_TSID_ERROR";

export const deleteTSID = (auth, tsid) => {
    return async dispatch => {
        const toast = {
            onOk: true,
            onOkText: "Utente scollegato con successo",
            onError: true,
            infoReq: { authId: auth.id, req: tsid }
        };
        const encodeTSID = encodeURIComponent(tsid);
        dispatch({
            type: DELETE_TSID_START,
            request: tsid
        });

        try {
            const result = await deleteRestCall(
                `${API_URL}/auth/v3/tsId?tsId=${encodeTSID}`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: DELETE_TSID_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: DELETE_TSID_ERROR,
                error: e
            });
        }
    };
};
