import { sharePackage, refreshPackage, disablePackage } from "./write";

import { combineReducers } from "redux";

export const packageMetering = combineReducers({
    sharePackage,
    refreshPackage,
    disablePackage
});

export default combineReducers({ packageMetering });
