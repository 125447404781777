import React from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tag } from "antd";

export const SignatureConfigExpanded = ({ dataSource }) => {
    const columns = [
        {
            title: <FormattedMessage id="functionality" />,
            dataIndex: "descrption",
            key: "descrption"
        },
        {
            title: <FormattedMessage id="not_allowed" />,
            dataIndex: "enabled",
            key: "enabled",
            render: text => (
                <span>
                    {text ? (
                        <Tag color="green">{<FormattedMessage id={"enabled"} />}</Tag>
                    ) : (
                        <Tag color="red">{<FormattedMessage id={"not_enabled"} />}</Tag>
                    )}
                </span>
            )
        }
    ];

    return (
        <div>
            <Table columns={columns} dataSource={dataSource || []} rowKey="descrption" size="medium" />
        </div>
    );
};

SignatureConfigExpanded.propTypes = {
    dataSource: PropTypes.array
};
