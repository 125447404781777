import { REGISTRY_WRITE_URL } from "../../../config";
import { postRestCall } from "../../../utils/rest-api-call";

export const POST_LOGO_START = "POST_LOGO_START";
export const POST_LOGO_SUCCESS = "POST_LOGO_SUCCESS";
export const POST_LOGO_ERROR = "POST_LOGO_ERROR";

export function replaceLogo(auth, itemId, base64logo, toast) {
    let body = {
        base64: base64logo
    };

    return async dispatch => {
        dispatch({
            type: POST_LOGO_START,
            request: body
        });

        try {
            const result = await postRestCall(
                `${REGISTRY_WRITE_URL}/${itemId}/logo`,
                auth,
                null,
                body,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: POST_LOGO_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: POST_LOGO_ERROR,
                error: e
            });
        }
    };
}
