import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Empty } from "antd";
import { Container, ContainerTitle } from "./styled";

export const DashboardAlarm = ({ title = <FormattedMessage id="warnings" /> }) => {
    let alarm = [];

    return (
        <Container>
            <ContainerTitle>{title}</ContainerTitle>
            {alarm.length === 0 ? <Empty description={<FormattedMessage id="no_warning_present" />} /> : null}
            {alarm}
        </Container>
    );
};

DashboardAlarm.propTypes = {
    title: PropTypes.any
};
