import styled from "styled-components";

export const SpinnerContainer = styled.div`
    margin-top: 50%;
    text-align: center;
`;

export const ContainerPDFViewer = styled.div`
    height: 90vh;
    border: 0px;
`;
