import React, { useState } from "react";
import { Modal, Input, Form, Select, Alert } from "antd";

import { FormattedMessage } from "react-intl";
const FormItem = Form.Item;
const { Option } = Select;
export const SharePackageModal = ({ sharePackageModal, onCancel, family, handleShareService, loading }) => {
    const [itemId, setItemId] = useState(null);
    const [serviceId, setServiceId] = useState(null);

    const shareService = (itemId, serviceId) => {
        handleShareService(itemId, serviceId);
    };
    return (
        <Modal
            visible={sharePackageModal}
            title={<FormattedMessage id="c-meteringtable.share-service" />}
            okText={<FormattedMessage id="c-meteringtable.share-service" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onCancel={onCancel}
            onOk={() => {
                shareService(itemId, serviceId);
            }}
            okButtonProps={{
                loading: loading
            }}
        >
            <div>
                <Alert
                    message={<FormattedMessage id="c-metering_share_warning_service" />}
                    banner
                    description={
                        <span>
                            <a href="https://agyo.elevio.help/it/articles/255">
                                {<FormattedMessage id="c-metering_share_warning_link" />}
                            </a>
                        </span>
                    }
                />
                <br></br>
            </div>

            <span>{<FormattedMessage id="c-metering_share_itemId" />}</span>

            <FormItem>
                <Input placeholder={"Identificativo(CF/UUID) azienda"} onChange={e => setItemId(e.target.value)} />
            </FormItem>
            <span>{<FormattedMessage id="c-metering_share_service" />}</span>
            <br></br>
            {family === "FATTURE_ATTIVE_PASSIVE" && (
                <Select
                    placeholder={<FormattedMessage id="c-metering_share_service_type" />}
                    style={{ width: "200px" }}
                    onChange={value => setServiceId(value)}
                >
                    <Option value="SHARE_SDI" key="SHARE_SDI">
                        SDI
                    </Option>
                    <Option value="SHARE_B2B_PASSIVE_FLOW" key="SHARE_B2B_PASSIVE_FLOW">
                        ERICEVI
                    </Option>
                    <Option value="SHARE_CRT" key="SHARE_CRT">
                        Corrispettivi
                    </Option>
                    <Option value="SHARE_NSO" key="SHARE_NSO">
                        NSO
                    </Option>
                </Select>
            )}
            {(family === "CRISI_IMPRESA" || family === "CRISI_IMPRESA_PRO") && (
                <Select
                    placeholder={<FormattedMessage id="c-metering_share_service_type" />}
                    style={{ width: "200px" }}
                    onChange={value => setServiceId(value)}
                >
                    <Option value="SHARE_MODE_CRAM">MODE_CRAM</Option>
                </Select>
            )}
            {(family === "TSPAY" || family === "TSPAY_PLUS") && (
                <Select
                    placeholder={<FormattedMessage id="c-metering_share_service_type" />}
                    style={{ width: "200px" }}
                    onChange={value => setServiceId(value)}
                >
                    <Option value="SHARE_BANKLINK_INFO">INFORMATIVE</Option>
                    <Option value="SHARE_BANKLINK_POST">DISPOSITIVE</Option>
                </Select>
            )}
        </Modal>
    );
};
