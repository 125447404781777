import styled from "styled-components";

export const ContractWrapper = styled.div`
    background-color: white;
    padding: 10px;
    width: 100%;
    height: 90%;
`;

export const PdfWrapper = styled.div`
    border: 1px dashed #0090d1;
    height: 80vh;
    margin: 0px 15px 15px 15px;
`;

export const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const InfoContainer = styled.div`
    display: flex;
    margin-left: 12px;
    flex-flow: row;
`;
export const InfoTitle = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #005075;
`;

export const DownloadButton = styled.div`
    position: absolute;
    right: 15px;
`;
