import { applyMiddleware, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import { persistStore, persistCombineReducers } from "redux-persist";
import thunk from "redux-thunk";
import reducersSchema from "./schema";
import storage from "redux-persist/lib/storage/session";
import { LOGOUT } from "../actions/auth";
import { GET_COMPANY_RESET } from "../actions/company/info";

const logger = createLogger({ collapsed: true });

const config = {
    key: "primary",
    storage,
    whitelist: ["auth"]
};

const persistedReducer = persistCombineReducers(config, reducersSchema);
const reducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    if (action.type === GET_COMPANY_RESET) {
        state.company = undefined;
    }
    return persistedReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk, logger)));

export const persistor = persistStore(store);

export default store;
