import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { contains, isNil } from "ramda";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import { injectIntl } from "react-intl";
import SignatureService from "../../../components/services/signature";
import { activateService } from "../../../actions/company/services";

import {
    getSigners,
    getSlot,
    getSignerDetails,
    downloadSigner,
    getSignerExtraData
} from "../../../actions/signers/read";
import { getSignatureConfig } from "../../../actions/services/signature";
import { updateCertificateId } from "../../../actions/signers/write";
import { errorBoundary } from "../../../components/general/error-boundary";

import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import { ActionIcon } from "../../../styled";
import { Container, GoBack } from "./styled";

const SignatureServiceView = ({
    activateService,
    admin,
    auth,
    company,
    getSigners,
    getSlot,
    getSignatureConfig,
    services,
    signersList,
    history,
    getSignerDetails,
    signerDetails,
    downloadSigner,
    intl,
    getSignerExtraData,
    signerDownload,
    updateCertificateId
}) => {
    const [slots, setSlots] = useState(null);
    const [fullText, setFullText] = useState("");

    const getExtraData = useExtraData(auth.loginAuth, company.info.base.id, getSignerExtraData);
    const getShowSigner = useShowSigner(auth.loginAuth, signerDownload, downloadSigner);

    useEffect(() => {
        const getListSigners = async () => {
            await getSigners(auth.loginAuth, company.info.base.id, fullText, 0);
        };
        getListSigners();
    }, [auth.loginAuth, fullText, getSigners, company.info.base.id]);

    useEffect(() => {
        const getSlots = async () => {
            const slot = await getSlot(auth.loginAuth, company.info.base.id);
            if (slot) {
                setSlots(slot);
            }
        };
        getSlots();
    }, [auth.loginAuth, getSlot, company.info.base.id]);

    useEffect(() => {
        if (company.info.base.uuid && company.active.services) {
            company.active.services.forEach(service => {
                if (contains("SIGNATURE-FLOW", service.agyoService.id)) {
                    getSignatureConfig(auth.loginAuth, company.info.base.uuid);
                }
            });
        }
    }, [auth.loginAuth, getSignatureConfig, company.info.base.uuid, company.active.services]);

    const loadMore = async page => {
        await getSigners(auth.loginAuth, company.info.base.id, fullText, page - 1);
    };

    const addFilter = async currentFullText => {
        if (currentFullText.length >= 3 || currentFullText.length === 0) {
            setFullText(currentFullText);
        }
    };

    const handleSignerDetalis = id => {
        if (id) {
            getSignerDetails(auth.loginAuth, id);
        }
    };

    const handleUpdateCertificate = filter => {
        updateCertificateId(auth.loginAuth, filter.certificateId);
    };
    const handleDownloadSigner = async (filter, documentType) => {
        let result = await downloadSigner(auth.loginAuth, filter.id, filter.type, documentType);
        if (result && result.base64) {
            try {
                var download = document.createElement("a");
                download.setAttribute("href", "data:application/octet-stream;charset=utf-8;base64," + result.base64);
                download.setAttribute("download", `sig_${filter.id}_${filter.type}_${documentType}.pdf`);
                download.click();
                message.success(
                    intl.formatMessage({
                        id: "the_file_downloaded_successfully"
                    })
                );
            } catch (e) {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        } else {
            message.error(
                intl.formatMessage({
                    id: "can_not_download_file"
                })
            );
        }
    };

    return !isNil(company.info.base) ? (
        <Container>
            <GoBack>
                <ActionIcon icon={faTimesCircle} onClick={() => history.goBack()} />
            </GoBack>

            <SignatureService
                admin={admin}
                auth={auth}
                activateService={activateService}
                active={company.active}
                addFilter={f => addFilter(f)}
                company={company}
                loadMore={p => loadMore(p)}
                services={services}
                signersList={signersList}
                slots={slots}
                handleSignerDetalis={handleSignerDetalis}
                handleUpdateCertificate={handleUpdateCertificate}
                signerDetails={signerDetails}
                handleDownloadSigner={handleDownloadSigner}
                extraData={getExtraData.data}
                handleShowSigner={getShowSigner.showSigner}
                loadingShowSigner={getShowSigner.loading}
                base64ShowSigner={getShowSigner.base64}
            />
        </Container>
    ) : (
        <Redirect to="/item" />
    );
};

SignatureServiceView.propTypes = {
    activateService: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object,
    getSignatureConfig: PropTypes.func.isRequired,
    getSigners: PropTypes.func.isRequired,
    getSlot: PropTypes.func.isRequired,
    signersList: PropTypes.object,
    services: PropTypes.object,
    downloadSigner: PropTypes.func.isRequired,
    intl: PropTypes.object,
    getSignerExtraData: PropTypes.func.isRequired,
    signerDownload: PropTypes.object.isRequired,
    updateCertificateId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    company: state.company,
    services: state.services,
    signersList: state.signatureNew.read.signersList,
    signerDetails: state.signatureNew.read.getSignerDetails,
    signerDownload: state.signatureNew.read.downloadSigner
});

const actions = {
    activateService,
    getSignatureConfig,
    getSigners,
    getSlot,
    getSignerDetails,
    downloadSigner,
    getSignerExtraData,
    updateCertificateId
};

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(SignatureServiceView);

const useExtraData = (auth, itemId, getSignerExtraData) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const getExtraData = async () => {
            const extraData = await getSignerExtraData(auth, itemId);
            if (extraData) {
                setData(extraData);
            } else {
                setData(null);
            }
        };

        getExtraData();
    }, [auth, getSignerExtraData, itemId]);

    return {
        data
    };
};

const useShowSigner = (auth, signerDownload, downloadSigner) => {
    const [base64, setBase64] = useState(null);
    const [loading, setLoading] = useState(false);

    const showSigner = async filter => {
        downloadSigner(auth, filter.id, filter.type, filter.documentType);
        setBase64(null);
    };

    useEffect(() => {
        if (signerDownload.status.started) {
            setLoading(true);
        } else {
            setLoading(false);
            if (signerDownload.status.ended) {
                setBase64(signerDownload.base64);
            }
        }
    }, [signerDownload]);

    return {
        showSigner,
        base64,
        loading
    };
};
