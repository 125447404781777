import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Button } from "@mondora/vrc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../components/general/error-boundary";
import TsCard from "../../components/general/ts-card";
import { CenteredContainer, ViewTitle } from "./styled";
import { runAssignNso } from "../../actions/nso";

const AssignNsoView = ({ auth, runAssignNso }) => {
    const handleNsoAssign = async () => {
        runAssignNso(auth.loginAuth);
    };
    return (
        <div>
            <ViewTitle>
                <FormattedMessage id="assignNsoTitle" />
            </ViewTitle>
            <TsCard
                withTitle={true}
                title={
                    <span>
                        <FontAwesomeIcon icon={faRepeat} /> <FormattedMessage id="assignNsoDescription" />
                    </span>
                }
                content={
                    <div>
                        <CenteredContainer>
                            <br />
                            <Button kind="secondary" size="small" onClick={handleNsoAssign}>
                                <FormattedMessage id="assignNso" />
                            </Button>
                        </CenteredContainer>
                    </div>
                }
            />
        </div>
    );
};

AssignNsoView.propTypes = {
    auth: PropTypes.object.isRequired,
    runAssignNso: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});

const actions = {
    runAssignNso
};
const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(AssignNsoView);
