import { USER_READ, API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_ADM_USERS_START = "GET_ADM_USERS_START";
export const GET_ADM_USERS_SUCCESS = "GET_ADM_USERS_SUCCESS";
export const GET_ADM_USERS_ERROR = "GET_ADM_USERS_ERROR";

export const getAdmUsers = (auth, filter) => async dispatch => {
    dispatch({
        type: GET_ADM_USERS_START
    });

    let urlParams = {
        profile: filter && filter.permits ? filter.permits : null
    };

    try {
        let result = await getRestCall(`${API_URL}/users`, auth, urlParams, dispatch, auth.refreshToken);

        dispatch({
            type: GET_ADM_USERS_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_ADM_USERS_ERROR,
            error: e
        });
    }
};

export const LIST_USER_START = "LIST_USER_START";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_ERROR = "LIST_USER_ERROR";

export const listUsers = (auth, itemId) => async dispatch => {
    dispatch({
        type: LIST_USER_START
    });
    const urlParams = {
        itemId,
        withTech: true,
        "pagination.itemsPerPage": 200,
        "pagination.pageNumber": 0
    };

    try {
        const result = await getRestCall(`${USER_READ}/users`, auth, urlParams, dispatch, auth.refreshToken);

        dispatch({
            type: LIST_USER_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: LIST_USER_ERROR,
            error: e
        });
    }
};

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const getUser = (auth, id) => async dispatch => {
    dispatch({
        type: GET_USER_START
    });

    try {
        let result = await getRestCall(`${USER_READ}/users/${id}`, auth, null, dispatch, auth.refreshToken);
        dispatch({
            type: GET_USER_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_USER_ERROR,
            error: e
        });
    }
};
