import React from "react";

import { InfoContainer, InfoTitle, StatusContainer } from "../styled";
import { FormattedMessage } from "react-intl";
import { translateitemStatus } from "../../../../utils/translate";
import { Tag, Alert } from "antd";

const ContractInfoCompany = ({ data, info }) => {
    return (
        <InfoContainer>
            <div>
                <InfoTitle>Info documento</InfoTitle>
                <li>
                    <FormattedMessage id="general.type" /> <span>: {info.policyDescription}</span>
                </li>
                <li>
                    <FormattedMessage id="general.extValidate" />
                    <span>: {info.externallyValidated ? "Si" : "No"}</span>
                </li>
                <li>
                    <FormattedMessage id="general.uploadBy" /> <span>: {info.user}</span>
                </li>
                <li>
                    <FormattedMessage id="general.date" /> <span>: {new Date(info.updatedAt).toLocaleString()}</span>
                </li>
                {info.paused && (
                    <li>
                        <FormattedMessage id="general.pausedDescription" />:{" "}
                        <Tag color="#f50">
                            <FormattedMessage id="general.pausedStatus" />
                        </Tag>
                    </li>
                )}
                <br />
                <InfoTitle>
                    <FormattedMessage id="general.registry" />
                </InfoTitle>
                <li>
                    <FormattedMessage id="general.itemDescription" />
                    <span>: {data.item.base.details.description}</span>
                </li>
                <li>
                    <FormattedMessage id="general.taxId" /> <span>: {data.item.base.identifier.taxId}</span>
                </li>
                <li>
                    <FormattedMessage id="general.vatNumber" /> <span>: {data.item.base.identifier.vatNumber}</span>
                </li>
                <li>
                    <FormattedMessage id="general.type" />
                    <span>: {data.item.base.details.classifier}</span>
                </li>
                <li>
                    <FormattedMessage id="general.address" />
                    <span>
                        {": "}
                        {data &&
                        data.item &&
                        data.item.base &&
                        data.item.base.details &&
                        data.item.base.details.addresses &&
                        data.item.base.details.addresses.length &&
                        data.item.base.details.addresses[0].fullAddress ? (
                            data.item.base.details.addresses[0].fullAddress
                        ) : (
                            <Alert message={"! non disponibile - completare anagrafica"} banner type="error" />
                        )}
                    </span>
                </li>
            </div>
            <StatusContainer>
                <InfoTitle>Stato certificazione: </InfoTitle>
                <span>{translateitemStatus(info.status)}</span>
            </StatusContainer>
        </InfoContainer>
    );
};

export default ContractInfoCompany;
