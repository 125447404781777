import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Badge, Table } from "antd";

import { FieldLabel } from "./styled";

export const CompanyTsPaySerive = ({ loading, configuration }) => {
    let columns = [
        {
            title: <FormattedMessage id="id" />,
            dataIndex: "userId",
            key: "userId"
        },
        {
            title: <FormattedMessage id="session_key" />,
            dataIndex: "sessionKey",
            key: "sessionKey"
        },
        {
            title: <FormattedMessage id="session_url" />,
            dataIndex: "sessionUrl",
            key: "sessionUrl"
        },
        {
            title: <FormattedMessage id="error" />,
            dataIndex: "error",
            render: text => (
                <span>
                    <Badge status="error" />
                    {text}
                    {/* {text ? <FormattedMessage id="active" /> : <FormattedMessage id="disabled" />} */}
                </span>
            )
        },
        {
            title: <FormattedMessage id="status" />,
            dataIndex: "string",
            render: (text, record) => (
                <span>
                    {/* <Badge status={text ? "success" : "error"} /> */}
                    {text}
                    {/* {text ? <FormattedMessage id="active" /> : <FormattedMessage id="disabled" />} */}
                </span>
            )
        }
    ];

    return (
        <div>
            <br />
            <br />
            <span className="a1">{<FormattedMessage id="ts_pay_configurations" />}</span>
            <br />
            <br />
            <FieldLabel>
                <FormattedMessage id="status" />:{" "}
            </FieldLabel>
            {configuration && configuration.status ? configuration.status : <FormattedMessage id="not_available" />}
            <br />
            <FieldLabel>
                <FormattedMessage id="created_at" />:{" "}
            </FieldLabel>
            {configuration && configuration.createdAt ? (
                new Date(configuration.createdAt).toLocaleString()
            ) : (
                <FormattedMessage id="not_available" />
            )}
            <br />
            <FieldLabel>
                <FormattedMessage id="created_by" />:{" "}
            </FieldLabel>
            {configuration && configuration.createdBy ? (
                new Date(configuration.createdBy).toLocaleString()
            ) : (
                <FormattedMessage id="not_available" />
            )}
            <br />
            <FieldLabel>
                <FormattedMessage id="updated_at" />:{" "}
            </FieldLabel>
            {configuration && configuration.updatedAt ? (
                new Date(configuration.updatedAt).toLocaleString()
            ) : (
                <FormattedMessage id="not_available" />
            )}
            <br />
            <FieldLabel>
                <FormattedMessage id="updated_by" />:{" "}
            </FieldLabel>
            {configuration && configuration.updatedBy ? (
                new Date(configuration.updatedBy).toLocaleString()
            ) : (
                <FormattedMessage id="not_available" />
            )}
            <br />
            <br />
            <Table
                rowKey={(record, index) => `${index}-ts-pay`}
                locale={{ emptyText: <FormattedMessage id="no_configuration_available" /> }}
                dataSource={configuration && configuration.onboardingSessions ? configuration.onboardingSessions : []}
                columns={columns}
                size="middle"
                pagination={false}
                loading={loading}
            />
            <br />
        </div>
    );
};

CompanyTsPaySerive.propTypes = {
    loading: PropTypes.bool.isRequired,
    configuration: PropTypes.array.isRequired
};
