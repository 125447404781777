import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { cctStatuses } from "../../../../utils/constant";
import { Table, Dropdown, Menu, Button, Badge } from "antd";

export const CompanySignatureTable = ({
    signatureAuthorized,
    handleLoadItem,
    signatureQuery,
    pagination,
    signatureDataSource,
    expanded,
    signatureLoading,
    handleGetDocuments,
    documentTypes,
    documentStatuses
}) => {
    const [columns, setColumns] = useState([
        {
            title: <FormattedMessage id="type" />,
            width: "40%",
            dataIndex: "documentTypeDetail.title",
            filters: documentTypes,
            filterMultiple: false
        },
        {
            title: <FormattedMessage id="signature_type" />,
            width: "15%",
            dataIndex: "signatureLabel"
        },
        {
            title: <FormattedMessage id="cct" />,
            width: "15%",
            dataIndex: "extStatusCode",
            filters: cctStatuses,
            filterMultiple: false,
            render: (text, record) => (
                <Badge
                    color={cctStatuses.find(x => x.value === record.extStatusCode).color}
                    text={cctStatuses.find(x => x.value === record.extStatusCode).text}
                />
            )
        },
        {
            title: <FormattedMessage id="date" />,
            align: "center",
            width: "15%",
            dataIndex: "creationDate",
            render: text => <span>{new Date(text).toLocaleString()}</span>
        },
        {
            title: <FormattedMessage id="status" />,
            width: "15%",
            align: "center",
            dataIndex: "documentStatusCode",
            filterMultiple: false,
            filters: documentStatuses
        }
    ]);

    useEffect(() => {
        if (signatureAuthorized) {
            let getColumns = columns;
            getColumns.push({
                title: <FormattedMessage id={"actions"} />,
                width: "5%",
                dataIndex: "actions",
                align: "center",
                render: (text, record) => (
                    <div>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="0" onClick={() => handleLoadItem(record)}>
                                        <i className="fas fa-info-circle" /> <FormattedMessage id={"show"} />
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button shape="circle" size="small">
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </div>
                )
            });
            setColumns(getColumns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureAuthorized]);

    return (
        <div>
            <Table
                title={() => signatureQuery}
                pagination={pagination}
                size="small"
                rowKey={record => record.hubId}
                columns={columns}
                locale={{ emptyText: <FormattedMessage id={"no_result"} /> }}
                dataSource={signatureDataSource}
                expandedRowRender={
                    expanded
                        ? record => (
                              <p>
                                  <b>
                                      <FormattedMessage id="hubId" />
                                  </b>{" "}
                                  {record.hubId}
                                  <br />
                                  <b>
                                      <FormattedMessage id="batchId" />
                                  </b>{" "}
                                  {record.batchId}
                              </p>
                          )
                        : null
                }
                loading={signatureLoading}
                bordered={true}
                onChange={handleGetDocuments}
            />
        </div>
    );
};

CompanySignatureTable.propTypes = {
    signatureAuthorized: PropTypes.bool.isRequired,
    handleLoadItem: PropTypes.func.isRequired,
    signatureQuery: PropTypes.any,
    pagination: PropTypes.object.isRequired,
    signatureDataSource: PropTypes.array.isRequired,
    expanded: PropTypes.bool.isRequired,
    signatureLoading: PropTypes.bool.isRequired,
    handleGetDocuments: PropTypes.func.isRequired,
    documentTypes: PropTypes.array.isRequired,
    documentStatuses: PropTypes.array.isRequired
};
