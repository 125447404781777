import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { addLocaleData, IntlProvider } from "react-intl";
import itLocaleData from "react-intl/locale-data/it";
import { ThemeProvider } from "@mondora/vrc";
import { PersistGate } from "redux-persist/integration/react";

import messages from "../i18n/";

import store from "../reducers";
import RootView from "./root";
import LoginView from "./login";
import ForgotPasswordView from "./forgot-password";
import ResetPasswordView from "./reset-password";
import ResetPasswordSuccess from "./update-password-success";
import Toast from "./toast";
import AutoReload from "../components/general/auto-reload";
import { SpinContainer } from "../styled";
import { Spin } from "antd";
import { persistor } from "../reducers/index";

addLocaleData(itLocaleData);

class Routes extends Component {
    changePage() {
        localStorage.setItem("changedPage", true);
    }

    render() {
        return (
            <Provider store={store}>
                <IntlProvider locale="it" timeZone="it-IT" messages={{ ...messages["it"] }}>
                    <PersistGate
                        loading={
                            <SpinContainer>
                                <Spin size="large" />
                            </SpinContainer>
                        }
                        persistor={persistor}
                    >
                        <HashRouter>
                            <ThemeProvider>
                                <Switch>
                                    <Route path="/login" component={LoginView} />
                                    <Route
                                        component={ResetPasswordView}
                                        exact
                                        name="password-reset"
                                        path="/resetPassword/utenza/:userId/token/:token"
                                    />
                                    <Route component={ResetPasswordSuccess} exact path="/resetPassword/success" />
                                    <Route component={ForgotPasswordView} exact path="/forgotPassword" />
                                    <Route path="/:menuItem?" component={RootView} />
                                </Switch>
                                <Toast />
                                <AutoReload />
                            </ThemeProvider>
                        </HashRouter>
                    </PersistGate>
                </IntlProvider>
            </Provider>
        );
    }
}

export default Routes;
