import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { Button, Row, Col, Select } from "antd";
import FormSelect from "../../general/select/form-select";

const Option = Select.Option;

class FormClassifier extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        formIsLocked: PropTypes.bool
    };

    renderForm() {
        const { handleSubmit, formIsLocked } = this.props;

        let classifierOptions = [];

        classifierOptions.push(
            <Option key={"COMPANY"} value={"COMPANY"}>
                {"AZIENDA"}
            </Option>
        );
        classifierOptions.push(
            <Option key={"STUDIO"} value={"STUDIO"}>
                {"STUDIO"}
            </Option>
        );

        return (
            <form onSubmit={handleSubmit}>
                <Row gutter={8}>
                    <Col span={19}>
                        <Field name="classifier" component={FormSelect} disabled={formIsLocked} label="Tipologia">
                            {classifierOptions}
                        </Field>
                    </Col>
                    <Col span={5}>
                        <Button disabled={formIsLocked} type="primary" htmlType="submit" className="adj-button">
                            {"Salva"}
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }

    render() {
        return <div>{this.renderForm()}</div>;
    }
}

export default reduxForm({
    form: "form-classifier",
    enableReinitialize: true
})(FormClassifier);
