import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Col, Button } from "antd";
import TextField from "@mondora/arc/antd/TextField";
import { SelectStringField } from "@mondora/arc/antd/SelectField";
import { form } from "@mondora/conv-redux-form";

import DataAddress from "../../form/data-address";

import { layerSchema } from "../../../../utils/form-schema/layer";
import { b2bCode, classifierLayerTypes } from "../../../../utils/constant";

const govCodes = b2bCode.map(p => {
    return {
        value: p.id,
        label: p.name,
        searchMatcherString: p.name
    };
});

class LayerForm extends Component {
    static propTypes = {
        action: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        initialValues: PropTypes.object,
        onCancel: PropTypes.func.isRequired
    };

    render() {
        const { handleSubmit, initialValues, onCancel, action } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Row gutter={16}>
                    <Col span={action === "create" ? 24 : 12}>
                        <SelectStringField
                            label="Tipologia"
                            placeholder="Tipologia"
                            name="classifier"
                            options={classifierLayerTypes}
                        />
                    </Col>
                    {action === "edit" ? (
                        <Col span={12}>
                            <SelectStringField
                                label="Codice ufficio fatturazione elettronica"
                                name="govCode"
                                options={govCodes}
                                showSearch={true}
                                disabled={initialValues.id && initialValues.id.includes("-000")}
                            />
                        </Col>
                    ) : null}
                </Row>
                <TextField label="Descrizione" name="description" />
                <DataAddress disabled={true} />
                <div style={{ textAlign: "right" }}>
                    <Button onClick={onCancel} style={{ margin: "0px 10px" }}>
                        Annulla
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </form>
        );
    }
}

const formDefinition = {
    form: "layer-form",
    schema: layerSchema,
    initialValues: {
        country: "IT"
    },
    enableReinitialize: true
};

export default form(formDefinition)(LayerForm);
