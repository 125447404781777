import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Dropdown, Menu, Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { SignatureConfigExpanded } from "../signature-config-expanded";
import { SignaturePermitsDetails } from "../signature-permits-detail";
import { DocumentPermitsDetails } from "../document-permits-detail";

export const SignaturesUserConfig = ({
    auth,
    company,
    getSignerPermission,
    getDocumentTypePermission,
    signaturesConfigList
}) => {
    const [showAdvancedDocPermission, setShowAdvancedDocPermission] = useState(false);

    const [showAdvancedSignerPermission, setShowAdvancedSignerPermission] = useState(false);
    const [documentTypesRoles, setDocumentTypesRoles] = useState([]);
    const [signerRoles, seSignerRoles] = useState([]);
    const [documentTypeListAllowed, setDocumentTypeListAllowed] = useState([]);
    const [signerListAllowed, seSignerListAllowed] = useState([]);

    const handleLoadDocPermission = async user => {
        let roles = [];
        setShowAdvancedDocPermission(true);
        const result = await getDocumentTypePermission(auth.loginAuth, company.info.base.id, user);
        if (result) {
            roles.push(result);
            setDocumentTypesRoles(roles[0]);
            let allowed = [];
            if (!roles[0].allAllowed) {
                roles[0].data.forEach(element => {
                    allowed.push(element);
                });
                setDocumentTypeListAllowed(allowed);
            }
        }
    };

    const handleLoadSignerPermission = async user => {
        let roles = [];
        setShowAdvancedSignerPermission(true);
        const result = await getSignerPermission(auth.loginAuth, company.info.base.id, user);
        if (result) {
            roles.push(result);
            seSignerRoles(roles[0]);
            let allowed = [];
            if (!roles[0].allAllowed) {
                roles[0].data.forEach(element => {
                    allowed.push(element);
                });
                seSignerListAllowed(allowed);
            }
        }
    };

    const columns = [
        {
            title: <FormattedMessage id="general.user" />,
            dataIndex: "id",
            key: "id"
        },
        {
            title: <FormattedMessage id="general.firstName" />,
            dataIndex: "firstName",
            key: "firstName"
        },
        {
            title: <FormattedMessage id="general.lastName" />,
            dataIndex: "lastName",
            key: "lastName"
        },
        {
            title: <FormattedMessage id="actions" />,
            width: "5%",
            dataIndex: "actions",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="0" onClick={() => handleLoadDocPermission(record.id)}>
                                    <i className="fas fa-info-circle" /> <FormattedMessage id={"advanced_doc_roles"} />
                                </Menu.Item>
                                <Menu.Item key="1" onClick={() => handleLoadSignerPermission(record.id)}>
                                    <i className="fas fa-info-circle" />{" "}
                                    <FormattedMessage id={"advanced_signer_roles"} />
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    return (
        <div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={signaturesConfigList || []}
                size="middle"
                expandedRowRender={(record, index) => (
                    <SignatureConfigExpanded key={index} dataSource={record.functionality[0]} />
                )}
                pagination={true}
            />
            <br></br>
            <Modal
                centered
                footer={null}
                onCancel={() => setShowAdvancedSignerPermission(false)}
                title={<FormattedMessage id={"advanced_signer_roles"} />}
                visible={showAdvancedSignerPermission}
            >
                {signerRoles.allAllowed ? (
                    <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id={"view_all_signers"} />
                    </span>
                ) : (
                    <SignaturePermitsDetails signersPermits={signerListAllowed} />
                )}
            </Modal>

            <Modal
                centered
                title={<FormattedMessage id={"advanced_doc_roles"} />}
                onCancel={() => setShowAdvancedDocPermission(false)}
                footer={null}
                visible={showAdvancedDocPermission}
            >
                <div>
                    {documentTypesRoles.allAllowed ? (
                        <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id={"view_all_docTypes"} />
                        </span>
                    ) : (
                        <DocumentPermitsDetails documentsPermits={documentTypeListAllowed} />
                    )}
                </div>
            </Modal>
        </div>
    );
};

SignaturesUserConfig.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getSignerPermission: PropTypes.func.isRequired,
    getDocumentTypePermission: PropTypes.func.isRequired,
    handleLoadPermission: PropTypes.func.isRequired,
    signatureConfigList: PropTypes.array
};
