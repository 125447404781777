import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, Input, Row, Col } from "antd";

import TsModal from "../../../components/general/ts-modal";
import { usersValidation } from "../../../utils/validation/user/fields-validation";
import { searchUser } from "../../../actions/search/user";

export const CreateNewUserModal = ({ auth, searchUser, visible, onCancel, onSubmit }) => {
    const [values, setValues] = useState({});
    const [validateStatus, setValidateStatus] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [okDisable, setOkDisable] = useState(true);

    const handleChangeValue = async target => {
        let newValues = { ...values };
        let newValidateStatus = { ...validateStatus };
        let newErrorMessage = { ...errorMessage };
        if (target.id === "firstName") {
            newValues.firstName = target.value;
            newErrorMessage.firstName = usersValidation(newValues).firstName;
            newValidateStatus.firstName = newErrorMessage.firstName ? "error" : "success";
        } else if (target.id === "lastName") {
            newValues.lastName = target.value;
            newErrorMessage.lastName = usersValidation(newValues).lastName;
            newValidateStatus.lastName = newErrorMessage.lastName ? "error" : "success";
        } else if (target.id === "description") {
            newValues.description = target.value;
            newErrorMessage.description = usersValidation(newValues).description;
            newValidateStatus.description = newErrorMessage.description ? "error" : "success";
        } else if (target.id === "email") {
            newValues.email = target.value;
            newErrorMessage.email = usersValidation(newValues).email;

            if (!newErrorMessage.email) {
                newValidateStatus.email = "validating";
                setErrorMessage(newErrorMessage);
                setValidateStatus(newValidateStatus);
                setOkDisable(true);
                let resultEmail = await searchUser(auth.loginAuth, newValues.email);
                if (resultEmail) {
                    newErrorMessage.email = "Utente già registrato";
                    newValidateStatus.email = "error";
                } else {
                    newErrorMessage.email = null;
                    newValidateStatus.email = "success";
                }
            } else {
                newValidateStatus.email = "error";
            }
        }
        setValues(newValues);
        setErrorMessage(newErrorMessage);
        setValidateStatus(newValidateStatus);
        setOkDisable(
            newValidateStatus.firstName === "success" &&
                newValidateStatus.lastName === "success" &&
                newValidateStatus.description === "success" &&
                newValidateStatus.email === "success"
                ? false
                : true
        );
    };

    return (
        <TsModal
            onCancel={onCancel}
            onOk={() => onSubmit(values)}
            onOkDisabled={okDisable}
            title={
                <span>
                    <FormattedMessage id="create_user" />
                </span>
            }
            visible={visible}
            cancelTextId="general.onCancel"
            okTextId="general.save"
            width="50%"
        >
            <Form layout="vertical" onChange={e => handleChangeValue(e.target)}>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            required={true}
                            hasFeedback
                            validateStatus={validateStatus.firstName}
                            help={errorMessage.firstName}
                        >
                            <Input id="firstName" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Cognome"
                            required={true}
                            hasFeedback
                            validateStatus={validateStatus.lastName}
                            help={errorMessage.lastName}
                        >
                            <Input id="lastName" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            required={true}
                            hasFeedback
                            validateStatus={validateStatus.email}
                            help={errorMessage.email}
                        >
                            <Input id="email" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Descrizione"
                            required={true}
                            hasFeedback
                            validateStatus={validateStatus.description}
                            help={errorMessage.description}
                        >
                            <Input id="description" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </TsModal>
    );
};

CreateNewUserModal.propTypes = {
    auth: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = {
    searchUser
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    )
);

export default composedHoc(CreateNewUserModal);
