import {
    GET_NOTIFICATIONS_START,
    GET_NOTIFICATIONS_ERROR,
    GET_NOTIFICATIONS_SUCCESS,
    GET_COMPANY_USERS_NOTIFICATIONS_START,
    GET_COMPANY_USERS_NOTIFICATIONS_SUCCESS,
    GET_COMPANY_USERS_NOTIFICATIONS_ERROR
} from "../../actions/company/notifications";
const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function settings(state = defaultState, { type, payload, error }) {
    switch (type) {
        default:
            return state;
    }
}

export function notificationList(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_NOTIFICATIONS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_NOTIFICATIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function companyUsersNotification(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_COMPANY_USERS_NOTIFICATIONS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_COMPANY_USERS_NOTIFICATIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                users: payload.items,
                pagination: payload.pagination
            };
        case GET_COMPANY_USERS_NOTIFICATIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
