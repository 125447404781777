import React from "react";

export const HackerMonitorIframe = () => {
    const hackerMonitorPageUrl = "https://threatmap.checkpoint.com/";

    return (
        <iframe
            id="ts_digital"
            title="Monitor attacchi hacker"
            src={hackerMonitorPageUrl}
            style={{
                right: 10,
                top: 150,
                width: "90%",
                height: "80%",
                position: "absolute",
                zIndex: "1"
            }}
        />
    );
};

HackerMonitorIframe.propTypes = {};
