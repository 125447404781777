import { API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_PREFERENCES_START = "GET_PREFERENCES_START";
export const GET_PREFERENCES_SUCCESS = "GET_PREFERENCES_SUCCESS";
export const GET_PREFERENCES_ERROR = "GET_PREFERENCES_ERROR";

export const getPreferences = (auth, ncsId, groupId) => async dispatch => {
    let urlParams = { groupId };
    dispatch({
        type: GET_PREFERENCES_START,
        request: urlParams
    });

    try {
        const result = await getRestCall(
            `${API_URL}/subjects/${ncsId}/preferences`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_PREFERENCES_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_PREFERENCES_ERROR,
            error: e
        });
    }
};
