import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Table } from "antd";
import { Searchbar } from "@mondora/arc/antd/Searchbar";

import { getInvoice } from "../../../actions/invoice/read";
import { setDocument } from "../../../actions/document/info";
import { displayErrorToast } from "../../../actions/toast";
import { ActionIcon } from "../../../styled";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

class SdiIdSearch extends Component {
    constructor() {
        super();
        this.state = {
            invoicesList: false
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        displayErrorToast: PropTypes.func.isRequired,
        getInvoice: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        invoices: PropTypes.object.isRequired,
        setDocument: PropTypes.func.isRequired
    };

    componentDidUpdate(prevProps) {
        const { invoices } = this.props;
        if (!prevProps.invoices._embedded && invoices._embedded) {
            if (invoices._embedded.invoiceList.length === 1) {
                let invoiceHubId = invoices._embedded.invoiceList[0].hubId;
                this.getInvoiceData(invoiceHubId);
            } else {
                this.setState({ invoicesList: true });
            }
        }
    }

    getInvoiceData(hubId) {
        const { auth, getInvoice, history, setDocument } = this.props;
        getInvoice(auth.loginAuth, { hubId: hubId }).then(res => {
            setDocument(res);
            history.push(`/dashboard/invoice/${hubId}/`);
        });
    }

    onSearch(searchValue) {
        const { auth, getInvoice, displayErrorToast } = this.props;

        if (searchValue.length >= 6) {
            getInvoice(auth.loginAuth, { sdiId: searchValue.trim() }, 0, 10).then(response => {
                if (response == null || response.page == null || response.page.size === 0) {
                    displayErrorToast("SdiId inserito non trovato.", true);
                    this.setState({ invoicesList: false });
                }
            });
        } else {
            this.setState({ invoicesList: false });
            displayErrorToast("SdiId inserito non valido.", true);
        }
    }

    render() {
        const { invoices } = this.props;
        const { invoicesList } = this.state;
        const columns = [
            {
                title: "Tipologia",
                dataIndex: "active",
                render: value => (value ? "Attiva" : "Passiva")
            },
            {
                title: "HubId",
                dataIndex: "hubId"
            },
            {
                render: (value, record) => (
                    <ActionIcon
                        icon={faArrowRight}
                        kind="secondary"
                        onClick={() => this.getInvoiceData(record.hubId)}
                    />
                )
            }
        ];

        return (
            <div className="c-sdiId-search">
                <Searchbar placeholder="Inserisci SdiId" onSearch={v => this.onSearch(v.value.trim())} enterButton />
                {invoicesList && (
                    <div>
                        <br />
                        <Table
                            rowKey={record => record.hubId}
                            columns={columns}
                            dataSource={invoices._embedded && invoices._embedded.invoiceList}
                            pagination={false}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    invoices: state.invoice.read.getInvoice
});
const actions = { displayErrorToast, getInvoice, setDocument };

export default connect(
    mapStateToProps,
    actions
)(SdiIdSearch);
