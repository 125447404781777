import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideToast } from "../../actions/toast";
import { message } from "antd";

class Toast extends Component {
    static propTypes = {
        hideToast: PropTypes.func.isRequired,
        toast: PropTypes.object.isRequired
    };
    showToast() {
        const { hideToast, toast } = this.props;

        message.config({
            top: 64
        });
        if (toast.type !== "error") {
            message.success(toast.message, 5, hideToast);
        } else {
            let textMessage =
                toast.message === "" ? "Errore durante l'esecuzione dell'operazione" : "Errore: " + toast.message;
            message.error(textMessage, 5, hideToast);
        }
    }

    componentDidUpdate() {
        if (this.props.toast.show) {
            this.showToast();
        }
    }

    render() {
        return <div className="c-toast-container" />;
    }
}

function mapStateToProps(state) {
    return {
        toast: state.toast
    };
}

export default connect(
    mapStateToProps,
    { hideToast }
)(Toast);
