import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { userIsAuthorized } from "../../../utils/get-roles";
import { Dropdown, Menu } from "antd";
import { ContainerAttached, ContainerMessage, ContainerTitle, ActionCard } from "./styled";

export const InvoiceInfoAttached = ({
    admin,
    documentInfo,
    lstAttachments,
    replayUsageInvoiceNotification,
    showMessage,
    displayErrorToast
}) => {
    const download = element => {
        try {
            let base64 = element.content;
            let name = element.name;
            let download = document.createElement("a");
            download.setAttribute("href", "data:application/octet-stream;charset=utf-16le;base64," + base64);
            download.setAttribute("download", name);
            download.click();
        } catch (e) {
            displayErrorToast("Impossibile scaricare il file xml", true);
        }
    };

    return (
        <div>
            <ContainerAttached>
                <ContainerTitle>
                    <FormattedMessage id={"attachments"} />
                </ContainerTitle>
                <br />
                {lstAttachments ? (
                    lstAttachments.map(attachment => (
                        <ActionCard key={attachment.name} onClick={() => download(attachment)}>
                            <i className="fas fa-paperclip attachment-icon" style={{ marginRight: "8px" }} />
                            <span>{attachment.name}</span>
                            <br />
                        </ActionCard>
                    ))
                ) : (
                    <div>
                        <span>
                            <FormattedMessage id={"missing_attachments"} />
                        </span>
                        <br />
                    </div>
                )}
                <br />
            </ContainerAttached>
            <ContainerMessage>
                <ContainerTitle>
                    <FormattedMessage id={"messages"} />
                </ContainerTitle>
                <br />
                {documentInfo.message.messages && documentInfo.message.messages.length > 0 ? (
                    documentInfo.message.messages.map(message => (
                        <Dropdown
                            key={message.description}
                            overlay={
                                <Menu style={{ width: "200px" }}>
                                    <Menu.Item key="1" onClick={() => download(message)}>
                                        <i className="fas fa-download" style={{ marginRight: "8px" }} />
                                        <FormattedMessage id={"download"} />
                                    </Menu.Item>
                                    <Menu.Item
                                        key="2"
                                        onClick={() => {
                                            showMessage(message);
                                        }}
                                    >
                                        <i className="fas fa-search" style={{ marginRight: "8px" }} />
                                        <FormattedMessage id={"preview"} />
                                    </Menu.Item>
                                    {userIsAuthorized("c-invoice-notification-replay", admin.adminInfo.permits) ? (
                                        <Menu.Item key="3" onClick={() => replayUsageInvoiceNotification()}>
                                            <i className="fas fa-sync-alt" style={{ marginRight: "8px" }} />
                                            <FormattedMessage id={"reload_notifications"} />
                                        </Menu.Item>
                                    ) : (
                                        ""
                                    )}
                                </Menu>
                            }
                        >
                            <ActionCard key={message.name}>
                                <i className="fas fa-envelope-open message-icon" style={{ marginRight: "8px" }} />
                                <span>{message.description}</span>
                                <br />
                            </ActionCard>
                        </Dropdown>
                    ))
                ) : (
                    <div>
                        {userIsAuthorized("c-invoice-notification-replay", admin.adminInfo.permits) ? (
                            <Dropdown
                                key="no-message"
                                overlay={
                                    <Menu style={{ width: "200px" }}>
                                        {userIsAuthorized("c-invoice-notification-replay", admin.adminInfo.permits) ? (
                                            <Menu.Item key="1" onClick={() => replayUsageInvoiceNotification()}>
                                                <i className="fas fa-sync-alt" style={{ marginRight: "8px" }} />
                                                <FormattedMessage id={"reload_notifications"} />
                                            </Menu.Item>
                                        ) : (
                                            ""
                                        )}
                                    </Menu>
                                }
                            >
                                <div>
                                    <span>
                                        <FormattedMessage id={"missing_message"} />
                                        <i className="fas fa-sync-alt" style={{ marginLeft: "8px" }} />
                                    </span>
                                    <br />
                                </div>
                            </Dropdown>
                        ) : (
                            <div>
                                <span>
                                    <FormattedMessage id={"missing_message"} />{" "}
                                    {userIsAuthorized("c-invoice-notification-replay", admin.adminInfo.permits) ? (
                                        <i className="fas fa-sync-alt" />
                                    ) : (
                                        ""
                                    )}
                                </span>{" "}
                                <br />
                            </div>
                        )}
                    </div>
                )}
            </ContainerMessage>
        </div>
    );
};

InvoiceInfoAttached.propTypes = {
    admin: PropTypes.object.isRequired,
    documentInfo: PropTypes.object.isRequired,
    lstAttachments: PropTypes.any,
    replayUsageInvoiceNotification: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    displayErrorToast: PropTypes.func.isRequired
};
