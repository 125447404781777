import { METERING_USAGE_INVOICING } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_USAGE_PERMS_START = "GET_USAGE_PERMS_START";
export const GET_USAGE_PERMS_SUCCESS = "GET_USAGE_PERMS_SUCCESS";
export const GET_USAGE_PERMS_ERROR = "GET_USAGE_PERMS_ERROR";

export function getUsagePerms(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_USAGE_PERMS_START,
            request: itemId
        });

        try {
            const result = await getRestCall(
                `${METERING_USAGE_INVOICING}/${itemId}/permissions`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_USAGE_PERMS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_USAGE_PERMS_ERROR,
                error: e
            });
        }
    };
}
