import { API_URL } from "../../../config";
import { getRestCall, putRestCall } from "../../../utils/rest-api-call";

const TEMPLATES = "templates";
const toast = {
    onOk: false,
    onOkText: "Operazione effetuata correttamente",
    onError: false,
    infoReq: null
};

export const UPDATE_TEMPLATE_START = "UPDATE_TEMPLATE_START";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_ERROR = "UPDATE_TEMPLATE_ERROR";

export const updateTemplate = (auth, notification) => async dispatch => {
    dispatch({
        type: UPDATE_TEMPLATE_START,
        request: notification
    });

    try {
        const result = await putRestCall(
            `${API_URL}/${TEMPLATES}`,
            auth,
            {},
            notification,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: UPDATE_TEMPLATE_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: UPDATE_TEMPLATE_ERROR,
            error: e
        });
    }
};

export const GET_TEMPLATE_START = "GET_TEMPLATE_START";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_ERROR = "GET_TEMPLATE_ERROR";

export const getTemplate = (auth, sentinel) => async dispatch => {
    dispatch({
        type: GET_TEMPLATE_START,
        request: null
    });

    try {
        let urlParams = { sentinel: sentinel ? "true" : "false" };

        const result = await getRestCall(
            `${API_URL}/${TEMPLATES}`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: GET_TEMPLATE_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_TEMPLATE_ERROR,
            error: e
        });
    }
};
