import { API_URL } from "../../config";
import { deleteRestCall } from "../../utils/rest-api-call";

export const DELETE_LINK_START = "DELETE_LINK_START";
export const DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS";
export const DELETE_LINK_ERROR = "DELETE_LINK_ERROR";

export function deleteLink(auth, connectionId) {
    return async dispatch => {
        dispatch({
            type: DELETE_LINK_START,
            request: connectionId
        });

        const toast = {
            onOk: true,
            onOkText: "Connessione cancellata correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: connectionId }
        };

        try {
            await deleteRestCall(
                `${API_URL}/connections/write/${connectionId}`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: DELETE_LINK_SUCCESS,
                payload: {
                    connectionId
                }
            });
        } catch (e) {
            dispatch({
                type: DELETE_LINK_ERROR,
                payload: { e, connectionId }
            });
        }
    };
}
