import React from "react";

import PropTypes from "prop-types";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageHeader from "../../../components/general/PageHeader/index";

import { ServiceStatusTSDigital } from "../../../components/service-status/ts-digital";

const ServiceStatusTSDigitalView = ({ match, history }) => {
    return (
        <div>
            <PageHeader history={history} labelId={`services_go_back`} />
            <ServiceStatusTSDigital match={match} />
        </div>
    );
};

ServiceStatusTSDigitalView.propTypes = {
    match: PropTypes.object.isRequired
};

const composedHoc = compose(
    injectIntl,

    errorBoundary
);
export default composedHoc(ServiceStatusTSDigitalView);
