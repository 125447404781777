import styled from "styled-components";

export const InfoContainer = styled.div`
    display: flex;
    margin-left: 12px;
    flex-flow: row;
`;
export const InfoTitle = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #005075;
`;
export const StatusContainer = styled.div`
    margin: 0px 48px;
`;
