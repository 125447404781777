import { getRestCall } from "../utils/rest-api-call";
import { API_URL } from "../config";

export const GET_REPORT_START = "GET_REPORT_START";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";

export const getColdirettiReport = auth => async dispatch => {
    dispatch({
        type: GET_REPORT_START
    });

    try {
        const result = await getRestCall(`${API_URL}/coldiretti-report`, auth, null, dispatch, auth.refreshToken);

        dispatch({
            type: GET_REPORT_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_REPORT_ERROR,
            error: e
        });
    }
};
