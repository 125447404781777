import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge, Spin, PageHeader } from "antd";

import "./style.css";

import {
    translateServiceCategory,
    translateServiceName,
    translateConnectionPermission,
    translateConnectionStatus,
    translateConnectionFeatureCode
} from "../../../../utils/translate";
import { PdfHandler } from "../../../../components/general/pdf-handler";

class ConnectionInfo extends Component {
    static propTypes = {
        connectionSelected: PropTypes.object,
        connectionService: PropTypes.object,
        closeInfo: PropTypes.func.isRequired,
        contractContent: PropTypes.string,
        showPdf: PropTypes.bool
    };

    downloadContract(nameFile, file) {
        var download = document.createElement("a");
        download.setAttribute("href", "data:application/octet-stream;charset=utf-8;base64," + file);
        download.setAttribute("download", nameFile);
        download.click();
    }

    render() {
        const { closeInfo, connectionSelected, connectionService, contractContent, showPdf } = this.props;

        return connectionService ? (
            <div>
                <PageHeader onBack={() => closeInfo()} title="Torna alla lista " style={{ margin: "-22px" }} />
                <div className="c-company-connection-contract">
                    <div className="page">
                        <div className="info">
                            <div>
                                <p>Info</p>
                                <span className="field-labels">Id:</span> {connectionService.id}
                                <br />
                                <span className="field-labels">Gestore: </span>
                                {connectionSelected.managerDescription + "(" + connectionSelected.managerCf + ")"}
                                <br />
                                <span className="field-labels">Gestita: </span>{" "}
                                {connectionSelected.managedDescription + "(" + connectionSelected.managedCf + ")"}
                                <br />
                                <span className="field-labels">Servizio: </span>{" "}
                                {translateServiceName(connectionService.serviceId)}
                                <br />
                                <span className="field-labels">Categoria: </span>
                                {translateServiceCategory(connectionService.appId)}
                                <br />
                                <span className="field-labels">FeatureCode: </span>
                                {translateConnectionFeatureCode(connectionService.featureCode)}
                                <br />
                                <span className="field-labels">Permessi: </span>
                                {translateConnectionPermission(connectionService.permission)}
                            </div>
                            <br />
                            <div>
                                <p>Stato</p>
                                <span className="field-labels">Creata da: </span>{" "}
                                {connectionService.status.createdBy
                                    ? connectionService.status.createdBy
                                    : "Dato No disponibile"}
                                <br />
                                <span className="field-labels">Creata il: </span>{" "}
                                {connectionService.status.createdAt
                                    ? connectionService.status.createdAt
                                    : "Dato No disponibile"}
                                <br />
                                <span className="field-labels">Attiva: </span>{" "}
                                {connectionService.status.active ? "Si" : "No"}
                                <br />
                                <span className="field-labels">Attivata da: </span>{" "}
                                {connectionService.status.activatedBy
                                    ? connectionService.status.activatedBy
                                    : "Dato No disponibile"}
                                <br />
                                <span className="field-labels">Attivata il: </span>{" "}
                                {connectionService.status.activatedAt
                                    ? connectionService.status.activatedAt
                                    : "Dato No disponibile"}
                                <br />
                                <span className="field-labels">Modificata da: </span>{" "}
                                {connectionService.status.modifiedBy
                                    ? connectionService.status.modifiedBy
                                    : "Dato non disponibile"}
                                <br />
                                <span className="field-labels">Modificata il: </span>{" "}
                                {connectionService.status.modifiedAt
                                    ? connectionService.status.modifiedAt
                                    : "Dato non disponibile"}
                                <br />
                                <span className="field-labels">Cancellata: </span>{" "}
                                {connectionService.status.deleted ? "Si" : "No"}
                                <br />
                                {connectionService.status.deleted && (
                                    <div>
                                        <span className="field-labels">Cancellata da: </span>{" "}
                                        {connectionService.status.deletedBy
                                            ? connectionService.status.deletedBy
                                            : "Dato No disponibile"}
                                        <br />
                                        <span className="field-labels">Cancellata il: </span>
                                        {connectionService.status.deletedAt
                                            ? connectionService.status.deletedAt
                                            : "Dato No disponibile"}
                                        <br />
                                    </div>
                                )}
                                <span className="field-labels">Stato: </span>
                                <Badge
                                    text={translateConnectionStatus(connectionService.status.status).title}
                                    status={translateConnectionStatus(connectionService.status.status).status}
                                />
                            </div>
                        </div>

                        {showPdf && (
                            <div className="view">
                                {contractContent ? (
                                    <PdfHandler content={contractContent} fileName={"allegato_rc.pdf"} />
                                ) : (
                                    <div className="spin">
                                        <Spin />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ) : null;
    }
}

export default ConnectionInfo;
