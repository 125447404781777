import React, { Component } from "react";
import PropTypes from "prop-types";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { Descriptions, Drawer, Divider, Button, Row, Col, Input } from "antd";
import { FormattedMessage } from "react-intl";
import "./style.css";
import "./textEditor.css";

require("codemirror/lib/codemirror.css");
require("codemirror/mode/xml/xml.js");

class TemplateDrawer extends Component {
    static propTypes = {
        template: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        visible: PropTypes.bool.isRequired
    };

    state = { edit: false, template: this.props.template };

    onEdit = e => {
        if (this.state.edit === true) {
            this.props.onSave(e);
        }
        this.setState({
            edit: this.state.edit === true ? false : true
        });
    };

    render() {
        return (
            <div>
                <Drawer
                    width={!this.state.edit ? "40%" : "100%"}
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    <div style={{ marginBottom: 15, marginLeft: 15, marginTop: 15 }}>
                        <div className="title">
                            <FormattedMessage id="c-templatedrawer.preview" />
                        </div>
                        <div className="edit-button">
                            <Button
                                style={{ marginRight: 10 }}
                                type="primary"
                                icon="close"
                                onClick={() => {
                                    this.setState({
                                        edit: false
                                    });
                                    this.props.onClose();
                                }}
                            >
                                <FormattedMessage id="c-templatedrawer.cancel" />
                            </Button>
                            <Button
                                type="primary"
                                icon={this.state.edit ? "save" : "edit"}
                                onClick={e => {
                                    this.onEdit(e);
                                }}
                            >
                                {this.state.edit ? (
                                    <FormattedMessage id="c-templatedrawer.save" />
                                ) : (
                                    <FormattedMessage id="c-templatedrawer.edit" />
                                )}
                            </Button>
                        </div>
                        <Divider />
                        <Row>
                            {this.state.edit === true ? (
                                <Col span={12}>
                                    <Row>
                                        <Col span={2}>
                                            <div style={{ color: "black", paddingTop: "5px" }}>
                                                <FormattedMessage id="c-templatedrawer.title" />:
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <Input
                                                placeholder={<FormattedMessage id="c-templatedrawer.enterTitle" />}
                                                style={{ width: "80%" }}
                                                title={<FormattedMessage id="c-templatedrawer.title" />}
                                                defaultValue={this.state.template.title}
                                                onChange={e => {
                                                    let newTemplate = this.state.template;
                                                    newTemplate.title = e.target.value;
                                                    this.setState({
                                                        template: newTemplate
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                        <Col span={2}>
                                            <div style={{ color: "black", paddingTop: "5px" }}>
                                                <FormattedMessage id="c-templatedrawer.from" />:
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <Input
                                                placeholder={<FormattedMessage id="c-templatedrawer.enterSender" />}
                                                style={{ width: "80%" }}
                                                title={<FormattedMessage id="c-templatedrawer.from" />}
                                                defaultValue={this.state.template.from}
                                                onChange={e => {
                                                    let newTemplate = this.state.template;
                                                    newTemplate.from = e.target.value;
                                                    this.setState({
                                                        template: newTemplate
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <CodeMirror
                                        value={this.state.template.message.replace(/>/gi, ">\n")}
                                        options={{
                                            mode: "xml",
                                            theme: "material",
                                            lineNumbers: true
                                        }}
                                        autoCursor={false}
                                        onChange={e => {
                                            let newTemplate = this.state.template;
                                            newTemplate.message = e.getValue().replace(/>\n/gi, ">");
                                            this.setState({
                                                template: newTemplate
                                            });
                                        }}
                                    />
                                </Col>
                            ) : null}
                            <Col span={this.state.edit ? 12 : 24}>
                                <Descriptions>
                                    <Descriptions.Item label={<FormattedMessage id="c-templatedrawer.title" />}>
                                        {this.state.template != null ? this.state.template.title : null}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label={<FormattedMessage id="c-templatedrawer.from" />}>
                                        {this.state.template != null ? this.state.template.from : null}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label={<FormattedMessage id="c-templatedrawer.type" />}>
                                        {this.state.template != null ? this.state.template.type : null}
                                    </Descriptions.Item>
                                </Descriptions>
                                <iframe
                                    title={<FormattedMessage id="c-templatedrawer.message" />}
                                    srcDoc={this.state.template.message}
                                    className="message"
                                />
                            </Col>
                        </Row>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default TemplateDrawer;
