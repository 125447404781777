import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Input, Form, Checkbox, Alert, Radio } from "antd";
import { userIsAuthorized } from "../../../utils/get-roles";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
class EnableDisableService extends Component {
    constructor() {
        super();
        this.state = {
            serviceOwner: false,
            validateStatus: "",
            help: "",
            serviceMultiOwner: []
        };
    }
    static propTypes = {
        activateService: PropTypes.func,
        admin: PropTypes.object,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        closeEnableService: PropTypes.func,
        fetchActiveServices: PropTypes.func,
        getB2bPassiveConfig: PropTypes.func,
        getSdiConfig: PropTypes.func,
        getPrivacyConfig: PropTypes.func,
        searchB2bPassiveConfig: PropTypes.func,
        searchCompany: PropTypes.func,
        searchItems: PropTypes.func,
        searchSdiConfig: PropTypes.func,
        services: PropTypes.object.isRequired,
        serviceSelect: PropTypes.string,
        setCompanyOnwer: PropTypes.func,
        setExtraData: PropTypes.func,
        setOwnerId: PropTypes.func,
        isToEnable: PropTypes.bool,
        getServicesForCompanySeached: PropTypes.func.isRequired
    };

    componentDidMount() {
        const {
            auth,
            company,
            getB2bPassiveConfig,
            getPrivacyConfig,
            getSdiConfig,
            isToEnable,
            serviceSelect,
            setCompanyOnwer,
            services
        } = this.props;
        if (!isToEnable) {
            if ("SDI-FLOW" === serviceSelect) {
                getSdiConfig(auth.loginAuth, company.info.base.id);
            }
            if ("B2B-PASSIVE-FLOW" === serviceSelect) {
                getB2bPassiveConfig(auth.loginAuth, company.info.base.id);
            }
            if ("PRIVACY-FLOW" === serviceSelect) {
                getPrivacyConfig(auth.loginAuth, company.info.base.id);
            }
        } else {
            if ("B2B-PASSIVE-FLOW" === serviceSelect || "SDI-FLOW" === serviceSelect) {
                setCompanyOnwer(false);
            }
        }
        if (services && services.list && services.list.services && services.list.services.length) {
            let serviceMultiOwner = services.list.services
                .filter(element => element.maxActiveConnections && element.maxActiveConnections > 0)
                .map(filteredElement => {
                    return filteredElement.id;
                });
            this.setState({ serviceMultiOwner: serviceMultiOwner || [] });
        }
    }

    async handleChangeOwner(itemId) {
        const { auth, company, serviceSelect, setOwnerId, getServicesForCompanySeached } = this.props;

        if (company.info.base.id === itemId) {
            this.setState({ ownerId: company.info.base.id });
        } else {
            let ownerId = itemId;

            getServicesForCompanySeached(auth.loginAuth, ownerId).then(res => {
                if (res) {
                    let agyoService = res.find(x => x.agyoService.id === serviceSelect && x.readonly === false);
                    if (agyoService && agyoService.owner === true) {
                        this.setState({
                            help: "",
                            validateStatus: "success"
                        });
                        setOwnerId(ownerId);
                    } else {
                        this.setState({
                            help: "Servizio non attivo",
                            validateStatus: "error"
                        });
                    }
                } else {
                    this.setState({
                        help: "Servizio non attivo",
                        validateStatus: "error"
                    });
                }
            });
        }
    }

    renderServicesWithMoreOption() {
        const { serviceSelect, setCompanyOnwer, setExtraData, setOwnerId, company } = this.props;
        const { validateStatus, help, serviceOwner } = this.state;

        return (
            <div>
                {serviceSelect === "HBLE-ESM" && (
                    <div>
                        <br />
                        <span style={{ fontWeight: "500" }}>{"Pacchetti disponibili"}</span>
                        <br />
                        <Checkbox onChange={e => setExtraData("rtl", e.target.checked)} />
                        {" Real Time Monitoring"}
                        <br />
                        <Checkbox onChange={e => setExtraData("tem", e.target.checked)} />
                        {" Telecoms Expense Management"}
                    </div>
                )}
                {serviceSelect === "A-FISCALI-IVA-FLOW" && (
                    <div>
                        <br />
                        <span style={{ fontWeight: "500" }}>{"Pacchetti disponibili"}</span>
                        <br />
                        <Checkbox defaultChecked={true} disabled={true} />
                        {" Invio Iva (pacchetto incluso nel servizio)"}
                        <br />
                        <Checkbox onChange={e => setExtraData("signatureIva", e.target.checked)} />
                        {" Generazione certificato CA"}
                        <br />
                        <Checkbox onChange={e => setExtraData("consoleIva", e.target.checked)} />
                        {" Console Iva"}
                        <br />
                        <Checkbox onChange={e => setExtraData("multiSendIva", e.target.checked)} />
                        {" Invio multiplo"}
                    </div>
                )}
                {serviceSelect === "SIGNATURE-FLOW" && (
                    <div>
                        <span style={{ fontWeight: "500" }}>{"Pacchetti disponibili"}</span>
                        <br />
                        <Checkbox onChange={e => setExtraData("fra", e.target.checked)} />
                        {" Firma Remota Avanzata"}
                        <br />
                        <Checkbox onChange={e => setExtraData("fga", e.target.checked)} />
                        {" Firma Grafometrica Avanzata"}
                        <br />
                        <Checkbox onChange={e => setExtraData("frq", e.target.checked)} />
                        {" Firma Remota Qualificata"}
                        <br />
                        <Checkbox onChange={e => setExtraData("fmq", e.target.checked)} />
                        {" Firma Massiva Qualificata"}
                        <br />
                        <Checkbox onChange={e => setExtraData("signatureConsole", e.target.checked)} />
                        {" Firma da Console"}
                        <br />
                        <Checkbox onChange={e => setExtraData("signatureErp", e.target.checked)} />
                        {" Firma da Applicativo"}
                        <br />
                    </div>
                )}
                {serviceSelect === "SDI-FLOW" && (
                    <div>
                        <span style={{ fontWeight: "500" }}>{"Pacchetti disponibili"}</span>
                        <br />
                        <Checkbox onChange={e => setExtraData("fatturaSmart", e.target.checked)} />{" "}
                        {<FormattedMessage id="smart_invoice" />}
                        <br />
                        <br />
                    </div>
                )}
                {serviceSelect !== "A-FISCALI-IVA-FLOW" &&
                    serviceSelect !== "HBLE-ESM" &&
                    serviceSelect !== "SIGNATURE-FLOW" &&
                    serviceSelect !== "DIGITALBOX" && (
                        <div>
                            {"A chi vuoi addebitare il servizio? "}
                            <RadioGroup
                                onChange={e => {
                                    this.setState({
                                        serviceOwner: e.target.value
                                    });
                                    setOwnerId(company.info.base.id);
                                    this.setState({
                                        help: "",
                                        validateStatus: ""
                                    });
                                    setCompanyOnwer(e.target.value);
                                }}
                                value={serviceOwner}
                            >
                                <Radio value={false}>Azienda terza</Radio>
                                <Radio value={true}>Azienda selezionata</Radio>
                            </RadioGroup>
                            <br />
                            <br />
                            {serviceOwner ? (
                                <span>{"Sei sicuro di voler attivare il servizio?"}</span>
                            ) : (
                                <div>
                                    <span>
                                        {
                                            "Inserisci il codice fiscale oppure l'identificativo dell'azienda a cui vuoi addebitare il servizio"
                                        }
                                    </span>
                                    <FormItem hasFeedback={true} validateStatus={validateStatus} help={help}>
                                        <Input
                                            onChange={e => this.handleChangeOwner(e.target.value.trim())}
                                            size="small"
                                        />
                                    </FormItem>
                                </div>
                            )}
                        </div>
                    )}
            </div>
        );
    }

    render() {
        const { serviceSelect, isToEnable, admin } = this.props;
        const { serviceMultiOwner } = this.state;
        return isToEnable ? (
            <div className="c-add-service">
                {serviceSelect !== "PEOPLEAPP" ? (
                    <Alert
                        message={
                            <div>
                                <FormattedMessage id="c-meteringpackageinfo.message" />
                                {userIsAuthorized(
                                    "c-display-warning-enable-disable-services",
                                    admin.adminInfo.permits
                                ) ? (
                                    <div>
                                        <div>
                                            <FormattedMessage id="c-meteringpackageinfo.message.warning" />
                                        </div>
                                        <br></br>
                                        <div>
                                            <p style={{ fontWeight: "bold" }}>
                                                <FormattedMessage id="c-meteringpackageinfo.message.warning1" />
                                            </p>
                                        </div>
                                        <ul>
                                            <li>
                                                <FormattedMessage id="c-meteringpackageinfo.message.warning2" />
                                            </li>
                                            <br></br>
                                            <li>
                                                <FormattedMessage id="c-meteringpackageinfo.message.warning3" />
                                            </li>
                                            <br></br>
                                            <li>
                                                <FormattedMessage id="c-meteringpackageinfo.message.warning4" />
                                            </li>
                                            <br></br>
                                            <li>
                                                <FormattedMessage id="c-meteringpackageinfo.message.warning5" />
                                            </li>
                                            <br></br>
                                            <li>
                                                <FormattedMessage id="c-meteringpackageinfo.message.warning6" />
                                                <a href="https://teamsystem.atlassian.net/servicedesk/customer/portal/6">
                                                    Clicca qui
                                                </a>
                                            </li>
                                            <br></br>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        }
                        banner
                        style={{ margin: "-24px", marginBottom: "20px" }}
                    />
                ) : null}
                {serviceMultiOwner.includes(serviceSelect) ? (
                    this.renderServicesWithMoreOption()
                ) : (
                    <span>{"Sei sicuro di voler attivare il servizio?"}</span>
                )}
            </div>
        ) : (
            <div>
                <span>{"Sei sicuro di voler disattivare il servizio?"}</span>
            </div>
        );
    }
}

export default EnableDisableService;
