import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { OrderFooterContent, OrderReceivedTableSelect, OrderQuaryContent } from "./styled";

export const OrderFooter = ({ pageSize, disabled, handlePageSizeChange, pageSizeOptions, query }) => {
    return (
        <OrderFooterContent>
            <div style={{ textAlign: "right" }}>
                <span style={{ marginRight: 5 }}>
                    <FormattedMessage id={"article_per_page"} />:
                </span>
                <OrderReceivedTableSelect
                    defaultValue={pageSize}
                    optionFilterProp="children"
                    disabled={disabled}
                    onChange={handlePageSizeChange}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {pageSizeOptions}
                </OrderReceivedTableSelect>
            </div>
            <OrderQuaryContent>{query}</OrderQuaryContent>
        </OrderFooterContent>
    );
};

OrderFooter.propTypes = {
    pageSize: PropTypes.any,
    disabled: PropTypes.bool,
    handlePageSizeChange: PropTypes.func,
    pageSizeOptions: PropTypes.array,
    query: PropTypes.any
};
