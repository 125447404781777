import styled from "styled-components";

export const UsageConsumptionBarContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const UsageConsumptionBarDatePicker = styled.div`
    width: 20%;
    margin-left: 15px;
`;
