import { CCT_CONFIG_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_CCT_CONFIG_START = "GET_CCT_CONFIG_START";
export const GET_CCT_CONFIG_ERROR = "GET_CCT_CONFIG_ERROR";
export const GET_CCT_CONFIG_SUCCESS = "GET_CCT_CONFIG_SUCCESS";

export const getCCTConfig = (auth, itemId, ownerId, docType) => async dispatch => {
    dispatch({
        type: GET_CCT_CONFIG_START,
        request: itemId
    });

    try {
        const result = await getRestCall(
            `${CCT_CONFIG_API_URL}/api/v2/rest/getConfig`,
            auth,
            {
                itemId,
                ownerId,
                docType
            },
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_CCT_CONFIG_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_CCT_CONFIG_ERROR,
            error: e
        });
    }
};
