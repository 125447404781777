import React, { useState } from "react";
import PropTypes from "prop-types";

import { Table, Input, Empty, Select } from "antd";
import { Link } from "react-router-dom";
import { ActionIcon } from "../../../styled";
import { FormattedMessage } from "react-intl";
import TsModal from "../../general/ts-modal";

import { faArrowRight, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

const { Option } = Select;
//TODO da capire come sistemare il db per la colonna policyCode
const tableConfig = ({ handleFilter, setInfoModal }) => [
    {
        title: (
            <Select defaultValue={null} onChange={v => handleFilter(v, "policyType")}>
                <Option value="CONNECTION">AdA</Option>
                <Option value="ADV">AdV</Option>
                <Option value={null}>Tutti</Option>
            </Select>
        ),
        dataIndex: "policyCode",
        key: "policyCode"
    },
    {
        title: "Data richiesta",
        dataIndex: "createdAt",
        key: "createdAt",
        render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
    },
    {
        title: "Data update",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
    },
    {
        title: <Input onChange={v => handleFilter(v.target.value, "fiscalCode")} placeholder="Codice Fiscale" />,
        dataIndex: "id",
        key: "id"
    },
    {
        title: <Input onChange={v => handleFilter(v.target.value, "reasonCode")} placeholder="Ragione sociale" />,
        dataIndex: "description",
        key: "description"
    },
    {
        title: <Input onChange={v => handleFilter(v.target.value, "email")} placeholder="Email" />,
        dataIndex: "user",
        key: "user"
    },
    {
        dataIndex: "info",
        key: "info",
        width: "6%",
        render: (text, record) => (
            <span>
                {(record.status === "REJECTED" || record.status === "AWAITING_UPLOAD") && (
                    <ActionIcon
                        icon={faInfoCircle}
                        onClick={() => setInfoModal({ open: true, message: record.message })}
                    />
                )}
                <Link to={`/certifications/contract/${record.id}`}>
                    <ActionIcon icon={faArrowRight} />
                </Link>
            </span>
        )
    }
];

const DocumentsList = ({ handleFilter, handleShowSize, handlePage, total, list, loading, page }) => {
    const [infoModal, setInfoModal] = useState({ open: false, message: null });
    return (
        <div>
            <Table
                rowKey="id"
                locale={{
                    emptyText: <Empty description="Nessun dato da mostrare" />
                }}
                style={{ backgroundColor: "white" }}
                pagination={{
                    current: page,
                    total: total,
                    onChange: p => handlePage(p),
                    onShowSizeChange: (c, s) => handleShowSize(s),
                    pageSizeOptions: ["10", "25", "50"],
                    showSizeChanger: true
                }}
                loading={loading}
                columns={tableConfig({
                    handleFilter: (value, name) => handleFilter(value, name),
                    setInfoModal
                })}
                dataSource={list}
            />
            <TsModal
                title={<FormattedMessage id="general.rejectMessage" />}
                visible={infoModal.open}
                onCancel={() => setInfoModal({ open: false, message: null })}
                cancelTextId="general.close"
            >
                <span>{infoModal.message}</span>
            </TsModal>
        </div>
    );
};

DocumentsList.propTypes = {
    handleFilter: PropTypes.func.isRequired,
    handleShowSize: PropTypes.func.isRequired,
    handlePage: PropTypes.func.isRequired,
    list: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    status: PropTypes.any.isRequired,
    total: PropTypes.number
};

export default DocumentsList;
