import React, { Component } from "react";
import PropTypes from "prop-types";
import { contains } from "ramda";

import { Checkbox } from "antd";

import SignersView from "../../../views/signers-table";

class AFiscaliService extends Component {
    static propTypes = {
        active: PropTypes.object.isRequired,
        services: PropTypes.object.isRequired
    };

    renderAdempimenti() {
        const {
            active,
            services: { aFiscaliIva }
        } = this.props;

        return (
            <div>
                <div>
                    <span className="a1">{"Info Pacchetto"}</span>
                    <br />
                    <span>{"Acquistato il: " + new Date(Number(aFiscaliIva.aConfig.startDate)).toLocaleString()}</span>
                    <br />
                    <span className="a1">{"Pacchetti disponibili"}</span>
                    <br />
                    <div style={{ display: "flex" }}>
                        <Checkbox disabled checked={aFiscaliIva.aConfig.sendIva}>
                            {"Invio"}
                        </Checkbox>

                        <Checkbox disabled checked={aFiscaliIva.aConfig.signatureIva}>
                            {"Generazione certificato CA"}
                        </Checkbox>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Checkbox disabled checked={aFiscaliIva.aConfig.consoleIva}>
                            {"Console"}
                        </Checkbox>
                        <br />
                        <Checkbox disabled checked={aFiscaliIva.aConfig.multiSendIva}>
                            {"Invio multiplo"}
                        </Checkbox>
                        <br />
                        <Checkbox disabled checked={contains("CBCR-FLOW", active.services)}>
                            {"CBCR"}
                        </Checkbox>
                    </div>

                    <br />
                    <div>
                        <span className="a1">{"Lista certificati"}</span>
                        <SignersView signers={aFiscaliIva.aSigner} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <div>{this.renderAdempimenti()}</div>;
    }
}

export default AFiscaliService;
