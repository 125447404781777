import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../components/general/error-boundary";
import { displaySuccessToast, displayErrorToast } from "../../actions/toast";

import { setDocument } from "../../actions/document/info";
import { downloadContent, getInvoice } from "../../actions/invoice/read";
import { getMessage } from "../../actions/document/message";
import {
    getInvoiceUsage,
    invoiceDeadletter,
    reassignInvoiceUsage,
    deleteInvoiceUsage,
    invoiceUsagePackage,
    reassignInvoiceUsagePackage,
    replayInvoiceNotificationUsage
} from "../../actions/usage/invoice";

import { Card, Spin } from "antd";

import { InvoiceInfo } from "../../components/invoice-info";
import "./style.css";

const InvoiceInfoView = ({
    admin,
    auth,
    document,
    downloadContent,
    getMessage,
    getInvoiceUsage,
    invoiceDeadletter,
    history,
    deleteInvoiceUsage,
    reassignInvoiceUsage,
    invoiceUsagePackage,
    reassignInvoiceUsagePackage,
    replayInvoiceNotificationUsage,
    setDocument,
    displaySuccessToast,
    displayErrorToast,
    getInvoice,
    match: {
        params: { hubId }
    }
}) => {
    useEffect(() => {
        getInvoice(auth.loginAuth, { hubId: hubId }, 0, 10).then(result => {
            setDocument(result);
        });
    }, [auth.loginAuth, getInvoice, hubId, setDocument, document.infoD.hubId]);

    useEffect(() => {
        if (document.infoD.hubId) {
            downloadContent(auth.loginAuth, hubId);
            getMessage(auth.loginAuth, hubId);
            getInvoiceUsage(auth.loginAuth, hubId);
            invoiceDeadletter(auth.loginAuth, hubId);
        }
    }, [auth.loginAuth, document.infoD.hubId, downloadContent, getInvoiceUsage, getMessage, hubId, invoiceDeadletter]);

    return document.infoD.hubId ? (
        <div className="c-invoice-info">
            <Card className="content">
                <div onClick={() => history.goBack()} className="close">
                    <i className="far fa-times-circle close-icon" />
                </div>
                <InvoiceInfo
                    admin={admin}
                    auth={auth}
                    document={document}
                    invoiceDeadletter={invoiceDeadletter}
                    deleteInvoiceUsage={deleteInvoiceUsage}
                    reassignInvoiceUsage={reassignInvoiceUsage}
                    invoiceUsagePackage={invoiceUsagePackage}
                    reassignInvoiceUsagePackage={reassignInvoiceUsagePackage}
                    replayInvoiceNotificationUsage={replayInvoiceNotificationUsage}
                    history={history}
                    displaySuccessToast={displaySuccessToast}
                    displayErrorToast={displayErrorToast}
                />
            </Card>
        </div>
    ) : (
        <div
            style={{
                position: "absolute",
                left: "50%",
                top: "50%"
            }}
        >
            <Spin size="large" />
        </div>
    );
};

InvoiceInfoView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    document: PropTypes.object,
    downloadContent: PropTypes.func.isRequired,
    getMessage: PropTypes.func.isRequired,
    getInvoiceUsage: PropTypes.func.isRequired,
    invoiceDeadletter: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    invoices: PropTypes.object,
    deleteInvoiceUsage: PropTypes.func.isRequired,
    reassignInvoiceUsage: PropTypes.func.isRequired,
    invoiceUsagePackage: PropTypes.func.isRequired,
    reassignInvoiceUsagePackage: PropTypes.func.isRequired,
    replayInvoiceNotificationUsage: PropTypes.func.isRequired,
    setDocument: PropTypes.func.isRequired,
    displaySuccessToast: PropTypes.func.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    getInvoice: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    document: state.document,
    filterDocumets: state.form.filterDocumets,
    invoices: state.invoice.read.invoices
});

const actions = {
    downloadContent,
    getMessage,
    getInvoiceUsage,
    invoiceDeadletter,
    deleteInvoiceUsage,
    reassignInvoiceUsage,
    invoiceUsagePackage,
    reassignInvoiceUsagePackage,
    replayInvoiceNotificationUsage,
    setDocument,
    displaySuccessToast,
    displayErrorToast,
    getInvoice
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(InvoiceInfoView);
