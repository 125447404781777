import React from "react";
import PropTypes from "prop-types";

import { Empty, Popover, Icon } from "antd";
import { ENV, ELASTIC_INVOICE_URL } from "../../config";
import ItemSearch from "../tool/item-search";
import HubIdSearch from "../tool/hubId-search";
import SdiIdSearch from "../tool/sdiId-search";
import UsersSearch from "../tool/user-search";
import CertificationsSearch from "../tool/certification-search";
import CctProvisioningSearch from "../tool/cctProvisioning-search";
import SignHubIdSearch from "../tool/sign-hubId-search";
import BankLinkHubIdSearch from "../tool/bank-link-hubid-search";
import SpidIdSearch from "../tool/spid-id-search";
import { userIsAuthorized } from "../../utils/get-roles";
import { useFeature } from "../../utils/hook/use-feature";

import TsCard from "../general/ts-card";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCopy, faUser, faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import {
    faExclamationCircle,
    faIndustry,
    faLink,
    faUsers,
    faFileInvoice,
    faCertificate,
    faSignature,
    faMoneyCheckAlt,
    faChartBar
} from "@fortawesome/pro-solid-svg-icons";

import { getProfileInfo } from "../../utils/get-roles";

import { PointerIcon } from "../../styled";
import { TokenCopy, UserAvatar, UserTitle, UserIcon, Title, SubTitle, RightIcon, Cards, Body } from "./styled";

const Dashboard = ({
    admin: {
        adminInfo: {
            profile: adminProfile,
            permits: { isAdminUser, isSuperUser, isAdvancedUser, isExpertUser },
            permits
        }
    },
    auth,
    displaySuccessToast,
    history
}) => {
    const spidEnabled = useFeature("Spid_Enabled");
    return (
        <Body>
            <Cards>
                <TsCard
                    onlyCard={true}
                    content={
                        <div>
                            <Popover content={<FormattedMessage id="c-dashboard.copyToken" />}>
                                <TokenCopy
                                    text={auth.loginAuth.securityToken}
                                    onCopy={() => displaySuccessToast("Token utente copiato correttamente", true)}
                                >
                                    <PointerIcon icon={faCopy} />
                                </TokenCopy>
                            </Popover>
                            <UserTitle>
                                <UserAvatar size={56} background={getProfileInfo(permits).color}>
                                    <UserIcon icon={faUser} />
                                </UserAvatar>
                                {" " + adminProfile.firstName + " " + adminProfile.lastName}
                            </UserTitle>
                            <br />
                            <li>
                                <FormattedMessage id="general.email" />: {adminProfile.id}
                            </li>
                            <li>
                                <FormattedMessage id="general.role" />: {getProfileInfo(permits).text}
                            </li>
                            <br />
                            <Title>
                                <FontAwesomeIcon icon={faExclamationCircle} /> <FormattedMessage id="general.warning" />
                            </Title>
                            <Empty description="" />
                        </div>
                    }
                />
            </Cards>
            <Cards>
                <TsCard
                    withTitle={true}
                    title={
                        <span>
                            <FontAwesomeIcon icon={faIndustry} /> <FormattedMessage id="general.company" />
                        </span>
                    }
                    content={
                        <div>
                            <SubTitle>
                                <FormattedMessage id="c-dashboard.tsDigitalSearch" />
                            </SubTitle>
                            <ItemSearch history={history} />
                        </div>
                    }
                />
                <TsCard
                    withTitle={true}
                    title={
                        <span>
                            <FontAwesomeIcon icon={faUsers} /> <FormattedMessage id="general.users" />
                        </span>
                    }
                    content={<UsersSearch history={history} displayError={true} />}
                />
                <TsCard
                    withTitle={true}
                    title={
                        <span>
                            <FontAwesomeIcon icon={faCertificate} /> <FormattedMessage id="c-dashboard.certTitle" />
                        </span>
                    }
                    content={
                        <div>
                            <SubTitle>Ricerca su Certificazioni</SubTitle>
                            <CertificationsSearch history={history} />
                            <br />
                            <SubTitle>Ricerca CCT Provisioning </SubTitle>
                            <CctProvisioningSearch history={history} />
                        </div>
                    }
                />
            </Cards>
            <Cards>
                <TsCard
                    styled={{ minWidth: "500px" }}
                    withTitle={true}
                    title={
                        <span>
                            <FontAwesomeIcon icon={faFileInvoice} /> <FormattedMessage id="general.invoicing" />
                        </span>
                    }
                    content={
                        <div>
                            <SubTitle>Ricerca per HubId</SubTitle>
                            <HubIdSearch history={history} />
                            <br />
                            <SubTitle>Ricerca per SdiId </SubTitle>
                            <SdiIdSearch history={history} />
                            <br />
                            {(ENV === "preprod" || ENV === "master") &&
                            userIsAuthorized("c-sideMenu-service-status", permits) ? (
                                <SubTitle>
                                    <Icon component={() => <FontAwesomeIcon icon={faChartBar} />} />
                                    {"  "}
                                    <FormattedMessage id={"monitoring-invoice-dashboard"} />
                                    <RightIcon onClick={() => window.open(ELASTIC_INVOICE_URL)}>
                                        <PointerIcon icon={faLink} />
                                    </RightIcon>
                                </SubTitle>
                            ) : null}
                        </div>
                    }
                    action={() => history.push("/cct-provisioning/pending")}
                />

                <TsCard
                    withTitle={true}
                    title={
                        <span>
                            <FontAwesomeIcon icon={faSignature} /> <FormattedMessage id="general.sign" />
                        </span>
                    }
                    content={
                        <div>
                            <SubTitle>Ricerca per hubId</SubTitle>
                            <SignHubIdSearch history={history} />
                        </div>
                    }
                />

                {userIsAuthorized("c-company-dashboard-bank-link", permits) && (
                    <TsCard
                        withTitle={true}
                        title={
                            <span>
                                <FontAwesomeIcon icon={faMoneyCheckAlt} /> <FormattedMessage id="bank_link" />
                            </span>
                        }
                        content={
                            <div>
                                <SubTitle>Ricerca per hubId</SubTitle>
                                <BankLinkHubIdSearch history={history} />
                            </div>
                        }
                    />
                )}
                {spidEnabled && userIsAuthorized("c-company-dashboard-spid", permits) && (
                    <TsCard
                        withTitle={true}
                        title={
                            <span>
                                <FontAwesomeIcon icon={faUserCircle} /> <FormattedMessage id="c-spid.spid-search" />
                            </span>
                        }
                        content={
                            <div>
                                <SubTitle>Ricerca per spidId</SubTitle>
                                <SpidIdSearch history={history} />
                            </div>
                        }
                    />
                )}
            </Cards>
        </Body>
    );
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    displaySuccessToast: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default Dashboard;
