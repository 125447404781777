import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { isEmail } from "validator";
import { FormattedMessage, injectIntl } from "react-intl";
import { Spin, Icon, message } from "antd";

import { Searchbar } from "@mondora/arc/antd/Searchbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@mondora/vrc";

import { createApiKey, createUser } from "../../actions/user/write";
import { getUser } from "../../actions/user/read";
import { errorBoundary } from "../../components/general/error-boundary";
import TsCard from "../../components/general/ts-card";
import CreateNewUserModal from "../../components/user/create-new-user-modal";
import CreateNewKeyModal from "../../components/user/create-new-key-modal";
import { ShowAPIKeyModal } from "../../components/user/show-api-key-modal";
import { userIsAuthorized } from "../../utils/get-roles";
import { CenteredContainer, ViewTitle, SpinContainer } from "./styled";

const UserSearchView = ({ admin, auth, intl, getUser, createApiKey, createUser, history, user }) => {
    const [createNewUserModal, setCreateNewUserModal] = useState(false);
    const [createKeyModal, setCreateKeyModal] = useState(false);
    const [resultKeyModal, setResultKeyModal] = useState(false);
    const viewModel = useUserSearchView(auth.loginAuth, admin, intl, history, createUser, createApiKey, getUser);

    if (viewModel.loading) {
        return (
            <SpinContainer>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </SpinContainer>
        );
    }

    return (
        <div>
            <ViewTitle>
                <FormattedMessage id="general.users" />
            </ViewTitle>
            <TsCard
                withTitle={true}
                title={
                    <span>
                        <FontAwesomeIcon icon={faUsers} /> <FormattedMessage id="general.searchUsers" />
                    </span>
                }
                content={
                    <div>
                        <Searchbar
                            placeholder="Inserisci l'id dell'utente"
                            onSearch={v => viewModel.onSearch(v.value.trim())}
                            enterButton
                        />
                        <CenteredContainer>
                            <br />
                            {user.infoU.status.error && <p>Nessun utente trovato</p>}
                            <Button kind="secondary" size="small" onClick={() => setCreateNewUserModal(true)}>
                                Crea nuovo utente
                            </Button>
                            {viewModel.hasPermission && (
                                <Button kind="secondary" size="small" onClick={() => setCreateKeyModal(true)}>
                                    Crea api key
                                </Button>
                            )}
                        </CenteredContainer>
                    </div>
                }
            />
            <CreateNewUserModal
                onCancel={() => setCreateNewUserModal(false)}
                onSubmit={val => {
                    setCreateNewUserModal(false);
                    viewModel.createNewUser(val);
                }}
                visible={createNewUserModal}
            />
            <CreateNewKeyModal
                onCancel={() => setCreateKeyModal(false)}
                onSubmit={val => {
                    setCreateKeyModal(false);
                    viewModel.createNewKey(val);
                    setResultKeyModal(true);
                }}
                visible={createKeyModal}
            />
            <ShowAPIKeyModal
                onCancel={() => setResultKeyModal(false)}
                visible={resultKeyModal}
                apiKey={viewModel.apiKey}
            />
        </div>
    );
};

UserSearchView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    intl: PropTypes.object
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    user: state.user
});

const actions = {
    createApiKey,
    createUser,
    getUser
};

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(UserSearchView);

const useUserSearchView = (auth, admin, intl, history, createUser, createApiKey, getUser) => {
    const [loading, setLoading] = useState(false);
    const [apiKey, setApiKey] = useState(null);

    const createNewUser = async values => {
        setLoading(true);
        let userValues = {
            ...values,
            preferences: {
                language: "it-IT"
            }
        };
        let result = await createUser(auth, userValues, []);
        if (result && result.id) {
            setTimeout(() => {
                setLoading(false);
                history.push(`/user/${result.id}`);
            }, 1000);
        } else {
            setLoading(false);
        }
    };

    const createNewKey = async values => {
        setLoading(true);
        let res = await createApiKey(auth, values.description);
        setLoading(false);
        if (res) {
            setApiKey(res);
        }
    };

    const onSearch = async userSearched => {
        if (isEmail(userSearched) || userSearched.length >= 36) {
            setLoading(true);
            let user = await getUser(auth, userSearched);
            setLoading(false);
            if (user) {
                history.push(`/user/${userSearched}`);
            } else {
                message.error(
                    intl.formatMessage({
                        id: "can_not_find_user_inserted"
                    })
                );
            }
        } else {
            message.error(
                intl.formatMessage({
                    id: "user_id_inserted_is_not_valid"
                })
            );
        }
    };

    return {
        loading,
        createNewUser,
        createNewKey,
        apiKey,
        onSearch,
        hasPermission: userIsAuthorized("c-create-apiKey", admin.adminInfo.permits)
    };
};
