import { monthForTaxIdCodeControl } from "./constant";
import { countryValueWithCodice } from "@mondora/agyo-validations/lib/common/constants";

function ottieniConsonanti(str) {
    return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, "");
}
function ottieniVocali(str) {
    return str.replace(/[^AEIOU]/gi, "");
}

function calcolaNome(nome) {
    var codiceNome = ottieniConsonanti(nome);
    if (codiceNome.length >= 4) {
        codiceNome = codiceNome.charAt(0) + codiceNome.charAt(2) + codiceNome.charAt(3);
    } else {
        codiceNome += ottieniVocali(nome);
        codiceNome += "XXX";
        codiceNome = codiceNome.substr(0, 3);
    }
    return codiceNome.toUpperCase();
}
function calcolaCognome(cognome) {
    var codiceCognome = ottieniConsonanti(cognome);
    codiceCognome += ottieniVocali(cognome);
    codiceCognome += "XXX";
    codiceCognome = codiceCognome.substr(0, 3);

    return codiceCognome.toUpperCase();
}

export const checkTaxId = (fiscalCode, formValues) => {
    let cfYear = fiscalCode && fiscalCode.substr(6, 2);
    let cfDay = fiscalCode && fiscalCode.substr(9, 2);
    let cfPaese = fiscalCode && fiscalCode.substr(11, 4);
    let cfMonth = fiscalCode && fiscalCode.substr(8, 1);
    let cfName = fiscalCode && fiscalCode.substr(3, 3);
    let cfSurname = fiscalCode && fiscalCode.substr(0, 3);

    var date = new Date(formValues.birthDate);

    let day = formValues.birthDate && (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    let month = formValues.birthDate && date.getMonth() + 1;
    let year = formValues.birthDate && date.getFullYear();
    let name = formValues.firstName && calcolaNome(formValues.firstName);
    let surname = formValues.lastName && calcolaCognome(formValues.lastName);

    let codicePaese = null;
    if (formValues.birthCountry) {
        if (formValues.birthCountry.length === 4) {
            codicePaese = formValues.birthCountry;
        } else {
            let index = countryValueWithCodice.findIndex(x => x.codice === formValues.birthCountry);
            codicePaese = index === -1 ? null : countryValueWithCodice[index].id;
        }
    }

    let error = "Codice fiscale e dati inseriti non corrispondenti";

    if (fiscalCode && name && name !== cfName) {
        return error;
    }
    if (fiscalCode && surname && surname !== cfSurname) {
        return error;
    }

    if (fiscalCode && formValues.sex === "M" && formValues.birthDate && cfDay.toString() !== day.toString()) {
        return error;
    }

    if (
        fiscalCode &&
        formValues.gender === "F" &&
        formValues.birthDate &&
        cfDay.toString() !== (40 + parseInt(day, 10)).toString()
    ) {
        return error;
    }

    if (fiscalCode && formValues.birthDate && cfMonth !== monthForTaxIdCodeControl[month - 1]) {
        return error;
    }

    if (fiscalCode && formValues.birthDate && year.toString().substr(2, 4) !== cfYear.toString()) {
        return error;
    }

    if (formValues.birthCountry && codicePaese !== "ZXXX" && codicePaese !== "Z111" && codicePaese !== cfPaese) {
        return error;
    }
};
