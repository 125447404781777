import { SpidViewInfoBlock, SpidTitleInfo, SpidUserInfo } from "./styled.js";
import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

export const SpidDetailInfo = ({ spidInfo }) => {
    return (
        <div>
            <SpidTitleInfo>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.title" />
                {spidInfo.id}
            </SpidTitleInfo>
            <br></br>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.id" />
                {spidInfo.id}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.itemId" />
                {spidInfo.itemId}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.itemCf" />
                {spidInfo.itemCf}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.type" />
                {spidInfo.type}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.status" />
                {spidInfo.status}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.requestedAt" />
                {new Date(spidInfo.requestedAt).toLocaleString()}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.updatedAt" />
                {new Date(spidInfo.updatedAt).toLocaleString()}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.slotId" />
                {spidInfo.slotId}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.videoSlotId" />
                {spidInfo.videoSlotId}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.sessionId" />
                {spidInfo.sessionId}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.sessionLink" />
                {spidInfo.sessionLink}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.cie" />
                {spidInfo.cie ? "Si" : "No"}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.cns" />
                {spidInfo.cns ? "Si" : "No"}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.feq" />
                {spidInfo.feq ? "Si" : "No"}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.video" />
                {spidInfo.video ? "Si" : "No"}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.rao" />
                {spidInfo.rao ? "Si" : "No"}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.sessionExpirationDate" />
                {new Date(spidInfo.sessionExpirationDate).toLocaleString()}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.raoId" />
                {spidInfo.raoId}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.level" />
                {spidInfo.level}
            </SpidViewInfoBlock>
            <SpidViewInfoBlock>
                <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.detail.user" />
            </SpidViewInfoBlock>
            <SpidUserInfo>
                <SpidViewInfoBlock>
                    <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.user.id" />
                    {spidInfo.user.id}
                </SpidViewInfoBlock>
                <SpidViewInfoBlock>
                    <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.user.name" />
                    {spidInfo.user.name}
                </SpidViewInfoBlock>
                <SpidViewInfoBlock>
                    <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.user.surname" />
                    {spidInfo.user.surname}
                </SpidViewInfoBlock>
                <SpidViewInfoBlock>
                    <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.user.email" />
                    {spidInfo.user.email}
                </SpidViewInfoBlock>
                <SpidViewInfoBlock>
                    <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.user.taxId" />
                    {spidInfo.user.taxId}
                </SpidViewInfoBlock>
                <SpidViewInfoBlock>
                    <FormattedMessage id="c-spid.spid-list.detail.spid.drawer.user.ncsId" />
                    {spidInfo.user.ncsId}
                </SpidViewInfoBlock>
            </SpidUserInfo>
        </div>
    );
};

SpidDetailInfo.propTypes = {
    spidInfo: PropTypes.object.isRequired
};
