import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { form } from "@mondora/conv-redux-form";
import { injectIntl, FormattedMessage } from "react-intl";
import { itemPrivacyDataSchema } from "../../../../utils/form-schema/item-privacy-data";

import RadioForm from "../../../general/RadioForm";
import TextField from "@mondora/arc/antd/TextField";
import ISO8601DayField from "@mondora/arc/antd/ISO8601DayField";
import { SelectStringField } from "@mondora/arc/antd/SelectField";

import { Field } from "redux-form";
import { Row, Col } from "antd";
const roles = [{ id: "OWNR" }, { id: "LEGL" }, { id: "PROS" }, { id: "OTHE" }];

export class ItemPrivacyDataForm extends Component {
    constructor() {
        super();
        this.state = {
            type: null
        };
    }
    static propTypes = {
        handleSubmit: PropTypes.func,
        initialValues: PropTypes.object
    };

    render() {
        const { handleSubmit, intl } = this.props;

        return (
            <form onSubmit={handleSubmit} className="c-item-privacy-data">
                <Row gutter={12}>
                    <Col span={5}>
                        <TextField
                            name="firstName"
                            placeholder={intl.formatMessage({
                                id: "general.firstName"
                            })}
                            label={<FormattedMessage id="general.firstName" />}
                        />
                    </Col>
                    <Col span={5}>
                        <TextField
                            name="lastName"
                            placeholder={intl.formatMessage({
                                id: "general.lastName"
                            })}
                            label={<FormattedMessage id="general.lastName" />}
                        />
                    </Col>
                    <Col span={4}>
                        <SelectStringField
                            getOptionKeyFromValue={value => {
                                return value === "" ? undefined : value;
                            }}
                            label={<FormattedMessage id="general.role" />}
                            name="role"
                            options={roles.map(r => {
                                return {
                                    value: r.id,
                                    label: <FormattedMessage id={`general.roles.${r.id}`} />
                                };
                            })}
                        />
                    </Col>
                    <Col span={6}>
                        <TextField
                            name="documentPlace"
                            placeholder={intl.formatMessage({
                                id: "general.documentPlace"
                            })}
                            label={<FormattedMessage id="general.documentPlace" />}
                        />
                    </Col>

                    <Col span={4}>
                        <ISO8601DayField
                            name="documentDate"
                            displayFormat={"DD/MM/YYYY"}
                            label={<FormattedMessage id="general.documentDate" />}
                            placeholder={intl.formatMessage({
                                id: "general.documentDate"
                            })}
                        />
                    </Col>
                </Row>
                <span>
                    <FormattedMessage id="c-item-privacy-data-form.title" />
                </span>
                <Field
                    name="notificationConsent"
                    component={RadioForm}
                    label={<FormattedMessage id="c-item-privacy-data-form.notificationConsent" />}
                />
                <Field
                    name="thirdPartyNotificationConsent"
                    component={RadioForm}
                    label={<FormattedMessage id="c-item-privacy-data-form.thirdPartyNotificationConsent" />}
                />
                <Field
                    name="analyticsConsent"
                    component={RadioForm}
                    label={<FormattedMessage id="c-item-privacy-data-form.analyticsConsent" />}
                />
            </form>
        );
    }
}
const formDefinition = {
    form: "item-privacy-data-form",
    schema: itemPrivacyDataSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(ItemPrivacyDataForm);
