//TODO RINOMINARE QUESTO FILE

const config = {
    "c-app-card-document-signing": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-unassigned-invoices-assign-button": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-invoice-usage-reassign-invoice-usage": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-invoice-usage-delete-invoice-usage": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-invoice.usage.reassign-invoice-usage-package": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-invoice-notification-replay": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-user-badge-console": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-drawer-certifications": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-drawer-cct-provisioning": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-drawer-bank-link": {
        adminView: true,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: true
    },
    "c-control-pannel-tools-view": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-control-pannel-users-backoffice-view": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-certifications-view": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-certifications-managed-view": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-contract-view": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-adjustmets-form-email": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cardConnections-validate-menuItem": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyContract-reject-note": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyContract-actions": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-company-notifications-switch": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-dashboard-cerved-search": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-userBadge-session-error-list": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-user-activate-deactivate": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-user-clone-roles": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-user-delete-roles": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-user-reload-roles": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-user-add-roles-free-app-selection": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-add-roles-form-components": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "company-cards-forms": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyInvoices-getInvoice": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyInvoices-switch": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyInvoices-actions-column": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-userRoles-delete-role": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyUser-delete-role": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-validate-cct": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-contract-rejected": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-contract-validated": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-contract-suspended": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-dashboard-sign-doc": {
        adminView: false,
        techView: false,
        betaView: true,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companySignature-actions-column": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companySignature-actions-send-cct": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cert-signer-update": {
        adminView: true,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cert-reject-message": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-create-apiKey": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-companyOrders-switch": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "cp-control-pannel-dashboard": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "cp-control-pannel-users-backoffice": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "cp-control-pannel-notification": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "cp-control-pannel-tools": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "cp-control-pannel-sentinel": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "cp-control-pannel-app-name": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-company-dashboard-eligible-invoice": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: true,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-company-dashboard-bank-link": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: true,
        bankLinkAdminView: false
    },
    "enable-disable-service": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "m-share-package": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-config-button-1000": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: true,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-config-button-1000-unlock": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-config-prov-row-details": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-config-action-reset-pwd": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-cct-config-action-delete-rdc": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-usage-button": {
        adminView: true,
        techView: true,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-company-edit-button-unlock": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-company-invoices-delete-button": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "add-exemption-flow": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-company-layer-add": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-drawer-report": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false,
        greenView: true
    },
    "c-sideMenu-service-status": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },

    "c-download-report": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false,
        greenView: true
    },
    "c-display-warning-enable-disable-services": {
        adminView: true,
        techView: true,
        betaView: true,
        advancedView: true,
        superView: false,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: true,
        bankLinkView: true,
        bankLinkAdminView: true
    },
    "c-user-enabled-delete-connection": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-drawer-nso-relaod": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-activate-readonly-signature": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-download-consumptions": {
        adminView: true,
        techView: false,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "m-assign-credits": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "enable-disable-dbx-service": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-hacker-monitor": {
        adminView: true,
        techView: true,
        betaView: true,
        advancedView: true,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: true,
        bankLinkView: true,
        bankLinkAdminView: true
    },
    "c-sideMenu-spid": {
        adminView: true,
        techView: true,
        betaView: true,
        advancedView: true,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: true,
        bankLinkView: true,
        bankLinkAdminView: true
    },
    "c-company-dashboard-spid": {
        adminView: true,
        techView: true,
        betaView: true,
        advancedView: true,
        superView: true,
        certificationView: true,
        expertView: true,
        eligibleInvoiceView: true,
        bankLinkView: true,
        bankLinkAdminView: true
    },
    "c-user-delete-ts-id": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-sideMenu-ih-bo": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-action-new-archive": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-metering-share-package": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-metering-refresh-package": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-metering-disable-package": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: true,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false
    },
    "c-connection-load-ada": {
        adminView: false,
        techView: false,
        betaView: false,
        advancedView: false,
        superView: true,
        certificationView: false,
        expertView: false,
        eligibleInvoiceView: false,
        bankLinkView: false,
        bankLinkAdminView: false,
        adaView: true
    }
};

export const userIsAuthorized = (componentName, permits) => {
    const {
        isAdminUser,
        isBetaUser,
        isAdvancedUser,
        isSuperUser,
        isCertificationUser,
        isExpertUser,
        isEligibleInvoiceUser,
        isTechUsers,
        isBankLinkUsers,
        isBankLinkAdminUsers,
        isGreenUsers,
        isAdaUsers
    } = permits;
    //se il componente non è censito non c'è bisogno del controllo
    if (!config[componentName]) {
        return true;
    }
    const {
        superView,
        certificationView,
        adminView,
        betaView,
        advancedView,
        expertView,
        eligibleInvoiceView,
        techView,
        bankLinkView,
        bankLinkAdminView,
        greenView,
        adaView
    } = config[componentName];

    return (
        (superView && isSuperUser) ||
        (certificationView && isCertificationUser) ||
        (adminView && isAdminUser) ||
        (betaView && isBetaUser) ||
        (advancedView && isAdvancedUser) ||
        (expertView && isExpertUser) ||
        (eligibleInvoiceView && isEligibleInvoiceUser) ||
        (techView && isTechUsers) ||
        (bankLinkView && isBankLinkUsers) ||
        (bankLinkAdminView && isBankLinkAdminUsers) ||
        (greenView && isGreenUsers) ||
        (adaView && isAdaUsers)
    );
};

export function getContractPermits(info, permits, status, privacy, data) {
    let certify = false;
    let reject = false;
    let readOnly = false;

    if (info.externallyValidated && !permits.isSuperUser) {
        readOnly = true;
    }

    if (info.externallyValidated && permits.isSuperUser) {
        certify = true;
    }

    if (permits.isSuperUser && status === "CERTIFIED" && !info.externallyValidated) {
        reject = true;
    }
    if (permits.isSuperUser && (status === "AWAITING_UPLOAD" || status === "REJECTED")) {
        certify = true;
    }
    if ((status === "PENDING" || status === "VALIDATED") && privacy) {
        certify = true;
    }
    if (status === "PENDING" || status === "VALIDATED") {
        reject = true;
    }
    if (data.itemsDetails) {
        if (
            data.itemsDetails.length > 0 &&
            data.itemsDetails.find(v => v.id === data.managerId).status.certificationStatus !== "CERTIFIED"
        ) {
            certify = false;
        }
    }
    if (
        info &&
        info.policyCode === "AdV" &&
        (!data ||
            !data.item ||
            !data.item.base ||
            !data.item.base.details ||
            !data.item.base.details.addresses ||
            !data.item.base.details.addresses.length ||
            !data.item.base.details.addresses[0].fullAddress)
    ) {
        certify = false;
    }

    return { certify, reject, readOnly };
}

export const getProfileInfo = permits => {
    if (permits.isSuperUser) {
        return profileFilter.find(item => item.value === "isSuperUser");
    } else if (permits.isAdminUser) {
        return profileFilter.find(item => item.value === "isAdminUser");
    } else if (permits.isAdvancedUser) {
        return profileFilter.find(item => item.value === "isAdvancedUser");
    } else if (permits.isExpertUser) {
        return profileFilter.find(item => item.value === "isExpertUser");
    } else if (permits.isTechUsers) {
        return profileFilter.find(item => item.value === "isTechUsers");
    } else if (permits.isBetaUser) {
        return profileFilter.find(item => item.value === "isBetaUser");
    } else if (permits.isCertificationUser) {
        return profileFilter.find(item => item.value === "isCertificationUser");
    } else if (permits.isEligibleInvoiceUser) {
        return profileFilter.find(item => item.value === "isEligibleInvoiceUser");
    } else if (permits.isBankLinkUsers) {
        return profileFilter.find(item => item.value === "isBankLinkUsers");
    } else if (permits.isBankLinkAdminUsers) {
        return profileFilter.find(item => item.value === "isBankLinkAdminUsers");
    } else if (permits.isGreenUsers) {
        return profileFilter.find(item => item.value === "isGreenUsers");
    } else if (permits.isAdaUsers) {
        return profileFilter.find(item => item.value === "isAdaUsers");
    } else {
        return profileFilter.find(item => item.value === "isOperator");
    }
};

export const profileFilter = [
    { text: "Super User BO", color: "#e7b40d", value: "isSuperUser" },
    { text: "Admin BO", color: "#d42e58", value: "isAdminUser" },
    { text: "Utente avanzato", color: "#009688", value: "isAdvancedUser" },
    { text: "Utente esperto", color: "#9c27b0", value: "isExpertUser" },
    { text: "Utente tecnico", color: "#2db7f5", value: "isTechUsers" },
    { text: "Utente beta", color: "#87d068", value: "isBetaUser" },
    { text: "Utente idoneo certificato", color: "#6f8ee9", value: "isCertificationUser" },
    { text: "Utente idoneo fatturazione", color: "#708ee9", value: "isEligibleInvoiceUser" },
    { text: "Utente idoneo banklink", color: "#a08ee9", value: "isBankLinkUsers" },
    { text: "Utente idoneo admin banklink", color: "#508ee9", value: "isBankLinkAdminUsers" },
    { text: "Utente idoneo per imprese verdi", color: "#004943", value: "isGreenUsers" },
    { text: "Utente idoneo per caricare ADA", color: "#d52888", value: "isAdaUsers" },
    { text: "Operatore", color: "#0091ec", value: "isOperator" }
];
