import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import SignersTable from "../../components/signers-table";

import { getData, downloadFilledDocument, withdraw, cancel, update } from "../../actions/signer";

import { getAdempimentiSigner } from "../../actions/services/a-fiscali";
import { getSignatureSigner } from "../../actions/services/signature";

class SignerTableView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        cancel: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        downloadFilledDocument: PropTypes.func.isRequired,
        getAdempimentiSigner: PropTypes.func.isRequired,
        getData: PropTypes.func.isRequired,
        getSignatureSigner: PropTypes.func.isRequired,
        signer: PropTypes.object.isRequired,
        signers: PropTypes.object,
        update: PropTypes.func.isRequired,
        withdraw: PropTypes.func.isRequired
    };

    render() {
        const {
            auth,
            cancel,
            company,
            downloadFilledDocument,
            getAdempimentiSigner,
            getData,
            getSignatureSigner,
            signer,
            signers,
            update,
            withdraw
        } = this.props;
        return (
            <div>
                <SignersTable
                    auth={auth}
                    cancel={cancel}
                    company={company}
                    downloadFilledDocument={downloadFilledDocument}
                    getAdempimentiSigner={getAdempimentiSigner}
                    getData={getData}
                    getSignatureSigner={getSignatureSigner}
                    signer={signer}
                    signers={signers}
                    update={update}
                    withdraw={withdraw}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    signer: state.signer
});
const actions = {
    cancel,
    downloadFilledDocument,
    getAdempimentiSigner,
    getData,
    getSignatureSigner,
    update,
    withdraw
};
const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    )
);
export default composedHoc(SignerTableView);
