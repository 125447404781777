import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import { InvoicesConfigExpanded } from "../invoices-config-expanded";

export const InvoicesUserConfig = ({ invoicesConfigList }) => {
    const columns = [
        {
            title: <FormattedMessage id="general.user" />,
            dataIndex: "id",
            key: "id"
        },
        {
            title: <FormattedMessage id="general.firstName" />,
            dataIndex: "firstName",
            key: "firstName"
        },
        {
            title: <FormattedMessage id="general.lastName" />,
            dataIndex: "lastName",
            key: "lastName"
        }
    ];

    return (
        <div>
            <div className={"a1"} style={{ marginBottom: 10 }}>
                <FormattedMessage id={"c-invoice-user-config-message"} />
            </div>
            <div style={{ marginBottom: 10 }}>
                <FormattedMessage id={"c-invoice-user-config-message_nb"} />
            </div>
            <Table
                rowKey="id"
                columns={columns}
                locale={{
                    emptyText: (
                        <div style={{ height: "50vh" }}>
                            <div style={{ position: "absolute", top: "50%", left: "30%" }}>
                                <FormattedMessage id={"no_result_user_invoices_config"} />
                            </div>
                        </div>
                    )
                }}
                dataSource={invoicesConfigList || []}
                size="middle"
                expandedRowRender={(record, index) => (
                    <InvoicesConfigExpanded key={index} dataSource={record.deniedFunctionalityIds} />
                )}
                pagination={false}
            />
        </div>
    );
};

InvoicesUserConfig.propTypes = {
    invoicesConfigList: PropTypes.array
};
