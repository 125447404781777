import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tabs } from "antd";

import { PdfHandler } from "../../../../components/general/pdf-handler";

import { PDFContainer } from "./styled";

export const SignatureServicePreview = ({ loading, base64, documentType, handleDocumentTypeChange }) => {
    return (
        <Tabs defaultActiveKey={documentType} onChange={handleDocumentTypeChange}>
            <Tabs.TabPane tab={<FormattedMessage id="filled_document" />} key="FILLED">
                <PDFContainer>
                    <PdfHandler content={base64} loading={loading} fileName={"documento_compilato.pdf"} />
                </PDFContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<FormattedMessage id="signed_document" />} key="SIGNED">
                <PDFContainer>
                    <PdfHandler content={base64} loading={loading} fileName={"documento_firmato.pdf"} />
                </PDFContainer>
            </Tabs.TabPane>
        </Tabs>
    );
};

SignatureServicePreview.propTypes = {
    loading: PropTypes.bool.isRequired,
    base64: PropTypes.string,
    documentType: PropTypes.string.isRequired,
    handleDocumentTypeChange: PropTypes.func.isRequired
};
