import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tooltip, Dropdown, Button, Menu } from "antd";
import { CCTManagerExpanded } from "../cct-manager-expanded";
import { ColumnSearchProps } from "../../../general/column-search-props";

export const CCTManager = ({
    companyCertificationStatus,
    dataSource,
    loading,
    paginationTotal,
    paginationCurrent,
    isEnableCoupon1000,
    handleEnableCoupon1004,
    handleGetConnection
}) => {
    const columns = [
        {
            title: <FormattedMessage id="general.taxId" />,
            dataIndex: "managerId",
            key: "managerId",
            onFilter: (value, record) =>
                record["managerId"]
                    ? record["managerId"]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : "",
            ...ColumnSearchProps("codice fiscale")
        },
        {
            title: <FormattedMessage id="general.itemDescription" />,
            dataIndex: "managerDescription",
            align: "center",
            key: "managerDescription",
            onFilter: (value, record) =>
                record["managerDescription"]
                    ? record["managerDescription"]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : "",
            ...ColumnSearchProps("ragione sociale")
        },
        {
            title: <FormattedMessage id="actions" />,
            key: "actions",
            align: "center",
            render: (text, record) => (
                <div>
                    <Tooltip title={<FormattedMessage id="enable_coupon_1004" />}>
                        <Dropdown
                            overlay={renderActions(record)}
                            trigger={["click"]}
                            placement="bottomRight"
                            disabled={checkRequisiteCoupon1004(record)}
                        >
                            <Button shape="circle" size="small" type={"primary"}>
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </Tooltip>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleEnableCoupon1004(record)}>
                    <FormattedMessage id="enable_coupon_1004" />
                </Menu.Item>
            </Menu>
        );
    };

    const checkRequisiteCoupon1004 = record => {
        let disable = true;
        if (record != null && record.connections != null) {
            Object.values(record.connections).forEach(element => {
                if (
                    element.status.status === "VALIDATED" &&
                    element.status.active &&
                    element.appId === "EIP" &&
                    companyCertificationStatus === "CERTIFIED" &&
                    isEnableCoupon1000
                ) {
                    disable = false;
                }
            });
        }
        return disable;
    };

    return (
        <div style={{ marginBottom: 10 }}>
            <div className={"a1"} style={{ marginBottom: 10 }}>
                Gestori
            </div>
            <Table
                rowKey={(record, index) => index}
                locale={{
                    emptyText: <FormattedMessage id="no_manager_available" />
                }}
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                pagination={{
                    total: paginationTotal,
                    current: paginationCurrent + 1,
                    hideOnSinglePage: false,
                    defaultPageSize: 10
                }}
                onChange={handleGetConnection}
                expandedRowRender={record => <CCTManagerExpanded dataSource={record.connections} />}
            />
        </div>
    );
};

CCTManager.propTypes = {
    companyCertificationStatus: PropTypes.string,
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    paginationTotal: PropTypes.number.isRequired,
    paginationCurrent: PropTypes.number.isRequired,
    isEnableCoupon1000: PropTypes.bool.isRequired,
    handleEnableCoupon1004: PropTypes.func.isRequired,
    handleGetConnection: PropTypes.func.isRequired
};
