import React from "react";
import PropTypes from "prop-types";

import { Row, Button, Col } from "antd";

const LoginRetry = ({ errorMsg, resetLogin }) => (
    <Row justify="center" type="flex">
        <Col>
            <div className="header">
                <br />
                <span>{errorMsg}</span>
                <br />
                <br />

                <Button type="primary" className="root-button" onClick={resetLogin}>
                    {"Riprova"}
                </Button>
            </div>
        </Col>
    </Row>
);

LoginRetry.propTypes = {
    errorMsg: PropTypes.string.isRequired,
    resetLogin: PropTypes.func.isRequired
};

export default LoginRetry;
