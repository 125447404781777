import { postRestCall, patchRestCall } from "../../../utils/rest-api-call";
import { API_URL } from "../../../config";

export const VALIDATE_RDC_START = "VALIDATE_RDC_START";
export const VALIDATE_RDC_SUCCESS = "VALIDATE_RDC_SUCCESS";
export const VALIDATE_RDC_ERROR = "VALIDATE_RDC_ERROR";

export const REJECT_RDC_START = "REJECT_RDC_START";
export const REJECT_RDC_SUCCESS = "REJECT_RDC_SUCCESS";
export const REJECT_RDC_ERROR = "REJECT_RDC_ERROR";

export const UPLOAD_FRONTEND_START = "UPLOAD_FRONTEND_START";
export const UPLOAD_FRONTEND_SUCCESS = "UPLOAD_FRONTEND_SUCCESS";
export const UPLOAD_FRONTEND_ERROR = "UPLOAD_FRONTEND_ERROR";

export const UPDATE_SUBMITTER_MAIL_START = "UPDATE_SUBMITTER_MAIL_START";
export const UPDATE_SUBMITTER_MAIL_SUCCESS = "UPDATE_SUBMITTER_MAIL_SUCCESS";
export const UPDATE_SUBMITTER_MAIL_ERROR = "UPDATE_SUBMITTER_MAIL_ERROR";

export const UPDATE_ARCHIVE_DOC_START = "UPDATE_ARCHIVE_DOC_START";
export const UPDATE_ARCHIVE_DOC_SUCCESS = "UPDATE_ARCHIVE_DOC_SUCCESS";
export const UPDATE_ARCHIVE_DOC_ERROR = "UPDATE_ARCHIVE_DOC_ERROR";

export const validateRDC = (auth, submitterId, holderId) => async dispatch => {
    let body = {
        submitterId: submitterId,
        holderId: holderId
    };
    dispatch({
        type: VALIDATE_RDC_START,
        request: body
    });
    const toast = {
        onOk: true,
        onOkText: "Validazione effettuata con successo",
        onError: true,
        onErrorText: "Validazione non effettuata",
        infoReq: { authId: auth.id, req: body }
    };
    try {
        const result = await patchRestCall(
            `${API_URL}/archive/validate`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: VALIDATE_RDC_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: VALIDATE_RDC_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const rejectRDC = (auth, submitterId, holderId, reason) => async dispatch => {
    let body = {
        submitterId: submitterId,
        holderId: holderId,
        reason: reason
    };
    dispatch({
        type: REJECT_RDC_START,
        request: body
    });
    const toast = {
        onOk: true,
        onOkText: "Rigetto effettuato con successo",
        onError: true,
        onErrorText: "Rigetto non effettuato",
        infoReq: { authId: auth.id, req: body }
    };
    try {
        const result = await postRestCall(
            `${API_URL}/archive/reject`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: REJECT_RDC_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: REJECT_RDC_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const uploadFrontend = (auth, submitterId, holderId, uploadFrontedActive) => async dispatch => {
    let body = {
        submitterId: submitterId,
        holderId: holderId,
        uploadFrontedActive: uploadFrontedActive
    };
    dispatch({
        type: UPLOAD_FRONTEND_START,
        request: body
    });
    const toast = {
        onOk: true,
        onOkText: "Aggiornamento effettuato con successo",
        onError: true,
        onErrorText: "Aggiornamento non effettuato",
        infoReq: { authId: auth.id, req: body }
    };
    try {
        const result = await postRestCall(
            `${API_URL}/archive/uploadFrontend`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: UPLOAD_FRONTEND_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: UPLOAD_FRONTEND_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const updateSubmitterMail = (auth, submitterId, holderId, email, emailPec) => async dispatch => {
    let body = {
        submitterId: submitterId,
        holderId: holderId,
        email: email,
        emailPec: emailPec
    };
    dispatch({
        type: UPDATE_SUBMITTER_MAIL_START,
        request: body
    });
    const toast = {
        onOk: true,
        onOkText: "Aggiornamento effettuato con successo",
        onError: true,
        onErrorText: "Aggiornamento non effettuato",
        infoReq: { authId: auth.id, req: body }
    };
    try {
        const result = await postRestCall(
            `${API_URL}/archive/updateSubmitterMail`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: UPDATE_SUBMITTER_MAIL_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: UPDATE_SUBMITTER_MAIL_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const updateArchiveDocs = (auth, submitterId, holderId, noArchiveDocs) => async dispatch => {
    let body = {
        submitterId: submitterId,
        holderId: holderId,
        noArchiveDocs: noArchiveDocs
    };
    dispatch({
        type: UPDATE_ARCHIVE_DOC_START,
        request: body
    });
    const toast = {
        onOk: true,
        onOkText: "Aggiornamento effettuato con successo",
        onError: true,
        onErrorText: "Aggiornamento non effettuato",
        infoReq: { authId: auth.id, req: body }
    };
    try {
        const result = await postRestCall(
            `${API_URL}/archive/updateArchiveDocs`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: UPDATE_ARCHIVE_DOC_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: UPDATE_ARCHIVE_DOC_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};
