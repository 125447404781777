import { getRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const GET_DIGITALBOX_CONFIG_START = "GET_DIGITALBOX_CONFIG_START";
export const GET_DIGITALBOX_CONFIG_ERROR = "GET_DIGITALBOX_CONFIG_ERROR";
export const GET_DIGITALBOX_CONFIG_SUCCESS = "GET_DIGITALBOX_CONFIG_SUCCESS";

export function getDigitalboxConfig(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_DIGITALBOX_CONFIG_START,
            itemId: itemId
        });

        let urlParams = {
            itemId: itemId
        };

        try {
            const result = await getRestCall(`${API_URL}/digitalbox`, auth, urlParams, dispatch, auth.refreshToken);

            dispatch({
                type: GET_DIGITALBOX_CONFIG_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_DIGITALBOX_CONFIG_ERROR,
                error: e
            });
        }
    };
}

export const GET_DIGITALBOX_CONFIG_RESET = "GET_DIGITALBOX_CONFIG_RESET";

export function resetDigitalboxConfig() {
    return {
        type: GET_DIGITALBOX_CONFIG_RESET
    };
}
