import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { Table, Badge, Modal, Tabs, Dropdown, Button, Menu, Form, Input, Checkbox, Alert, Select, Tooltip } from "antd";
import { ActionIcon, ViewTitle, SearchContainer, ToolTipIcon } from "./styled.js";

import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { translateProvisioningSource, translateProvisionigStatus } from "../../../utils/translate";
import { userIsAuthorized } from "../../../utils/get-roles";

const { Search } = Input;
const { Option } = Select;

export const NewArchiveConfig = ({
    admin,
    intl,
    archiveConfig,
    archiveSubmitterConfig,
    loading,
    loadingSubmitter,
    handleSubmitterPageChange,
    submitterPage,
    archiveSubmitterTotalConfig,
    archiveSubmitterPageSize,
    changeArchiveDocs,
    changeLoadFrontend,
    changeSubmitterMail,
    onSubmitterSearch,
    changeFilter
}) => {
    const [preservationModalVisibile, setPreservationModalVisible] = useState(false);
    const [preservationInfo, setPreservationInfo] = useState({});
    const [infoDraftRDCModalVisible, setInfoDraftRDCModalVisible] = useState(false);
    const [infoDraftRDC, setInfoDraftRDC] = useState({});

    const [configRecord, setConfigRecord] = useState({});
    const { TabPane } = Tabs;
    const handlePreservationModalOk = () => {
        setPreservationModalVisible(false);
    };
    const handleInfoDraftModalOk = () => {
        setInfoDraftRDCModalVisible(false);
    };
    const [modalChangeMail, setModalChangeMail] = useState(false);
    const [modalArchiveDocs, setModalArchiveDocs] = useState(false);
    const [modalUploadFrontend, setModalUploadFrontend] = useState(false);
    const [newMail, setNewMail] = useState(null);
    const [isMailPec, setMailPec] = useState(false);

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item
                    key="0"
                    onClick={() => {
                        setModalChangeMail(true);
                        setConfigRecord(record);
                    }}
                >
                    <FormattedMessage id="c-new-archive-modal-update-mail-message-title" />
                </Menu.Item>
                <Menu.Item
                    key="1"
                    onClick={() => {
                        setModalArchiveDocs(true);
                        setConfigRecord(record);
                    }}
                >
                    {record.no_use_archive ? (
                        <FormattedMessage id="c-new-archive-modal-no-use-archive-message-title-deactivate" />
                    ) : (
                        <FormattedMessage id="c-new-archive-modal-no-use-archive-message-title-activate" />
                    )}
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => {
                        setModalUploadFrontend(true);
                        setConfigRecord(record);
                    }}
                >
                    {record.upload_frontend_active ? (
                        <FormattedMessage id="c-new-archive-modal-upload-message-title-deactivate" />
                    ) : (
                        <FormattedMessage id="c-new-archive-modal-upload-message-title-activate" />
                    )}
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FormattedMessage id="c-archive-config-service-status" />,
            dataIndex: "active",
            render: text => (
                <span>
                    <Badge status={text ? "success" : "error"} />
                    {text ? (
                        <FormattedMessage id="c-cctconfigservice.active" />
                    ) : (
                        <FormattedMessage id="c-cctconfigservice.disabled" />
                    )}
                </span>
            )
        },
        {
            title: <FormattedMessage id="read_only" />,
            dataIndex: "read_only",
            render: text => (
                <span>
                    <Badge status={text ? "error" : "success"} />
                    {text ? (
                        <FormattedMessage id="c-meteringtable.yes" />
                    ) : (
                        <FormattedMessage id="c-meteringtable.no" />
                    )}
                </span>
            )
        },

        {
            title: <FormattedMessage id="c-cctconfigservice.cfazienda" />,
            dataIndex: "holder.cf",
            key: "holder.cf"
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.cfmain" />,
            dataIndex: "submitter.cf"
        },

        {
            title: <FormattedMessage id="c-archive-config-preservation-manager" />,
            dataIndex: "preservation_manager",
            render: (text, record) => (
                <span style={{ display: "flex" }}>
                    {record.preservation_manager.tax_id !== "" ? (
                        <ActionIcon
                            icon={faInfoCircle}
                            onClick={() => {
                                setPreservationModalVisible(true);
                                setPreservationInfo(record.preservation_manager);
                            }}
                        />
                    ) : null}
                    {record.preservation_manager.tax_id !== "" ? (
                        record.preservation_manager.tax_id
                    ) : (
                        <FormattedMessage id="c-archive-config-provisionig-not-available" />
                    )}
                </span>
            )
        },
        {
            title: (
                <Select
                    placeholder={<FormattedMessage id="c-archive-config-provisioning-source" />}
                    style={{ width: "200px" }}
                    onChange={v => changeFilter(v, "provisioning_source")}
                    defaultValue={null}
                >
                    <Option value={null}>Tutti gli stati</Option>
                    <Option value="RDC_MANUAL">RDC Manuale</Option>
                    <Option value="RDC_SMART">RDC Smart</Option>
                    <Option value="METERING">Avviata solo sottoscrizione</Option>
                </Select>
            ),

            dataIndex: "provisioning_source",
            render: text => <span>{translateProvisioningSource(text)}</span>
        },
        {
            title: (
                <>
                    <FormattedMessage id="c-archive-config-provisioning-process-status" />
                    <Tooltip
                        placement="bottom"
                        title={<FormattedHTMLMessage id="c-archive-config-rdc-state-tooltip" />}
                    >
                        <ToolTipIcon icon={faInfoCircle} />
                    </Tooltip>
                </>
            ),
            dataIndex: "draft",
            render: (text, record) => (
                <span style={{ display: "flex" }}>
                    {record.draft !== null ? (
                        <ActionIcon
                            icon={faInfoCircle}
                            onClick={() => {
                                setInfoDraftRDCModalVisible(true);
                                setInfoDraftRDC(record.draft);
                            }}
                        />
                    ) : null}
                    {record.draft !== null ? (
                        record.provisioning_source === "RDC_MANUAL" || record.provisioning_source === "RDC_SMART" ? (
                            <FormattedMessage id="c-archive-config-provisioning-new-rdc-activated" />
                        ) : (
                            translateProvisionigStatus(record.draft.status)
                        )
                    ) : (
                        <FormattedMessage id="c-archive-config-provisioning-no-rdc-activated" />
                    )}
                </span>
            )
        },

        {
            title: <FormattedMessage id="c-cctconfigservice.actions" />,
            key: "actions",
            render: (text, record) => (
                <div>
                    <Dropdown
                        overlay={renderActions(record)}
                        trigger={["click"]}
                        placement="bottomRight"
                        disabled={!userIsAuthorized("c-action-new-archive", admin.adminInfo.permits)}
                    >
                        <Button shape="circle" size="small" type={"primary"}>
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab={<FormattedMessage id="c-cctconfigservice.config" />} key="1">
                    <Table
                        rowKey={(record, index) => index}
                        locale={{
                            emptyText: <FormattedMessage id="c-cctconfigservice.nullconfiguration" />
                        }}
                        dataSource={archiveConfig}
                        columns={columns}
                        expandedRowRender={record => (
                            <div>
                                <div>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-cctconfigservice.createdAt" />
                                        </b>
                                        {new Date(record.created_date).toLocaleString()}
                                    </p>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-cctconfigservice.createdBy" />
                                        </b>
                                        {record.last_created_by}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-cctconfigservice.updatedAt" />
                                        </b>
                                        {new Date(record.last_modified_date).toLocaleString()}
                                    </p>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-cctconfigservice.updatedBy" />
                                        </b>
                                        {record.last_modified_by}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-archive-config-upload-frontend" />
                                        </b>
                                        <Badge status={record.upload_frontend_active ? "success" : "error"} />
                                        {record.upload_frontend_active ? (
                                            <FormattedMessage id="c-cctconfigservice.active" />
                                        ) : (
                                            <FormattedMessage id="c-cctconfigservice.disabled" />
                                        )}
                                    </p>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-archive-config-no-use-archive" />
                                        </b>
                                        <Badge status={record.no_use_archive ? "success" : "error"} />
                                        {record.no_use_archive ? (
                                            <FormattedMessage id="c-cctconfigservice.active" />
                                        ) : (
                                            <FormattedMessage id="c-cctconfigservice.disabled" />
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-cctconfigservice.submitter.email" />
                                        </b>
                                        {record.submitter.email}
                                    </p>
                                    <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                        <b>
                                            <FormattedMessage id="c-cctconfigservice.submitter.is_email_pec" />
                                        </b>
                                        <span>
                                            {record.submitter.is_email_pec ? (
                                                <FormattedMessage id="c-meteringtable.yes" />
                                            ) : (
                                                <FormattedMessage id="c-meteringtable.no" />
                                            )}
                                        </span>
                                        {record.submitter.is_email_pec}
                                    </p>
                                </div>
                            </div>
                        )}
                        loading={loading}
                        pagination={false}
                    />
                    <br />
                    <br />
                    <br />
                </TabPane>
                {archiveSubmitterTotalConfig !== 0 && (
                    <TabPane tab={<FormattedMessage id="c-cctconfigservice.shared-config" />} key="2">
                        <SearchContainer>
                            <Search
                                placeholder={intl.formatMessage({
                                    id: "c-cctconfigservice.titolarecf-search"
                                })}
                                enterButton
                                size="large"
                                onSearch={v => onSubmitterSearch(v.trim())}
                            />
                        </SearchContainer>
                        <Table
                            rowKey={(record, index) => index}
                            locale={{
                                emptyText: <FormattedMessage id="c-cctconfigservice.nullconfiguration" />
                            }}
                            dataSource={archiveSubmitterConfig}
                            columns={columns}
                            expandedRowRender={record => (
                                <div>
                                    <div>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-cctconfigservice.createdAt" />
                                            </b>
                                            {new Date(record.created_date).toLocaleString()}
                                        </p>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-cctconfigservice.createdBy" />
                                            </b>
                                            {record.last_created_by}
                                        </p>
                                    </div>
                                    <div>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-cctconfigservice.updatedAt" />
                                            </b>
                                            {new Date(record.last_modified_date).toLocaleString()}
                                        </p>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-cctconfigservice.updatedBy" />
                                            </b>
                                            {record.last_modified_by}
                                        </p>
                                    </div>
                                    <div>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-archive-config-upload-frontend" />
                                            </b>
                                            <Badge status={record.upload_frontend_active ? "success" : "error"} />
                                            {record.upload_frontend_active ? (
                                                <FormattedMessage id="c-cctconfigservice.active" />
                                            ) : (
                                                <FormattedMessage id="c-cctconfigservice.disabled" />
                                            )}
                                        </p>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-archive-config-no-use-archive" />
                                            </b>
                                            <Badge status={record.no_use_archive ? "success" : "error"} />
                                            {record.no_use_archive ? (
                                                <FormattedMessage id="c-cctconfigservice.active" />
                                            ) : (
                                                <FormattedMessage id="c-cctconfigservice.disabled" />
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-cctconfigservice.submitter.email" />
                                            </b>
                                            {record.submitter.email}
                                        </p>
                                        <p style={{ display: "inline-block", paddingRight: "1%" }}>
                                            <b>
                                                <FormattedMessage id="c-cctconfigservice.submitter.is_email_pec" />
                                            </b>
                                            <span>
                                                {record.submitter.is_email_pec ? (
                                                    <FormattedMessage id="c-meteringtable.yes" />
                                                ) : (
                                                    <FormattedMessage id="c-meteringtable.no" />
                                                )}
                                            </span>
                                            {record.submitter.is}
                                        </p>
                                    </div>
                                </div>
                            )}
                            loading={loadingSubmitter}
                            pagination={{
                                current: submitterPage,
                                total: archiveSubmitterTotalConfig,
                                onChange: p => handleSubmitterPageChange(p),
                                pageSize: archiveSubmitterPageSize,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                            }}
                        />
                    </TabPane>
                )}
            </Tabs>
            <Modal
                title={<FormattedMessage id="c-archive-config-preservation-modal-title" />}
                visible={preservationModalVisibile}
                onOk={handlePreservationModalOk}
                onCancel={handlePreservationModalOk}
            >
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-tax-id" />
                    </b>
                    {preservationInfo.tax_id}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-first-name" />
                    </b>
                    {preservationInfo.first_name}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-last-name" />
                    </b>
                    {preservationInfo.last_name}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-birth-date" />
                    </b>
                    {new Date(preservationInfo.birth_date).toLocaleString()}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-birth-place" />
                    </b>
                    {preservationInfo.birth_place}
                </p>
                <p>
                    <b>E-mail: </b>
                    {preservationInfo.email}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-pec" />
                    </b>
                    {preservationInfo.is_email_pec ? (
                        <FormattedMessage id="c-meteringtable.yes" />
                    ) : (
                        <FormattedMessage id="c-meteringtable.no" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-tax-region" />
                    </b>
                    {preservationInfo.tax_region}
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage id="c-archive-config-new-rdc-modal-title" />}
                visible={infoDraftRDCModalVisible}
                onOk={handleInfoDraftModalOk}
                onCancel={handleInfoDraftModalOk}
            >
                {infoDraftRDC.status === "REJECTED" ? (
                    <p>
                        <b>{<FormattedMessage id="c-archive-config-rdc-rejected" />}</b>
                        <Alert message={infoDraftRDC.reject_reason} banner />
                        <br></br>
                    </p>
                ) : null}
                <ViewTitle>{<FormattedMessage id="c-archive-config-rdc-activation-data" />}</ViewTitle>
                <br></br>
                <p>
                    <b>
                        <FormattedMessage id="activated_by" />{" "}
                    </b>
                    {infoDraftRDC.last_created_by ? (
                        infoDraftRDC.last_created_by
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="activated_on" />{" "}
                    </b>
                    {infoDraftRDC.created_date ? (
                        new Date(infoDraftRDC.created_date).toLocaleString()
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="modified_by" />{" "}
                    </b>
                    {infoDraftRDC.last_created_by ? (
                        infoDraftRDC.last_created_by
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="modified_on" />{" "}
                    </b>
                    {infoDraftRDC.created_date ? (
                        new Date(infoDraftRDC.last_modified_date).toLocaleString()
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <ViewTitle>{<FormattedMessage id="c-archive-config-rdc-data" />}</ViewTitle>
                <br></br>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-tax-id" />
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.tax_id
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-first-name" />
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.first_name
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-last-name" />
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.last_name
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-birth-date" />
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        new Date(infoDraftRDC.preservation_manager.birth_date).toLocaleString()
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-birth-place" />
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.birth_place
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>E-mail: </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.email
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-pec" />
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.is_email_pec ? (
                            <FormattedMessage id="c-meteringtable.yes" />
                        ) : (
                            <FormattedMessage id="c-meteringtable.no" />
                        )
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-archive-config-preservation-modal-tax-region" />{" "}
                    </b>
                    {infoDraftRDC.preservation_manager ? (
                        infoDraftRDC.preservation_manager.tax_region
                    ) : (
                        <FormattedMessage id="date_not_available" />
                    )}
                </p>
            </Modal>
            <Modal
                centered
                title={
                    configRecord.no_use_archive ? (
                        <FormattedMessage id="c-new-archive-modal-no-use-archive-message-title-deactivate" />
                    ) : (
                        <FormattedMessage id="c-new-archive-modal-no-use-archive-message-title-activate" />
                    )
                }
                visible={modalArchiveDocs}
                onOk={() => {
                    changeArchiveDocs(configRecord);
                    setModalArchiveDocs(false);
                }}
                okText={<FormattedMessage id="general.confirm" />}
                cancelText={<FormattedMessage id="general.cancel" />}
                onCancel={() => setModalArchiveDocs(false)}
                width={640}
            >
                <div>
                    <span>
                        <FormattedMessage
                            id="c-new-archive-modal-no-use-archive-message"
                            values={{
                                message: configRecord.no_use_archive ? (
                                    <FormattedMessage id="c-new-archive-modal-deactivate" />
                                ) : (
                                    <FormattedMessage id="c-new-archive-modal-activate" />
                                )
                            }}
                        />
                    </span>
                </div>
            </Modal>
            <Modal
                centered
                title={
                    configRecord.upload_frontend_active ? (
                        <FormattedMessage id="c-new-archive-modal-upload-message-title-deactivate" />
                    ) : (
                        <FormattedMessage id="c-new-archive-modal-upload-message-title-activate" />
                    )
                }
                visible={modalUploadFrontend}
                onOk={() => {
                    changeLoadFrontend(configRecord);
                    setModalUploadFrontend(false);
                }}
                okText={<FormattedMessage id="general.confirm" />}
                cancelText={<FormattedMessage id="general.cancel" />}
                onCancel={() => setModalUploadFrontend(false)}
                width={640}
            >
                <div>
                    <span>
                        <FormattedMessage
                            id="c-new-archive-modal-upload-message"
                            values={{
                                message: configRecord.upload_frontend_active ? (
                                    <FormattedMessage id="c-new-archive-modal-deactivate" />
                                ) : (
                                    <FormattedMessage id="c-new-archive-modal-activate" />
                                )
                            }}
                        />
                    </span>
                </div>
            </Modal>
            <Modal
                centered
                title={<FormattedMessage id="c-new-archive-modal-update-mail-message-title" />}
                visible={modalChangeMail}
                onOk={() => {
                    changeSubmitterMail(configRecord, newMail, isMailPec);
                    setModalChangeMail(false);
                }}
                okText={<FormattedMessage id="general.confirm" />}
                cancelText={<FormattedMessage id="general.cancel" />}
                onCancel={() => setModalChangeMail(false)}
                width={640}
            >
                <Form.Item
                    label={<FormattedMessage id="c-new-archive-modal-insert-new-mail" />}
                    name="email"
                    rules={[{ required: true }]}
                >
                    <Input onChange={e => setNewMail(e.target.value)} placeholder={"Inserire mail"} />
                </Form.Item>
                <div>
                    <span>
                        <FormattedMessage id="c-new-archive-modal-insert-is-pec" />
                    </span>
                    <Checkbox onChange={e => setMailPec(e.target.checked)} />
                </div>
            </Modal>
        </div>
    );
};

NewArchiveConfig.propTypes = {
    admin: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    changeArchiveDocs: PropTypes.func.isRequired,
    changeSubmitterMail: PropTypes.func.isRequired,
    changeLoadFrontend: PropTypes.func.isRequired
};
