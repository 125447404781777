import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Badge, Descriptions, Tag } from "antd";
import { ContainerTitle, ContainerBody } from "./styled";

export const InvoiceTrading = ({ fawConfig }) => {
    const columns = [
        {
            title: <FormattedMessage id="field" />,
            dataIndex: "field",
            key: "field"
        },
        {
            title: <FormattedMessage id="value" />,
            dataIndex: "value",
            key: "value"
        },
        {
            title: <FormattedMessage id="operator" />,
            dataIndex: "operator",
            key: "operator"
        },
        {
            title: <FormattedMessage id="description" />,
            dataIndex: "description",
            key: "description"
        }
    ];

    return (
        <div>
            <ContainerBody>
                <Descriptions title={<FormattedMessage id="details" />}>
                    <Descriptions.Item label={<FormattedMessage id="autosend" />}>
                        <span>
                            {fawConfig.autosend ? (
                                <Tag color="green">
                                    <span>
                                        <Badge status={"success"} />
                                        <FormattedMessage id="general.active" />
                                    </span>
                                </Tag>
                            ) : (
                                <Tag color="red">
                                    <span>
                                        <Badge status={"error"} />
                                        <FormattedMessage id="general.disabled" />
                                    </span>
                                </Tag>
                            )}
                        </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={<FormattedMessage id="privacy_status" />}>
                        <span>
                            {fawConfig.hasAcceptedPrivacy ? (
                                <Tag color="green">
                                    <span>
                                        <Badge status={"success"} />
                                        <FormattedMessage id="accepted" />
                                    </span>
                                </Tag>
                            ) : (
                                <Tag color="red">
                                    <span>
                                        <Badge status={"error"} />
                                        <FormattedMessage id="not_accepted" />
                                    </span>
                                </Tag>
                            )}
                        </span>
                    </Descriptions.Item>
                </Descriptions>
            </ContainerBody>
            <ContainerTitle>
                <FormattedMessage id="filters" />
            </ContainerTitle>
            <Table
                rowKey={(record, index) => index}
                locale={{
                    emptyText: <FormattedMessage id="no_filter_available" />
                }}
                dataSource={fawConfig.filters || []}
                columns={columns}
                size="middle"
                pagination={false}
                loading={fawConfig.status.started}
            />
        </div>
    );
};

InvoiceTrading.propTypes = {
    fawConfig: PropTypes.object.isRequired
};
