import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tabs, Drawer } from "antd";

import { NotificationPreferences } from "./notification-preferences";
import { NotificationChannels } from "./notification-channels";
import { NotificationActivity } from "./notification-activity";
import { Container } from "./styled";

export const UserNotification = ({
    ncsPersonalPreferenceList,
    ncsGroupPreferenceList,
    ncsActivityList,
    ncsActivityListLoading,
    handlePageSizeChangeNcsActivity,
    pageSizeNcsActivity,
    groupId,
    handleChangeGroupId,
    ncsGroupPreferenceLoading,
    lazyLoadCompanyOptions,
    initialCompanyOptions
}) => {
    const [channelInfo, setChannelInfo] = useState(null);
    const [channelView, setChannelView] = useState(false);

    return (
        <div>
            <Tabs tabPosition="left">
                <Tabs.TabPane tab={<FormattedMessage id="personal_preferences" />} key="personal-preferences">
                    <Container>
                        <NotificationPreferences
                            isPersonal={true}
                            ncsPreferenceList={ncsPersonalPreferenceList}
                            loading={false}
                            handleViewChannel={value => {
                                setChannelInfo(value);
                                setChannelView(true);
                            }}
                        />
                    </Container>
                </Tabs.TabPane>
                {initialCompanyOptions && initialCompanyOptions.length && (
                    <Tabs.TabPane tab={<FormattedMessage id="company_preferences" />} key="group-preferences">
                        <Container>
                            <NotificationPreferences
                                isPersonal={false}
                                ncsPreferenceList={ncsGroupPreferenceList}
                                groupId={groupId}
                                handleChangeGroupId={handleChangeGroupId}
                                loading={ncsGroupPreferenceLoading}
                                handleViewChannel={value => {
                                    setChannelInfo(value);
                                    setChannelView(true);
                                }}
                                lazyLoadCompanyOptions={lazyLoadCompanyOptions}
                                initialCompanyOptions={initialCompanyOptions}
                            />
                        </Container>
                    </Tabs.TabPane>
                )}
                {
                    <Tabs.TabPane tab={<FormattedMessage id="notification" />} key="activity">
                        <Container>
                            <NotificationActivity
                                loading={ncsActivityListLoading}
                                activityList={ncsActivityList}
                                handlePageSizeChange={handlePageSizeChangeNcsActivity}
                                pageSize={pageSizeNcsActivity}
                            />
                        </Container>
                    </Tabs.TabPane>
                }
            </Tabs>
            <Drawer
                width={"50%"}
                title={<FormattedMessage id="notification_channels" />}
                onClose={() => setChannelView(false)}
                visible={channelView}
            >
                <NotificationChannels channelInfo={channelInfo} />
            </Drawer>
        </div>
    );
};

UserNotification.propTypes = {
    ncsPersonalPreferenceList: PropTypes.array.isRequired,
    ncsGroupPreferenceList: PropTypes.array.isRequired,
    ncsGroupPreferenceLoading: PropTypes.bool.isRequired,
    ncsActivityList: PropTypes.array.isRequired,
    ncsActivityListLoading: PropTypes.bool.isRequired,
    handlePageSizeChangeNcsActivity: PropTypes.func.isRequired,
    pageSizeNcsActivity: PropTypes.any.isRequired,
    groupId: PropTypes.object,
    handleChangeGroupId: PropTypes.func,
    lazyLoadCompanyOptions: PropTypes.func,
    initialCompanyOptions: PropTypes.array
};
