import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import TsCard from "../general/ts-card";
import { ENV } from "../../config";

import { CardServiceWrapper, ServiceViewBlock, ViewTitle } from "./styled";

export const ServiceStatus = ({ history }) => {
    return (
        <div>
            {
                <ServiceViewBlock>
                    <ViewTitle>
                        <FormattedMessage id="service_status" />
                    </ViewTitle>

                    <CardServiceWrapper className="content">
                        <TsCard
                            title={<FormattedMessage id="service_status_digital" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_digital_description" />}
                            action={() => history.push("/service-status/service-ts-digital")}
                        />
                        <TsCard
                            title={<FormattedMessage id="service_status_hr" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_hr_description" />}
                            action={() => history.push("/service-status/service-hr")}
                        />

                        <TsCard
                            title={<FormattedMessage id="service_status_hub" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_hub_description" />}
                            action={() => history.push("/service-status/service-hub")}
                        />
                        <TsCard
                            title={<FormattedMessage id="service_status_analytics" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_analytics_description" />}
                            action={() => history.push("/service-status/service-analytics")}
                        />
                        <TsCard
                            title={<FormattedMessage id="service_status_ncs" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_ncs_description" />}
                            action={() => history.push("/service-status/service-ncs")}
                        />
                        <TsCard
                            title={<FormattedMessage id="service_status_atlassian" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_atlassian_description" />}
                            action={() => history.push("/service-status/service-atlassian")}
                        />
                        {ENV === "preprod" || ENV === "master" ? (
                            <TsCard
                                title={<FormattedMessage id="service_status_invoice" />}
                                icon="fas fa-server"
                                description={<FormattedMessage id="service_status_invoice_description" />}
                                action={() => history.push("/service-status/service-ts-invoice")}
                            />
                        ) : null}
                        <TsCard
                            title={<FormattedMessage id="service_status_azure" />}
                            icon="fas fa-server"
                            description={<FormattedMessage id="service_status_azure_description" />}
                            action={() => history.push("/service-status/service-azure")}
                        />
                        {ENV === "preprod" || ENV === "master" ? (
                            <TsCard
                                title={<FormattedMessage id="service_status_tsid" />}
                                icon="fas fa-server"
                                description={<FormattedMessage id="service_status_tsid_description" />}
                                action={() => history.push("/service-status/service-ts-id")}
                            />
                        ) : null}
                    </CardServiceWrapper>
                </ServiceViewBlock>
            }
        </div>
    );
};

ServiceStatus.propTypes = {
    history: PropTypes.object.isRequired
};
