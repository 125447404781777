import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { submit, isValid, isSubmitting } from "redux-form";
import { FormattedMessage } from "react-intl";
import { ContractInfo, ContractActions, ListConnection } from "../../components/contract";
import { PdfHandler } from "../../components/general/pdf-handler";
import { Row, Col, Spin, Tabs } from "antd";
import PageHeader from "../../components/general/PageHeader/index";

import { getFullInfoDocument } from "../../actions/contract";
import { getDocument } from "../../actions/documents";
import { searchCompany, searchItems } from "../../actions/search/company";
import { searchConnection } from "../../actions/connections/read";
import { usePrevious } from "../../utils/hook";
import { iVerde } from "../../utils/constant";
import { getOldId } from "../../utils/get-company-id";
import { rejectingContract, validatingContract, pauseContract } from "../../actions/contract";
import { getPrivacyData, setPrivacyData } from "../../actions/certification";

import { ContractWrapper, PdfWrapper, SpinContainer, EmptyContainer, GreenCompanyContainer } from "./styled";
import { findOwnManagedConnections } from "../../actions/connections/read";

const { TabPane } = Tabs;

const ContractView = ({
    admin,
    auth,
    formEnabled,
    getFullInfoDocument,
    documentInfo,
    getDocument,
    getPrivacyData,
    history,
    infoStatus,
    info,
    infoConnections,
    match: {
        params: { id }
    },
    rejectingContract,
    rejectStatus,
    searchConnection,
    searchCompany,
    searchItems,
    findOwnManagedConnections,
    setPrivacyData,
    submitForm,
    validatingContract,
    validateStatus,
    pauseContract,
    pauseStatus
}) => {
    const [data, setData] = useState(null);
    const [privacyPrefereces, setPrivacyPrefereces] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showListCon, setShowListCon] = useState(false);
    const [isGreenCompany, setIsGreenCompany] = useState(false);

    const prevInfoStatus = usePrevious(infoStatus);

    useEffect(() => {
        setData(null);
        setShowListCon(false);
    }, [id]);

    useEffect(() => {
        const asyncSearchConnection = async id => {
            const result = await searchConnection(auth.loginAuth, id);
            const itemsResult = await searchItems(
                auth.loginAuth,
                [result.managedId, result.managerId],
                false,
                10,
                true
            );
            let itemsDetails;
            if (itemsResult) {
                itemsDetails = itemsResult.items.map(v => v.item.base);
            }
            setData({ ...result, itemsDetails });
        };

        const asyncSearchCompany = async id => {
            const result = await searchItems(auth.loginAuth, id, true, 10, true);
            setData(result.items[0]);
        };

        if (prevInfoStatus && !prevInfoStatus.ended && infoStatus.ended) {
            const {
                documentInfo: { policyType }
            } = info;

            switch (policyType) {
                case "CONNECTION":
                    asyncSearchConnection(id);
                    break;
                case "COMPANY":
                    asyncSearchCompany(id);
                    break;

                default:
                    break;
            }
        }
    }, [searchConnection, searchCompany, getDocument, infoStatus, info, auth, prevInfoStatus, searchItems, id]);

    useEffect(() => {
        getFullInfoDocument(auth, id);
        getDocument(auth.loginAuth, id);
    }, [getFullInfoDocument, getDocument, auth, id]);

    useEffect(() => {
        const getPrivacy = async () => {
            setIsLoading(true);
            if (data !== null) {
                const privacy = await getPrivacyData(auth.loginAuth, data.item ? getOldId(data.item) : data.id);
                if (privacy) {
                    setPrivacyPrefereces(privacy.preferences);
                }
                setIsLoading(false);
            }
        };
        getPrivacy();
    }, [getPrivacyData, auth, data]);

    useEffect(() => {
        let isGreenCompany = false;
        if (data && data.managerId && iVerde.includes(data.managerId)) {
            isGreenCompany = true;
        } else if (data && data.item && data.item.base && data.item.base.id && iVerde.includes(data.item.base.id)) {
            isGreenCompany = true;
        }
        setIsGreenCompany(isGreenCompany);
    }, [data]);

    const setPrivacy = (auth, id, values) => {
        setPrivacyData(auth, id, values, false);
        setPrivacyPrefereces(values);
    };

    if (infoStatus.started) {
        return (
            <SpinContainer>
                <Spin size="large" />
            </SpinContainer>
        );
    }

    if (!info && !infoStatus.started) {
        return (
            <ContractWrapper>
                <EmptyContainer>
                    <PageHeader labelId="general.goPageBack" history={history} />
                    <SpinContainer>
                        <FormattedMessage id="the_document_is_not_available" />
                    </SpinContainer>
                </EmptyContainer>
            </ContractWrapper>
        );
    }

    return (
        <div>
            <ContractWrapper>
                <Row>
                    {!showListCon ? (
                        <div>
                            <PageHeader labelId="general.goPageBack" history={history} />
                            <Col span={13}>
                                <ContractInfo
                                    info={!data ? null : info.documentInfo}
                                    loading={!data || isLoading}
                                    type={!data ? null : info.documentInfo.policyType}
                                    data={data}
                                />
                                <ContractActions
                                    admin={admin}
                                    auth={auth}
                                    content={!data ? null : documentInfo.content}
                                    data={data}
                                    formEnabled={formEnabled}
                                    history={history}
                                    info={!data ? null : info.documentInfo}
                                    id={id}
                                    loading={!data || isLoading}
                                    privacyPrefereces={privacyPrefereces}
                                    reject={note => rejectingContract(auth.loginAuth, info.documentInfo.id, note)}
                                    validate={() => validatingContract(auth.loginAuth, info.documentInfo.id)}
                                    pauseContract={() => pauseContract(auth.loginAuth, info.documentInfo.id)}
                                    validateStatus={validateStatus}
                                    rejectStatus={rejectStatus}
                                    setPrivacy={setPrivacy}
                                    submitForm={submitForm}
                                    type={!data ? null : info.documentInfo.status}
                                    showListCon={val => setShowListCon(val)}
                                    pauseStatus={pauseStatus}
                                />
                            </Col>
                            <Col span={11}>
                                {isGreenCompany && (
                                    <GreenCompanyContainer>
                                        <i className="fas fa-info-circle" style={{ marginRight: 8 }} />
                                        <FormattedMessage id="green_company" />
                                    </GreenCompanyContainer>
                                )}
                                <Tabs defaultActiveKey="1">
                                    <TabPane
                                        tab={info && info.documentInfo ? info.documentInfo.policyDescription : ""}
                                        key="1"
                                    >
                                        <PdfWrapper>
                                            <PdfHandler
                                                loading={documentInfo.status.started}
                                                content={!data ? null : documentInfo.content}
                                                fileName={"documento_contratto.pdf"}
                                            />
                                        </PdfWrapper>
                                    </TabPane>
                                    {infoConnections.status.ended &&
                                        infoConnections.info.connections[0].approvalType === "USER" && (
                                            <TabPane tab="Contratto" key="2">
                                                <PdfWrapper>
                                                    <PdfHandler
                                                        loading={documentInfo.status.started}
                                                        content={!documentInfo ? null : documentInfo.content}
                                                        fileName={"documento_contratto.pdf"}
                                                    />
                                                </PdfWrapper>
                                            </TabPane>
                                        )}
                                </Tabs>
                            </Col>
                        </div>
                    ) : (
                        <Col span={24}>
                            <PageHeader
                                labelId="general.goToStudio"
                                customAction={() => {
                                    setShowListCon(false);
                                }}
                            />
                            <ListConnection
                                auth={auth}
                                id={id}
                                history={history}
                                findOwnManagedConnections={findOwnManagedConnections}
                            />
                        </Col>
                    )}
                </Row>
            </ContractWrapper>
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    formEnabled: formName => isValid(formName)(state) && !isSubmitting(formName)(state),
    info: state.contract.getFullInfoDocument.info,
    documentInfo: state.company.documents.getDocument,
    infoConnections: state.contract.getConnections,
    infoStatus: state.contract.getFullInfoDocument.status,
    validateStatus: state.contract.contractActions.validating.status,
    rejectStatus: state.contract.contractActions.rejecting.status,
    pauseStatus: state.contract.contractActions.pauseContract.status
});

const actions = {
    getFullInfoDocument,
    getDocument,
    getPrivacyData,
    rejectingContract,
    searchCompany,
    searchConnection,
    findOwnManagedConnections,
    searchItems,
    setPrivacyData,
    submitForm: formName => submit(formName),
    validatingContract,
    pauseContract
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    )
);

ContractView.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getFullInfoDocument: PropTypes.func.isRequired,
    getDocument: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    infoConnections: PropTypes.object,
    infoStatus: PropTypes.object,
    info: PropTypes.object,
    documentInfo: PropTypes.object,
    searchConnection: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    rejectingContract: PropTypes.func.isRequired,
    validatingContract: PropTypes.func.isRequired,
    validateStatus: PropTypes.object.isRequired,
    rejectStatus: PropTypes.object.isRequired,
    pauseContract: PropTypes.func
};

export default composedHoc(ContractView);
