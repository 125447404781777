import {
    fieldLength,
    fieldRequired,
    isTaxId,
    isVatNumber,
    taxIdExists,
    vatNumberExists
} from "@mondora/agyo-validations";

import combineValidators from "@mondora/agyo-validations/lib/utils/combineValidators";
import orValidators from "@mondora/agyo-validations/lib/utils/orValidators";
import { translateValiationError } from "../translate";
import { addressSchema } from "./address";

import { DOMAIN } from "../../config";

export const companyCreateSchema = {
    type: "object",
    properties: {
        ...addressSchema.properties,
        classifier: {
            type: "string"
        },
        ownerId: {
            type: "string",
            validate: ownerId => fieldRequired("Campo obbligatorio")(ownerId)
        },
        description: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        vatNumber: {
            type: "string",
            validate: (vatNumber, formValues) =>
                isVatNumber("taxRegion", error => translateValiationError(error))(vatNumber, formValues),
            asyncValidate: vatNumberExists("Partita IVA già registrata", DOMAIN, "ts-portale")
        },
        taxRegime: {
            type: "string",
            validate: taxRegime => fieldRequired("Campo obbligatorio")(taxRegime)
        },
        taxRegion: {
            type: "string"
        },
        taxId: {
            type: "string",
            validate: (taxId, formValues) =>
                orValidators(
                    isVatNumber("taxRegion", error => translateValiationError(error)),
                    isTaxId("taxRegion", "classifier", error => translateValiationError(error))
                )(taxId, formValues),
            asyncValidate: taxIdExists("Codice fiscale già registrato", DOMAIN, "ts-portale")
        }
    }
};
