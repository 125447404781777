import { API_URL } from "../../../config";
import { postRestCall } from "../../../utils/rest-api-call";

export const DISABLE_PACKAGE_START = "DISABLE_PACKAGE_START";
export const DISABLE_PACKAGE_SUCCESS = "DISABLE_PACKAGE_SUCCESS";
export const DISABLE_PACKAGE_ERROR = "DISABLE_PACKAGE_ERROR";

export const disablePackage = (auth, id) => {
    return async dispatch => {
        dispatch({
            type: DISABLE_PACKAGE_START
        });

        const toast = {
            onOk: true,
            onOkText: "Servizio disattivato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: id }
        };

        try {
            const result = await postRestCall(
                `${API_URL}/disable/${id}`,
                auth,
                null,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: DISABLE_PACKAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DISABLE_PACKAGE_ERROR,
                error: e
            });
        }
    };
};
