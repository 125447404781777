import {
    CREATE_ITEM_START,
    CREATE_ITEM_ERROR,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_RESET,
    UPDATE_ITEM_START,
    UPDATE_ITEM_ERROR,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_RESET
} from "../actions/item.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function createItem(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CREATE_ITEM_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CREATE_ITEM_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CREATE_ITEM_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case CREATE_ITEM_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
export function updateItem(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_ITEM_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_ITEM_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_ITEM_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPDATE_ITEM_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
