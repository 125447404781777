import styled from "styled-components";
import { Select, Input } from "antd";

export const FilterContent = styled.div`
    background: #f7f7f7;
    border-radius: 5px;
    padding: 10px 10px 5px;
`;

export const CompanyCodeFilterSelect = styled(Input)`
    width: 320px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
`;

export const CategoryFilterSelect = styled(Select)`
    width: 230px;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
`;

export const ActiveFilterSelect = styled(Select)`
    width: 120px;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
`;

export const CertificationStatusFilterSelect = styled(Select)`
    width: 210px;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
`;

export const ClearFilterItems = styled.div`
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 10px;
    text-align: end;
`;
