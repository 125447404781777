import * as config from "../../../config";
import { patchRestCall } from "../../../utils/rest-api-call";

export const UPDATE_SIGNER_START = "UPDATE_SIGNER_START";
export const UPDATE_SIGNER_SUCCESS = "UPDATE_SIGNER_SUCCESS";
export const UPDATE_SIGNER_ERROR = "UPDATE_SIGNER_ERROR";

export const updateCertificateId = (auth, certificateId) => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SIGNER_START
        });
        const urlParams = {
            certificateId: certificateId
        };
        const toast = {
            onOk: true,
            onOkText: "Certificato aggiornato con successo",
            onError: true,
            infoReq: { authId: auth.id, req: certificateId }
        };
        try {
            const result = await patchRestCall(
                `${config.SIGNER_V2_WRITE_URL}/utils/update-ca-certificate`,
                auth,
                urlParams,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: UPDATE_SIGNER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: UPDATE_SIGNER_ERROR,
                error: e
            });
        }
    };
};
