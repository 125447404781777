import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { userIsAuthorized } from "../../../utils/get-roles";

export const restrictToRoleForComponent = role => WrappedComponent => props =>
    props.admin.adminInfo.permits.isSuperUser === role ? (
        <WrappedComponent {...props} />
    ) : (
        <div style={console.error("UTENTE_NON_ABILITATO")}>{""}</div>
    );

export const restrictToPermits = (role, componentName) => WrappedComponent => props => {
    return userIsAuthorized(componentName, props.admin.adminInfo.permits) ? (
        <WrappedComponent {...props} />
    ) : (
        <div style={{ textAlign: "center", marginTop: "15vh" }}>
            <img alt="" src="https://b2bstaticdev.blob.core.windows.net/static-apps/images/bo-404.svg" />
            <div style={{ marginTop: "2vh" }}>
                <span
                    style={{
                        color: " #434e59",
                        fontSize: "52px",
                        fontWeight: 600,
                        lineHeight: "52px"
                    }}
                >
                    {"403"}
                </span>
                <span
                    style={{
                        color: "rgba(0,0,0,.45)",
                        fontSize: "20px",
                        lineHeight: "28px"
                    }}
                >
                    {" Utente non autorizzato."}
                </span>
                <br />
                <br />
                <Link to="/dashboard">
                    <Button type="primary">{"Torna Alla Home"}</Button>
                </Link>
            </div>
        </div>
    );
};
