import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { StandardTabs, TabPane } from "@mondora/arc/antd";
import { SignatureInfoData } from "./signature-info-data";
import { SignatureInfoSigners } from "./signature-info-signers";
import { SignatureInfoDrawer } from "./signature-info-drawer";
import { SignatureInfoCCT } from "./signature-info-cct";

import { ContainerMetadata, Container } from "./styled";

export const SignatureInfo = ({
    displaySuccessToast,
    documentInfo,
    documentDownloaded,
    handleGetSignDocumentDownload,
    handleCctSend,
    isPermittedForCctSend,
    appNameDesc
}) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [signed, setSigned] = useState(false);

    useEffect(() => {
        if (documentInfo.status.ended) {
            if (documentInfo.originalStorageRef) {
                setSigned(false);
            } else {
                setSigned(true);
            }
        }
    }, [documentInfo]);

    const handlePreview = () => {
        setPreviewVisible(true);
        handleGetSignDocumentDownload(signed);
    };
    const resendEmail = () => {};

    const handleChangeSigned = value => {
        let signed;
        if (value === "signed") {
            signed = true;
        } else {
            signed = false;
        }
        setSigned(signed);
        handleGetSignDocumentDownload(signed);
    };

    return (
        <Container>
            {previewVisible && (
                <SignatureInfoDrawer
                    documentDownloaded={documentDownloaded}
                    handleClose={() => setPreviewVisible(false)}
                    signed={signed}
                    handleChangeSigned={handleChangeSigned}
                    disableOrginalDoc={!documentInfo.originalStorageRef}
                    disableSignedDoc={!documentInfo.signedStorageRef}
                    loading={documentDownloaded.status.started}
                />
            )}
            <div>
                <StandardTabs variant="secondary">
                    <TabPane tab={<FormattedMessage id="document_detail" />} key="active-tab">
                        <ContainerMetadata>
                            <SignatureInfoData
                                displaySuccessToast={displaySuccessToast}
                                document={documentInfo}
                                handlePreview={handlePreview}
                                handleCctSend={handleCctSend}
                                isPermittedForCctSend={isPermittedForCctSend}
                                appNameDesc={appNameDesc}
                            />
                        </ContainerMetadata>
                    </TabPane>
                    <TabPane tab={<FormattedMessage id="signatories" />} key="signers">
                        <ContainerMetadata>
                            <SignatureInfoSigners document={documentInfo} resendEmail={resendEmail} />
                        </ContainerMetadata>
                    </TabPane>
                    {documentInfo.metadata && documentInfo.metadata.cct ? (
                        <TabPane tab={<FormattedMessage id="cctData" />} key="cctData">
                            <ContainerMetadata>
                                <SignatureInfoCCT document={documentInfo} />
                            </ContainerMetadata>
                        </TabPane>
                    ) : null}
                </StandardTabs>
            </div>
        </Container>
    );
};

SignatureInfo.propTypes = {
    displaySuccessToast: PropTypes.func,
    documentInfo: PropTypes.object.isRequired,
    documentDownloaded: PropTypes.object.isRequired,
    handleGetSignDocumentDownload: PropTypes.func.isRequired,
    handleCctSend: PropTypes.func.isRequired,
    isPermittedForCctSend: PropTypes.bool.isRequired,
    appNameDesc: PropTypes.object.isRequired
};
