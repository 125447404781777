import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";

import "./style.css";
import { userIsAuthorized } from "../../../../utils/get-roles";

export const InvoicesUnassigned = ({
    admin,
    auth,
    company,
    expanded,
    postUnassignedInvoices,
    postNotAssignabledInvoices
}) => {
    const [unassignedList, setUnassignedList] = useState([]);
    const [notAssignabledList, setNotAssignabledList] = useState([]);
    const [authorized, setAuthorized] = useState(null);

    const locale = {
        emptyText: "Nessun risultato"
    };

    const handleAssignInvoices = () => {
        postUnassignedInvoices(
            auth.loginAuth,
            company.info.base.identifier.taxId,
            company.info.base.identifier.vatNumber,
            company.info.base.identifier.taxRegion
        );
    };

    const handleNotAssignInvoices = () => {
        postNotAssignabledInvoices(
            auth.loginAuth,
            company.info.base.identifier.taxId,
            company.info.base.identifier.vatNumber,
            company.info.base.identifier.taxRegion
        );
    };

    useEffect(() => {
        if (admin.adminInfo != null) {
            let authorized = userIsAuthorized("c-unassigned-invoices-assign-button", admin.adminInfo.permits);
            setAuthorized(authorized);
        }
    }, [admin.adminInfo]);

    useEffect(() => {
        if (company.unassigned_invoice != null) {
            var unassignedList = [];
            if (
                company.unassigned_invoice.unassignedInvoices.page &&
                company.unassigned_invoice.unassignedInvoices.page.totalElements !== 0
            ) {
                unassignedList = company.unassigned_invoice.unassignedInvoices._embedded.unassignedInvoiceEntityList;
            }
            setUnassignedList(unassignedList);
            var notAssignabledList = [];
            if (
                company.unassigned_invoice.notAssignabledInvoices.page &&
                company.unassigned_invoice.notAssignabledInvoices.page.totalElements !== 0
            ) {
                notAssignabledList =
                    company.unassigned_invoice.notAssignabledInvoices._embedded.unassignedInvoiceEntityList;
            }
            setNotAssignabledList(notAssignabledList);
        }
    }, [company.unassigned_invoice]);

    const columns = [
        {
            title: "Paese",
            align: "center",
            width: "5%",
            dataIndex: "countryId"
        },
        {
            title: "Identificativo",
            align: "center",
            width: "10%",
            dataIndex: "codeId"
        },
        {
            title: "Codice Fiscale",
            align: "center",
            width: "15%",
            dataIndex: "taxId"
        },
        {
            title: "Mittente",
            align: "center",
            dataIndex: "senderName"
        },
        {
            title: "Codice SDI",
            align: "center",
            width: "10%",
            dataIndex: "sdiId"
        },
        {
            title: "Id Doc.",
            align: "center",
            width: "10%",
            dataIndex: "docId"
        },
        {
            title: "Data Doc.",
            align: "center",
            width: "10%",
            dataIndex: "docDate",
            render: text => <span>{text ? text : ""}</span>
        },
        {
            title: "Data ric",
            align: "center",
            width: "10%",
            dataIndex: "receiptDate",
            render: text => <span>{text ? new Date(text).toLocaleString() : ""}</span>
        },
        {
            title: "Data aggiornamento",
            align: "center",
            width: "10%",
            dataIndex: "updatedAt",
            render: text => <span>{text ? new Date(text).toLocaleString() : ""}</span>
        }
    ];

    return (
        <div>
            <div className="assign-button">
                <Button onClick={() => handleAssignInvoices()} disabled={unassignedList.length === 0}>
                    Assegna
                </Button>
            </div>
            <p className="a1">Da assegnare</p>
            <Table
                rowKey={record => record.eventHash}
                size={"small"}
                columns={columns}
                dataSource={unassignedList}
                locale={locale}
                bordered={true}
                pagination={false}
            />
            <br />
            {authorized ? (
                <div className="assign-button">
                    <Button onClick={() => handleNotAssignInvoices()} disabled={notAssignabledList.length === 0}>
                        Assegna
                    </Button>
                </div>
            ) : null}
            <p className="a1">Non assegnabili (CF o Partita Iva non coincidente con quello aziendale)</p>
            <Table
                rowKey={record => record.eventHash}
                size={"small"}
                columns={columns}
                dataSource={notAssignabledList}
                locale={locale}
                bordered={true}
                pagination={false}
            />
        </div>
    );
};

InvoicesUnassigned.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    postUnassignedInvoices: PropTypes.func.isRequired,
    postNotAssignabledInvoices: PropTypes.func.isRequired
};
