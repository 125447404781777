import styled from "styled-components";

export const MeteringViewBody = styled.div`
    display: flex;
    flex-flow: wrap;
`;

export const MeteringViewSpinner = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const MeteringViewPanelEmpty = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;
