import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Select, Typography } from "antd";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { Payment } from "../../../components/company/payment";
import { getPayments } from "../../../actions/payment";

const PaymentView = ({ auth, company, history, getPayments }) => {
    const viewModel = useCompanyPaymentsView(auth.loginAuth, company, getPayments);

    return (
        <ServiceCard
            history={history}
            content={
                <Payment
                    loading={viewModel.loadingPayments}
                    paymentsEntityList={viewModel.paymentsEntityList}
                    handleNextPage={viewModel.handleNextPagePayments}
                    handlePageSizeChanged={viewModel.handlePageSizeChangedPayments}
                    handlePreviousPage={viewModel.handlePreviousPagePayments}
                    hasNextPage={viewModel.hasNextPagePayments}
                    currentPage={viewModel.currentPagePayments}
                    defaultPageSize={viewModel.defaultPageSize}
                    pageSizeOptions={viewModel.pageSizeOptions}
                    query={viewModel.queryPayments}
                />
            }
            serviceInfo={{ name: <FormattedMessage id="payment" />, category: "EIP" }}
            statisticsEnable={false}
        />
    );
};

PaymentView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getPayments: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company
});

const actions = { getPayments };

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(PaymentView);

const useCompanyPaymentsView = (auth, company, getPayments) => {
    let companyUuid = company.info.base.uuid;
    let defaultPageSize = "20";
    let pageSizeOptions = [
        <Select.Option key="20" value="20">
            20
        </Select.Option>,
        <Select.Option key="40" value="40">
            40
        </Select.Option>,
        <Select.Option key="60" value="60">
            60
        </Select.Option>,
        <Select.Option key="80" value="80">
            80
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>
    ];

    const [loadingPayments, setLoadingPayments] = useState(true);
    const [paymentsEntityList, setPaymentsEntityList] = useState([]);
    const [currentPagePayments, setCurrentPagePayments] = useState(1);
    const [pageSizePayments, setPageSizePayments] = useState(defaultPageSize);
    const [hasNextPagePayments, setHasNextPagePayments] = useState(false);
    const [continuationTokenPayments, setContinuationTokenPayments] = useState([null]);
    const [queryPayments, setQueryPayments] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        listFilter.push("ownerId=" + companyUuid);
        listFilter.push("size=" + pageSizePayments);
        if (continuationTokenPayments && continuationTokenPayments[currentPagePayments - 1]) {
            listFilter.push("continuationToken=" + continuationTokenPayments[currentPagePayments - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQueryPayments(query);
    }, [currentPagePayments, pageSizePayments, companyUuid, continuationTokenPayments]);

    useEffect(() => {
        const getPaymentsInfo = async () => {
            let filter = {
                ownerId: companyUuid,
                timestamp: "1641032734000",
                direction: "desc",
                continuationToken: continuationTokenPayments[currentPagePayments - 1]
            };
            let result = await getPayments(auth, filter, pageSizePayments);
            if (result) {
                if (result.content) {
                    setPaymentsEntityList(result.content);
                }
                setHasNextPagePayments(result.hasNext);
                if (continuationTokenPayments.length <= currentPagePayments) {
                    continuationTokenPayments.push(result.continuationToken);
                }
                setContinuationTokenPayments(continuationTokenPayments);
            }
            setLoadingPayments(false);
        };

        setLoadingPayments(true);
        setPaymentsEntityList([]);
        setHasNextPagePayments(false);
        getPaymentsInfo();
    }, [auth, companyUuid, continuationTokenPayments, currentPagePayments, getPayments, pageSizePayments]);

    const handlePageSizeChangedPayments = pageSize => {
        if (pageSize) {
            setCurrentPagePayments(1);
            setPageSizePayments(parseInt(pageSize));
            setContinuationTokenPayments([null]);
        }
    };

    const handleNextPagePayments = () => {
        if (hasNextPagePayments) {
            setCurrentPagePayments(currentPagePayments + 1);
        }
    };

    const handlePreviousPagePayments = () => {
        if (currentPagePayments !== 1) {
            setCurrentPagePayments(currentPagePayments - 1);
        }
    };

    return {
        defaultPageSize,
        pageSizeOptions,
        loadingPayments,
        paymentsEntityList,
        handleNextPagePayments,
        currentPagePayments,
        hasNextPagePayments,
        handlePreviousPagePayments,
        handlePageSizeChangedPayments,
        queryPayments
    };
};
