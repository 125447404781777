import createHmac from "create-hmac";

export const setElevio = (admin, elevioLoad, setElevioState) => {
    let elevioAccountSecret = "5c93b01439bb3";
    const hmac = createHmac("sha256", Buffer.from(elevioAccountSecret));
    hmac.update(admin.adminInfo.profile.email);
    let hash = hmac.digest("hex");

    if (window._elev && window._elev.setUser && window._elev.setLanguage && !elevioLoad) {
        window._elev.setLanguage("it");
        window._elev.setUser({
            first_name: admin.adminInfo.profile.firstName,
            last_name: admin.adminInfo.profile.lastName,
            email: admin.adminInfo.profile.email,
            user_hash: hash
        });
        setElevioState(true);
    }
};
