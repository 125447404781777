import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

import { Button, Col, Row } from "antd";

class UserNotEnable extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };

    render() {
        const { auth, logout } = this.props;
        return (
            <div className="pageUserNotEnable">
                <Row justify="center" type="flex">
                    <Col style={{ width: "80%" }}>
                        <img alt="" src="https://b2bstaticdev.blob.core.windows.net/static-apps/images/bo-404.svg" />

                        <br />
                        <br />
                        <span style={{ lineHeight: "28px" }}> Utente non autorizzato al BackOffice.</span>
                        <br />
                        <br />
                        <Button onClick={() => logout(auth.loginAuth)} type="primary" className="root-button">
                            {"Esci"}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UserNotEnable;
