import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { message, Tabs } from "antd";
import { FormattedMessage } from "react-intl";

import { getCCTConfig } from "../../../actions/cct/flow-config";
import { getCctProvisioningRequest } from "../../../actions/cct/read";
import { postCctProvisioningRequest, postResetCctPassword, deleteResetCctPassword } from "../../../actions/cct/write";
import { getPackageSubscriptions } from "../../../actions/usage/read";

import { findOwnManagedConnections, findOwnManagerConnections } from "../../../actions/connections/read";

import { searchCompany, searchItems } from "../../../actions/search/company";
import { searchCCTConfig } from "../../../actions/search/cct-flow-config";
import { userIsAuthorized } from "../../../utils/get-roles";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { CCTConfig } from "../../../components/company/cct-flow/cct-config";
import { CCTConfigPersonal } from "../../../components/company/cct-flow/cct-config-personal";
import { CCTProvisioning } from "../../../components/company/cct-flow/cct-provisioning";
import { CCTManaged } from "../../../components/company/cct-flow/cct-managed";
import { CCTManager } from "../../../components/company/cct-flow/cct-manager";

import { CCTContent } from "./styled";

const CctFlowView = ({
    getCCTConfig,
    history,
    company,
    auth,
    admin,
    getCctProvisioningRequest,
    postCctProvisioningRequest,
    postResetCctPassword,
    deleteResetCctPassword,
    getPackageSubscriptions,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchItems,
    searchCompany,
    intl,
    searchCCTConfig
}) => {
    const viewModel = useCctFlowView(
        auth.loginAuth,
        admin,
        company,
        intl,
        getCCTConfig,
        getCctProvisioningRequest,
        searchCompany,
        searchItems,
        getPackageSubscriptions,
        findOwnManagedConnections,
        findOwnManagerConnections,
        searchCCTConfig,
        postCctProvisioningRequest,
        postResetCctPassword,
        deleteResetCctPassword
    );

    return (
        <ServiceCard
            history={history}
            content={
                <CCTContent>
                    <Tabs variant="secondary" defaultActiveKey="1">
                        <Tabs.TabPane tab={<FormattedMessage id="c-cctconfigservice.tecnicalCredential" />} key="1">
                            <Tabs variant="secondary" defaultActiveKey="1" tabPosition="right">
                                <Tabs.TabPane tab={<FormattedMessage id="c-cctconfigservice.coupon" />} key="1">
                                    <CCTProvisioning
                                        companyCf={viewModel.companyCf}
                                        dataSource={viewModel.cctProvisioningItem}
                                        loading={viewModel.cctProvisioningLoading}
                                        button1000Disabled={viewModel.cctProvisioningDisabledCoupon1000}
                                        rowDetailsDisabled={viewModel.cctProvisioningRowDetailsDisabled}
                                        handleActiveCoupon1000={viewModel.handleActiveCoupon1000}
                                        button1000Loading={viewModel.cctProvisioningLoadingCoupon1000}
                                        cctOwnerList={viewModel.cctProvisioningOwnerList}
                                        cctOwnerListLoading={viewModel.cctProvisioningOwnerListLoading}
                                        handleRemoveRdcTbf={viewModel.handleDeleteCctRdcToBeFixed}
                                        actionRemoveRdcTbfDisabled={viewModel.cctProvisioningReqeustRemoveRdcDisabled}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<FormattedMessage id="c-cctconfigservice.credential" />} key="2">
                                    <CCTConfig
                                        loading={viewModel.cctConfigLoading}
                                        dataSource={viewModel.cctConfigItem}
                                        handleResetPassword={viewModel.handleResetCctPassword}
                                        actionResetPwdDisabled={viewModel.cctProvisioningResetPwdDisabled}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<FormattedMessage id="c-cctconfigservice.connection" />} key="3">
                                    <CCTManager
                                        loading={viewModel.cctManagerLoading}
                                        dataSource={viewModel.cctManagerItems}
                                        isEnableCoupon1000={viewModel.cctProvisioningHasCoupon1000}
                                        handleEnableCoupon1004={viewModel.handleActiveCoupon1004}
                                        handleGetConnection={viewModel.handleGetCctManagerList}
                                        paginationTotal={viewModel.cctManagerListTotalItems}
                                        paginationCurrent={viewModel.cctManagerListCurrentPage}
                                        companyCertificationStatus={viewModel.companyCertificationStatus}
                                    />
                                    <CCTManaged
                                        loading={viewModel.cctManagedLoading}
                                        dataSource={viewModel.cctManagedItems}
                                        isEnableCoupon1000={viewModel.cctProvisioningHasCoupon1000}
                                        handleEnableCoupon1004={viewModel.handleActiveCoupon1004}
                                        handleGetConnection={viewModel.handleGetCctManagedList}
                                        paginationTotal={viewModel.cctManagedListTotalItems}
                                        paginationCurrent={viewModel.cctManagedListCurrentPage}
                                        companyCertificationStatus={viewModel.companyCertificationStatus}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id="c-cctconfigservice.personalCredential" />} key="2">
                            <CCTConfigPersonal
                                loading={viewModel.cctConfigLoading}
                                dataSource={viewModel.cctConfigPersonalItem}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </CCTContent>
            }
            serviceInfo={{ name: "CCTConfig", category: "ARC" }}
            statisticsEnable={false}
        />
    );
};

CctFlowView.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    company: PropTypes.object,
    getCCTConfig: PropTypes.func.isRequired,
    getCctProvisioningRequest: PropTypes.func.isRequired,
    postCctProvisioningRequest: PropTypes.func.isRequired,
    postResetCctPassword: PropTypes.func.isRequired,
    deleteResetCctPassword: PropTypes.func.isRequired,
    getPackageSubscriptions: PropTypes.func.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    searchItems: PropTypes.func.isRequired,
    intl: PropTypes.object,
    searchCCTConfig: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company,
    admin: state.admin
});

const actions = {
    getCCTConfig,
    getCctProvisioningRequest,
    postCctProvisioningRequest,
    postResetCctPassword,
    deleteResetCctPassword,
    getPackageSubscriptions,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchCompany,
    searchItems,
    searchCCTConfig
};

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CctFlowView);

const useCctFlowView = (
    auth,
    admin,
    company,
    intl,
    getCCTConfig,
    getCctProvisioningRequest,
    searchCompany,
    searchItems,
    getPackageSubscriptions,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchCCTConfig,
    postCctProvisioningRequest,
    postResetCctPassword,
    deleteResetCctPassword
) => {
    // Public Variables
    const [cctProvisioningItem, setCctProvisioningItem] = useState([]);
    const [cctProvisioningLoading, setCctProvisioningLoading] = useState(true);
    const [cctProvisioningDisabledCoupon1000, setCctProvisioningDisabledCoupon1000] = useState(true);
    const [cctProvisioningRowDetailsDisabled, setCctProvisioningRowDetailsDisabled] = useState(true);
    const [cctProvisioningResetPwdDisabled, setCctProvisioningResetPwdDisabled] = useState(true);
    const [cctProvisioningReqeustRemoveRdcDisabled, setCctProvisioningReqeustRemoveRdcDisabled] = useState(true);
    const [cctProvisioningLoadingCoupon1000, setCctProvisioningLoadingCoupon1000] = useState(false);
    const [cctProvisioningOwnerList, setCctProvisioningOwnerList] = useState([]);
    const [cctProvisioningOwnerListLoading, setCctProvisioningOwnerListLoading] = useState(false);
    const [cctConfigLoading, setCctConfigLoading] = useState(true);
    const [cctConfigItem, setCctConfigItem] = useState([]);
    const [cctConfigPersonalItem, setCctConfigPersonalItem] = useState([]);
    const [cctManagerIsNotEmpty, setCctManagerIsNotEmpty] = useState(false);
    const [cctManagerLoading, setCctManagerLoading] = useState(true);
    const [cctManagerItems, setCctManagerItems] = useState([]);
    const [cctProvisioningHasCoupon1000, setCctProvisioningHasCoupon1000] = useState(false);
    const [cctManagerListTotalItems, setCctManagerListTotalItems] = useState(0);
    const [cctManagerListCurrentPage, setCctManagerListCurrentPage] = useState(0);
    const [cctManagedIsNotEmpty, setCctManagedIsNotEmpty] = useState(false);
    const [cctManagedLoading, setCctManagedLoading] = useState(true);
    const [cctManagedItems, setCctManagedItems] = useState([]);
    const [cctManagedListTotalItems, setCctManagedListTotalItems] = useState(0);
    const [cctManagedListCurrentPage, setCctManagedListCurrentPage] = useState(0);

    // Private Variables
    const [refreshCctProvisioning, setRefreshCctProvisioning] = useState(false);
    const [cctManagerListFilter, setCctManagerListFilter] = useState(null);
    const [cctManagedListFilter, setCctManagedListFilter] = useState(null);

    const getCctProvisioningOwnerList = useCallback(
        async (filters, page, itemForPage) => {
            let items = [];
            let totalItems;

            let prov = await getCctProvisioningRequest(auth, filters, page, itemForPage);
            if (prov && prov.provisioning && prov.provisioning.length) {
                totalItems = prov.totalItems;
                let ids = [];
                for (const item of prov.provisioning) {
                    if (item.cfMain !== item.cfAzienda) {
                        ids.push(item.cfAzienda);
                    }
                }

                let companyItems;
                if (ids.length > 0) {
                    companyItems = await searchItems(auth, ids, null, 1000);
                }

                for (const item of prov.provisioning) {
                    if (item.cfMain !== item.cfAzienda) {
                        let ownerItem = { ...item, companyDescription: "" };

                        if (companyItems && companyItems.items) {
                            let companyInfo = companyItems.items.find(x => x.item.base.id === item.cfAzienda);
                            if (companyInfo) {
                                ownerItem.companyDescription = companyInfo.item.base.details.description;
                            }
                        }

                        items.push(ownerItem);
                    } else {
                        totalItems--;
                    }
                }
            }

            return { items, totalItems };
        },
        [auth, getCctProvisioningRequest, searchItems]
    );

    useEffect(() => {
        const isActiveCctConfigForCompany = () => {
            let isActive = false;
            if (company.active && company.active.services && company.active.services.length) {
                let findCompany = company.active.services.find(item => {
                    if (
                        item.owner &&
                        item.agyoService &&
                        item.agyoService.id &&
                        item.agyoService.active &&
                        item.agyoService.id === "CCT-FLOW"
                    ) {
                        return true;
                    }
                    return false;
                });
                if (findCompany) {
                    isActive = true;
                }
            }
            return isActive;
        };

        const getItemCctSpecsList = async (itemId, ownerId) => {
            let cctConfig = [];

            let cctConfigResult = await getCCTConfig(auth, itemId, ownerId);
            if (cctConfigResult) {
                cctConfig = cctConfigResult;
            }

            return cctConfig;
        };

        const checkButton1000Disabled = async () => {
            let button1000Disabled = true;
            if (userIsAuthorized("c-cct-config-button-1000-unlock", admin.adminInfo.permits)) {
                button1000Disabled = false;
            } else {
                let packageSubscriptions = await getPackageSubscriptions(auth, company.info.base.uuid, true);
                if (packageSubscriptions) {
                    if (userIsAuthorized("c-cct-config-button-1000", admin.adminInfo.permits)) {
                        Object.values(packageSubscriptions).forEach(element => {
                            let family = String(element.family);
                            if (["FATTURE_ATTIVE_PASSIVE", "FICAGYO"].includes(family)) {
                                button1000Disabled = false;
                            }
                        });
                    }
                }
            }
            setCctProvisioningDisabledCoupon1000(button1000Disabled);
        };

        const getCctProvisioningListAndCctConfig = async () => {
            let cctProvisioning = [];
            let cctConfig = [];
            let cctConfigTec = [];
            let cctConfigPersonal = [];
            let hasCoupon1000 = false;
            let hasCoupon = false;
            let filters = {
                "cf-titolari": company.info.base.identifier.taxId
            };
            let prov = await getCctProvisioningRequest(auth, filters, 0, 10);

            if (prov && prov.provisioning && prov.provisioning.length) {
                if (userIsAuthorized("c-cct-config-action-reset-pwd", admin.adminInfo.permits)) {
                    setCctProvisioningResetPwdDisabled(false);
                }

                if (userIsAuthorized("c-cct-config-action-delete-rdc", admin.adminInfo.permits)) {
                    setCctProvisioningReqeustRemoveRdcDisabled(false);
                }

                if (userIsAuthorized("c-cct-config-prov-row-details", admin.adminInfo.permits)) {
                    setCctProvisioningRowDetailsDisabled(false);
                }

                if (!prov.provisioning.find(x => x.codiceCoupon === "1000")) {
                    checkButton1000Disabled();
                } else {
                    if (userIsAuthorized("c-cct-config-button-1000-unlock", admin.adminInfo.permits)) {
                        setCctProvisioningDisabledCoupon1000(false);
                    }
                }

                for await (const item of prov.provisioning) {
                    if (company.info.base.identifier.taxId === item.cfAzienda) {
                        cctProvisioning.push(item);
                    }

                    if (item.codiceCoupon === "1000" && item.statusCode === "validated") {
                        hasCoupon1000 = true;
                    }

                    if (
                        (item.codiceCoupon === "1000" || item.codiceCoupon === "1004") &&
                        item.statusCode === "validated"
                    ) {
                        hasCoupon = true;
                        let cctConfigResult = await getItemCctSpecsList(item.cfAzienda, item.cfMain);

                        if (cctConfigResult) {
                            cctConfig = [cctConfigResult];
                        }
                    }
                }

                if (!hasCoupon && isActiveCctConfigForCompany()) {
                    let cctConfigResult = await getItemCctSpecsList(
                        company.info.base.identifier.taxId,
                        company.info.base.identifier.taxId
                    );

                    if (cctConfigResult) {
                        cctConfig = [cctConfigResult];
                    }
                }
            } else {
                checkButton1000Disabled();
                if (isActiveCctConfigForCompany()) {
                    let cctConfigResult = await getItemCctSpecsList(
                        company.info.base.identifier.taxId,
                        company.info.base.identifier.taxId
                    );

                    if (cctConfigResult) {
                        cctConfig = [cctConfigResult];
                    }
                }
            }

            cctConfigPersonal = cctConfig.filter(c => c.agyoFepa === false);

            cctConfigTec = cctConfig.filter(c => c.agyoFepa === true);

            if (cctConfigTec.length === 0 && hasCoupon1000) {
                cctConfigTec = [
                    {
                        ownerId: company.info.base.identifier.taxId
                    }
                ];
            }
            setCctProvisioningItem(cctProvisioning);
            setCctProvisioningLoading(false);
            setCctProvisioningHasCoupon1000(hasCoupon1000);
            setCctConfigItem(cctConfigTec);
            setCctConfigPersonalItem(cctConfigPersonal);
            setCctConfigLoading(false);
        };

        setCctProvisioningLoading(true);
        setCctProvisioningItem([]);
        setCctProvisioningDisabledCoupon1000(true);
        setCctProvisioningRowDetailsDisabled(true);
        setCctProvisioningResetPwdDisabled(true);
        setCctProvisioningReqeustRemoveRdcDisabled(true);
        setCctProvisioningLoadingCoupon1000(false);
        setCctProvisioningHasCoupon1000(false);
        setCctConfigLoading(true);
        setCctConfigItem([]);
        setCctConfigPersonalItem([]);
        getCctProvisioningListAndCctConfig();
    }, [
        admin.adminInfo.permits,
        auth,
        company.active,
        company.active.services,
        company.info.base.id,
        company.info.base.uuid,
        company.info.base.identifier.taxId,
        getCCTConfig,
        getCctProvisioningRequest,
        getPackageSubscriptions,
        refreshCctProvisioning,
        searchCCTConfig
    ]);

    useEffect(() => {
        const getCctOwnerList = async () => {
            let ownerList = [];
            let totalItems = 0;
            let currentPage = 0;
            let filters = {
                "cf-produttore": company.info.base.identifier.taxId,
                codiceCoupon: 1004
            };

            do {
                let res = await getCctProvisioningOwnerList(filters, currentPage, 1000);
                if (res.items) {
                    ownerList = ownerList.concat(res.items);
                }
                totalItems = res.totalItems ? res.totalItems : 0;
                currentPage++;
            } while (ownerList.length < totalItems);

            setCctProvisioningOwnerList(ownerList);
            setCctProvisioningOwnerListLoading(false);
        };

        setCctProvisioningOwnerList([]);
        setCctProvisioningOwnerListLoading(true);

        if (cctProvisioningHasCoupon1000) {
            getCctOwnerList();
        } else {
            setCctProvisioningOwnerListLoading(false);
        }
    }, [
        auth,
        cctProvisioningHasCoupon1000,
        company.info.base.identifier.taxId,
        getCctProvisioningOwnerList,
        getCctProvisioningRequest,
        searchItems
    ]);

    useEffect(() => {
        const getManagerList = async () => {
            let managerList = [];
            let managerListTotal = 0;
            let params = { page: cctManagerListCurrentPage, items: 10 };
            let result = await findOwnManagerConnections(
                auth,
                { managedIds: company.info.base.id, ...cctManagerListFilter },
                params
            );
            if (result) {
                if (result.content) {
                    managerList = result.content;
                }
                if (result.totalElements) {
                    managerListTotal = result.totalElements;
                }
            }
            setCctManagerItems(managerList);
            setCctManagerListTotalItems(managerListTotal);
            setCctManagerIsNotEmpty(cctManagerListFilter ? true : managerList.length);
            setCctManagerLoading(false);
        };

        setCctManagerLoading(true);
        setCctManagerIsNotEmpty(true);
        setCctManagerItems([]);
        setCctManagerListTotalItems(0);
        getManagerList();
    }, [auth, cctManagerListCurrentPage, cctManagerListFilter, company.info.base.id, findOwnManagerConnections]);

    useEffect(() => {
        const getManagedList = async () => {
            let managedList = [];
            let managedListTotal = 0;
            let params = { page: cctManagedListCurrentPage, items: 10 };
            let result = await findOwnManagedConnections(
                auth,
                { managerIds: company.info.base.uuid, ...cctManagedListFilter },
                params
            );
            if (result) {
                if (result.content) {
                    managedList = result.content;
                }
                if (result.totalElements) {
                    managedListTotal = result.totalElements;
                }
            }
            setCctManagedItems(managedList);
            setCctManagedListTotalItems(managedListTotal);
            setCctManagedIsNotEmpty(cctManagedListFilter ? true : managedList.length);
            setCctManagedLoading(false);
        };

        setCctManagedLoading(true);
        setCctManagedIsNotEmpty(true);
        setCctManagedItems([]);
        setCctManagedListTotalItems(0);
        getManagedList();
    }, [auth, cctManagedListCurrentPage, cctManagedListFilter, company.info.base.uuid, findOwnManagedConnections]);

    const handleActiveCoupon1000 = async () => {
        setCctProvisioningLoadingCoupon1000(true);
        let filters = {
            "cf-titolare": company.info.base.identifier.taxId,
            "cf-produttore": company.info.base.identifier.taxId
        };
        let result = await postCctProvisioningRequest(auth, filters);
        if (result) {
            message.success(
                intl.formatMessage(
                    {
                        id: "c-cctconfigview.msg-couponenabled"
                    },
                    { coupon: "1000" }
                )
            );
            setRefreshCctProvisioning(!refreshCctProvisioning);
        } else {
            message.error(
                intl.formatMessage(
                    {
                        id: "c-cctconfigview.msg-couponcannotenabled"
                    },
                    { coupon: "1000" }
                )
            );
        }
        setCctProvisioningLoadingCoupon1000(false);
    };

    const handleActiveCoupon1004 = async item => {
        setCctManagerLoading(true);
        let companyInfo = await searchCompany(auth, item.managedId, "BASE", true);
        if (companyInfo.item.base.status.certificationStatus === "CERTIFIED") {
            let filters = {
                "cf-titolare": item.managedId,
                "cf-produttore": company.info.base.identifier.taxId
            };
            let result = await postCctProvisioningRequest(auth, filters);
            if (result) {
                message.success(
                    intl.formatMessage(
                        {
                            id: "c-cctconfigview.msg-couponenabled"
                        },
                        { coupon: "1004" }
                    )
                );
            } else {
                message.error(
                    intl.formatMessage(
                        {
                            id: "c-cctconfigview.msg-couponcannotenabled"
                        },
                        { coupon: "1004" }
                    )
                );
            }
        } else {
            message.error(
                intl.formatMessage(
                    {
                        id: "c-cctconfigview.msg-couponcannotenabled"
                    },
                    { coupon: "1004" }
                ) +
                    ", " +
                    intl.formatMessage({
                        id: "the_managed_is_not_certified"
                    })
            );
        }
        setCctManagerLoading(false);
    };

    const handleResetCctPassword = async item => {
        setCctConfigLoading(true);

        let result = await postResetCctPassword(auth, item.ownerId);

        if (result.code === true) {
            message.success(
                intl.formatMessage({
                    id: "c-cctconfigview.msg-pwdresetted"
                })
            );
        } else {
            message.error(
                intl.formatMessage({
                    id: "c-cctconfigview.msg-pwdnotresetted"
                })
            );
        }
        setCctConfigLoading(false);
    };

    const handleDeleteCctRdcToBeFixed = async item => {
        setCctConfigLoading(true);

        let result = await deleteResetCctPassword(auth, item.id);

        if (result.code === true) {
            message.success(
                intl.formatMessage({
                    id: "c-cctconfigview.msg-rdctbfremoved"
                })
            );
        } else {
            message.error(
                intl.formatMessage({
                    id: "c-cctconfigview.msg-rdctbfnotremoved"
                })
            );
        }
        setCctConfigLoading(false);
    };

    const handleGetCctManagerList = (page, filters, sorter, extra) => {
        let filter = {};

        let currentPage = page.current - 1;

        if (filters) {
            let keys = Object.keys(filters);

            if (keys.includes("managerId")) {
                filter.fullText = filters.managerId;
            } else if (keys.includes("managerDescription")) {
                filter.fullText = filters.managerDescription;
            }
        }
        setCctManagerListCurrentPage(currentPage);
        setCctManagerListFilter(filter);
    };

    const handleGetCctManagedList = (page, filters, sorter) => {
        let filter = {};
        let currentPage = page.current - 1;

        if (filters) {
            let keys = Object.keys(filters);
            if (keys.includes("managedId")) {
                filter.fullText = filters.managedId;
            } else if (keys.includes("managedDescription")) {
                filter.fullText = filters.managedDescription;
            }
        }
        setCctManagedListCurrentPage(currentPage);
        setCctManagedListFilter(filter);
    };

    return {
        cctProvisioningItem,
        cctProvisioningLoading,
        cctProvisioningDisabledCoupon1000,
        cctProvisioningRowDetailsDisabled,
        cctProvisioningLoadingCoupon1000,
        handleActiveCoupon1000,
        cctProvisioningOwnerList,
        cctProvisioningOwnerListLoading,
        cctConfigLoading,
        cctConfigItem,
        cctConfigPersonalItem,
        cctProvisioningHasCoupon1000,
        handleActiveCoupon1004,
        handleResetCctPassword,
        cctProvisioningResetPwdDisabled,
        handleDeleteCctRdcToBeFixed,
        cctProvisioningReqeustRemoveRdcDisabled,
        cctManagerIsNotEmpty,
        cctManagerLoading,
        cctManagerItems,
        handleGetCctManagerList,
        cctManagerListTotalItems,
        cctManagerListCurrentPage,
        cctManagedIsNotEmpty,
        cctManagedLoading,
        cctManagedItems,
        handleGetCctManagedList,
        cctManagedListTotalItems,
        cctManagedListCurrentPage,
        companyCertificationStatus: company.info.base.status.certificationStatus,
        companyCf: company.info.base.identifier.taxId
    };
};
