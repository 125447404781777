import styled from "styled-components";

export const DrawerImg = styled.img`
    height: 44px;
    width: 120px;
`;
export const IconContainer = styled.div`
    height: 60px;
    text-align: center;
    justify-content: center;
    line-height: 60px;
    background: ${props => (props.isConsoleAdmin ? "#1e2427" : "white")};
`;
export const IconImage = styled.img`
    height: 40px;
    width: ${props => (props.isOpen ? "40px" : "145px")};
`;
