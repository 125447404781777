import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Checkbox, Select, Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";

import "./style.css";

const DigitalboxService = ({
    activateService,
    disableService,
    auth,
    company,
    isHavePermission,
    services: { digitalbox }
}) => {
    const [activeConfigId, setActiveConfigId] = useState(null);
    const [activeConfig, setActiveConfig] = useState({});
    const [configsOption, setConfigOptions] = useState([]);
    const [modalDisable, setModalDisable] = useState(false);

    const handleDeactivate = () => {
        disableService(auth.loginAuth, activeConfig.itemUuid, "DIGITALBOX", "", activeConfig.ownerUuid);
        setModalDisable(false);
    };
    const handleChangePackage = checkValue => {
        activateService(
            auth.loginAuth,
            company.info.base.id,
            "DIGITALBOX",
            { pro: checkValue.toString() },
            company.info.base.id,
            { onOkText: "Configurazione aggiornata correttamente" }
        );
    };
    useEffect(() => {
        if (digitalbox && digitalbox.configs) {
            let options = [];

            digitalbox &&
                digitalbox.configs.map(config =>
                    options.push(
                        <Select.Option key={config.id} value={config.id}>
                            <FormattedMessage
                                id="c-dbox-config.config-selection"
                                values={{
                                    configId: config.id,
                                    configOwner: config.ownerId
                                }}
                            />
                        </Select.Option>
                    )
                );

            setActiveConfigId(digitalbox.configs.length > 0 ? digitalbox.configs[0].id : "");
            setConfigOptions(options);
        }
    }, [setActiveConfigId, setConfigOptions, digitalbox, digitalbox.configs]);

    useEffect(() => {
        if (activeConfigId) {
            const filteredActiveConfig =
                digitalbox && digitalbox.configs && digitalbox.configs.find(config => config.id === activeConfigId);
            setActiveConfig(filteredActiveConfig);
        }
    }, [setActiveConfig, activeConfigId, digitalbox, digitalbox.configs]);

    return activeConfig && activeConfig.id ? (
        <div className="service-digitalbox">
            <div style={{ paddingLeft: "15px" }}>
                <b>
                    <FormattedMessage id="c-dbox-config.select-config" />
                </b>
                <Select
                    value={activeConfigId}
                    style={{ width: 400, marginLeft: "15px" }}
                    onChange={value => setActiveConfigId(value)}
                >
                    {configsOption}
                </Select>

                <b style={{ paddingLeft: "15px" }}>
                    {isHavePermission ? (
                        <Button onClick={() => setModalDisable(true)} type="primary">
                            <FormattedMessage id="disable" />
                        </Button>
                    ) : null}
                </b>
            </div>

            <br />
            <br />
            <span className="a1">
                <b>
                    <FormattedMessage id="c-dbox-config.dbox-config" />
                </b>
            </span>
            <br />
            <br />
            <div className="info">
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.config-id" />{" "}
                    </span>
                    <span>{activeConfig.id}</span>
                </div>
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.name" />{" "}
                    </span>
                    <span> {activeConfig.businessName}</span>
                </div>
            </div>
            <br />

            <div className="info">
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.item-uuid" />{" "}
                    </span>
                    <span>
                        {activeConfig.itemUuid ? (
                            activeConfig.itemUuid
                        ) : (
                            <FormattedMessage id="c-dbox-config.data-unavailable" />
                        )}
                    </span>
                </div>
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.item-cf" />{" "}
                    </span>
                    <span>
                        {activeConfig.itemId ? (
                            activeConfig.itemId
                        ) : (
                            <FormattedMessage id="c-dbox-config.data-unavailable" />
                        )}
                    </span>
                </div>
            </div>
            <br />
            <div className="info">
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.owner-uuid" />{" "}
                    </span>
                    <span>
                        {activeConfig.ownerUuid ? (
                            activeConfig.ownerUuid
                        ) : (
                            <FormattedMessage id="c-dbox-config.data-unavailable" />
                        )}
                    </span>
                </div>
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.owner-cf" />{" "}
                    </span>
                    <span>
                        {activeConfig.ownerId ? (
                            activeConfig.ownerId
                        ) : (
                            <FormattedMessage id="c-dbox-config.data-unavailable" />
                        )}
                    </span>
                </div>
            </div>
            <br />
            <div className="info">
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.package-type" />{" "}
                    </span>
                    <span>
                        <Checkbox
                            disabled={!isHavePermission}
                            defaultChecked={activeConfig.pro}
                            onChange={e => handleChangePackage(e.target.checked)}
                        />{" "}
                        PRO
                    </span>
                </div>
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.accountant" />{" "}
                    </span>
                    <span>
                        {activeConfig.accountant ? (
                            <FormattedMessage id="general.yes" />
                        ) : (
                            <FormattedMessage id="general.no" />
                        )}
                    </span>
                </div>
            </div>
            <br />
            <div className="info">
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.updated-at" />
                    </span>
                    <span>
                        {activeConfig.updatedAt ? (
                            new Date(activeConfig.updatedAt).toLocaleString()
                        ) : (
                            <FormattedMessage id="c-dbox-config.data-unavailable" />
                        )}
                    </span>
                </div>
                <div className="info-divided">
                    <span className="a1">
                        <FormattedMessage id="c-dbox-config.workspace-id" />{" "}
                    </span>
                    <span>
                        {activeConfig.workspaceId ? (
                            activeConfig.workspaceId
                        ) : (
                            <FormattedMessage id="c-dbox-config.data-unavailable" />
                        )}
                    </span>
                </div>
            </div>

            <br />
            <Modal
                centered
                title={<FormattedMessage id="digital-box-disable-title" />}
                visible={modalDisable}
                onOk={() => handleDeactivate()}
                okText={<FormattedMessage id="general.confirm" />}
                cancelText={<FormattedMessage id="general.cancel" />}
                onCancel={() => setModalDisable(false)}
                width={640}
            >
                <div>
                    <span>{<FormattedMessage id="digital-box-confirm-disable" />}</span>
                </div>
            </Modal>
        </div>
    ) : null;
};

DigitalboxService.propTypes = {
    activateService: PropTypes.func.isRequired,
    disableService: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    isHavePermission: PropTypes.bool.isRequired
};

export default DigitalboxService;
