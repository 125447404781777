import {
    GET_METERING_EXTENSIONS_START,
    GET_METERING_EXTENSIONS_SUCCESS,
    GET_METERING_EXTENSIONS_ERROR,
    POST_METERING_EXTENSIONS_START,
    POST_METERING_EXTENSIONS_SUCCESS,
    POST_METERING_EXTENSIONS_ERROR
} from "../../actions/usage/extensions";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const getExtensible = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_METERING_EXTENSIONS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_METERING_EXTENSIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_METERING_EXTENSIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const postExtensions = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_METERING_EXTENSIONS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_METERING_EXTENSIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_METERING_EXTENSIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
