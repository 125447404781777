import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Menu, Icon } from "antd";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { userIsAuthorized } from "../../../utils/get-roles";

import { useFeature } from "../../../utils/hook/use-feature";

import {
    faWrench,
    faUsers,
    faBell,
    faTachometerAlt,
    faIndustry,
    faCertificate,
    faArchive,
    faUserSecret,
    faMoneyBillAlt,
    faChartBar,
    faSignal,
    faRepeat,
    faEnvelope,
    faCctv,
    faUserCircle,
    faProjectDiagram,
    faBrowser
} from "@fortawesome/pro-solid-svg-icons";

export const MenuItems = ({ menuItem, admin, isConsoleAdmin, onClose }) => {
    const coldirettiExportEnabled = useFeature("Coldiretti_Export");
    const hackerMonitorEnabled = useFeature("Hacker_Monitor");
    const spidEnabled = useFeature("Spid_Enabled");
    const ihBoEnabled = useFeature("Tsih_bo");

    let permits = admin.adminInfo.permits;

    if (isConsoleAdmin) {
        return (
            <Menu
                defaultSelectedKeys={["control-pannel-dashboard"]}
                selectedKeys={[menuItem]}
                theme="light"
                style={{ background: "white" }}
            >
                {userIsAuthorized("cp-control-pannel-dashboard", permits) && (
                    <Menu.Item key="control-pannel-dashboard" onClick={() => onClose()}>
                        <Link to="/control-pannel-dashboard">
                            <Icon component={() => <FontAwesomeIcon icon={faTachometerAlt} />} />
                            <FormattedMessage id="general.dashboard" />
                        </Link>
                    </Menu.Item>
                )}
                {userIsAuthorized("cp-control-pannel-users-backoffice", permits) && (
                    <Menu.Item key="control-pannel-users-backoffice" onClick={() => onClose()}>
                        <Link to="/control-pannel-users-backoffice">
                            <Icon component={() => <FontAwesomeIcon icon={faUserSecret} />} />
                            <FormattedMessage id="c-menu.admUsers" />
                        </Link>
                    </Menu.Item>
                )}
                {userIsAuthorized("cp-control-pannel-tools", permits) && (
                    <Menu.Item key="control-pannel-tools" onClick={() => onClose()}>
                        <Link to="/control-pannel-tools">
                            <Icon component={() => <FontAwesomeIcon icon={faWrench} />} />
                            <FormattedMessage id="c-menu.admTools" />
                        </Link>
                    </Menu.Item>
                )}
                {userIsAuthorized("cp-control-pannel-notification", permits) && (
                    <Menu.Item key="control-pannel-notification" onClick={() => onClose()}>
                        <Link to="/control-pannel-notification">
                            <Icon component={() => <FontAwesomeIcon icon={faBell} />} />
                            <FormattedMessage id="c-menu.admTemplates" />
                        </Link>
                    </Menu.Item>
                )}
                {userIsAuthorized("cp-control-pannel-sentinel", permits) && (
                    <Menu.Item key="control-pannel-sentinel" onClick={() => onClose()}>
                        <Link to="/control-pannel-sentinel">
                            <Icon component={() => <FontAwesomeIcon icon={faEnvelope} />} />
                            <FormattedMessage id="c-menu.sentinel" />
                        </Link>
                    </Menu.Item>
                )}
                {userIsAuthorized("cp-control-pannel-app-name", permits) && (
                    <Menu.Item key="control-pannel-app-name" onClick={() => onClose()}>
                        <Link to="/control-pannel-app-name">
                            <Icon component={() => <FontAwesomeIcon icon={faBrowser} />} />
                            <FormattedMessage id="c-menu.appaname" />
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    return (
        <Menu
            defaultSelectedKeys={["dashboard"]}
            selectedKeys={[menuItem]}
            theme={"dark"}
            style={{ background: "#1e2427" }}
        >
            <Menu.Item key="dashboard" onClick={() => onClose()}>
                <Link to="/dashboard">
                    <Icon component={() => <FontAwesomeIcon icon={faTachometerAlt} />} />
                    <FormattedMessage id="c-menu.dashboard" />
                </Link>
            </Menu.Item>
            <Menu.Item key="item" onClick={() => onClose()}>
                <Link to="/item">
                    <Icon component={() => <FontAwesomeIcon icon={faIndustry} />} />
                    <FormattedMessage id="c-menu.items" />
                </Link>
            </Menu.Item>
            <Menu.Item key="user" onClick={() => onClose()}>
                <Link to="/user">
                    <Icon component={() => <FontAwesomeIcon icon={faUsers} />} />
                    <FormattedMessage id="c-menu.users" />
                </Link>
            </Menu.Item>
            {userIsAuthorized("c-sideMenu-drawer-certifications", permits) && (
                <Menu.Item key="certifications" onClick={() => onClose()}>
                    <Link to="/certifications">
                        <Icon component={() => <FontAwesomeIcon icon={faCertificate} />} />
                        <FormattedMessage id="c-certifications.title" />
                    </Link>
                </Menu.Item>
            )}
            {userIsAuthorized("c-sideMenu-drawer-cct-provisioning", permits) && (
                <Menu.Item key="cct-provisioning" onClick={() => onClose()}>
                    <Link to="/cct-provisioning">
                        <Icon component={() => <FontAwesomeIcon icon={faArchive} />} />
                        <FormattedMessage id="c-menu.cct" />
                    </Link>
                </Menu.Item>
            )}
            {userIsAuthorized("c-sideMenu-drawer-bank-link", permits) && (
                <Menu.Item key="bank-link" onClick={() => onClose()}>
                    <Link to="/bank-link">
                        <Icon component={() => <FontAwesomeIcon icon={faMoneyBillAlt} />} />
                        <FormattedMessage id="bank_link" />
                    </Link>
                </Menu.Item>
            )}
            {spidEnabled && userIsAuthorized("c-sideMenu-spid", permits) && (
                <Menu.Item key="spid" onClick={() => onClose()}>
                    <Link to="/spid">
                        <Icon component={() => <FontAwesomeIcon icon={faUserCircle} />} />
                        <FormattedMessage id="c-menu.spid" />
                    </Link>
                </Menu.Item>
            )}
            {coldirettiExportEnabled && userIsAuthorized("c-sideMenu-drawer-report", permits) && (
                <Menu.Item key="report" onClick={() => onClose()}>
                    <Link to="/report">
                        <Icon component={() => <FontAwesomeIcon icon={faChartBar} />} />
                        <FormattedMessage id="report" />
                    </Link>
                </Menu.Item>
            )}
            {userIsAuthorized("c-sideMenu-service-status", permits) && (
                <Menu.Item key="service-status" onClick={() => onClose()}>
                    <Link to="/service-status">
                        <Icon component={() => <FontAwesomeIcon icon={faSignal} />} />
                        <FormattedMessage id="service_status" />
                    </Link>
                </Menu.Item>
            )}
            {userIsAuthorized("c-sideMenu-drawer-nso-relaod", permits) && (
                <Menu.Item key="nso-riassign" onClick={() => onClose()}>
                    <Link to="/nso-riassign">
                        <Icon component={() => <FontAwesomeIcon icon={faRepeat} />} />
                        <FormattedMessage id="nso_riassign" />
                    </Link>
                </Menu.Item>
            )}
            {hackerMonitorEnabled && userIsAuthorized("c-sideMenu-hacker-monitor", permits) && (
                <Menu.Item key="hacker-monitor" onClick={() => onClose()}>
                    <Link to="/hacker-monitor">
                        <Icon component={() => <FontAwesomeIcon icon={faCctv} />} />
                        <FormattedMessage id="hacker-monitor" />
                    </Link>
                </Menu.Item>
            )}
            {ihBoEnabled && userIsAuthorized("c-sideMenu-ih-bo", permits) && (
                <Menu.Item key="ih-bo" onClick={() => onClose()}>
                    <Link to="/ih-bo">
                        <Icon component={() => <FontAwesomeIcon icon={faProjectDiagram} />} />
                        <FormattedMessage id="ih_bo" />
                    </Link>
                </Menu.Item>
            )}
        </Menu>
    );
};

MenuItems.propTypes = {
    menuItem: PropTypes.string,
    admin: PropTypes.object.isRequired,
    isConsoleAdmin: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
