import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal, Button, Tooltip } from "antd";

import { userIsAuthorized } from "../../../utils/get-roles";
import Roles from "../roles";
import AddRoles from "../add-roles";
import CloneRoles from "../clone-roles";
import AdminInfo from "../adm";

import "./style.css";

export const UserRoles = ({
    admin,
    isAdmPage,
    userInfo,
    handleAddRoles,
    handleAddRolesWithPolicy,
    handleRemoveRoles,
    handleGetCompanyAndServiceInfo,
    handleRemoveSelectedRoles,
    companyInfo,
    loadingGetCompanyInfo,
    handleAddRolesWithRefresh,
    handleGetCompanyInfo,
    handleGetServices,
    handleGetConnectionInfo,
    handleChangeStatus,
    handleGetUserInfo,
    handleRemoveAllRoles,
    handleSelectedRoles,
    handleRefreshAllRoles,
    handleSendWelcomEmail,
    handleDeleteTSID
}) => {
    const [addRoleModal, setAddRoleModal] = useState(false);
    const [enableDisableModal, setEnableDisableModal] = useState(false);
    const [cloneRolesModal, setCloneRolesModal] = useState(false);
    const [deleteAllRolesModal, setDeleteAllRolesModal] = useState(false);
    const [deleteSelectedRolesModal, setDeleteSelectedRolesModal] = useState(false);
    const [refreshAllRolesModal, setRefreshAllRolesModal] = useState(false);
    const [deleteTSIDModal, setDeleteTSIDModal] = useState(false);

    let permits = admin.adminInfo.permits;
    let userId = userInfo && userInfo.profile ? userInfo.profile.id : null;
    let userStatusActive = userInfo && userInfo.status && userInfo.status.active;

    const changeStatus = () => {
        let userStatus = userInfo.status.active;
        setEnableDisableModal(false);
        handleChangeStatus(userStatus);
    };

    const removeAllRoles = () => {
        setDeleteAllRolesModal(false);
        handleRemoveAllRoles(userInfo.roles);
    };

    const refreshAllRoles = () => {
        setRefreshAllRolesModal(false);
        handleRefreshAllRoles();
    };

    const deleteTSID = () => {
        setDeleteTSIDModal(false);
        handleDeleteTSID();
    };
    const sendEmail = () => {
        handleSendWelcomEmail();
    };

    return (
        <div className="c-user">
            {isAdmPage ? (
                <div>
                    <AdminInfo
                        admin={admin}
                        userInfo={userInfo}
                        handleAddRoles={handleAddRoles}
                        handleAddRolesWithPolicy={handleAddRolesWithPolicy}
                        handleRemoveRoles={handleRemoveRoles}
                    />
                </div>
            ) : (
                <div>
                    <div style={{ textAlign: "right" }}>
                        {userIsAuthorized("c-user-activate-deactivate", permits) && (
                            <Button
                                type="primary"
                                className="actions-button"
                                onClick={() => setEnableDisableModal(true)}
                                icon={userStatusActive ? "user-delete" : "user-add"}
                                hidden={!userStatusActive}
                            >
                                {userStatusActive ? "Disattiva Utente" : "Attiva utente"}
                            </Button>
                        )}
                        {userIsAuthorized("c-user-clone-roles", permits) && (
                            <Button
                                type="primary"
                                icon="snippets"
                                className="actions-button"
                                onClick={() => setCloneRolesModal(true)}
                            >
                                {"Clona i ruoli di un altro utente"}
                            </Button>
                        )}
                        {userIsAuthorized("c-user-delete-roles", permits) && (
                            <Button
                                type="primary"
                                icon="delete"
                                className="actions-button"
                                onClick={() => setDeleteAllRolesModal(true)}
                            >
                                {"Elimina tutti i ruoli"}
                            </Button>
                        )}
                        {userIsAuthorized("c-user-delete-roles", permits) && (
                            <Button
                                type="primary"
                                icon="delete"
                                className="actions-button"
                                onClick={() => setDeleteSelectedRolesModal(true)}
                            >
                                {"Elimina tutti i ruoli selezionati"}
                            </Button>
                        )}
                        {userIsAuthorized("c-user-reload-roles", permits) && (
                            <Button
                                type="primary"
                                icon="reload"
                                className="actions-button"
                                onClick={() => setRefreshAllRolesModal(true)}
                            >
                                {"Ricarica tutti i ruoli"}
                            </Button>
                        )}
                        {/*userIsAuthorized("c-user-delete-ts-id", permits) &&*/ userInfo.profile.tsid !== null && (
                            <Tooltip placement="top" title={<FormattedMessage id={"c-user-delete-ts-id-info"} />}>
                                <Button
                                    type="primary"
                                    icon="reload"
                                    className="actions-button"
                                    onClick={() => setDeleteTSIDModal(true)}
                                >
                                    {<FormattedMessage id={"c-user-delete-ts-id"} />}
                                </Button>
                            </Tooltip>
                        )}
                        {!userStatusActive && (
                            <Tooltip placement="top" title={<FormattedMessage id={"c-user-send-welcome-mail"} />}>
                                <Button
                                    type="primary"
                                    // bottone nascosto perchè la funzione di Invia mail di benvenuto non è più funzionante
                                    hidden="true"
                                    disabled="true"
                                    icon="rocket"
                                    className="actions-button"
                                    onClick={() => sendEmail()}
                                >
                                    {"Invia mail di benvenuto"}
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                    <br />
                    <br />
                    <p className="paragraph-label">RUOLI TECNICI</p>
                    <Roles
                        admin={admin}
                        userInfo={userInfo}
                        toggleRegistered={true}
                        handleAddRoles={handleAddRoles}
                        handleAddRolesWithPolicy={handleAddRolesWithPolicy}
                        handleRemoveRoles={handleRemoveRoles}
                        handleSelectedRoles={handleSelectedRoles}
                    />
                </div>
            )}
            <br />
            <br />
            <p className="paragraph-label">ALTRI RUOLI</p>
            <Roles
                admin={admin}
                isAdmPage={isAdmPage}
                openAddRole={() => setAddRoleModal(true)}
                toggleRegistered={false}
                userInfo={userInfo}
                handleAddRoles={handleAddRoles}
                handleAddRolesWithPolicy={handleAddRolesWithPolicy}
                handleRemoveRoles={handleRemoveRoles}
                handleSelectedRoles={handleSelectedRoles}
                handleGetCompanyAndServiceInfo={handleGetCompanyAndServiceInfo}
                loadingGetCompanyInfo={loadingGetCompanyInfo}
                companyInfo={companyInfo}
            />

            <Modal
                footer={null}
                onCancel={() => setAddRoleModal(false)}
                className="modal"
                title={`Aggiungi ruolo a ${userId ? userId : ""}`}
                visible={addRoleModal}
                centered
            >
                <AddRoles
                    admin={admin}
                    userInfo={userInfo}
                    handleAddRolesWithRefresh={handleAddRolesWithRefresh}
                    handleAddRolesWithPolicy={handleAddRolesWithPolicy}
                    handleGetCompanyInfo={handleGetCompanyInfo}
                    handleGetServices={handleGetServices}
                    handleGetConnectionInfo={handleGetConnectionInfo}
                />
            </Modal>

            <Modal
                centered
                okText="Si"
                cancelText="No"
                onCancel={() => setEnableDisableModal(false)}
                onOk={() => changeStatus()}
                className="modal"
                title={`${userStatusActive ? "Disattiva utente" : "Attiva utente"} ${userId ? userId : ""}`}
                visible={enableDisableModal}
            >
                {`Sei sicuro di voler ${userStatusActive ? "disattivare l'utente" : "attivare l'utente"} ${
                    userId ? userId : ""
                } ?`}
            </Modal>

            <Modal
                centered
                footer={null}
                onCancel={() => setCloneRolesModal(false)}
                className="modal"
                width={800}
                title={"Clona ruoli"}
                visible={cloneRolesModal}
            >
                <CloneRoles
                    userInfo={userInfo}
                    handleGetUserInfo={handleGetUserInfo}
                    handleAddRolesWithRefresh={handleAddRolesWithRefresh}
                />
            </Modal>

            <Modal
                centered
                onCancel={() => setDeleteAllRolesModal(false)}
                onOk={() => removeAllRoles()}
                className="modal"
                okText="Si"
                cancelText="No"
                width={600}
                title={"Elimina tutti i ruoli"}
                visible={deleteAllRolesModal}
            >
                {`Sei sicuro di voler eliminare tutti i ruoli dell'utente ${userId ? userId : ""} ?`}
            </Modal>

            <Modal
                centered
                onCancel={() => setDeleteTSIDModal(false)}
                onOk={() => deleteTSID()}
                className="modal"
                okText={<FormattedMessage id={"general.yes"} />}
                cancelText={<FormattedMessage id={"general.no"} />}
                width={600}
                title={<FormattedMessage id={"c-user-delete-ts-id-modal-title"} />}
                visible={deleteTSIDModal}
            >
                {<FormattedMessage id={"c-user-delete-ts-id-modal-confirm"} />}
            </Modal>
            <Modal
                centered
                onCancel={() => setDeleteSelectedRolesModal(false)}
                onOk={handleRemoveSelectedRoles}
                className="modal"
                okText="Si"
                cancelText="No"
                width={600}
                title={"Elimina tutti i ruoli selezionati"}
                visible={deleteSelectedRolesModal}
            >
                {`Sei sicuro di voler eliminare tutti i ruoli selezionati dell'utente ${userId ? userId : ""} ?`}
            </Modal>

            <Modal
                centered
                onCancel={() => setRefreshAllRolesModal(false)}
                onOk={() => refreshAllRoles()}
                className="modal"
                okText="Si"
                cancelText="No"
                width={600}
                title={"Reload di tutti i ruoli"}
                visible={refreshAllRolesModal}
            >
                {`Sei sicuro di voler ricarica tutti i ruoli dell'utente ${userId ? userId : ""} ?`}
            </Modal>
        </div>
    );
};

UserRoles.propTypes = {
    admin: PropTypes.object.isRequired,
    isAdmPage: PropTypes.bool.isRequired,
    userInfo: PropTypes.object,
    selectedRows: PropTypes.object,
    handleAddRoles: PropTypes.func.isRequired,
    handleAddRolesWithPolicy: PropTypes.func.isRequired,
    handleRemoveRoles: PropTypes.func.isRequired,
    handleGetCompanyAndServiceInfo: PropTypes.func.isRequired,
    companyInfo: PropTypes.object,
    loadingGetCompanyInfo: PropTypes.bool.isRequired,
    handleAddRolesWithRefresh: PropTypes.func.isRequired,
    handleGetCompanyInfo: PropTypes.func.isRequired,
    handleGetConnectionInfo: PropTypes.func.isRequired,
    handleGetServices: PropTypes.func.isRequired,
    handleChangeStatus: PropTypes.func.isRequired,
    handleGetUserInfo: PropTypes.func.isRequired,
    handleRemoveAllRoles: PropTypes.func.isRequired,
    handleSelectedRoles: PropTypes.func.isRequired,
    handleRemoveSelectedRoles: PropTypes.func.isRequired,
    handleRefreshAllRoles: PropTypes.func.isRequired,
    handleSendWelcomEmail: PropTypes.func.isRequired,
    handleDeleteTSID: PropTypes.func.isRequired
};
