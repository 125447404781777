import { WEBHOOK_URL } from "../../config";
import { postRestCall } from "../../utils/rest-api-call";

export const GET_WEBHOOK_START = "GET_WEBHOOK_START";
export const GET_WEBHOOK_SUCCESS = "GET_WEBHOOK_SUCCESS";
export const GET_WEBHOOK_ERROR = "GET_WEBHOOK_ERROR";

export function getWebhook(auth, taxId, pagination) {
    return async dispatch => {
        const bodyParams = {
            fiscalCodes: [taxId]
        };
        dispatch({
            type: GET_WEBHOOK_START,
            request: bodyParams
        });

        try {
            const result = await postRestCall(
                `${WEBHOOK_URL}/webhook/list`,
                auth,
                {},
                bodyParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_WEBHOOK_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_WEBHOOK_ERROR,
                error: e
            });
        }
    };
}
