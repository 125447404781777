import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.css";

import { Card, Spin, Table } from "antd";
import { Searchbar } from "@mondora/arc/antd/Searchbar";

import { getCervedItem } from "../../../actions/cerved";
import { displayErrorToast } from "../../../actions/toast";

const locale = {
    emptyText: "Nessuna azienda trovata"
};

class CervedSearch extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        cerved: PropTypes.object.isRequired,
        displayErrorToast: PropTypes.func.isRequired,
        getCervedItem: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = {
            showList: false
        };
    }

    componentDidUpdate(prevProps) {
        const { cerved } = this.props;
        if (!prevProps.cerved.cervedItems.companies && cerved.cervedItems.companies) {
            this.setState({
                showList: true
            });
        }
    }

    onSearch(searchValue) {
        const { auth, displayErrorToast, getCervedItem, history } = this.props;
        if (searchValue.length > 0) {
            getCervedItem(auth.loginAuth, searchValue);
            if (!history.location.pathname.includes("cerved")) {
                history.push("dashboard/cerved");
            }
        } else {
            displayErrorToast("La ricerca deve contenere almeno 1 carattere.", true);
        }
    }

    render() {
        const { cerved, history } = this.props;
        const { showList } = this.state;
        let isOnDashboardPage = !history.location.pathname.includes("cerved");

        const columns = [
            {
                title: "Codice fiscale",
                dataIndex: "dati_anagrafici.codice_fiscale"
            },

            {
                title: "Partita iva",
                dataIndex: "dati_anagrafici.partita_iva"
            },
            {
                title: "Denominazione",
                dataIndex: "dati_anagrafici.denominazione"
            }
        ];

        return (
            <div className="c-cerved-search">
                {isOnDashboardPage ? (
                    <div>
                        <Searchbar
                            placeholder="Inserisci l'id dell'azienda"
                            onSearch={v => this.onSearch(v.value.trim())}
                            enterButton
                        />
                    </div>
                ) : (
                    <Card>
                        <div>
                            <div onClick={() => history.goBack()} className="close">
                                <i className="far fa-times-circle close-icon" />
                            </div>
                            <br />
                        </div>
                        <Searchbar
                            placeholder="Inserisci l'id dell'azienda"
                            onSearch={v => this.onSearch(v.value.trim())}
                            enterButton
                        />
                        <div>
                            {cerved.cervedItems.status.started ? (
                                <div className="spinner">
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div>
                                    {showList && (
                                        <div>
                                            <br />
                                            <Table
                                                rowKey={record => record.dati_anagrafici.id_soggetto}
                                                columns={columns}
                                                dataSource={cerved.cervedItems.companies}
                                                size="middle"
                                                locale={locale}
                                                bordered
                                                expandedRowRender={record => (
                                                    <span>
                                                        {" Indirizzo: " +
                                                            record.dati_anagrafici.indirizzo.descrizione +
                                                            " " +
                                                            record.dati_anagrafici.indirizzo.provincia +
                                                            " " +
                                                            record.dati_anagrafici.indirizzo.cap}
                                                    </span>
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Card>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    cerved: state.cerved
});
const actions = { displayErrorToast, getCervedItem };

export default connect(
    mapStateToProps,
    actions
)(CervedSearch);
