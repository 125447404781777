import styled from "styled-components";

export const Container = styled.div`
    font-weight: bold;
    font-size: 18px;
`;

export const PdfWrapper = styled.div`
    border: 1px dashed #0090d1;
    height: 85vh;
    margin: 0px 15px 15px 15px;
`;
export const DownloadButton = styled.div`
    position: absolute;
    right: 15px;
`;
