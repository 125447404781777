import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../../../components/general/error-boundary";
import { getPackageSubscriptions } from "../../../../actions/usage/read";

import { MeteringCard } from "../../../../components/company/metering";
import { meteringPackageDecode } from "../../../../utils/decode-metering-package";
import { Spin, Icon } from "antd";

import { MeteringViewBody, MeteringViewSpinner, MeteringViewPanelEmpty } from "./styled";

const MeteringTabView = ({ company, auth, meteringPackageSubscriptions, getCompanyPackages }) => {
    const [meteringItem, setMeteringItem] = useState([]);

    useEffect(() => {
        getPackageSubscriptions(auth.loginAuth, company.info.base.uuid, false);
    }, [auth, company.info.base.uuid]);

    useEffect(() => {
        let meteringItem = [];

        if (meteringPackageSubscriptions.status.ended) {
            Object.values(meteringPackageSubscriptions.companySubscription).forEach(element => {
                let enabled = false;
                let completeElement = element;
                if (element.expireDate > new Date().toISOString()) {
                    enabled = true;
                }
                completeElement = { ...element, enabled };
                if (element.family != null) {
                    let findedIndex = meteringItem.findIndex(x => x.item.family === completeElement.family);
                    if (findedIndex === -1) {
                        let list = {
                            item: completeElement,
                            list: [completeElement]
                        };
                        meteringItem.push(list);
                    } else {
                        let findedPackage = meteringItem[findedIndex];
                        findedPackage.item.enabled |= completeElement.enabled;
                        findedPackage.list.push(completeElement);
                        meteringItem[findedIndex] = findedPackage;
                    }
                }
            });
        }
        setMeteringItem(meteringItem);
    }, [meteringPackageSubscriptions]);

    return (
        <MeteringViewBody>
            {meteringPackageSubscriptions.status.started ? (
                <MeteringViewSpinner>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                </MeteringViewSpinner>
            ) : meteringItem.length > 0 ? (
                meteringItem.map((element, index) => (
                    <MeteringCard
                        key={index}
                        icon={meteringPackageDecode(element.item.family).icon}
                        color={meteringPackageDecode(element.item.family).color}
                        title={meteringPackageDecode(element.item.family).title}
                        description={element.list}
                        background={meteringPackageDecode(element.item.family).background}
                        status={Boolean(element.item.enabled)}
                        family={element.item.family}
                    />
                ))
            ) : (
                <MeteringViewPanelEmpty>
                    {<FormattedMessage id="c-meteringtabview.emptyservice" />}
                </MeteringViewPanelEmpty>
            )}
        </MeteringViewBody>
    );
};

MeteringTabView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    meteringPackageSubscriptions: PropTypes.object.isRequired,
    getPackageSubscriptions: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company,
    meteringPackageSubscriptions: state.usage.getPackageSubscriptions
});

const actions = {
    getPackageSubscriptions
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(MeteringTabView);
