import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Dropdown, Modal, Badge, Menu, Button, Alert } from "antd";
import { decodeItemSchema } from "../../../utils/create-item-schema";
import { translateClassifier } from "../../../utils/translate";
import { userIsAuthorized } from "../../../utils/get-roles";
import FormLayer from "./form-layer";
import "./style.css";

const locale = {
    emptyText: "Nessun uffico presente"
};

class CompanyLayers extends Component {
    constructor() {
        super();
        this.state = {
            mEnableDisable: false,
            mAdd: false,
            mDelete: false,
            mEdit: false,
            layerSelected: null
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        admin: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        createLayer: PropTypes.func.isRequired,
        deleteLayer: PropTypes.func.isRequired,
        disableLayer: PropTypes.func.isRequired,
        enableLayer: PropTypes.func.isRequired,
        updateLayer: PropTypes.func.isRequired
    };

    handleCreate(layerData) {
        const { auth, company, createLayer } = this.props;
        createLayer(auth.loginAuth, layerData, company.info.base.uuid);
        this.setState({ mAdd: false });
    }

    handleDelete() {
        const { auth, deleteLayer } = this.props;
        const { layerSelected } = this.state;
        deleteLayer(auth.loginAuth, layerSelected.base.id);
        this.setState({ mDelete: false, layerSelected: null });
    }

    handleEditLayer(layerData) {
        const { auth, updateLayer } = this.props;
        const { layerSelected } = this.state;
        let dataToUpdate = {
            ...layerData,
            id: layerSelected.base.id
        };
        updateLayer(auth.loginAuth, dataToUpdate, layerSelected.base.id);
        this.setState({ mEdit: false });
    }

    handleDisableEnable() {
        const { auth, enableLayer, disableLayer } = this.props;
        const { layerSelected } = this.state;
        if (layerSelected.base.status.active) {
            disableLayer(auth.loginAuth, layerSelected.base.id);
            this.setState({ mEnableDisable: false, layerSelected: null });
        } else {
            enableLayer(auth.loginAuth, layerSelected.base.id);
            this.setState({ mEnableDisable: false, layerSelected: null });
        }
        this.setState({ mEnableDisable: false, layerSelected: null });
    }

    renderDelete() {
        const { mDelete, layerSelected } = this.state;
        return (
            layerSelected && (
                <Modal
                    centered
                    okText="Si"
                    cancelText="No"
                    onCancel={() => this.setState({ mDelete: false })}
                    onOk={() => this.handleDelete()}
                    title={"Elimina ufficio " + layerSelected.base.hierarchyId}
                    visible={mDelete}
                >
                    <div>
                        <Alert
                            message="Per non visualizzare l'ufficio nel PORTALE basta la disattivazione dell'ufficio selezionato"
                            banner
                            style={{ margin: "-24px", marginBottom: "20px" }}
                        />
                        {"Sei sicuro di voler eliminare l'ufficio?"}{" "}
                    </div>
                </Modal>
            )
        );
    }

    renderEdit() {
        const { mEdit, layerSelected } = this.state;
        let layerData = decodeItemSchema(layerSelected);
        return (
            layerSelected && (
                <Modal
                    footer={null}
                    centered
                    onCancel={() => this.setState({ mEdit: false })}
                    title={"Modifica ufficio " + layerSelected.base.hierarchyId}
                    visible={mEdit}
                    width={700}
                >
                    <FormLayer
                        action="edit"
                        initialValues={{
                            ...layerData,
                            id: layerSelected.base.hierarchyId
                        }}
                        onSubmit={val => this.handleEditLayer(val)}
                        onCancel={() => this.setState({ mEdit: false })}
                    />
                </Modal>
            )
        );
    }

    rendeEnableDisable() {
        const { mEnableDisable, layerSelected } = this.state;
        let isLayerActive = layerSelected ? layerSelected.base.status.active : false;

        return (
            layerSelected && (
                <Modal
                    centered
                    okText="Si"
                    cancelText="No"
                    onCancel={() => this.setState({ mEnableDisable: false })}
                    onOk={() => this.handleDisableEnable()}
                    title={(isLayerActive ? "Disattiva " : "Attiva") + " ufficio " + layerSelected.base.hierarchyId}
                    visible={mEnableDisable}
                >
                    {"Sei sicuro di voler " + (isLayerActive ? "disattivare " : "attivare ") + "l'ufficio?"}
                </Modal>
            )
        );
    }

    renderAction(layerValue) {
        const { company } = this.props;
        return (
            <Menu>
                <Menu.Item
                    key="0"
                    disabled={company.info.base.uuid + "-000" === layerValue.base.hierarchyId}
                    onClick={() =>
                        this.setState({
                            mEnableDisable: true,
                            layerSelected: layerValue
                        })
                    }
                >
                    <i className={layerValue.base.status.active ? "far fa-times-circle" : "fas fa-check-circle"} />
                    {layerValue.base.status.active ? " Disattiva" : " Attiva"}
                </Menu.Item>
                <Menu.Item
                    key="1"
                    disabled={company.info.base.uuid + "-000" === layerValue.base.hierarchyId}
                    onClick={() =>
                        this.setState({
                            mDelete: true,
                            layerSelected: layerValue
                        })
                    }
                >
                    <i className="fas fa-trash" /> Cancella
                </Menu.Item>
            </Menu>
        );
    }

    render() {
        const {
            company: { info },
            admin
        } = this.props;
        const { mAdd } = this.state;
        const columns = [
            {
                title: "Stato",
                width: "10%",
                dataIndex: "base.status.active",
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? "Attivo" : "Disattivo"}
                    </span>
                )
            },
            {
                title: "Identificativo",
                width: "21%",
                dataIndex: "base.hierarchyId"
            },
            {
                title: "Tipo",
                width: "10%",
                dataIndex: "base.details.classifier",
                render: text => translateClassifier(text)
            },
            {
                title: "Descrizione",
                width: "30%",
                dataIndex: "base.details.description"
            },
            {
                title: "Cod. Uff. Fatt. Elettronica",
                width: "18%",
                dataIndex: "base.identifier.govCode"
            },
            {
                title: "Azioni",
                width: "10%",
                dataIndex: "actions",
                render: (text, record) => (
                    <div style={{ textAlign: "right" }}>
                        <Dropdown.Button
                            overlay={this.renderAction(record)}
                            trigger={["click"]}
                            placement="bottomRight"
                            onClick={() =>
                                this.setState({
                                    mEdit: true,
                                    layerSelected: record
                                })
                            }
                        >
                            Modifica
                        </Dropdown.Button>
                    </div>
                )
            }
        ];
        let layers = [];
        if (info.layers) {
            info.layers.forEach(val => {
                if (!val.base.status.deleted) {
                    layers.push(val);
                }
            });
        }

        return (
            <div className="c-company-layers">
                <Table
                    title={() =>
                        userIsAuthorized("c-company-layer-add", admin.adminInfo.permits) ? (
                            <div className="add-button">
                                <Button onClick={() => this.setState({ mAdd: true })}>Aggiungi</Button>
                            </div>
                        ) : (
                            ""
                        )
                    }
                    rowKey={record => record.base.id}
                    columns={columns}
                    dataSource={layers}
                    locale={locale}
                    size="small"
                />
                <Modal
                    footer={null}
                    onCancel={() => this.setState({ mAdd: false })}
                    width={700}
                    title={"Crea Ufficio"}
                    visible={mAdd}
                    centered
                >
                    <FormLayer
                        action="create"
                        onCancel={() => this.setState({ mAdd: false })}
                        onSubmit={val => this.handleCreate(val)}
                    />
                </Modal>
                {this.rendeEnableDisable()}
                {this.renderEdit()}
                {this.renderDelete()}
            </div>
        );
    }
}

export default CompanyLayers;
