import sha256 from "sha256";
import { AUTH_REST_URL, API_URL, API_CAPTCHA_SIGNUP_URL } from "../config";

import { getRestCall, postRestCall, deleteRestCall } from "../utils/rest-api-call";
import { displayErrorToast, displaySuccessToast } from "./toast";

function trimAndLower(id) {
    return id != null ? id.trim().toLowerCase() : id;
}

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";

export function login(appName, userId, password) {
    return async dispatch => {
        dispatch({
            type: LOGIN_START
        });
        let id = trimAndLower(userId);

        try {
            const nonceResponse = await postRestCall(`${AUTH_REST_URL}/nonces`, null, null, { id });
            const digest = sha256(sha256(userId + password) + nonceResponse.nonce);
            const tokenResponse = await postRestCall(`${AUTH_REST_URL}/tokens`, null, null, { id: userId, digest });
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    securityToken: tokenResponse.accessToken,
                    id,
                    appName,
                    refreshToken: tokenResponse.refreshToken
                }
            });
        } catch (e) {
            let message = e.message.includes("Controllare id e password") ? "Email o password non valide" : e.message;
            dispatch({
                type: LOGIN_ERROR,
                error: { code: e.code, message }
            });
        }
    };
}

export const LOGOUT = "LOGOUT";

export function logout(auth) {
    return async dispatch => {
        sessionStorage.clear();
        window._elev.logoutUser();
        dispatch({
            type: LOGOUT
        });
        try {
            await deleteRestCall(`${AUTH_REST_URL}/tokens`, null, null, { accessToken: auth.securityToken }, dispatch);
        } catch (e) {
            console.error("Error while invalidating token", e);
        }
    };
}

export function resetLogin() {
    return {
        type: LOGIN_RESET
    };
}

export const FORGOT_PWD_START = "FORGOT_PWD_START";
export const FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS";
export const FORGOT_PWD_ERROR = "FORGOT_PWD_ERROR";
export const FORGOT_PWD_RESET = "FORGOT_PWD_RESET";

export function forgotPassword(email, captchaCode) {
    return async dispatch => {
        dispatch({
            type: FORGOT_PWD_START
        });
        email = trimAndLower(email);
        try {
            const response = await postRestCall(`${API_CAPTCHA_SIGNUP_URL}/forgot-password`, null, null, {
                captchaCode,
                userId: email
            });
            dispatch({
                type: FORGOT_PWD_SUCCESS,
                payload: response
            });
            displaySuccessToast("Email di reset password inviata correttamente", true, { userId: email })(dispatch);
        } catch (e) {
            dispatch({
                type: FORGOT_PWD_ERROR,
                error: e
            });
            displayErrorToast("Errore nell'invio della mail di reset password", true, { userId: email })(dispatch);
        }
    };
}

export const GET_AUTH_INFO_START = "GET_AUTH_INFO_START";
export const GET_AUTH_INFO_SUCCESS = "GET_AUTH_INFO_SUCCESS";
export const GET_AUTH_INFO_ERROR = "GET_AUTH_INFO_ERROR";

export function getAuthInfo(auth, userId) {
    return async dispatch => {
        dispatch({
            type: GET_AUTH_INFO_START
        });
        const urlParams = { userId: userId };

        try {
            const result = await getRestCall(`${API_URL}/auth`, auth, urlParams, dispatch, auth.refreshToken);
            if (!result.profile) {
                dispatch({
                    type: GET_AUTH_INFO_ERROR,
                    error: result
                });
            } else {
                dispatch({
                    type: GET_AUTH_INFO_SUCCESS,
                    payload: result
                });
            }
            return result;
        } catch (e) {
            dispatch({
                type: GET_AUTH_INFO_ERROR,
                error: e
            });
        }
    };
}

export const GET_AUTH_INFO_RESET = "GET_AUTH_INFO_RESET";
export function resetGetAuthInfo() {
    return dispatch => {
        dispatch({
            type: GET_AUTH_INFO_RESET
        });
    };
}

export const sharedLogin = (userId, token, refreshToken, idToken) => dispatch =>
    dispatch({
        type: LOGIN_SUCCESS,
        payload: {
            securityToken: token,
            refreshToken,
            id: userId,
            appName: "BACKOFFICE",
            idToken: idToken
        }
    });
