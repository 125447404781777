import { API_URL } from "../../../config";
import { getRestCall, deleteRestCall, postRestCall } from "../../../utils/rest-api-call";

const toast = {
    onOk: false,
    onOkText: "Operazione effettuata correttamente",
    onError: false,
    infoReq: null
};

export const GET_SENTINEL_MAILINGLIST_START = "GET_SENTINEL_MAILINGLIST_START";
export const GET_SENTINEL_MAILINGLIST_SUCCESS = "GET_SENTINEL_MAILINGLIST_SUCCESS";
export const GET_SENTINEL_MAILINGLIST_ERROR = "GET_SENTINEL_MAILINGLIST_ERROR";

export const getMailingList = (auth, groupNcsid) => async dispatch => {
    dispatch({
        type: GET_SENTINEL_MAILINGLIST_START,
        request: null
    });

    try {
        const result = await getRestCall(
            `${API_URL}/sentinel/groups/${groupNcsid}`,
            auth,
            null,
            dispatch,
            auth.refreshToken,
            toast
        );

        dispatch({
            type: GET_SENTINEL_MAILINGLIST_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_SENTINEL_MAILINGLIST_ERROR,
            error: e
        });
    }
};

export const DELETE_FROM_SENTINEL_MAILINGLIST_START = "DELETE_FROM_SENTINEL_MAILINGLIST_START";
export const DELETE_FROM_SENTINEL_MAILINGLIST_SUCCESS = "DELETE_FROM_SENTINEL_MAILINGLIST_SUCCESS";
export const DELETE_FROM_SENTINEL_MAILINGLIST_ERROR = "DELETE_FROM_SENTINEL_MAILINGLIST_ERROR";

export const deleteFromMailingList = (auth, selectedGroupNcsid, ncsId) => async dispatch => {
    dispatch({
        type: DELETE_FROM_SENTINEL_MAILINGLIST_START,
        request: ncsId
    });

    const toast = {
        onOk: true,
        onOkText: "Utente rimosso con successo",
        onError: true,
        infoReq: { authId: auth.id }
    };

    try {
        const result = await deleteRestCall(
            `${API_URL}/sentinel/groups/${selectedGroupNcsid}/list/${ncsId}`,
            auth,
            null,
            null,
            dispatch,
            auth.refreshToken,
            toast
        );

        dispatch({
            type: DELETE_FROM_SENTINEL_MAILINGLIST_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: DELETE_FROM_SENTINEL_MAILINGLIST_ERROR,
            error: e
        });
    }
};

export const ADD_TO_SENTINEL_MAILINGLIST_START = "ADD_TO_SENTINEL_MAILINGLIST_START";
export const ADD_TO_SENTINEL_MAILINGLIST_SUCCESS = "ADD_TO_SENTINEL_MAILINGLIST_SUCCESS";
export const ADD_TO_SENTINEL_MAILINGLIST_ERROR = "ADD_TO_SENTINEL_MAILINGLIST_ERROR";

export const addToMailingList = (auth, selectedGroupNcsid, email) => async dispatch => {
    dispatch({
        type: ADD_TO_SENTINEL_MAILINGLIST_START,
        request: email
    });

    let body = {};
    if (email && email.indexOf("," !== -1)) {
        body = { emailList: email.split(",") };
    } else {
        body = {
            email
        };
    }

    const toast = {
        onOk: true,
        onOkText: "Utente aggiunto con successo",
        onError: true,
        infoReq: { authId: auth.id }
    };

    try {
        let result = null;
        if (body.emailList) {
            result = await postRestCall(
                `${API_URL}/sentinel/groups/${selectedGroupNcsid}/list/massive`,
                auth,
                null,
                body,
                dispatch,
                auth.refreshToken,
                toast
            );
        } else {
            result = await postRestCall(
                `${API_URL}/sentinel/groups/${selectedGroupNcsid}/list`,
                auth,
                null,
                body,
                dispatch,
                auth.refreshToken,
                toast
            );
        }

        dispatch({
            type: ADD_TO_SENTINEL_MAILINGLIST_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: ADD_TO_SENTINEL_MAILINGLIST_ERROR,
            error: e
        });
    }
};

export const NOTIFY_TO_SENTINEL_START = "NOTIFY_TO_SENTINEL_START";
export const NOTIFY_TO_SENTINEL_SUCCESS = "NOTIFY_TO_SENTINEL_SUCCESS";
export const NOTIFY_TO_SENTINEL_ERROR = "NOTIFY_TO_SENTINEL_ERROR";

export const notifyToSentinel = (auth, selectedGroupNcsid, params) => async dispatch => {
    dispatch({
        type: NOTIFY_TO_SENTINEL_START,
        request: params
    });

    let body = {
        params
    };

    const toast = {
        onOk: true,
        onOkText: "Notifica inviata con successo",
        onError: true,
        infoReq: { authId: auth.id }
    };

    try {
        const result = await postRestCall(
            `${API_URL}/sentinel/${selectedGroupNcsid}`,
            auth,
            null,
            body,
            dispatch,
            auth.refreshToken,
            toast
        );

        dispatch({
            type: NOTIFY_TO_SENTINEL_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: NOTIFY_TO_SENTINEL_ERROR,
            error: e
        });
    }
};

export const SINGLE_NOTIFY_TO_SENTINEL_START = "SINGLE_NOTIFY_TO_SENTINEL_START";
export const SINGLE_NOTIFY_TO_SENTINEL_SUCCESS = "SINGLE_NOTIFY_TO_SENTINEL_SUCCESS";
export const SINGLE_NOTIFY_TO_SENTINEL_ERROR = "SINGLE_NOTIFY_TO_SENTINEL_ERROR";

export const singleNotifyToSentinel = (auth, email, params) => async dispatch => {
    dispatch({
        type: SINGLE_NOTIFY_TO_SENTINEL_START,
        request: params
    });

    let body = {
        params
    };

    const toast = {
        onOk: true,
        onOkText: "Notifica inviata con successo",
        onError: true,
        infoReq: { authId: auth.id }
    };

    try {
        const result = await postRestCall(
            `${API_URL}/sentinel/direct/${email}`,
            auth,
            null,
            body,
            dispatch,
            auth.refreshToken,
            toast
        );

        dispatch({
            type: SINGLE_NOTIFY_TO_SENTINEL_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: SINGLE_NOTIFY_TO_SENTINEL_ERROR,
            error: e
        });
    }
};

export const GET_SENTINEL_GROUPS_START = "GET_SENTINEL_GROUPS_START";
export const GET_SENTINEL_GROUPS_SUCCESS = "GET_SENTINEL_GROUPS_SUCCESS";
export const GET_SENTINEL_GROUPS_ERROR = "GET_SENTINEL_GROUPS_ERROR";

export const getSentinelGroups = auth => async dispatch => {
    dispatch({
        type: GET_SENTINEL_GROUPS_START,
        request: null
    });

    try {
        const result = await getRestCall(`${API_URL}/sentinel/groups`, auth, null, dispatch, auth.refreshToken, toast);

        dispatch({
            type: GET_SENTINEL_GROUPS_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_SENTINEL_GROUPS_ERROR,
            error: e
        });
    }
};
