import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmail } from "validator";
import { CSVLink } from "react-csv";
import { FormattedMessage } from "react-intl";
import { isNil } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

import "./style.css";

import { Avatar, Card, Input, Table, Tag, Icon } from "antd";
import { restrictToRoleForComponent } from "../general/hoc";

import { getProfileInfo, profileFilter } from "../../utils/get-roles";

const Search = Input.Search;

class ControlPannelUsersBackoffice extends Component {
    constructor() {
        super();
        this.state = {
            csvData: [],
            filter: null
        };
    }

    static propTypes = {
        admUsers: PropTypes.object.isRequired,
        displayErrorToast: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        updateAdminUserList: PropTypes.func.isRequired
    };

    getCsvData() {
        const { admUsers } = this.props;
        let data = [["Id", "Nome", "Cognome", "Descrizione", "Profilo"]];
        if (admUsers.users) {
            admUsers.users.forEach(user => {
                data.push([
                    user.profile.id,
                    user.profile.firstName,
                    user.profile.lastName,
                    user.profile.description,
                    getProfileInfo(user.permits).text
                ]);
            });
        }
        this.setState({ csvData: data });
    }

    handleFilterChange(filters) {
        const { updateAdminUserList } = this.props;
        const { filter } = this.state;
        let newFilter = {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "permits") {
                        newFilter.permits = filters[key][0];
                    }
                }
            }
        }
        if (
            (!filter && newFilter && newFilter.permits) ||
            (filter && newFilter && filter.permits !== newFilter.permits)
        ) {
            this.setState({ filter: newFilter });
            updateAdminUserList(newFilter);
        }
    }

    async loadUser(userId) {
        const { displayErrorToast, history } = this.props;
        if (isEmail(userId) || userId.length >= 36) {
            if (userId) {
                history.push(`/control-pannel-users-backoffice/${userId}`);
            } else {
                displayErrorToast("Utente non trovato", true);
            }
        } else {
            displayErrorToast("Identificativo utente non valido, inserire una mail o una chiave tecnica", true);
        }
    }

    render() {
        const { admUsers } = this.props;
        const { csvData, filter } = this.state;

        const columns = [
            {
                title: "",
                dataIndex: "profile.type",
                key: "profile.type",
                width: "5%",
                render: (text, record) => (
                    <Avatar
                        className={record.roles.map(role => (role.actionKey === "OWNER" ? "admin-icon" : "user-icon"))}
                    >
                        {text === "PERSONALE" ? <i className="fas fa-user" /> : <i className="fas fa-key" />}
                    </Avatar>
                )
            },
            {
                title: "Id",
                dataIndex: "profile.id",
                key: "profile.id"
            },
            {
                title: "Nome",
                dataIndex: "profile.firstName",
                key: "profile.firstName"
            },
            {
                title: "Cognome",
                dataIndex: "profile.lastName",
                key: "profile.lastName"
            },
            {
                title: <FormattedMessage id="description" />,
                dataIndex: "profile.description",
                key: "profile.description"
            },
            {
                title: <FormattedMessage id="profile" />,
                dataIndex: "permits",
                key: "permits",
                filters: profileFilter,
                filteredValue: filter && filter.permits ? [filter.permits] : null,
                filterMultiple: false,
                render: permits => <Tag color={getProfileInfo(permits).color}>{getProfileInfo(permits).text}</Tag>
            },
            {
                title: "",
                render: value => (
                    <FontAwesomeIcon
                        className="user-list-action"
                        icon={faInfoCircle}
                        onClick={() => this.loadUser(value.profile.id.trim())}
                    />
                )
            }
        ];

        return (
            <div className="c-control-pannel-users-backoffice">
                <Card>
                    <Search
                        placeholder="Inserisci l'indentificativo dell'utenza di BackOffice che stai cercando..."
                        onSearch={val => this.loadUser(val.trim())}
                        enterButton
                    />
                    <br />
                    <br />
                    <Table
                        title={() => (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="table-title">
                                    {"Totale utenze di Backoffice: " + admUsers.totalItems}
                                </div>
                                <CSVLink
                                    data={csvData}
                                    target="_blank"
                                    filename="BackOffice_users.csv"
                                    asyncOnClick={true}
                                    onClick={(event, done) => {
                                        this.getCsvData();
                                        done();
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon type="download" style={{ marginRight: 8 }} />
                                        <FormattedMessage id="download" />
                                    </div>
                                </CSVLink>
                            </div>
                        )}
                        rowKey={record => record.profile.id}
                        dataSource={admUsers.users}
                        columns={columns}
                        loading={admUsers.status.started}
                        size="small"
                        onChange={(page, filter, sorter) => this.handleFilterChange(filter)}
                    />
                </Card>
            </div>
        );
    }
}

export default restrictToRoleForComponent(true)(ControlPannelUsersBackoffice);
