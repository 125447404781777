import {
    GET_CCT_PROV_REQUEST_START,
    GET_CCT_PROV_REQUEST_SUCCESS,
    GET_CCT_PROV_REQUEST_ERROR,
    GET_ONE_CCT_PROV_REQUEST_START,
    GET_ONE_CCT_PROV_REQUEST_SUCCESS,
    GET_ONE_GET_CCT_PROV_REQUEST_ERROR
} from "../../../actions/cct/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function cctProvRequest(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CCT_PROV_REQUEST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CCT_PROV_REQUEST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_CCT_PROV_REQUEST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
//TODO fare dal BE
export function cctOneProvRequest(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_ONE_CCT_PROV_REQUEST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ONE_CCT_PROV_REQUEST_SUCCESS:
            const { result, idCoupon } = payload;

            const cctProvRequestList = result.provisioning.filter(item => item.id.toString() === idCoupon);

            if (cctProvRequestList.length <= 0) {
                return {
                    status: {
                        started: false,
                        error: true,
                        ended: false,
                        errorInfo: { code: 404, message: "provisiong request not found" }
                    }
                };
            } else {
                return {
                    status: {
                        started: false,
                        error: false,
                        ended: true
                    },
                    cctProvisoingRequest: cctProvRequestList[0]
                };
            }

        case GET_ONE_GET_CCT_PROV_REQUEST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
