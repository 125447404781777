import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button, Input } from "antd";
import { ChipCheckbox } from "@mondora/arc/antd";

import { InvoiceFilterContent, InvoiceFilterItems, InvoiceFilterSelect } from "./styled";

export const InvoiceFilter = ({
    disabledClearFilter,
    handleClearFilter,
    companyOptions,
    disabledCompanyOptions,
    companySelected,
    handleCompanyChanged,
    sdiId,
    handleSdiIdChanging,
    handleSdiIdChanged,
    invoiceTrashed,
    handleInvoiceTrashedChanged
}) => {
    return (
        <InvoiceFilterContent>
            <span>
                {companyOptions.length > 0 ? (
                    <InvoiceFilterSelect
                        disabled={disabledCompanyOptions}
                        value={companySelected}
                        showSearch
                        optionFilterProp="children"
                        onChange={handleCompanyChanged}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {companyOptions}
                    </InvoiceFilterSelect>
                ) : null}
                <FormattedMessage id={"enter_a_correct_sdiid"} defaultMessage=" ">
                    {placeholder => (
                        <Input
                            placeholder={placeholder}
                            value={sdiId}
                            onChange={handleSdiIdChanging}
                            style={{
                                marginRight: "10px",
                                marginBottom: "5px",
                                width: "400px"
                            }}
                            onPressEnter={handleSdiIdChanged}
                        />
                    )}
                </FormattedMessage>
                <InvoiceFilterItems>
                    <ChipCheckbox small checked={invoiceTrashed} onChange={handleInvoiceTrashedChanged}>
                        <FormattedMessage id="trashed" />
                    </ChipCheckbox>
                </InvoiceFilterItems>
                <InvoiceFilterItems>
                    <Button
                        type="primary"
                        shape="round"
                        icon="filter"
                        disabled={disabledClearFilter}
                        onClick={event => {
                            handleClearFilter();
                            event.stopPropagation();
                        }}
                    >
                        <FormattedMessage id="reset_filters" />
                    </Button>
                </InvoiceFilterItems>
            </span>
        </InvoiceFilterContent>
    );
};

InvoiceFilter.propTypes = {
    disabledClearFilter: PropTypes.bool.isRequired,
    handleClearFilter: PropTypes.func.isRequired,
    companyOptions: PropTypes.array.isRequired,
    disabledCompanyOptions: PropTypes.bool.isRequired,
    companySelected: PropTypes.any,
    handleCompanyChanged: PropTypes.func.isRequired,
    sdiId: PropTypes.any,
    handleSdiIdChanging: PropTypes.func.isRequired,
    handleSdiIdChanged: PropTypes.func.isRequired,
    invoiceTrashed: PropTypes.bool,
    handleInvoiceTrashedChanged: PropTypes.func.isRequired
};
