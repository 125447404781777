import {
    LOGIN_START,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGIN_RESET,
    LOGOUT,
    GET_AUTH_INFO_START,
    GET_AUTH_INFO_SUCCESS,
    GET_AUTH_INFO_ERROR,
    GET_AUTH_INFO_RESET
} from "../actions/auth";

import {
    UPDATE_AUTH_PASSWORD_START,
    UPDATE_AUTH_PASSWORD_ERROR,
    UPDATE_AUTH_PASSWORD_SUCCESS,
    UPDATE_AUTH_PASSWORD_RESET,
    RESET_PWD_START,
    RESET_PWD_ERROR,
    RESET_PWD_SUCCESS,
    RESET_PWD_RESET
} from "../actions/user/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function loginAuth(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LOGIN_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case LOGIN_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case LOGIN_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };
        case LOGIN_RESET:
            return {
                ...defaultState
            };
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
}
export function adminInfo(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_AUTH_INFO_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_AUTH_INFO_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_AUTH_INFO_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_AUTH_INFO_RESET:
            return {
                ...defaultState
            };
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
}
export function updatePassword(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_AUTH_PASSWORD_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_AUTH_PASSWORD_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_AUTH_PASSWORD_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case LOGOUT:
            return defaultState;
        case UPDATE_AUTH_PASSWORD_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function resetPassword(state = defaultState, { type, payload, error }) {
    switch (type) {
        case RESET_PWD_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case RESET_PWD_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case RESET_PWD_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case LOGOUT:
            return defaultState;
        case RESET_PWD_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function sharedLogin(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LOGIN_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case LOGIN_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case LOGIN_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                }
            };
        case LOGIN_RESET:
            return {
                ...defaultState
            };
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
}
