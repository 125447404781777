import { postRestCall, putRestCall, deleteRestCall } from "../../../utils/rest-api-call";
import { LCA_CCT_PROVISIONING_API_URL } from "../../../config";
export const AUTO_UPLOAD_DOCUMENT_START = "AUTO_UPLOAD_DOCUMENT_START";
export const AUTO_UPLOAD_DOCUMENT_SUCCESS = "AUTO_UPLOAD_DOCUMENT_SUCCESS";
export const AUTO_UPLOAD_DOCUMENT_ERROR = "AUTO_UPLOAD_DOCUMENT_ERROR";

export const autoUploadDocument = (auth, manager, managed, delegatedData, file) => {
    return async dispatch => {
        dispatch({
            type: AUTO_UPLOAD_DOCUMENT_START,
            requestParams: { manager, managed }
        });
        const toast = {
            onOk: false,
            onOkText: "Operazione eseguita correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: manager, managed }
        };

        try {
            const result = await postRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/autouploadDocument`,
                auth,
                {
                    "cf-titolare": managed,
                    "cf-produttore": manager
                },
                {
                    cctDelegaRequest: delegatedData,
                    document: file
                },
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: AUTO_UPLOAD_DOCUMENT_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: AUTO_UPLOAD_DOCUMENT_ERROR,
                error: e
            });
        }
    };
};

export const PUT_CCT_PROV_REQUEST_START = "PUT_CCT_PROV_REQUEST_START";
export const PUT_CCT_PROV_REQUEST_SUCCESS = "PUT_CCT_PROV_REQUEST_SUCCESS";
export const PUT_CCT_PROV_REQUEST_ERROR = "PUT_CCT_PROV_REQUEST_ERROR";

export function putCctProvisioningRequest(auth, request, messageStr = "") {
    return async dispatch => {
        const urlParams = {
            ...request
        };
        dispatch({
            type: PUT_CCT_PROV_REQUEST_START
        });

        try {
            const result = await putRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request`,
                auth,
                urlParams,
                { message: messageStr },
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: PUT_CCT_PROV_REQUEST_SUCCESS,
                payload: result
            });
            return { code: true, message: "OK" };
        } catch (e) {
            dispatch({
                type: PUT_CCT_PROV_REQUEST_ERROR,
                error: e
            });
            return { code: false, message: e.message };
        }
    };
}

export const SET_CEDENTE_PRESTATORE_START = "SET_CEDENTE_PRESTATORE_START";
export const SET_CEDENTE_PRESTATORE_ERROR = "SET_CEDENTE_PRESTATORE_ERROR";
export const SET_CEDENTE_PRESTATORE_SUCCESS = "SET_CEDENTE_PRESTATORE_SUCCESS";

export function setCedentePrestatore(auth, cfTitolare, cedentePrestatore, cfProduttore) {
    return async dispatch => {
        dispatch({
            type: SET_CEDENTE_PRESTATORE_START
        });
        const toast = {
            onOk: true,
            onOkText: "Operazione eseguita correttamente",
            onError: true,
            infoReq: { authId: auth.id }
        };
        const urlParams = {
            "cf-titolare": cfTitolare,
            "cf-produttore": cfProduttore
        };

        try {
            const result = await putRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request/cedente-prestatore`,
                auth,
                urlParams,
                cedentePrestatore, //as body
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: SET_CEDENTE_PRESTATORE_SUCCESS,
                payload: result
            });
            return true;
        } catch (e) {
            dispatch({
                type: SET_CEDENTE_PRESTATORE_ERROR,
                error: e
            });
            return false;
        }
    };
}
export const POST_CCT_PROV_REQUEST_START = "POST_CCT_PROV_REQUEST_START";
export const POST_CCT_PROV_REQUEST_SUCCESS = "POST_CCT_PROV_REQUEST_SUCCESS";
export const POST_CCT_PROV_REQUEST_ERROR = "POST_CCT_PROV_REQUEST_ERROR";

export const postCctProvisioningRequest = (auth, request) => async dispatch => {
    const urlParams = {
        ...request
    };
    dispatch({
        type: POST_CCT_PROV_REQUEST_START
    });

    try {
        const result = await postRestCall(
            `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: POST_CCT_PROV_REQUEST_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: POST_CCT_PROV_REQUEST_ERROR,
            error: e
        });
    }
};

export const POST_SUSPEND_CCT_PROV_REQUEST_START = "POST_SUSPEND_CCT_PROV_REQUEST_START";
export const POST_SUSPEND_CCT_PROV_REQUEST_SUCCESS = "POST_SUSPEND_CCT_PROV_REQUEST_SUCCESS";
export const POST_SUSPEND_CCT_PROV_REQUEST_ERROR = "POST_SUSPEND_CCT_PROV_REQUEST_ERROR";

export const postSuspendCctProvisioningRequest = (auth, idCoupon) => async dispatch => {
    let body = {
        description: "Sospeso da operatore di BackOffice"
    };

    dispatch({
        type: POST_SUSPEND_CCT_PROV_REQUEST_START,
        request: body
    });

    try {
        const result = await postRestCall(
            `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request/${idCoupon}/suspend`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: POST_SUSPEND_CCT_PROV_REQUEST_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: POST_SUSPEND_CCT_PROV_REQUEST_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const POST_RESET_CCT_PASSWORD_START = "POST_RESET_CCT_PASSWORD_START";
export const POST_RESET_CCT_PASSWORD_SUCCESS = "POST_RESET_CCT_PASSWORD_SUCCESS";
export const POST_RESET_CCT_PASSWORD_ERROR = "POST_RESET_CCT_PASSWORD_ERROR";

export const postResetCctPassword = (auth, cfTitolare) => async dispatch => {
    dispatch({
        type: POST_RESET_CCT_PASSWORD_START
    });
    try {
        const result = await postRestCall(
            `${LCA_CCT_PROVISIONING_API_URL}/itemId/${cfTitolare}/resetPassword`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: POST_RESET_CCT_PASSWORD_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: POST_RESET_CCT_PASSWORD_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const DELTE_CCT_RDC_TO_BE_FIXED_START = "DELTE_CCT_RDC_TO_BE_FIXED_START";
export const DELTE_CCT_RDC_TO_BE_FIXED_SUCCESS = "DELTE_CCT_RDC_TO_BE_FIXED_SUCCESS";
export const DELTE_CCT_RDC_TO_BE_FIXED_ERROR = "DELTE_CCT_RDC_TO_BE_FIXED_ERROR";

export const deleteResetCctPassword = (auth, id_coupon) => async dispatch => {
    dispatch({
        type: DELTE_CCT_RDC_TO_BE_FIXED_START
    });
    try {
        const result = await deleteRestCall(
            `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request/${id_coupon}/rdcToBeFixed`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: DELTE_CCT_RDC_TO_BE_FIXED_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: DELTE_CCT_RDC_TO_BE_FIXED_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};
