import React from "react";
import { injectIntl } from "react-intl";
import { Table, Empty, Input } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ActionIcon, DisabledActionIcon } from "../../../styled";
import { faSpellCheck } from "@fortawesome/pro-solid-svg-icons";

const tableConfig = ({ handleFilter, intl }) => {
    let colums = [
        {
            title: intl.formatMessage({
                id: "c-new-archive.desc-produttore-column"
            }),
            dataIndex: "submitter.searchable_info.description",
            key: "submitterDescription"
        },
        {
            title: (
                <Input
                    onChange={v => {
                        if (v.target.value !== "") {
                            handleFilter(v.target.value, "submitter");
                        } else {
                            handleFilter(null, "submitter");
                        }
                    }}
                    placeholder={intl.formatMessage({
                        id: "c-new-archive.cf-produttore-column"
                    })}
                />
            ),
            dataIndex: "submitter.searchable_info.tax_id",
            key: "submitter"
        },
        {
            title: intl.formatMessage({
                id: "c-new-archive.desc-titolare-column"
            }),
            dataIndex: "holder.searchable_info.description",
            key: "holderDescription"
        },
        {
            title: (
                <Input
                    onChange={v => {
                        if (v.target.value !== "") {
                            handleFilter(v.target.value, "holder");
                        } else {
                            handleFilter(null, "holder");
                        }
                    }}
                    placeholder={intl.formatMessage({
                        id: "c-new-archive.cf-titolare-column"
                    })}
                />
            ),
            dataIndex: "holder.searchable_info.tax_id",
            key: "holder"
        },
        {
            dataIndex: "info",
            key: "info",
            render: (text, record) => (
                <span>
                    {record.status !== "INITIALIZED" ? (
                        <Link to={`/cct-new-archive/detail/${record.submitter.uuid}/${record.holder.uuid}`}>
                            <ActionIcon icon={faSpellCheck} />
                        </Link>
                    ) : (
                        <DisabledActionIcon icon={faSpellCheck} />
                    )}
                </span>
            )
        }
    ];

    return colums;
};

const NewArchiveDataList = ({ intl, handleShowSize, handlePage, handleFilter, total, list, loading, page }) => (
    <Table
        rowKey="id"
        locale={{
            emptyText: (
                <Empty
                    description={intl.formatMessage({
                        id: "c-new-archive.draft-nodata"
                    })}
                />
            )
        }}
        style={{ backgroundColor: "white" }}
        pagination={{
            current: page,
            total: total,
            onChange: p => handlePage(p),
            onShowSizeChange: (c, s) => handleShowSize(s),
            pageSizeOptions: ["3", "10", "25", "50"],
            showSizeChanger: true
        }}
        loading={loading}
        columns={tableConfig({
            handleFilter: (value, name) => handleFilter(value, name),
            intl
        })}
        dataSource={list}
    />
);

NewArchiveDataList.propTypes = {
    handleShowSize: PropTypes.func.isRequired,
    handlePage: PropTypes.func.isRequired,
    list: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    status: PropTypes.string,
    total: PropTypes.number
};

export default injectIntl(NewArchiveDataList);
