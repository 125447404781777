import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, message, Spin, Icon, Input } from "antd";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AsyncPaginate } from "react-select-async-paginate";

import { ContainerField, ContainerCopyIcon, ContainerItem } from "./styled";

export const BankLinkBankInfo = ({
    loading,
    isNewBank,
    bankInfo,
    handleUpdateBankInfo,
    handleCreateBankInfo,
    handleDeleteBankInfo,
    handleLoadAssociationLazy
}) => {
    const [bankUpdated, setBankUpdated] = useState(null);
    const [isDifference, setIsDifference] = useState(false);

    useEffect(() => {
        if (!isNewBank) {
            setBankUpdated(bankInfo);
        } else {
            setBankUpdated({});
        }
    }, [bankInfo, isNewBank]);

    useEffect(() => {
        let isDifference = false;
        if (isNewBank) {
            if (bankUpdated && bankUpdated.associationId && bankUpdated.name && bankUpdated.abi) {
                isDifference = true;
            }
        } else {
            if (bankInfo && bankUpdated) {
                if (
                    bankInfo.associationId !== bankUpdated.associationId ||
                    (bankInfo.name !== bankUpdated.name && bankUpdated.name) ||
                    (bankInfo.abi !== bankUpdated.abi && bankUpdated.abi)
                ) {
                    isDifference = true;
                }
            } else {
                if (bankUpdated && bankUpdated.associationId && bankUpdated.name && bankUpdated.abi) {
                    isDifference = true;
                }
            }
        }

        setIsDifference(isDifference);
    }, [bankInfo, bankUpdated, isNewBank]);

    const displaySuccessToast = msg => {
        message.success(msg);
    };

    if (loading) {
        return (
            <div style={{ position: "absolute", top: "50%", right: "50%" }}>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </div>
        );
    }

    return (
        <div>
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    icon="delete"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={true}
                    onClick={() => handleDeleteBankInfo(bankUpdated)}
                >
                    <FormattedMessage id={"delete"} />
                </Button>
                <Button
                    type="primary"
                    icon="save"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={!isDifference}
                    onClick={() => (isNewBank ? handleCreateBankInfo(bankUpdated) : handleUpdateBankInfo(bankUpdated))}
                >
                    <FormattedMessage id={"save"} />
                </Button>
            </div>
            <div>
                {bankUpdated && bankUpdated.id && (
                    <ContainerItem>
                        <ContainerField>{<FormattedMessage id={"hubid"} />}: </ContainerField>
                        {bankUpdated.id}
                        <ContainerCopyIcon>
                            <CopyToClipboard
                                text={bankUpdated.id}
                                onCopy={() => displaySuccessToast("HubId copiato correttamente", true)}
                            >
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </ContainerCopyIcon>
                    </ContainerItem>
                )}
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"name"} />}:</ContainerField>
                    <Input
                        placeholder="Inserisci nome"
                        value={bankUpdated && bankUpdated.name ? bankUpdated.name : ""}
                        onChange={e =>
                            setBankUpdated({
                                ...bankUpdated,
                                name: e.target.value
                            })
                        }
                    />
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"abi"} />}:</ContainerField>
                    <Input
                        placeholder="Inserisci ABI"
                        value={bankUpdated && bankUpdated.abi ? bankUpdated.abi : ""}
                        onChange={e =>
                            setBankUpdated({
                                ...bankUpdated,
                                abi: e.target.value
                            })
                        }
                    />
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"consortium"} />}:</ContainerField>
                    <AsyncPaginate
                        value={
                            bankUpdated && bankUpdated.associationId
                                ? {
                                      value: bankUpdated.associationId,
                                      label: `${bankUpdated.associationName} (${bankUpdated.associationId})`
                                  }
                                : null
                        }
                        loadOptions={handleLoadAssociationLazy}
                        onChange={item =>
                            setBankUpdated({
                                ...bankUpdated,
                                associationId: item.value,
                                associationName: item.label.split(" (")[0]
                            })
                        }
                        isSearchable={true}
                        placeholder={
                            bankUpdated && bankUpdated.associationId
                                ? {
                                      value: bankUpdated.associationId,
                                      label: `${bankUpdated.associationName} (${bankUpdated.associationId})`
                                  }
                                : null
                        }
                        loadingMessage={() => <FormattedMessage id={"loading"} />}
                        noOptionsMessage={() => <FormattedMessage id="not_available" />}
                        isClearable={false}
                        additional={{
                            page: 1
                        }}
                    />
                </ContainerItem>
            </div>
        </div>
    );
};

BankLinkBankInfo.propTypes = {
    loading: PropTypes.bool.isRequired,
    isNewBank: PropTypes.bool.isRequired,
    bankInfo: PropTypes.object,
    handleUpdateBankInfo: PropTypes.func.isRequired,
    handleDeleteBankInfo: PropTypes.func.isRequired,
    handleCreateBankInfo: PropTypes.func.isRequired,
    handleLoadAssociationLazy: PropTypes.func.isRequired
};
