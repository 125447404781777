import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import { SectionHeaderContainer, SpidSectionTitle } from "./styled";

import { FormattedMessage } from "react-intl";
import { errorBoundary } from "../../../components/general/error-boundary";
import { getSingleSpid } from "../../../actions/spid/spidId";
import SpidListComponent from "../../../components/spid/list";

const SpidIdView = ({
    admin,
    auth,
    getSingleSpid,
    history,
    spid,
    match: {
        params: { spidId }
    }
}) => {
    const [oneSpid, setOneSpid] = useState([]);

    useEffect(() => {
        const getOneSpid = async () => {
            let singleSpidRow = [];
            let resultSpid = await getSingleSpid(auth.loginAuth, spidId);
            if (resultSpid) {
                singleSpidRow.push(resultSpid);
                setOneSpid(singleSpidRow);
            }
        };

        getOneSpid();
    }, [auth, getSingleSpid, spidId]);
    return (
        <div>
            <SectionHeaderContainer>
                <SpidSectionTitle>
                    <FormattedMessage id="c-spid.spid-single-spid.view-title" />
                </SpidSectionTitle>
            </SectionHeaderContainer>
            <SpidListComponent
                admin={admin}
                history={history}
                spidList={oneSpid}
                filterDisabled={true}
                pagination={{
                    currentPage: 0,
                    totalElements: 1
                }}
                loading={spid.singleSpid.status.started}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    spid: state.spid
});

const actions = { getSingleSpid };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(SpidIdView);
