import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { ActionIcon } from "./styled.js";

import { faSearch, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Table, Button, Drawer, Modal, Empty, Select, Tag, Timeline } from "antd";
import { mockHistory } from "../mock/history.js";
import TsModal from "../../general/ts-modal";
import { SpidDetailInfo } from "./detail";
const { Option } = Select;

const SpidTable = ({ filterDisabled, loading, spidList, handleFilter, pagination }) => {
    const list = spidList;
    const [userModalVisibile, setUserModalVisible] = useState(false);
    const [userDetailedInfo, setUserDetailedInfo] = useState({});
    const [waitingModal, setWaitingModal] = useState(false);

    const [historySpidModalVisibile, setHistorySpidModalVisible] = useState(false);
    const [historyDetailedInfo, setHistoryDetailedInfo] = useState([]);
    const [detailSpid, setDetailSpid] = useState(false);
    const [detailInfoSpid, setDetailInfoSpid] = useState({});
    let historyWaiting = true;
    const tableColumns = [
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.spid-id" />,
            dataIndex: "id",
            key: "id"
        },
        /*{
            title: "itemId",
            dataIndex: "itemId",
            key: "itemId"
        },*/
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.item-cf" />,
            dataIndex: "itemCf",
            key: "itemCf"
        },
        {
            title: (
                <Select
                    placeholder={<FormattedMessage id="c-spid.spid-list.table-headers.identity-type" />}
                    style={{ width: "200px" }}
                    onChange={v => handleFilter(v, "identity_type")}
                    disabled={filterDisabled ? true : false}
                >
                    <Option value={null}>--</Option>
                    <Option value="INDIVIDUAL">INDIVIDUAL</Option>
                    <Option value="LEGAL_ENTITY">LEGAL_ENTITY</Option>
                    <Option value="PROFESSIONAL_INDIVIDUAL">PROFESSIONAL_INDIVIDUAL</Option>
                    <Option value="PROFESSIONAL_LEGAL_ENTITY">PROFESSIONAL_LEGAL_ENTITY</Option>
                </Select>
            ),
            dataIndex: "type",
            key: "type"
        },
        {
            title: (
                <Select
                    placeholder={<FormattedMessage id="c-spid.spid-list.table-headers.status" />}
                    style={{ width: "150px" }}
                    onChange={v => handleFilter(v, "status")}
                    disabled={filterDisabled ? true : false}
                >
                    <Option value={null}>--</Option>
                    <Option value="CREATED">CREATED</Option>
                    <Option value="APPROVED">APPROVED</Option>
                    <Option value="ISSUING">ISSUING</Option>
                    <Option value="ISSUED">ISSUED</Option>
                    <Option value="HOLD">HOLD</Option>
                    <Option value="EXPIRED">EXPIRED</Option>
                    <Option value="REVOKED">REVOKED</Option>
                    <Option value="INITIALIZED">INITIALIZED</Option>
                    <Option value="DELETED">DELETED</Option>
                    <Option value="SESSION_EXPIRED">SESSION_EXPIRED</Option>
                </Select>
            ),
            dataIndex: "status",
            key: "status"
        },
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.requested-date" />,
            dataIndex: "requestedAt",
            key: "requestedAt",
            render: text => <span>{text !== null ? new Date(text).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.update-date" />,
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: text => <span>{text !== null ? new Date(text).toLocaleString() : ""}</span>
        },
        /*{
            title: "slotId",
            dataIndex: "slotId",
            key: "slotId"
        },
        {
            title: "videoSlotId",
            dataIndex: "videoSlotId",
            key: "videoSlotId"
        },
        {
            title: "sessionId",
            dataIndex: "sessionId",
            key: "sessionId"
        },
        {
            title: "sessionLink",
            dataIndex: "sessionLink",
            key: "sessionLink"
        },*/
        {
            title: (
                <Select
                    placeholder={<FormattedMessage id="c-spid.spid-list.table-headers.recognition" />}
                    onChange={v => handleFilter(v, "recognition_type")}
                    style={{ width: "120px" }}
                    disabled={filterDisabled ? true : false}
                >
                    <Option value={null}>--</Option>
                    <Option value="CIE">CIE</Option>
                    <Option value="CNS">CNS</Option>
                    <Option value="FEQ">FEQ</Option>
                    <Option value="VIDEO">VIDEO</Option>
                    <Option value="RAO">RAO</Option>
                </Select>
            ),
            dataIndex: "rec_type",
            key: "rec_type",
            render: (text, record) => (
                <span>
                    {record.cie ? <Tag>CIE</Tag> : ""}
                    {record.cns ? <Tag>CNS</Tag> : ""}
                    {record.feq ? <Tag>FEQ</Tag> : ""}
                    {record.video ? <Tag>VIDEO</Tag> : ""}
                    {record.rao ? <Tag>RAO</Tag> : ""}
                </span>
            )
        },
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.expiration-date" />,
            dataIndex: "sessionExpirationDate",
            key: "sessionExpirationDate",
            render: text => <span>{text !== null ? new Date(text).toLocaleString() : ""}</span>
        },
        /*{
            title: "raoId",
            dataIndex: "raoId",
            key: "raoId"
        },*/
        /*{
            title: "level",
            dataIndex: "level",
            key: "level"
        },*/
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.user" />,
            dataIndex: "user",
            key: "user",
            render: (text, record) => (
                <span style={{ display: "flex" }}>
                    <ActionIcon
                        icon={faInfoCircle}
                        onClick={() => {
                            setUserModalVisible(true);
                            setUserDetailedInfo(record.user);
                        }}
                    />
                    {record.user.email}
                </span>
            )
        },
        {
            title: <FormattedMessage id="c-spid.spid-list.table-headers.history" />,
            dataIndex: "sessionHistory",
            key: "sessionHistory",
            width: "6%",
            render: (text, record) => (
                <span>
                    {historyWaiting ? (
                        <ActionIcon
                            icon={faInfoCircle}
                            onClick={() => {
                                setWaitingModal(true);
                            }}
                            disabled={true}
                        />
                    ) : (
                        <ActionIcon
                            icon={faSearch}
                            onClick={() => {
                                setHistorySpidModalVisible(true);
                                setHistoryDetailedInfo(mockHistory);
                            }}
                            disabled={true}
                        />
                    )}
                </span>
            )
        },

        {
            title: "",
            align: "center",
            render: (text, record) => (
                <div style={{ textAlign: "right" }}>
                    <Button
                        trigger={["click"]}
                        placement="bottomRight"
                        onClick={() => {
                            setDetailSpid(true);
                            setDetailInfoSpid(record);
                        }}
                    >
                        <FormattedMessage id="c-spid.spid-list.history-popup.subtitle" />
                    </Button>
                </div>
            )
        }
    ];

    const handleUserModalOk = () => {
        setUserModalVisible(false);
    };
    const handleWaitingModal = () => {
        setWaitingModal(false);
    };

    const handleHistoryModalOk = () => {
        setHistorySpidModalVisible(false);
    };

    return (
        <div>
            <Table
                rowKey="id"
                locale={{
                    emptyText: <Empty description="Nessun dato da mostrare" />
                }}
                style={{ backgroundColor: "white", overflowX: "scroll" }}
                pagination={{
                    current: pagination.currentPage,
                    total: pagination.totalElements,
                    onChange: p => handleFilter(p, "page"),
                    onShowSizeChange: (c, s) => handleFilter(s, "pageSize"),
                    pageSizeOptions: ["10", "25", "50"],
                    showSizeChanger: true
                }}
                loading={loading}
                columns={tableColumns}
                dataSource={list}
            />
            <Modal
                title={<FormattedMessage id="c-spid.spid-list.user-popup.title" />}
                visible={userModalVisibile}
                onOk={handleUserModalOk}
                onCancel={handleUserModalOk}
            >
                <p>
                    <b>
                        <FormattedMessage id="c-spid.spid-list.user-popup.user-id" />
                    </b>
                    {userDetailedInfo.id}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-spid.spid-list.user-popup.name" />
                    </b>
                    {userDetailedInfo.name}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-spid.spid-list.user-popup.surname" />
                    </b>
                    {userDetailedInfo.surname}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-spid.spid-list.user-popup.email" />
                    </b>
                    {userDetailedInfo.email}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-spid.spid-list.user-popup.taxId" />
                    </b>
                    {userDetailedInfo.taxId}
                </p>
                <p>
                    <b>
                        <FormattedMessage id="c-spid.spid-list.user-popup.ncsId" />
                    </b>
                    {userDetailedInfo.ncsId}
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage id="c-spid.spid-list.history-popup.title" />}
                visible={historySpidModalVisibile}
                onOk={handleHistoryModalOk}
                onCancel={handleHistoryModalOk}
            >
                <p>
                    <b>{<FormattedMessage id="c-spid.spid-list.history-popup.subtitle" />}</b>
                </p>
                <Timeline>
                    {historyDetailedInfo.map(val => (
                        <Timeline.Item>
                            <p> {val.status}</p>
                            <p>{new Date(val.requestedAt).toLocaleString()}</p>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </Modal>
            <Drawer
                width={800}
                placement="right"
                closable={true}
                onClose={() => setDetailSpid(false)}
                visible={detailSpid}
            >
                <div style={{ margin: "20px" }}>
                    <SpidDetailInfo spidInfo={detailInfoSpid} />
                </div>
            </Drawer>
            <TsModal
                title={<FormattedMessage id="c-spid.spid-list.history-popup.title" />}
                visible={waitingModal}
                onCancel={handleWaitingModal}
                cancelTextId="general.close"
                width="40%"
            >
                <span>
                    <b>
                        <FormattedMessage id="general-function-available" />
                    </b>
                </span>
            </TsModal>
        </div>
    );
};

SpidTable.propTypes = {
    spidList: PropTypes.array,
    spidHistory: PropTypes.array,
    filterDisabled: PropTypes.bool
};
export default SpidTable;
