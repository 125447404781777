import {
    GET_APP_NAMES_START,
    GET_APP_NAMES_SUCCESS,
    GET_APP_NAMES_ERROR,
    DELETE_APP_NAME_START,
    DELETE_APP_NAME_SUCCESS,
    DELETE_APP_NAME_ERROR,
    ADD_APP_NAME_START,
    ADD_APP_NAME_SUCCESS,
    ADD_APP_NAME_ERROR,
    EDIT_APP_NAME_START,
    EDIT_APP_NAME_SUCCESS,
    EDIT_APP_NAME_ERROR,
    GET_APPNAME_DESCRIPTION_ERROR,
    GET_APPNAME_DESCRIPTION_START,
    GET_APPNAME_DESCRIPTION_SUCCESS,
    GET_APPNAME_EXPORT_START,
    GET_APPNAME_EXPORT_SUCCESS,
    GET_APPNAME_EXPORT_ERROR
} from "../actions/app-name";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function getAppNames(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_APP_NAMES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_APP_NAMES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                items: payload.rows,
                total: payload.total
            };
        case GET_APP_NAMES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function deleteAppNames(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DELETE_APP_NAME_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DELETE_APP_NAME_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case DELETE_APP_NAME_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function addAppNames(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ADD_APP_NAME_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ADD_APP_NAME_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case ADD_APP_NAME_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function editAppNames(state = defaultState, { type, payload, error }) {
    switch (type) {
        case EDIT_APP_NAME_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case EDIT_APP_NAME_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case EDIT_APP_NAME_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function getAppNameDescription(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_APPNAME_DESCRIPTION_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_APPNAME_DESCRIPTION_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                description: payload
            };
        case GET_APPNAME_DESCRIPTION_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function getAppNameExport(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_APPNAME_EXPORT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_APPNAME_EXPORT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                description: payload
            };
        case GET_APPNAME_EXPORT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
