import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Badge } from "antd";
import { ColumnSearchProps } from "../../../../../components/general/column-search-props";
import { ColumnCalenderProps } from "../../../../../components/general/column-date-props";
import { ordersOrdinationsFilter, ordersCurrentStatusFilter } from "../../../../../utils/constant";

import { OrderTableContent } from "./styled";

export const OrdersTable = ({ dataSource, loading, handleTableChange, expanded, filter }) => {
    let columns = [
        {
            title: <FormattedMessage id="supplier" />,
            dataIndex: "sellerSupplier.name",
            render: (text, record) => (
                <div>
                    {text + " "}
                    {record.sellerSupplier.taxId
                        ? `(${record.sellerSupplier.taxId})`
                        : record.sellerSupplier.vatNumber
                        ? `(${record.sellerSupplier.vatNumber})`
                        : ""}
                </div>
            ),
            filteredValue: filter && filter.sellerSupplier ? [filter.sellerSupplier] : null,
            ...ColumnSearchProps("fornitore")
        },
        {
            title: <FormattedMessage id="customer" />,
            dataIndex: "buyerCustomer.name",
            width: "15%",
            render: (text, record) => (
                <div>
                    {text + " "}
                    {record.buyerCustomer.taxId
                        ? `(${record.buyerCustomer.taxId})`
                        : record.buyerCustomer.vatNumber
                        ? `(${record.buyerCustomer.vatNumber})`
                        : ""}
                </div>
            ),
            filteredValue: filter && filter.buyerCustomer ? [filter.buyerCustomer] : null,
            ...ColumnSearchProps("cliente")
        },
        {
            title: <FormattedMessage id="number" />,
            width: "12%",
            dataIndex: "order.orderDetail.id",
            filteredValue: filter && filter.orderId ? [filter.orderId] : null,
            ...ColumnSearchProps("numero")
        },
        {
            title: <FormattedMessage id="date_doc" />,
            width: "15%",
            dataIndex: "order.orderDetail.issuedAt",
            filteredValue:
                filter && filter.orderDateFrom && filter.orderDateTo
                    ? [[moment(filter.orderDateFrom), moment(filter.orderDateTo)]]
                    : null,
            ...ColumnCalenderProps()
        },
        {
            title: <FormattedMessage id="ordination" />,
            width: "12%",
            dataIndex: "type",
            filters: ordersOrdinationsFilter,
            filteredValue: filter && filter.orderType ? [filter.orderType] : null,
            filterMultiple: false
        },
        {
            title: <FormattedMessage id="state" />,
            width: "12%",
            dataIndex: "currentStatus.status",
            render: text => (
                <Badge
                    status={text === "ERRORE" ? "error" : "processing"}
                    text={
                        ordersCurrentStatusFilter.find(x => x.value === text)
                            ? ordersCurrentStatusFilter.find(x => x.value === text).text
                            : text
                    }
                />
            ),
            filters: ordersCurrentStatusFilter,
            filteredValue: filter && filter.currentStatus ? [filter.currentStatus] : null,
            filterMultiple: false
        },
        {
            title: <FormattedMessage id="ord_ref" />,
            width: "12%",
            dataIndex: "order.orderDetail.refersToOrderId.originalOrderDocumentReference",
            filteredValue: filter && filter.orderDocumentReference ? [filter.orderDocumentReference] : null,
            ...ColumnSearchProps("riferimento")
        }
    ];

    return (
        <div>
            <OrderTableContent>
                <Table
                    pagination={false}
                    size="middle"
                    rowKey={(record, index) => index}
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={dataSource}
                    loading={loading}
                    onChange={handleTableChange}
                    expandedRowRender={
                        expanded
                            ? record => (
                                  <p>
                                      <b>
                                          <FormattedMessage id="hubid" />
                                      </b>
                                      {": " + record.hubId}
                                  </p>
                              )
                            : null
                    }
                    scroll={{ y: 460 }}
                />
            </OrderTableContent>
        </div>
    );
};

OrdersTable.propTypes = {
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    handleTableChange: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    filter: PropTypes.object
};
