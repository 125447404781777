import React from "react";
import ReactDOM from "react-dom";

import Routes from "./views";
//import registerServiceWorker from "./registerServiceWorker"; //ABILITANDOLO NON FUNZIONA PIU AUTORELOAD
import { FeatureContextProvider } from "./feature-context";

import "antd/dist/antd.min.css";
import "./index.css";

ReactDOM.render(
    <FeatureContextProvider>
        <Routes />
    </FeatureContextProvider>,
    document.getElementById("root")
);
