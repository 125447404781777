import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, message, Select, Spin, Icon, Input, Collapse } from "antd";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import base64 from "react-native-base64";
import { AsyncPaginate } from "react-select-async-paginate";

import { bankLinkCbiReason } from "../../../utils/constant";

import { ContainerField, ContainerCopyIcon, ContainerItem } from "./styled";

export const BankLinkRuleInfo = ({
    loading,
    isNewRoule,
    ruleInfo,
    handleUpdateRuleInfo,
    handleCreateRuleInfo,
    handleDeleteRuleInfo,
    handleLoadAssociationLazy
}) => {
    const [ruleUpdated, setRuleUpdated] = useState(null);
    const [isDifference, setIsDifference] = useState(false);
    const [isSameExpression, setIsSameExpression] = useState(false);
    const [expressionTest, setExpressionTest] = useState(null);

    let cbiOptions = bankLinkCbiReason.map(element => (
        <Select.Option key={element.value} value={element.value}>
            {`${element.text} - ${element.description}`}
        </Select.Option>
    ));

    useEffect(() => {
        if (!isNewRoule) {
            setRuleUpdated(ruleInfo);
        } else {
            let ruleUpdated = {
                type: "REGEX",
                category: "BANKLINK_CBI_REASON",
                meaning: "REASON_01"
            };
            if (ruleInfo && ruleInfo.expression) {
                ruleUpdated.expression = ruleInfo.expression;
                setExpressionTest(base64.decode(ruleInfo.expression));
            }
            if (ruleInfo && ruleInfo.groupId) {
                ruleUpdated.groupId = ruleInfo.groupId;
                ruleUpdated.name = ruleInfo.name;
            }
            setRuleUpdated(ruleUpdated);
        }
    }, [ruleInfo, isNewRoule]);

    useEffect(() => {
        let isDifference = false;
        if (isNewRoule) {
            if (
                ruleUpdated &&
                ruleUpdated.expression &&
                ruleUpdated.expression !== "AA==" &&
                ruleUpdated.groupId &&
                ruleUpdated.groupId.length === 24 &&
                ruleUpdated.meaning
            ) {
                isDifference = true;
            }
        } else {
            if (ruleInfo && ruleUpdated) {
                if (
                    ruleInfo.description !== ruleUpdated.description ||
                    (ruleInfo.expression !== ruleUpdated.expression &&
                        ruleUpdated.expression &&
                        ruleUpdated.expression !== "AA==") ||
                    (ruleInfo.groupId !== ruleUpdated.groupId && ruleUpdated.groupId.length === 24) ||
                    (ruleInfo.meaning !== ruleUpdated.meaning && ruleUpdated.meaning)
                ) {
                    isDifference = true;
                }
            } else {
                if (ruleUpdated && ruleUpdated.groupId && ruleUpdated.expression && ruleUpdated.meaning) {
                    isDifference = true;
                }
            }
        }

        setIsDifference(isDifference);
    }, [ruleInfo, ruleUpdated, isNewRoule]);

    useEffect(() => {
        let isSameExpression = false;
        if (expressionTest && ruleUpdated && ruleUpdated.expression) {
            let expression = base64.decode(ruleUpdated.expression);
            isSameExpression = new RegExp(expression).test(expressionTest);
        }
        setIsSameExpression(isSameExpression);
    }, [expressionTest, ruleUpdated]);

    const displaySuccessToast = msg => {
        message.success(msg);
    };

    if (loading) {
        return (
            <div style={{ position: "absolute", top: "50%", right: "50%" }}>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </div>
        );
    }

    return (
        <div>
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    icon="delete"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={true}
                    onClick={() => handleDeleteRuleInfo(ruleUpdated)}
                >
                    <FormattedMessage id={"delete"} />
                </Button>
                <Button
                    type="primary"
                    icon="save"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={!isDifference}
                    onClick={() => (isNewRoule ? handleCreateRuleInfo(ruleUpdated) : handleUpdateRuleInfo(ruleUpdated))}
                >
                    <FormattedMessage id={"save"} />
                </Button>
            </div>
            <div>
                {ruleUpdated && ruleUpdated.id && (
                    <ContainerItem>
                        <ContainerField>{<FormattedMessage id={"hubid"} />}: </ContainerField>
                        {ruleUpdated.id}
                        <ContainerCopyIcon>
                            <CopyToClipboard
                                text={ruleUpdated.id}
                                onCopy={() => displaySuccessToast("HubId copiato correttamente", true)}
                            >
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </ContainerCopyIcon>
                    </ContainerItem>
                )}
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"type"} />}: </ContainerField>
                    {ruleUpdated && ruleUpdated.type ? ruleUpdated.type : <FormattedMessage id={"missing_data"} />}
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"category"} />}: </ContainerField>
                    {ruleUpdated && ruleUpdated.category ? (
                        ruleUpdated.category
                    ) : (
                        <FormattedMessage id={"missing_data"} />
                    )}
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>
                        <FormattedMessage id={"cbi_reason"} />:
                    </ContainerField>
                    <Select
                        showSearch
                        style={{ width: "100%", color: "#333333" }}
                        value={ruleUpdated && ruleUpdated.meaning ? ruleUpdated.meaning : null}
                        optionFilterProp="children"
                        onChange={value =>
                            setRuleUpdated({
                                ...ruleUpdated,
                                meaning: value
                            })
                        }
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {cbiOptions}
                    </Select>
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"consortium"} />}:</ContainerField>
                    <AsyncPaginate
                        value={
                            ruleUpdated && ruleUpdated.groupId
                                ? { value: ruleUpdated.groupId, label: `${ruleUpdated.name} (${ruleUpdated.groupId})` }
                                : null
                        }
                        loadOptions={handleLoadAssociationLazy}
                        onChange={item =>
                            setRuleUpdated({
                                ...ruleUpdated,
                                groupId: item.value,
                                name: item.label.split(" (")[0]
                            })
                        }
                        isSearchable={true}
                        placeholder={
                            ruleUpdated && ruleUpdated.groupId
                                ? { value: ruleUpdated.groupId, label: `${ruleUpdated.name} (${ruleUpdated.groupId})` }
                                : null
                        }
                        loadingMessage={() => <FormattedMessage id={"loading"} />}
                        noOptionsMessage={() => <FormattedMessage id="not_available" />}
                        isClearable={false}
                        additional={{
                            page: 1
                        }}
                    />
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"expression"} />}:</ContainerField>
                    <Input
                        placeholder="Inserisci espressione"
                        value={ruleUpdated && ruleUpdated.expression ? base64.decode(ruleUpdated.expression) : ""}
                        onChange={e =>
                            setRuleUpdated({
                                ...ruleUpdated,
                                expression: base64.encode(e.target.value)
                            })
                        }
                    />
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"description"} />}:</ContainerField>
                    <Input.TextArea
                        placeholder="Inserisci la descrizione"
                        value={ruleUpdated && ruleUpdated.description ? ruleUpdated.description : null}
                        rows={5}
                        onChange={e =>
                            setRuleUpdated({
                                ...ruleUpdated,
                                description: e.target.value
                            })
                        }
                    />
                </ContainerItem>
                <Collapse
                    defaultActiveKey="1"
                    bordered={false}
                    style={{ marginTop: 40 }}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                >
                    <Collapse.Panel
                        header="Controllo espressione"
                        key="1"
                        style={{
                            background: "#f7f7f7",
                            borderRadius: 4,
                            marginBottom: 24,
                            border: 0,
                            overflow: "hidden",
                            paddingBottom: 24
                        }}
                    >
                        <Input
                            style={{ marginLeft: 8, marginRight: 8 }}
                            placeholder="Inserisci un testo per controllare espressione"
                            onChange={e => setExpressionTest(e.target.value)}
                            value={expressionTest}
                            suffix={
                                <Icon
                                    type={isSameExpression ? "check-circle" : "close-circle"}
                                    style={{
                                        color: isSameExpression ? "green" : "red"
                                    }}
                                />
                            }
                        />
                    </Collapse.Panel>
                </Collapse>
            </div>
        </div>
    );
};

BankLinkRuleInfo.propTypes = {
    loading: PropTypes.bool.isRequired,
    isNewRoule: PropTypes.bool.isRequired,
    ruleInfo: PropTypes.object,
    handleUpdateRuleInfo: PropTypes.func.isRequired,
    handleDeleteRuleInfo: PropTypes.func.isRequired,
    handleCreateRuleInfo: PropTypes.func.isRequired,
    handleLoadAssociationLazy: PropTypes.func.isRequired
};
