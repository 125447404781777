import React from "react";
import { compose } from "redux";
import { form } from "@mondora/conv-redux-form";
import { injectIntl, FormattedMessage } from "react-intl";

import TextField from "@mondora/arc/antd/TextField";
import { Col, Row } from "antd";
import { apiKeySchema } from "../../../utils/form-schema/api-key";

const ApiKeyForm = ({ intl }) => {
    return (
        <Row gutter={12}>
            <Col span={24}>
                <TextField
                    name="description"
                    label={<FormattedMessage id="general.description" />}
                    placeholder={intl.formatMessage({
                        id: "general.description"
                    })}
                />
            </Col>
        </Row>
    );
};
const formDefinition = {
    form: "apiKey",
    schema: apiKeySchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);
export default composedHoc(ApiKeyForm);
