import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { FormattedMessage } from "react-intl";

import { serviceDecode } from "../../../utils/decode-services";
import { userIsAuthorized } from "../../../utils/get-roles";
import isNumeric from "antd/es/_util/isNumeric";
import { Typography, Switch, Avatar, Tabs } from "antd";

import { CompanySignatureTable } from "./signature-table";
import { SignaturesUserConfig } from "./signature-config-user";
import { CompanySignatureContent, CompanySignatureSwitch } from "./styled";

export const CompanySignature = ({
    auth,
    admin,
    company,
    postSignSearch,
    signatureSearch,
    getSignatureUserConfig,
    getDocumentTypePermission,
    getSignerPermission,
    getSignDocumentStatuses,
    getSignDocumentTypes,
    signatureDocumentStatuses,
    signatureDocumentTypes,
    history
}) => {
    const [expanded, setExpanded] = useState(false);
    const [signatureDataSource, setSignatureDataSource] = useState([]);
    const [signatureQuery, setSignatureQuery] = useState("");
    const [documentStatuses, setDocumentStatuses] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [signatureAuthorized, setSignatureAuthorized] = useState(false);
    const [userList, setUserList] = useState([]);

    let pagination = {
        pageSize: 10,
        current: signatureSearch.page ? signatureSearch.page.number + 1 : 0,
        total: signatureSearch.page ? signatureSearch.page.totalElements.valueOf() : 0,
        hideOnSinglePage: true
    };

    useEffect(() => {
        let value = localStorage.getItem("CompanySignature:expanded");
        setExpanded(value && value === "true" ? true : false);
        handleGetDocuments(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (admin.adminInfo != null) {
            let signatureAuthorized = userIsAuthorized("c-companySignature-actions-column", admin.adminInfo.permits);
            setSignatureAuthorized(signatureAuthorized);
        } else {
            setSignatureAuthorized(false);
        }
    }, [admin]);

    useEffect(() => {
        if (signatureSearch.status.ended && signatureSearch._embedded != null) {
            setSignatureDataSource(signatureSearch._embedded.documentList);
        } else {
            setSignatureDataSource([]);
        }
    }, [signatureSearch]);

    useEffect(() => {
        getSignDocumentStatuses(auth.loginAuth);
        getSignDocumentTypes(auth.loginAuth);
    }, [auth.loginAuth, getSignDocumentStatuses, getSignDocumentTypes]);

    useEffect(() => {
        let documentStatuses = [];
        if (signatureDocumentStatuses.status.ended) {
            Object.entries(signatureDocumentStatuses).forEach(status => {
                status.forEach(element => {
                    if (element.id) {
                        documentStatuses.push({ value: element.id, text: element.title });
                    }
                });
            });
        }
        setDocumentStatuses(documentStatuses);
    }, [signatureDocumentStatuses]);

    useEffect(() => {
        let documentTypes = [];
        if (signatureDocumentTypes.status.ended) {
            Object.entries(signatureDocumentTypes).forEach(types => {
                types.forEach(element => {
                    if (element.id) {
                        documentTypes.push({ value: element.id, text: element.title });
                    }
                });
            });
        }
        setDocumentTypes(documentTypes);
    }, [signatureDocumentTypes]);

    useEffect(() => {
        let users = [];

        if (company.users.users) {
            company.users.users.forEach(user => {
                let signatureRoles = [];
                if (user.profile.type === "PERSONALE") {
                    const asyncSetUserConfig = async user => {
                        const result = await getSignatureUserConfig(
                            auth.loginAuth,
                            company.info.base.id,
                            user.profile.id
                        );
                        if (result) {
                            signatureRoles.push(result.data);
                        }
                    };
                    users.push({
                        id: user.profile.id,
                        firstName: user.profile.firstName,
                        lastName: user.profile.lastName,
                        functionality: signatureRoles
                    });
                    asyncSetUserConfig(user);
                }
            });
        }
        setUserList(users);
    }, [auth.loginAuth, getSignatureUserConfig, company.users.users, company.info.base.id]);
    const handleChangeExpanded = value => {
        localStorage.setItem("CompanySignature:expanded", value);
        setExpanded(value);
    };

    const getQueries = currentFilters => {
        let listFilter = [];
        if (currentFilters) {
            let keys = Object.keys(currentFilters);
            for (let key of keys) {
                if (currentFilters[key] != null && currentFilters[key] !== "") {
                    listFilter.push(key + "=" + currentFilters[key]);
                }
            }
        }
        let nueva = (
            <Typography.Paragraph code={true}>
                <b>
                    <FormattedMessage id="current_query" />
                </b>
                {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setSignatureQuery(nueva);
    };

    const handleGetDocuments = (page, filters) => {
        let filter = { documentIntermediaryId: company.info.base.id };
        if (!isNil(filters)) {
            for (var key in filters) {
                if (key === "documentTypeDetail.title") {
                    filter.documentTypeId = filters[key][0];
                } else if (key === "extStatusCode") {
                    filter.cctStatusCode = filters[key][0];
                } else if (key === "documentStatusCode") {
                    filter.documentStatusId = filters[key][0];
                }
            }
        }
        getQueries(filter);
        postSignSearch(auth.loginAuth, filter, isNumeric(page) ? page : page.current - 1);
    };

    const handleLoadItem = item => {
        history.push(`signature/${item.hubId}`);
    };
    const handleLoadPermission = user => {
        history.push(`signature/${user}`);
    };

    return (
        <div>
            <Tabs variant="secondary" defaultActiveKey="1">
                <Tabs.TabPane tab={<FormattedMessage id="doc_signature_list" />} key="signatureList">
                    <Avatar
                        style={{
                            background: serviceDecode("SIG").background,
                            color: serviceDecode("SIG").color,
                            border: "1px solid" + serviceDecode("SIG").color
                        }}
                    >
                        <i className={serviceDecode("SIG").icon} />
                    </Avatar>{" "}
                    <span className="a1">{serviceDecode("SIG").title}</span>
                    <span className="a1">{" (" + serviceDecode("SIG").title + ")"}</span>
                    <CompanySignatureSwitch>
                        <Switch
                            defaultChecked={false}
                            checked={expanded}
                            checkedChildren={<FormattedMessage id="reduce" />}
                            unCheckedChildren={<FormattedMessage id="expand" />}
                            onChange={e => handleChangeExpanded(e)}
                        />
                    </CompanySignatureSwitch>
                    <br />
                    <CompanySignatureContent>
                        <CompanySignatureTable
                            signatureAuthorized={signatureAuthorized}
                            handleLoadItem={handleLoadItem}
                            signatureQuery={signatureQuery}
                            pagination={pagination}
                            signatureDataSource={signatureDataSource}
                            expanded={expanded}
                            signatureLoading={signatureSearch.status.started && !signatureSearch.status.error}
                            handleGetDocuments={handleGetDocuments}
                            documentTypes={documentTypes}
                            documentStatuses={documentStatuses}
                        />
                    </CompanySignatureContent>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="signature_configuration" />} key="signatureUser">
                    <SignaturesUserConfig
                        signaturesConfigList={userList}
                        getSignerPermission={getSignerPermission}
                        getDocumentTypePermission={getDocumentTypePermission}
                        handleLoadPermission={handleLoadPermission}
                        company={company}
                        auth={auth}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

CompanySignature.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    postSignSearch: PropTypes.func.isRequired,
    signaturesConfigList: PropTypes.array,
    userList: PropTypes.array,
    getSignatureUserConfig: PropTypes.func.isRequired,
    getSignerPermission: PropTypes.func.isRequired,
    getDocumentTypePermission: PropTypes.func.isRequired,
    getSignDocumentStatuses: PropTypes.func.isRequired,
    getSignDocumentTypes: PropTypes.func.isRequired,
    signatureSearch: PropTypes.object.isRequired,
    signatureDocumentStatuses: PropTypes.object.isRequired,
    signatureDocumentTypes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};
