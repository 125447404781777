import { combineReducers } from "redux";
import { getTemplate } from "./templates";
import {
    addToMailingList,
    getMailingList,
    getSentinelGroups,
    deleteFromMailingList,
    notifyToSentinel,
    singleNotifyToSentinel
} from "./sentinel";

export const templates = combineReducers({
    getTemplate
});

export const sentinel = combineReducers({
    addToMailingList,
    getMailingList,
    getSentinelGroups,
    deleteFromMailingList,
    notifyToSentinel,
    singleNotifyToSentinel
});
