import { API_URL } from "../config";
import { postRestCall, getRestCall } from "../utils/rest-api-call";

export const SET_PRIVACY_DATA_START = "SET_PRIVACY_DATA_START";
export const SET_PRIVACY_DATA_SUCCESS = "SET_PRIVACY_DATA_SUCCESS";
export const SET_PRIVACY_DATA_ERROR = "SET_PRIVACY_DATA_ERROR";

export function setPrivacyData(auth, item, privacyValue, message) {
    return async dispatch => {
        const request = {
            ...privacyValue
        };
        const toast = {
            onOk: message,
            onOkText: "Operazione eseguita correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: SET_PRIVACY_DATA_START,
            request: request
        });

        try {
            const result = await postRestCall(
                `${API_URL}/registry-privacy/items/${item}/privacy`,
                auth,
                {},
                request,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: SET_PRIVACY_DATA_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: SET_PRIVACY_DATA_ERROR,
                error: e
            });
        }
    };
}
export const GET_PRIVACY_DATA_START = "GET_PRIVACY_DATA_START";
export const GET_PRIVACY_DATA_SUCCESS = "GET_PRIVACY_DATA_SUCCESS";
export const GET_PRIVACY_DATA_ERROR = "GET_PRIVACY_DATA_ERROR";

export function getPrivacyData(auth, item) {
    return async dispatch => {
        dispatch({
            type: GET_PRIVACY_DATA_START,
            request: item
        });

        try {
            const result = await getRestCall(
                `${API_URL}/registry-privacy/items/${item}/privacy`,
                auth,
                {},
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_PRIVACY_DATA_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_PRIVACY_DATA_ERROR,
                error: e
            });
        }
    };
}
