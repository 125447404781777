import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { contains, isNil } from "ramda";
import { Redirect } from "react-router-dom";

import ServiceCard from "../../../components/general/ServiceCard/info";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getDigitalboxConfig } from "../../../actions/services/digitalbox";
import { activateService, disableService } from "../../../actions/company/services";
import { userIsAuthorized } from "../../../utils/get-roles";
import DigitalboxService from "../../../components/services/digitalbox";

class DigitalboxView extends Component {
    static propTypes = {
        activateService: PropTypes.func.isRequired,
        disableService: PropTypes.func.isRequired,
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        getDigitalboxConfig: PropTypes.func.isRequired,
        location: PropTypes.object,
        services: PropTypes.object
    };

    componentDidMount() {
        const { auth, company, getDigitalboxConfig } = this.props;

        if (company.info.base) {
            company.active.services.forEach(service => {
                if (contains("DIGITALBOX", service.agyoService.id)) {
                    getDigitalboxConfig(auth.loginAuth, company.info.base.id);
                }
            });
        }
    }

    render() {
        const { auth, admin, activateService, disableService, company, history, services } = this.props;
        return !isNil(company.info.base) ? (
            <ServiceCard
                history={history}
                content={
                    <DigitalboxService
                        auth={auth}
                        active={company.active}
                        activateService={activateService}
                        disableService={disableService}
                        company={company}
                        services={services}
                        isHavePermission={userIsAuthorized("enable-disable-dbx-service", admin.adminInfo.permits)}
                    />
                }
                serviceInfo={{ name: "Digitalbox", category: "DBX" }}
                statisticsEnable={false}
            />
        ) : (
            <Redirect to="/item" />
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    services: state.services
});

const actions = { activateService, disableService, getDigitalboxConfig };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(DigitalboxView);
