import { API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_EXEMPTION_FLOW_START = "GET_EXEMPTION_FLOW_START";
export const GET_EXEMPTION_FLOW_SUCCESS = "GET_EXEMPTION_FLOW_SUCCESS";
export const GET_EXEMPTION_FLOW_ERROR = "GET_EXEMPTION_FLOW_ERROR";

export function getExemptionFlow(auth, owner, managed) {
    return async dispatch => {
        dispatch({
            type: GET_EXEMPTION_FLOW_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/flowExemption/${owner}/${managed}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_EXEMPTION_FLOW_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_EXEMPTION_FLOW_ERROR,
                error: e
            });
        }
    };
}
