import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { StandardTabs, TabPane, Button } from "@mondora/arc/antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress } from "@fortawesome/pro-regular-svg-icons";
import { PdfHandler } from "../../../components/general/pdf-handler";

import { ContainerMetadata, CloseContainer, DetailContainer, ExpandContainer } from "./styled";

export const SignatureInfoDrawer = ({
    documentDownloaded,
    handleClose,
    signed,
    handleChangeSigned,
    disableOrginalDoc,
    disableSignedDoc,
    loading = false
}) => {
    const [defaultActiveKey, setDefaultActiveKey] = useState("orginal");
    const [base64, setBase64] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (documentDownloaded.status.ended) {
            setBase64(documentDownloaded.base64);
        } else {
            setBase64("");
        }
    }, [documentDownloaded]);

    useEffect(() => {
        if (signed) {
            setDefaultActiveKey("signed");
        } else {
            setDefaultActiveKey("orginal");
        }
    }, [signed]);

    return (
        <DetailContainer isExpanded={isExpanded ? "97.5%" : "40%"}>
            <CloseContainer>
                <Button onClick={handleClose}>x</Button>
            </CloseContainer>
            <ExpandContainer onClick={() => setIsExpanded(!isExpanded)}>
                <FontAwesomeIcon icon={isExpanded ? faCompress : faExpand} />
            </ExpandContainer>
            <div style={{ padding: "30px" }}>
                <StandardTabs onChange={handleChangeSigned} defaultActiveKey={defaultActiveKey} variant="secondary">
                    <TabPane
                        tab={<FormattedMessage id={"original_document"} />}
                        key="orginal"
                        disabled={disableOrginalDoc}
                    >
                        <ContainerMetadata>
                            <PdfHandler content={base64} loading={loading} fileName={"documento_originale.pdf"} />
                        </ContainerMetadata>
                    </TabPane>
                    <TabPane tab={<FormattedMessage id={"signed_document"} />} key="signed" disabled={disableSignedDoc}>
                        <ContainerMetadata>
                            <PdfHandler content={base64} loading={loading} fileName={"documento_firmato.pdf"} />
                        </ContainerMetadata>
                    </TabPane>
                </StandardTabs>
            </div>
        </DetailContainer>
    );
};

SignatureInfoDrawer.propTypes = {
    documentDownloaded: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChangeSigned: PropTypes.func.isRequired,
    disableOrginalDoc: PropTypes.bool.isRequired,
    disableSignedDoc: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired
};
