import styled from "styled-components";

export const CctForm = styled.form`
    padding: 20px;
`;
export const FormStatus = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0;
`;

export const BoldSpan = styled.span`
    font-weight: bolder;
    margin: 20px 0;
`;

export const Title = styled.h1`
    font-weight: bolder;
    font-size: 18px;
    color: #005075;
`;
