import styled from "styled-components";

export const CenterContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const Container = styled.div`
    height: 90vh;
    min-height: 700px;
    background: white;
    padding: 24px;
    position: relative;
`;

export const NoData = styled.div`
    font-size: 22px;
    position: absolute;
    left: 50%;
    top: 50%;
`;
