import { fieldRequired } from "@mondora/agyo-validations";

export const boRolesSchema = {
    type: "object",
    properties: {
        appId: {
            type: "string"
        },
        featureCode: {
            type: "string"
        },
        resourceId: {
            type: "string",
            validate: resourceId => fieldRequired("Campo obbligatorio")(resourceId)
        },
        actionKey: {
            type: "string",
            validate: actionKey => fieldRequired("Campo obbligatorio")(actionKey)
        }
    }
};
