import React from "react";
import PropTypes from "prop-types";

import TsCard from "../general/ts-card";
import { FormattedMessage } from "react-intl";
import { CardTsWrapper, SpidViewBlock } from "./styled.js";
import { userIsAuthorized } from "../../utils/get-roles";

const Spid = ({ history, admin }) => {
    return (
        <div>
            {userIsAuthorized("c-sideMenu-spid", admin.adminInfo.permits) ? (
                <SpidViewBlock>
                    <CardTsWrapper className="content">
                        <TsCard
                            title={<FormattedMessage id="c-spid.spid-list.card-title" />}
                            icon="fas fa-user-circle"
                            description={<FormattedMessage id="c-spid.spid-list.card-description" />}
                            action={() => history.push("/spid/spid-list")}
                        />
                    </CardTsWrapper>
                </SpidViewBlock>
            ) : null}
        </div>
    );
};
Spid.propTypes = {
    admin: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};
export default Spid;
