import { METERING_READ_API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_COMPANY_METERING_START = "GET_COMPANY_METERING_START";
export const GET_COMPANY_METERING_SUCCESS = "GET_COMPANY_METERING_SUCCESS";
export const GET_COMPANY_METERING_ERROR = "GET_COMPANY_METERING_ERROR";

export function getCompanyMetering(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_COMPANY_METERING_START
        });

        try {
            const result = await getRestCall(
                `${METERING_READ_API_URL}/companies/${itemId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );
            dispatch({
                type: GET_COMPANY_METERING_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_COMPANY_METERING_ERROR,
                error: e
            });
        }
    };
}
