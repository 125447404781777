import { API_URL } from "../../config";

import { getRestCall } from "../../utils/rest-api-call";

export const FIND_OWN_MANAGED_CONNECTIONS_START = "FIND_OWN_MANAGED_CONNECTIONS_START";
export const FIND_OWN_MANAGED_CONNECTIONS_SUCCESS = "FIND_OWN_MANAGED_CONNECTIONS_SUCCESS";
export const FIND_OWN_MANAGED_CONNECTIONS_ERROR = "FIND_OWN_MANAGED_CONNECTIONS_ERROR";

export function findOwnManagedConnections(auth, filter, params) {
    return async dispatch => {
        const urlParams = {
            ...filter,
            page: params.page,
            size: params.items
        };
        dispatch({
            type: FIND_OWN_MANAGED_CONNECTIONS_START,
            request: urlParams
        });
        try {
            const result = await getRestCall(
                `${API_URL}/connections/read/managed`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );
            dispatch({
                type: FIND_OWN_MANAGED_CONNECTIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: FIND_OWN_MANAGED_CONNECTIONS_ERROR,
                error: e
            });
        }
    };
}

export const FIND_OWN_MANAGER_CONNECTIONS_START = "FIND_OWN_MANAGER_CONNECTIONS_START";
export const FIND_OWN_MANAGER_CONNECTIONS_SUCCESS = "FIND_OWN_MANAGER_CONNECTIONS_SUCCESS";
export const FIND_OWN_MANAGER_CONNECTIONS_ERROR = "FIND_OWN_MANAGER_CONNECTIONS_ERROR";

export function findOwnManagerConnections(auth, filter, params) {
    return async dispatch => {
        const urlParams = {
            ...filter,
            page: params.page,
            size: params.items
        };
        dispatch({
            type: FIND_OWN_MANAGER_CONNECTIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${API_URL}/connections/read/manager`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: FIND_OWN_MANAGER_CONNECTIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: FIND_OWN_MANAGER_CONNECTIONS_ERROR,
                error: e
            });
        }
    };
}

export const SEARCH_CONNECTION_START = "SEARCH_CONNECTION_START";
export const SEARCH_CONNECTION_SUCCESS = "SEARCH_CONNECTION_SUCCESS";
export const SEARCH_CONNECTION_ERROR = "SEARCH_CONNECTION_ERROR";

export function searchConnection(auth, id) {
    return async dispatch => {
        dispatch({
            type: SEARCH_CONNECTION_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/connections/read/connection/${id}`,
                auth,
                "",
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: SEARCH_CONNECTION_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: SEARCH_CONNECTION_ERROR,
                error: e
            });
        }
    };
}
