import { postRestCall, patchRestCall } from "../../../utils/rest-api-call";
import { B2B_WRITE_API_URL } from "../../../config";

export const PATCH_BANK_LINK_RULE_START = "PATCH_BANK_LINK_RULE_START";
export const PATCH_BANK_LINK_RULE_SUCCESS = "PATCH_BANK_LINK_RULE_SUCCESS";
export const PATCH_BANK_LINK_RULE_ERROR = "PATCH_BANK_LINK_RULE_ERROR";

export const updateBankLinkRule = (auth, data, hubId) => async dispatch => {
    let body = {
        ...data
    };

    dispatch({
        type: PATCH_BANK_LINK_RULE_START,
        request: body
    });

    try {
        const result = await patchRestCall(
            `${B2B_WRITE_API_URL}/v1/banklink/private/rules/cbi/${hubId}`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: PATCH_BANK_LINK_RULE_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: PATCH_BANK_LINK_RULE_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const POST_BANK_LINK_RULE_START = "POST_BANK_LINK_RULE_START";
export const POST_BANK_LINK_RULE_SUCCESS = "POST_BANK_LINK_RULE_SUCCESS";
export const POST_BANK_LINK_RULE_ERROR = "POST_BANK_LINK_RULE_ERROR";

export const createBankLinkRule = (auth, data) => async dispatch => {
    let body = {
        ...data
    };

    dispatch({
        type: POST_BANK_LINK_RULE_START,
        request: body
    });

    try {
        const result = await postRestCall(
            `${B2B_WRITE_API_URL}/v1/banklink/private/rules/cbi`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: POST_BANK_LINK_RULE_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: POST_BANK_LINK_RULE_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const PATCH_BANK_LINK_ASSOCIATION_START = "PATCH_BANK_LINK_ASSOCIATION_START";
export const PATCH_BANK_LINK_ASSOCIATION_SUCCESS = "PATCH_BANK_LINK_ASSOCIATION_SUCCESS";
export const PATCH_BANK_LINK_ASSOCIATION_ERROR = "PATCH_BANK_LINK_ASSOCIATION_ERROR";

export const updateBankLinkAssociation = (auth, data, hubId) => async dispatch => {
    let body = {
        ...data
    };

    dispatch({
        type: PATCH_BANK_LINK_ASSOCIATION_START,
        request: body
    });

    try {
        const result = await patchRestCall(
            `${B2B_WRITE_API_URL}/v1/banklink/private/rules/associations/${hubId}`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: PATCH_BANK_LINK_ASSOCIATION_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: PATCH_BANK_LINK_ASSOCIATION_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const POST_BANK_LINK_ASSOCIATION_START = "POST_BANK_LINK_ASSOCIATION_START";
export const POST_BANK_LINK_ASSOCIATION_SUCCESS = "POST_BANK_LINK_ASSOCIATION_SUCCESS";
export const POST_BANK_LINK_ASSOCIATION_ERROR = "POST_BANK_LINK_ASSOCIATION_ERROR";

export const createBankLinkAssociation = (auth, data) => async dispatch => {
    let body = {
        ...data
    };

    dispatch({
        type: POST_BANK_LINK_ASSOCIATION_START,
        request: body
    });

    try {
        const result = await postRestCall(
            `${B2B_WRITE_API_URL}/v1/banklink/private/rules/associations`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: POST_BANK_LINK_ASSOCIATION_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: POST_BANK_LINK_ASSOCIATION_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const PATCH_BANK_LINK_BANK_START = "PATCH_BANK_LINK_BANK_START";
export const PATCH_BANK_LINK_BANK_SUCCESS = "PATCH_BANK_LINK_BANK_SUCCESS";
export const PATCH_BANK_LINK_BANK_ERROR = "PATCH_BANK_LINK_BANK_ERROR";

export const updateBankLinkBank = (auth, data, hubId) => async dispatch => {
    let body = {
        ...data
    };

    dispatch({
        type: PATCH_BANK_LINK_BANK_START,
        request: body
    });

    try {
        const result = await patchRestCall(
            `${B2B_WRITE_API_URL}/v1/banklink/private/rules/bank/${hubId}`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: PATCH_BANK_LINK_BANK_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: PATCH_BANK_LINK_BANK_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};

export const POST_BANK_LINK_BANK_START = "POST_BANK_LINK_BANK_START";
export const POST_BANK_LINK_BANK_SUCCESS = "POST_BANK_LINK_BANK_SUCCESS";
export const POST_BANK_LINK_BANK_ERROR = "POST_BANK_LINK_BANK_ERROR";

export const createBankLinkBank = (auth, data) => async dispatch => {
    let body = {
        ...data
    };

    dispatch({
        type: POST_BANK_LINK_BANK_START,
        request: body
    });

    try {
        const result = await postRestCall(
            `${B2B_WRITE_API_URL}/v1/banklink/private/rules/bank`,
            auth,
            {},
            body,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: POST_BANK_LINK_BANK_SUCCESS,
            payload: result
        });
        return { code: true, message: "OK" };
    } catch (e) {
        dispatch({
            type: POST_BANK_LINK_BANK_ERROR,
            error: e
        });
        return { code: false, message: e.message };
    }
};
