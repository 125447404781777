import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse, Card, Icon, Spin, Timeline } from "antd";

import dt from "../../../../assets/xsl/ricevuta_df_v1.0.xsl";
import li from "../../../../assets/xsl/ricevuta_li_v1.0.xsl";
import { getOTStatusDescription } from "../../../../utils/documents/obligations-tax-status";

const Panel = Collapse.Panel;

class InfoDocument extends Component {
    static propTypes = {
        document: PropTypes.object,
        exit: PropTypes.func.isRequired,
        info: PropTypes.bool
    };

    renderView() {
        const { document } = this.props;
        if (document.content.status.started) {
            return (
                <div style={{ textAlign: "center", marginTop: "35vh" }}>
                    <Spin size="large" />
                </div>
            );
        }

        if (document.content.status.error) {
            return <div style={{ textAlign: "center", marginTop: "35vh" }}>{"ERRORE DURANTE IL CARICAMENTO"}</div>;
        }

        if (document.content && document.content.payload) {
            return (
                <div>
                    <div id="receipt_preview">
                        {new global.Transformation()
                            .setXml(decodeURIComponent(atob(document.content.payload)))
                            .setXslt(document.infoD.tipo === "LI" ? li : dt)
                            .transform("receipt_preview")}
                    </div>
                </div>
            );
        }
    }

    renderHistory() {
        const { document } = this.props;
        var historyComponent = [];
        if (document !== undefined && document.infoD.steps !== 0) {
            document.infoD.steps.forEach(value => {
                historyComponent.push(
                    <Timeline.Item key={value.stepName + value.id} color={value.error ? "red" : "green"}>
                        <p>
                            {value.stepName + " " + new Date(Number(value.timestamp)).toLocaleString()}
                            <br />
                            <span>{value.messaggio}</span>
                        </p>
                        <p>{value.description}</p>
                    </Timeline.Item>
                );
            });
        } else {
            historyComponent.push(<span>{"Nessun stato presente."}</span>);
        }
        return (
            <div>
                <Timeline>{historyComponent}</Timeline>
            </div>
        );
    }

    renderInfoItem() {
        const { info, document, exit } = this.props;

        return info && document.infoD !== undefined ? (
            <div style={{ margin: "15px 0px", borderRadius: "5px" }}>
                <Card
                    title={"HubId: " + document.infoD.hubId}
                    extra={
                        <Icon onClick={exit} type="close-circle-o" style={{ fontSize: "20px", cursor: "pointer" }} />
                    }
                    style={{ borderRadius: "5px", height: "88vh" }}
                >
                    <div>
                        <div style={{ width: "45%", float: "left" }}>
                            <div
                                style={{
                                    paddingBottom: "15px",
                                    borderBottom: "1px solid #e8e8e8"
                                }}
                            >
                                <span className="invoiceInfo">HubId:</span> <span>{document.infoD.hubId}</span>
                                <br />
                                <span className="invoiceInfo">Tipo:</span> <span>{document.infoD.tipo}</span>
                                <br />
                                <span className="invoiceInfo">Stato:</span>{" "}
                                <span>{getOTStatusDescription(document.infoD.stato)}</span>
                                <br />
                                <span className="invoiceInfo">Data:</span>{" "}
                                <span>
                                    {document.infoD.timestamp !== null
                                        ? new Date(Number(document.infoD.timestamp)).toLocaleString()
                                        : "Non presente"}
                                </span>
                                <br />
                                <span className="invoiceInfo">Codice Fiscale:</span>
                                <span>
                                    {document.infoD.codiceFiscale !== null
                                        ? document.infoD.codiceFiscale
                                        : "Non presente"}
                                </span>
                                <span className="invoiceInfo">Id:</span>{" "}
                                <span>{document.infoD.id !== null ? document.infoD.id : "Non presente"}</span>
                                <br />
                                <span className="invoiceInfo">Nome file: </span>
                                <span>
                                    {document.infoD.fileName !== null ? document.infoD.fileName : "Non presente"}
                                </span>
                                <br />
                                <span className="invoiceInfo">SdiId: </span>
                                <span>{document.infoD.sdiId !== null ? document.infoD.sdiId : "Non presente"}</span>
                                <br />
                                <span className="invoiceInfo">AppName: </span>
                                <span>{document.infoD.appName !== null ? document.infoD.appName : "Non presente"}</span>
                                <br />
                            </div>
                            <div style={{ paddingBottom: "15px" }}>
                                <Collapse bordered={false} accordion defaultActiveKey={["1"]}>
                                    <Panel header={"Storia documento"} key="1">
                                        {this.renderHistory()}
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div
                            style={{
                                float: "right",
                                width: "45%",
                                height: "75vh",
                                overflow: "auto",
                                border: "2px solid #e8e8e8",
                                borderRadius: "4px"
                            }}
                        >
                            {this.renderView()}
                        </div>
                    </div>
                </Card>
            </div>
        ) : null;
    }

    render() {
        const { document } = this.props;

        if (document.infoD.status.started) {
            return (
                <div style={{ textAlign: "center", marginTop: "35vh" }}>
                    <Spin size="large" />
                </div>
            );
        }

        if (document.infoD.status.error) {
            return <div style={{ textAlign: "center", marginTop: "35vh" }}>{"ERRORE DURANTE IL CARICAMENTO"}</div>;
        }

        return <div>{this.renderInfoItem()}</div>;
    }
}

export default InfoDocument;
