import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Spin, Icon } from "antd";
import { MeteringLynfaServiceContent, MeteringLynfaTitle, MeteringLynfaServiceSpinner } from "./styled";

export const MeteringLynfaService = ({ data, importsAmount }) => {
    const columns = [
        {
            title: <FormattedMessage id="c-meteringlynfaservice.item" />,
            dataIndex: "item",
            key: "item"
        },
        {
            title: <FormattedMessage id="c-meteringlynfaservice.amount" />,
            dataIndex: "amount",
            key: "amount"
        }
    ];

    return (
        <MeteringLynfaServiceContent>
            <span className="a1">
                <FormattedMessage id="c-meteringlynfaservice.import" />
            </span>
            <br />
            {data != null ? (
                <Table
                    title={() => (
                        <MeteringLynfaTitle>
                            <FormattedMessage id="c-meteringlynfaservice.total" />
                            {importsAmount}
                        </MeteringLynfaTitle>
                    )}
                    dataSource={data}
                    columns={columns}
                    size="small"
                    pagination={false}
                />
            ) : (
                <MeteringLynfaServiceSpinner>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                </MeteringLynfaServiceSpinner>
            )}
            <br />
        </MeteringLynfaServiceContent>
    );
};

MeteringLynfaService.propTypes = {
    data: PropTypes.array,
    importsAmount: PropTypes.number
};
