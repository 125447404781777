import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, message, Spin, Icon, Input } from "antd";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ContainerField, ContainerCopyIcon, ContainerItem } from "./styled";

export const BankLinkAssociationInfo = ({
    loading,
    isNewAssociation,
    associationInfo,
    handleUpdateAssociationInfo,
    handleDeleteAssociationInfo,
    handleCreateAssociationInfo
}) => {
    const [associationUpdated, setAssociationUpdated] = useState(null);
    const [isDifference, setIsDifference] = useState(false);

    useEffect(() => {
        if (!isNewAssociation) {
            setAssociationUpdated(associationInfo);
        } else {
            setAssociationUpdated({});
        }
    }, [associationInfo, isNewAssociation]);

    useEffect(() => {
        let isDifference = false;
        if (isNewAssociation) {
            if (associationUpdated && associationUpdated.name) {
                isDifference = true;
            }
        } else {
            if (associationInfo && associationUpdated) {
                if (
                    associationInfo.description !== associationUpdated.description ||
                    associationInfo.name !== associationUpdated.name
                ) {
                    isDifference = true;
                }
            } else {
                if (associationUpdated && associationUpdated.name) {
                    isDifference = true;
                }
            }
        }

        setIsDifference(isDifference);
    }, [associationInfo, associationUpdated, isNewAssociation]);

    const displaySuccessToast = msg => {
        message.success(msg);
    };

    if (loading) {
        return (
            <div style={{ position: "absolute", top: "50%", right: "50%" }}>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </div>
        );
    }

    return (
        <div>
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    icon="delete"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={true}
                    onClick={() => handleDeleteAssociationInfo(associationUpdated)}
                >
                    <FormattedMessage id={"delete"} />
                </Button>
                <Button
                    type="primary"
                    icon="save"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={!isDifference}
                    onClick={() =>
                        isNewAssociation
                            ? handleCreateAssociationInfo(associationUpdated)
                            : handleUpdateAssociationInfo(associationUpdated)
                    }
                >
                    <FormattedMessage id={"save"} />
                </Button>
            </div>
            <div>
                {associationUpdated && associationUpdated.id && (
                    <ContainerItem>
                        <ContainerField>{<FormattedMessage id={"hubid"} />}: </ContainerField>
                        {associationUpdated.id}
                        <ContainerCopyIcon>
                            <CopyToClipboard
                                text={associationUpdated.id}
                                onCopy={() => displaySuccessToast("HubId copiato correttamente", true)}
                            >
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </ContainerCopyIcon>
                    </ContainerItem>
                )}
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"name"} />}:</ContainerField>
                    <Input.TextArea
                        placeholder="Inserisci nome"
                        value={associationUpdated && associationUpdated.name ? associationUpdated.name : null}
                        rows={1}
                        onChange={e =>
                            setAssociationUpdated({
                                ...associationUpdated,
                                name: e.target.value
                            })
                        }
                    />
                </ContainerItem>
                <ContainerItem>
                    <ContainerField>{<FormattedMessage id={"description"} />}:</ContainerField>
                    <Input.TextArea
                        placeholder="Inserisci descrizione"
                        value={
                            associationUpdated && associationUpdated.description ? associationUpdated.description : null
                        }
                        rows={5}
                        onChange={e =>
                            setAssociationUpdated({
                                ...associationUpdated,
                                description: e.target.value
                            })
                        }
                    />
                </ContainerItem>
            </div>
        </div>
    );
};

BankLinkAssociationInfo.propTypes = {
    loading: PropTypes.bool.isRequired,
    isNewAssociation: PropTypes.bool.isRequired,
    associationInfo: PropTypes.object,
    handleUpdateAssociationInfo: PropTypes.func.isRequired,
    handleDeleteAssociationInfo: PropTypes.func.isRequired,
    handleCreateAssociationInfo: PropTypes.func.isRequired
};
