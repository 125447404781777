import React, { Component } from "react";
import PropTypes from "prop-types";
import { isUUID } from "validator";

import { Button, Modal, Switch, Table } from "antd";
import { restrictToRoleForComponent } from "../../general/hoc";
import { backOfficeRoles } from "../../../utils/constant";

import BoRolesForm from "../form";

class AdminInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mAddRole: false
        };
    }
    static propTypes = {
        admin: PropTypes.object.isRequired,
        userInfo: PropTypes.object.isRequired,
        handleAddRoles: PropTypes.func.isRequired,
        handleRemoveRoles: PropTypes.func.isRequired
    };

    addRole(values) {
        const { handleAddRoles } = this.props;
        if (isUUID(values.resourceId)) {
            values.resourceId.toLowerCase();
        } else {
            values.resourceId.toUpperCase();
        }
        let role = { ...values };
        this.setState({ mAddRole: false });
        handleAddRoles([role]);
    }

    enableDisableTechniciansRole(isToEnable, roleValues) {
        const { handleAddRoles, handleRemoveRoles } = this.props;
        let role = {
            appId: roleValues.appId,
            resourceId: roleValues.resourceId,
            actionKey: roleValues.actionKey,
            featureCode: roleValues.featureCode
        };
        if (isToEnable) {
            handleAddRoles([role]);
        } else {
            handleRemoveRoles([role]);
        }
    }

    render() {
        const { userInfo } = this.props;
        const { mAddRole } = this.state;
        const backOfficeColumns = [
            {
                title: "Id",
                dataIndex: "id"
            },
            {
                title: "AppId",
                dataIndex: "appId"
            },
            {
                title: "Descrizione",
                dataIndex: "description"
            },
            {
                title: "",
                render: (text, record) => (
                    <div className="c-user-roles-action-button">
                        <Switch
                            checked={
                                userInfo.roles &&
                                userInfo.roles.some(
                                    role =>
                                        role.actionKey === record.actionKey &&
                                        role.resourceId === record.resourceId &&
                                        record.appId === role.appId
                                )
                            }
                            onChange={value => this.enableDisableTechniciansRole(value, record)}
                        />
                    </div>
                )
            }
        ];

        return (
            <div style={{ overflow: "auto" }}>
                <div style={{ textAlign: "right" }}>
                    <Button
                        type="primary"
                        className="actions-button"
                        onClick={() => this.setState({ mAddRole: true })}
                        icon={"user-add"}
                    >
                        Aggiungi ruolo
                    </Button>
                </div>
                <br /> <br />
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>RUOLI BACKOFFICE</p>
                <div style={{ backgroundColor: "white" }}>
                    <Table
                        rowKey={(record, index) => index}
                        columns={backOfficeColumns}
                        dataSource={backOfficeRoles}
                        size="small"
                        pagination={false}
                    />
                </div>
                <Modal
                    footer={null}
                    centered
                    onCancel={() => this.setState({ mAddRole: false })}
                    title="Aggiungi ruolo"
                    visible={mAddRole}
                >
                    <BoRolesForm onSubmit={values => this.addRole(values)} />
                </Modal>
            </div>
        );
    }
}

export default restrictToRoleForComponent(true)(AdminInfo);
