import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Searchbar } from "@mondora/arc/antd/Searchbar";
import { isUUID } from "validator";

import { searchItems } from "../../../actions/search/company";
import { displayErrorToast } from "../../../actions/toast";
import { findOwnManagerConnections } from "../../../actions/connections/read";

const CertificationsSearch = ({ auth, history, displayErrorToast, searchItems, findOwnManagerConnections }) => {
    const onSearch = async itemSearched => {
        itemSearched = itemSearched.toUpperCase();
        if (itemSearched.length === 11 || itemSearched.length === 16 || isUUID(itemSearched)) {
            if (isUUID(itemSearched)) {
                history.push(`/certifications/contract/${itemSearched}`);
            } else {
                let itemsData = await searchItems(auth.loginAuth, itemSearched, true);
                if (itemsData.items.length === 1) {
                    let item = itemsData.items[0].item;
                    let id = item.base.id;
                    if (item.base.details.classifier === "COMPANY") {
                        let filter = {
                            managedIds: item.base.uuid,
                            appId: ["EIP", "CRAM"],
                            // active: true,
                            deleted: false,
                            status: ["PENDING_VALIDATION", "VALIDATED", "VALIDATION_REJECTED"]
                        };
                        let connection = await findOwnManagerConnections(auth.loginAuth, filter, {
                            page: 0,
                            items: 2000
                        });
                        if (connection.content.length > 0) {
                            id = connection.content[0].connections[0].id;
                        }
                    }
                    history.push(`/certifications/contract/${id}`);
                }
                if (itemsData.items.length === 0 || itemsData.items.length > 1) {
                    displayErrorToast("Identificativo assente o non univoco", true);
                }
            }
        } else {
            displayErrorToast("Il valore di ricerca non corretto", true);
        }
    };
    return (
        <Searchbar
            placeholder="Inserisci l'id del documento/certificazione"
            onSearch={v => onSearch(v.value.trim())}
            enterButton
        />
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});
const actions = { displayErrorToast, findOwnManagerConnections, searchItems };

CertificationsSearch.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(CertificationsSearch);
