import { B2B_READ_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_ERECEIPTS_START = "GET_ERECEIPTS_START";
export const GET_ERECEIPTS_SUCCESS = "GET_ERECEIPTS_SUCCESS";
export const GET_ERECEIPTS_ERROR = "GET_ERECEIPTS_ERROR";

export function getEReceipts(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_ERECEIPTS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/eReceipts`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_ERECEIPTS_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_ERECEIPTS_ERROR,
                error: e
            });
        }
    };
}

export const GET_ERECEIPT_START = "GET_ERECEIPT_START";
export const GET_ERECEIPT_SUCCESS = "GET_ERECEIPT_SUCCESS";
export const GET_ERECEIPT_ERROR = "GET_ERECEIPT_ERROR";

export function getEReceipt(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: GET_ERECEIPT_START
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/eReceipts/${hubId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_ERECEIPT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_ERECEIPT_ERROR,
                error: e
            });
        }
    };
}

export const DOWNLOAD_ERECEIPT_START = "DOWNLOAD_ERECEIPT_START";
export const DOWNLOAD_ERECEIPT_SUCCESS = "DOWNLOAD_ERECEIPT_SUCCESS";
export const DOWNLOAD_ERECEIPT_ERROR = "DOWNLOAD_ERECEIPT_ERROR";

export function downloadEReceipt(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: DOWNLOAD_ERECEIPT_START
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/eReceipts/${hubId}/download`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: DOWNLOAD_ERECEIPT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: DOWNLOAD_ERECEIPT_ERROR,
                error: e
            });
        }
    };
}
