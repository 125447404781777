import { B2B_READ_API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

const SEARCH_ORDERS_START = "SEARCH_ORDERS_START";
const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
const SEARCH_ORDERS_ERROR = "SEARCH_ORDERS_ERROR";

export function getOrders(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size: size
        };
        dispatch({
            type: SEARCH_ORDERS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/orders`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: SEARCH_ORDERS_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: SEARCH_ORDERS_ERROR,
                error: e
            });
        }
    };
}
