import styled from "styled-components";

export const Container = styled.div`
    background: white;
`;

export const ContainerMetadata = styled.div`
    width: calc(100% - 20px);
    margin-left: 20px;
    padding-right: 24px;
    height: 85%;
    position: absolute;
`;
