import { API_URL } from "../../config";
import { METERING_ARCHIVER_READ_URL } from "../../config";

import { getRestCall } from "../../utils/rest-api-call";

export const GET_FILE_DOWNLOAD_START = "GET_FILE_DOWNLOAD_START";
export const GET_FILE_DOWNLOAD_SUCCESS = "GET_FILE_DOWNLOAD_SUCCESS";
export const GET_FILE_DOWNLOAD_ERROR = "GET_FILE_DOWNLOAD_ERROR";

export const getFileConsumptions = (
    auth,
    id,
    dateFrom,
    dateTo,
    ownerId,
    typeList,
    online,
    page,
    size
) => async dispatch => {
    let urlParams = {
        dateFrom,
        dateTo,
        ownerId,
        typeList,
        online,
        page,
        size
    };
    dispatch({
        type: GET_FILE_DOWNLOAD_START,
        request: urlParams
    });
    const toast = {
        onOk: "message",
        onOkText: "File estratto con successo",
        onError: true,
        infoReq: { authId: auth.id, req: urlParams }
    };
    try {
        const result = await getRestCall(
            `${API_URL}/consumptions/${id}/report`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        const resultDown = await getRestCall(
            `${METERING_ARCHIVER_READ_URL}/files/download?idList=${result}`,
            auth,
            null,
            dispatch,
            auth.refreshToken,
            toast,
            "blob"
        );
        dispatch({
            type: GET_FILE_DOWNLOAD_SUCCESS,
            payload: resultDown
        });
        return resultDown;
    } catch (e) {
        dispatch({
            type: GET_FILE_DOWNLOAD_ERROR,
            error: e
        });
    }
};
