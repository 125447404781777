import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { translateInvoiceStatus } from "../../../utils/translate";
import { userIsAuthorized } from "../../../utils/get-roles";
import { Button, Alert, Input, Select } from "antd";

import {
    Container,
    ContainerIdentity,
    ContainerUsage,
    ContainerTitle,
    ContainerField,
    DivContainer,
    ContainerSubTitle,
    InfoSection
} from "./styled";

export const InvoiceInfoIdentity = ({
    admin,
    document,
    getUsageInvoicePackage,
    fromInformations,
    reassignUsageInvoice,
    removeUsageInvoice,
    reassignUsageInvoicePackage
}) => {
    const [ownerId, setOwnerId] = useState(null);
    const [packages, setPackages] = useState([]);
    const [newPackage, setNewPackage] = useState(null);

    useEffect(() => {
        getUsageInvoicePackage(document.invoiceUsage.owner);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let packages = [];
        if (
            document.invoiceUsagePackage &&
            document.invoiceUsagePackage.status.ended &&
            !document.invoiceUsagePackage.status.error
        ) {
            for (var i = 0; i < Object.keys(document.invoiceUsagePackage).length - 1; i++) {
                packages.push(
                    <Select.Option
                        disabled={!document.invoiceUsagePackage[i].enabled}
                        value={document.invoiceUsagePackage[i].id}
                        key={i}
                    >
                        {`${document.invoiceUsagePackage[i].id} - ${document.invoiceUsagePackage[i].name}`}
                    </Select.Option>
                );
            }
        }
        setPackages(packages);
    }, [document.invoiceUsagePackage]);

    const checkIsManaged = history => {
        if (history !== undefined && history.length !== 0) {
            for (var value of history) {
                if (value.name === "IN_GESTIONE") {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <Container>
            {!fromInformations && (
                <ContainerIdentity>
                    <InfoSection>
                        <ContainerTitle> {<FormattedMessage id={"identifier"} />}</ContainerTitle>
                        <div>
                            <ContainerField>{<FormattedMessage id={"hubid"} />}:</ContainerField> {document.infoD.hubId}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"sdiid"} />}:</ContainerField>{" "}
                            {document.infoD.sdiId ? document.infoD.sdiId : <FormattedMessage id={"missing_data"} />}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"batchid"} />}:</ContainerField>{" "}
                            {document.infoD.batchId ? document.infoD.batchId : <FormattedMessage id={"missing_data"} />}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"pacakgeid"} />}:</ContainerField>{" "}
                            {document.infoD.packageId ? (
                                document.infoD.packageId
                            ) : (
                                <FormattedMessage id={"missing_data"} />
                            )}
                        </div>
                    </InfoSection>
                    <InfoSection>
                        <ContainerTitle> {<FormattedMessage id={"additional_data"} />}</ContainerTitle>
                        <div>
                            <ContainerField>{<FormattedMessage id={"state"} />}:</ContainerField>{" "}
                            {translateInvoiceStatus(
                                document.infoD.currentStatus.name,
                                checkIsManaged(document.infoD.status.HUB)
                            )}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"date"} />}:</ContainerField>{" "}
                            {document.infoD.docDate !== null ? (
                                document.infoD.docDate
                            ) : (
                                <FormattedMessage id={"missing_data"} />
                            )}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"format"} />}:</ContainerField>{" "}
                            {document.infoD.format !== null ? (
                                document.infoD.format
                            ) : (
                                <FormattedMessage id={"missing_data"} />
                            )}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"category"} />}:</ContainerField>{" "}
                            {document.infoD.category !== null ? (
                                document.infoD.category
                            ) : (
                                <FormattedMessage id={"missing_data"} />
                            )}
                        </div>
                        <div>
                            <ContainerField>{<FormattedMessage id={"file_name"} />}:</ContainerField>{" "}
                            {document.infoD.fileName !== null ? (
                                document.infoD.fileName
                            ) : (
                                <FormattedMessage id={"missing_data"} />
                            )}
                        </div>

                        <div>
                            <ContainerField>{<FormattedMessage id={"reception_date"} />}:</ContainerField>{" "}
                            {document.infoD.receiptDate !== null ? (
                                document.infoD.receiptDate
                            ) : (
                                <FormattedMessage id={"missing_data"} />
                            )}
                        </div>
                    </InfoSection>
                </ContainerIdentity>
            )}
            <ContainerUsage>
                <InfoSection>
                    {!fromInformations ? (
                        <ContainerTitle>
                            <FormattedMessage id={"c-invoice.usage.panel"} />
                        </ContainerTitle>
                    ) : (
                        <ContainerSubTitle>
                            <FormattedMessage id={"c-invoice.usage.panel"} />
                        </ContainerSubTitle>
                    )}
                </InfoSection>
                {document.invoiceUsage.requestId ? (
                    !document.invoiceUsage.status.errorInfo ? (
                        <InfoSection>
                            <div>
                                <ContainerField>
                                    {" "}
                                    <FormattedMessage id={"c-invoice.usage.app"} />:
                                </ContainerField>
                                {document.invoiceUsage.app !== null ? (
                                    document.invoiceUsage.app
                                ) : (
                                    <FormattedMessage id={"missing_data"} />
                                )}
                            </div>
                            <div>
                                <ContainerField>
                                    <FormattedMessage id={"c-invoice.usage.owner"} />:
                                </ContainerField>
                                {document.invoiceUsage.owner !== null ? (
                                    document.invoiceUsage.owner
                                ) : (
                                    <FormattedMessage id={"missing_data"} />
                                )}
                            </div>
                            {userIsAuthorized("c-invoice-usage-reassign-invoice-usage", admin.adminInfo.permits) &&
                            !fromInformations ? (
                                <Container>
                                    <span style={{ marginRight: "15px" }}>
                                        <Input
                                            size="small"
                                            value={ownerId}
                                            onChange={e => setOwnerId(e.target.value)}
                                            style={{ width: "350" }}
                                        />
                                    </span>
                                    <Button size="small" onClick={() => reassignUsageInvoice(ownerId)}>
                                        <FormattedMessage id={"c-invoice.usage.reassign-invoice-usage"} />
                                    </Button>
                                </Container>
                            ) : null}
                            <div>
                                <ContainerField>
                                    <FormattedMessage id={"c-invoice.usage.usage-id"} />:
                                </ContainerField>
                                {document.invoiceUsage.requestId !== null ? (
                                    document.invoiceUsage.requestId
                                ) : (
                                    <FormattedMessage id={"missing_data"} />
                                )}
                                {userIsAuthorized("c-invoice-usage-delete-invoice-usage", admin.adminInfo.permits) &&
                                    !fromInformations && (
                                        <DivContainer>
                                            <Button size="small" onClick={() => removeUsageInvoice()}>
                                                <FormattedMessage id={"c-invoice.usage.delete-invoice-usage"} />
                                            </Button>
                                        </DivContainer>
                                    )}
                            </div>
                            <div>
                                <ContainerField>
                                    <FormattedMessage id={"c-invoice.usage.pacakge"} />:
                                </ContainerField>
                                {document.invoiceUsage.packageName !== null ? (
                                    document.invoiceUsage.packageName
                                ) : (
                                    <FormattedMessage id={"missing_data"} />
                                )}
                                {userIsAuthorized(
                                    "c-invoice.usage.reassign-invoice-usage-package",
                                    admin.adminInfo.permits
                                ) &&
                                    !fromInformations && (
                                        <Container>
                                            <span style={{ marginRight: "15px" }}>
                                                <Select
                                                    size="small"
                                                    defaultValue="Seleziona nuovo pacchetto"
                                                    showSearch
                                                    className="invoices-item-select"
                                                    placeholder={document.invoiceUsage.packageName}
                                                    onChange={newPackage => setNewPackage(newPackage)}
                                                >
                                                    {packages}
                                                </Select>
                                            </span>
                                            <Button
                                                size="small"
                                                onClick={() => reassignUsageInvoicePackage(newPackage)}
                                            >
                                                <FormattedMessage
                                                    id={"c-invoice.usage.reassign-invoice-usage-package"}
                                                />
                                            </Button>
                                        </Container>
                                    )}
                            </div>
                        </InfoSection>
                    ) : (
                        <Alert
                            key={`${document.invoiceUsage.status.errorInfo.code} (${
                                document.invoiceUsage.status.errorInfo.message
                                    ? document.invoiceUsage.status.errorInfo.message
                                    : "nessuna descrizione"
                            })`}
                            message={`${document.invoiceUsage.status.errorInfo.code} (${
                                document.invoiceUsage.status.errorInfo.message
                                    ? document.invoiceUsage.status.errorInfo.message
                                    : "nessuna descrizione"
                            })`}
                            type="error"
                            showIcon
                            style={{ marginBottom: "5px" }}
                        />
                    )
                ) : (
                    <Alert
                        key={"Dato non disponibile"}
                        message={"Dato non disponibile"}
                        type="warning"
                        showIcon
                        style={{ marginBottom: "5px" }}
                    />
                )}
            </ContainerUsage>
        </Container>
    );
};

InvoiceInfoIdentity.propTypes = {
    admin: PropTypes.object.isRequired,
    document: PropTypes.object.isRequired,
    getUsageInvoicePackage: PropTypes.func.isRequired,
    reassignUsageInvoice: PropTypes.func.isRequired,
    removeUsageInvoice: PropTypes.func.isRequired,
    reassignUsageInvoicePackage: PropTypes.func.isRequired
};
