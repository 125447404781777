import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, Card } from "antd";
import { serviceDecode } from "../../../utils/decode-services";

import { Body, ActionContainer } from "./styled";
class AppCard extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    render() {
        const { app, company, history } = this.props;
        return (
            <Body>
                <Card style={{ height: 100 }}>
                    <Card.Meta
                        avatar={
                            <Avatar
                                style={{
                                    background: serviceDecode(app.id).background,
                                    color: serviceDecode(app.id).color,
                                    border: "1px solid" + serviceDecode(app.id).color
                                }}
                            >
                                <i className={serviceDecode(app.id).icon} />
                            </Avatar>
                        }
                        title={
                            <div>
                                {app.title}
                                <ActionContainer>
                                    <Button
                                        onClick={() =>
                                            history.push({
                                                pathname: app.path,
                                                state: {
                                                    companyId: company.info.base.id
                                                }
                                            })
                                        }
                                        shape="circle"
                                        icon="arrow-right"
                                        size="small"
                                        disabled={app.disabled}
                                    />
                                </ActionContainer>
                            </div>
                        }
                        description={<div style={{ fontSize: 12 }}>{app.description}</div>}
                    />
                </Card>
            </Body>
        );
    }
}

export default AppCard;
