import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";

export const NotificationChannelsEmail = ({ defaultChannels, templateChannels }) => {
    const [data, setData] = useState([]);
    const [defaultEmail, setDefaultEmail] = useState(null);

    useEffect(() => {
        let data = [];
        if (templateChannels && templateChannels.length) {
            templateChannels
                .filter(email => email.type === "EMAIL")
                .forEach(element => {
                    element.recipients.forEach(item => {
                        data.push({ value: item.value || item, name: item.name });
                    });
                });
        }
        setData(data);
    }, [templateChannels]);

    useEffect(() => {
        let data = [];
        if (defaultChannels && defaultChannels.length) {
            data = defaultChannels
                .filter(email => email.type === "EMAIL")
                .map(item => {
                    return item.recipients.map(recipient => {
                        return { value: recipient.value || recipient, name: recipient.name };
                    });
                });
        }
        if (data && data.length) {
            setDefaultEmail(...data[0]);
        } else {
            setDefaultEmail(null);
        }
    }, [defaultChannels]);

    let columns = [
        {
            title: <FormattedMessage id={"recipient"} />,
            dataIndex: "value",
            render: (text, record) => <span>{text || record}</span>
        },
        {
            title: <FormattedMessage id={"name"} />,
            dataIndex: "name"
        }
    ];

    return (
        <div style={{ marginTop: 20, background: "white" }}>
            {(!data || !data.length) &&
                (defaultEmail ? (
                    <div>
                        <span style={{ marginLeft: 10 }}>
                            <FormattedMessage id={"no_specific_channels_configured"} />,{" "}
                            <FormattedMessage id={"notifications_are_sent_to"} />:{" "}
                        </span>
                        <span style={{ fontWeight: "bold" }}>{defaultEmail.value}</span>
                    </div>
                ) : (
                    <FormattedMessage id={"no_default_channels_configured"} />
                ))}
            <Table
                title={() => (
                    <div style={{ marginTop: 20, fontWeight: "bold" }}>
                        <FormattedMessage id={"email_configuration_list"} />:
                    </div>
                )}
                rowKey={(record, index) => index}
                size="middle"
                columns={columns}
                locale={{
                    emptyText: <FormattedMessage id={"no_result"} />
                }}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

NotificationChannelsEmail.propTypes = {
    defaultChannels: PropTypes.array,
    templateChannels: PropTypes.array
};
