import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Dropdown, Menu, Button, Switch, Tag, Tooltip } from "antd";
import { InvoiceFooter } from "../../../components/general/invoice-footer";
import { bankLinkCbiReason, bankLinkCbiReasonFilter } from "../../../utils/constant";

export const BankLinkFileTransactions = ({
    loading,
    paymentTransactionEntityList,
    handleNextPage,
    currentPage,
    hasNextPage,
    handlePreviousPage,
    handlePageSizeChanged,
    defaultPageSize,
    query,
    pageSizeOptions,
    expanded,
    handleExpandedChange,
    handleAddRoules,
    handleDownloadFile,
    filter,
    handleFilterChange
}) => {
    let columns = [
        {
            title: <FormattedMessage id={"cbi_reason"} />,
            width: "10%",
            dataIndex: "cbiReason",
            filters: bankLinkCbiReasonFilter,
            filteredValue: filter && filter.cbiReason ? [filter.cbiReason] : null,
            filterMultiple: false,
            render: text => {
                let item = bankLinkCbiReason.find(x => x.value === text);
                return (
                    <Tooltip title={text !== "XD" && text !== "XA" ? (item ? item.description : null) : null}>
                        <Tag color={text !== "XD" && text !== "XA" ? "green" : "red"}>{text}</Tag>
                    </Tooltip>
                );
            }
        },
        {
            title: <FormattedMessage id={"amount"} />,
            width: "15%",
            dataIndex: "amount"
        },
        {
            title: <FormattedMessage id={"value_date"} />,
            width: "15%",
            dataIndex: "valueDate"
        },
        {
            title: <FormattedMessage id={"transaction_date"} />,
            width: "15%",
            dataIndex: "transactionDate"
        },
        {
            title: <FormattedMessage id={"iban"} />,
            dataIndex: "iban"
        },
        {
            title: <FormattedMessage id="actions" />,
            key: "actions",
            width: "5%",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleAddRoules(record)}>
                    <i className="fas fa-plus-circle" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"add_rules"} />
                </Menu.Item>
                <Menu.Item key="1" onClick={() => handleDownloadFile(record)}>
                    <i className="fas fa-download" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"download_file"} />
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div>
            <div style={{ textAlign: "right", padding: 8 }}>
                <Switch
                    className="notification-switch"
                    checked={expanded}
                    checkedChildren={<FormattedMessage id={"reduce"} />}
                    unCheckedChildren={<FormattedMessage id={"expand"} />}
                    onChange={handleExpandedChange}
                />
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: (
                            <div style={{ height: "50vh" }}>
                                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                                    <FormattedMessage id={"no_result"} />
                                </div>
                            </div>
                        )
                    }}
                    dataSource={paymentTransactionEntityList}
                    loading={loading}
                    expandedRowRender={
                        expanded
                            ? record => (
                                  <p>
                                      <b>
                                          <FormattedMessage id={"id"} />:
                                      </b>
                                      {` ${record.id ? record.id : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"upload_id"} />:
                                      </b>
                                      {` ${record.uploadId ? record.uploadId : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"description"} />:
                                      </b>
                                      {` ${record.description ? record.description : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"source"} />:
                                      </b>
                                      {` ${record.source ? record.source : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"sia"} />:
                                      </b>
                                      {` ${record.sia ? record.sia : "N.D."}`}
                                      <br />
                                  </p>
                              )
                            : null
                    }
                    pagination={false}
                    onChange={(page, filter, sorter) => handleFilterChange(filter)}
                    scroll={{ y: 540 }}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize.toString()}
                disabled={loading}
                handleInvoicePageSizeChanged={handlePageSizeChanged}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === 1}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

BankLinkFileTransactions.propTypes = {
    loading: PropTypes.bool.isRequired,
    paymentTransactionEntityList: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageSizeChanged: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.any.isRequired,
    query: PropTypes.any.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired,
    handleAddRoules: PropTypes.func.isRequired,
    handleDownloadFile: PropTypes.func.isRequired,
    filter: PropTypes.object,
    handleFilterChange: PropTypes.func.isRequired
};
