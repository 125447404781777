import { REGISTRY_WRITE_URL } from "../config";
import { postRestCall, putRestCall } from "../utils/rest-api-call";
import { toNumber } from "../utils/thrift";

export const CREATE_ITEM_START = "CREATE_ITEM_START";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_ERROR = "CREATE_ITEM_ERROR";

export function createItem(auth, itemValue, ownerId) {
    return async dispatch => {
        const request = { item: itemValue, noKeys: true, noOwnership: false, ownerIds: [ownerId] };
        dispatch({
            type: CREATE_ITEM_START,
            payload: request
        });

        const toast = {
            onOk: true,
            onOkText: "Azienda creata correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await postRestCall(
                `${REGISTRY_WRITE_URL}/items`,
                auth,
                {},
                request,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: CREATE_ITEM_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: CREATE_ITEM_ERROR,
                error: e
            });
        }
    };
}

export const CREATE_ITEM_RESET = "CREATE_ITEM_RESET";
export function resetCreateItem() {
    return dispatch => {
        dispatch({
            type: CREATE_ITEM_RESET
        });
    };
}

export const UPDATE_ITEM_START = "UPDATE_ITEM_START";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_ERROR = "UPDATE_ITEM_ERROR";
export const UPDATE_ITEM_RESET = "UPDATE_ITEM_RESET";

export function updateItem(auth, itemValue) {
    return async dispatch => {
        dispatch({
            type: UPDATE_ITEM_START,
            payload: itemValue
        });

        const toast = {
            onOk: true,
            onOkText: "Azienda modificata correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: itemValue }
        };

        const identifier = { ...itemValue.base.identifier };

        let details = { ...itemValue.base.details };

        details.birthDate = toNumber(details.birthDate);

        if (details.economics) {
            details.economics.registrationDate = toNumber(details.economics.registrationDate);
            details.economics.balanceSheetDate = toNumber(details.economics.balanceSheetDate);
        }

        if (details.balanceSheetDate) {
            details.economics.balanceSheetDate = toNumber(details.economics.balanceSheetDate);
        }

        if (details.professionalRegister) {
            details.professionalRegister.registrationDate = toNumber(details.professionalRegister.registrationDate);
        }

        details.legalForm = details.legalForm.code;

        const base = {
            ...itemValue.base,
            identifier,
            details
        };

        const preferences = {
            ...itemValue.preferences,
            enableConsole: itemValue.preferences.enableConsole
        };

        const item = {
            ...itemValue,
            base,
            preferences
        };

        try {
            const result = await putRestCall(
                `${REGISTRY_WRITE_URL}/items/${item.base.uuid}`,
                auth,
                null,
                item,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: UPDATE_ITEM_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: UPDATE_ITEM_ERROR,
                error: e
            });
        }
    };
}
