import { REGISTRY_BO_READ_URL } from "../../config";
import { normalizeItem } from "../../utils/item-utils";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_COMPANY_START = "GET_COMPANY_START";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

export function getCompany(auth, uuid, packageType = "BASE") {
    return async dispatch => {
        dispatch({
            type: GET_COMPANY_START
        });
        const urlParams = {
            packageType
        };
        try {
            let resultV3 = await getRestCall(
                `${REGISTRY_BO_READ_URL}/items/${uuid}`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );
            let resultNormalizeItem = null;
            if (resultV3 && resultV3.item) {
                resultNormalizeItem = normalizeItem(resultV3);
            }
            dispatch({
                type: GET_COMPANY_SUCCESS,
                payload: resultNormalizeItem && resultNormalizeItem.item
            });
            return resultNormalizeItem;
        } catch (e) {
            dispatch({
                type: GET_COMPANY_ERROR,
                error: e
            });
        }
    };
}

export const GET_COMPANY_RESET = "GET_COMPANY_RESET";

export function resetGetCompany() {
    return dispatch => {
        dispatch({
            type: GET_COMPANY_RESET
        });
    };
}

export const FIND_COMPANY_START = "FIND_COMPANY_START";
export const FIND_COMPANY_SUCCESS = "FIND_COMPANY_SUCCESS";
export const FIND_COMPANY_ERROR = "FIND_COMPANY_ERROR";

export function findItems(auth, keyWord, packageType = "BASE") {
    return async dispatch => {
        dispatch({
            type: FIND_COMPANY_START
        });
        const urlParams = {
            packageType,
            "pagination.itemsPerPage": 10,
            "pagination.pageNumber": 0,
            "fullText.keyWord": keyWord
        };

        if (keyWord) {
            try {
                const result = await getRestCall(
                    `${REGISTRY_BO_READ_URL}/items`,
                    auth,
                    urlParams,
                    dispatch,
                    auth.refreshToken
                );
                dispatch({
                    type: FIND_COMPANY_SUCCESS,
                    payload: result
                });
                return result;
            } catch (e) {
                dispatch({
                    type: FIND_COMPANY_ERROR,
                    error: e
                });
            }
        } else {
            dispatch({
                type: FIND_COMPANY_ERROR,
                error: "findItems - Bad Request: itemId is empty"
            });
        }
    };
}
