import { fieldRequired, fieldLength, isInteger } from "@mondora/agyo-validations";
import combineValidators from "@mondora/agyo-validations/lib/utils/combineValidators";

export const addressSchema = {
    type: "object",
    properties: {
        streetName: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        streetNumber: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        city: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        province: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        country: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        zipCode: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    isInteger("Il campo deve contenere valori numerici"),
                    fieldLength("Il campo deve esser lungo 5", 5, 5)
                )(desc)
        }
    }
};
