import {
    GET_B2B_PASSIVE_CONFIG_START,
    GET_B2B_PASSIVE_CONFIG_ERROR,
    GET_B2B_PASSIVE_CONFIG_SUCCESS,
    GET_B2B_PASSIVE_CONFIG_RESET
} from "../../actions/services/b2b-passive";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function b2bPasive(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_B2B_PASSIVE_CONFIG_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_B2B_PASSIVE_CONFIG_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_B2B_PASSIVE_CONFIG_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_B2B_PASSIVE_CONFIG_RESET:
            return defaultState;

        default:
            return state;
    }
}
