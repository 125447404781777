export function getOTStatusDescription(status) {
    switch (status) {
        case "OK":
            return "Ricevuta presto nuovamente disponibile.";
        case "KO":
            return "Scartata in fase di controllo.";
        case "W":
            return "In elaborazione.";
        case "ER":
            return "File elaborato ma il servizio non è al momento disponibile.";
        case "ES03":
            return "File con esito negativo. ";
        case "ES01":
            return "File elaborato, ricevuta disponibile. ";
        case "ES02":
            return "File elaborato con avvisi, ricevuta disponibile.";
        default:
            return status;
    }
}

export function getOTStatusColor(status) {
    switch (status) {
        case "KO":
            return "red";
        case "W":
            return "gray";
        case "ES02":
        case "ER":
        case "OK":
            return "orange";
        case "ES03":
            return "blood";
        case "ES01":
            return "green";
        default:
            return "red";
    }
}

export function getOTPeriodDescription(period) {
    switch (period) {
        case "1":
            return "Gen/Mar(1° Trimestre)";
        case "2":
            return "Apr/Giu(2° Trimestre)";
        case "3":
            return "Lug/Set(3° Trimestre)";
        case "4":
            return "Ott/Dic(4° Trimestre)";
        case "11":
            return "Gen/Giu(1° Semestre)";
        case "12":
            return "Lug/Dic(2° Semestre)";
        default:
            return period;
    }
}
