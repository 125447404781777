import styled from "styled-components";

export const Container = styled.div`
    width: 65%;
`;

export const ContainerTitle = styled.p`
    font-size: 15px;
    font-weight: bold;
    color: #595959;
`;
