import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { translateInvoiceStatus } from "../../../utils/translate";
import { Card, Timeline } from "antd";

import {
    Container,
    ContainerChronology,
    ContainerTitle,
    ContainerHistoryTitle,
    ContainerHistoryDescription
} from "./styled";

export const InvoiceInfoChronology = ({ document }) => {
    const [chronologyComponent, setChronologyComponent] = useState([]);
    const [chronologyCCTComponent, setChronologyCTComponent] = useState([]);
    const [chronologySDIComponent, setChronologySDIComponent] = useState([]);

    useEffect(() => {
        const checkItem = item => {
            let list = [];
            if (item !== undefined && item.length !== 0) {
                let isManaged = checkIsManaged(item);
                item.forEach(value => {
                    list.push(
                        <Timeline.Item key={value.name + value.timestamp}>
                            <ContainerHistoryTitle>
                                {translateInvoiceStatus(value.name, isManaged)}
                            </ContainerHistoryTitle>
                            <br />
                            <ContainerHistoryDescription>
                                {new Date(Number(value.timestamp)).toLocaleString()}
                            </ContainerHistoryDescription>
                            <br />
                            <ContainerHistoryDescription>{value.description}</ContainerHistoryDescription>
                        </Timeline.Item>
                    );
                });
            }
            return list;
        };
        let chronologyComponent = checkItem(document.infoD.status.HUB);
        let chronologyCCTComponent = checkItem(document.infoD.status.CCT);
        let chronologySDIComponent = checkItem(document.infoD.status.SDI);
        setChronologyComponent(chronologyComponent);
        setChronologyCTComponent(chronologyCCTComponent);
        setChronologySDIComponent(chronologySDIComponent);
    }, [document.infoD.status]);

    const checkIsManaged = history => {
        if (history !== undefined && history.length !== 0) {
            for (var value of history) {
                if (value.name === "IN_GESTIONE") {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <Container>
            <ContainerChronology>
                <ContainerTitle>
                    <FormattedMessage id={"chronology"} />
                </ContainerTitle>
                <br />
                <Card bordered={false} style={{ background: "#f7f7f7" }}>
                    {chronologyComponent.length > 0 ? (
                        <Timeline mode="alternate">{chronologyComponent}</Timeline>
                    ) : (
                        <Timeline mode="alternate">
                            <span>
                                <FormattedMessage id={"missing_state"} />
                            </span>
                        </Timeline>
                    )}
                </Card>
            </ContainerChronology>
            <ContainerChronology>
                <ContainerTitle>
                    <FormattedMessage id={"chronology_cct"} />
                </ContainerTitle>
                <br />
                <Card bordered={false} style={{ background: "#f7f7f7" }}>
                    {chronologyCCTComponent.length > 0 ? (
                        <Timeline mode="alternate">{chronologyCCTComponent}</Timeline>
                    ) : (
                        <Timeline mode="alternate">
                            <span>
                                <FormattedMessage id={"missing_state"} />
                            </span>
                        </Timeline>
                    )}
                </Card>
            </ContainerChronology>
            <ContainerChronology>
                <ContainerTitle>
                    <FormattedMessage id={"chronology_sdi"} />
                </ContainerTitle>
                <br />
                <Card bordered={false} style={{ background: "#f7f7f7" }}>
                    {chronologySDIComponent.length > 0 ? (
                        <Timeline mode="alternate">{chronologySDIComponent}</Timeline>
                    ) : (
                        <Timeline mode="alternate">
                            <span>
                                <FormattedMessage id={"missing_state"} />
                            </span>
                        </Timeline>
                    )}
                </Card>
            </ContainerChronology>
        </Container>
    );
};

InvoiceInfoChronology.propTypes = {
    document: PropTypes.object.isRequired
};
