import React from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import { FormattedMessage } from "react-intl";

export const DocumentPermitsDetails = ({ documentsPermits }) => {
    return (
        <span style={{ fontWeight: "bold" }}>
            <FormattedMessage id={"view_selected_docTypes"} />
            <List size="large" dataSource={documentsPermits} renderItem={item => <List.Item>{item}</List.Item>} />
        </span>
    );
};

DocumentPermitsDetails.propTypes = {
    documentsPermits: PropTypes.array.isRequired
};
