import { B2B_WRITE_API_URL } from "../../../config";
import { patchRestCall, deleteRestCall } from "../../../utils/rest-api-call";

export const PATCH_TRASH_INVOICE_START = "PATCH_TRASH_INVOICE_START";
export const PATCH_TRASH_INVOICE_SUCCESS = "PATCH_TRASH_INVOICE_SUCCESS";
export const PATCH_TRASH_INVOICE_ERROR = "PATCH_TRASH_INVOICE_ERROR";

export function trashInvoice(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: PATCH_TRASH_INVOICE_START,
            request: hubId
        });

        const toast = {
            onOk: true,
            onOkText: "Fattura cestinata con successo",
            onError: true,
            infoReq: { authId: auth.id, req: hubId }
        };

        try {
            const result = await patchRestCall(
                `${B2B_WRITE_API_URL}/v1/invoices/trash`,
                auth,
                "",
                [hubId],
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: PATCH_TRASH_INVOICE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: PATCH_TRASH_INVOICE_ERROR,
                error: e
            });
        }
    };
}

export const PATCH_UNTRASH_INVOICE_START = "PATCH_UNTRASH_INVOICE_START";
export const PATCH_UNTRASH_INVOICE_SUCCESS = "PATCH_UNTRASH_INVOICE_SUCCESS";
export const PATCH_UNTRASH_INVOICE_ERROR = "PATCH_UNTRASH_INVOICE_ERROR";

export function untrashInvoice(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: PATCH_UNTRASH_INVOICE_START,
            request: hubId
        });

        const toast = {
            onOk: true,
            onOkText: "Fattura rimossa dal cestino con successo",
            onError: true,
            infoReq: { authId: auth.id, req: hubId }
        };

        try {
            const result = await patchRestCall(
                `${B2B_WRITE_API_URL}/v1/invoices/untrash`,
                auth,
                "",
                [hubId],
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: PATCH_UNTRASH_INVOICE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: PATCH_UNTRASH_INVOICE_ERROR,
                error: e
            });
        }
    };
}

export const DELETE_INVOICE_START = "DELETE_INVOICE_START";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR";

export function deleteInvoice(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: DELETE_INVOICE_START,
            request: hubId
        });

        const toast = {
            onOk: true,
            onOkText: "Fattura eliminata con successo",
            onError: true,
            infoReq: { authId: auth.id, req: hubId }
        };

        try {
            const result = await deleteRestCall(
                `${B2B_WRITE_API_URL}/v2/invoices/${hubId}`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: DELETE_INVOICE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DELETE_INVOICE_ERROR,
                error: e
            });
        }
    };
}
