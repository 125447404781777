import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Dropdown, Menu, Button, Switch } from "antd";
import { InvoiceFooter } from "../../../../components/general/invoice-footer";

export const BankLinkFiles = ({
    loading,
    fileUploadEntityList,
    handleNextPage,
    currentPage,
    hasNextPage,
    handlePreviousPage,
    handlePageSizeChanged,
    defaultPageSize,
    query,
    pageSizeOptions,
    expanded,
    handleExpandedChange,
    handleDownloadFile,
    handleViewFileTransaction
}) => {
    let columns = [
        {
            title: <FormattedMessage id={"type"} />,
            width: "10%",
            dataIndex: "fileType"
        },
        {
            title: <FormattedMessage id={"total_transaction"} />,
            width: "15%",
            dataIndex: "totalTransaction"
        },
        {
            title: <FormattedMessage id={"created_at"} />,
            dataIndex: "createdAt"
        },
        {
            title: <FormattedMessage id={"resolved"} />,
            width: "10%",
            dataIndex: "resolvedReasons"
        },
        {
            title: <FormattedMessage id={"not_resolved"} />,
            width: "15%",
            dataIndex: "defaultReasons"
        },
        {
            title: <FormattedMessage id={"status"} />,
            width: "15%",
            dataIndex: "currentStatusName"
        },
        {
            title: <FormattedMessage id="actions" />,
            key: "actions",
            width: "5%",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleViewFileTransaction(record)}>
                    <i className="fas fa-info-circle" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"show_movements"} />
                </Menu.Item>
                <Menu.Item key="1" onClick={() => handleDownloadFile(record)}>
                    <i className="fas fa-download" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"download_file"} />
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div>
            <div style={{ textAlign: "right", padding: 8 }}>
                <Switch
                    className="notification-switch"
                    checked={expanded}
                    checkedChildren={<FormattedMessage id={"reduce"} />}
                    unCheckedChildren={<FormattedMessage id={"expand"} />}
                    onChange={handleExpandedChange}
                />
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={fileUploadEntityList}
                    loading={loading}
                    expandedRowRender={
                        expanded
                            ? record => (
                                  <p>
                                      <b>
                                          <FormattedMessage id={"hubid"} />:
                                      </b>
                                      {` ${record.hubId ? record.hubId : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"iban"} />:
                                      </b>
                                      {` ${record.iban ? record.iban : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"owner_id"} />:
                                      </b>
                                      {` ${record.ownerId ? record.ownerId : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"sender_id"} />:
                                      </b>
                                      {` ${record.senderId ? record.senderId : "N.D."}`}
                                      <br />
                                      <b>
                                          <FormattedMessage id={"transmitter_id"} />:
                                      </b>
                                      {` ${record.transmitterId ? record.transmitterId : "N.D."}`}
                                      <br />
                                  </p>
                              )
                            : null
                    }
                    pagination={false}
                    scroll={{ y: 540 }}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize.toString()}
                disabled={loading}
                handleInvoicePageSizeChanged={handlePageSizeChanged}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === 1}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

BankLinkFiles.propTypes = {
    loading: PropTypes.bool.isRequired,
    fileUploadEntityList: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageSizeChanged: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.any.isRequired,
    query: PropTypes.any.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired,
    handleDownloadFile: PropTypes.func.isRequired,
    handleViewFileTransaction: PropTypes.func.isRequired
};
