import styled from "styled-components";

export const SpidSectionTitle = styled.h1`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
`;

export const SectionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px;
`;
