import styled from "styled-components";

export const MeteringInfoCardBody = styled.div`
    height: 100vh;
`;

export const MeteringInfoTitle = styled.span`
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
    color: #595959;
`;

export const MeteringInfoCloseIcon = styled.div`
    font-size: 25px;
    right: 20px;
    position: absolute;
    margin-top: -10px;
    cursor: pointer;
`;

export const MeteringInfoSpinner = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;
