import React from "react";
import PropTypes from "prop-types";

import { Table } from "antd";

export const UserPreferencesTable = ({ userPreferences, onSelectUser, onChange, pagination, loading }) => {
    const columns = [
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "NcsId",
            dataIndex: "ncsId"
        },
        {
            title: "Data Inserimento",
            dataIndex: "insertedDate"
        }
    ];

    const data =
        userPreferences &&
        userPreferences
            .filter(user => user !== null)
            .map(
                user =>
                    user && {
                        key: user.email,
                        email: user.email,
                        ncsId: user.ncsId,
                        insertedDate: user.insertedDate
                    }
            );

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKey, selectedRows) => {
            onSelectUser(selectedRowKey[0]);
        },
        getCheckboxProps: record => ({
            disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name
        })
    };

    return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Table
                rowSelection={{
                    type: "radio",
                    ...rowSelection
                }}
                columns={columns}
                dataSource={data}
                onChange={onChange}
                pagination={pagination}
                loading={loading}
            />
        </div>
    );
};

UserPreferencesTable.propTypes = {
    onSelectUser: PropTypes.func,
    userPreferences: PropTypes.array
};
