import styled from "styled-components";

export const CenteredContainer = styled.div`
    text-align: center;
`;

export const SpinContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
`;

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;
