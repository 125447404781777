import { API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_INVOICE_USER_CONFIG_START = "GET_INVOICE_USER_CONFIG_START";
export const GET_INVOICE_USER_CONFIG_SUCCESS = "GET_INVOICE_USER_CONFIG_SUCCESS";
export const GET_INVOICE_USER_CONFIG_ERROR = "GET_INVOICE_USER_CONFIG_ERROR";

export const getInvoiceUserConfig = (auth, itemId) => async dispatch => {
    dispatch({
        type: GET_INVOICE_USER_CONFIG_START,
        request: itemId
    });

    try {
        const result = await getRestCall(
            `${API_URL}/companyConfigs/${itemId}/configs/authz`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_INVOICE_USER_CONFIG_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_INVOICE_USER_CONFIG_ERROR,
            error: e
        });
    }
};
