export default {
    "c-expired-password.title": "Password scaduta. Inserisci una nuova password.",

    "c-reset-password.title": "Inserisci la nuova password che vuoi utilizzare.",
    "c-reset-password.password.placeholder": "Nuova Password",
    "c-reset-password.confirmPassword.placeholder": "Conferma Password",
    "c-reset-password.confirm.button": "Conferma",

    "c-change-password.title": "Cambio password",
    "c-change-password.password.placeholder": "Nuova Password",
    "c-change-password.confirmPassword.placeholder": "Conferma Password",
    "c-change-password.confirm.button": "Salva",
    "c-change-password.confirm.error": "Errore: {errMsg} ",
    "c-change-password.confirm.tooltip1": "La password deve essere lunga almeno 8 caratteri e deve contenere:",
    "c-change-password.confirm.tooltip2": "- almeno una lettera maiuscola: A-Z",
    "c-change-password.confirm.tooltip3": "- almeno una lettera minuscola: a-z",
    "c-change-password.confirm.tooltip4": "- almeno un numero: 0-9 ",
    "c-change-password.confirm.tooltip5": "- almeno un carattere speciale:  !@#£%?ç$%^&*)(+=._-",
    "c-form-login.button.text": "Accedi senza Teamsystem ID",
    "c-form-login.tsid.button.text": "Accedi o registrati con Teamsystem ID",
    "c-form-login.message.text":
        "Se non disponi di un TeamSystem ID Inserisci le tue credenziali per effettuare l’accesso al Backoffice.",
    "c-form-login.alert.text": "Questa operazione sarà consentita solo fino al 10 Novembre 2022",
    "validation.taxId.person.wrongLength": "Il codice fiscale deve avere lunghezza 16 caratteri",

    "general.yes": "SI",
    "general.no": "NO",
    "general.show": "Visualizza",
    "general.taxId": "Codice fiscale",
    "general.vatNumber": "Partita iva",
    "general.status": "Stato",
    "general.certify": "Certifica",
    "general.itemDescription": "Ragione sociale",
    "general.id": "Identificativo",
    "general.goBackList": "Torna alla lista",
    "general.no-result": "Nessun risultato.",
    "general.itemIdNotStudio": "L'identificativo inserito non si riferisce ad uno STUDIO.",
    "general.file-not-found": "File non trovato",
    "general.no-data": "Dato non presente",
    "general.firstName": "Nome",
    "general.lastName": "Cognome",
    "general.role": "Ruolo",
    "general.documentDate": "Data documento",
    "general.documentPlace": "Luogo documento",
    "general.save": "Salva",
    "general.skip": "Salta",
    "general.reject": "Rigetta",
    "general.roles.OWNR": "Titolare",
    "general.roles.LEGL": "Legale rappresentante",
    "general.roles.PROS": "Procuratore",
    "general.roles.OTHE": "Altro",
    "general.enable": "Abilitata",
    "general.notEnable": "Non abilitata",
    "general.goPageBack": " Torna alla pagina precedente",
    "general.type": "Tipologia",
    "general.extValidate": "Validata esternamente",
    "general.uploadBy": "Caricato da",
    "general.date": "Data",
    "general.address": "Indirizzo",
    "general.registry": "Anagrafica",
    "general.itemToBeCertify": "Aziende da certificare",
    "general.privacy": "Privacy",
    "general.documentAction": "Azioni documento",
    "general.dashboard": "Dashboard",
    "general.searchItems": "Aziende",
    "general.users": "Utenti",
    "general.companyInfo": "Scheda azienda",
    "general.correction": "Rettifiche",
    "general.monitoring": "Monitoraggio",
    "general.bo": "Backoffice",
    "general.core": "Core",
    "general.invoicing": "Fatturazione",
    "general.admUsers": "Utenti backoffice",
    "general.admTools": "Tools",
    "general.admTemplates": "Moduli",
    "general.email": "Email",
    "general.company": "Azienda",
    "general.warning": "Avvisi",
    "general.searchUsers": "Ricerca utenti",
    "general.user": "Utente",
    "general.onCancel": "Indietro",
    "general.birthday": "Data di nascita",
    "general.intialized": "Inizializzato",
    "general.rejected": "Rigettato",
    "general.validated": "Validato",
    "general.uploaded": "Caricato",
    "general.close": "Chiudi",
    "general.rejectMessage": "Motivo rifiuto",
    "general.sign": "Firma",
    "general.hubId": "HubId",
    "general.jobId": "JobId",
    "general.batchId": "BatchId",
    "general.fileName": "Nome file",
    "general.signType": "Tipo firma",
    "general.cctStatus": "Stato CCT",
    "general.year": "Anno",
    "general.expiryDate": "Scadenza",
    "general.signOwner": "Azienda/Privato",
    "general.transmitter": "Intermediario",
    "general.active": "Attivo",
    "general.disabled": "Disattivo",
    "general.actions": "Azioni",
    "general.goToStudio": "Torna allo studio",
    "general.managedList": "Lista gestite",
    "general.pausedAction": "Sospendi",
    "general.pausedStatus": "In pausa",
    "general.pausedDescription": "Stato di avanzamento",
    "general.description": "Descrizione",
    "general.add": "Aggiungi",
    "general.cancel": "Annulla",
    "general.confirm": "Conferma",

    "error.company-not-valid-for-certify": "Impossibile certificare l'azienda",

    "c-sentinel.sentinelTitle": "Invio email report sentinel",
    "c-sentinel.configuredTemplates": "Templates configurati:",
    "c-sentinel.sendTitle": "Invia email",
    "c-sentinel.sendTo": "Invia a:",
    "c-sentinel.sendTemplate": "Template:",
    "c-sentinel.emailTitle": "Titolo:",
    "c-sentinel.sentinelNumber": "Numero sentinel:",
    "c-sentinel.delete-modal-title": "Conferma rimozione",
    "c-sentinel.delete-modal-text": "Sei sicuro di voler rimuovere {email} dalla mailing list? ( ncsId: {ncsId} )",
    "c-sentinel.add-modal-title": "Aggiungi destinatario",
    "c-sentinel.add-modal-text": "Inserisci la mail del destinatario da aggiungere: ",
    "c-sentinel.send-email": "Invia Email",
    "c-sentinel.severity": "Severity:",
    "c-sentinel.informational": "INFORMATIONAL",
    "c-sentinel.low": "LOW",
    "c-sentinel.medium": "MEDIUM",
    "c-sentinel.high": "HIGH",
    "c-sentinel.description": "Corpo del messaggio:",
    "c-sentinel.nextupdate": "Data e ora prossimo aggiornamento ( Next Update ):",
    "c-sentinel.add-user": "Aggiungi utente al gruppo",
    "c-sentinel.group-select": "Seleziona gruppo per invio",
    "c-sentinel.single-email": "email singola",
    "c-sentinel.send-confirm-title": "Conferma invio",
    "c-sentinel.send-confirm-group-text": "Confermi l'invio al gruppo di notifica: {groupEmails}",
    "c-sentinel.send-confirm-single-text": "Confermi l'invio alla mail: {recipientEmail}",
    "c-sentinel.copy-users": "Esporta in clipboard la lista email",

    "c-app-name.title": "Censimento Applicativi",
    "c-app-name.configuredAppNames": "Applicativi Censiti",
    "c-app-name.add-app-name": "Aggiungi nuovo applicativo",

    "c-app-name.delete-modal-title": "Conferma rimozione",
    "c-app-name.delete-modal-text": "Sei sicuro di voler rimuovere l'applicativo: {appName}?",

    "c-app-name.add-modal-title": "Aggiungi applicativo",
    "c-app-name.pop-confirm-text": "Conferma inserimento dati",

    "c-app-name.edit-modal-title": "Modifica dati applicativo",

    "c-app-name.app-modal-text": "Inserisci i dati relativi all'applicativo ( tutti i campi richiesti): ",
    "c-app-name.app-modal-required": "Tutti i campi richiesti *",
    "c-app-name.option.int": "Interno",
    "c-app-name.option.ext": "Esterno",
    "c-app-name.form.type": "Type *",
    "c-app-name.download.csv": "Scarica CSV applicativi",
    "c-app-name.title.appname": "Totale applicativi censiti:",
    "c-menu.admUsers": "Gestione Utenti BO",
    "c-menu.admTools": "Tools",
    "c-menu.admTemplates": "Gestione Template NCS",
    "c-menu.dashboard": "Dashboard",
    "c-menu.items": "Aziende",
    "c-menu.users": "Utenti",
    "c-menu.admNotifications": "Notifiche",
    "c-menu.correction": "Retifiche",
    "c-menu.monitoring": "Monitoraggio",
    "c-menu.api": "Ts Digital Api",
    "c-menu.bus": "Bus Produzione",
    "c-menu.cct": "Conservazione",
    "c-menu.sentinel": "Invia email Sentinel",
    "c-menu.spid": "SPID",
    "c-menu.appaname": "Censimento applicativi",

    "c-certifications.modal.pausedConfirm":
        "Dando conferma sospenderai temporaneamente il contratto per l'azienda {name}. Il contratto potra essere ritrovato nella lista dei documenti sospesi",
    "c-certifications.modal.validateConfirm": "Dando conferma certificherai il contratto per l'azienda {name}",
    "c-certifications.modal.rejectConfirm": "Dando conferma rifiuterai il contratto per l'azienda {name}",

    "c-certifications-managed.goToSeach": "Torna alla ricerca studio",
    "c-certifications-managed.no-studio-result": "Nessuno studio trovato",
    "c-certifications-managed.no-result": "Nessun dato trovato",
    "c-certifications-managed.load-contract-error": "Errore durante il caricamento del contratto",
    "c-certifications-managed.search-title": "Inserisci l'identificativo dello STUDIO che vuoi certificare.",
    "c-certifications-managed.studio-data": "DATI COMMERCIALISTA",
    "c-certifications-managed.company-data": "DATI AZIENDA",
    "c-certifications-managed.managed-contracts": "CONTRATTI GESTITE",
    "c-certifications-managed.contract": "CONTRATTO",
    "c-certifications-managed.go-to-studio": "Torna allo studio",
    "c-certifications.showManaged": "Visualizza la lista delle aziende associate allo studio.",

    "c-certifications.title": "Certificazioni",
    "c-certifications.validation-type": "VALIDAZIONI",
    "c-certifications.pending": "Nuovi documenti",
    "c-certifications.validated": "Aziende da certificare",
    "c-certifications.rejected": "Documenti rigettati",
    "c-certifications.reloaded": "Documenti ricaricati",
    "c-certifications.pending-desc": "Visualizza i nuovi documenti caricati.",
    "c-certifications.reloaded-desc": "Visualizza documenti ricaricati.",
    "c-certifications.rejected-desc": "Visualizza i documenti rigettati.",
    "c-certifications.validated-desc": "Esamina e certifica i documenti delle aziende.",
    "c-certifications.awaiting-upload": "Documenti rigettati",
    "c-certifications.awaiting-upload-desc": "Visualizza i documenti rigettati.",
    "c-certifications.certified": "Documenti certificati",
    "c-certifications.certified-desc": "Visualizza i documenti certificati.",
    "c-certifications.managed-certify": "Certificazione Gestite",
    "c-certifications.managed-certify-desc": "Certifica i contratti delle gestite di uno studio.",
    "c-certifications.managed-certify-type": "CERTIFICAZIONI",
    "c-certifications.item-certify": "Documenti da certificare",
    "c-certifications.item-certify-desc": "Esamina e certifica i contratti delle aziende.",
    "c-certifications.warningPrivacy":
        "Aggiungere i dati riguardanti la privacy, una volta inseriti si potrà certificare il contratto.",
    "c-certifications.warningExtValidation": "Documento validato esternamente. Nessuna azione disponibile",
    "c-certifications.certifyError": "Errore durante la certificazione",
    "c-certifications.certifySuccess": "Contratto certificato correttamente",
    "c-certifications.pauseError": "Errore durante la sospensione del documento",
    "c-certifications.pauseSuccess": "Contratto sospeso correttamente",
    "c-certifications.rejectError": "Errore durante la fase di rigetto",
    "c-certifications.rejectSuccess": "Contratto rigettato correttamente",
    "c-certifications.privacyDataExist": " Dati inseriti",
    "c-certifications.addNoteForReject": "Inserisci una nota per rigettare il certificato:",

    "c-certifications.manager-info": "Anagrafica Gestore",
    "c-certifications.managed-info": "Anagrafica Gestita",
    "c-certifications.rc-info": "Dati responsabile alla conservazione",
    "c-certifications.validate-cct": "Conservazione Sostitutiva",
    "c-certifications.validate-cct-desc": "Valida i contratti di conservazione caricati.",
    "c-certifications.validate-cct-type": "VALIDAZIONE CCT",
    "c-certifications.warningCFValidation":
        "CF Responsabile della conservazione non corretto o dati non sufficienti per la verifica",
    "c-certifications-item.search-title": "Inserisci l'identificativo dell'azienda che vuoi certificare.",
    "c-certifications-paused": "Documenti sospesi",
    "c-certifications-paused-desc": "Visualizza i documenti che sono stati messi in stato sospeso",

    "c-new-archive.section-title": "CCT - Richieste RDC NUOVO ARCHIVE",

    "c-new-archive.inzialized-title": "Inizializzati",
    "c-new-archive.inzialized-description": "Lista documenti inizializzati.",
    "c-new-archive.tobecertified-title": "Da certificare",
    "c-new-archive.tobecertified-description": "Lista documenti da certificare.",
    "c-new-archive.rejected-title": "Rigettati",
    "c-new-archive.rejected-description": "Lista documenti rigettati.",

    "c-new-archive.desc-produttore-column": "Descrizione Produttore",
    "c-new-archive.cf-produttore-column": "CF/UUID Produttore",
    "c-new-archive.desc-titolare-column": "Descrizione Titolare",
    "c-new-archive.cf-titolare-column": "CF/UUID Titolare",
    "c-new-archive.draft-nodata": "Nessun dato da mostrare",

    "c-new-archive.rdc-list-INITIALIZED": "NEW ARCHIVE - Contratti RDC inizializzati",
    "c-new-archive.rdc-list-TOBE_CERTIFIED": "NEW ARCHIVE - Contratti RDC da certificare",
    "c-new-archive.rdc-list-REJECTED": "NEW ARCHIVE - Contratti RDC rigettati",

    "c-spid.spid-list.card-title": "SPID",
    "c-spid.spid-list.card-description": "Visualizza la lista con tutti i dettagli sullo stato degli SPID.",

    "c-spid.spid-list.view-title": "Status delle richieste SPID",
    "c-spid.spid-list.item-id": "Inserisci item ID",
    "c-spid.spid-list.item-search": "Inserisci l'id dell'azienda da ricercare",

    "c-spid.spid-list.user-popup.title": "Informazioni utente",
    "c-spid.spid-list.user-popup.user-id": "Id utente: ",
    "c-spid.spid-list.user-popup.name": "Nome: ",
    "c-spid.spid-list.user-popup.surname": "Cognome: ",
    "c-spid.spid-list.user-popup.email": "Email: ",
    "c-spid.spid-list.user-popup.taxId": "Codice Fiscale: ",
    "c-spid.spid-list.user-popup.ncsId": "NcsId: ",

    "c-spid.spid-list.table-headers.requested-date": "Data richiesta",
    "c-spid.spid-list.table-headers.update-date": "Data aggiornamento",
    "c-spid.spid-list.table-headers.expiration-date": "Scadenza riconoscimento",
    "c-spid.spid-list.table-headers.user": "Utente",
    "c-spid.spid-list.table-headers.history": "Storico",
    "c-spid.spid-list.table-headers.recognition": "Riconoscimento",
    "c-spid.spid-list.table-headers.status": "SPID Status",
    "c-spid.spid-list.table-headers.identity-type": "Identity Type",
    "c-spid.spid-list.table-headers.item-cf": "CF Azienda",
    "c-spid.spid-list.table-headers.spid-id": "Spid ID",

    "c-invoice.usage.panel": "Consumo",
    "c-invoice.usage.reassign-invoice-usage": "Riassegna",
    "c-invoice.usage.reassign-invoice-usage-package": "Riassegna",
    "c-invoice.usage.delete-invoice-usage": "Elimina consumo",
    "c-invoice.usage.app": "App",
    "c-invoice.usage.usage-id": "Id consumo",
    "c-invoice.usage.owner": "Owner",
    "c-invoice.usage.button": "Ricalcolo dei consumi",
    "c-invoice.usage.console": "Console consumi su portale",
    "c-invoice.usage.pacakge": "Pacchetto",
    "c-invoice.info.is-read": "Presa visione",
    "c-invoice.trash": "Cestina",
    "c-invoice.untrash": "Ripristina",
    "c-invoice.notification.replay": "Ricarica Notifiche",
    "c-invoice.deadletter.panel": "Deadletter",

    "c-certifications.title-registry": "ANAGRAFICA",
    "c-certifications.title-privacy": "PRIVACY",
    "c-certifications.title-end": "FINE",
    "c-certifications.title-managed": "AZIENDE CONNESSE",
    "c-certifications-managed.success":
        "Azienda certificata correttamente, passa alla certificazione delle altre aziende connesse allo studio. Torna alla",
    "c-certifications-managed.success-link": "  lista.",
    "c-certifications-managed.error": "Azienda rigettata, in attesa del caricamento di un nuovo contratto. Torna alla",
    "c-certifications.cct-contract-uploaded": "Contratti da gestire",
    "c-certifications.cct-contract-uploaded-desc": "Valida i contratti di conservazione caricati.",
    "c-certifications.cct-contract-rejected": "Contratti rigettati",
    "c-certifications.cct-contract-rejected-desc": "Visualizza tutti i contratti di conservazione rigettati.",
    "c-certifications.cct-contract-validated": "Contratti validati",
    "c-certifications.cct-contract-validated-desc": "Visualizza tutti i contratti di conservazione validati.",
    "c-certifications.cct-contract-suspended": "Contratti sospesi",
    "c-certifications.cct-contract-suspended-desc": "Visualizza tutti i contratti di conservazione sospesi.",
    "c-certifications.cct-contract-undefined": "Contratti",
    "c-certifications.cct-contract-initialized": "Contratti inizializzati",
    "c-certifications.cct-sending-to-cct": "Invio cct...",
    "c-certifications.cct-contract-initialized-desc": "Compila i contratti di conservazione inizializzati.",
    "c-certifications.cct-contract-send-error": "Errore durante l'invio in cct, riprovare.",
    "c-certifications.cct-contract-send-success": "Inviato alla cct correttamente.",
    "c-certifications.cct-contract-certify-error": "Errore durante la certificazione in cct, riprovare.",
    "c-certifications.cct-contract-certify-success": "Certifica cct correttamente.",
    "c-certifications.modalTitle": "Stato invio in cct",
    "c-certifications.modalCertify": "Dando conferma certificherai il contratto",

    "c-certifications.rejectMessage.NOT_COMPLIANT": "Modulo non conforme",
    "c-certifications.rejectMessage.NOT_COMPLIANT.format": "Formato richiesto, .pdf max 5 MB.",
    "c-certifications.rejectMessage.NOT_COMPLIANT.atto": "Caricare Atto di affidamento per la connessione TS Digital.",
    "c-certifications.rejectMessage.NOT_COMPLIANT.tsdigital":
        "Caricare il modulo di sottoscrizione alla piattaforma TS Digital.",

    "c-certifications.rejectMessage.ERROR_DATA": "Dati errati",
    "c-certifications.rejectMessage.ERROR_DATA.cf":
        "Il CF indicato non coincide con quello registrato in anagrafica. Si invita a ricaricare nuovamente il contratto con i dati corretti.",
    "c-certifications.rejectMessage.ERROR_DATA.cf-piva":
        "Il CF e la PIVA indicati non coincidono con quelli registrati in anagrafica. Si invita a ricaricare nuovamente il contratto con i dati corretti.",
    "c-certifications.rejectMessage.ERROR_DATA.signatory":
        "Inserire per il firmatario una carica valida (es. Titolare, Amministratore, Procuratore…).",
    "c-certifications.rejectMessage.ERROR_DATA.piva":
        "La PIVA indicata non coincide con quella registrata in anagrafica. Si invita a ricaricare nuovamente il contratto con i dati corretti.",

    "c-certifications.rejectMessage.NOT_COMPLETE": "Modulo incompleto",
    "c-certifications.rejectMessage.NOT_COMPLETE.place-date": 'Compilare i campi "luogo" e "data".',
    "c-certifications.rejectMessage.NOT_COMPLETE.cf":
        'Compilare il campo "CF" nella sezione "Terzo Beneficiario" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.social-name":
        'Compilare il campo "Denominazione sociale" nella sezione "Terzo Beneficiario" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.signatory-name-lastname":
        'Compilare il campo "Nome, cognome e carica del firmatario" inserendo la carica del firmatario.',
    "c-certifications.rejectMessage.NOT_COMPLETE.signatory-name-lastname2":
        'Compilare il campo "Nome, cognome e carica del firmatario".',
    "c-certifications.rejectMessage.NOT_COMPLETE.piva":
        'Compilare il campo "PIVA" nella sezione "Terzo Beneficiario" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.head-office":
        'Compilare il campo "Sede" nella sezione "Terzo Beneficiario" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.privacy":
        'Compilare la sezione relativa al trattamento dei dati personali scegliendo il flag "SI" o "NO".',
    "c-certifications.rejectMessage.NOT_COMPLETE.signatory": "Compilare tutti i campi firma.",
    "c-certifications.rejectMessage.NOT_COMPLETE.place": 'Inserire il luogo nel campo "luogo e data".',
    "c-certifications.rejectMessage.NOT_COMPLETE.date": 'Inserire la data nel campo "luogo e data".',
    "c-certifications.rejectMessage.NOT_COMPLETE.cf-green-company":
        'Compilare il campo "CF" nella sezione "Impresa Verde" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.social-name-green-company":
        'Compilare il campo "Denominazione sociale" nella sezione "Impresa Verde" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.signatory-name-lastname3":
        'Compilare in modo completo il campo "Nome, cognome e carica del firmatario".',
    "c-certifications.rejectMessage.NOT_COMPLETE.piva-green-company":
        'Compilare il campo "PIVA" nella sezione "Impresa Verde" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.head-office-green-company":
        'Compilare il campo "Sede" nella sezione  "Impresa Verde" (anche manualmente).',
    "c-certifications.rejectMessage.NOT_COMPLETE.section-company":
        "Compilare la sezione del cliente impresa/società in tutte le sue parti.",

    "c-certifications.rejectMessage.ATTACHED_2": "Allegato 2",
    "c-certifications.rejectMessage.ATTACHED_2.absent": "Modulo assente.",
    "c-certifications.rejectMessage.ATTACHED_2.upload":
        "Caricare tutte le pagine relative al Responsabile della Conservazione.",
    "c-certifications.rejectMessage.ATTACHED_2.data-error":
        "Dati del titolare non corretti o non coerenti. Prego correggere i dati del Responsabile della Conservazione.",
    "c-certifications.rejectMessage.ATTACHED_2.date": 'Inserire la data nel campo "luogo e data".',
    "c-certifications.rejectMessage.ATTACHED_2.place-date": 'Compilare il campo "luogo e data".',
    "c-certifications.rejectMessage.ATTACHED_2.place": 'Inserire il luogo nel campo "luogo e data".',
    "c-certifications.rejectMessage.ATTACHED_2.signature":
        "Inserire una firma leggibile del Responsabile della Conservazione.",

    "c-certifications.rejectMessage.OTHER": "Altro",
    "c-certifications.rejectMessage.OTHER.OTHER": "Altro",

    "c-cct-provisioning.rejectMessage.OTHER": "Altro",
    "c-cct-provisioning.rejectMessage.SIGNATURE": "Inserire la firma leggibile del Responsabile della Conservazione",
    "c-cct-provisioning.rejectMessage.UPLOAD_PAGES":
        "Caricare tutte le pagine relative al Responsabile della Conservazione",
    "c-cct-provisioning.rejectMessage.NOT_COMPLIANT": "Modulo non conforme (formato richiesto: .pdf max 5 MB )",
    "c-cct-provisioning.rejectMessage.NOT_COMPLETE":
        "Il modulo è incompleto. Manca la compilazione del campo: luogo e data.",
    "c-cct-provisioning.rejectMessage.DATE_EMPTY": "Inserire la data nel campo luogo e data.",
    "c-cct-provisioning.rejectMessage.PLACE_EMPTY": "Inserire il luogo nel campo luogo e data.",
    "c-cct-provisioning.rejectMessage.NOT_CORRECT":
        "Dati del titolare non corretti o non coerenti. Prego correggere i dati del Responsabile della Conservazione.",

    "c-item-privacy-data-form.title": "Il cliente dichiara di :",
    "c-item-privacy-data-form.notificationConsent": "(iv)",
    "c-item-privacy-data-form.thirdPartyNotificationConsent": "(v )",
    "c-item-privacy-data-form.analyticsConsent": "(vi)",

    "c-company-obt-title": "Lista adempimenti",

    "c-cctconfigservice.titolarecf-search": "Inserisci CF Titolare da cercare",
    "c-cctconfigservice.state": "Stato",
    "c-cctconfigservice.cctuser": "Utente",
    "c-cctconfigservice.active": "Attivo",
    "c-cctconfigservice.disabled": "Disattivo",
    "c-cctconfigservice.servicename": "Servizi Abilitati",
    "c-cctconfigservice.actions": "Azioni",
    "c-cctconfigservice.configurations": "Configurazioni",
    "c-cctconfigservice.enable-coupon-1000": "Abilita coupon 1000",
    "c-cctconfigservice.nullconfiguration": "Nessuna configurazione disponibile",
    "c-cctconfigservice.null1100": "Nessun coupon di aggiornamento",
    "c-cctconfigservice.1100": "Coupon di aggiornamento",
    "c-cctconfigservice.nullprovisioning": "Nessun provisioning disponibile",
    "c-cctconfigservice.id": "Id Coupon",
    "c-cctconfigservice.cfazienda": "CF Titolare",
    "c-cctconfigservice.cfmain": "CF Produttore",
    "c-cctconfigservice.titolare": "Titolare",
    "c-cctconfigservice.produttore": "Produttore",
    "c-cctconfigservice.rdc_short": "Responsabile",
    "c-cctconfigservice.rdc": "Responsabile della Conservazione",
    "c-cctconfigservice.coupon": "Coupon",
    "c-cctconfigservice.credential": "Credenziali",
    "c-cctconfigservice.connection": "Connessioni",
    "c-cctconfigservice.couponcode": "Codice coupon",
    "c-cctconfigservice.couponcodePlus": "Coupon di aggiornamento",
    "c-cctconfigservice.description": "Descrizione",
    "c-cctconfigservice.date": "Data",
    "c-cctconfigservice.statuscode": "Stato",
    "c-cctconfigservice.cctprovisioning": "CCT Provisioning",
    "c-cctconfigservice.lenderidcct": "Id CCT: ",
    "c-cctconfigservice.lendercfcompany": "Codice Fiscale: ",
    "c-cctconfigservice.lendercf": "Codice Fiscale: ",
    "c-cctconfigservice.lendername": "Nome: ",
    "c-cctconfigservice.lastname": "Cognome: ",
    "c-cctconfigservice.sesso": "Sesso: ",
    "c-cctconfigservice.lenderbirthday": "Data di nascita: ",
    "c-cctconfigservice.lenderbirthplace": "Luogo di nascita: ",
    "c-cctconfigservice.lendernationplace": "Nazione di nascita: ",
    "c-cctconfigservice.lenderpec": "PEC: ",
    "c-cctconfigservice.lenderemail": "Email: ",
    "c-cctconfigservice.loadingagencyid": "Id ente di caricamento: ",
    "c-cctconfigservice.loadingagencycf": "CF ente di caricamento: ",
    "c-cctconfigservice.exceptionDesc": "Descrizione dell'eccezione: ",
    "c-cctconfigservice.personalCredential": "Credenziali Digital Archive",
    "c-cctconfigservice.tecnicalCredential": "TS Digital Archive",
    "c-cctconfigservice.created_at": "Data creazione",
    "c-cctconfigservice.updated_at": "Data ultimo aggiornamento",
    "c-cctconfigservice.updated_by": "Utente ultimo aggiornamento",
    "c-cctconfigservice.expiring_at": "Data scadenza",
    "c-cctconfigservice.createdAt": "Creato il: ",
    "c-cctconfigservice.createdBy": "Creato da: ",
    "c-cctconfigservice.updatedAt": "Aggiornato il: ",
    "c-cctconfigservice.updatedBy": "Aggiornato da: ",
    "c-cctconfigservice.config": "Configurazione Titolare",
    "c-cctconfigservice.shared-config": "Configurazione Produttore",

    "c-templatedrawer.preview": "Anteprima",
    "c-templatedrawer.cancel": "Annulla",
    "c-templatedrawer.save": "Salva",
    "c-templatedrawer.edit": "Modifica",
    "c-templatedrawer.enterTitle": "Inserisci il titolo",
    "c-templatedrawer.title": "Titolo",
    "c-templatedrawer.enterSender": "Inserisci il mittente",
    "c-templatedrawer.from": "Da",
    "c-templatedrawer.type": "Tipo",
    "c-templatedrawer.message": "Messaggio",

    "c-templatecard.update": "Aggiornata",
    "c-templatecard.edit": "Edit",
    "c-templatecard.appCode": "Codice del APP",
    "c-templatecard.tag": "Etichetta",

    "c-sideMenu.notification": "Notifica",
    "c-sideMenu.templates": "Modelli",
    "c-sideMenu.channels": "Canali",
    "c-sideMenu.preference": "Preferenza",

    "c-company.dashboard": "Dashboard",
    "c-company.metering": "Metering",

    "c-meteringcard.active": "Attivo",
    "c-meteringcard.disable": "Disattivo",
    "c-meteringcard.numberpackage": "Numero di pacchetti: ",

    "c-meteringexpandedtable.id": "Id",
    "c-meteringexpandedtable.maximumquantity": "Quantità massima",
    "c-meteringexpandedtable.type": "Tipo",
    "c-meteringexpandedtable.limites": "Limiti",

    "c-meteringextensionsmodal.extension": "Extensione",
    "c-meteringextensionsmodal.cancel": "Annulla",
    "c-meteringextensionsmodal.companycode": "Il codice fiscale dell'azienda ",
    "c-meteringextensionsmodal.companycode-tooltip":
        "Inserisci il codice fiscale dell'azienda a cui condividere il pacchetto",
    "c-meteringextensionsmodal.companycode-error": "Per favore inserire il codice fiscale dell'azienda!",

    "c-meteringtable.state": "Stato",
    "c-meteringtable.detail.state": "Dettaglio Stato",
    "c-meteringtable.active": "Attivo",
    "c-meteringtable.disable": "Disattivo",
    "c-meteringtable.readonly": "Sola Lettura",
    "c-meteringtable.id": "Id",
    "c-meteringtable.bbsid": "Package Id",
    "c-meteringtable.autorenewal": "Rinnovo automatico",
    "c-meteringtable.yes": "Si",
    "c-meteringtable.no": "No",
    "c-meteringtable.insertiondate": "Data di inserimento",
    "c-meteringtable.updatedate": "Data di modifica",
    "c-meteringtable.expiredate": "Data di scadenza",
    "c-meteringtable.actions": "Azioni",
    "c-meteringtable.title": "Pacchetti di metering",
    "c-meteringtable.updatedby": "Aggiornato da",
    "c-meteringtable.extend": "Estendi",
    "c-meteringtable.dowloadCurrentConsumption": "Scarica file consumi",
    "c-meteringtable.dowloadArchivedConsumption": "Scarica storico consumi",
    "c-meteringpackageinfo.title": "Estensione di pacchetto  - ",
    "c-meteringpackageinfo.message":
        "Tutti i servizi a pagamento attivati tramite BO non saranno registrati su METERING.",
    "c-meteringpackageinfo.message.warning":
        "Di conseguenza tutte le attività, verranno tracciate ai fini della gestione di eventuali controversie con il cliente. ",
    "c-meteringpackageinfo.message.warning1": "Controlli preventivi consigliati:",
    "c-meteringpackageinfo.message.warning2":
        "Multiazienda: hai verificato che l'azienda su cui stai attivando il servizio abbia una relazione con quella titolare del pacchetto?",
    "c-meteringpackageinfo.message.warning3":
        "Se non è Multiazienda hai verificato la presenza di una connessione attiva?",
    "c-meteringpackageinfo.message.warning4":
        "Attivazione servizio: hai verificato se è già attivo un pacchetto nel tab metering?",
    "c-meteringpackageinfo.message.warning5":
        "Disattivazione/attivazione clienti morosi: hai verificato che il VAR abbia confermato la attivazione/disattivazione?​ Per questi casi è sempre consigliato rivolgersi al technical support.",
    "c-meteringpackageinfo.message.warning6": "Se hai dubbi non procedere!  Apri un ticket al Technical Support. ",
    "c-meteringpackagedecode.fulfillments": "Adempimenti",
    "c-meteringpackagedecode.ficagyo": "FICAGYO",
    "c-meteringpackagedecode.peopleapp": "App personale",
    "c-meteringpackagedecode.ocr": "OCR",
    "c-meteringpackagedecode.fulfillments-multi": "Adempimenti moltiplicati",
    "c-meteringpackagedecode.fulfillments-cbcr": "Adempimenti CBCR",
    "c-meteringpackagedecode.activeinvoice": "Fatture attive",
    "c-meteringpackagedecode.privacy": "Privacy",
    "c-meteringpackagedecode.expensereports": "Note spese",
    "c-meteringpackagedecode.signature-fga-doc": "Firma document FGA",
    "c-meteringpackagedecode.signature-fga": "Firma FGA",
    "c-meteringpackagedecode.signature-frq": "Firma FRQ",
    "c-meteringpackagedecode.signature-faq": "Firma FAQ",
    "c-meteringpackagedecode.signature-pro-sk": "Firma pro SK",
    "c-meteringpackagedecode.digital-signature-qual": "DG SIGN QUAL",
    "c-meteringpackagedecode.digital-signature-mass": "DG SIGN MASS",
    "c-meteringpackagedecode.digital-signature-graf": "DG SIGN GRAF",
    "c-meteringpackagedecode.digital-signature-conn": "DG SIGN CONN",
    "c-meteringpackagedecode.digital-signature-doc": "DG SIGN DOC",
    "c-meteringpackagedecode.digital-signature-prm": "DG SIGN PRM",
    "c-meteringpackagedecode.passiveinvoice": "Fatture passive",
    "c-meteringpackagedecode.activepassiveinvoice": "Fatture attive e passive",
    "c-meteringpackagedecode.empty": " ",

    "c-dashboard.certTitle": "Documenti/certificazioni",
    "c-dashboard.copyToken": "Copia token utente",
    "c-dashboard.tsDigitalSearch": "Ricerca su TS Digital",
    "c-dashboard.cervedSearch": "Ricerca su Cerved",

    "c-meteringlynfacard.active": "Attivo",
    "c-meteringlynfacard.disabled": "Disattivo",

    "c-meteringlynfaservice.item": "Articolo",
    "c-meteringlynfaservice.amount": "Importo",
    "c-meteringlynfaservice.import": "Importazioni",
    "c-meteringlynfaservice.total": "Totale: ",

    "c-companyservices.importfirstnote": "Importa Prima Nota",
    "c-companyservices.virtualservice": "Servizio virtuale",

    "c-meteringlynfatabview.importfirstnote": "Importa Prima Nota",

    "c-companyservices.all": "Tutti",
    "c-companyservices.active": "Attivi",
    "c-companyservices.readonly": "Sola lettura",
    "c-companyservices.disactive": "Disattivi",
    "c-companyservices.companyservice": "Servizio aziendale",
    "c-companyservices.emptyservice": "Nessun servizio attivo",

    "c-meteringtabview.activepackage": "Pacchetti Attivi",
    "c-meteringtabview.disactivepackage": "Pacchetti Disattivati",

    "c-companydocuments-title": "Documenti associati",
    "c-companydocuments-empty": " : nessun documento.",

    "c-invoice-trading-msg-hasAcceptedMailsFromTeamSystem":
        "Vorremmo aggiornarti su novità e offerte commerciali delle altre società del Gruppo TeamSystem, a cui verranno comunicati i tuoi dati",
    "c-invoice-trading-msg-hasAcceptedMailsFromWhite":
        "Vorremmo scrivere via e-mail o contattarti al telefono per aggiornarti su novità e offerte commerciali di Whit-e",
    "c-delete.connection.message.warning":
        "Tutte le attività, verranno tracciate ai fini della gestione di eventuali controversie con il cliente.",
    "c-delete.connection.message.warning1": "Procedura consigliata: ",
    "c-delete.connection.message.warning2":
        "Si consiglia di far cancellare la connessione in autonomia al cliente (Studio o Azienda) direttamente da TS Digital.",
    "c-delete.connection.message.warning3": "Se vuoi eliminare la connessione, contatta il Technical support ",
    "c-connection.message.modal.confirm": "Sei sicuro di voler eliminare questa connessione?",
    "c-delete.connection.message.force": "Forza eliminazione",
    "c-invoice-user-config-message":
        "Visualizzazione dei permessi degli utenti relativamente al servizio TS Digital Invoice",
    "c-invoice-user-config-message_nb":
        "(N.B. Se l'utente non è presente nella lista, non c'è nessuna configurazione/restrizione attiva)",

    "c-dbox-config.select-config": "Config disponibili:",
    "c-dbox-config.dbox-config": "Configurazione DIGITALBOX",
    "c-dbox-config.accountant": "Commercialista:",
    "c-dbox-config.name": "Ragione Sociale:",
    "c-dbox-config.owner-uuid": "Owner UUID:",
    "c-dbox-config.owner-cf": "Owner CF:",
    "c-dbox-config.item-uuid": "Item UUID:",
    "c-dbox-config.item-cf": "Item CF:",
    "c-dbox-config.package-type": "Tipo pacchetto:",
    "c-dbox-config.updated-at": "Modificato il:",
    "c-dbox-config.data-unavailable": "Dato non disponibile",
    "c-dbox-config.config-id": "Config ID:",
    "c-dbox-config.config-selection": "Id config: {configId} - Config OWNER: {configOwner}",
    "c-dbox-config.workspace-id": "Workspace Id:",

    functionality: "Permessi",
    not_allowed: "Configurazione permessi",
    package_receiver: "Ricevitore del pacchetto",
    shared_configurations: "Configurazioni condivise",
    receiver_tax_code: "Codice fiscale del destinatario: ",
    receiver_vat_number: "Partita IVA del destinatario: ",
    activated_on: "Attivato il: ",
    date_not_available: "Dato non disponibile",
    activated_by: "Attivato da:",
    modified_on: "Modificato il: ",
    modified_by: "Modificato da:",
    finished_on: "Terminato il: ",
    status: "Stato",
    active: "Attivo",
    disabled: "Disattivo",
    actions: "Azioni",
    details: "Dettagli",
    informations: "Informazioni",
    document_state: "Stato documento",
    ts_Digital: "TS Digital",
    cct_state: "CCT",
    sdi_state: "SDI",
    chronology: "Cronologia",
    chronology_cct: "Cronologia CCT",
    chronology_sdi: "Cronologia SDI",
    revenue_agency: "Agenzia delle Entrate",
    xml: "XML",
    identifier: "Identificativo",
    missing_data: "Dato non presente",
    additional_data: "Dati aggiuntivi",
    hubid: "HubId",
    sdiid: "SdiId",
    batchid: "BatchId",
    pacakgeid: "PackageId",
    state: "Stato",
    date: "Data",
    format: "Formato",
    category: "Categoria",
    file_name: "Nome file",
    reception_date: "Data ricezione",
    active_invoice: "Fattura Attiva",
    passive_invoice: "Fattura Passiva",
    recipient: "Destinatario",
    sender: "Mittente",
    transmitter: "Trasmittente",
    invoice_data: "Dati fattura",
    flow: "Flusso",
    number: "Numero",
    application: "Applicativo",
    invoice_received: "Fattura ricevuta",
    missing_date: "La data non è disponibile",
    preview: "Anteprima",
    notifications: "Avvisi",
    missing_state: "Nessun stato presente",
    attachments: "Allegati",
    missing_attachments: "Nessun allegato presente",
    messages: "Messaggi",
    download: "Scarica",
    reload_notifications: "Ricarica notifiche",
    missing_message: "Nessun messaggio presente",
    no_result: "Nessun risultato",
    no_result_user_invoices_config: "Nessun utente è stato configurato per questa azienda",
    reset_filters: "Ripristina i filtri",
    filters: "Filtri",
    enter_a_correct_sdiid: "Inserisci un corretto SdiId",
    trashed: "Cestinate",
    search: "Cerca",
    cancel: "Annulla",
    reduce: "Riduci",
    expand: "Espandi",
    usages: "Consumi",
    receive: "Ricevute",
    issued: "Emesse",
    not_assigned: "Non assegnate",
    invoices_configuration: "Configurazione utenti fatturazione",
    type: "Tipo",
    date_doc: "Data Doc.",
    sdi_ric_date: "Data ric SDI",
    sdi_sending_date: "Data invio SDI",
    show: "Visualizza",
    trash: "Cestina",
    deleteInvoice: "Elimina Fattura",
    untrash: "Ripristina",
    article_per_page: "Articolo per pagina",
    reset: "Reset",
    document_type_ID: "ID del tipo di documento",
    document_status_ID: "ID del stato di documento",
    signature_type: "Tipologia firma",
    cct: "CCT",
    hubId: "Hub Id: ",
    batchId: "Batch Id: ",
    jobId: "Job Id: ",
    current_query: "Current Query: ",
    document_detail: "Dettagli del documento",
    signatories: "Firmatari",
    not_available: "Non è disponibile",
    name: "Nome",
    document_status: "Stato di documento",
    bio_signature: "FEA grafometrica",
    secure_call: "FEA remota",
    fsm: "FEQ remota",
    fmq: "Firma massiva qualificata",
    cct_status: "Stato CCT",
    company_or_private: "Azienda/Privato",
    intermediary: "Intermediario",
    not_preserved: "Non conservato",
    in_storage: "In conservazione",
    error: "Errore",
    preserved: "Conservato",
    year: "Anno",
    mainSigner: "Firmatario Principale",
    deadline: "Scadenza",
    events: "Eventi",
    no_event: "Nessun evento",
    cct_events: "Eventi CCT",
    uploaded: "Caricato",
    to_signature: "Alla firma",
    partially_signed: "Parzialmente firmato",
    signed: "Firmato",
    in_processing: "In elaborazione",
    removed: "Eliminato",
    original_document: "Documento originale",
    signed_document: "Documento firmato",
    certified: "Certificato",
    email: "Email",
    no_signatory: "Nessun firmatario",
    all: "Tutti",

    "c-meteringtabview.emptyservice": "Nessun servizio",

    "c-user.notificationcenterservice": "Notification Center Service",
    "c-user.ncs": "NCS",

    active_services: "Servizi attivi",
    users_created: "Utenti creati",
    offices_created: "Uffici creati",
    managed_companies: "Aziende gestite",
    managers_companies: "Aziende gestori",
    company_not_validated: "Azienda non validata",
    dashboard_alarm_msg_misaligned_metering:
        "Attenzione: Dati nel metering disallineati.\n Codice Fiscale in metering: {fiscalCode}, Partita IVA in metering: {vatNumber}",
    dashboard_alarm_msg_without_user: "Attenzione: Azienda senza utente OWNER",
    dashboard_alarm_msg_not_active_package:
        "Attenzione: attivare il provisioning CCT (FICAGYO e/o FATTURE ATTIVE-PASSIVE attivi)",
    dashboard_alarm_msg_cct_validated: "Attenzione: Provisioning CCT non completato",
    no_warning_present: "Nessun avviso presente",
    enable_coupon_1004: "Abilita coupon 1004",
    reset_cct_password: "Aggiorna credenziali",
    delete_cct_rdctbf: "Rimuovi avviso Responsabile da correggere",
    no_managed_available: "Nessuna gestita disponibile",
    no_other_coupons_connected: "Nessun altro titolare collegato",
    service: "Servizio",
    type_of_package: "Tipologia pacchetto",
    read_only: "Sola lettura",
    read_and_write: "Lettura e Scrittura",
    the_managed_is_not_certified: "la gestita non è certificata",
    "c-cctconfigview.msg-couponenabled": "Coupon {coupon} abilitato con successo",
    "c-cctconfigview.msg-couponcannotenabled": "Non è possibile abilitare il coupon {coupon}",
    "c-cctconfigview.msg-pwdresetted": "Credenziali aggiornate",
    "c-cctconfigview.msg-pwdnotresetted": "Non è stato possibile aggiornare le credenziali",
    "c-cctconfigview.msg-rdctbfremoved": "Avviso Rimosso",
    "c-cctconfigview.msg-rdctbfnotremoved": "Non è stato possibile rimouvere l'avviso",
    field: "Campo",
    value: "Valore",
    operator: "Operatore",
    description: "Descrizione",
    autosend: "Autoinvio",
    privacy_status: "Stato di privacy",
    accepted: "Accettato",
    not_accepted: "Non è accettato",
    no_filter_available: "Nessun filtro disponibile",
    passive: "Passivo",
    no_manager_available: "Nessun gestore disponibile",
    managers: "Gestori",
    configurations: "Configurazioni",
    app_name: "AppName",
    channel: "Canale",
    the_document_is_not_available: "Non è disponibile il documento",
    credential: "Credenziali",
    technical: "Tecniche",
    personal: "Personali",
    waiting_list: "Lista d'attesa",
    no_service: "Nessun servizio",
    created_on: "Creato il",
    created_by: "Creato da",
    signatory_card: "Scheda Firmatario",
    sex: "Sesso",
    birth_place: "Luogo di nascita",
    contacts: "Contatti",
    telephone_number: "Numero di telefono",
    document_information: "Informazioni documento",
    document_type: "Tipologia Documento",
    released_by: "Rilasciato da",
    document_number: "Numero documento",
    expiry_date: "Data scadenza",
    receives: "Ricevuti",
    send: "Inviati",
    supplier: "Fornitore",
    customer: "Cliente",
    ordination: "Ordinazione",
    ord_ref: "Ord. Rif.",
    invoices_declined: "Fatture in rifiutate",
    invoices_in_error: "Fatture in errore",
    invoices_preserved: "Fatture conservate",
    not_send: "Non inviate",
    in_progress: "In lavorazione",
    preserve: "Conservate",
    rejected: "Rifiutate",
    in_error: "In errore",
    total: "Totale",
    conservation_2018: "Conservazione 2018",
    can_not_download_file: "Impossibile scaricare il file",
    the_file_downloaded_successfully: "Il file scaricato con successo",
    no_configuration_available: "Nessun configurazione disponibile",
    owner_service: "Owner servizio",
    disable: "Disattiva",
    new_configuration: "Nuova configurazione",
    active_configuration: "Attiva configurazione",
    disable_configuration: "Disattiva configurazione",
    confirm: "Conferma",
    owner_tax_code: "Codice fiscale owner",
    owner_vat_number: "Partita iva owner",
    marketing: "Marketing",
    policy_number: "Numero polizza",
    web_site: "Sito internet",
    jurisdiction_place: "Foro competente",
    cell_number: "Numero di cellulare",
    filled_document: "Documento compilato",
    connections: "Connessioni",
    managed: "Gestite",
    download_signed_document: "Scarica documento firmato",
    update_frm_certificate: "Aggiorna certiifcato FRM",
    download_filled_document: "Scarica documento compilato",
    cct_status_id: "Id stato di CCT",
    delegation_management: "Gestione delega",
    request_status: "Stato richiesta",
    cct_responsible: "Responsabile CCT",
    cf_responsible: "CF responsabile",
    eligible_invoice: "Incassa Subito",
    end_customer: "Cliente finale",
    op_type: "Tipo Op.",
    invoice_number: " Numero fattura",
    invoice_date: " Data fattura",
    amount: "Importo",
    down_payment_amount: "Importo acconto",
    estimated_balance: "Saldo stimato",
    lv_int: "Lv int.",
    platform_commissions: "Commissioni piattaforma",
    relationship_between_supplier_and_customer: "Relazione tra fornitore e cliente",
    acceptance_assignment: "Accettazione cessione",
    inserted_in_a_draft: "Inserita in una bozza",
    e_recipts: "Corrispettivi",
    progressive: "Progressivo",
    device_type: "Tipo dispositivo",
    device_id: "Id dispositivo",
    detection_date_time: "Data ora rilevazione",
    id: "Id",
    notes: "Note",
    download_xml: "Scarica XML",
    can_not_find_user_inserted: "Non può trovare l'utente inserito",
    user_id_inserted_is_not_valid: "Id utente inserito non valido",
    insert_data_of_user_you_want_to_create: "Inserisci i dati dell'utente che vuoi creare.",
    create_user: "Crea utente",
    create_api_key: "Crea api key",
    api_key: "API Key",
    technical_utilities_created_correctly: "Utenze tecnica creata correttamente.",
    secret: "Secret",
    roles: "Ruoli",
    warnings: "Avvisi",
    summary: "Riepilogo",
    resource: "Risorsa",
    app_code: "App code",
    notification_type: "Tipo notifica",
    channels: "Canali",
    preferences: "Preferenze",
    webhook: "Webhook",
    no_specific_channels_configured: "Nessun canale specifico configurato",
    notifications_are_sent_to: "le notifiche sono inviate a",
    no_default_channels_configured: "Nessun canale predefinito configurato",
    webhook_configuration_list: "Lista Webhook configurate",
    email_configuration_list: "Lista email configurate",
    it_is_available_but_not_active: "È disponibile ma non è attivo",
    notification_channels: "Canali di notifica",
    company_preferences: "Preferenze Aziende",
    personal_preferences: "Preferenze Personali",
    app: "Applicazione",
    syncronize_list: "Elenco sincronizzazioni",
    executed_at: "Eseguito Alle",
    scheduled_at: "Programmato Alle",
    user_consents_list: "Lista consensi di utente",
    consents: "Consensi",
    version: "Versione",
    latest_version: "Ultima versione",
    loading: "Caricamento in corso",
    edit_functionality: "Modifica funzionalità",
    edit_configuration: "Modifica configurazione",
    bank_link: "Movimenti Bancari",
    payment: "Pagamenti",
    value_date: "Data valuta",
    sia: "SIA",
    source: "Sorgente",
    transaction_date: "Data contabile",
    iban: "IBAN",
    upload_id: "Id caricamento",
    bank_link_details: "Dettaglio Movimenti Bancari",
    owner_id: "Owner Id",
    green_company: "Impresa Verde",
    next: "Successiva",
    previous: "Precedente",
    zoom_in: "Ingrandire",
    zoom_out: "Rimpicciolire",
    rotation: "Rotazione",
    smart_invoice: "Fattura smart",
    expression: "Espressione",
    consortium_id: "ID Consorzio",
    cbi_causal: "CausaleCbi",
    add: "Aggiungi",
    rules: "Regole",
    consortium: "Consorzio",
    bank: "Banca",
    save: "Salva",
    delete: "Cancella",
    edit: "Modifica",
    abi: "ABI",
    total_transaction: "Totale transazioni",
    created_at: "Creato il",
    resolved: "Risolto",
    not_resolved: "Non risolto",
    movements: "Movimenti",
    files: "File",
    sender_id: "Mittente Id",
    transmitter_id: "Trasmittente Id",
    add_rules: "Aggiungi regole",
    download_file: "Scarica file",
    show_movements: "Visualizza movimenti",
    cbi_reason: "Causale CBI",
    ts_pay_configurations: "Configurazione TS Pay",
    updated_at: "Aggiornato il",
    updated_by: "Aggiornato da",
    session_key: "Chiave sessione",
    session_url: "Url sessione",
    resend_cct: "Reinviare CCT",
    notification: "Notifiche",
    event_name: "Nome evento",
    inserted_date: "Data evento",
    recipients: "Destinatari",
    profile: "Profilo",
    report: "Reportistica",
    service_status: "Stato dei servizi",
    ih_bo: "TSIH Backoffice",
    tsih_bo: "TSIH Backoffice",
    iv_items: "Report per aziende Imprese Verdi",
    download_report_iv: "Stato Clienti Coldiretti",
    download_report_iv_description: "Report sullo stato delle aziende e connessioni",
    download_report_iv_semplified: "Coldiretti Semplificato",
    download_report_iv_description_semplified: "Report sullo stato coldiretti semplificato",
    event_description: "Descrizione esito",
    enabled: "Abilitato",
    not_enabled: "Non abilitato",
    service_status_digital: "Teamsystem Digital",
    service_status_hr: "Teamsystem HR",
    service_status_hub: "Integration Hub",
    service_status_analytics: "Teamsystem Analytics",
    service_status_ncs: "Teamsystem NCS",
    service_status_atlassian: "Atlassian",
    service_status_invoice: "TS Digital Invoice",
    service_status_digital_description: "Dettaglio dello stato dei servizi TS Digital Infrastructure",
    service_status_hr_description: "Dettaglio dello stato dei servizi TS HR",
    service_status_hub_description: "Dettaglio dello stato dei servizi TS Integration-Hub",
    service_status_analytics_description: "Dettaglio dello stato dei servizi TS Analytics",
    service_status_ncs_description: "Dettaglio dello stato dei servizi Notification Center",
    service_status_atlassian_description: "Dettaglio dello stato dei servizi Atlassian",
    service_status_invoice_description: "Monitoring dei servizi di fatturazione",
    service_status_azure: "Azure",
    service_status_azure_description: "Dettaglio dello stato dei servizi Azure",
    service_status_tsid: "TS ID",
    service_status_tsid_description: "Dettaglio dello stato del TS ID",
    services_go_back: "Torna alla lista dello stato dei servizi",
    appName: "Applicativo",
    sendSignedDocumentToMail: "Invio documento alla mail",
    signUrl: "Url per firmare documento",
    cctData: "Dati per invio in CCT",
    autoInvioCCT: "Invio automatico in CCT",
    SoggettoTitolareCodiceFiscale: "Soggetto Titolare Codice Fiscale",
    SoggettoTitolarePartitaIva: "Soggetto Titolare Partita Iva",
    SoggettoTitolareIdPaese: "Soggetto Titolare Id Paese",
    periodoImposta: "Periodo di imposta",
    periodoRiferimento: "Periodo di riferimento",
    SoggettoTitolareDenominazione: "Soggetto Titolare Denominazione",
    SoggettoTitolareNome: "Soggetto Titolare Nome",
    SoggettoTitolareCognome: "Soggetto Titolare Cognome",
    SoggettoFornitoreCodiceFiscale: "Soggetto Fornitore Codice Fiscale",
    SoggettoFornitorePartitaIva: "Soggetto Fornitore Partita Iva",
    SoggettoFornitoreIdPaese: "Soggetto Fornitore Id Paese",
    SoggettoFornitoreNome: "Soggetto Fornitore Nome",
    SoggettoFornitoreCognome: "Soggetto Fornitore Cognome",
    DataTrasmissione: "Data Trasmissione",
    DataInizio: "Data inizio",
    DataFine: "Data Fine",
    assignNsoTitle: "Funzione di assegnazione NSO",
    assignNsoDescription:
        "Clicca su ASSEGNA NSO per riprocessare tutti gli ordini che non sono stati correttamente assegnati",
    nso_riassign: "Riassegna Ordini",
    assignNso: "Assegna NSO",
    causale: "Causale",
    importo_totale: "Importo totale",
    richiesta_creata: "Richiesta creata il",
    stato_richiesta: "Stato richiesta",
    doc_signature_list: "Lista documenti Firma",
    signature_configuration: "Configurazione utenti firma",
    advanced_doc_roles: "Permessi avanzati Classi documentali",
    advanced_signer_roles: "Permessi avanzati Firmatari",
    view_all_signers: "L' utente può visualizzare tutti i firmatari",
    view_all_docTypes: "L' utente può visualizzare tutte le classi documentali",
    view_selected_docTypes: "L' utente può visualizzare solo le seguenti classi documentali:",
    view_selected_signers: "L' utente può selezionare solo i seguenti firmatari:",
    company_added_exemption_flow: "Azienda inserita il:",
    company_no_exemption_flow: "Azienda non inserita",
    exemption_flow: "Inserita nel flusso di esenzione",
    metering_extract_consumtpion_packageId: "Estrazione dei consumi per il pacchetto - ",
    metering_extract_consumtpion_range_date: "Inserire le date di inizio e fine del ciclo di fatturazione desiderato",
    metering_extract_consumtpion_ok_button: "Estrai file",
    metering_extract_consumtpion_extract_file: "Clicca su Estrai file per estrarre tutti i consumi archiviati",
    metering_credits_quantity: "Inserire la quantità di crediti da assegnare",
    metering_credits_ok_button: "Assegna crediti",
    metering_credits_packageId: "Assegna crediti al pacchetto - ",
    metering_credits_action: "Assegna crediti",
    metering_credits_range_date: "Data inizio/fine validità crediti",
    metering_package_id_detail_expired: "Scaduto",
    metering_package_id_detail_active: "Attivo",
    metering_package_id_detail_expire_date_reached: "Sola lettura per scadenza raggiunta",
    metering_package_id_detail_expire_limit_reached: "Sola lettura per limiti raggiunti",
    metering_package_id_detail_expire_administrative_block: "Blocco amministrativo",
    "hacker-monitor": "Monitor attacchi hacker",
    "c-sideMenu-hacker-monitor": "Monitor attacchi hacker",
    "digital-box-confirm-disable": "Sei sicuro di voler disattivare il servizio?",
    "digital-box-disable-title": "Disattiva il servizio",
    "c-spid.spid-list.history-popup.title": "Storico Richiesta SPID",
    "c-spid.spid-list.history-popup.subtitle": "Dettaglio",
    "c-spid.spid-single-spid.view-title": "Richiesta SPID",
    "c-spid.spid-search": "SPID",
    "c-spid.spid-list.tab-title": "Lista delle richieste SPID",
    "c-spid.spid-list.tab-usage-spid": "Utilizzo richieste SPID",
    "c-spid.spid-list.tab-usage-spid-total": "Totale a disposizione:",
    "c-spid.spid-list.tab-usage-spid-used": "Utilizzati:",
    "c-spid.spid-list.tab-usage-spid-available": "Disponibili:",
    "c-spid.spid-list.tab-usage-spid-video": "Utilizzo riconoscimenti video",
    "c-spid.spid-list.detail.spid.drawer.title": "Dettaglio richiesta spid:",
    "c-spid.spid-list.detail.spid.drawer.id": "Identificativo:",
    "c-spid.spid-list.detail.spid.drawer.itemId": "UUID:",
    "c-spid.spid-list.detail.spid.drawer.itemCf": "Codice fiscale:",
    "c-spid.spid-list.detail.spid.drawer.type": "Tipologia SPID:",
    "c-spid.spid-list.detail.spid.drawer.status": "Stato:",
    "c-spid.spid-list.detail.spid.drawer.requestedAt": "Data della richiesta:",
    "c-spid.spid-list.detail.spid.drawer.updatedAt": "Data ultima modifica:",
    "c-spid.spid-list.detail.spid.drawer.detail.user": "Dettagli utente:",
    "c-spid.spid-list.detail.spid.drawer.user.id": "Id utente:",
    "c-spid.spid-list.detail.spid.drawer.user.name": "Nome utente:",
    "c-spid.spid-list.detail.spid.drawer.user.surname": "Cognome utente:",
    "c-spid.spid-list.detail.spid.drawer.user.email": "E-mail utente:",
    "c-spid.spid-list.detail.spid.drawer.user.taxId": "Codice fiscale utente:",
    "c-spid.spid-list.detail.spid.drawer.user.ncsId": "NCS-ID utente:",
    "c-spid.spid-list.detail.spid.drawer.slotId": "Slot id:",
    "c-spid.spid-list.detail.spid.drawer.videoSlotId": "Video slot id:",
    "c-spid.spid-list.detail.spid.drawer.sessionId": "Id sessione:",
    "c-spid.spid-list.detail.spid.drawer.sessionLink": "Link della sessione:",
    "c-spid.spid-list.detail.spid.drawer.cie": "CIE:",
    "c-spid.spid-list.detail.spid.drawer.cns": "CNS:",
    "c-spid.spid-list.detail.spid.drawer.feq": "FEQ:",
    "c-spid.spid-list.detail.spid.drawer.video": "VIDEO:",
    "c-spid.spid-list.detail.spid.drawer.rao": "RAO:",
    "c-spid.spid-list.detail.spid.drawer.sessionExpirationDate": "Data scadenza:",
    "c-spid.spid-list.detail.spid.drawer.raoId": "RAO id:",
    "c-spid.spid-list.detail.spid.drawer.level": "Livello SPID:",
    "general-function-available": "Funzionalità disponibile a breve",
    "c-metering-reload-consumtpion": "Ricalcolo Consumi",
    "c-metering-popup-consumption": "Sei sicuro di voler lanciare il ricalcolo dei consumi?",
    "c-metering-action-reload-consumption": "Lancia ricalcolo consumi",
    "c-user-send-welcome-mail": "Funzione temporaneamente non disponibile, contatta il Technical Support",
    "c-user-delete-ts-id": "Scollega TS ID",
    "c-user-delete-ts-id-info": "Si possono scollegare solo gli utenti collegati prima del 23 agosto 2022",
    "c-user-delete-ts-id-modal-confirm": "Sei sicuro di voler cancellare l'associazione con il TSID ?",
    "c-user-delete-ts-id-modal-title": "Cancella associazione TSID",
    "c-archive-config-tab": "Nuova configurazione Archive",
    "c-archive-config-upload-frontend": "Caricamento da frontend: ",
    "c-archive-config-no-use-archive": "Conservazione su Archive: ",
    "c-archive-config-preservation-manager": "Responsabile Conservazione",
    "c-archive-config-provisioning-source": "Stato del provisioning",
    "c-archive-config-preservation-modal-title": "Responsabile della conservazione",
    "c-archive-config-preservation-modal-tax-id": "Codice fiscale: ",
    "c-archive-config-preservation-modal-first-name": "Nome: ",
    "c-archive-config-preservation-modal-last-name": "Cognome: ",
    "c-archive-config-preservation-modal-birth-date": "Data di nascita: ",
    "c-archive-config-preservation-modal-birth-place": "Luogo di nascita: ",
    "c-archive-config-preservation-modal-pec": "PEC: ",
    "c-archive-config-preservation-modal-tax-region": "Nazione: ",
    "c-archive-config-service-status": "Stato del servizio",
    "c-archive-config-provisionig-not-available": "Configurazione RDC non terminata",
    "c-archive-tab1-certification": "Contratto / Atto di affidamento",
    "c-archive-tab2-certification": "Allegato RC",
    "c-new-archive-modal-upload-message":
        "Sei sicuro di voler {message} la funzionalità relativa all'upload da frontend?",
    "c-new-archive-modal-deactivate": "disattivare",
    "c-new-archive-modal-activate": "attivare",
    "c-new-archive-modal-upload-message-title-activate": "Attiva upload da frontend",
    "c-new-archive-modal-upload-message-title-deactivate": "Disattiva upload da frontend",
    "c-new-archive-modal-no-use-archive-message-title-activate": "Attiva conservazione su archive",
    "c-new-archive-modal-no-use-archive-message-title-deactivate": "Disattiva conservazione su archive",
    "c-new-archive-modal-no-use-archive-message":
        "Sei sicuro di voler {message} la funzionalità relativa alla conservazione su archive?",
    "c-new-archive-modal-update-mail-message-title": "Cambia mail per comunicazioni produttore",
    "c-cctconfigservice.submitter.email": "Email per comunicazioni al produttore: ",
    "c-cctconfigservice.submitter.is_email_pec": "PEC: ",
    "c-new-archive-modal-insert-new-mail": "Inserisci la nuova mail per ricevere le comunicazioni al produttore",
    "c-new-archive-modal-insert-is-pec": "Specificare se si tratta di una PEC: ",
    "c-archive-config-provisioning-process-status": "Stato processo di configurazione RDC",
    "c-archive-config-provisioning-new-rdc-activated": "Rettifica RDC avviata",
    "c-archive-config-provisioning-no-rdc-activated": "Completato",
    "c-archive-config-rdc-activation-data": "Dati di attivazione",
    "c-archive-config-rdc-rejected": "MOTIVO DEL RIGETTO: ",
    "c-archive-config-new-rdc-modal-title": "Provisioning in corso",
    "c-archive-config-rdc-data": "Dati del Responsabile della conservazione",
    "c-archive-config-rdc-state-tooltip":
        "Legenda degli stati:<br><br>1) <b>Inizializzato</b>: Il pacchetto è stato acquistato<br><br>2) <b>In attesa di certificazione</b>: Modulo RDC caricato<br><br>3) <b>Rigettato</b> ( Modulo rigettato da operatore ) oppure <b>Completato</b> ( Configurazione correttamente completata )<br><br>4) <b>Rettifica RDC avviata</b>: Rettifica RDC in corso",
    "c-meteringtable.share-service": "Condividi servizio",
    "c-metering_share_itemId": "Inserisci l'identificativo (CF/UUID) dell'Azienda a cui vuoi estendere il servizio",
    "c-metering_share_placeholder": "Identificativo(CF/UUID) azienda",
    "c-metering_share_service": "Inserisci Il servizio che vuoi condividere",
    "c-metering_share_service_type": "Servizio",
    "c-metering_share_warning_service":
        "Sei sicuro di voler estendere il servizio? Hai verificato che l'azienda su cui stai attivando il servizio abbia una relazione con quella titolare del pacchetto?",
    "c-metering_share_warning_link": "Se hai dubbi consulta questo link",
    "c-meteringtable.refresh-package": "Sei sicuro di voler ricaricare il pacchetto?",
    "c-metering-action.refresh-package": "Refresh pacchetto",
    "c-meteringtable.disable-service": "Sei sicuro di voler disattivare il servizio?",
    "c-metering-action.disable-service": "Disattiva servizio",
    "monitoring-invoice-dashboard": "Monitoring dashboard fatturazione",
    "c-company-users-drawer.title": "Seleziona utente",
    "c-company-users.user-select": "Seleziona utente",
    "c-company-users.selected-user": "Utente selezionato: ",
    "c-company-users.receipt-list-details": "Destinatari configurati per canale email",
    "c-company-users.no-email-channels-details": "Nessun canale email configurato",
    "c-company-users.no-channels": "Nessuna configurazione",
    "v-company-users.user-preferences-tab": "Preferenze Utenti",
    "c-load-ada-instruction": "Clicca o trascina il file in questa area per caricarlo",
    "c-load-ada-send-title": "Carica Atto di Affidamento",
    "c-load-ada-send-button": "Carica File"
};
