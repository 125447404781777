import { B2B_READ_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_PAYMENT_TRANSACTIONS_START = "GET_PAYMENT_TRANSACTIONS_START";
export const GET_PAYMENT_TRANSACTIONS_SUCCESS = "GET_PAYMENT_TRANSACTIONS_SUCCESS";
export const GET_PAYMENT_TRANSACTIONS_ERROR = "GET_PAYMENT_TRANSACTIONS_ERROR";

export function getPaymentTransactions(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_PAYMENT_TRANSACTIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/paymentTransactions`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_PAYMENT_TRANSACTIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_PAYMENT_TRANSACTIONS_ERROR,
                error: e
            });
        }
    };
}

export const GET_PAYMENT_TRANSACTION_INFO_START = "GET_PAYMENT_TRANSACTION_INFO_START";
export const GET_PAYMENT_TRANSACTION_INFO_SUCCESS = "GET_PAYMENT_TRANSACTION_INFO_SUCCESS";
export const GET_PAYMENT_TRANSACTION_INFO_ERROR = "GET_PAYMENT_TRANSACTION_INFO_ERROR";

export const getPaymentTransactionInfo = (auth, hubId) => {
    return async dispatch => {
        dispatch({
            type: GET_PAYMENT_TRANSACTION_INFO_START
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/paymentTransactions/${hubId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_PAYMENT_TRANSACTION_INFO_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_PAYMENT_TRANSACTION_INFO_ERROR,
                error: e
            });
        }
    };
};

export const GET_BANK_LINK_RULES_START = "GET_BANK_LINK_RULES_START";
export const GET_BANK_LINK_RULES_SUCCESS = "GET_BANK_LINK_RULES_SUCCESS";
export const GET_BANK_LINK_RULES_ERROR = "GET_BANK_LINK_RULES_ERROR";

export function getBankLinkRules(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_BANK_LINK_RULES_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/rules`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_RULES_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_RULES_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_BANKS_START = "GET_BANK_LINK_BANKS_START";
export const GET_BANK_LINK_BANKS_SUCCESS = "GET_BANK_LINK_BANKS_SUCCESS";
export const GET_BANK_LINK_BANKS_ERROR = "GET_BANK_LINK_BANKS_ERROR";

export function getBankLinkBanks(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_BANK_LINK_BANKS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/rules/banks`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_BANKS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_BANKS_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_ASSOCIATIONS_START = "GET_BANK_LINK_ASSOCIATIONS_START";
export const GET_BANK_LINK_ASSOCIATIONS_SUCCESS = "GET_BANK_LINK_ASSOCIATIONS_SUCCESS";
export const GET_BANK_LINK_ASSOCIATIONS_ERROR = "GET_BANK_LINK_ASSOCIATIONS_ERROR";

export function getBankLinkAssociations(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_BANK_LINK_ASSOCIATIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/rules/associations`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_ASSOCIATIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_ASSOCIATIONS_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_RULE_START = "GET_BANK_LINK_RULE_START";
export const GET_BANK_LINK_RULE_SUCCESS = "GET_BANK_LINK_RULE_SUCCESS";
export const GET_BANK_LINK_RULE_ERROR = "GET_BANK_LINK_RULE_ERROR";

export function getBankLinkRule(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: GET_BANK_LINK_RULE_START,
            request: hubId
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/rules/${hubId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_RULE_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_RULE_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_ASSOCIATION_START = "GET_BANK_LINK_ASSOCIATION_START";
export const GET_BANK_LINK_ASSOCIATION_SUCCESS = "GET_BANK_LINK_ASSOCIATION_SUCCESS";
export const GET_BANK_LINK_ASSOCIATION_ERROR = "GET_BANK_LINK_ASSOCIATION_ERROR";

export function getBankLinkAssociation(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: GET_BANK_LINK_ASSOCIATION_START,
            request: hubId
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/rules/associations/${hubId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_ASSOCIATION_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_ASSOCIATION_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_BANK_START = "GET_BANK_LINK_BANK_START";
export const GET_BANK_LINK_BANK_SUCCESS = "GET_BANK_LINK_BANK_SUCCESS";
export const GET_BANK_LINK_BANK_ERROR = "GET_BANK_LINK_BANK_ERROR";

export function getBankLinkBank(auth, hubId) {
    return async dispatch => {
        dispatch({
            type: GET_BANK_LINK_BANK_START,
            request: hubId
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/rules/banks/${hubId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_BANK_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_BANK_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_FILES_START = "GET_BANK_LINK_FILES_START";
export const GET_BANK_LINK_FILES_SUCCESS = "GET_BANK_LINK_FILES_SUCCESS";
export const GET_BANK_LINK_FILES_ERROR = "GET_BANK_LINK_FILES_ERROR";

export function getBankLinkFiles(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_BANK_LINK_FILES_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/files`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_BANK_LINK_FILES_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_FILES_ERROR,
                error: e
            });
        }
    };
}

export const GET_BANK_LINK_DOWNLOAD_FILE_START = "GET_BANK_LINK_DOWNLOAD_FILE_START";
export const GET_BANK_LINK_DOWNLOAD_FILE_SUCCESS = "GET_BANK_LINK_DOWNLOAD_FILE_SUCCESS";
export const GET_BANK_LINK_DOWNLOAD_FILE_ERROR = "GET_BANK_LINK_DOWNLOAD_FILE_ERROR";

export function getBankLinkDownloadFile(auth, hubId, isXlsFile) {
    return async dispatch => {
        dispatch({
            type: GET_BANK_LINK_DOWNLOAD_FILE_START,
            request: hubId
        });
        const toast = {
            onOk: false,
            onOkText: "Operazione eseguita correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: hubId }
        };

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/files/${hubId}/download`,
                auth,
                null,
                dispatch,
                auth.refreshToken,
                toast,
                isXlsFile ? "arraybuffer" : null
            );

            dispatch({
                type: GET_BANK_LINK_DOWNLOAD_FILE_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_BANK_LINK_DOWNLOAD_FILE_ERROR,
                error: e
            });
        }
    };
}

export const GET_ADMIN_PAYMENT_TRANSACTIONS_START = "GET_ADMIN_PAYMENT_TRANSACTIONS_START";
export const GET_ADMIN_PAYMENT_TRANSACTIONS_SUCCESS = "GET_ADMIN_PAYMENT_TRANSACTIONS_SUCCESS";
export const GET_ADMIN_PAYMENT_TRANSACTIONS_ERROR = "GET_ADMIN_PAYMENT_TRANSACTIONS_ERROR";

export function getAdminPaymentTransactions(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size
        };
        dispatch({
            type: GET_ADMIN_PAYMENT_TRANSACTIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/banklink/private/paymentTransactions`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_ADMIN_PAYMENT_TRANSACTIONS_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_ADMIN_PAYMENT_TRANSACTIONS_ERROR,
                error: e
            });
        }
    };
}
