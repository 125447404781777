import {
    GET_SIGN_DOC_START,
    GET_SIGN_DOC_SUCCESS,
    GET_SIGN_DOC_ERROR,
    POST_SIGN_SEARCH_START,
    POST_SIGN_SEARCH_SUCCESS,
    POST_SIGN_SEARCH_ERROR,
    GET_SIGN_DOC_STATUSES_START,
    GET_SIGN_DOC_STATUSES_SUCCESS,
    GET_SIGN_DOC_STATUSES_ERROR,
    GET_SIGN_DOC_TYPES_START,
    GET_SIGN_DOC_TYPES_SUCCESS,
    GET_SIGN_DOC_TYPES_ERROR,
    GET_SIGN_DOC_DOWNLOAD_START,
    GET_SIGN_DOC_DOWNLOAD_SUCCESS,
    GET_SIGN_DOC_DOWNLOAD_ERROR
} from "../../../actions/signature/read";

import {
    GET_SIGNERS_START,
    GET_SIGNERS_SUCCESS,
    GET_SIGNERS_ERROR,
    GET_SIGNERS_DETAILS_START,
    GET_SIGNERS_DETAILS_SUCCESS,
    GET_SIGNERS_DETAILS_ERROR,
    DOWNLOAD_SIGNERS_START,
    DOWNLOAD_SIGNERS_SUCCESS,
    DOWNLOAD_SIGNERS_ERROR,
    GET_SIGNERS_EXTRA_DATA_START,
    GET_SIGNERS_EXTRA_DATA_SUCCESS,
    GET_SIGNERS_EXTRA_DATA_ERROR
} from "../../../actions/signers/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const document = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGN_DOC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGN_DOC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGN_DOC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const postSignSearch = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_SIGN_SEARCH_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_SIGN_SEARCH_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_SIGN_SEARCH_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getSignDocumentStatuses = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGN_DOC_STATUSES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGN_DOC_STATUSES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGN_DOC_STATUSES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getSignDocumentTypes = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGN_DOC_TYPES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGN_DOC_TYPES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGN_DOC_TYPES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getSignDocumentDownload = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGN_DOC_DOWNLOAD_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGN_DOC_DOWNLOAD_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGN_DOC_DOWNLOAD_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const signersList = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGNERS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGNERS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGNERS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getSignerDetails = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGNERS_DETAILS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGNERS_DETAILS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGNERS_DETAILS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const downloadSigner = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case DOWNLOAD_SIGNERS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DOWNLOAD_SIGNERS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DOWNLOAD_SIGNERS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export const getSignerExtraData = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_SIGNERS_EXTRA_DATA_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGNERS_EXTRA_DATA_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGNERS_EXTRA_DATA_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
