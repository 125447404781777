/**
 * Given an item retrieved from the registry (either v2 or v3) normalizes
 * its format to that of registry V2 (id becomes old id, uuid key is present)
 * @param {*} item registry read item data
 */
export function normalizeItem(item) {
    return item.item.base.id.length > 30
        ? {
              ...item,
              item: {
                  ...item.item,
                  base: { ...item.item.base, uuid: item.item.base.id, id: item.item.base.hierarchyId }
              }
          }
        : item;
}
