import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Searchbar } from "@mondora/arc/antd/Searchbar";

import { displayErrorToast } from "../../../actions/toast";

const ItemSearch = ({ history, displayErrorToast }) => {
    const onSearch = async itemSearched => {
        if (itemSearched.length >= 4) {
            history.push({
                pathname: "item",
                state: {
                    itemId: itemSearched
                }
            });
        } else {
            displayErrorToast("Il valore di ricerca deve almeno essere di 4 caratteri", true);
        }
    };

    return <Searchbar placeholder="Inserisci l'id dell'azienda" onSearch={v => onSearch(v.value.trim())} enterButton />;
};

const mapStateToProps = state => ({});
const actions = { displayErrorToast };

ItemSearch.propTypes = {
    displayErrorToast: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(ItemSearch);
