import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../../components/general/error-boundary";
import { getDocuments } from "../../../actions/contract";

import List from "../../../components/certifications/list";
import PageHeader from "../../../components/general/PageHeader/index";
import { CertificationsListContainter } from "./styled";
import { useQuery } from "../../../utils/url-utils";

import PropTypes from "prop-types";

const CertificationsListView = props => {
    const query = useQuery();

    const paused = query.get("paused") === "true";

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        itemsPerPage: 10,
        filter: {
            policyType: "",
            reasonCode: "",
            email: "",
            fiscalCode: "",
            externallyValidated: false,
            paused: paused
        }
    });

    const [pageLoading, setPageLoading] = useState(true);

    const { getDocuments, auth, list, loadingStatus, total, match, history } = props;

    useEffect(() => {
        setPageLoading(!loadingStatus.ended);
    }, [loadingStatus]);

    useEffect(() => {
        const {
            params: { status }
        } = match;
        if (canPermormRequest(pagination.filter)) {
            getDocuments(
                auth,
                status.toUpperCase().replace("-", "_"),
                {
                    pageNumber: pagination.pageNumber - 1,
                    itemsPerPage: pagination.itemsPerPage
                },
                pagination.filter
            );
        }
    }, [pagination, auth, getDocuments, match]);

    const canPermormRequest = filter => {
        const { email, fiscalCode, reasonCode } = filter;

        if (fiscalCode !== "" && fiscalCode.length < 11) {
            return false;
        }

        if (reasonCode !== "" && reasonCode.length < 5) {
            return false;
        }

        const checkMail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
        if (email !== "" && !checkMail.test(email)) {
            return false;
        }

        return true;
    };

    return (
        <CertificationsListContainter>
            <PageHeader
                history={history}
                labelId={paused ? "c-certifications-paused" : `c-certifications.${match.params.status}`}
            />
            <List
                status={match.params.status}
                key="list"
                handlePage={page => setPagination({ ...pagination, pageNumber: page })}
                handleShowSize={itemPerPage =>
                    setPagination({
                        ...pagination,
                        pageNumber: 1,
                        itemsPerPage: itemPerPage
                    })
                }
                handleFilter={(value, name) => {
                    let newFilter = pagination.filter;
                    newFilter[name] = value;
                    setPagination({ ...pagination, pageNumber: 1, filter: newFilter });
                }}
                page={pagination.pageNumber}
                loading={pageLoading}
                list={canPermormRequest(pagination.filter) ? list : []}
                total={total}
                itemPerPage={pagination.itemsPerPage}
            />
        </CertificationsListContainter>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    list: state.contract.getDocuments.documents,
    total: state.contract.getDocuments.totalItems,
    page: state.contract.getDocuments.pageNumber,
    loadingStatus: state.contract.getDocuments.status
});

const composedHoc = compose(
    connect(
        mapStateToProps,
        { getDocuments }
    ),
    errorBoundary
);

CertificationsListView.propTypes = {
    auth: PropTypes.object.isRequired,
    getDocuments: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    list: PropTypes.array,
    loadingStatus: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    total: PropTypes.number
};

export default composedHoc(CertificationsListView);
