import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { isNil } from "ramda";
import { Redirect } from "react-router-dom";
import CompanyCard from "../../../components/general/CompanyCard";
import { CompanySignature } from "../../../components/company/signature";
import {
    getSignatureUserConfig,
    getSignerPermission,
    getDocumentTypePermission
} from "../../../actions/search/signature-user-config";
import { postSignSearch, getSignDocumentStatuses, getSignDocumentTypes } from "../../../actions/signature/read";
import { errorBoundary } from "../../../components/general/error-boundary";

class CompanySignatureView extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        admin: PropTypes.object.isRequired,
        company: PropTypes.object,
        postSignSearch: PropTypes.func,
        getSignDocumentStatuses: PropTypes.func,
        getSignDocumentTypes: PropTypes.func,
        getSignatureUserConfig: PropTypes.func,
        getSignerPermission: PropTypes.func,
        getDocumentTypePermission: PropTypes.func,
        signatureSearch: PropTypes.object,
        signatureDocumentStatuses: PropTypes.object,
        signatureDocumentTypes: PropTypes.object
    };

    render() {
        const {
            auth,
            admin,
            company,
            history,
            postSignSearch,
            signatureSearch,
            getSignatureUserConfig,
            getDocumentTypePermission,
            getSignerPermission,
            getSignDocumentStatuses,
            getSignDocumentTypes,
            signatureDocumentStatuses,
            signatureDocumentTypes
        } = this.props;

        return !isNil(company.info.base) ? (
            <CompanyCard
                company={company}
                content={
                    <CompanySignature
                        auth={auth}
                        admin={admin}
                        company={company}
                        postSignSearch={postSignSearch}
                        signatureSearch={signatureSearch}
                        getSignDocumentStatuses={getSignDocumentStatuses}
                        getSignatureUserConfig={getSignatureUserConfig}
                        getSignerPermission={getSignerPermission}
                        getDocumentTypePermission={getDocumentTypePermission}
                        getSignDocumentTypes={getSignDocumentTypes}
                        signatureDocumentStatuses={signatureDocumentStatuses}
                        signatureDocumentTypes={signatureDocumentTypes}
                        history={history}
                    />
                }
                history={history}
            />
        ) : (
            <Redirect to="/item" />
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    company: state.company,
    signatureSearch: state.signatureNew.read.postSignSearch,
    signatureDocumentStatuses: state.signatureNew.read.getSignDocumentStatuses,
    signatureDocumentTypes: state.signatureNew.read.getSignDocumentTypes
});

const actions = {
    postSignSearch,
    getSignDocumentStatuses,
    getSignDocumentTypes,
    getSignatureUserConfig,
    getSignerPermission,
    getDocumentTypePermission
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanySignatureView);
