import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { restrictToPermits } from "../../../components/general/hoc";
import { errorBoundary } from "../../../components/general/error-boundary";

import CervedSearch from "../../../components/tool/cerved-search";

class CervedView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    render() {
        const { history } = this.props;
        return (
            <div>
                <span className="view-title">Ricerca su Cerved</span>
                <CervedSearch history={history} />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth
});

const actions = {};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-cerved-view")
);
export default composedHoc(CervedView);
