import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { message } from "antd";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { CompanyEReceipt } from "../../../components/company/e-receipt";
import { getEReceipts, downloadEReceipt } from "../../../actions/e-receipt/read";

const CompanyEReceiptView = ({ auth, company, history, intl, getEReceipts, downloadEReceipt }) => {
    const viewModel = useCompanyEReceiptView(auth.loginAuth, company, intl, getEReceipts, downloadEReceipt);

    return (
        <ServiceCard
            history={history}
            content={
                <CompanyEReceipt
                    loading={viewModel.loading}
                    invoices={viewModel.eReceiptList}
                    companyId={viewModel.companyId}
                    currentPage={viewModel.currentPage}
                    pageSize={viewModel.pageSize}
                    handlePageSizeChanged={viewModel.handlePageSizeChanged}
                    handleNextPage={viewModel.handleNextPage}
                    handlePreviousPage={viewModel.handlePreviousPage}
                    defaultPageSize={viewModel.defaultPageSize}
                    hasNextPage={viewModel.hasNextPage}
                    continuationToken={viewModel.continuationToken}
                    handleDownloadDocument={viewModel.handleDownloadDocument}
                    handlePreviewDocument={viewModel.handlePreviewDocument}
                    eReceiptDocument={viewModel.eReceiptDocument}
                    eReceiptDocumentLoading={viewModel.eReceiptDocumentLoading}
                />
            }
            serviceInfo={{ name: <FormattedMessage id="e_recipts" />, category: "EIT" }}
            statisticsEnable={false}
        />
    );
};

CompanyEReceiptView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getEReceipts: PropTypes.func.isRequired,
    downloadEReceipt: PropTypes.func.isRequired,
    intl: PropTypes.object
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company
});

const actions = { getEReceipts, downloadEReceipt };

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyEReceiptView);

const useCompanyEReceiptView = (auth, company, intl, getEReceipts, downloadEReceipt) => {
    let companyId = company.info.base.id;
    let defaultPageSize = 25;
    const [loading, setLoading] = useState(true);
    const [eReceiptList, setEReceiptList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [continuationToken, setContinuationToken] = useState([null]);
    const [eReceiptDocument, setEReceiptDocument] = useState(null);
    const [eReceiptDocumentLoading, setEReceiptDocumentLoading] = useState(false);

    useEffect(() => {
        const getEReceiptList = async () => {
            let filter = {
                ownerId: company.info.base.id,
                continuationToken: continuationToken[currentPage - 1]
            };
            let result = await getEReceipts(auth, filter, pageSize);
            if (
                result &&
                result._embedded &&
                result._embedded.eReceiptEntityList &&
                result._embedded.eReceiptEntityList.length
            ) {
                setHasNextPage(result.page.hasNext);
                if (continuationToken.length <= currentPage) {
                    continuationToken.push(result.page.continuationToken);
                }
                setContinuationToken(continuationToken);
                setEReceiptList(result._embedded.eReceiptEntityList);
            }
            setLoading(false);
        };

        setLoading(true);
        setEReceiptList([]);
        setHasNextPage(false);
        getEReceiptList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, company.info.base.id, currentPage, getEReceipts, pageSize]);

    const handlePageSizeChanged = pageSize => {
        if (pageSize) {
            setCurrentPage(1);
            setPageSize(parseInt(pageSize));
            setContinuationToken([null]);
        }
    };

    const handleNextPage = () => {
        if (hasNextPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDownloadDocument = async hubId => {
        let result = await downloadEReceipt(auth, hubId);
        if (result) {
            try {
                var download = document.createElement("a");
                download.setAttribute(
                    "href",
                    "data:application/octet-stream;charset=utf-16le;base64," + window.btoa(result)
                );
                download.setAttribute("download", `EReceipt_${hubId}.xml`);
                download.click();
                message.success(
                    intl.formatMessage({
                        id: "the_file_downloaded_successfully"
                    })
                );
            } catch (e) {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        } else {
            message.error(
                intl.formatMessage({
                    id: "can_not_download_file"
                })
            );
        }
    };

    const handlePreviewDocument = async hubId => {
        setEReceiptDocumentLoading(true);
        setEReceiptDocument(null);
        let result = await downloadEReceipt(auth, hubId);
        if (result) {
            setEReceiptDocument(result);
        }
        setEReceiptDocumentLoading(false);
    };

    return {
        loading,
        eReceiptList,
        handlePageSizeChanged,
        handleNextPage,
        handlePreviousPage,
        currentPage,
        pageSize,
        companyId,
        defaultPageSize,
        hasNextPage,
        continuationToken: continuationToken[currentPage - 1],
        handleDownloadDocument,
        handlePreviewDocument,
        eReceiptDocument,
        eReceiptDocumentLoading
    };
};
