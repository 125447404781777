import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Carousel } from "antd";
import { Container, ContainerTitle, ValueContainer, IconContainer } from "./styled";

export const DashboardSummary = ({ title = <FormattedMessage id="summary" />, userInfo }) => {
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        let companiesRolesValue = userInfo
            ? userInfo.roles
                ? [
                      ...new Set(
                          userInfo.roles
                              .map(role => role.resourceId)
                              .filter(r => r !== "BACKOFFICE" && r !== "REGISTERED")
                      )
                  ].length
                : [].length
            : [].length;
        let summary = [
            <div key={"1"}>
                <IconContainer className={"fas fa-briefcase"} />
                <ValueContainer>{companiesRolesValue} </ValueContainer>
                <span>Aziende collegate</span>
            </div>
        ];
        setSummary(summary);
    }, [userInfo]);

    return (
        <Container>
            <ContainerTitle>{title}</ContainerTitle>
            <Carousel autoplay>{summary}</Carousel>
        </Container>
    );
};

DashboardSummary.propTypes = {
    userInfo: PropTypes.object,
    title: PropTypes.any
};
