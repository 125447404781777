import { fieldRequired, isTaxId } from "@mondora/agyo-validations";
import combineValidators from "@mondora/agyo-validations/lib/utils/combineValidators";
import { EmailValidator } from "commons-validator-js";
import { translateValiationError } from "../translate";
import { checkTaxId } from "../check-taxId";

const prepareTaxId = values => {
    var newValues = {};
    newValues.birthDate = values.dataNascita;
    newValues.firstName = values.nome;
    newValues.birthCountry = values.nazione;
    newValues.lastName = values.cognome;
    newValues.gender = values.sesso;
    return newValues;
};

export const cedenteForm = {
    form: "cedente-data-form",
    schema: {
        type: "object",
        properties: {
            balanceSheetDate: {
                type: "string",
                validate: balanceSheetDate => fieldRequired("Campo obbligatorio")(balanceSheetDate)
            },
            nome: {
                type: "string",
                validate: nome => fieldRequired("Campo obbligatorio")(nome)
            },
            cognome: {
                type: "string",
                validate: cognome => fieldRequired("Campo obbligatorio")(cognome)
            },
            cf: {
                type: "string",
                validate: combineValidators((cf, formValues) => {
                    return checkTaxId(cf, prepareTaxId(formValues));
                }, isTaxId("taxRegion", "classifier", error => translateValiationError(error)))
            },

            luogoNascita: {
                type: "string",
                validate: luogoNascita => fieldRequired("Campo obbligatorio")(luogoNascita)
            },
            dataNascita: {
                type: "string",
                validate: dataNascita => fieldRequired("Campo obbligatorio")(dataNascita)
            },
            email: {
                type: "string",
                validate: combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    value =>
                        !new EmailValidator().isValid(value ? value : "") && "Il valore inserito non è una mail valida"
                )
            },
            nazione: { type: "string" },
            sesso: { type: "string" },
            isPec: { type: "bool" }
        }
    }
};
