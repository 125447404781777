import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.css";

import { Card, Avatar } from "antd";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

import { serviceDecode } from "../../../../utils/decode-services";

class ServiceCard extends Component {
    constructor() {
        super();
        this.state = {
            visible: false
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        content: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        serviceInfo: PropTypes.object.isRequired,
        statisticsEnable: PropTypes.bool.isRequired
    };

    render() {
        const { content, history, serviceInfo, statisticsEnable } = this.props;

        const data = [
            { name: "Gennaio", uv: 4000 },
            { name: "Febbraio", uv: 3000 },
            { name: "Marzo", uv: 2000 },
            { name: "Aprile", uv: 2780 },
            { name: "Maggio", uv: 1890 },
            { name: "Giugno", uv: 390 },
            { name: "Luglio", uv: 990 },
            { name: "Agosto", uv: 2390 },
            { name: "Settembre", uv: 1390 },
            { name: "Ottobbre", uv: 390 },
            { name: "Novembre", uv: 290 },
            { name: "Dicembre", uv: 3490 }
        ];
        return (
            <div className="c-ServiceCard">
                <Card className="content">
                    <div>
                        <i className={"far fa-times-circle close-icon"} onClick={() => history.goBack()} />
                    </div>
                    <Avatar
                        style={{
                            background: serviceDecode(serviceInfo.category).background,
                            color: serviceDecode(serviceInfo.category).color,
                            border: "1px solid" + serviceDecode(serviceInfo.category).color
                        }}
                    >
                        <i className={serviceDecode(serviceInfo.category).icon} />
                    </Avatar>{" "}
                    <span className="a1">{serviceInfo.name}</span>
                    <span className="a1">{" (" + serviceDecode(serviceInfo.category).title + ")"}</span>
                    <br />
                    <br />
                    <div style={{ height: "37vh" }}>{content}</div>
                    {statisticsEnable ? (
                        <div style={{ height: "37vh" }}>
                            <span className="a1">{"Consumi"}</span>
                            <div className="chart-no-avaibble">
                                <div> Dati presto disponibili</div>
                            </div>

                            <ResponsiveContainer>
                                <AreaChart
                                    data={data}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 0,
                                        bottom: 0
                                    }}
                                >
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area
                                        type="monotone"
                                        dataKey="uv"
                                        stroke={serviceDecode(serviceInfo.category).color}
                                        fill={serviceDecode(serviceInfo.category).color}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    ) : null}
                </Card>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company,
    services: state.services
});

const actions = {};

export default connect(
    mapStateToProps,
    actions
)(ServiceCard);
