import {
    newArchiveConfig,
    newArchiveHolderConfig,
    newArchiveSubmitterConfig,
    newArchiveDownloadRDC,
    newArchiveInfoRDC,
    newArchiveListRDC
} from "./read";
import { validateRDC, rejectRDC, frontend, submitterMail, archiveDocs } from "./write";

import { combineReducers } from "redux";

export const readNewArchive = combineReducers({
    newArchiveConfig,
    newArchiveHolderConfig,
    newArchiveSubmitterConfig,
    newArchiveDownloadRDC,
    newArchiveInfoRDC,
    newArchiveListRDC
});

export const writeNewArchive = combineReducers({
    validateRDC,
    rejectRDC,
    frontend,
    archiveDocs,
    submitterMail
});

export default combineReducers({ readNewArchive, writeNewArchive });
