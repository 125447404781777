import { SIGNATURE_READ_URL } from "../../../config";
import { getRestCall, postRestCall } from "../../../utils/rest-api-call";

export const GET_SIGN_DOC_START = "GET_SIGN_DOC_START";
export const GET_SIGN_DOC_SUCCESS = "GET_SIGN_DOC_SUCCESS";
export const GET_SIGN_DOC_ERROR = "GET_SIGN_DOC_ERROR";

export const getSignDocument = (auth, hubId) => async dispatch => {
    dispatch({
        type: GET_SIGN_DOC_START
    });

    try {
        const result = await getRestCall(
            `${SIGNATURE_READ_URL}/documents/${hubId}`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_SIGN_DOC_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGN_DOC_ERROR,
            error: e
        });
    }
};

export const POST_SIGN_SEARCH_START = "POST_SIGN_SEARCH_START";
export const POST_SIGN_SEARCH_SUCCESS = "POST_SIGN_SEARCH_SUCCESS";
export const POST_SIGN_SEARCH_ERROR = "POST_SIGN_SEARCH_ERROR";

/**
 * 
Search documents.
 *
 *
 * @name postSearch
 * @alias Search documents
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {Object} `filter` pass filters.
 * @param {Number} `page` the wanted page number (0-based).
 * @param {Number} `size` the number of items per page.
 * @param {array[string]} `sort' Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 * @return {undefined}
 */
export const postSignSearch = (auth, filter, page = 0, size = 10, sort = "creationDate,desc") => async dispatch => {
    const params = {
        page,
        size,
        sort
    };
    const body = {
        ...filter
    };

    dispatch({
        type: POST_SIGN_SEARCH_START,
        request: params
    });

    try {
        const result = await postRestCall(
            `${SIGNATURE_READ_URL}/documents/search`,
            auth,
            params,
            body,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: POST_SIGN_SEARCH_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: POST_SIGN_SEARCH_ERROR,
            error: e
        });
    }
};

export const GET_SIGN_DOC_STATUSES_START = "GET_SIGN_DOC_STATUSES_START";
export const GET_SIGN_DOC_STATUSES_SUCCESS = "GET_SIGN_DOC_STATUSES_SUCCESS";
export const GET_SIGN_DOC_STATUSES_ERROR = "GET_SIGN_DOC_STATUSES_ERROR";

/**
 * 
    Get document statuses.
 *
 *
 * @name getSignDocumentStatuses
 * @alias Get document statuses
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @return {undefined}
 */
export const getSignDocumentStatuses = auth => async dispatch => {
    dispatch({
        type: GET_SIGN_DOC_STATUSES_START
    });

    try {
        const result = await getRestCall(
            `${SIGNATURE_READ_URL}/documentStatuses`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_SIGN_DOC_STATUSES_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGN_DOC_STATUSES_ERROR,
            error: e
        });
    }
};

export const GET_SIGN_DOC_TYPES_START = "GET_SIGN_DOC_TYPES_START";
export const GET_SIGN_DOC_TYPES_SUCCESS = "GET_SIGN_DOC_TYPES_SUCCESS";
export const GET_SIGN_DOC_TYPES_ERROR = "GET_SIGN_DOC_TYPES_ERROR";

/**
 * 
    Get document types.
 *
 *
 * @name getSignDocumentTypes
 * @alias Get document types
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @return {undefined}
 */
export const getSignDocumentTypes = auth => async dispatch => {
    dispatch({
        type: GET_SIGN_DOC_TYPES_START
    });

    try {
        const result = await getRestCall(
            `${SIGNATURE_READ_URL}/documentTypes`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_SIGN_DOC_TYPES_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SIGN_DOC_TYPES_ERROR,
            error: e
        });
    }
};

export const GET_SIGN_DOC_DOWNLOAD_START = "GET_SIGN_DOC_DOWNLOAD_START";
export const GET_SIGN_DOC_DOWNLOAD_SUCCESS = "GET_SIGN_DOC_DOWNLOAD_SUCCESS";
export const GET_SIGN_DOC_DOWNLOAD_ERROR = "GET_SIGN_DOC_DOWNLOAD_ERROR";

/**
 * 
    Download base64 document.
 *
 *
 * @name getSignDocumentDownload
 * @alias Download base64 document
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `hubId` pass hubId of document.
 * @param {boolean} `signed` pass signed status that you want, Default value : false.
 * @return {undefined}
 */
export const getSignDocumentDownload = (auth, hubId, signed = false) => async dispatch => {
    const params = {
        signed
    };
    dispatch({
        type: GET_SIGN_DOC_DOWNLOAD_START
    });

    try {
        const result = await getRestCall(
            `${SIGNATURE_READ_URL}/documents/${hubId}/download`,
            auth,
            params,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_SIGN_DOC_DOWNLOAD_SUCCESS,
            payload: { base64: result }
        });
        return { base64: result };
    } catch (e) {
        dispatch({
            type: GET_SIGN_DOC_DOWNLOAD_ERROR,
            error: e
        });
    }
};
