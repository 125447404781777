/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Auth = module.exports.Auth = function(args) {
  this.id = null;
  this.securityToken = null;
  this.appName = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.securityToken !== undefined && args.securityToken !== null) {
      this.securityToken = args.securityToken;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
  }
};
Auth.prototype = {};
Auth.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.securityToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Auth.prototype.write = function(output) {
  output.writeStructBegin('Auth');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.securityToken !== null && this.securityToken !== undefined) {
    output.writeFieldBegin('securityToken', Thrift.Type.STRING, 2);
    output.writeString(this.securityToken);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 3);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ReplaceUserId = module.exports.ReplaceUserId = function(args) {
  this.oldId = null;
  this.newId = null;
  if (args) {
    if (args.oldId !== undefined && args.oldId !== null) {
      this.oldId = args.oldId;
    }
    if (args.newId !== undefined && args.newId !== null) {
      this.newId = args.newId;
    }
  }
};
ReplaceUserId.prototype = {};
ReplaceUserId.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.oldId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.newId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ReplaceUserId.prototype.write = function(output) {
  output.writeStructBegin('ReplaceUserId');
  if (this.oldId !== null && this.oldId !== undefined) {
    output.writeFieldBegin('oldId', Thrift.Type.STRING, 1);
    output.writeString(this.oldId);
    output.writeFieldEnd();
  }
  if (this.newId !== null && this.newId !== undefined) {
    output.writeFieldBegin('newId', Thrift.Type.STRING, 2);
    output.writeString(this.newId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ReplaceCompanyId = module.exports.ReplaceCompanyId = function(args) {
  this.oldId = null;
  this.newId = null;
  if (args) {
    if (args.oldId !== undefined && args.oldId !== null) {
      this.oldId = args.oldId;
    }
    if (args.newId !== undefined && args.newId !== null) {
      this.newId = args.newId;
    }
  }
};
ReplaceCompanyId.prototype = {};
ReplaceCompanyId.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.oldId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.newId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ReplaceCompanyId.prototype.write = function(output) {
  output.writeStructBegin('ReplaceCompanyId');
  if (this.oldId !== null && this.oldId !== undefined) {
    output.writeFieldBegin('oldId', Thrift.Type.STRING, 1);
    output.writeString(this.oldId);
    output.writeFieldEnd();
  }
  if (this.newId !== null && this.newId !== undefined) {
    output.writeFieldBegin('newId', Thrift.Type.STRING, 2);
    output.writeString(this.newId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "GenericError");
  this.name = "GenericError";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
  Thrift.TException.call(this, "AuthenticationException");
  this.name = "AuthenticationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthenticationException.prototype.write = function(output) {
  output.writeStructBegin('AuthenticationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryException = module.exports.RegistryException = function(args) {
  Thrift.TException.call(this, "RegistryException");
  this.name = "RegistryException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(RegistryException, Thrift.TException);
RegistryException.prototype.name = 'RegistryException';
RegistryException.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryException.prototype.write = function(output) {
  output.writeStructBegin('RegistryException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ReplaceClassifier = module.exports.ReplaceClassifier = function(args) {
  this.itemId = null;
  this.classifier = null;
  if (args) {
    if (args.itemId !== undefined && args.itemId !== null) {
      this.itemId = args.itemId;
    }
    if (args.classifier !== undefined && args.classifier !== null) {
      this.classifier = args.classifier;
    }
  }
};
ReplaceClassifier.prototype = {};
ReplaceClassifier.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.itemId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.classifier = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ReplaceClassifier.prototype.write = function(output) {
  output.writeStructBegin('ReplaceClassifier');
  if (this.itemId !== null && this.itemId !== undefined) {
    output.writeFieldBegin('itemId', Thrift.Type.STRING, 1);
    output.writeString(this.itemId);
    output.writeFieldEnd();
  }
  if (this.classifier !== null && this.classifier !== undefined) {
    output.writeFieldBegin('classifier', Thrift.Type.STRING, 2);
    output.writeString(this.classifier);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ReplaceVatNumber = module.exports.ReplaceVatNumber = function(args) {
  this.itemId = null;
  this.vatNumber = null;
  if (args) {
    if (args.itemId !== undefined && args.itemId !== null) {
      this.itemId = args.itemId;
    }
    if (args.vatNumber !== undefined && args.vatNumber !== null) {
      this.vatNumber = args.vatNumber;
    }
  }
};
ReplaceVatNumber.prototype = {};
ReplaceVatNumber.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.itemId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.vatNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ReplaceVatNumber.prototype.write = function(output) {
  output.writeStructBegin('ReplaceVatNumber');
  if (this.itemId !== null && this.itemId !== undefined) {
    output.writeFieldBegin('itemId', Thrift.Type.STRING, 1);
    output.writeString(this.itemId);
    output.writeFieldEnd();
  }
  if (this.vatNumber !== null && this.vatNumber !== undefined) {
    output.writeFieldBegin('vatNumber', Thrift.Type.STRING, 2);
    output.writeString(this.vatNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Identifier = module.exports.Identifier = function(args) {
  this.taxId = null;
  this.taxRegion = null;
  this.vatNumber = null;
  if (args) {
    if (args.taxId !== undefined && args.taxId !== null) {
      this.taxId = args.taxId;
    }
    if (args.taxRegion !== undefined && args.taxRegion !== null) {
      this.taxRegion = args.taxRegion;
    }
    if (args.vatNumber !== undefined && args.vatNumber !== null) {
      this.vatNumber = args.vatNumber;
    }
  }
};
Identifier.prototype = {};
Identifier.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.taxId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.taxRegion = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.vatNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Identifier.prototype.write = function(output) {
  output.writeStructBegin('Identifier');
  if (this.taxId !== null && this.taxId !== undefined) {
    output.writeFieldBegin('taxId', Thrift.Type.STRING, 1);
    output.writeString(this.taxId);
    output.writeFieldEnd();
  }
  if (this.taxRegion !== null && this.taxRegion !== undefined) {
    output.writeFieldBegin('taxRegion', Thrift.Type.STRING, 2);
    output.writeString(this.taxRegion);
    output.writeFieldEnd();
  }
  if (this.vatNumber !== null && this.vatNumber !== undefined) {
    output.writeFieldBegin('vatNumber', Thrift.Type.STRING, 3);
    output.writeString(this.vatNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateIdentifier = module.exports.UpdateIdentifier = function(args) {
  this.itemId = null;
  this.newIdentifier = null;
  if (args) {
    if (args.itemId !== undefined && args.itemId !== null) {
      this.itemId = args.itemId;
    }
    if (args.newIdentifier !== undefined && args.newIdentifier !== null) {
      this.newIdentifier = new ttypes.Identifier(args.newIdentifier);
    }
  }
};
UpdateIdentifier.prototype = {};
UpdateIdentifier.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.itemId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.newIdentifier = new ttypes.Identifier();
        this.newIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateIdentifier.prototype.write = function(output) {
  output.writeStructBegin('UpdateIdentifier');
  if (this.itemId !== null && this.itemId !== undefined) {
    output.writeFieldBegin('itemId', Thrift.Type.STRING, 1);
    output.writeString(this.itemId);
    output.writeFieldEnd();
  }
  if (this.newIdentifier !== null && this.newIdentifier !== undefined) {
    output.writeFieldBegin('newIdentifier', Thrift.Type.STRUCT, 2);
    this.newIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};
