import styled from "styled-components";
import { Select } from "antd";

export const InvoiceFooterContent = styled.div`
    background: #f7f7f7;
    border-radius: 5px;
    padding: 10px 10px 5px;
`;

export const InvoiceQuaryContent = styled.div`
    margin-top: -35px;
    margin-right: 260px !important;
`;

export const InvoicesReceivedTableSelect = styled(Select)`
    width: 80px;
    margin-bottom: 10px !important;
`;
