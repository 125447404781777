import styled from "styled-components";

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;

export const CardServiceWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;
export const ServiceViewBlock = styled.div`
    margin-bottom: 50px;
`;
export const Cards = styled.div`
    background: white;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 1 1 400px;
`;

export const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
