import {
    GET_SIGNER_DATA_START,
    GET_SIGNER_DATA_SUCCESS,
    GET_SIGNER_DATA_ERROR,
    DOWNLOAD_FILLED_DOCUMENT_START,
    DOWNLOAD_FILLED_DOCUMENT_SUCCESS,
    DOWNLOAD_FILLED_DOCUMENT_ERROR,
    WITHDRAW_START,
    WITHDRAW_SUCCESS,
    WITHDRAW_ERROR,
    CANCEL_START,
    CANCEL_SUCCESS,
    CANCEL_ERROR,
    UPDATE_SIGNER_START,
    UPDATE_SIGNER_SUCCESS,
    UPDATE_SIGNER_ERROR
} from "../actions/signer";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function data(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_SIGNER_DATA_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGNER_DATA_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGNER_DATA_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function documentFilled(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DOWNLOAD_FILLED_DOCUMENT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DOWNLOAD_FILLED_DOCUMENT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DOWNLOAD_FILLED_DOCUMENT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function withdraw(state = defaultState, { type, payload, error }) {
    switch (type) {
        case WITHDRAW_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case WITHDRAW_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case WITHDRAW_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function cancel(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CANCEL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CANCEL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CANCEL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function update(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_SIGNER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_SIGNER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_SIGNER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
