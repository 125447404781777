import styled from "styled-components";

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

export const InputContainer = styled.div`
    padding: 8px;
`;

export const ErrorLabelContainer = styled.div`
    color: red;
    text-align: right;
`;
