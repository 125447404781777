import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import EnableDisableService from "../../services/enable-disable";
import { Badge, Dropdown, Button, Menu, Table, Modal } from "antd";
import { CompanyDefaultSerivesExpanded } from "./default-services-expanded";
import { useFeature } from "../../../utils/hook/use-feature";

export const CompanyDefaultSerives = ({
    admin,
    auth,
    company,
    serviceId,
    loading,
    configuration,
    searchCompany,
    getServicesForCompanySeached,
    handleEnableDisableService,
    isHavePermission,
    services
}) => {
    const [showAddConfiguration, setShowAddConfiguration] = useState(false);
    const [showRemoveConfiguration, setShowRemoveConfiguration] = useState(false);
    const [showEditConfiguration, setShowEditConfiguration] = useState(false);
    const [isCompanyOwner, setIsCompanyOwner] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [extraData, setExtraData] = useState({ RTL: "false", TEM: "false" });
    const [serviceMultiOwner, setServiceMultiOwner] = useState([]);
    const meteringExtension = useFeature("Metering_Extension");
    useEffect(() => {
        let serviceMultiOwner = [];
        if (services && services.list && services.list.services && services.list.services.length) {
            serviceMultiOwner = services.list.services
                .filter(element => element.maxActiveConnections && element.maxActiveConnections > 0)
                .map(filteredElement => {
                    return filteredElement.id;
                });
        }
        setServiceMultiOwner(serviceMultiOwner);
    }, [services]);

    const columns = [
        {
            title: <FormattedMessage id="status" />,
            dataIndex: "status.active",
            render: (text, record) => (
                <span>
                    <Badge status={text ? "success" : "error"} />
                    {text ? <FormattedMessage id="active" /> : <FormattedMessage id="disabled" />}
                </span>
            )
        },
        {
            title: <FormattedMessage id="owner_service" />,
            dataIndex: "ownerId",
            key: "ownerId",
            render: (text, record) => <span>{record.ownerDesc + "(" + record.ownerTaxId + ")"}</span>
        },
        {
            title: <FormattedMessage id="actions" />,
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    <Dropdown overlay={renderAction(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];
    const renderAction = record => {
        return (
            <Menu>
                {!meteringExtension && (
                    <Menu.Item
                        key="0"
                        disabled={!isHavePermission}
                        onClick={() => {
                            setOwnerId(record.ownerId);
                            setShowRemoveConfiguration(true);
                        }}
                    >
                        <i className="fas fa-times-circle" /> <FormattedMessage id="disable" />
                    </Menu.Item>
                )}
                {serviceId === "HBLE-ESM" && (
                    <Menu.Item
                        key="1"
                        disabled={!isHavePermission}
                        onClick={() => {
                            setOwnerId(record.ownerId);
                            setShowEditConfiguration(true);
                        }}
                    >
                        <i className="fas fa-edit" /> <FormattedMessage id="edit_functionality" />
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const handleChangeExtraData = (key, value) => {
        let oldExtraData = extraData || {};
        oldExtraData[key.toUpperCase()] = value.toString();
        setExtraData(oldExtraData);
    };

    const enableDisableService = isToEnable => {
        handleEnableDisableService(isToEnable, ownerId, serviceId === "HBLE-ESM" ? extraData : null);
        setExtraData({ RTL: "false", TEM: "false" });
    };
    return (
        <div>
            <br />
            <span className="a1">{<FormattedMessage id="configurations" />}</span>
            <Table
                title={() =>
                    !meteringExtension &&
                    (serviceMultiOwner.includes(serviceId) && (
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={() => setShowAddConfiguration(true)} disabled={!isHavePermission}>
                                <FormattedMessage id="new_configuration" />
                            </Button>
                        </div>
                    ))
                }
                rowKey={(record, index) => record.itemId + record.ownerId + index}
                locale={{ emptyText: <FormattedMessage id="no_configuration_available" /> }}
                dataSource={configuration}
                columns={columns}
                size="middle"
                pagination={false}
                loading={loading}
                expandedRowRender={data => <CompanyDefaultSerivesExpanded data={data} />}
            />
            <Modal
                centered
                title={
                    showAddConfiguration ? (
                        <FormattedMessage id="active_configuration" />
                    ) : showEditConfiguration ? (
                        <FormattedMessage id="edit_configuration" />
                    ) : (
                        <FormattedMessage id="disable_configuration" />
                    )
                }
                visible={showAddConfiguration || showRemoveConfiguration || showEditConfiguration}
                onOk={() => {
                    enableDisableService(showAddConfiguration || showEditConfiguration);
                    setShowAddConfiguration(false);
                    setShowRemoveConfiguration(false);
                    setShowEditConfiguration(false);
                }}
                okText={<FormattedMessage id="confirm" />}
                cancelText={<FormattedMessage id="cancel" />}
                onCancel={() => {
                    setShowAddConfiguration(false);
                    setShowRemoveConfiguration(false);
                    setShowEditConfiguration(false);
                }}
                okButtonProps={{
                    disabled: !isCompanyOwner && !ownerId && (showAddConfiguration || showEditConfiguration)
                }}
                width={640}
            >
                <div>
                    <EnableDisableService
                        admin={admin}
                        auth={auth}
                        company={company}
                        serviceSelect={serviceId}
                        isToEnable={showAddConfiguration || showEditConfiguration}
                        searchCompany={searchCompany}
                        setCompanyOnwer={value => setIsCompanyOwner(value)}
                        setOwnerId={value => setOwnerId(value)}
                        getServicesForCompanySeached={getServicesForCompanySeached}
                        setExtraData={handleChangeExtraData}
                        services={services}
                    />
                </div>
            </Modal>
            <br />
        </div>
    );
};

CompanyDefaultSerives.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    serviceId: PropTypes.string.isRequired,
    configuration: PropTypes.array.isRequired,
    handleEnableDisableService: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    getServicesForCompanySeached: PropTypes.func.isRequired,
    isHavePermission: PropTypes.bool.isRequired,
    services: PropTypes.object.isRequired
};
