import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { userIsAuthorized } from "../../../utils/get-roles";
import {
    translateServiceCategory,
    translateServiceName,
    translateConnectionPermission,
    translateConnectionStatus,
    translateConnectionActive
} from "../../../utils/translate";
import { errorBoundary } from "../../../components/general/error-boundary";
import { CompanyConnections } from "../../../components/company/connections";
import { CompanyNotifications } from "../../../components/company/notifications";
import { getConnectionDocument } from "../../../actions/documents";
import { deleteLink } from "../../../actions/connections/write";
import { uploadADA } from "../../../actions/uploadADA";
import { getNotifications } from "../../../actions/company/notifications";
import { findOwnManagedConnections, findOwnManagerConnections } from "../../../actions/connections/read";
import { getServicesForCompanySeached } from "../../../actions/search/services";
import { searchCompany } from "../../../actions/search/company";

import { Tabs } from "antd";
import { Container } from "./styled";

const CompanyConnectionsView = ({
    admin,
    auth,
    company,
    deleteLink,
    getConnectionDocument,
    getServicesForCompanySeached,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchCompany,
    services,
    getNotifications,
    uploadADA
}) => {
    const viewModel = useCompanyConnectionsView(
        auth.loginAuth,
        admin,
        company,
        findOwnManagedConnections,
        findOwnManagerConnections,
        getNotifications,
        services,
        searchCompany,
        getServicesForCompanySeached,
        deleteLink,
        getConnectionDocument,
        uploadADA
    );

    return (
        <div>
            <Tabs tabPosition="left" onChange={viewModel.handleTabChange} activeKey={viewModel.tabSelected}>
                {viewModel.isAvailableManaged && (
                    <Tabs.TabPane tab={<FormattedMessage id="managed" />} key="MANAGED">
                        <Container>
                            <CompanyConnections
                                loading={viewModel.managedListLoading}
                                admin={admin}
                                linkType={"MANAGED"}
                                connectionList={viewModel.managedList}
                                handlePageChange={viewModel.handleManagedConnectionsPage}
                                handleFilterChange={viewModel.handleManagedConnectionsFilter}
                                connectionFilter={viewModel.managedFilter}
                                currentPage={viewModel.managedListCurrentPage}
                                totalItem={viewModel.managedListTotalItem}
                                serviceList={viewModel.serviceList}
                                companyId={viewModel.companyId}
                                handleGetCompanyInfo={viewModel.handleGetCompanyInfo}
                                handleDeleteLink={viewModel.handleManagedDeleteLink}
                                handleGetConnectionDocument={viewModel.handleGetConnectionDocument}
                                handleDownloadCSVFile={viewModel.handleManagedDownloadCSVFile}
                                pageSize={viewModel.connectionPageSize}
                                handleUploadADA={viewModel.handleUploadADA}
                            />
                        </Container>
                    </Tabs.TabPane>
                )}
                {viewModel.isAvailableManager && (
                    <Tabs.TabPane tab={<FormattedMessage id="managers" />} key="MANAGER">
                        <Container>
                            <CompanyConnections
                                loading={viewModel.managerListLoading}
                                admin={admin}
                                linkType={"MANAGER"}
                                connectionList={viewModel.managerList}
                                handlePageChange={viewModel.handleManagerConnectionsPage}
                                handleFilterChange={viewModel.handleManagerConnectionsFilter}
                                connectionFilter={viewModel.managerFilter}
                                currentPage={viewModel.managerListCurrentPage}
                                totalItem={viewModel.managerListTotalItem}
                                serviceList={viewModel.serviceList}
                                companyId={viewModel.companyId}
                                handleGetCompanyInfo={viewModel.handleGetCompanyInfo}
                                handleDeleteLink={viewModel.handleManagerDeleteLink}
                                handleGetConnectionDocument={viewModel.handleGetConnectionDocument}
                                pageSize={viewModel.connectionPageSize}
                                handleUploadADA={viewModel.handleUploadADA}
                            />
                        </Container>
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab={<FormattedMessage id="c-menu.admNotifications" />} key="notifications">
                    <Container>
                        <CompanyNotifications
                            loading={viewModel.notificationListLoading}
                            isPermissionForExpanded={viewModel.notificationExpandedPermission}
                            expanded={viewModel.notificationExpanded}
                            handleExpandedChange={viewModel.handleNotificationExpandedChange}
                            notificationList={viewModel.notificationList}
                            handlePageChange={viewModel.handleNotificationsPageChange}
                            currentPage={viewModel.notificationListCurrentPage}
                            totalItem={viewModel.notificationListTotalItem}
                            unreadNotification={viewModel.notificationListUnread}
                            pageSize={viewModel.notificationPageSize}
                        />
                    </Container>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

CompanyConnectionsView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    deleteLink: PropTypes.func.isRequired,
    getConnectionDocument: PropTypes.func.isRequired,
    getServicesForCompanySeached: PropTypes.func.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    services: PropTypes.object.isRequired,
    getNotifications: PropTypes.func.isRequired,
    uploadADA: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    services: state.services
});

const actions = {
    deleteLink,
    getConnectionDocument,
    getServicesForCompanySeached,
    findOwnManagedConnections,
    findOwnManagerConnections,
    searchCompany,
    getNotifications,
    uploadADA
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyConnectionsView);

const useCompanyConnectionsView = (
    auth,
    admin,
    company,
    findOwnManagedConnections,
    findOwnManagerConnections,
    getNotifications,
    services,
    searchCompany,
    getServicesForCompanySeached,
    deleteLink,
    getConnectionDocument,
    uploadADA
) => {
    let itemForPageConnection = 5;
    let itemForPageNotification = 10;
    let companyId = company.info.base.id;
    let companyUUID = company.info.base.uuid;
    const [firstLoading, setFirstLoading] = useState(true);
    const [tabSelected, setTabSelected] = useState("notifications");

    const [refreshManager, setRefreshManager] = useState(false);
    const [managerListLoading, setManagerListLoading] = useState(true);
    const [managerFilter, setManagerFilter] = useState(null);
    const [isAvailableManager, setIsAvailableManager] = useState(false);
    const [managerList, setManagerList] = useState([]);
    const [managerListCurrentPage, setManagerListCurrentPage] = useState(0);
    const [managerListTotalItem, setManagerListTotalItem] = useState(0);

    const [refreshManaged, setRefreshManaged] = useState(false);
    const [managedListLoading, setManagedListLoading] = useState(true);
    const [isAvailableManaged, setIsAvailableManaged] = useState(false);
    const [managedFilter, setManagedFilter] = useState(null);
    const [managedList, setManagedList] = useState([]);
    const [managedListCurrentPage, setManagedListCurrentPage] = useState(0);
    const [managedListTotalItem, setManagedListTotalItem] = useState(0);

    const [notificationListLoading, setNotificationListLoading] = useState(true);
    const [notificationExpandedPermission, setNotificationExpandedPermission] = useState(false);
    const [notificationExpanded, setNotificationExpanded] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [notificationListCurrentPage, setNotificationListCurrentPage] = useState(0);
    const [notificationListTotalItem, setNotificationListTotalItem] = useState(0);
    const [notificationListUnread, setNotificationListUnread] = useState(0);

    const prepareFilter = filter => {
        if (filter && (filter.status || filter.fullText || filter.appId || filter.certificationStatus)) {
            let filterValue = {};
            if (filter.status) {
                filterValue = { ...filter.status };
            }
            if (filter.fullText) {
                filterValue.fullText = filter.fullText;
            }
            if (filter.appId) {
                filterValue.appId = filter.appId;
            }
            if (filter.certificationStatus) {
                filterValue.certificationStatus = filter.certificationStatus;
            }
            return filterValue;
        } else {
            return null;
        }
    };

    const getManagerList = useCallback(
        async (filters, page, itemForPage) => {
            let params = { page: page, items: itemForPage };
            let result = await findOwnManagerConnections(auth, filters, params);
            let items = result && result.content ? result && result.content : [];
            let totalItems = result && result.totalElements ? result.totalElements : 0;
            return { items, totalItems };
        },
        [auth, findOwnManagerConnections]
    );

    const getManagedList = useCallback(
        async (filters, page, itemForPage) => {
            let params = { page: page, items: itemForPage };
            let result = await findOwnManagedConnections(auth, filters, params);
            let items = result && result.content ? result && result.content : [];
            let totalItems = result && result.totalElements ? result.totalElements : 0;
            return { items, totalItems };
        },
        [auth, findOwnManagedConnections]
    );

    const getNotificationList = useCallback(
        async (filters, page, itemForPage) => {
            let params = { page: page, items: itemForPage };
            let result = await getNotifications(auth, companyId, params, filters);
            let items = result && result.notifications ? result && result.notifications : [];
            let totalItems = result && result.totalItems ? result.totalItems : 0;
            let unread = result && result.unreadNotifications ? result.unreadNotifications : 0;
            return { items, totalItems, unread };
        },
        [auth, getNotifications, companyId]
    );

    useEffect(() => {
        let permission = userIsAuthorized("c-company-notifications-switch", admin.adminInfo.permits);
        setNotificationExpandedPermission(permission);
    }, [admin.adminInfo.permits]);

    useEffect(() => {
        const getInitial = async () => {
            let isAvailableManager = false;
            let filtersManager = {
                managedIds: companyUUID,
                ...prepareFilter(managerFilter)
            };
            let resultManager = await getManagerList(filtersManager, managerListCurrentPage, itemForPageConnection);
            setManagerList(resultManager.items);
            setManagerListTotalItem(resultManager.totalItems);

            if (resultManager.items.length) {
                isAvailableManager = true;
            } else {
                if (prepareFilter(managerFilter)) {
                    isAvailableManager = true;
                }
            }
            setIsAvailableManager(isAvailableManager);
            setManagerListLoading(false);
        };

        setManagerListLoading(true);
        setManagerList([]);
        setManagerListTotalItem(0);
        getInitial();
    }, [getManagerList, itemForPageConnection, companyUUID, managerFilter, managerListCurrentPage, refreshManager]);

    useEffect(() => {
        const getInitial = async () => {
            let isAvailableManaged = false;
            let filtersManaged = {
                managerIds: companyUUID,
                ...prepareFilter(managedFilter)
            };

            let resultManaged = await getManagedList(filtersManaged, managedListCurrentPage, itemForPageConnection);
            setManagedList(resultManaged.items);
            setManagedListTotalItem(resultManaged.totalItems);

            if (resultManaged.items.length) {
                isAvailableManaged = true;
            } else {
                if (prepareFilter(managedFilter)) {
                    isAvailableManaged = true;
                }
            }
            setIsAvailableManaged(isAvailableManaged);
            setManagedListLoading(false);
        };

        setManagedListLoading(true);
        setManagedList([]);
        setManagedListTotalItem(0);
        getInitial();
    }, [getManagedList, itemForPageConnection, companyUUID, managedFilter, managedListCurrentPage, refreshManaged]);

    useEffect(() => {
        const getInitial = async () => {
            let resultNotification = await getNotificationList(
                null,
                notificationListCurrentPage,
                itemForPageNotification
            );
            setNotificationList(resultNotification.items);
            setNotificationListTotalItem(resultNotification.totalItems);
            setNotificationListUnread(resultNotification.unread);
            setNotificationListLoading(false);
        };

        setNotificationListLoading(true);
        setNotificationList([]);
        setNotificationListTotalItem(0);
        setNotificationListUnread(0);
        getInitial();
    }, [getNotificationList, itemForPageNotification, notificationListCurrentPage]);

    useEffect(() => {
        if (firstLoading && !managerListLoading && !managedListLoading && !notificationListLoading) {
            setFirstLoading(false);
            if (isAvailableManaged) {
                setTabSelected("MANAGED");
            } else if (isAvailableManager) {
                setTabSelected("MANAGER");
            }
        }
    }, [
        firstLoading,
        isAvailableManaged,
        isAvailableManager,
        managedListLoading,
        managerListLoading,
        notificationListLoading
    ]);

    const handleTabChange = tab => {
        setTabSelected(tab);
    };

    const handleNotificationExpandedChange = value => {
        setNotificationExpanded(value);
    };

    const handleManagedConnectionsFilter = filters => {
        setManagedListCurrentPage(0);
        setManagedFilter(filters);
    };

    const handleManagedConnectionsPage = page => {
        setManagedListCurrentPage(page - 1);
    };

    const handleManagerConnectionsFilter = filters => {
        setManagerListCurrentPage(0);
        setManagerFilter(filters);
    };

    const handleManagerConnectionsPage = page => {
        setManagerListCurrentPage(page - 1);
    };

    const handleNotificationsPageChange = page => {
        setNotificationListCurrentPage(page - 1);
    };

    const handleGetCompanyInfo = async itemId => {
        let companyInfo = null;
        let itemInfo = await searchCompany(auth, itemId, "BASE", true);
        let itemServices = await getServicesForCompanySeached(auth, itemId);
        if (itemInfo && itemInfo.item) {
            companyInfo = { ...itemInfo.item, active: { services: itemServices } };
        }
        return companyInfo;
    };

    const handleManagedDeleteLink = async id => {
        await deleteLink(auth, id);
        setRefreshManaged(!refreshManaged);
    };

    const handleManagerDeleteLink = async id => {
        await deleteLink(auth, id);
        setRefreshManager(!refreshManager);
    };

    const handleUploadADA = async (policyId, connectionLink, content) => {
        await uploadADA(auth, policyId, connectionLink.managedDescription, connectionLink.managedId, content);
    };
    const handleGetConnectionDocument = async id => await getConnectionDocument(auth, id);

    const handleManagedDownloadCSVFile = async () => {
        let csvData = [
            ["Codice Fiscale", "Ragione Sociale", "Tipologia", "Attiva", "Categoria", "Service Id", "Permesso", "Stato"]
        ];
        let itemForPage = 1000;
        let page = 0;
        let totalItems = 0;
        const getData = async () => {
            let filtersManaged = {
                managerIds: companyUUID,
                ...prepareFilter(managedFilter)
            };
            let resultManaged = await getManagedList(filtersManaged, page, itemForPage);
            totalItems = resultManaged.totalItems;

            for (const item of resultManaged.items) {
                for (const element of item.connections) {
                    csvData.push([
                        item.managedCf,
                        item.managedDescription,
                        element ? element.approvalType : "",
                        element ? translateConnectionActive(element.status).title : "",
                        element ? translateServiceCategory(element.appId) : "",
                        element ? translateServiceName(element.serviceId) : "",
                        element ? translateConnectionPermission(element.permission) : "",
                        element ? translateConnectionStatus(element.status).title : ""
                    ]);
                }
            }

            return csvData;
        };

        while (true) {
            await getData();
            if ((page + 1) * itemForPage >= totalItems) {
                break;
            }
            page++;
        }
        return csvData;
    };

    return {
        managerListLoading,
        managedListLoading,
        notificationListLoading,
        managedList,
        managerList,
        managerListCurrentPage,
        managedListCurrentPage,
        managerListTotalItem,
        managedListTotalItem,
        handleTabChange,
        tabSelected,
        isAvailableManaged,
        isAvailableManager,
        notificationList,
        notificationExpandedPermission,
        handleNotificationExpandedChange,
        notificationListCurrentPage,
        notificationListTotalItem,
        notificationListUnread,
        handleNotificationsPageChange,
        notificationExpanded,
        managedFilter,
        handleManagedConnectionsFilter,
        handleManagedConnectionsPage,
        managerFilter,
        handleManagerConnectionsFilter,
        handleManagerConnectionsPage,
        serviceList:
            services && services.list && services.list.services && services.list.services.length
                ? services.list.services
                : [],
        companyId,
        handleGetCompanyInfo,
        handleManagedDeleteLink,
        handleManagerDeleteLink,
        handleGetConnectionDocument,
        connectionPageSize: itemForPageConnection,
        notificationPageSize: itemForPageNotification,
        handleManagedDownloadCSVFile,
        handleUploadADA
    };
};
