import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { CompanyEligibleInvoice } from "../../../components/company/eligible-invoice";
import { getEligibleInvoices } from "../../../actions/invoice/read";

const CompanyEligibleInvoiceView = ({ auth, company, history, eligibleInvoices, getEligibleInvoices }) => {
    const viewModel = useCompanyEligibleInvoiceView(auth.loginAuth, company, eligibleInvoices, getEligibleInvoices);

    return (
        <ServiceCard
            history={history}
            content={
                <CompanyEligibleInvoice
                    loading={viewModel.loading}
                    invoices={viewModel.invoices}
                    currentPage={viewModel.currentPage}
                    handleNextPage={viewModel.handleNextPage}
                    hasNextPage={viewModel.hasNextPage}
                    handlePreviousPage={viewModel.handlePreviousPage}
                    handlePageSizeChanged={viewModel.handlePageSizeChanged}
                    defaultPageSize={viewModel.defaultPageSize}
                    expanded={viewModel.expanded}
                    handleExpandedChange={viewModel.handleExpandedChange}
                    pageSize={viewModel.pageSize}
                    companyId={viewModel.companyId}
                />
            }
            serviceInfo={{ name: <FormattedMessage id="eligible_invoice" />, category: "EIT" }}
            statisticsEnable={false}
        />
    );
};

CompanyEligibleInvoiceView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getEligibleInvoices: PropTypes.func.isRequired,
    eligibleInvoices: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company,
    eligibleInvoices: state.invoice.read.getEligibleInvoices
});

const actions = { getEligibleInvoices };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyEligibleInvoiceView);

const useCompanyEligibleInvoiceView = (auth, company, eligibleInvoices, getEligibleInvoices) => {
    let companyId = company.info.base.id;
    let defaultPageSize = "25";

    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [currentPage, setCurrentPage] = useState("1");
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [filter, setFilter] = useState(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setLoading(true);
        getEligibleInvoices(auth, companyId, currentPage, pageSize).then(res => {
            if (res && res.results && res.results.length > 0) {
                setInvoices(res.results);
                setHasNextPage(res.pageInfo.hasNextPage);
            } else {
                setInvoices([]);
            }
            setLoading(false);
        });
    }, [auth, companyId, getEligibleInvoices, currentPage, pageSize]);

    const handleNextPage = () => {
        if (eligibleInvoices.pageInfo && eligibleInvoices.pageInfo.endCursor) {
            setCurrentPage(eligibleInvoices.pageInfo.endCursor);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage !== "1") {
            let newPage = currentPage - 1;
            setCurrentPage(newPage.toString());
        }
    };

    const handlePageSizeChanged = pageSize => {
        if (pageSize) {
            setCurrentPage("1");
            setPageSize(pageSize);
        }
    };

    const handleFilterChanged = filter => {
        setFilter(filter);
    };

    const handleExpandedChange = value => {
        setExpanded(value);
    };

    return {
        loading,
        invoices,
        handleNextPage,
        handlePreviousPage,
        currentPage,
        hasNextPage,
        handlePageSizeChanged,
        defaultPageSize,
        filter,
        handleFilterChanged,
        expanded,
        handleExpandedChange,
        pageSize,
        companyId
    };
};
