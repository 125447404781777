import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export const CompanyDefaultSerivesExpanded = ({ data }) => {
    return (
        <div>
            <span className="label">
                <FormattedMessage id="owner_tax_code" />:{" "}
            </span>
            {data.ownerTaxId}
            <br />
            <span className="label">
                <FormattedMessage id="owner_vat_number" />:{" "}
            </span>
            {data.ownerVatNumber}
            <br />
        </div>
    );
};

CompanyDefaultSerivesExpanded.propTypes = {
    data: PropTypes.object.isRequired
};
