import { getRestCall } from "../../utils/rest-api-call";
import { METERING_LYNFA_IMPORT_API_URL } from "../../config";

export const GET_METERING_LYNFA_IMPORT_START = "GET_METERING_LYNFA_IMPORT_START";
export const GET_METERING_LYNFA_IMPORT_SUCCESS = "GET_METERING_LYNFA_IMPORT_SUCCESS";
export const GET_METERING_LYNFA_IMPORT_ERROR = "GET_METERING_LYNFA_IMPORT_ERROR";

/**
 * Fetches the amount of imported invoice by a given item and all its managed items as well, if any.
 *
 *
 * @name getImports
 * @alias Check import
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `item` the company tax code.
 * @param {String} `itemsPerPage` the number of items per page.
 * @param {String} `page` the wanted page number (0-based).
 * @param {String} `sortBy` specifies the parameter for which to apply the sorting and the order of the sorting, in a “parameterToSortBy.sortingOrder” format (optional).
 * @return {undefined}
 */
export const getImports = (auth, item, itemsPerPage = "20", page = "0", sortBy) => async dispatch => {
    const params = {
        itemsPerPage,
        page,
        sortBy
    };
    dispatch({
        type: GET_METERING_LYNFA_IMPORT_START,
        request: params
    });

    try {
        const result = await getRestCall(
            `${METERING_LYNFA_IMPORT_API_URL}/api/v1/${item}/imports`,
            auth,
            params,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_METERING_LYNFA_IMPORT_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_METERING_LYNFA_IMPORT_ERROR,
            error: e
        });
    }
};

export const GET_METERING_LYNFA_IMPORT_TOTAL_START = "GET_METERING_LYNFA_IMPORT_TOTAL_START";
export const GET_METERING_LYNFA_IMPORT_TOTAL_SUCCESS = "GET_METERING_LYNFA_IMPORT_TOTAL_SUCCESS";
export const GET_METERING_LYNFA_IMPORT_TOTAL_ERROR = "GET_METERING_LYNFA_IMPORT_TOTAL_ERROR";

/**
 * Fetches the total amount of imported invoice by a given item.
 *
 *
 * @name getTotalImports
 * @alias Get total imports
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `item` the company tax code.
 * @return {undefined}
 */
export const getTotalImports = (auth, item) => async dispatch => {
    dispatch({
        type: GET_METERING_LYNFA_IMPORT_TOTAL_START
    });

    try {
        const result = await getRestCall(
            `${METERING_LYNFA_IMPORT_API_URL}/api/v1/${item}/imports/total`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_METERING_LYNFA_IMPORT_TOTAL_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_METERING_LYNFA_IMPORT_TOTAL_ERROR,
            error: e
        });
    }
};
