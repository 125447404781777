import { API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_TS_SPID_SLOTS_START = "GET_TS_SPID_SLOTS_START";
export const GET_TS_SPID_SLOTS_SUCCESS = "GET_TS_SPID_SLOTS_SUCCESS";
export const GET_TS_SPID_SLOTS_ERROR = "GET_TS_SPID_SLOTS_ERROR";

export const getSpidSlots = (auth, itemId) => async dispatch => {
    dispatch({
        type: GET_TS_SPID_SLOTS_START
    });

    try {
        const response = await getRestCall(`${API_URL}/usage/${itemId}/slots`, auth, null, dispatch, auth.refreshToken);

        dispatch({
            type: GET_TS_SPID_SLOTS_SUCCESS,
            payload: response
        });
        return response;
    } catch (err) {
        dispatch({ type: GET_TS_SPID_SLOTS_ERROR, error: err });
    }
};
