import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmail } from "validator";
import packageInfo from "../../../package.json";
import "./style.css";

import { Form, Input, Button, Icon, Layout, Row } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY, LOGO_COLOR } from "../../config";
import { forgotPassword } from "../../actions/auth";

class ForgotPassword extends Component {
    static propTypes = {
        forgotPassword: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            emailValue: "",
            reCaptchaIsChecked: "",
            email: {
                help: "",
                status: ""
            }
        };
    }

    onChange(event, valueToUptade) {
        this.setState({
            [valueToUptade]: event.target.value
        });
    }

    onCheckReCaptcha(value) {
        window.setTimeout(
            function() {
                this.setState({
                    reCaptchaIsChecked: value
                });
            }.bind(this),
            0
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        const { forgotPassword } = this.props;
        const { emailValue, reCaptchaIsChecked } = this.state;
        let emailErr = !isEmail(emailValue)
            ? {
                  action: this.setState({
                      email: {
                          status: "error",
                          help: "Inserisci una mail valida"
                      }
                  }),
                  status: false
              }
            : {
                  action: this.setState({ email: { status: "", help: "" } }),
                  status: true
              };
        if (emailErr.status && this.state.reCaptchaIsChecked !== "") {
            forgotPassword(emailValue, reCaptchaIsChecked);
            this.props.history.goBack();
        }
    }

    renderReCAPTCHA() {
        return (
            <div className="ResetPassword-Captcha">
                <ReCAPTCHA ref="explicit" onChange={value => this.onCheckReCaptcha(value)} sitekey={CAPTCHA_SITE_KEY} />
                <br />
            </div>
        );
    }

    render() {
        const FormItem = Form.Item;
        return (
            <div className="loginBackground">
                <div className="c-reset-password">
                    <div className="Login">
                        <div className="Login-header">
                            <img src={LOGO_COLOR} className="header-img" alt="logo" />
                        </div>
                        <Row justify="center" type="flex">
                            <div style={{ width: "80%" }}>
                                <span style={{ fontSize: "20px" }}>{"Password dimenticata?"}</span> <br />
                                <span>
                                    Inserisci la mail dell'utenza di cui non ti ricordi la password così da poterla
                                    resettare.
                                </span>{" "}
                                <br />
                                <br />
                                <Form onSubmit={e => this.handleSubmit(e)}>
                                    <FormItem validateStatus={this.state.email.status} help={this.state.email.help}>
                                        <Input
                                            addonBefore={<Icon type="user" />}
                                            placeholder="Email"
                                            onChange={e => this.onChange(e, "emailValue")}
                                        />
                                    </FormItem>

                                    {this.renderReCAPTCHA()}

                                    <FormItem>
                                        <Button onClick={() => this.props.history.goBack()} icon="arrow-left">
                                            {"Annulla"}
                                        </Button>
                                        <Button htmlType="submit" icon="arrow-right" style={{ float: "right" }}>
                                            {"Prosegui"}
                                        </Button>
                                    </FormItem>
                                </Form>
                            </div>
                        </Row>
                        <Layout.Footer className="root-footer">
                            <p>{"TS Digital BackOffice 2020 - v" + packageInfo.version}</p>
                        </Layout.Footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    { forgotPassword }
)(ForgotPassword);
