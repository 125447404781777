import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { isNil } from "ramda";
import { Select, Modal, Table, Badge, Dropdown, Button, Menu } from "antd";
import EnableDisableService from "../enable-disable";
import { translateSdiSign } from "../../../utils/translate";
import "./style.css";
import { Bar } from "../../general/usage-bar";
const Option = Select.Option;
const locale = {
    emptyText: "Nessun configurazione disponibile"
};

class SdiService extends Component {
    constructor() {
        super();
        this.state = {
            mEdit: false,
            mEnableDisable: false,
            signTypeSelected: null,
            ownerId: null,
            serviceConsumptions: 0,
            serviceCapacity: 0,
            fatturaSmart: "false"
        };
    }
    static propTypes = {
        activateService: PropTypes.func.isRequired,
        active: PropTypes.object.isRequired,
        admin: PropTypes.object,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        connections: PropTypes.object.isRequired,
        disableService: PropTypes.func.isRequired,
        getSdiConfig: PropTypes.func.isRequired,
        searchCompany: PropTypes.func.isRequired,
        searchItems: PropTypes.func.isRequired,
        findOwnManagerConnections: PropTypes.func.isRequired,
        searchSdiConfig: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        updateSdiConfig: PropTypes.func.isRequired,
        usage: PropTypes.object.isRequired,
        getServicesForCompanySeached: PropTypes.func.isRequired,
        sdiConfig: PropTypes.array.isRequired,
        sdiConfigLoaded: PropTypes.bool.isRequired,
        sdiShared: PropTypes.array.isRequired,
        sdiSharedLoaded: PropTypes.bool.isRequired,
        handleChangeSdiSharedPage: PropTypes.func.isRequired,
        sdiSharedCurrentPage: PropTypes.number.isRequired,
        sdiSharedTotalItems: PropTypes.number.isRequired,
        sdiSharedItemForPage: PropTypes.number.isRequired,
        isHavePermission: PropTypes.bool.isRequired,
        meteringExtension: PropTypes.bool
    };

    componentDidMount() {
        const { usage } = this.props;
        const subscriptions = usage.invoicingUsage.subscriptions;
        if (subscriptions && subscriptions.length > 0) {
            this.updateServiceConsumptionsAndCapacity(subscriptions);
        }
    }

    componentDidUpdate(prevProps) {
        const { usage } = this.props;
        const { usage: previousUsage } = prevProps;
        const previousSubscriptions =
            previousUsage && previousUsage.invoicingUsage && previousUsage.invoicingUsage.subscriptions;
        const subscriptions = usage && usage.invoicingUsage && usage.invoicingUsage.subscriptions;
        if (previousSubscriptions !== subscriptions && subscriptions) {
            this.updateServiceConsumptionsAndCapacity(subscriptions);
        }
    }

    updateServiceConsumptionsAndCapacity(subscriptions) {
        this.setState(
            subscriptions.reduce(
                (accumulator, { sent, capacity }) => {
                    accumulator.serviceConsumptions += sent;
                    accumulator.serviceCapacity += capacity;
                    return accumulator;
                },
                {
                    serviceConsumptions: 0,
                    serviceCapacity: 0
                }
            )
        );
    }

    handleSetOwnerId(ownerIdValue) {
        this.setState({ ownerId: ownerIdValue });
    }

    handleSetExtraData(fieldName, fieldValue) {
        this.setState({ [fieldName]: fieldValue.toString() });
    }

    handleEnableDisableService() {
        const { activateService, auth, company, disableService } = this.props;
        const { sdiConfigSelected, isToEnable, ownerId, fatturaSmart } = this.state;
        if (isToEnable) {
            activateService(
                auth.loginAuth,
                company.info.base.id,
                "SDI-FLOW",
                { fatturaSmart },
                ownerId === null ? company.info.base.id : ownerId
            );
        } else {
            disableService(auth.loginAuth, sdiConfigSelected.itemId, "SDI-FLOW", "", sdiConfigSelected.ownerId);
        }

        this.setState({ mEnableDisable: false, isToEnable: false });
    }

    handleChangeSign() {
        const { auth, updateSdiConfig } = this.props;
        const { signTypeSelected, sdiConfigSelected } = this.state;

        let config = {
            ownerId: sdiConfigSelected.ownerId,
            itemId: sdiConfigSelected.itemId,
            extraData: {
                signatureType: signTypeSelected ? signTypeSelected : sdiConfigSelected.signatureType
            }
        };
        updateSdiConfig(auth.loginAuth, config);
        this.setState({ mEdit: false });
    }

    renderAction(configValue) {
        const { activateService, auth, company, isHavePermission, meteringExtension } = this.props;

        return (
            <Menu>
                <Menu.Item
                    disabled={
                        !configValue.status.active ||
                        (company.info.base.id === configValue.ownerId &&
                            company.info.base.status.status !== "VALIDATED") ||
                        (company.info.base.id !== configValue.ownerId && !configValue.editSignIsEnable)
                    }
                    key="0"
                    onClick={() =>
                        this.setState({
                            mEdit: true,
                            sdiConfigSelected: configValue
                        })
                    }
                >
                    <i className="fas fa-edit" /> Modifica firma
                </Menu.Item>
                <Menu.Item
                    key="1"
                    disabled={!isHavePermission}
                    onClick={() =>
                        activateService(
                            auth.loginAuth,
                            configValue.itemId,
                            "SDI-FLOW",
                            { fatturaSmart: (!configValue.fatturaSmart).toString() },
                            configValue.ownerId,
                            {
                                onOkText: configValue.fatturaSmart
                                    ? "Fattura smart disattivato correttamente"
                                    : "Fattura smart attivato correttamente"
                            }
                        )
                    }
                >
                    {configValue.fatturaSmart ? (
                        <span>
                            <i className="fas fa-times-circle" /> Disabilita Fattura Smart
                        </span>
                    ) : (
                        <span>
                            <i className="fas fa-play-circle" /> Abilita Fattura Smart
                        </span>
                    )}
                </Menu.Item>
                {!meteringExtension && (
                    <Menu.Item
                        key="2"
                        disabled={!isHavePermission}
                        onClick={() =>
                            this.setState({
                                mEnableDisable: true,
                                isToEnable: false,
                                sdiConfigSelected: configValue
                            })
                        }
                    >
                        <i className="fas fa-times-circle" /> Disattiva
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    render() {
        const {
            admin,
            auth,
            company,
            getSdiConfig,
            searchCompany,
            searchSdiConfig,
            getServicesForCompanySeached,
            sdiConfig,
            sdiConfigLoaded,
            sdiShared,
            sdiSharedLoaded,
            handleChangeSdiSharedPage,
            sdiSharedCurrentPage,
            sdiSharedTotalItems,
            sdiSharedItemForPage,
            isHavePermission,
            services,
            meteringExtension
        } = this.props;
        const { mEdit, mEnableDisable, sdiConfigSelected, isToEnable, isCompanyOwner, ownerId } = this.state;
        const columns = [
            {
                title: "Stato",
                dataIndex: "status.active",
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? "Attivo" : "Disattivo"}
                    </span>
                )
            },
            {
                title: "Owner pacchetto",
                dataIndex: "ownerId",
                key: "ownerId",
                render: (text, record) => <span>{record.ownerDesc + "(" + text + ")"}</span>
            },
            {
                title: "Tipo firma",
                dataIndex: "signatureType",
                key: "signatureType",
                render: text => translateSdiSign(text)
            },
            {
                title: <FormattedMessage id="type_of_package" />,
                dataIndex: "readonly",
                key: "readonly",
                render: text => (
                    <span>
                        <Badge status={text ? "error" : "success"} />
                        {text ? <FormattedMessage id="read_only" /> : <FormattedMessage id="read_and_write" />}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="smart_invoice" />,
                dataIndex: "fatturaSmart",
                key: "fatturaSmart",
                render: text => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? <FormattedMessage id="general.yes" /> : <FormattedMessage id="general.no" />}
                    </span>
                )
            },
            {
                title: "Azioni",
                render: (text, record) => (
                    <div style={{ textAlign: "center" }}>
                        <Dropdown overlay={this.renderAction(record)} trigger={["click"]} placement="bottomRight">
                            <Button shape="circle" renderActionsize="small">
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </div>
                )
            }
        ];
        const columnsShared = [
            {
                title: <FormattedMessage id="status" />,
                dataIndex: "status.active",
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? <FormattedMessage id="active" /> : <FormattedMessage id="disabled" />}
                    </span>
                )
            },
            {
                title: <FormattedMessage id="package_receiver" />,
                dataIndex: "itemId",
                key: "itemId",
                render: (text, record) => <span>{record.itemDesc + "(" + text + ")"}</span>
            },
            {
                title: <FormattedMessage id="signature_type" />,
                dataIndex: "signatureType",
                key: "signatureType",
                render: text => translateSdiSign(text)
            },
            {
                title: <FormattedMessage id="actions" />,
                render: (text, record) => (
                    <div style={{ textAlign: "center" }}>
                        <Button shape="circle" size="small" disabled={true}>
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </div>
                )
            }
        ];
        const { serviceConsumptions, serviceCapacity } = this.state;
        return (
            <div className="service-sdi">
                <span className="a1">Consumi</span>
                <br />
                {serviceConsumptions && serviceCapacity ? (
                    <Bar
                        capacity={serviceCapacity}
                        totalConsumptions={serviceConsumptions}
                        activeConsumptions={serviceConsumptions}
                        supportsActiveInvoicing
                        showPackageType={false}
                    />
                ) : (
                    <span>Nessun dato disponibile.</span>
                )}
                <br />
                <br />
                <span className="a1">{"Configurazioni"}</span>
                <Table
                    title={() =>
                        !meteringExtension && (
                            <div className="new-config-button">
                                <Button
                                    disabled={!isHavePermission}
                                    onClick={() =>
                                        this.setState({
                                            mEnableDisable: true,
                                            isToEnable: true
                                        })
                                    }
                                >
                                    Nuova configurazione
                                </Button>
                            </div>
                        )
                    }
                    rowKey={(record, index) => record.itemId + record.ownerId + index}
                    locale={locale}
                    dataSource={sdiConfig}
                    columns={columns}
                    size="middle"
                    pagination={false}
                    loading={sdiConfigLoaded}
                    expandedRowRender={config => (
                        <div>
                            <span className="label">Codice fiscale owner: </span>
                            {config.ownerTaxId}
                            <br />
                            <span className="label">Partita iva owner: </span>
                            {config.ownerVatNumber}
                            <br />
                            <span className="label">Attivato il: </span>
                            {config.status.startDate
                                ? new Date(Number(config.status.startDate)).toLocaleString()
                                : "Dato non disponibile"}
                            <br />
                            <span className="label">Attivato da:</span>{" "}
                            {config.status.createdBy ? config.status.createdBy : "Dato non disponibile"}
                            <br />
                            <span className="label">Modificato il: </span>
                            {config.status.modifiedAt
                                ? new Date(Number(config.status.modifiedAt)).toLocaleString()
                                : "Dato non disponibile"}
                            <br />
                            <span className="label">Modificato da:</span>{" "}
                            {config.status.modifiedBy ? config.status.modifiedBy : "Dato non disponibile"} <br />
                            <span className="label">Terminato il: </span>
                            {config.status.endDate
                                ? new Date(Number(config.status.endDate)).toLocaleString()
                                : "Dato non disponibile"}
                            <br />
                        </div>
                    )}
                />
                <br />
                <br />
                <span className="a1">
                    <FormattedMessage id="shared_configurations" />
                </span>
                <Table
                    rowKey={record => record.itemId + record.ownerId}
                    locale={locale}
                    dataSource={sdiShared}
                    columns={columnsShared}
                    size="small"
                    pagination={{
                        current: sdiSharedCurrentPage + 1,
                        total: sdiSharedTotalItems,
                        hideOnSinglePage: false,
                        pageSize: sdiSharedItemForPage,
                        size: "small",
                        position: "top"
                    }}
                    onChange={pagination => handleChangeSdiSharedPage(pagination.current - 1)}
                    loading={sdiSharedLoaded}
                    expandedRowRender={config => (
                        <div>
                            <span className="label">
                                {" "}
                                <FormattedMessage id="receiver_tax_code" />
                            </span>
                            {config.itemTaxId}
                            <br />
                            <span className="label">
                                <FormattedMessage id="receiver_vat_number" />
                            </span>
                            {config.itemVatNumber}
                            <br />
                            <span className="label">
                                <FormattedMessage id="activated_on" />
                            </span>
                            {config.status.startDate ? (
                                new Date(Number(config.status.startDate)).toLocaleString()
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                            <span className="label">
                                <FormattedMessage id="activated_by" />
                            </span>{" "}
                            {config.status.createdBy ? (
                                config.status.createdBy
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                            <span className="label">
                                <FormattedMessage id="modified_on" />
                            </span>
                            {config.status.modifiedAt ? (
                                new Date(Number(config.status.modifiedAt)).toLocaleString()
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                            <span className="label">
                                <FormattedMessage id="modified_by" />
                            </span>{" "}
                            {config.status.modifiedBy ? (
                                config.status.modifiedBy
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}{" "}
                            <br />
                            <span className="label">
                                <FormattedMessage id="finished_on" />
                            </span>
                            {config.status.endDate ? (
                                new Date(Number(config.status.endDate)).toLocaleString()
                            ) : (
                                <FormattedMessage id="date_not_available" />
                            )}
                            <br />
                        </div>
                    )}
                />
                <br />
                {!isNil(sdiConfigSelected) && (
                    <Modal
                        centered
                        title="Modifica tipologia di firma"
                        visible={mEdit}
                        onOk={() => this.handleChangeSign()}
                        okText="Conferma"
                        cancelText="Annulla"
                        onCancel={() =>
                            this.setState({
                                mEdit: false,
                                signTypeSelected: null
                            })
                        }
                    >
                        <div>
                            Seleziona il tipo di firma che vuoi applicare per il servizio Sdi.
                            <Select
                                defaultValue={sdiConfigSelected.signatureType}
                                onChange={signType =>
                                    this.setState({
                                        signTypeSelected: signType
                                    })
                                }
                            >
                                <Option value="NO_SIGN">Nessuna firma</Option>
                                <Option value="TEAMSYSTEM">Firma TeamSystem</Option>
                            </Select>
                        </div>
                    </Modal>
                )}
                <Modal
                    centered
                    title={isToEnable ? "Attiva configurazione" : "Disattiva configurazione"}
                    visible={mEnableDisable}
                    onOk={() => this.handleEnableDisableService()}
                    okText="Conferma"
                    cancelText="Annulla"
                    onCancel={() =>
                        this.setState({
                            mEnableDisable: false
                        })
                    }
                    okButtonProps={{
                        disabled: !isCompanyOwner && !ownerId && isToEnable
                    }}
                    width={640}
                >
                    <div>
                        <EnableDisableService
                            admin={admin}
                            auth={auth}
                            company={company}
                            serviceSelect={"SDI-FLOW"}
                            isToEnable={isToEnable}
                            getSdiConfig={getSdiConfig}
                            setCompanyOnwer={value => this.setState({ isCompanyOwner: value })}
                            searchCompany={searchCompany}
                            setOwnerId={value => this.handleSetOwnerId(value)}
                            setExtraData={(name, value) => this.handleSetExtraData(name, value)}
                            searchSdiConfig={searchSdiConfig}
                            getServicesForCompanySeached={getServicesForCompanySeached}
                            services={services}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default SdiService;
