export function displaySuccessToast(msg, displaySuccess, infoReq) {
    return dispatch => displayToast(dispatch, "success", msg, displaySuccess, infoReq);
}

export function displayErrorToast(msg, displayError) {
    return dispatch => displayToast(dispatch, "error", msg, displayError);
}

export const SHOW_TOAST = "SHOW_TOAST";

function displayToast(dispatch, type, msg, display, infoReq) {
    let errorMsg = typeof msg === "string" ? msg : msg.message;

    if (display) {
        dispatch({
            type: SHOW_TOAST,
            payload: {
                type,
                message: errorMsg,
                show: display,
                infoReq: infoReq
            }
        });
    }
}

export const HIDE_TOAST = "HIDE_TOAST";

export function hideToast() {
    return dispatch => {
        dispatch({
            type: HIDE_TOAST
        });
    };
}
