import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { isNil } from "ramda";
import { submit } from "redux-form";

import { Redirect } from "react-router-dom";
import { Divider, Button } from "antd";
import { FormattedMessage } from "react-intl";
import ItemPrivacyDataForm from "../../../components/certifications/forms/privacy";
import CompanyCard from "../../../components/general/CompanyCard";
import CompanyEditForm from "../../../components/company/form/edit";
import TaxIdForm from "../../../components/adjustments/tax-id";
import VatNumberForm from "../../../components/adjustments/vat-number";
import ClassifierForm from "../../../components/adjustments/classifier";
import { LogoForm } from "../../../components/adjustments/logo";

import { getCompany } from "../../../actions/company/info";
import { updateItem } from "../../../actions/item";
import { userIsAuthorized } from "../../../utils/get-roles";
import { errorBoundary } from "../../../components/general/error-boundary";
import { decodeItemSchema } from "../../../utils/create-item-schema";
import { replaceClassifier, replaceTaxId, replaceVatNumber, replaceIdentifier } from "../../../actions/corrections";
import { replaceLogo } from "../../../actions/registry/write";
import { getCctProvisioningRequest } from "../../../actions/cct/read";
import { getPrivacyData, setPrivacyData } from "../../../actions/certification";

const CompanyEditView = ({
    admin: {
        adminInfo: { permits }
    },
    auth,
    company,
    history,
    corrections,
    cct,
    getCctProvisioningRequest,
    getPrivacyData,
    replaceClassifier,
    replaceIdentifier,
    setPrivacyData,
    replaceLogo,
    submitForm,
    updateItem,
    getCompany
}) => {
    const [itemPrivacy, setItemPrivacy] = useState();

    useEffect(() => {
        const asyncGetPrivacy = async () => {
            const dataPrivacy = await getPrivacyData(auth.loginAuth, company.info.base.id);
            if (dataPrivacy) {
                setItemPrivacy(dataPrivacy);
            }
        };
        asyncGetPrivacy();
        getCctProvisioningRequest(auth.loginAuth, {
            "cf-titolari": company.info.base.identifier.taxId,
            statusCode: "validated"
        });
    }, [
        auth.loginAuth,
        company.info.base.id,
        company.info.base.identifier.taxId,
        getPrivacyData,
        getCctProvisioningRequest
    ]);

    const editCompany = companyData => {
        let dataToUpdate;
        if (!isNil(company)) {
            let address = {
                types: ["REGISTERED_OFFICE"],
                country: companyData.country,
                province: companyData.province,
                city: companyData.city,
                zipCode: companyData.zipCode,
                streetName: companyData.streetName,
                streetNumber: companyData.streetNumber
            };

            dataToUpdate = {
                base: {
                    ...company.info.base,
                    details: {
                        ...company.info.base.details,
                        description: companyData.description,
                        addresses: [address],
                        economics: {
                            ...company.info.base.details.economics,
                            taxRegime: companyData.taxRegime,
                            cciaa: companyData.cciaa,
                            rea: companyData.rea,
                            liquidationState: companyData.liquidationState,
                            capitalStock: companyData.capitalStock,
                            soleShareholder: companyData.soleShareholder,
                            balanceSheetDate:
                                moment(companyData.balanceSheetDate, "YYYY-MM-DD").isValid() &&
                                companyData.balanceSheetDate !== 0
                                    ? moment.utc(companyData.balanceSheetDate, "YYYY-MM-DD").valueOf()
                                    : ""
                        },
                        professionalRegister: {
                            ...company.info.base.details.professionalRegister,
                            description: companyData.registerDescription,
                            code: companyData.registerCode,
                            province: companyData.registerProvince,
                            registrationDate: moment(companyData.registerRegistrationDate, "YYYY-MM-DD").isValid()
                                ? moment.utc(companyData.registerRegistrationDate, "YYYY-MM-DD").valueOf()
                                : companyData.registerRegistrationDate
                        }
                    }
                },
                preferences: { hidden: companyData.privacyFlag }
            };
        }
        updateItem(auth.loginAuth, dataToUpdate);
    };

    const replaceTaxId = val => {
        val.cfOld = company.info.base.identifier.taxId;
        val.pivaOld = company.info.base.identifier.vatNumber;
        replaceIdentifier(auth.loginAuth, val.cfOld, val.newTaxId, val.pivaOld, val.taxRegion);
    };

    const replaceVatNumber = val => {
        val.cfOld = company.info.base.identifier.taxId;
        val.itemId = company.info.base.identifier.taxId;
        replaceIdentifier(auth.loginAuth, val.itemId, val.cfOld, val.newVatNumber, val.taxRegion);
    };

    const replaceItemClassifier = val => {
        val.itemId = company.info.base.identifier.taxId;
        replaceClassifier(auth.loginAuth, val.itemId, val.classifier);
    };

    const replaceItemLogo = val => {
        const toast = {
            onOk: true,
            onOkText: "Logo cambiato con successo",
            onError: true
        };
        replaceLogo(auth.loginAuth, company.info.base.uuid, val, toast);
        getCompany(auth.loginAuth, company.info.base.uuid);
    };

    const savePrivacyData = value => {
        let documentDate = value.documentDate.includes("T00:00:00.000Z")
            ? value.documentDate
            : `${value.documentDate}T00:00:00.000Z`;
        setPrivacyData(auth.loginAuth, company.info.base.id, { ...value, documentDate: documentDate }, true);
    };

    let itemData;
    if (company.info.base) {
        itemData = decodeItemSchema(company.info);
    }

    let cctValidated = cct.cctProvRequest.totalItems > 0 ? true : false;
    let companyCertified = company.info.base.status.certificationStatus === "CERTIFIED";
    const formIsLocked =
        !permits.isSuperUser &&
        (!userIsAuthorized("company-cards-forms", permits) || (cctValidated && companyCertified));
    const formIsLockedForTypology = !userIsAuthorized("company-cards-forms", permits);

    return !isNil(company.info.base) ? (
        <CompanyCard
            contentTaxIdAdj={
                <TaxIdForm
                    initialValues={{
                        ...itemData,
                        token: auth.loginAuth.securityToken
                    }}
                    onSubmit={val => replaceTaxId(val)}
                    formIsLocked={formIsLocked}
                />
            }
            contentVatNumberAdj={
                <VatNumberForm
                    initialValues={{
                        ...itemData,
                        token: auth.loginAuth.securityToken
                    }}
                    corrections={corrections}
                    onSubmit={val => replaceVatNumber(val)}
                    formIsLocked={formIsLocked}
                />
            }
            contentClassifierAdj={
                <ClassifierForm
                    initialValues={{
                        ...itemData,
                        ...auth
                    }}
                    corrections={corrections}
                    onSubmit={val => replaceItemClassifier(val)}
                    formIsLocked={formIsLockedForTypology}
                />
            }
            contentLogoAdj={
                <LogoForm
                    logoUrl={company.info.base.details.logoUrl ? company.info.base.details.logoUrl : null}
                    handleSubmit={replaceItemLogo}
                    formIsLocked={formIsLocked}
                />
            }
            content={
                <div>
                    <CompanyEditForm initialValues={{ ...itemData }} onSubmit={val => editCompany(val)} />
                    <Divider orientation="left">
                        <FormattedMessage id="c-certifications.title-privacy" />
                    </Divider>
                    <ItemPrivacyDataForm
                        initialValues={itemPrivacy && itemPrivacy.preferences}
                        onSubmit={value => savePrivacyData(value)}
                    />
                    <div style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            disabled={itemPrivacy && !userIsAuthorized("c-company-edit-button-unlock", permits)}
                            onClick={() => submitForm("item-privacy-data-form")}
                        >
                            <FormattedMessage id="general.save" />
                        </Button>
                    </div>
                </div>
            }
            history={history}
        />
    ) : (
        <Redirect to="/item" />
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    cct: state.cct.read,
    corrections: state.corrections
});

const actions = {
    getCctProvisioningRequest,
    getCompany,
    getPrivacyData,
    replaceLogo,
    replaceTaxId,
    replaceVatNumber,
    replaceClassifier,
    replaceIdentifier,
    setPrivacyData,
    updateItem,
    submitForm: formName => submit(formName)
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

CompanyEditView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object,
    corrections: PropTypes.object.isRequired,
    getCompany: PropTypes.func.isRequired,
    getPrivacyData: PropTypes.func.isRequired,
    setPrivacyData: PropTypes.func.isRequired,
    getCctProvisioningRequest: PropTypes.func.isRequired,
    location: PropTypes.object,
    updateItem: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired
};
export default composedHoc(CompanyEditView);
