import { API_URL } from "../config";
import { getRestCall, putRestCall } from "../utils/rest-api-call";

export const GET_PORTAL_STATE_START = "GET_PORTAL_STATE_START";
export const GET_PORTAL_STATE_SUCCESS = "GET_PORTAL_STATE_SUCCESS";
export const GET_PORTAL_STATE_ERROR = "GET_PORTAL_STATE_ERROR";

export function getPortalState(auth) {
    return async dispatch => {
        dispatch({
            type: GET_PORTAL_STATE_START
        });

        try {
            const result = await getRestCall(`${API_URL}/maintenance`, auth, {}, dispatch, auth.refreshToken);

            dispatch({
                type: GET_PORTAL_STATE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_PORTAL_STATE_ERROR,
                error: e
            });
        }
    };
}

export const PUT_PORTAL_IN_MAINTNANCE_START = "PUT_PORTAL_IN_MAINTNANCE_START";
export const PUT_PORTAL_IN_MAINTNANCE_SUCCESS = "PUT_PORTAL_IN_MAINTNANCE_SUCCESS";
export const PUT_PORTAL_IN_MAINTNANCE_ERROR = "PUT_PORTAL_IN_MAINTNANCE_ERROR";

export function putPortalInMaintenance(auth, request) {
    return async dispatch => {
        dispatch({
            type: PUT_PORTAL_IN_MAINTNANCE_START,
            request: request
        });

        try {
            const result = await putRestCall(`${API_URL}/maintenance`, auth, {}, request, dispatch, auth.refreshToken);

            dispatch({
                type: PUT_PORTAL_IN_MAINTNANCE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: PUT_PORTAL_IN_MAINTNANCE_ERROR,
                error: e
            });
        }
    };
}
