import { API_URL } from "../config";
import { getRestCall, postRestCall, patchRestCall, deleteRestCall } from "../utils/rest-api-call";

export const GET_APP_NAMES_START = "GET_APP_NAMES_START";
export const GET_APP_NAMES_SUCCESS = "GET_APP_NAMES_SUCCESS";
export const GET_APP_NAMES_ERROR = "GET_APP_NAMES_ERROR";

export function getAppNames(auth, pagination, app_name, contact_mail, type, software_house) {
    return async dispatch => {
        const urlParams = {
            page: pagination && pagination.page,
            size: pagination && pagination.size ? pagination.size : 10,
            app_name: app_name,
            contact_mail: contact_mail,
            type: type,
            software_house: software_house
        };

        dispatch({
            type: GET_APP_NAMES_START
        });

        try {
            const result = await getRestCall(`${API_URL}/appname/read`, auth, urlParams, dispatch, auth.refreshToken);

            dispatch({
                type: GET_APP_NAMES_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: GET_APP_NAMES_ERROR,
                error: e
            });
        }
    };
}

export const DELETE_APP_NAME_START = "DELETE_APP_NAME_START";
export const DELETE_APP_NAME_SUCCESS = "DELETE_APP_NAME_SUCCESS";
export const DELETE_APP_NAME_ERROR = "DELETE_APP_NAME_ERROR";

export function deleteApp(auth, idAppName) {
    return async dispatch => {
        dispatch({
            type: DELETE_APP_NAME_START,
            request: idAppName
        });

        try {
            const result = await deleteRestCall(
                `${API_URL}/appname/delete/${idAppName}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: DELETE_APP_NAME_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: DELETE_APP_NAME_ERROR,
                error: e
            });
        }
    };
}

export const ADD_APP_NAME_START = "ADD_APP_NAME_START";
export const ADD_APP_NAME_SUCCESS = "ADD_APP_NAME_SUCCESS";
export const ADD_APP_NAME_ERROR = "ADD_APP_NAME_ERROR";

export function addApp(auth, newApp) {
    return async dispatch => {
        dispatch({
            type: ADD_APP_NAME_START,
            request: newApp
        });

        const urlParams = { ...newApp };

        try {
            const result = await postRestCall(`${API_URL}/appname/write`, auth, urlParams, dispatch, auth.refreshToken);

            dispatch({
                type: ADD_APP_NAME_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: ADD_APP_NAME_ERROR,
                error: e
            });
        }
    };
}

export const EDIT_APP_NAME_START = "EDIT_APP_NAME_START";
export const EDIT_APP_NAME_SUCCESS = "EDIT_APP_NAME_SUCCESS";
export const EDIT_APP_NAME_ERROR = "EDIT_APP_NAME_ERROR";

export function editApp(auth, editingApp, appId) {
    return async dispatch => {
        dispatch({
            type: EDIT_APP_NAME_START,
            request: editingApp
        });

        const urlParams = { ...editingApp };

        try {
            const result = await patchRestCall(
                `${API_URL}/appname/update/${appId}`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: EDIT_APP_NAME_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: EDIT_APP_NAME_ERROR,
                error: e
            });
        }
    };
}

export const GET_APPNAME_DESCRIPTION_START = "GET_APPNAME_DESCRIPTION_START";
export const GET_APPNAME_DESCRIPTION_SUCCESS = "GET_APPNAME_DESCRIPTION_SUCCESS";
export const GET_APPNAME_DESCRIPTION_ERROR = "GET_APPNAME_DESCRIPTION_ERROR";

export function getAppNameDescription(auth, appname) {
    return async dispatch => {
        dispatch({
            type: GET_APPNAME_DESCRIPTION_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/appname/readescription/${appname}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({ type: GET_APPNAME_DESCRIPTION_SUCCESS, payload: result });
            return result;
        } catch (e) {
            dispatch({
                type: GET_APPNAME_DESCRIPTION_ERROR,
                error: e
            });
        }
    };
}

export const GET_APPNAME_EXPORT_START = "GET_APPNAME_EXPORT_START";
export const GET_APPNAME_EXPORT_SUCCESS = "GET_APPNAME_EXPORT_SUCCESS";
export const GET_APPNAME_EXPORT_ERROR = "GET_APPNAME_EXPORT_ERROR";

export function getAppNameExport(auth) {
    return async dispatch => {
        dispatch({
            type: GET_APPNAME_EXPORT_START
        });

        try {
            const result = await getRestCall(`${API_URL}/appname/report`, auth, null, dispatch, auth.refreshToken);
            dispatch({ type: GET_APPNAME_EXPORT_SUCCESS, payload: result });
            return result;
        } catch (e) {
            dispatch({
                type: GET_APPNAME_EXPORT_ERROR,
                error: e
            });
        }
    };
}
