import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { UPTIME_ROBOT_URL, ELASTIC_INVOICE_URL, ELASTIC_TSID_URL, AZURE_STATUS_PAGE_URL } from "../../../config";

export const ServiceStatusTSDigital = ({
    match: {
        params: { type }
    }
}) => {
    const [urlForStatus, setUrlForStatus] = useState("");

    useEffect(() => {
        if (type === "service-ts-digital") setUrlForStatus(UPTIME_ROBOT_URL);
        if (type === "service-hr") setUrlForStatus("https://stats.uptimerobot.com/22BnlHkQPN/");
        if (type === "service-hub") setUrlForStatus("https://stats.uptimerobot.com/77KEwcz34D/");
        if (type === "service-analytics") setUrlForStatus("https://stats.uptimerobot.com/GW8J7FJKOZ");
        if (type === "service-ncs") setUrlForStatus("https://stats.uptimerobot.com/3y3lpI3zg");
        if (type === "service-atlassian") setUrlForStatus("https://status.atlassian.com/");
        if (type === "service-ts-invoice") setUrlForStatus(ELASTIC_INVOICE_URL);
        if (type === "service-azure") setUrlForStatus(AZURE_STATUS_PAGE_URL);
        if (type === "service-ts-id") setUrlForStatus(ELASTIC_TSID_URL);
    }, [type]);

    return (
        <div>
            <div>
                <iframe
                    id="ts_digital"
                    title="Stato dei servizi"
                    src={urlForStatus}
                    style={{
                        border: "20px",
                        right: 10,
                        top: 150,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: "1",
                        paddingLeft: "100px"
                    }}
                />
            </div>
        </div>
    );
};

ServiceStatusTSDigital.propTypes = {
    match: PropTypes.object.isRequired
};
