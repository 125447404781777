import { SERVICES_SUBSCRIPTION_URL, SDI_CONFIG, B2B_PASSIVE_CONFIG, API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";
import { callWithRetry } from "../../utils/callWithRetry";
import thrift from "browser-thrift";
import cuid from "cuid";
import uuid from "uuid/v4";
import { isUUID } from "validator";

import SdiConfig from "../../assets/thrift/sdi/SDIFlowConfig";
import SdiTypes from "../../assets/thrift/sdi/v2_types";
import B2bPassiveConfig from "../../assets/thrift/b2b-passive/B2BPassiveFlowConfig";
import B2bPassiveTypes from "../../assets/thrift/b2b-passive/v2_types";

const sdiUrl = new URL(SDI_CONFIG);
const sdiClient = thrift.createXHRClient(
    SdiConfig,
    thrift.createXHRConnection(sdiUrl.hostname, 443, {
        useCors: true,
        path: sdiUrl.pathname,
        https: sdiUrl.protocol === "https:"
    })
);

const b2bPassiveUrl = new URL(B2B_PASSIVE_CONFIG);
const b2bPassiveClient = thrift.createXHRClient(
    B2bPassiveConfig,
    thrift.createXHRConnection(b2bPassiveUrl.hostname, 443, {
        useCors: true,
        path: b2bPassiveUrl.pathname,
        https: b2bPassiveUrl.protocol === "https:"
    })
);

export const GET_SERVICES_FOR_COMPANY_SEARCHED_START = "GET_SERVICES_FOR_COMPANY_SEARCHED_START";
export const GET_SERVICES_FOR_COMPANY_SEARCHED_ERROR = "GET_SERVICES_FOR_COMPANY_SEARCHED_ERROR";
export const GET_SERVICES_FOR_COMPANY_SEARCHED_SUCCESS = "GET_SERVICES_FOR_COMPANY_SEARCHED_SUCCESS";

export function getServicesForCompanySeached(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_SERVICES_FOR_COMPANY_SEARCHED_START,
            request: itemId
        });

        const url = `${SERVICES_SUBSCRIPTION_URL}/items`;

        try {
            let requestItemId = itemId;
            if (!isUUID(requestItemId)) {
                const itemRegistry = await getRestCall(
                    `${API_URL}/items/${requestItemId}`,
                    auth,
                    {
                        packageType: "BASE"
                    },
                    dispatch,
                    auth.refreshToken
                );
                requestItemId = itemRegistry.item.base.uuid;
            }

            const result = await getRestCall(url, auth, { itemIds: requestItemId }, dispatch, auth.refreshToken);

            dispatch({
                type: GET_SERVICES_FOR_COMPANY_SEARCHED_SUCCESS,
                payload: result.itemServices[requestItemId]
            });
            return result.itemServices[requestItemId];
        } catch (e) {
            dispatch({
                type: GET_SERVICES_FOR_COMPANY_SEARCHED_ERROR,
                error: e
            });
        }
    };
}

export const SEARCH_SDI_CONFIG_START = "SEARCH_SDI_CONFIG_START";
export const SEARCH_SDI_CONFIG_ERROR = "SEARCH_SDI_CONFIG_ERROR";
export const SEARCH_SDI_CONFIG_SUCCESS = "SEARCH_SDI_CONFIG_SUCCESS";

export function searchSdiConfig(auth, fiscalCode) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const authRegistry = new SdiTypes.Header({
                ...auth,
                accessToken: auth.securityToken,
                correlationId: uuid(),
                requestId: cuid()
            });

            let pagination = new SdiTypes.Pagination({
                page: 0,
                items: 10
            });
            let request = new SdiTypes.ListConfigsRequest({
                itemIds: [fiscalCode],
                pagination
            });
            dispatch({
                type: SEARCH_SDI_CONFIG_START,
                request: request
            });

            callWithRetry(
                sdiClient,
                sdiClient.listConfigs,
                authRegistry,
                request,
                auth.refreshToken,
                dispatch,
                (error, response) => {
                    if (error !== null) {
                        dispatch({
                            type: SEARCH_SDI_CONFIG_ERROR,
                            error: error
                        });
                        resolve(false);
                    } else {
                        dispatch({
                            type: SEARCH_SDI_CONFIG_SUCCESS,
                            payload: response
                        });
                        resolve(true);
                    }
                }
            );
        });
    };
}

export const SEARCH_B2B_PASSIVE_CONFIG_START = "SEARCH_B2B_PASSIVE_CONFIG_START";
export const SEARCH_B2B_PASSIVE_CONFIG_ERROR = "SEARCH_B2B_PASSIVE_CONFIG_ERROR";
export const SEARCH_B2B_PASSIVE_CONFIG_SUCCESS = "GET_B2B_PASSIVE_CONFIG_SUCCESS";

export function searchB2bPassiveConfig(auth, fiscalCode) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const authRegistry = new B2bPassiveTypes.Header({
                ...auth,
                accessToken: auth.securityToken,
                correlationId: uuid(),
                requestId: cuid()
            });

            let pagination = new B2bPassiveTypes.Pagination({
                page: 0,
                items: 10
            });
            let request = new B2bPassiveTypes.ListConfigsRequest({
                itemIds: [fiscalCode],
                pagination
            });
            dispatch({
                type: SEARCH_B2B_PASSIVE_CONFIG_START,
                request: request
            });
            callWithRetry(
                b2bPassiveClient,
                b2bPassiveClient.listConfigs,
                authRegistry,
                request,
                auth.refreshToken,
                dispatch,
                (error, response) => {
                    if (error !== null) {
                        dispatch({
                            type: SEARCH_B2B_PASSIVE_CONFIG_ERROR,
                            error: error
                        });
                        resolve(false);
                    } else {
                        dispatch({
                            type: SEARCH_B2B_PASSIVE_CONFIG_SUCCESS,
                            payload: response
                        });
                        resolve(true);
                    }
                }
            );
        });
    };
}
