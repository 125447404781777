import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tag, Select, Typography } from "antd";

import { translateNCSAppCode, translateNcsEventsCode } from "../../../../utils/translate";
import { OrderFooter } from "../../../../components/general/order-footer";

export const NotificationActivity = ({ loading, activityList, handlePageSizeChange, pageSize }) => {
    const [query, setQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        listFilter.push("size=" + pageSize);
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQuery(query);
    }, [pageSize]);

    let columns = [
        {
            title: <FormattedMessage id={"id"} />,
            dataIndex: "id"
        },
        {
            title: <FormattedMessage id={"app"} />,
            dataIndex: "appCode",
            render: text => <span>{translateNCSAppCode(text)}</span>
        },
        {
            title: <FormattedMessage id={"event_name"} />,
            dataIndex: "eventName",
            render: text => <span>{translateNcsEventsCode(text)}</span>
        },
        {
            title: <FormattedMessage id={"event_description"} />,
            dataIndex: "eventDescription"
        },
        {
            title: <FormattedMessage id={"inserted_date"} />,
            dataIndex: "insertedDate"
        },
        {
            title: <FormattedMessage id={"channels"} />,
            dataIndex: "channelType",
            render: text => (
                <Tag color="grey" key={"channelType"} style={{ marginBottom: 8 }}>
                    {text}
                </Tag>
            )
        },
        {
            title: <FormattedMessage id={"recipients"} />,
            dataIndex: "recipients",
            render: text => (
                <span>
                    {text &&
                        text.map((item, index) => (
                            <div key={"recipients" + index} style={{ marginBottom: 8 }}>
                                {item}{" "}
                            </div>
                        ))}
                </span>
            )
        }
    ];

    const pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ marginTop: 20, marginBottom: 10, overflow: "auto", height: "52vh" }}>
                <Table
                    rowKey={(record, index) => "ncsActivity" + index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    loading={loading}
                    dataSource={activityList || []}
                    expandedRowRender={record => (
                        <div>
                            <b>ncsId: </b>
                            {record.ncsId ? record.ncsId : <FormattedMessage id={"not_available"} />}
                        </div>
                    )}
                    pagination={false}
                />
            </div>
            <OrderFooter
                pageSize={pageSize}
                disabled={!activityList || !activityList.length || loading}
                handlePageSizeChange={handlePageSizeChange}
                pageSizeOptions={pageSizeOptions}
                query={query}
            />
        </div>
    );
};

NotificationActivity.propTypes = {
    loading: PropTypes.bool.isRequired,
    activityList: PropTypes.array.isRequired,
    pageSize: PropTypes.any.isRequired,
    handlePageSizeChange: PropTypes.func.isRequired
};
