import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../../components/general/error-boundary";

import Company from "../../../components/company";

import { getCompany } from "../../../actions/company/info";
import { getUser } from "../../../actions/user/read";
import { getWebhook } from "../../../actions/company/webhook";
import { removeRoles } from "../../../actions/user/write";
import { createLayer, deleteLayer, updateLayer, disableLayer, enableLayer } from "../../../actions/company/layers";
import { getPackageSubscriptions } from "../../../actions/usage/read";
import { getCctProvisioningRequest } from "../../../actions/cct/read";
import { getItemWaitingList } from "../../../actions/waiting-list/read";

class CompanyDataView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        connections: PropTypes.object,
        createLayer: PropTypes.func.isRequired,
        deleteLayer: PropTypes.func.isRequired,
        disableLayer: PropTypes.func.isRequired,
        enableLayer: PropTypes.func.isRequired,
        getCompany: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        getWebhook: PropTypes.func.isRequired,
        removeRoles: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        updateLayer: PropTypes.func.isRequired,
        meteringImports: PropTypes.object,
        cctProvRequest: PropTypes.object.isRequired,
        packageSubscriptions: PropTypes.object.isRequired,
        getPackageSubscriptions: PropTypes.func.isRequired,
        getCctProvisioningRequest: PropTypes.func.isRequired,
        getItemWaitingList: PropTypes.func.isRequired,
        itemWaitingList: PropTypes.object.isRequired
    };

    render() {
        const {
            admin,
            auth,
            company,
            connections,
            createLayer,
            deleteLayer,
            disableLayer,
            enableLayer,
            getCompany,
            getUser,
            getWebhook,
            history,
            removeRoles,
            services,
            updateLayer,
            meteringImports,
            cctProvRequest,
            packageSubscriptions,
            getPackageSubscriptions,
            getCctProvisioningRequest,
            getItemWaitingList,
            itemWaitingList
        } = this.props;

        return (
            <div>
                <Company
                    admin={admin}
                    auth={auth}
                    company={company}
                    connections={connections}
                    createLayer={createLayer}
                    deleteLayer={deleteLayer}
                    disableLayer={disableLayer}
                    enableLayer={enableLayer}
                    getCompany={getCompany}
                    getUser={getUser}
                    getWebhook={getWebhook}
                    history={history}
                    removeRoles={removeRoles}
                    services={services}
                    updateLayer={updateLayer}
                    meteringImports={meteringImports}
                    cctProvRequest={cctProvRequest}
                    packageSubscriptions={packageSubscriptions}
                    getPackageSubscriptions={getPackageSubscriptions}
                    getCctProvisioningRequest={getCctProvisioningRequest}
                    getItemWaitingList={getItemWaitingList}
                    itemWaitingList={itemWaitingList}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    connections: state.connections.read,
    services: state.services,
    meteringImports: state.usage.getImports,
    cctProvRequest: state.cct.read,
    packageSubscriptions: state.usage.getPackageSubscriptions,
    itemWaitingList: state.waitingList.read.getItemWaitingList
});

const actions = {
    createLayer,
    deleteLayer,
    disableLayer,
    enableLayer,
    getCompany,
    getUser,
    getWebhook,
    removeRoles,
    updateLayer,
    getCctProvisioningRequest,
    getPackageSubscriptions,
    getItemWaitingList
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyDataView);
