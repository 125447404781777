import styled from "styled-components";

export const Container = styled.div`
    height: 90vh;
    min-height: 700px;
    min-width: 400px;
    background: white;
    padding: 24px;
    overflow: auto;
`;

export const GoBack = styled.div`
    text-align: right;
`;
