import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../components/general/error-boundary";
import { displaySuccessToast } from "../../actions/toast";

import Dashboard from "../../components/dashboard";
import { ViewTitle } from "../../styled";
import { FormattedMessage } from "react-intl";

const DashboardView = ({ admin, auth, displaySuccessToast, history }) => {
    return (
        <div>
            <ViewTitle>
                <FormattedMessage id="general.dashboard" />
            </ViewTitle>
            <Dashboard auth={auth} admin={admin} displaySuccessToast={displaySuccessToast} history={history} />
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth
});
const actions = { displaySuccessToast };

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    displaySuccessToast: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(DashboardView);
