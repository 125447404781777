import { B2B_READ_API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_ORDERS_START = "GET_ORDERS_START";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export function getOrders(auth, filter, size, sort) {
    return async dispatch => {
        const urlParams = {
            ...sort,
            ...filter,
            size: size
        };
        dispatch({
            type: GET_ORDERS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${B2B_READ_API_URL}/v1/orders`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_ORDERS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_ORDERS_ERROR,
                error: e
            });
        }
    };
}
