import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { loginAuth, adminInfo, updatePassword, resetPassword } from "./auth";
import { infoU, addRoles, removeRoles, enableUser, disableUser, createUser } from "./user";
import info from "./company/info";
import infoMetering from "./company/infoMetering";
import find from "./company/find";
import connections from "./connections";

import { users } from "./company/users";
import { active } from "./company/services";
import { createLayer, deleteLayer, updateLayer, enableLayer, disableLayer } from "./company/layers";

import { invoice } from "./invoice";
import obligationsTax from "./obligations-tax";
import { infoD, content } from "./document/info";
import message from "./document/message";
import list from "./services/list";
import { aConfig, aSigner } from "./services/a-fiscali";
import { sConfig, sSigner } from "./services/signature";
import { searchIt } from "./search/company";
import { data, documentFilled, cancel, withdraw, update } from "./signer";
import b2bPassive from "./services/b2b-passive";
import digitalbox from "./services/digitalbox";
import gdprLog from "./services/gdprlog";
import { getSdiConfig, updateSdiConfig } from "./services/sdi";
import { newVersionAvailable } from "./session";
import { taxId, vatNumber, classifier } from "./corrections";
import { createItem, updateItem } from "./item";

import { packageMetering } from "./metering";
import { invoicingUsage, invoicingDetailsUsage, invoicingStatistics, postInvoiceConservation } from "./usage/invoices";
import {
    invoiceUsage,
    invoiceDeadletter,
    reassignInvoiceUsage,
    deleteInvoiceUsage,
    invoiceUsagePackage,
    reassignInvoiceUsagePackage,
    replayInvoiceNotificationUsage
} from "./usage/invoice";
import { usagePerms } from "./usage/permission";
import { privacyUsage } from "./usage/privacy";
import { getCompanies, getPackageSubscriptions, getCompanyPackages } from "./usage/read";
import { getExtensible, postExtensions } from "./usage/extensions";
import { postReassignments } from "./usage/realtime";
import { getImports, getTotalImports } from "./usage/lynfa";
import {
    unassignedInvoices,
    notAssignabledInvoices,
    postUnassignedInvoices,
    postNotassignedInvoices
} from "./company/unassigned-invoices";
import { cervedItems } from "./cerved";
import { getStatus, putInMaintenance } from "./portal";
import toast from "./toast";
import { sentinel, templates } from "./notification";

import admUsers from "./adm/users";

import { validating, rejecting, getDocuments, getFullInfoDocument, getConnections, pauseContract } from "./contract";

import { getDocument, getConnectionDocument } from "./documents";
import { settings, notificationList, companyUsersNotification } from "./company/notifications";
import { read, write } from "./cct";
import { readNewArchive, writeNewArchive } from "./archive";

import { webhookList } from "./company/webhook";

import { getConfig } from "./cct/flow-config";
import signatureNew from "./signature";
import order from "./order";
import { waitingList } from "./waiting-list";

import { spidlist, singleSpid, spidUsage } from "./spid";

import {
    getAppNames,
    deleteAppNames,
    addAppNames,
    editAppNames,
    getAppNameDescription,
    getAppNameExport
} from "./app-name";

const spid = combineReducers({ spidlist, singleSpid, spidUsage });

const password = combineReducers({
    resetPassword,
    updatePassword
});

const auth = combineReducers({
    loginAuth
});

const admin = combineReducers({
    adminInfo,
    password
});

const contractActions = combineReducers({
    rejecting,
    validating,
    pauseContract
});

const cct = combineReducers({
    read,
    write
});

const newArchive = combineReducers({
    readNewArchive,
    writeNewArchive
});

const contract = combineReducers({
    contractActions,
    getDocuments,
    getFullInfoDocument,
    getConnections
});

const document = combineReducers({
    infoD,
    content,
    invoiceUsage,
    invoiceDeadletter,
    deleteInvoiceUsage,
    reassignInvoiceUsage,
    invoiceUsagePackage,
    reassignInvoiceUsagePackage,
    replayInvoiceNotificationUsage,
    message
});

const aFiscaliIva = combineReducers({
    aConfig,
    aSigner
});
const signature = combineReducers({
    sConfig,
    sSigner
});
const sdi = combineReducers({
    getSdiConfig,
    updateSdiConfig
});
const searchItems = combineReducers({
    searchIt
});
const services = combineReducers({
    aFiscaliIva,
    b2bPassive,
    digitalbox,
    gdprLog,
    list,
    sdi,
    signature,
    searchItems
});
const signer = combineReducers({
    data,
    documentFilled,
    cancel,
    withdraw,
    update
});
const layers = combineReducers({
    createLayer,
    deleteLayer,
    updateLayer,
    enableLayer,
    disableLayer
});

const unassigned_invoice = combineReducers({
    unassignedInvoices,
    notAssignabledInvoices,
    postUnassignedInvoices,
    postNotassignedInvoices
});
const notifications = combineReducers({
    settings,
    notificationList,
    webhookList
});
const documents = combineReducers({
    getDocument,
    getConnectionDocument
});

const company = combineReducers({
    active,
    layers,
    find,
    info,
    infoMetering,
    notifications,
    unassigned_invoice,
    users,
    documents,
    companyUsersNotification
});

const user = combineReducers({
    infoU,
    addRoles,
    removeRoles,
    enableUser,
    disableUser,
    createUser
});

const corrections = combineReducers({
    taxId,
    vatNumber,
    classifier
});
const item = combineReducers({
    createItem,
    updateItem
});
const session = combineReducers({
    newVersionAvailable
});
const usage = combineReducers({
    invoicingDetailsUsage,
    invoicingStatistics,
    invoicingUsage,
    privacyUsage,
    usagePerms,
    getCompanies,
    getPackageSubscriptions,
    getCompanyPackages,
    getExtensible,
    postExtensions,
    postReassignments,
    getImports,
    getTotalImports,
    postInvoiceConservation
});

const cerved = combineReducers({
    cervedItems
});

const portal = combineReducers({
    getStatus,
    putInMaintenance
});

const cctConfig = combineReducers({
    getConfig
});

const notification = combineReducers({
    sentinel,
    templates
});

const metering = combineReducers({
    packageMetering
});

const appNames = combineReducers({
    getAppNames,
    deleteAppNames,
    addAppNames,
    editAppNames,
    getAppNameDescription,
    getAppNameExport
});

const reducersSchema = {
    admin,
    admUsers,
    appNames,
    auth,
    cct,
    cerved,
    company,
    connections,
    contract,
    corrections,
    document,
    form: formReducer,
    invoice,
    item,
    newArchive,
    obligationsTax,
    portal,
    services,
    signatureNew,
    signer,
    usage,
    user,
    toast,
    session,
    spid,
    cctConfig,
    notification,
    order,
    waitingList,
    metering
};

export default reducersSchema;
