import { combineReducers } from "redux";

import { getWaitingList, getItemWaitingList } from "./read";

const read = combineReducers({
    getWaitingList,
    getItemWaitingList
});

export const waitingList = combineReducers({
    read
});
