import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Searchbar } from "@mondora/arc/antd/Searchbar";

import { getInvoice } from "../../../actions/invoice/read";
import { setDocument } from "../../../actions/document/info";
import { displayErrorToast } from "../../../actions/toast";

const HubIdSearch = ({ auth, history, displayErrorToast, getInvoice, setDocument }) => {
    const onSearch = async hubIdSearched => {
        if (hubIdSearched.length === 24 || hubIdSearched.length === 36) {
            getInvoice(auth.loginAuth, { hubId: hubIdSearched.trim() })
                .then(res => {
                    if (res) {
                        setDocument(res);
                        history.push(`/dashboard/invoice/${hubIdSearched.trim()}/`);
                    } else {
                        displayErrorToast("HubId inserito non trovato.", true);
                    }
                })
                .catch(err => {
                    displayErrorToast("HubId inserito non trovato.", true);
                });
        } else {
            displayErrorToast("HubId inserito non valido.", true);
        }
    };

    return <Searchbar placeholder="Inserisci HubId" onSearch={v => onSearch(v.value.trim())} enterButton />;
};

const mapStateToProps = state => ({
    auth: state.auth
});
const actions = { displayErrorToast, getInvoice, setDocument };

HubIdSearch.propTypes = {
    auth: PropTypes.object.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    getInvoice: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    setDocument: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(HubIdSearch);
