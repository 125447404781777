import { cctProvRequest, cctOneProvRequest } from "./read";
import {
    autoUploadDocument,
    putCctProvRequest,
    postCctProvRequest,
    setCedentePrestatore,
    postSuspendCctProvisioningRequest
} from "./write";
import { combineReducers } from "redux";

export const read = combineReducers({
    cctProvRequest,
    cctOneProvRequest
});

export const write = combineReducers({
    autoUploadDocument,
    putCctProvRequest,
    setCedentePrestatore,
    postCctProvRequest,
    postSuspendCctProvisioningRequest
});
