import styled from "styled-components";

export const CompanyInfoWrapper = styled.div`
    background-color: white;
    justify-content: space-between;
`;

export const CompanyInfoBlock = styled.div`
    padding: 20px;
`;

export const CctRole = styled.h1`
    color: #005075;
    font-size: 18px;
    font-weight: bolder;
`;
