import { getRestCall, postRestCall } from "../../utils/rest-api-call";
import { METERING_EXTENSIONS_API_URL } from "../../config";

export const GET_METERING_EXTENSIONS_START = "GET_METERING_EXTENSIONS_START";
export const GET_METERING_EXTENSIONS_SUCCESS = "GET_METERING_EXTENSIONS_SUCCESS";
export const GET_METERING_EXTENSIONS_ERROR = "GET_METERING_EXTENSIONS_ERROR";

/**
 * Check extensible of metering package.
 *
 *
 * @name getExtensible
 * @alias Check extensible
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `item` the company tax code.
 * @param {Numbers} `packageId` package Id.
 * @return {undefined}
 */
export const getExtensible = (auth, item, packageId) => async dispatch => {
    dispatch({
        type: GET_METERING_EXTENSIONS_START
    });

    try {
        const result = await getRestCall(
            `${METERING_EXTENSIONS_API_URL}/v1/${item}/packages/${packageId}/extensible`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_METERING_EXTENSIONS_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_METERING_EXTENSIONS_ERROR,
            error: e
        });
    }
};

export const POST_METERING_EXTENSIONS_START = "POST_METERING_EXTENSIONS_START";
export const POST_METERING_EXTENSIONS_SUCCESS = "POST_METERING_EXTENSIONS_SUCCESS";
export const POST_METERING_EXTENSIONS_ERROR = "POST_METERING_EXTENSIONS_ERROR";

/**
 * Extend metering package.
 *
 *
 * @name postExtensions
 * @alias Extend
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `item` the company tax code want to extend.
 * @param {Numbers} `packageId` package Id.
 * * @param {String} `extended` the company tax code want to receive extend the package.
 * @return {undefined}
 */
export const postExtensions = (auth, item, packageId, extended) => async dispatch => {
    dispatch({
        type: POST_METERING_EXTENSIONS_START
    });

    try {
        const result = await postRestCall(
            `${METERING_EXTENSIONS_API_URL}/v1/${item}/packages/${packageId}/extensions/${extended}`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: POST_METERING_EXTENSIONS_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: POST_METERING_EXTENSIONS_ERROR,
            error: e
        });
    }
};
