import styled from "styled-components";

export const Message = styled.div`
    color: rgb(157, 166, 173);
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const Info = styled.div`
    color: rgb(157, 166, 173);
    font-size: 16px;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const OrContainer = styled.div`
    width: 100%;
    height: 64px;
    color: rgb(90, 104, 114);
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Or = styled.div`
    z-index: 1;
`;

export const Line = styled.div`
    background-color: #e4eaf0;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 32px;
    z-index: 0;
`;
export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 2.5rem;
    font-family: Cairo;
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 3rem;
    justify-content: left;
    width: 100%;
    height: 100%;
`;
export const LoginContainer = styled.div`
    margin-top: 6rem;
    margin-bottom: 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
`;
