import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmail } from "validator";

import { Button, Table, Input, Form } from "antd";

const FormItem = Form.Item;

class CloneRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validateStatus: "",
            help: ""
        };
    }
    static propTypes = {
        userInfo: PropTypes.object.isRequired,
        handleGetUserInfo: PropTypes.func.isRequired,
        handleAddRolesWithRefresh: PropTypes.func.isRequired
    };

    remapRole(values) {
        let roles = [];
        let roleToAdd;
        values.forEach(val => {
            roleToAdd = {
                appId: val.appId,
                resourceId: val.resourceId.toUpperCase(),
                actionKey: val.actionKey === "OWNER" ? "ADMIN" : val.actionKey,
                featureCode: val.featureCode
            };

            roles.push(roleToAdd);
        });
        return roles;
    }

    cloneRoles() {
        const { handleAddRolesWithRefresh } = this.props;
        const { data } = this.state;
        handleAddRolesWithRefresh(data, {
            onOkText: "Ruoli clonati correttamente"
        });
    }

    searchUser(value) {
        const { handleGetUserInfo, userInfo } = this.props;
        if (isEmail(value) || value.length >= 36) {
            handleGetUserInfo(value).then(res => {
                if (userInfo.profile.id !== value) {
                    if (res) {
                        if (res.roles) {
                            let rolesToClone = this.remapRole(res.roles);
                            this.setState({
                                help: "",
                                validateStatus: "",
                                data: rolesToClone
                            });
                        } else {
                            this.setState({
                                help: "L'utente non ha ruoli da clonare.",
                                validateStatus: "error"
                            });
                        }
                    } else {
                        this.setState({
                            help: "Utenza non presente in AGYO",
                            validateStatus: "error"
                        });
                    }
                } else {
                    this.setState({
                        help: "L'utente da cui copiare i ruoli non può essere l'utente selezionato.",
                        validateStatus: "error"
                    });
                }
            });
        }
    }

    renderSearch() {
        const { validateStatus, help } = this.state;

        return (
            <div>
                {"Inserisci l'identificativo dell'utente da cui vuoi copiare i ruoli"}
                <FormItem hasFeedback={true} validateStatus={validateStatus} help={help}>
                    <Input onChange={e => this.searchUser(e.target.value)} size="small" />
                </FormItem>
            </div>
        );
    }

    renderRoles() {
        const { data } = this.state;
        const columns = [
            {
                title: "AppId",
                dataIndex: "appId"
            },
            {
                title: "FeatureCode",
                dataIndex: "featureCode"
            },
            {
                title: "Risorsa",
                dataIndex: "resourceId"
            },
            {
                title: "Azione",
                dataIndex: "actionKey"
            }
        ];

        return data ? (
            <div>
                <p>{"Lista delle risorse che verranno clonate."}</p>
                <p>
                    <span style={{ color: "red", fontWeight: "500" }}>{"Attenzione: "}</span>
                    {"I ruoli OWNER saranno trasformati in ADMIN."}
                </p>
                <Table
                    rowKey={record => record.appId + record.resourceId + record.actionKey}
                    columns={columns}
                    dataSource={this.state.data}
                    size="small"
                />

                <div style={{ textAlign: "right" }}>
                    <Button style={{ width: "100px" }} type="primary" onClick={() => this.cloneRoles()}>
                        {"Clona"}
                    </Button>
                </div>
            </div>
        ) : null;
    }

    render() {
        return (
            <div className="clone-roles">
                {this.renderSearch()}
                {this.renderRoles()}
            </div>
        );
    }
}

export default CloneRoles;
