import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Select, Typography, message, Tabs } from "antd";
import { saveAs } from "file-saver";

import { errorBoundary } from "../../../components/general/error-boundary";
import ServiceCard from "../../../components/general/ServiceCard/info";
import { BankLinkTransactions } from "../../../components/company/bank-link/bank-link-transactions";
import { BankLinkFiles } from "../../../components/company/bank-link/bank-link-files";
import { getPaymentTransactions, getBankLinkFiles, getBankLinkDownloadFile } from "../../../actions/bank-link/read";

import { Container } from "./styled";

const CompanyBankLinkView = ({
    auth,
    intl,
    company,
    history,
    getPaymentTransactions,
    getBankLinkFiles,
    getBankLinkDownloadFile
}) => {
    const viewModel = useCompanyBankLinkView(
        auth.loginAuth,
        intl,
        company,
        history,
        getPaymentTransactions,
        getBankLinkFiles,
        getBankLinkDownloadFile
    );

    return (
        <ServiceCard
            history={history}
            content={
                <Tabs defaultActiveKey="bank-link-movements">
                    <Tabs.TabPane tab={<FormattedMessage id={"movements"} />} key="bank-link-movements">
                        <Container>
                            <BankLinkTransactions
                                loading={viewModel.loadingTransactions}
                                paymentTransactionEntityList={viewModel.paymentTransactionEntityList}
                                handleNextPage={viewModel.handleNextPageTransactions}
                                handlePageSizeChanged={viewModel.handlePageSizeChangedTransactions}
                                handlePreviousPage={viewModel.handlePreviousPageTransactions}
                                hasNextPage={viewModel.hasNextPageTransactions}
                                currentPage={viewModel.currentPageTransactions}
                                defaultPageSize={viewModel.defaultPageSize}
                                pageSizeOptions={viewModel.pageSizeOptions}
                                query={viewModel.queryTransactions}
                                expanded={viewModel.expandedTransactions}
                                handleExpandedChange={viewModel.handleExpandedChangeTransactions}
                                handleShowPaymentTransaction={viewModel.handleShowPaymentTransaction}
                            />
                        </Container>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<FormattedMessage id={"files"} />} key="bank-link-files">
                        <Container>
                            <BankLinkFiles
                                loading={viewModel.loadingFiles}
                                fileUploadEntityList={viewModel.fileUploadEntityList}
                                handleNextPage={viewModel.handleNextPageFiles}
                                handlePageSizeChanged={viewModel.handlePageSizeChangedFiles}
                                handlePreviousPage={viewModel.handlePreviousPageFiles}
                                hasNextPage={viewModel.hasNextPageFiles}
                                currentPage={viewModel.currentPageFiles}
                                defaultPageSize={viewModel.defaultPageSize}
                                pageSizeOptions={viewModel.pageSizeOptions}
                                query={viewModel.queryFiles}
                                expanded={viewModel.expandedFiles}
                                handleExpandedChange={viewModel.handleExpandedChangeFiles}
                                handleDownloadFile={viewModel.handleDownloadFile}
                                handleViewFileTransaction={viewModel.handleViewFileTransaction}
                            />
                        </Container>
                    </Tabs.TabPane>
                </Tabs>
            }
            serviceInfo={{ name: <FormattedMessage id="bank_link" />, category: "BANK-LINK" }}
            statisticsEnable={false}
        />
    );
};

CompanyBankLinkView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getPaymentTransactions: PropTypes.func.isRequired,
    getBankLinkFiles: PropTypes.func.isRequired,
    getBankLinkDownloadFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company
});

const actions = { getPaymentTransactions, getBankLinkFiles, getBankLinkDownloadFile };

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(CompanyBankLinkView);

const useCompanyBankLinkView = (
    auth,
    intl,
    company,
    history,
    getPaymentTransactions,
    getBankLinkFiles,
    getBankLinkDownloadFile
) => {
    let companyUuid = company.info.base.uuid;
    let defaultPageSize = "25";
    let pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];
    /// Transactions
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [paymentTransactionEntityList, setPaymentTransactionEntityList] = useState([]);
    const [currentPageTransactions, setCurrentPageTransactions] = useState(1);
    const [pageSizeTransactions, setPageSizeTransactions] = useState(defaultPageSize);
    const [hasNextPageTransactions, setHasNextPageTransactions] = useState(false);
    const [continuationTokenTransactions, setContinuationTokenTransactions] = useState([null]);
    const [expandedTransactions, setExpandedTransactions] = useState(false);
    const [queryTransactions, setQueryTransactions] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        listFilter.push("ownerId=" + companyUuid);
        listFilter.push("size=" + pageSizeTransactions);
        if (continuationTokenTransactions && continuationTokenTransactions[currentPageTransactions - 1]) {
            listFilter.push("continuationToken=" + continuationTokenTransactions[currentPageTransactions - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQueryTransactions(query);
    }, [currentPageTransactions, pageSizeTransactions, companyUuid, continuationTokenTransactions]);

    useEffect(() => {
        const getPaymentTransactionsInfo = async () => {
            let filter = {
                ownerId: companyUuid,
                continuationToken: continuationTokenTransactions[currentPageTransactions - 1]
            };
            let resultPaymentTransactions = await getPaymentTransactions(auth, filter, pageSizeTransactions);
            if (resultPaymentTransactions) {
                if (
                    resultPaymentTransactions._embedded &&
                    resultPaymentTransactions._embedded.paymentTransactionEntityList &&
                    resultPaymentTransactions._embedded.paymentTransactionEntityList.length
                ) {
                    setPaymentTransactionEntityList(resultPaymentTransactions._embedded.paymentTransactionEntityList);
                }
                setHasNextPageTransactions(resultPaymentTransactions.page.hasNext);
                if (continuationTokenTransactions.length <= currentPageTransactions) {
                    continuationTokenTransactions.push(resultPaymentTransactions.page.continuationToken);
                }
                setContinuationTokenTransactions(continuationTokenTransactions);
            }
            setLoadingTransactions(false);
        };

        setLoadingTransactions(true);
        setPaymentTransactionEntityList([]);
        setHasNextPageTransactions(false);
        getPaymentTransactionsInfo();
    }, [
        auth,
        companyUuid,
        continuationTokenTransactions,
        currentPageTransactions,
        getPaymentTransactions,
        pageSizeTransactions
    ]);

    const handlePageSizeChangedTransactions = pageSize => {
        if (pageSize) {
            setCurrentPageTransactions(1);
            setPageSizeTransactions(parseInt(pageSize));
            setContinuationTokenTransactions([null]);
        }
    };

    const handleNextPageTransactions = () => {
        if (hasNextPageTransactions) {
            setCurrentPageTransactions(currentPageTransactions + 1);
        }
    };

    const handlePreviousPageTransactions = () => {
        if (currentPageTransactions !== 1) {
            setCurrentPageTransactions(currentPageTransactions - 1);
        }
    };

    const handleExpandedChangeTransactions = value => {
        setExpandedTransactions(value);
    };

    const handleShowPaymentTransaction = id => {
        history.push(`BANK-LINK/${id}`);
    };

    /// Files
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [fileUploadEntityList, setFileUploadEntityList] = useState([]);
    const [currentPageFiles, setCurrentPageFiles] = useState(1);
    const [pageSizeFiles, setPageSizeFiles] = useState(defaultPageSize);
    const [hasNextPageFiles, setHasNextPageFiles] = useState(false);
    const [continuationTokenFiles, setContinuationTokenFiles] = useState([null]);
    const [expandedFiles, setExpandedFiles] = useState(false);
    const [queryFiles, setQueryFiles] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        listFilter.push("ownerId=" + companyUuid);
        listFilter.push("size=" + pageSizeFiles);
        if (continuationTokenFiles && continuationTokenFiles[currentPageFiles - 1]) {
            listFilter.push("continuationToken=" + continuationTokenFiles[currentPageFiles - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setQueryFiles(query);
    }, [currentPageFiles, pageSizeFiles, companyUuid, continuationTokenFiles]);

    useEffect(() => {
        const getFileUploadInfo = async () => {
            let filter = {
                ownerId: companyUuid,
                continuationToken: continuationTokenFiles[currentPageFiles - 1]
            };
            let resultFiles = await getBankLinkFiles(auth, filter, pageSizeFiles);
            if (resultFiles) {
                if (
                    resultFiles._embedded &&
                    resultFiles._embedded.fileUploadEntityList &&
                    resultFiles._embedded.fileUploadEntityList.length
                ) {
                    setFileUploadEntityList(resultFiles._embedded.fileUploadEntityList);
                }
                setHasNextPageFiles(resultFiles.page.hasNext);
                if (continuationTokenFiles.length <= currentPageFiles) {
                    continuationTokenFiles.push(resultFiles.page.continuationToken);
                }
                setContinuationTokenFiles(continuationTokenFiles);
            }
            setLoadingFiles(false);
        };

        setLoadingFiles(true);
        setFileUploadEntityList([]);
        setHasNextPageFiles(false);
        getFileUploadInfo();
    }, [auth, companyUuid, continuationTokenFiles, currentPageFiles, getBankLinkFiles, pageSizeFiles]);

    const handlePageSizeChangedFiles = pageSize => {
        if (pageSize) {
            setCurrentPageFiles(1);
            setPageSizeFiles(parseInt(pageSize));
            setContinuationTokenFiles([null]);
        }
    };

    const handleNextPageFiles = () => {
        if (hasNextPageFiles) {
            setCurrentPageFiles(currentPageFiles + 1);
        }
    };

    const handlePreviousPageFiles = () => {
        if (currentPageFiles !== 1) {
            setCurrentPageFiles(currentPageFiles - 1);
        }
    };

    const handleExpandedChangeFiles = value => {
        setExpandedFiles(value);
    };

    const handleDownloadFile = async item => {
        if (item && item.hubId) {
            let resultFile = await getBankLinkDownloadFile(auth, item.hubId, item.fileType === "XLS");
            if (resultFile) {
                try {
                    if (item.fileType === "CBI") {
                        var download = document.createElement("a");
                        download.setAttribute(
                            "href",
                            "data:application/octet-stream;charset=utf-16le;base64," + window.btoa(resultFile)
                        );
                        download.setAttribute("download", `BankLinK_File_${item.hubId}.txt`);
                        download.click();
                    } else {
                        let blob = new Blob([resultFile], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        saveAs(blob, `BankLinK_File_${item.hubId}.xls`);
                    }
                    message.success(
                        intl.formatMessage({
                            id: "the_file_downloaded_successfully"
                        })
                    );
                } catch (e) {
                    message.error(
                        intl.formatMessage({
                            id: "can_not_download_file"
                        })
                    );
                }
            } else {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        }
    };

    const handleViewFileTransaction = item => {
        history.push(`bank-link/bank-link-files/${item.hubId}`);
    };

    return {
        defaultPageSize,
        pageSizeOptions,
        /// Transactions
        loadingTransactions,
        paymentTransactionEntityList,
        handleNextPageTransactions,
        currentPageTransactions,
        hasNextPageTransactions,
        handlePreviousPageTransactions,
        handlePageSizeChangedTransactions,
        queryTransactions,
        expandedTransactions,
        handleExpandedChangeTransactions,
        handleShowPaymentTransaction,
        /// Files
        loadingFiles,
        fileUploadEntityList,
        handleNextPageFiles,
        currentPageFiles,
        hasNextPageFiles,
        handlePreviousPageFiles,
        handlePageSizeChangedFiles,
        queryFiles,
        expandedFiles,
        handleExpandedChangeFiles,
        handleDownloadFile,
        handleViewFileTransaction
    };
};
