import styled from "styled-components";

export const CompanySignatureContent = styled.div`
    height: 68vh;
    overflow: auto;
`;

export const CompanySignatureSwitch = styled.div`
    text-align: right;
    margin-right: 5px;
    margin-top: -10px;
`;
