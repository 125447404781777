import { fieldRequired, fieldLength } from "@mondora/agyo-validations";
import combineValidators from "@mondora/agyo-validations/lib/utils/combineValidators";

import { addressSchema } from "./address";

export const layerSchema = {
    type: "object",
    properties: {
        ...addressSchema.properties,
        description: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired("Campo obbligatorio"),
                    fieldLength("Il campo deve avere una lunghezza compresa tra 1 e 255 caratteri", 1, 255)
                )(desc)
        },
        govCode: {
            type: "string"
        },
        classifier: {
            type: "string",
            validate: classifier => fieldRequired("Campo obbligatorio")(classifier)
        }
    }
};
