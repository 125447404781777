import { getRestCall } from "../../../utils/rest-api-call";
import { LCA_CCT_PROVISIONING_API_URL } from "../../../config";

export const GET_CCT_PROV_REQUEST_START = "GET_CCT_PROV_REQUEST_START";
export const GET_CCT_PROV_REQUEST_SUCCESS = "GET_CCT_PROV_REQUEST_SUCCESS";
export const GET_CCT_PROV_REQUEST_ERROR = "GET_CCT_PROV_REQUEST_ERROR";

export function getCctProvisioningRequest(auth, filters, page = 0, size = 10) {
    return async dispatch => {
        const urlParams = {
            ...filters,
            page: page,
            itemsPerPage: size
        };

        dispatch({
            type: GET_CCT_PROV_REQUEST_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_CCT_PROV_REQUEST_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_CCT_PROV_REQUEST_ERROR,
                error: e
            });
        }
    };
}

export const GET_ONE_CCT_PROV_REQUEST_START = "GET_ONE_CCT_PROV_REQUEST_START";
export const GET_ONE_CCT_PROV_REQUEST_SUCCESS = "GET_ONE_CCT_PROV_REQUEST_SUCCESS";
export const GET_ONE_GET_CCT_PROV_REQUEST_ERROR = "GET_ONE_GET_CCT_PROV_REQUEST_ERROR";

export function getOneCctProvisioningRequest(auth, idCoupon, filters, page = 0, size = 10) {
    return async dispatch => {
        const urlParams = {
            ...filters,
            page: page,
            itemsPerPage: size
        };

        dispatch({
            type: GET_ONE_CCT_PROV_REQUEST_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_ONE_CCT_PROV_REQUEST_SUCCESS,
                payload: {
                    idCoupon,
                    result
                }
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_ONE_GET_CCT_PROV_REQUEST_ERROR,
                error: e
            });
        }
    };
}

export const GET_CCT_PROV_DETAIL_START = "GET_CCT_PROV_DETAIL_START";
export const GET_CCT_PROV_DETAIL_SUCCESS = "GET_CCT_PROV_DETAIL_SUCCESS";
export const GET_CCT_PROV_DETAIL_ERROR = "GET_CCT_PROV_DETAIL_ERROR";

export function getCctProvisioningDetail(auth, idCoupon) {
    return async dispatch => {
        dispatch({
            type: GET_CCT_PROV_DETAIL_START,
            request: idCoupon
        });

        try {
            const result = await getRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/provisioning-request/${idCoupon}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );
            dispatch({
                type: GET_CCT_PROV_DETAIL_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_CCT_PROV_DETAIL_ERROR,
                error: e
            });
        }
    };
}

export const DOWNLOAD_CCT_PROV_DELEGA_START = "DOWNLOAD_CCT_PROV_DELEGA_START";
export const DOWNLOAD_CCT_PROV_DELEGA_SUCCESS = "DOWNLOAD_CCT_PROV_DELEGA_SUCCESS";
export const DOWNLOAD_CCT_PROV_DELEGA_ERROR = "DOWNLOAD_CCT_PROV_DELEGA_ERROR";

export function downloadDelega(auth, idCedente) {
    return async dispatch => {
        dispatch({
            type: DOWNLOAD_CCT_PROV_DELEGA_START
        });

        try {
            const result = await getRestCall(
                `${LCA_CCT_PROVISIONING_API_URL}/cedente-prestatore/${idCedente}/delega.pdf`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );
            dispatch({
                type: DOWNLOAD_CCT_PROV_DELEGA_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: DOWNLOAD_CCT_PROV_DELEGA_ERROR,
                error: e
            });
        }
    };
}
