import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Upload, Icon, message } from "antd";

export const LogoForm = ({ handleSubmit, formIsLocked, logoUrl }) => {
    const [loading, setLoading] = useState(false);
    const [newLogoUrl, setNewLogoUrl] = useState(logoUrl ? `${logoUrl}?${performance.now()}` : null);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = info => {
        if (info.file.status === "uploading") {
            setLoading(true);
            setNewLogoUrl(null);
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, imageUrl => {
                setNewLogoUrl(imageUrl);
                setLoading(false);
            });
        }

        if (info.file.status === "error") {
            message.error(
                `${info.file.name} caricamento del file non andato buon fine.!${
                    info.file.response ? info.file.response : ""
                }`
            );
            setLoading(false);
        }
    };

    const beforeUpload = file => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Puoi caricare solo file JPG / PNG!");
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            message.error("L'immagine deve essere inferiore a 1 MB!");
        }
        return isJpgOrPng && isLt1M;
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Row>
            <Col span={9}>
                <Upload
                    name="logoUrl"
                    listType="picture-card"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={dummyRequest}
                >
                    {newLogoUrl ? (
                        <img src={newLogoUrl} alt="avatar" style={{ maxHeight: "200px" }} />
                    ) : (
                        <div>
                            <Icon type={loading ? "loading" : "plus"} />
                            <div className="ant-upload-text">Upload</div>
                        </div>
                    )}
                </Upload>
            </Col>
            <Col span={5}>
                <Button
                    disabled={formIsLocked}
                    type="primary"
                    onClick={() => {
                        if (newLogoUrl) {
                            handleSubmit(newLogoUrl);
                        }
                    }}
                >
                    Salva
                </Button>
            </Col>
        </Row>
    );
};

LogoForm.propTypes = {
    formIsLocked: PropTypes.bool.isRequired,
    logoUrl: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired
};
