import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { contains } from "ramda";
import { Tabs, Switch, Select, Typography } from "antd";
import { userIsAuthorized } from "../../../utils/get-roles";
import { OrdersTabs } from "./orders-tabs";

export const CompanyOrders = ({ admin, auth, company, history, getOrders }) => {
    const getExpanded = useExpanded(admin);

    const activeFilter = useFilter();
    const getActivePageSize = usePageNumber("25");
    const getActiveCompany = useCompany(company);
    const getActiveOrder = useOrder(
        auth.loginAuth,
        getOrders,
        getActivePageSize.pageSize,
        activeFilter.filter,
        getActiveCompany.companySelected,
        true
    );

    const passiveFilter = useFilter();
    const getPassivePageSize = usePageNumber("25");
    const getPassiveCompany = useCompany(company);
    const getPassiveOrder = useOrder(
        auth.loginAuth,
        getOrders,
        getPassivePageSize.pageSize,
        passiveFilter.filter,
        getPassiveCompany.companySelected,
        false
    );

    return (
        <div>
            <Tabs
                onChange={value => {
                    history.push(value);
                }}
                tabBarExtraContent={
                    <Switch
                        defaultChecked={false}
                        checked={getExpanded.expanded}
                        checkedChildren={<FormattedMessage id="reduce" />}
                        unCheckedChildren={<FormattedMessage id="expand" />}
                        disabled={getExpanded.disable}
                        onChange={getExpanded.onChange}
                    />
                }
            >
                <Tabs.TabPane tab={<FormattedMessage id="send" />} key="active">
                    <div>
                        <OrdersTabs
                            company={company}
                            loading={getActiveOrder.loading}
                            filter={activeFilter.filter}
                            handleFilterChange={activeFilter.onChange}
                            expanded={getExpanded.expanded}
                            dataSource={getActiveOrder.dataSource}
                            pageSize={getActivePageSize.pageSize}
                            pageSizeOptions={getActivePageSize.pageSizeOptions}
                            handlePageSizeChange={getActivePageSize.onChange}
                            query={getActiveOrder.query}
                            companyOptions={getActiveCompany.companyOptions}
                            companySelected={getActiveCompany.companySelected}
                            handleCompanyChange={getActiveCompany.onChange}
                        />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="receives" />} key="passive">
                    <div>
                        <OrdersTabs
                            company={company}
                            loading={getPassiveOrder.loading}
                            filter={passiveFilter.filter}
                            handleFilterChange={passiveFilter.onChange}
                            expanded={getExpanded.expanded}
                            dataSource={getPassiveOrder.dataSource}
                            pageSize={getPassivePageSize.pageSize}
                            pageSizeOptions={getPassivePageSize.pageSizeOptions}
                            handlePageSizeChange={getPassivePageSize.onChange}
                            query={getPassiveOrder.query}
                            companyOptions={getPassiveCompany.companyOptions}
                            companySelected={getPassiveCompany.companySelected}
                            handleCompanyChange={getPassiveCompany.onChange}
                        />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

CompanyOrders.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    orders: PropTypes.object.isRequired,
    getOrders: PropTypes.func.isRequired
};

/*
 Custom hooks for this component
*/
const useExpanded = admin => {
    const [expanded, setExpanded] = useState(false);
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        let expanded = localStorage.getItem("CompanyOrders:expanded");
        setExpanded(expanded && expanded === "true" ? true : false);
    }, []);

    useEffect(() => {
        let disable = true;
        if (userIsAuthorized("c-companyOrders-switch", admin.adminInfo.permits)) {
            disable = false;
        }
        setDisable(disable);
    }, [admin.adminInfo.permits]);

    const onChange = value => {
        localStorage.setItem("CompanyOrders:expanded", value);
        setExpanded(value);
    };

    return {
        disable,
        expanded,
        onChange
    };
};

const useFilter = () => {
    const [filter, setFilter] = useState(null);

    const onChange = value => {
        setFilter(value);
    };

    return {
        filter,
        onChange
    };
};

const usePageNumber = defaultPageSize => {
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    const onChange = value => {
        setPageSize(value);
    };

    return {
        pageSize,
        pageSizeOptions,
        onChange
    };
};

const useCompany = company => {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [companySelected, setCompanySelected] = useState(null);

    useEffect(() => {
        let arr_layers = [company.info.base.id];
        if (company.info.layers !== null) {
            for (var node of company.info.layers) {
                arr_layers.push(node.base.id);
            }
        }
        arr_layers.sort();
        let allLayers = arr_layers.join(",");
        if (allLayers) {
            let companyOptions = [];
            if (company.info.layers) {
                companyOptions.push(
                    <Select.Option value={allLayers} key={allLayers}>
                        {"Tutti gli uffici"}
                    </Select.Option>
                );
                companyOptions.push(
                    <Select.Option value={company.info.base.id} key={company.info.base.id}>
                        {company.info.base.details.description + " (" + company.info.base.id + ")"}
                    </Select.Option>
                );
                var sortedresult = company.info.layers.sort((a, b) =>
                    a.base.id > b.base.id ? 1 : b.base.id > a.base.id ? -1 : 0
                );
                sortedresult.forEach(layer => {
                    companyOptions.push(
                        <Select.Option value={layer.base.id} key={layer.base.id}>
                            {layer.base.details.description + " (" + layer.base.id + ")"}
                        </Select.Option>
                    );
                });
            } else {
                companyOptions.push(
                    <Select.Option value={allLayers} key={allLayers}>
                        {company.info.base.details.description + " (" + company.info.base.id + ")"}
                    </Select.Option>
                );
            }
            setCompanyOptions(companyOptions);
            setCompanySelected(allLayers);
        }
    }, [company.info]);

    const onChange = value => {
        setCompanySelected(value);
    };

    return {
        companyOptions,
        companySelected,
        onChange
    };
};

const useOrder = (loginAuth, getOrders, page, filters, companySelected, active) => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        const getQuary = filters => {
            let listFilter = [];
            if (filters) {
                let keys = Object.keys(filters);
                for (let key of keys) {
                    if (filters[key] != null && filters[key].length !== "") {
                        if (key === "ownerId") {
                            if (contains(",", companySelected)) {
                                listFilter.push("ownerId=ALL");
                            } else {
                                listFilter.push("ownerId=" + companySelected);
                            }
                        } else {
                            listFilter.push(key + "=" + filters[key]);
                        }
                    }
                }
            }
            listFilter.push("size=" + page);
            let nueva = (
                <Typography.Paragraph code={true} className="c-footer">
                    <b>Current Query:</b> {listFilter.join(" AND ")}
                </Typography.Paragraph>
            );
            setQuery(nueva);
        };

        const getData = async filters => {
            let order = await getOrders(loginAuth, filters, page);
            setLoading(false);
            if (order && order._embedded) {
                setDataSource(order._embedded.orderList);
            } else {
                setDataSource([]);
            }
        };

        if (page && page !== "" && companySelected && companySelected !== "") {
            setLoading(true);
            let newFilters = filters || {};
            newFilters.active = active;
            newFilters.ownerId = companySelected;
            getData(newFilters);
            getQuary(newFilters);
        }
    }, [active, companySelected, filters, getOrders, loginAuth, page]);

    return {
        loading,
        dataSource,
        query
    };
};
