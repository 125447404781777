import React, { Component } from "react";
import PropTypes from "prop-types";

import { Table, Badge, Dropdown, Menu, Modal, Drawer, Input } from "antd";
import UserInfo from "../../user/info";
import { userIsAuthorized } from "../../../utils/get-roles";
const locale = {
    emptyText: "Nessun utente presente"
};

class CompanyUsers extends Component {
    constructor() {
        super();
        this.state = {
            mDelete: false,
            userSelected: null,
            userDrawer: false,
            taxId: null
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        getUser: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        removeRoles: PropTypes.func.isRequired,
        permits: PropTypes.object.isRequired
    };

    handleDeleteRole() {
        const { auth, removeRoles } = this.props;
        const { userSelected } = this.state;
        removeRoles(auth.loginAuth, userSelected.profile.id, userSelected.roles);
        this.setState({ mDelete: false });
    }

    renderAction(userValue) {
        const { permits } = this.props;
        return (
            <Menu>
                <Menu.Item
                    disabled={!userIsAuthorized("c-companyUser-delete-role", permits)}
                    key="1"
                    onClick={() =>
                        this.setState({
                            mDelete: true,
                            userSelected: userValue
                        })
                    }
                >
                    <i className="fas fa-trash" /> Elimina
                </Menu.Item>
            </Menu>
        );
    }

    getFilteredUser(toggleOwnerUser) {
        const {
            company: { users }
        } = this.props;
        const { taxId } = this.state;

        if (users.users && toggleOwnerUser) {
            return users.users.filter(x => x.roles[0].actionKey === "OWNER");
        }
        if (taxId) {
            return users.users.filter(
                x => x.profile.id.toLowerCase().includes(taxId.toLowerCase()) && x.roles[0].actionKey !== "OWNER"
            );
        }
        return users.users && users.users.filter(x => x.roles[0].actionKey !== "OWNER");
    }

    render() {
        const { auth, getUser, history } = this.props;
        const { mDelete, userSelected } = this.state;
        const columns = [
            {
                title: "Stato",
                dataIndex: "status.active",
                align: "center",
                key: "status",
                width: "10%",
                onFilter: (value, record) => record.status.active.toString().includes(value),
                filters: [{ text: "Attivo", value: "true" }, { text: "Disattivo", value: "false" }],
                filterMultiple: false,
                render: (text, record) => (
                    <span>
                        <Badge status={text ? "success" : "error"} />
                        {text ? "Attivo" : "Disattivo"}
                    </span>
                )
            },
            {
                title: "Identificativo",
                width: "30%",
                dataIndex: "profile.id"
            },
            {
                title: "Ruolo",
                width: "10%",
                dataIndex: "roles[0].actionKey",
                onFilter: (value, record) => record.roles[0].actionKey.includes(value),
                filters: [
                    { text: "Lettura", value: "READ" },
                    { text: "Scrittura", value: "WRITE" },
                    { text: "Amministratore", value: "ADMIN" }
                ],
                filterMultiple: false,
                key: "role"
            },
            {
                title: "Descrizione",
                width: "20%",
                dataIndex: "profile.description"
            },
            {
                title: "Azioni",
                width: "10%",
                render: (text, record) => (
                    <div style={{ textAlign: "right" }}>
                        <Dropdown.Button
                            overlay={this.renderAction(record)}
                            trigger={["click"]}
                            placement="bottomRight"
                            onClick={() =>
                                this.setState({
                                    userDrawer: true,
                                    userSelected: record
                                })
                            }
                        >
                            Visualizza
                        </Dropdown.Button>
                    </div>
                )
            }
        ];

        return (
            <div className="c-company-users">
                <p className="a1">Utenti Owner</p>
                <Table
                    rowKey={record => record.profile.id}
                    columns={columns}
                    locale={locale}
                    dataSource={this.getFilteredUser(true)}
                    bordered
                    size="small"
                />
                <p className="a1">Altri utenti</p>
                <Table
                    title={() => (
                        <div>
                            <Input
                                placeholder="Inserisci l'identificativo dell'utente che stai cercando ..."
                                onChange={e =>
                                    this.setState({
                                        taxId: e.target.value
                                    })
                                }
                            />
                        </div>
                    )}
                    rowKey={record => record.profile.id}
                    columns={columns}
                    locale={locale}
                    dataSource={this.getFilteredUser(false)}
                    bordered
                    size="small"
                />
                {userSelected && (
                    <div>
                        <Modal
                            centered
                            okText="Si"
                            cancelText="No"
                            onCancel={() => this.setState({ mDelete: false })}
                            onOk={() => this.handleDeleteRole()}
                            title={"Elimina ruoli a " + userSelected.profile.id}
                            visible={mDelete}
                        >
                            <div>{"Sei sicuro di voler eliminare i ruoli di questo utente sull'azienda?"}</div>
                        </Modal>
                        <Drawer
                            width={550}
                            placement="right"
                            closable={false}
                            onClose={() => this.setState({ userDrawer: false })}
                            visible={this.state.userDrawer}
                        >
                            <div style={{ margin: "20px" }}>
                                <UserInfo
                                    auth={auth}
                                    getUser={getUser}
                                    history={history}
                                    isAdmPage={false}
                                    userInfo={{ ...userSelected }}
                                    onlyInfo={true}
                                />
                            </div>
                        </Drawer>
                    </div>
                )}
            </div>
        );
    }
}

export default CompanyUsers;
