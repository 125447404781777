import React from "react";

import { TS_IH_BO_URL } from "../../config";

export const TSIHBOIframe = ({ token }) => {
    return (
        <iframe
            id="ts_digital_tsih_bo"
            title="TS IntegrationHub Backoffice"
            src={TS_IH_BO_URL + "?access_token=" + token}
            style={{
                width: "95%",
                height: "90%",
                position: "absolute",
                zIndex: "1"
            }}
        />
    );
};

TSIHBOIframe.propTypes = {};
