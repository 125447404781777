import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../components/general/error-boundary";

import Items from "../../components/items";

import { getCompany, findItems, resetGetCompany } from "../../actions/company/info";
import { getCompanyMetering } from "../../actions/company/infoMetering";
import { listUsers } from "../../actions/user/read";
import { fetchActiveServices } from "../../actions/company/services";
import { fetchActiveAgyoServices } from "../../actions/services/list";
import { displayErrorToast } from "../../actions/toast";
import { getNotifications } from "../../actions/company/notifications";
import { getImports, getTotalImports } from "../../actions/usage/lynfa";
import { findOwnManagedConnections, findOwnManagerConnections } from "../../actions/connections/read";

class ItemsView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        connections: PropTypes.object,
        displayErrorToast: PropTypes.func.isRequired,
        fetchActiveAgyoServices: PropTypes.func.isRequired,
        fetchActiveServices: PropTypes.func.isRequired,
        findItems: PropTypes.func.isRequired,
        findOwnManagedConnections: PropTypes.func.isRequired,
        findOwnManagerConnections: PropTypes.func.isRequired,
        getCompany: PropTypes.func.isRequired,
        getCompanyMetering: PropTypes.func.isRequired,
        getNotifications: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        listUsers: PropTypes.func.isRequired,
        resetGetCompany: PropTypes.func.isRequired,
        getImports: PropTypes.func.isRequired,
        getTotalImports: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { auth, fetchActiveAgyoServices } = this.props;
        fetchActiveAgyoServices(auth.loginAuth);
    }

    render() {
        const {
            auth,
            admin,
            company,
            displayErrorToast,
            fetchActiveServices,
            findItems,
            findOwnManagedConnections,
            findOwnManagerConnections,
            getCompany,
            getCompanyMetering,
            getNotifications,
            listUsers,
            history,
            resetGetCompany,
            getImports,
            getTotalImports
        } = this.props;

        return (
            <Items
                auth={auth}
                admin={admin}
                company={company}
                displayErrorToast={displayErrorToast}
                fetchActiveServices={fetchActiveServices}
                findItems={findItems}
                findOwnManagedConnections={findOwnManagedConnections}
                findOwnManagerConnections={findOwnManagerConnections}
                getCompany={getCompany}
                getCompanyMetering={getCompanyMetering}
                getNotifications={getNotifications}
                history={history}
                listUsers={listUsers}
                resetGetCompany={resetGetCompany}
                getImports={getImports}
                getTotalImports={getTotalImports}
            />
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company
});

const actions = {
    displayErrorToast,
    findOwnManagedConnections,
    findOwnManagerConnections,
    listUsers,
    fetchActiveAgyoServices,
    fetchActiveServices,
    findItems,
    getCompany,
    getCompanyMetering,
    getNotifications,
    resetGetCompany,
    getImports,
    getTotalImports
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(ItemsView);
