import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Button } from "antd";
import { compose } from "redux";

import TextField from "@mondora/arc/antd/TextField";
import { form } from "@mondora/conv-redux-form";
import { injectIntl } from "react-intl";

import { boRolesSchema } from "../../../utils/form-schema/bo-roles";

export class BoRolesForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Alert
                    message="Ruolo custom, non verranno applicati controlli sul ruolo inserito."
                    banner
                    style={{ margin: "-24px", marginBottom: "20px" }}
                />

                <TextField placeholder="AppId" name="appId" />
                <TextField placeholder="FeatureCode" name="featureCode" />
                <TextField placeholder="ResourceId" name="resourceId" />
                <TextField placeholder="ActionKey" name="actionKey" />
                <div style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </form>
        );
    }
}

const formDefinition = {
    form: "bo-roles-form",
    schema: boRolesSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(BoRolesForm);
