import styled from "styled-components";

export const ContainerMetadata = styled.div`
    width: calc(100% - 20px);
    padding-right: 10px;
    height: 85%;
    position: absolute;
`;

export const CloseContainer = styled.div`
    margin: 0px -40px;
    position: absolute;
`;

export const DetailContainer = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    width:  ${props => props.isExpanded}
    min-width: 40vw;
    background: white;
    z-index: 100;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.5);
`;

export const ExpandContainer = styled.div`
    margin: 40px -40px;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0px 0px 3px 0 rgba(21, 41, 53, 0.32);
    text-align: center;
    cursor: pointer;
    padding-top: 10px;
    color: #0090d1;
    background: white;
`;

export const ContainerCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const ContainerPDFViewer = styled.div`
    height: 90vh;
    border: 0px;
`;
