import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Statistic, Progress } from "antd";
import { ProgressBarsContainer } from "./styled";

export const Bar = memo(
    ({
        capacity,
        supportsActiveInvoicing,
        supportsPassiveInvoicing,
        fic,
        totalConsumptions,
        activeConsumptions,
        passiveConsumptions,
        showPackageType
    }) => {
        const getPackageType = (supportsActive, supportsPassive, isFic) => {
            if (isFic) {
                return "fic";
            }
            if (supportsActive && supportsPassive) {
                return "attivo/passivo";
            }
            if (supportsActive) {
                return "attivo";
            }
            return "passivo";
        };

        const [packageType, setPackageType] = useState(
            getPackageType(supportsActiveInvoicing, supportsPassiveInvoicing, fic)
        );

        useEffect(() => {
            setPackageType(getPackageType(supportsActiveInvoicing, supportsPassiveInvoicing, fic));
        }, [supportsActiveInvoicing, supportsPassiveInvoicing, fic]);

        return (
            <div>
                <Statistic
                    value={totalConsumptions}
                    suffix={`/${capacity || "∞"} fatture utilizzate${
                        showPackageType && packageType ? ` (pacchetto ${packageType})` : ""
                    }`}
                />
                <ProgressBarsContainer>
                    {supportsActiveInvoicing && (
                        <Progress
                            percent={(activeConsumptions / capacity) * 100}
                            type="line"
                            strokeColor="#f7ac1e"
                            format={() => `${activeConsumptions} consumi SDI`}
                        />
                    )}
                    <br />
                    {supportsPassiveInvoicing && (
                        <Progress
                            percent={(passiveConsumptions / capacity) * 100}
                            successPercent={(passiveConsumptions / capacity) * 100}
                            type="line"
                            format={() => `${passiveConsumptions} consumi eRicevi`}
                        />
                    )}
                </ProgressBarsContainer>
            </div>
        );
    }
);

Bar.propTypes = {
    capacity: PropTypes.number.isRequired,
    totalConsumptions: PropTypes.number.isRequired,
    activeConsumptions: PropTypes.number,
    passiveConsumptions: PropTypes.number,
    supportsActiveInvoicing: PropTypes.bool,
    supportsPassiveInvoicing: PropTypes.bool,
    showPackageType: PropTypes.bool,
    fic: PropTypes.bool
};

Bar.defaultProps = {
    showPackageType: true
};
