import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { FormattedMessage } from "react-intl";

export const InvoicesWebhook = ({ webhookList }) => {
    const columns = [
        {
            title: <FormattedMessage id="app_name" />,
            dataIndex: "appName"
        },
        {
            title: <FormattedMessage id="category" />,
            dataIndex: "category"
        },
        {
            title: <FormattedMessage id="channel" />,
            dataIndex: "mode"
        },
        {
            title: <FormattedMessage id="filters" />,
            dataIndex: "filters",
            render: record => customizeRender(record)
        },
        {
            title: <FormattedMessage id="recipient" />,
            dataIndex: "url"
        },
        {
            title: <FormattedMessage id="created_by" />,
            dataIndex: "createdBy"
        }
    ];

    const customizeRender = filters => {
        var arr_filters = [];
        if (filters) {
            for (var i of filters) {
                arr_filters.push(i.status);
            }
            return arr_filters.join(", ");
        }
    };

    return (
        <div>
            <Table
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={webhookList.webhooksData || []}
                size="middle"
                pagination={false}
            />
        </div>
    );
};

InvoicesWebhook.propTypes = {
    webhookList: PropTypes.object
};
