import { CERVED_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_CERVED_ITEM_START = "GET_CERVED_ITEM_START";
export const GET_CERVED_ITEM_SUCCESS = "GET_CERVED_ITEM_SUCCESS";
export const GET_CERVED_ITEM_ERROR = "GET_CERVED_ITEM_ERROR";

export function getCervedItem(auth, serchText) {
    return async dispatch => {
        const urlParams = {
            authID: auth.id,
            testoricerca: serchText
        };
        dispatch({
            type: GET_CERVED_ITEM_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${CERVED_URL}/cervedApi/v1/entitySearch/live`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_CERVED_ITEM_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_CERVED_ITEM_ERROR,
                error: e
            });
        }
    };
}
