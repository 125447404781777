import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Icon, Button, DatePicker } from "antd";
import moment from "moment";

export const ColumnCalenderProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: "8px" }}>
            <DatePicker.RangePicker
                bordered={false}
                style={{ marginBottom: "8px", display: "block", width: "250px" }}
                value={selectedKeys ? selectedKeys[0] : ""}
                onChange={e => setSelectedKeys(e && e.length > 0 ? [e] : [])}
                disabledDate={current => current > moment().endOf("day")}
                on
            />
            <Button
                type="primary"
                onClick={() => confirm()}
                icon="search"
                size="small"
                style={{ width: "120px", marginRight: "10px" }}
            >
                <FormattedMessage id="search" />
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: "120px" }}>
                <FormattedMessage id="cancel" />
            </Button>
        </div>
    ),
    filterIcon: filtered => <Icon type="calendar" style={{ color: filtered ? "#1890ff" : undefined }} />
});

ColumnCalenderProps.propTypes = {
    dataIndex: PropTypes.string.isRequired
};
