import { CONNECTION_READ_API } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const SEARCH_CONNECTION_START = "SEARCH_CONNECTION_START";
export const SEARCH_CONNECTION_SUCCESS = "SEARCH_CONNECTION_SUCCESS";
export const SEARCH_CONNECTION_ERROR = "SEARCH_CONNECTION_ERROR";

export function searchConnection(auth, id) {
    return async dispatch => {
        return new Promise(async (accept, reject) => {
            dispatch({ type: SEARCH_CONNECTION_START, request: id });

            try {
                const result = await getConnectionRestCall(auth, id);

                dispatch({
                    type: SEARCH_CONNECTION_SUCCESS,
                    payload: result
                });
                accept(result);
            } catch (e) {
                dispatch({
                    type: SEARCH_CONNECTION_ERROR,
                    error: e
                });
                reject(e);
            }
        });
    };
}
export const SEARCH_MANAGER_CONNECTIONS_START = "SEARCH_MANAGER_CONNECTIONS_START";
export const SEARCH_MANAGER_CONNECTIONS_SUCCESS = "SEARCH_MANAGER_CONNECTIONS_SUCCESS";
export const SEARCH_MANAGER_CONNECTIONS_ERROR = "SEARCH_MANAGER_CONNECTIONS_ERROR";

export function searchManagerConnections(auth, filter, params) {
    return async dispatch => {
        const urlParams = {
            ...filter,
            page: params.page,
            size: params.items
        };
        dispatch({
            type: SEARCH_MANAGER_CONNECTIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${CONNECTION_READ_API}/connections/manager`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: SEARCH_MANAGER_CONNECTIONS_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: SEARCH_MANAGER_CONNECTIONS_ERROR,
                error: e
            });
        }
    };
}

export const SEARCH_MANAGED_CONNECTIONS_START = "SEARCH_MANAGED_CONNECTIONS_START";
export const SEARCH_MANAGED_CONNECTIONS_SUCCESS = "SEARCH_MANAGED_CONNECTIONS_SUCCESS";
export const SEARCH_MANAGED_CONNECTIONS_ERROR = "SEARCH_MANAGED_CONNECTIONS_ERROR";

export function searchManagedConnections(auth, filter, params) {
    return async dispatch => {
        const urlParams = {
            ...filter,
            page: params.page,
            size: params.items
        };
        dispatch({
            type: SEARCH_MANAGED_CONNECTIONS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${CONNECTION_READ_API}/connections/managed`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: SEARCH_MANAGED_CONNECTIONS_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: SEARCH_MANAGED_CONNECTIONS_ERROR,
                error: e
            });
        }
    };
}

export async function getConnectionRestCall(auth, id, dispatch) {
    return await getRestCall(`${CONNECTION_READ_API}/connections/${id}`, auth, {}, dispatch, auth.refreshToken);
}
