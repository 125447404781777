import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "antd";

import { RowWithValues } from "./styled";

export const SignatureInfoCCT = ({ document }) => {
    return (
        <div>
            <br></br>
            <br></br>
            <Row>
                <Col span={6}>
                    <FormattedMessage id={"type"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"autoInvioCCT"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoTitolareCodiceFiscale"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoTitolarePartitaIva"} />
                </Col>
            </Row>
            <RowWithValues>
                <Col span={6}>{document.documentTypeDetail.title}</Col>
                <Col span={6}>{document.autoSendCCT ? "Si" : "No"}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoTitolare-CodiceFiscale"]}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoTitolare-PartitaIva"]}</Col>
            </RowWithValues>
            <br></br>
            <Row>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoTitolareIdPaese"} />
                </Col>
                {document.metadata.cct.PeriodoImposta ? (
                    <Col span={6}>
                        <FormattedMessage id={"periodoImposta"} />{" "}
                    </Col>
                ) : (
                    <Col span={6}>
                        <FormattedMessage id={"periodoRiferimento"} />{" "}
                    </Col>
                )}
                <Col span={6}>
                    <FormattedMessage id={"SoggettoTitolareDenominazione"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoTitolareNome"} />
                </Col>
            </Row>
            <RowWithValues>
                <Col span={6}>{document.metadata.cct["SoggettoTitolare-IdPaese"]}</Col>
                <Col span={6}>
                    {document.metadata.cct.PeriodoImposta
                        ? document.metadata.cct.PeriodoImposta
                        : document.metadata.cct.PeriodoRiferimento}
                </Col>
                <Col span={6}>{document.metadata.cct["SoggettoTitolare-Denominazione"]}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoTitolare-Nome"]}</Col>
            </RowWithValues>
            <br></br>
            <Row>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoTitolareCognome"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoFornitoreCodiceFiscale"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoFornitorePartitaIva"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoFornitoreIdPaese"} />
                </Col>
            </Row>
            <RowWithValues>
                <Col span={6}>{document.metadata.cct["SoggettoTitolare-Cognome"]}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoFornitore-CodiceFiscale"]}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoFornitore-PartitaIva"]}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoFornitore-IdPaese"]}</Col>
            </RowWithValues>
            <br></br>
            <Row>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoFornitoreNome"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"SoggettoFornitoreCognome"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"DataTrasmissione"} />
                </Col>
                <Col span={6}>
                    <FormattedMessage id={"DataInizio"} />
                </Col>
            </Row>
            <RowWithValues>
                <Col span={6}>{document.metadata.cct["SoggettoFornitore-Nome"]}</Col>
                <Col span={6}>{document.metadata.cct["SoggettoFornitore-Cognome"]}</Col>
                <Col span={6}>{document.metadata.cct["DataTrasmissione"]}</Col>
                <Col span={6}>{document.metadata.cct["DataInizio"]}</Col>
            </RowWithValues>
            <br></br>
            <Row>
                <Col span={6}>
                    <FormattedMessage id={"DataFine"} />
                </Col>
            </Row>
            <RowWithValues>
                <Col span={6}>{document.metadata.cct["DataFine"]}</Col>
            </RowWithValues>
            <br></br>
        </div>
    );
};

SignatureInfoCCT.propTypes = {
    document: PropTypes.object.isRequired
};
