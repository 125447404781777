import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { isNil } from "ramda";
import { Redirect } from "react-router-dom";

import CompanyCard from "../../../components/general/CompanyCard";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getSpid } from "../../../actions/spid";
import SpidListComponent from "../../../components/spid/list";
import { getSpidSlots } from "../../../actions/spid/slots";

const ItemSpidView = ({ admin, auth, company, getSpid, getSpidSlots, history, spid }) => {
    const [filters, setFilters] = useState({ page: 1, pageSize: 10, itemId: company.info.base.uuid });

    const handleFilter = (filterValue, filterField) => {
        let newFilters = { ...filters };
        newFilters[filterField] = filterValue;

        if (filterField !== "page") {
            newFilters["page"] = 1;
        }

        setFilters(newFilters);
        if (newFilters.itemId && newFilters.itemId !== "") {
            getSpid(auth.loginAuth, newFilters);
        }
    };

    useEffect(() => {
        getSpid(auth.loginAuth, filters);
        getSpidSlots(auth.loginAuth, company.info.base.uuid);
    }, [auth.loginAuth, company.info.base.uuid, getSpid, getSpidSlots, filters]);

    return !isNil(company.info.base) ? (
        <CompanyCard
            company={company}
            content={
                <div>
                    <SpidListComponent
                        admin={admin}
                        history={history}
                        spidUsage={spid.spidUsage.payload}
                        spidList={spid.spidlist.payload && spid.spidlist.payload.content}
                        handleFilter={handleFilter}
                        pagination={{
                            currentPage: filters.page,
                            totalElements: spid.spidlist.payload && spid.spidlist.payload.totalElements
                        }}
                        loading={spid.spidlist.status.started}
                    />
                </div>
            }
            history={history}
        />
    ) : (
        <Redirect to="/item" />
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    spid: state.spid
});

const actions = { getSpid, getSpidSlots };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(ItemSpidView);
