import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../components/general/error-boundary";

import { getTemplate, updateTemplate } from "../../actions/notification/templates";
import { restrictToPermits } from "../../components/general/hoc";
import { GroupTemplateCard, TemplateDrawer } from "../../components/control-pannel-notification";
import { Spin, Icon, Collapse, message } from "antd";
import { FormattedMessage } from "react-intl";
import { ViewTitle, SpinnerContainer } from "./styled";

const ControlPannelNotificationView = ({ admin, auth, getTemplate, updateTemplate, notification }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [templateSelected, setTemplateSelected] = useState(null);
    const [notificationSelected, setNotificationSelected] = useState(null);
    const [notificationAppCode, setNotificationAppCode] = useState([]);
    const [notificationTemplates, setNotificationTemplates] = useState([]);

    useEffect(() => {
        getTemplate(auth.loginAuth);
    }, [auth.loginAuth, getTemplate]);

    useEffect(() => {
        let appCode = [];
        let notificationTemplates = [];
        if (notification.templates.getTemplate.status.ended) {
            notificationTemplates = notification.templates.getTemplate.payload;
            notification.templates.getTemplate.payload.forEach(notification => {
                let findAppCode = appCode.find(x => x === notification.appCode);
                if (findAppCode == null) {
                    appCode.push(notification.appCode);
                }
            });
        }
        setNotificationTemplates(notificationTemplates);
        setNotificationAppCode(appCode);
    }, [notification.templates.getTemplate]);

    const onSave = async () => {
        if (notificationSelected.updatedDate) {
            delete notificationSelected.updatedDate;
        }
        var result = await updateTemplate(auth.loginAuth, notificationSelected);
        if (result != null && result.appCode) {
            message.success("È stato modificato con successo");
        } else {
            message.error("Non è stato modificato con successo");
            getTemplate(auth.loginAuth);
        }
    };

    return !notification.templates.getTemplate.status.started ? (
        <div>
            <ViewTitle>
                <FormattedMessage id="general.admTemplates" />
            </ViewTitle>

            {notificationAppCode.length > 0 ? (
                notificationAppCode.map((appCode, index) => (
                    <GroupTemplateCard
                        key={index}
                        notifications={notificationTemplates.filter(x => x.appCode === appCode)}
                        onChannelClick={(template, index) => {
                            setTemplateSelected(template);
                            setNotificationSelected(notificationTemplates.filter(x => x.appCode === appCode)[index]);
                            setPreviewVisible(true);
                        }}
                        appCode={appCode}
                    />
                ))
            ) : (
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} style={{ marginBottom: "20px" }}>
                    <Collapse.Panel header={"NCS"} key="1">
                        <p style={{ textAlign: "center" }}>Nessun Dato</p>
                    </Collapse.Panel>
                </Collapse>
            )}

            {templateSelected != null ? (
                <TemplateDrawer
                    visible={previewVisible}
                    template={templateSelected}
                    onClose={() => {
                        setPreviewVisible(false);
                        setTemplateSelected(null);
                        setNotificationSelected(null);
                    }}
                    onSave={() => onSave()}
                />
            ) : null}
        </div>
    ) : (
        <SpinnerContainer>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
        </SpinnerContainer>
    );
};

ControlPannelNotificationView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object,
    getTemplate: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    notification: PropTypes.object
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    contract: state.contract,
    notification: state.notification
});

const actions = {
    getTemplate,
    updateTemplate
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-templates-view")
);

export default composedHoc(ControlPannelNotificationView);
