import { getRestCall } from "../utils/rest-api-call";
import { API_URL } from "../config";

export const GET_SPID_START = "GET_SPID_START";
export const GET_SPID_SUCCESS = "GET_SPID_SUCCESS";
export const GET_SPID_ERROR = "GET_SPID_ERROR";
export const GET_SPID_RESET = "GET_SPID_RESET";

export const getSpid = (auth, filters) => async dispatch => {
    dispatch({
        type: GET_SPID_START
    });

    try {
        const queryParams = `itemId=${filters.itemId}${filters.pageSize ? `&size=${filters.pageSize}` : ""}${
            filters.page ? `&page=${filters.page - 1}` : ""
        }${filters.recognition_type ? `&channels=${filters.recognition_type}` : ""}${
            filters.identity_type ? `&identityTypes=${filters.identity_type}` : ""
        }${filters.status ? `&statuses=${filters.status}` : ""}`;

        const result = await getRestCall(`${API_URL}/spid?${queryParams}`, auth, null, dispatch, auth.refreshToken);

        dispatch({
            type: GET_SPID_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_SPID_ERROR,
            error: e
        });
    }
};

export function resetSpid() {
    return {
        type: GET_SPID_RESET
    };
}
