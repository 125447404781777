import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "antd";
import { compose } from "redux";

import TextField from "@mondora/arc/antd/TextField";
import { form } from "@mondora/conv-redux-form";
import { injectIntl } from "react-intl";

import { taxIdSchema } from "../../../utils/form-schema/taxId";

export class TaxIdForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired,
        formIsLocked: PropTypes.bool
    };

    render() {
        const { handleSubmit, formIsLocked } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Row gutter={8}>
                    <Col span={4}>
                        <TextField name="taxRegion" disabled={true} />
                    </Col>
                    <Col span={15}>
                        <TextField name="newTaxId" placeholder="Nuovo codice fiscale" disabled={formIsLocked} />
                    </Col>
                    <Col span={5}>
                        <Button disabled={formIsLocked} type="primary" htmlType="submit" className="adj-button">
                            Salva
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
const formDefinition = {
    asyncFields: ["newTaxId"],
    form: "tax-id-form",
    schema: taxIdSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(TaxIdForm);
