import { isLength, isEmail } from "validator";
import { isNil } from "ramda";

export function usersValidation(values) {
    const errors = {};
    const requiredFields = [
        { fieldName: "firstName", minLength: 1, maxLength: 255 },
        { fieldName: "lastName", minLength: 2, maxLength: 2 },
        { fieldName: "email", minLength: 1, maxLength: 255 },
        { fieldName: "description", minLength: 1, maxLength: 255 }
    ];
    requiredFields.forEach(field => {
        const minLength = field.minLength;

        if (!values[field.fieldName] && minLength > 0) {
            errors[field.fieldName] = "Campo obbligatorio";
        } else if (field.minLength > 0 && field.maxLength > 0) {
            if (!isLength(values[field.fieldName].toString(), minLength)) {
                errors[field.fieldName] = "Formato dato sbagliato";
            }
        }
    });

    if (!isNil(values["email"])) {
        if (!isEmail(values["email"])) {
            errors["email"] = "Email non valida";
        }
    }

    return errors;
}
