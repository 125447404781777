import styled from "styled-components";

export const CenteredContainer = styled.div`
    text-align: center;
`;

export const SpinContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
`;

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;

export const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const InfoCard = styled.div`
    background: white;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 1 1 300px;
`;

export const DataCard = styled.div`
    background: white;
    margin-left: 10px;
    width: 70%;
    min-width: 550px;
    flex: 1 1 70%;
`;
