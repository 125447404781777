import React from "react";
import PropTypes from "prop-types";
import { Badge, Pagination, Switch, Table, Spin, Icon } from "antd";
import { translateNotification } from "../../../utils/translate";

import "./style.css";

export const CompanyNotifications = ({
    loading,
    notificationList,
    handlePageChange,
    currentPage,
    totalItem,
    unreadNotification,
    isPermissionForExpanded,
    expanded,
    handleExpandedChange,
    pageSize
}) => {
    const columns = [
        {
            title: "Data",
            dataIndex: "createdAt",
            render: value => <span>{new Date(Number(value)).toLocaleString()}</span>
        },
        {
            title: "Stato",
            dataIndex: "readStatus",
            render: (value, record) => (
                <div className="c-notification-status">
                    {record.type !== "INFO" && !record.accepted && !record.rejected && (
                        <span className="c-notification-status-badge">
                            {!value && <Badge status="processing" />} {value ? "Letta " : "Non letta "}
                        </span>
                    )}
                    {record.type !== "INFO" && record.accepted && (
                        <div>
                            <Badge status="success" />
                            Accettata
                        </div>
                    )}
                    {record.type !== "INFO" && record.rejected && (
                        <div>
                            <Badge status="error" />
                            Rifiutata
                        </div>
                    )}
                </div>
            )
        },
        {
            title: "Tipologia",
            dataIndex: "name",
            render: value => <span>{translateNotification(value)}</span>
        },
        {
            title: "Azienda",
            dataIndex: "requesterName",
            render: (value, record) => <span>{value + "(" + record.requesterId + ")"}</span>
        }
    ];

    if (loading) {
        return (
            <div
                style={{
                    marginTop: "50%",
                    textAlign: "center"
                }}
            >
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </div>
        );
    }

    return (
        <div className="c-company-notifications">
            <Switch
                className="notification-switch"
                checked={expanded}
                checkedChildren="Riduci"
                unCheckedChildren="Espandi "
                disabled={!isPermissionForExpanded}
                onChange={handleExpandedChange}
            />
            <br />
            <Table
                title={() => (
                    <div className="title-not">
                        {notificationList ? unreadNotification + " notifiche non lette di " + totalItem : ""}
                    </div>
                )}
                rowKey={record => record.id}
                columns={columns}
                dataSource={notificationList}
                expandedRowRender={
                    expanded
                        ? record => (
                              <p>
                                  <b>ResourceId:</b> {record.resourceId}
                              </p>
                          )
                        : null
                }
                locale={{
                    emptyText: "Nessun notifica presente"
                }}
                size="small"
                pagination={false}
                footer={() => (
                    <div className="pagination">
                        <Pagination
                            size="small"
                            current={currentPage + 1}
                            total={totalItem}
                            onChange={handlePageChange}
                            pageSize={pageSize}
                        />
                    </div>
                )}
            />
        </div>
    );
};

CompanyNotifications.propTypes = {
    loading: PropTypes.bool.isRequired,
    notificationList: PropTypes.array.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalItem: PropTypes.number.isRequired,
    unreadNotification: PropTypes.number.isRequired,
    isPermissionForExpanded: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired
};
