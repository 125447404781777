import React from "react";
import PropTypes from "prop-types";

import { CardContainer, Title } from "./styled";
import "./style.css";

//TODO DA SISTEMARE
const TsCard = ({
    actionLink,
    action,
    description,
    displaySuccessMessage,
    disabled,
    icon,
    title,
    content,
    onlyCard,
    withTitle
}) => {
    if (onlyCard) {
        return <CardContainer>{content}</CardContainer>;
    }

    if (withTitle) {
        return (
            <CardContainer className="ts-card">
                <Title>{title}</Title>
                {content}
            </CardContainer>
        );
    }

    return (
        <div className="ts-card">
            <div className="card-container">
                <div className="card-left">
                    <div className="card-title">
                        <i className={icon} /> {title}
                    </div>
                    <div className="card-description">{description}</div>
                </div>
                <div className="card-right">
                    {!disabled && actionLink && (
                        <a className="card-action" onClick={displaySuccessMessage} href={actionLink} download>
                            <i className="fas fa-arrow-right" />
                        </a>
                    )}
                    {!disabled && !actionLink && (
                        <div className="card-action" onClick={action}>
                            <i className="fas fa-arrow-right" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

TsCard.propTypes = {
    action: PropTypes.func,
    description: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    title: PropTypes.any
};

export default TsCard;
