import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ServiceCard from "../../general/ServiceCard";
import { MeteringLynfaCard } from "../../company/metering-lynfa";
import { WaitingListTable } from "./waiting-list-table";
import { Collapse, Icon, Radio } from "antd";
import "./style.css";
import { useFeature } from "../../../utils/hook/use-feature";

export const CompanyServices = ({ auth, company, services, meteringImports, getItemWaitingList, itemWaitingList }) => {
    const [allServices, setAllServices] = useState(null);
    const [companyServices, setCompanyServices] = useState([]);
    const [waitingListServices, setWaitingListServices] = useState([]);
    const [meteringLynfaServices, setMeteringLynfaServices] = useState(null);
    let meteringExtension = useFeature("Metering_Extension");

    useEffect(() => {
        getItemWaitingList(auth.loginAuth, company.info.base.id);
    }, [auth, company.info.base.id, getItemWaitingList]);

    useEffect(() => {
        let currentStatus = localStorage.getItem("services:filtered");
        if (currentStatus !== null) setAllServices(currentStatus);
        else setAllServices("all");
    }, []);

    useEffect(() => {
        if (allServices === "all") {
            setMeteringLynfaServices(meteringImports);
        } else {
            if (meteringImports != null && meteringImports.size > 0) {
                setMeteringLynfaServices(meteringImports);
            } else {
                setMeteringLynfaServices(null);
            }
        }
    }, [allServices, meteringImports]);

    useEffect(() => {
        let waitingListServices = [];
        if (itemWaitingList.status.ended) {
            waitingListServices = itemWaitingList[company.info.base.id];
        }
        setWaitingListServices(waitingListServices);
    }, [company.info.base.id, itemWaitingList]);

    const handleChangeAllServices = value => {
        localStorage.setItem("services:filtered", value);
        setAllServices(value);
    };

    useEffect(() => {
        let companyServices = [];
        if (services.list.services && company.active.services) {
            let serviceSorted = services.list.services.sort((a, b) => {
                if (a.appId === b.appId) return 0;
                return a.appId > b.appId ? 1 : -1;
            });
            serviceSorted.forEach(services => {
                if (allServices === "all") {
                    companyServices.push(services);
                } else if (allServices === "active") {
                    let serviceFind = company.active.services.find(
                        itemService => !itemService.readonly && itemService.agyoService.id === services.id
                    );
                    if (serviceFind != null) {
                        companyServices.push(services);
                    }
                } else if (allServices === "readOnly") {
                    let serviceFind = company.active.services.find(
                        itemService => itemService.readonly && itemService.agyoService.id === services.id
                    );
                    if (serviceFind != null) {
                        companyServices.push(services);
                    }
                } else if (allServices === "disactive") {
                    if (!company.active.services.find(itemService => itemService.agyoService.id === services.id))
                        companyServices.push(services);
                }
            });
        }

        setCompanyServices(companyServices);
    }, [company.active.services, services.list.services, allServices, company]);

    return (
        <div className="c-company-services">
            <div className="service-category">
                <Collapse
                    defaultActiveKey={["1", "2", "3"]}
                    bordered={false}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                    style={{ width: "-webkit-fill-available", marginTop: "10px" }}
                >
                    <Collapse.Panel
                        header={<FormattedMessage id="c-companyservices.companyservice" />}
                        key="1"
                        className="service-panel"
                    >
                        <div style={{ textAlign: "center" }}>
                            <Radio.Group
                                defaultValue={allServices}
                                value={allServices}
                                buttonStyle="solid"
                                size="large"
                                onChange={e => handleChangeAllServices(e.target.value)}
                            >
                                <Radio.Button value="active">
                                    <FormattedMessage id="c-companyservices.active" />
                                </Radio.Button>
                                <Radio.Button value="readOnly">
                                    <FormattedMessage id="c-companyservices.readonly" />
                                </Radio.Button>
                                <Radio.Button value="disactive">
                                    <FormattedMessage id="c-companyservices.disactive" />
                                </Radio.Button>
                                <Radio.Button value="all">
                                    <FormattedMessage id="c-companyservices.all" />
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="service-category">
                            {meteringLynfaServices != null ? (
                                <MeteringLynfaCard
                                    id={"IMPORT-FIRST-NOTE"}
                                    background={"#e6f7ff"}
                                    color={"#00bcd4"}
                                    icon={"fas fa-archive"}
                                    title={<FormattedMessage id="c-companyservices.importfirstnote" />}
                                    description={<FormattedMessage id="c-companyservices.virtualservice" />}
                                    status={
                                        meteringLynfaServices != null && meteringLynfaServices.size > 0 ? true : false
                                    }
                                    key={"IMPORT-FIRST-NOTE"}
                                />
                            ) : null}
                            {companyServices != null && companyServices.length > 0 ? (
                                companyServices.map(service => (
                                    <ServiceCard
                                        agyoService={service}
                                        companyService={
                                            company.active.services
                                                ? company.active.services.find(
                                                      itemService => itemService.agyoService.id === service.id
                                                  )
                                                : false
                                        }
                                        meteringExtension={meteringExtension}
                                        key={service.id}
                                    />
                                ))
                            ) : (
                                <div className="service-panel-empty">
                                    {<FormattedMessage id="c-companyservices.emptyservice" />}
                                </div>
                            )}
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel header={<FormattedMessage id="waiting_list" />} key="3" className="service-panel">
                        <div>
                            <WaitingListTable
                                dataSource={waitingListServices || []}
                                loading={itemWaitingList.status.started}
                            />
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        </div>
    );
};

CompanyServices.propTypes = {
    auth: PropTypes.object,
    company: PropTypes.object,
    services: PropTypes.object,
    meteringImports: PropTypes.object.isRequired,
    getItemWaitingList: PropTypes.func.isRequired,
    itemWaitingList: PropTypes.object.isRequired
};
