import React from "react";
import { Alert } from "antd";
import { CustomLi } from "../styled";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ActionResult = ({ renderError, renderSuccess, path, messageSuccess, messageError, history }) => {
    if (renderSuccess) {
        return (
            <Alert
                banner
                message={
                    <div>
                        {messageSuccess} <br />
                        <span>Come vuoi procedere?</span>
                        <ul>
                            {path ? (
                                <CustomLi>
                                    <Link to={`/certifications/list/${path}`}>
                                        Vai ai contratti {path === "certified" ? "certificati" : "rigettati"}
                                    </Link>
                                </CustomLi>
                            ) : null}
                            <CustomLi onClick={() => history.goBack()}>Torna indietro alla lista</CustomLi>
                        </ul>
                    </div>
                }
                type="success"
                showIcon
            />
        );
    }

    if (renderError) {
        return <Alert banner message={messageError} type="error" showIcon />;
    }

    return null;
};
ActionResult.propTypes = {
    renderError: PropTypes.bool,
    renderSuccess: PropTypes.bool,
    path: PropTypes.string,
    messageSuccess: PropTypes.object,
    messageError: PropTypes.object,
    history: PropTypes.object
};
export default ActionResult;
