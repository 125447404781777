import React, { useState } from "react";
import PropTypes from "prop-types";
import PDFViewer from "pdf-viewer-reactjs";
import { FormattedMessage } from "react-intl";
import { Spin, Icon, Button, Tooltip } from "antd";

import { CenteredDiv, Content } from "./styled";

export const PdfHandler = React.memo(({ content, loading, fileName }) => {
    const viewModel = usePdfHandler(content, loading, fileName);

    if (viewModel.loading) {
        return (
            <CenteredDiv>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </CenteredDiv>
        );
    }

    if (!viewModel.content) {
        return <CenteredDiv>Documento non trovato.</CenteredDiv>;
    }

    return (
        <div>
            <Content>
                <div style={{ textAlign: "right" }}>
                    <Tooltip title={<FormattedMessage id="rotation" />}>
                        <Button
                            disabled={viewModel.isDisabledRotationLeftButton}
                            onClick={viewModel.handleRotationLeft}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fas fa-undo" />
                        </Button>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="rotation" />}>
                        <Button
                            disabled={viewModel.isDisabledRotationRightButton}
                            onClick={viewModel.handleRotationRight}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fas fa-redo" />
                        </Button>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="zoom_out" />}>
                        <Button
                            disabled={viewModel.isDisabledZoomOutButton}
                            onClick={viewModel.handleZoomOut}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fas fa-search-minus" />
                        </Button>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="zoom_in" />}>
                        <Button
                            disabled={viewModel.isDisabledZoomInButton}
                            onClick={viewModel.handleZoomIn}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fas fa-search-plus" />
                        </Button>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="previous" />}>
                        <Button
                            disabled={viewModel.isDisabledPreviousButton}
                            onClick={viewModel.handlePreviousPageNumber}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fas fa-angle-left" />
                        </Button>
                    </Tooltip>

                    <span style={{ marginLeft: 10 }}>{`${viewModel.pageNumber}/${viewModel.maxPageNumber}`}</span>

                    <Tooltip title={<FormattedMessage id="next" />}>
                        <Button
                            disabled={viewModel.isDisabledNextButton}
                            onClick={viewModel.handleNextPageNumber}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fas fa-angle-right" />
                        </Button>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="download" />}>
                        <Button onClick={viewModel.handleDownloadPdf} style={{ marginLeft: 10 }}>
                            <i className="fas fa-download" />
                        </Button>
                    </Tooltip>
                </div>
            </Content>
            <div style={{ overflow: "auto", height: "74vh" }}>
                <PDFViewer
                    document={{
                        base64: viewModel.content
                    }}
                    externalInput
                    scale={viewModel.scale}
                    minScale={viewModel.minScale}
                    maxScale={viewModel.maxScale}
                    page={viewModel.pageNumber}
                    rotationAngle={viewModel.rotation}
                    getMaxPageCount={viewModel.handleMaxPageNumberChanged}
                />
            </div>
        </div>
    );
});

PdfHandler.propTypes = {
    loading: PropTypes.bool,
    content: PropTypes.any,
    fileName: PropTypes.string.isRequired
};

const usePdfHandler = (content, loading, fileName) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPageNumber, setMaxPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);

    let maxScale = 3.0;
    let minScale = 0.5;
    let scaleValue = 0.25;

    const handleMaxPageNumberChanged = value => {
        setTimeout(() => {
            setMaxPageNumber(value);
        }, 1000);
    };

    const handleNextPageNumber = () => {
        setPageNumber(pageNumber + 1);
    };

    const handlePreviousPageNumber = () => {
        setPageNumber(pageNumber - 1);
    };

    const handleZoomIn = () => {
        setScale(scale + scaleValue);
    };

    const handleZoomOut = () => {
        setScale(scale - scaleValue);
    };

    const handleRotationRight = () => {
        setRotation(rotation + 90);
    };

    const handleRotationLeft = () => {
        setRotation(rotation - 90);
    };

    const handleDownloadPdf = () => {
        let element = document.createElement("a");
        element.setAttribute("href", `data:application/pdf;base64,${content}`);
        element.setAttribute("target", "_blank");
        element.setAttribute("download", fileName);
        document.body.appendChild(element);
        element.click();
    };

    return {
        content,
        loading,
        scale,
        pageNumber,
        maxScale,
        minScale,
        rotation,
        maxPageNumber,
        handleMaxPageNumberChanged,
        handleNextPageNumber,
        handlePreviousPageNumber,
        isDisabledNextButton: pageNumber === maxPageNumber,
        isDisabledPreviousButton: pageNumber === 1,
        handleZoomIn,
        handleZoomOut,
        isDisabledZoomInButton: scale === maxScale,
        isDisabledZoomOutButton: scale === minScale,
        handleRotationRight,
        handleRotationLeft,
        isDisabledRotationRightButton: rotation > 90,
        isDisabledRotationLeftButton: rotation < -90,
        handleDownloadPdf
    };
};
