import React from "react";
import { Modal } from "antd";

import { FormattedMessage } from "react-intl";

export const DisablePackageModal = ({ disablePackageModal, onCancel, handleDisablePackage, loading }) => {
    const disablePackage = () => {
        handleDisablePackage();
    };
    return (
        <Modal
            visible={disablePackageModal}
            title={<FormattedMessage id="c-metering-action.disable-service" />}
            okText={<FormattedMessage id="general.confirm" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onCancel={onCancel}
            onOk={() => {
                disablePackage();
            }}
            okButtonProps={{
                loading: loading
            }}
        >
            <div>{<FormattedMessage id="c-meteringtable.disable-service" />}</div>
        </Modal>
    );
};
