import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Row, Col, Steps, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dateFormat from "dateformat";
import { translateSignatureType, translateListCctStatus, translateListStatus } from "../../../utils/translate";
import { faEye, faCircle } from "@fortawesome/pro-regular-svg-icons";
import {
    ActionContainer,
    ActionEnable,
    ActionDisabled,
    EventTitle,
    RowWithValues,
    StepIcon,
    EventList
} from "./styled";

export const SignatureInfoData = ({ document, handlePreview, handleCctSend, isPermittedForCctSend, appNameDesc }) => {
    const [nameText, setNameText] = useState(<FormattedMessage id={"not_available"} />);
    const [owner, setOwner] = useState(<FormattedMessage id={"not_available"} />);
    useEffect(() => {
        let nameText = <FormattedMessage id={"not_available"} />;
        if (document && document.fileName) {
            if (document.fileName.length > 30) {
                nameText = document.fileName.substr(0, 30) + "-";
                let i = 0;
                do {
                    i += 30;
                    nameText += document.fileName.substr(i, 30) + "-";
                } while (i <= document.fileName.length);
                nameText = nameText.substr(0, nameText.length - 2);
            } else nameText = document.fileName;
        }
        let owner =
            document.owner.kind === "person"
                ? document.owner.firstName + " " + document.owner.lastName
                : document.owner.companyName;
        setNameText(nameText);
        setOwner(owner);
    }, [document]);
    return (
        <div>
            <ActionContainer>
                {isPermittedForCctSend && (
                    <Button style={{ marginRight: 15 }} onClick={() => handleCctSend(document.hubId)}>
                        <FormattedMessage id={"resend_cct"} />
                    </Button>
                )}
                {document.originalStorageRef || document.signedStorageRef ? (
                    <ActionEnable onClick={handlePreview}>
                        <FontAwesomeIcon icon={faEye} />
                        <FormattedMessage id={"preview"} />
                    </ActionEnable>
                ) : (
                    <ActionDisabled>
                        <FontAwesomeIcon icon={faEye} />
                        <FormattedMessage id={"preview"} />
                    </ActionDisabled>
                )}
            </ActionContainer>
            <div>
                <Row>
                    <Col span={6}>
                        <FormattedMessage id={"name"} />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id={"type"} />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id={"signature_type"} />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id={"document_status"} />
                    </Col>
                </Row>
                <RowWithValues>
                    <Col span={6}>{nameText}</Col>
                    <Col span={6}>{document.documentTypeDetail.title}</Col>
                    <Col span={6}>
                        {document.signatureProcessName
                            ? document.signatureProcessName
                            : translateSignatureType(document.signatureLabel)}
                    </Col>
                    <Col span={6}>{document.documentStatus.title}</Col>
                </RowWithValues>
                <br />
                <Row>
                    <Col span={6}>
                        <FormattedMessage id="cct_status" />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id="company_or_private" />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id="intermediary" />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id="hubId" />
                    </Col>
                </Row>
                <RowWithValues>
                    <Col span={6}>
                        {document.extStatus ? (
                            translateListCctStatus(document.extStatus)
                        ) : (
                            <FormattedMessage id={"not_available"} />
                        )}
                    </Col>
                    <Col span={6}>{owner}</Col>
                    <Col span={6}>{document.transmitterId}</Col>
                    <Col span={6}>{document.hubId}</Col>
                </RowWithValues>
                <br />
                <Row>
                    <Col span={6}>
                        <FormattedMessage id="year" />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id="deadline" />
                    </Col>
                    {document.idSignData && document.idSignData.jobId && (
                        <Col span={6}>
                            <FormattedMessage id="jobId" />
                        </Col>
                    )}
                    <Col span={6}>
                        <FormattedMessage id="mainSigner" />
                    </Col>
                </Row>
                <RowWithValues>
                    <Col span={6}>{document.year}</Col>
                    <Col span={6}>{dateFormat(document.expiryDate, "dd/mm/yyyy")}</Col>
                    {document.idSignData && document.idSignData.jobId && (
                        <Col span={6}>{document.idSignData.jobId}</Col>
                    )}
                    <Col span={6}>{document.mainSignerId}</Col>
                </RowWithValues>
                <br />
                <Row>
                    <Col span={6}>
                        <FormattedMessage id="appName" />
                    </Col>
                    <Col span={6}>
                        <FormattedMessage id="sendSignedDocumentToMail" />
                    </Col>

                    <Col span={12}>
                        <FormattedMessage id="signUrl" />
                    </Col>
                </Row>
                <RowWithValues>
                    <Col span={6}>
                        {typeof appNameDesc.description === "string" ? appNameDesc.description : document.appName}
                    </Col>
                    <Col span={6}>{document.sendSignedDocumentToMail ? "Si" : "No"}</Col>
                    <Col span={10}>
                        {document.signUrl}{" "}
                        <span className="copy-token-icon">
                            <CopyToClipboard text={document.signUrl}>
                                <i className="far fa-copy" />
                            </CopyToClipboard>
                        </span>
                    </Col>
                </RowWithValues>
                <br />
            </div>
            <Row>
                <Col span={6}>
                    <EventTitle>
                        <FormattedMessage id="events" />
                    </EventTitle>
                    <EventList>
                        {document.statusHistory && document.statusHistory.length > 0 ? (
                            <Steps direction="vertical" size="small">
                                {document.statusHistory.map(val => (
                                    <Steps.Step
                                        title={translateListStatus(val)}
                                        key={val.statusCode}
                                        description={dateFormat(val.creationDate, "dd/mm/yyyy H:MM")}
                                        status="finish"
                                        icon={<StepIcon icon={faCircle} />}
                                    />
                                ))}
                            </Steps>
                        ) : (
                            <span>
                                <FormattedMessage id="no_event" />
                            </span>
                        )}
                    </EventList>
                </Col>
                <Col span={6}>
                    <EventTitle>
                        <FormattedMessage id="cct_events" />
                    </EventTitle>
                    <EventList>
                        {document.extStatusHistory && document.extStatusHistory.length > 0 ? (
                            <Steps direction="vertical" size="small">
                                {document.extStatusHistory.map((val, index) => (
                                    <Steps.Step
                                        title={translateListCctStatus(val)}
                                        key={val.statusCode + index}
                                        description={dateFormat(val.creationDate, "dd/mm/yyyy H:MM")}
                                        status="finish"
                                        icon={<StepIcon icon={faCircle} />}
                                    />
                                ))}
                            </Steps>
                        ) : (
                            <span>
                                <FormattedMessage id="no_event" />
                            </span>
                        )}
                    </EventList>
                </Col>
            </Row>
        </div>
    );
};

SignatureInfoData.propTypes = {
    displaySuccessToast: PropTypes.func,
    document: PropTypes.object.isRequired,
    handlePreview: PropTypes.func.isRequired,
    handleCctSend: PropTypes.func.isRequired,
    isPermittedForCctSend: PropTypes.bool.isRequired
};
