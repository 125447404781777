import { fieldEqual, passwordStrength } from "@mondora/agyo-validations";

export const passwordSchema = {
    type: "object",
    properties: {
        password: {
            type: "string",
            validate: password => passwordStrength("La password non è abbastanza complessa")(password)
        },
        confirmPassword: {
            type: "string",
            validate: (confirmPassword, formValues) =>
                fieldEqual("Le password inserite non combaciano", "password")(confirmPassword, formValues)
        }
    }
};
