import { API_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_TS_SPID_ID_START = "GET_TS_SPID_ID_START";
export const GET_TS_SPID_ID_SUCCESS = "GET_TS_SPID_ID_SUCCESS";
export const GET_TS_SPID_ID_ERROR = "GET_TS_SPID_ID_ERROR";

export const getSingleSpid = (auth, spidId) => async dispatch => {
    dispatch({
        type: GET_TS_SPID_ID_START
    });

    try {
        const response = await getRestCall(`${API_URL}/spid/${spidId}`, auth, null, dispatch, auth.refreshToken);

        dispatch({
            type: GET_TS_SPID_ID_SUCCESS,
            payload: response
        });
        return response;
    } catch (err) {
        dispatch({ type: GET_TS_SPID_ID_ERROR, error: err });
    }
};
