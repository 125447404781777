import { REGISTRY_BO_READ_URL, REGISTRY_READ_URL } from "../../config";
import { normalizeItem } from "../../utils/item-utils";
import { getRestCall } from "../../utils/rest-api-call";
import { isEmpty, isNil } from "ramda";

export const SEARCH_COMPANY_START = "SEARCH_COMPANY_START";
export const SEARCH_COMPANY_SUCCESS = "SEARCH_COMPANY_SUCCESS";
export const SEARCH_COMPANY_ERROR = "SEARCH_COMPANY_ERROR";

export function searchCompany(auth, itemId, packageType = "BASE", boUrl = true) {
    return async dispatch => {
        dispatch({
            type: SEARCH_COMPANY_START
        });
        const urlParams = {
            packageType: packageType
        };

        if (itemId) {
            let url = REGISTRY_READ_URL;

            if (boUrl) {
                url = REGISTRY_BO_READ_URL;
            }
            try {
                const result = await getRestCall(
                    `${url}/items/${itemId}`,
                    auth,
                    urlParams,
                    dispatch,
                    auth.refreshToken
                );
                let resultNormalizeItem = normalizeItem(result);
                dispatch({
                    type: SEARCH_COMPANY_SUCCESS,
                    payload: resultNormalizeItem
                });
                return resultNormalizeItem;
            } catch (e) {
                dispatch({
                    type: SEARCH_COMPANY_ERROR,
                    error: e
                });
            }
        } else {
            dispatch({
                type: SEARCH_COMPANY_ERROR,
                error: "searchCompany - Bad Request: itemId is empty"
            });
        }
    };
}
export const SEARCH_ITEMS_START = "SEARCH_ITEMS_START";
export const SEARCH_ITEMS_SUCCESS = "SEARCH_ITEMS_SUCCESS";
export const SEARCH_ITEMS_ERROR = "SEARCH_ITEMS_ERROR";

export function searchItems(auth, ids, fullText, itemsPerPage = 10, boUrl = true, packageType = "BASE") {
    return async dispatch => {
        dispatch({
            type: SEARCH_ITEMS_START
        });

        if (!isNil(ids) && !isEmpty(ids)) {
            let filter = fullText ? { "fullText.keyWord": ids } : { idList: ids };
            const urlParams = {
                packageType: packageType,
                "pagination.itemsPerPage": itemsPerPage,
                "pagination.pageNumber": 0,
                ...filter
            };
            try {
                const result = await getRestCall(
                    `${boUrl ? REGISTRY_BO_READ_URL : REGISTRY_READ_URL}/items`,
                    auth,
                    urlParams,
                    dispatch,
                    auth.refreshToken
                );
                dispatch({
                    type: SEARCH_ITEMS_SUCCESS,
                    payload: result
                });
                return result;
            } catch (e) {
                dispatch({
                    type: SEARCH_ITEMS_ERROR,
                    error: e
                });
            }
        } else {
            dispatch({
                type: SEARCH_ITEMS_ERROR,
                error: "searchItems - Bad request"
            });
        }
    };
}
