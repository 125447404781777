import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";

import { CompanyInvoices } from "../../../components/company/invoices";
import { setDocument } from "../../../actions/document/info";
import { getInvoices, getInvoice } from "../../../actions/invoice/read";
import { trashInvoice, untrashInvoice, deleteInvoice } from "../../../actions/invoice/write";
import { searchCompany } from "../../../actions/search/company";
import { findOwnManagedConnections } from "../../../actions/connections/read";
import { getInvoiceUserConfig } from "../../../actions/search/invoice-user-config";
import {
    getInvoicingDetails,
    getInvoicingStatistics,
    getInvoicingUsage,
    postInvoiceConservation
} from "../../../actions/usage/invoices";
import { getUsagePerms } from "../../../actions/usage/permission";
import {
    getUnassignedInvoices,
    getNotAssignabledInvoices,
    postUnassignedInvoices,
    postNotAssignabledInvoices
} from "../../../actions/company/unassigned-invoices";
import { displayErrorToast } from "../../../actions/toast";
import { getWebhook } from "../../../actions/company/webhook";
import { serviceDecode } from "../../../utils/decode-services";
import { Avatar } from "antd";
import { Container, TitleContainer, TitleAction } from "./styled";

const CompanyInvoicesView = ({
    admin,
    auth,
    company,
    document,
    filterDocumets,
    getUsagePerms,
    getInvoices,
    getInvoiceUserConfig,
    deleteInvoice,
    trashInvoice,
    untrashInvoice,
    getInvoice,
    getInvoicingDetails,
    getInvoicingStatistics,
    getUnassignedInvoices,
    postUnassignedInvoices,
    postNotAssignabledInvoices,
    history,
    invoices,
    searchCompany,
    findOwnManagedConnections,
    setDocument,
    usage,
    getWebhook,
    postInvoiceConservation,
    invoiceConservation
}) => {
    const [invoiceUserConfig, setInvoiceUserConfig] = useState(null);

    useEffect(() => {
        const getConfigs = async () => {
            const invoiceConfig = await getInvoiceUserConfig(auth.loginAuth, company.info.base.id);

            if (invoiceConfig) {
                setInvoiceUserConfig(invoiceConfig.users);
            }
        };
        getConfigs();
    }, [auth.loginAuth, getInvoiceUserConfig, company.info.base.id]);
    useEffect(() => {
        if (company.info.base != null) {
            getInvoicingDetails(auth.loginAuth, company.info.base.id);
            getInvoicingStatistics(auth.loginAuth, company.info.base.id, null, null, company.info.base.id);
            getInvoicingUsage(auth.loginAuth, company.info.base.id);
            getUsagePerms(auth.loginAuth, company.info.base.id);
            getUnassignedInvoices(
                auth.loginAuth,
                company.info.base.identifier.taxId,
                company.info.base.identifier.vatNumber,
                company.info.base.identifier.taxRegion
            );
            if (company.info.base.id !== company.info.base.identifier.vatNumber) {
                getNotAssignabledInvoices(
                    auth.loginAuth,
                    company.info.base.identifier.taxId,
                    company.info.base.identifier.vatNumber,
                    company.info.base.identifier.taxRegion
                );
            }
            getWebhook(auth.loginAuth, company.info.base.id, { page: 0 });
            getInvoiceUserConfig(auth.loginAuth, company.info.base.id);
        }
    }, [
        auth.loginAuth,
        company.info.base,
        getInvoicingDetails,
        getInvoiceUserConfig,
        getInvoicingStatistics,
        getUnassignedInvoices,
        getUsagePerms,
        getWebhook
    ]);

    return (
        <Container>
            <TitleContainer>
                <div>
                    <Avatar
                        style={{
                            background: serviceDecode("EIP").background,
                            color: serviceDecode("EIP").color,
                            border: "1px solid" + serviceDecode("EIP").color,
                            marginRight: "8px"
                        }}
                    >
                        <i className={serviceDecode("EIP").icon} />
                    </Avatar>
                    <span className="a1">{serviceDecode("EIP").title}</span>
                    <span className="a1">{" (" + serviceDecode("EIP").title + ")"}</span>
                </div>
                <TitleAction onClick={() => history.push(`/item/${company.info.base.uuid}/dashboard`)}>
                    <i className="far fa-times-circle" />
                </TitleAction>
            </TitleContainer>
            <div style={{ marginTop: "20px" }}>
                <CompanyInvoices
                    admin={admin}
                    auth={auth}
                    company={company}
                    getInvoices={getInvoices}
                    getInvoice={getInvoice}
                    getInvoiceUserConfig={getInvoiceUserConfig}
                    trashInvoice={trashInvoice}
                    deleteInvoice={deleteInvoice}
                    untrashInvoice={untrashInvoice}
                    getInvoicingDetails={getInvoicingDetails}
                    getInvoicingStatistics={getInvoicingStatistics}
                    getUnassignedInvoices={getUnassignedInvoices}
                    postUnassignedInvoices={postUnassignedInvoices}
                    postNotAssignabledInvoices={postNotAssignabledInvoices}
                    history={history}
                    invoices={invoices}
                    findOwnManagedConnections={findOwnManagedConnections}
                    invoicesConfigList={invoiceUserConfig}
                    setDocument={setDocument}
                    usage={usage}
                    webhookList={company.notifications.webhookList}
                    postInvoiceConservation={postInvoiceConservation}
                    invoiceConservation={invoiceConservation}
                />
            </div>
        </Container>
    );
};

CompanyInvoicesView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    document: PropTypes.object,
    getInvoices: PropTypes.func.isRequired,
    getInvoice: PropTypes.func.isRequired,
    getInvoiceUserConfig: PropTypes.func.isRequired,
    trashInvoice: PropTypes.func.isRequired,
    deleteInvoice: PropTypes.func.isRequired,
    untrashInvoice: PropTypes.func.isRequired,
    getInvoicingDetails: PropTypes.func.isRequired,
    getInvoicingStatistics: PropTypes.func.isRequired,
    getInvoicingUsage: PropTypes.func.isRequired,
    getNotAssignabledInvoices: PropTypes.func.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    getUnassignedInvoices: PropTypes.func.isRequired,
    postNotAssignabledInvoices: PropTypes.func.isRequired,
    getUsagePerms: PropTypes.func.isRequired,
    postUnassignedInvoices: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    usage: PropTypes.object.isRequired,
    getWebhook: PropTypes.func.isRequired,
    postInvoiceConservation: PropTypes.func.isRequired,
    invoiceConservation: PropTypes.object
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    document: state.document,
    filterDocumets: state.form.filterDocumets,
    invoices: state.invoice.read.invoices,
    usage: state.usage,
    invoiceConservation: state.usage.postInvoiceConservation
});

const actions = {
    getInvoices,
    getInvoice,
    getInvoiceUserConfig,
    trashInvoice,
    deleteInvoice,
    untrashInvoice,
    getInvoicingDetails,
    getInvoicingStatistics,
    getInvoicingUsage,
    getNotAssignabledInvoices,
    getUnassignedInvoices,
    postNotAssignabledInvoices,
    getUsagePerms,
    postUnassignedInvoices,
    searchCompany,
    setDocument,
    findOwnManagedConnections,
    displayErrorToast,
    getWebhook,
    postInvoiceConservation
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

export default composedHoc(CompanyInvoicesView);
