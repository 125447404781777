import { METERING_USAGE_INVOICING, INVOICES_URL } from "../../config";
import { getRestCall, postRestCall } from "../../utils/rest-api-call";

export const GET_INVOICING_USAGE_START = "GET_INVOICING_USAGE_START";
export const GET_INVOICING_USAGE_SUCCESS = "GET_INVOICING_USAGE_SUCCESS";
export const GET_INVOICING_USAGE_ERROR = "GET_INVOICING_USAGE_ERROR";

export function getInvoicingUsage(auth, itemId) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id
        };
        dispatch({
            type: GET_INVOICING_USAGE_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${METERING_USAGE_INVOICING}/invoicing/${itemId}/subscriptions`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICING_USAGE_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICING_USAGE_ERROR,
                error: e
            });
        }
    };
}

export const GET_INVOICING_DETAILS_START = "GET_INVOICING_DETAILS_START";
export const GET_INVOICING_DETAILS_SUCCESS = "GET_INVOICING_DETAILS_SUCCESS";
export const GET_INVOICING_DETAILS_ERROR = "GET_INVOICING_DETAILS_ERROR";

export function getInvoicingDetails(auth, itemId, from, to, managed) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id,
            page: 0,
            itemsPerPage: 2000,
            from: from,
            to: to,
            managed: managed
        };
        dispatch({
            type: GET_INVOICING_DETAILS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${METERING_USAGE_INVOICING}/invoicing/${itemId}/details`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICING_DETAILS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICING_DETAILS_ERROR,
                error: e
            });
        }
    };
}

export const GET_INVOICING_STATISTICS_START = "GET_INVOICING_STATISTICS_START";
export const GET_INVOICING_STATISTICS_SUCCESS = "GET_INVOICING_STATISTICS_SUCCESS";
export const GET_INVOICING_STATISTICS_ERROR = "GET_INVOICING_STATISTICS_ERROR";

export function getInvoicingStatistics(auth, itemId, from, to, managed) {
    return async dispatch => {
        const urlParams = {
            userId: auth.id,
            page: 0,
            itemsPerPage: 10,
            from: from,
            to: to,
            filteredItem: managed
        };
        dispatch({
            type: GET_INVOICING_STATISTICS_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${METERING_USAGE_INVOICING}/invoicing/${itemId}/statistics`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INVOICING_STATISTICS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_INVOICING_STATISTICS_ERROR,
                error: e
            });
        }
    };
}

export const POST_INVOICE_CONSERVATION_START = "POST_INVOICE_CONSERVATION_START";
export const POST_INVOICE_CONSERVATION_SUCCESS = "POST_INVOICE_CONSERVATION_SUCCESS";
export const POST_INVOICE_CONSERVATION_ERROR = "POST_INVOICE_CONSERVATION_ERROR";

/**
 * GET invoice conservation.
 *
 *
 * @name postInvoiceConservation
 * @alias GET invoice conservation
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * @param {String} `itemId` the company tax code.
 * @param {String} `flowType` the tyupe of flow for invoce.
 * @param {Boolean} `details` the indicator for get more details information, if is true return invoice with more details.
 * @param {String} `year` the year for invoice (optional).
 * @param {String} `month` the month for invoice (optional).
 * @return {undefined}
 */
export const postInvoiceConservation = (auth, itemId, flowType, details, year, month, readOnly) => {
    return async dispatch => {
        const urlParams = {
            flowType: flowType,
            details: details,
            year: year ? year : "2018",
            month: month ? month : "*",
            readOnly: readOnly ? readOnly : true
        };

        dispatch({
            type: POST_INVOICE_CONSERVATION_START,
            request: urlParams
        });

        try {
            const result = await postRestCall(
                `${INVOICES_URL}/invoices/cct/${itemId}/executeHistory`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: POST_INVOICE_CONSERVATION_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: POST_INVOICE_CONSERVATION_ERROR,
                error: e
            });
        }
    };
};
