import styled from "styled-components";

export const CardContainer = styled.div`
    background: white;
    width: 100%;
    padding: 12px 24px;
    margin-bottom: 24px;
`;
export const Title = styled.div`
    font-size: 20px;
    color: #005075;
`;
