import styled from "styled-components";

export const Container = styled.div`
    padding: 10px 0px;
`;
export const PageHeaderTitle = styled.span`
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
    color: #1d2427;
`;
export const PageHeaderIcon = styled.i`
    color: #0090d1;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
`;
