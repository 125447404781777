import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import NewArchiveDataList from "../../../components/new-archive/list";
import PageHeader from "../../../components/general/PageHeader/index";

import { ContractWrapper } from "../styled";

import { errorBoundary } from "../../../components/general/error-boundary";

import { getListDraftRDC } from "../../../actions/archive/read";

const NewArchiveRdcDraftListView = props => {
    const { getListDraftRDC, auth, list, loadingStatus, total, match, history } = props;

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        itemsPerPage: 10,
        filter: {}
    });
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        setPageLoading(loadingStatus.started);
    }, [loadingStatus]);

    useEffect(() => {
        getListDraftRDC(
            auth.loginAuth,
            match.params.status,
            pagination.pageNumber - 1,
            pagination.itemsPerPage,
            pagination.filter.submitter,
            pagination.filter.holder
        );
    }, [
        auth.loginAuth,
        getListDraftRDC,
        match.params.status,
        pagination.pageNumber,
        pagination.itemsPerPage,
        pagination.filter.submitter,
        pagination.filter.holder
    ]);

    return (
        <ContractWrapper>
            <PageHeader history={history} labelId={`c-new-archive.rdc-list-${match.params.status}`} />
            <NewArchiveDataList
                status={match.params.status}
                key="list"
                handlePage={page => setPagination({ ...pagination, pageNumber: page })}
                handleShowSize={itemPerPage =>
                    setPagination({
                        ...pagination,
                        pageNumber: 1,
                        itemsPerPage: itemPerPage
                    })
                }
                handleFilter={(value, name) => {
                    let newFilter = pagination.filter;

                    newFilter[name] = value;
                    setPagination({ ...pagination, pageNumber: 1, filter: newFilter });
                }}
                page={pagination.pageNumber}
                loading={pageLoading}
                list={list}
                total={total}
                itemPerPage={pagination.itemsPerPage}
                cfMain={match.params.cfProduttore}
            />
        </ContractWrapper>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    list: state.newArchive.readNewArchive.newArchiveListRDC.hits,
    total: state.newArchive.readNewArchive.newArchiveListRDC.totalHits,
    loadingStatus: state.newArchive.readNewArchive.newArchiveListRDC.status
});

const actions = {
    getListDraftRDC
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);

NewArchiveRdcDraftListView.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    list: PropTypes.array,
    loadingStatus: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    total: PropTypes.number
};

export default composedHoc(NewArchiveRdcDraftListView);
