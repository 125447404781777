import React, { useCallback } from "react";
import { PdfWrapper } from "../styled";
import { PdfHandler } from "../../../../components/general/pdf-handler";
import { useAsync } from "react-async";

const ConnectionDocumentTab = ({ auth, company, connection, getConnectionDocument, key, tab }) => {
    const connectionDocumentFn = useCallback(async () => await getConnectionDocument(auth.loginAuth, connection.id), [
        getConnectionDocument,
        auth.loginAuth,
        connection.id
    ]);

    const connectionDocument = useAsync({
        promiseFn: connectionDocumentFn
    });

    const contentDocs = useCallback(connectionDocument => {
        let content = null;
        if (!connectionDocument.isPending && !connectionDocument.error && connectionDocument.data) {
            content = connectionDocument.data.content;
        }
        return content;
    }, []);

    return (
        <PdfWrapper>
            <PdfHandler
                content={contentDocs(connectionDocument)}
                loading={connectionDocument.isPending}
                fileName={"atto_di_affidamento.pdf"}
            />
        </PdfWrapper>
    );
};

export default ConnectionDocumentTab;
