import { fieldRequired } from "@mondora/agyo-validations";

export const apiKeySchema = {
    type: "object",
    properties: {
        description: {
            type: "string",
            validate: description => fieldRequired("Campo obbligatorio")(description)
        }
    }
};
