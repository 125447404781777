import styled from "styled-components";

export const MeteringExpandedTableSpinner = styled.div`
    position: relative;
    left: 50%;
    top: 30%;
    display: inline;
`;

export const MeteringExpandedTableBody = styled.div`
    margin-top: 5px;
    overflow: auto;
    height: 30vh;
`;
