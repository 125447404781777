import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Table, Spin, Modal, Badge, Avatar, Button, Menu, Dropdown, Upload, Icon } from "antd";
import {
    translateServiceCategory,
    translateServiceName,
    translateConnectionPermission,
    translateConnectionStatus,
    translateConnectionActive
} from "../../../../utils/translate";

import { userIsAuthorized } from "../../../../utils/get-roles";

const Dragger = Upload.Dragger;
const locale = {
    emptyText: "Nessun connessione presente"
};

class CardConnections extends Component {
    constructor() {
        super();
        this.state = {
            mDelete: false,
            connectionValueSelected: null,
            mUpload: false,
            fileToUpload: null,
            fileList: []
        };
    }
    static propTypes = {
        connectionSelected: PropTypes.object,
        admin: PropTypes.object.isRequired,
        handleDeleteLink: PropTypes.func.isRequired,
        openInfo: PropTypes.func.isRequired,
        handleUploadADA: PropTypes.func.isRequired
    };

    handleDelete() {
        const { handleDeleteLink } = this.props;
        const { connectionValueSelected } = this.state;
        handleDeleteLink(connectionValueSelected.id);
        this.setState({ mDelete: false, connectionValueSelected: null });
    }
    handleUpload() {
        const { handleUploadADA, connectionSelected } = this.props;
        const { connectionValueSelected, fileToUpload } = this.state;
        handleUploadADA(connectionValueSelected.id, connectionSelected, fileToUpload);
        this.setState({ mUpload: false, connectionValueSelected: null });
    }

    renderAction(connectionValue) {
        const { openInfo, admin } = this.props;

        return (
            <Menu>
                <Menu.Item key="0" onClick={() => openInfo(connectionValue)}>
                    <i className="fas fa-info-circle" /> Info
                </Menu.Item>
                <Menu.Item
                    key="1"
                    onClick={() =>
                        this.setState({
                            mDelete: true,
                            connectionValueSelected: connectionValue
                        })
                    }
                    disabled={connectionValue.status.deleted}
                >
                    <i className="fas fa-trash" /> Cancella
                </Menu.Item>
                {userIsAuthorized("c-connection-load-ada", admin.adminInfo.permits) &&
                connectionValue.status.certificationStatus !== "CERTIFIED" &&
                connectionValue.status.certificationStatus !== "AWAITING_APPROVAL" ? (
                    <Menu.Item
                        key="2"
                        onClick={() =>
                            this.setState({
                                mUpload: true,
                                connectionValueSelected: connectionValue,
                                fileList: []
                            })
                        }
                    >
                        <i className="fas fa-upload" /> <FormattedMessage id="c-load-ada-send-title" />
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    }

    render() {
        const { connectionSelected, admin } = this.props;
        const { mDelete, mUpload } = this.state;
        const columns = [
            {
                title: "Tipologia",
                align: "center",
                width: "5%",
                dataIndex: "approvalType",
                render: text => <Avatar>{text}</Avatar>
            },
            {
                title: "Attiva",
                align: "center",
                dataIndex: "status",
                key: "statusActive",
                render: text => (
                    <span>
                        <Badge
                            status={translateConnectionActive(text).status}
                            text={translateConnectionActive(text).title}
                        />
                    </span>
                )
            },
            {
                title: "Categoria",
                dataIndex: "appId",
                align: "center",
                render: text => translateServiceCategory(text)
            },

            {
                title: "Service Id",
                dataIndex: "serviceId",
                align: "center",
                render: text => translateServiceName(text)
            },
            {
                title: "Permesso",
                dataIndex: "permission",
                align: "center",
                render: text => translateConnectionPermission(text)
            },
            {
                title: "Stato",
                dataIndex: "status",
                align: "center",
                render: text => (
                    <Badge
                        status={translateConnectionStatus(text).status}
                        text={translateConnectionStatus(text).title}
                    />
                )
            },
            {
                title: "Azioni",
                align: "center",
                render: (text, record) => (
                    <div style={{ textAlign: "center" }}>
                        <Dropdown overlay={this.renderAction(record)} trigger={["click"]} placement="bottomRight">
                            <Button shape="circle" size="small">
                                <i className="fas fa-ellipsis-v" />
                            </Button>
                        </Dropdown>
                    </div>
                )
            }
        ];
        const props = {
            accept: "application/pdf",
            beforeUpload: file => {
                var fileContent = "";
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = evt => {
                    fileContent = evt.target.result;
                    fileContent = fileContent.substring(fileContent.indexOf("base64,") + 7, fileContent.length);
                    this.setState({ fileToUpload: fileContent });
                };
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return false;
            },
            multiple: false,
            fileList: []
        };
        return connectionSelected.connections !== undefined ? (
            <div className="c-company-connection-card">
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={connectionSelected.connections}
                    locale={locale}
                    size="small"
                    pagination={false}
                />
                <Modal
                    centered
                    onOk={() => this.handleDelete()}
                    okButtonProps={{
                        disabled: !userIsAuthorized("c-user-enabled-delete-connection", admin.adminInfo.permits)
                    }}
                    okText={
                        !userIsAuthorized("c-user-enabled-delete-connection", admin.adminInfo.permits) ? (
                            <FormattedMessage id="c-delete.connection.message.force" />
                        ) : (
                            "Si"
                        )
                    }
                    onCancel={() => {
                        this.setState({ mDelete: false });
                    }}
                    cancelText="No"
                    className="modal"
                    title={"Elimina Connessione"}
                    visible={mDelete}
                >
                    {!userIsAuthorized("c-user-enabled-delete-connection", admin.adminInfo.permits) ? (
                        <div style={{ background: "#FFFACD" }}>
                            <div style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="c-delete.connection.message.warning1" />
                            </div>
                            <ul>
                                <li>
                                    <FormattedMessage id="c-delete.connection.message.warning2" />
                                </li>
                            </ul>
                            <br></br>
                            <div style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="c-delete.connection.message.warning3" />
                                <a href="https://teamsystem.atlassian.net/servicedesk/customer/portal/6">Clicca qui</a>
                            </div>
                            <br></br>
                        </div>
                    ) : (
                        <div>
                            <FormattedMessage id="c-connection.message.modal.confirm" />
                        </div>
                    )}
                </Modal>
                <Modal
                    footer={null}
                    onCancel={() => this.setState({ mUpload: false })}
                    visible={mUpload}
                    style={{ top: "20%" }}
                    width="600px"
                    title={<FormattedMessage id="c-load-ada-send-title" />}
                >
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            {this.state.fileList.length === 0 ? <Icon type="inbox" /> : <Icon type="file-text" />}
                        </p>
                        {this.state.fileList.length === 0 ? null : (
                            <Icon onClick={() => this.setState({ fileList: [] })} type="close" />
                        )}
                        {this.state.fileList.length === 0 ? (
                            <p className="ant-upload-text">
                                <FormattedMessage id="c-load-ada-instruction" />
                            </p>
                        ) : (
                            <p className="ant-upload-text">{this.state.fileList[0].name}</p>
                        )}
                    </Dragger>
                    <Button
                        type="primary"
                        onClick={() => this.handleUpload()}
                        disabled={this.state.fileList.length === 0 || this.state.fileToUpload === null}
                        style={{ marginLeft: "83%", marginTop: "35px" }}
                    >
                        <FormattedMessage id="c-load-ada-send-button" />
                    </Button>
                </Modal>
            </div>
        ) : (
            <div style={{ textAlign: "center" }}>
                <Spin size="large" />
            </div>
        );
    }
}

export default CardConnections;
