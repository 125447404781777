import { REGISTRY_CORRECTOR_URL } from "../config";
import { getAuthWithAppName, callWithRetry } from "../utils/callWithRetry";
import RegistryCorrector from "../assets/thrift/registry-corrector/RegistryCorrectorApi";
import RegistryCorrectorTypes from "../assets/thrift/registry-corrector/registry-corrector-api_types";
import thrift from "browser-thrift";

const registryCorrectorUrl = new URL(REGISTRY_CORRECTOR_URL);
const registryCorrectorClient = thrift.createXHRClient(
    RegistryCorrector,
    thrift.createXHRConnection(registryCorrectorUrl.hostname, 443, {
        useCors: true,
        path: registryCorrectorUrl.pathname,
        https: registryCorrectorUrl.protocol === "https:"
    })
);

export const REPLACE_TAX_ID_START = "REPLACE_TAX_ID_START";
export const REPLACE_TAX_ID_SUCCESS = "REPLACE_TAX_ID_SUCCESS";
export const REPLACE_TAX_ID_ERROR = "REPLACE_TAX_ID_ERROR";

export function replaceTaxId(auth, oldTaxId, newTaxId) {
    return dispatch => {
        const request = new RegistryCorrectorTypes.ReplaceCompanyId({
            oldId: oldTaxId.toUpperCase(),
            newId: newTaxId.toUpperCase().trim()
        });
        const toast = {
            onOk: true,
            onOkText: "Rettifica Codice Fiscale effettuata con successo",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: REPLACE_TAX_ID_START,
            request: request
        });

        callWithRetry(
            registryCorrectorClient,
            registryCorrectorClient.replaceCompanyId,
            getAuthWithAppName(auth),
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: REPLACE_TAX_ID_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: REPLACE_TAX_ID_SUCCESS,
                        payload: result
                    });
                }
            },
            toast
        );
    };
}

export const REPLACE_VAT_NUMBER_START = "REPLACE_VAT_NUMBER_START";
export const REPLACE_VAT_NUMBER_SUCCESS = "REPLACE_VAT_NUMBER_SUCCESS";
export const REPLACE_VAT_NUMBER_ERROR = "REPLACE_VAT_NUMBER_ERROR";

export function replaceVatNumber(auth, itemId, vatNumber) {
    return dispatch => {
        const request = new RegistryCorrectorTypes.ReplaceVatNumber({
            itemId: itemId.toUpperCase().trim(),
            vatNumber: vatNumber.trim()
        });
        const toast = {
            onOk: true,
            onOkText: "Rettifica Partita IVA effettuata con successo",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: REPLACE_VAT_NUMBER_START,
            request: request
        });

        callWithRetry(
            registryCorrectorClient,
            registryCorrectorClient.replaceVatNumber,
            getAuthWithAppName(auth),
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: REPLACE_VAT_NUMBER_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: REPLACE_VAT_NUMBER_SUCCESS,
                        payload: result
                    });
                }
            },
            toast
        );
    };
}

export const REPLACE_CLASSIFIER_START = "REPLACE_CLASSIFIER_START";
export const REPLACE_CLASSIFIER_SUCCESS = "REPLACE_CLASSIFIER_SUCCESS";
export const REPLACE_CLASSIFIER_ERROR = "REPLACE_CLASSIFIER_ERROR";

export function replaceClassifier(auth, itemId, classifier) {
    return dispatch => {
        const request = new RegistryCorrectorTypes.ReplaceClassifier({
            itemId: itemId.toUpperCase().trim(),
            classifier: classifier.toUpperCase().trim()
        });
        const toast = {
            onOk: true,
            onOkText: "Rettifica Tipo Azienda effettuata con successo",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: REPLACE_CLASSIFIER_START,
            request: request
        });

        callWithRetry(
            registryCorrectorClient,
            registryCorrectorClient.replaceClassifier,
            getAuthWithAppName(auth),
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: REPLACE_CLASSIFIER_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: REPLACE_CLASSIFIER_SUCCESS,
                        payload: result
                    });
                }
            },
            toast
        );
    };
}
export const REPLACE_IDENTIFIER_START = "REPLACE_IDENTIFIER_START";
export const REPLACE_IDENTIFIER_SUCCESS = "REPLACE_IDENTIFIER_SUCCESS";
export const REPLACE_IDENTIFIER_ERROR = "REPLACE_IDENTIFIER_ERROR";

export function replaceIdentifier(auth, itemId, newTaxId, newVatNumber, taxRegion) {
    return dispatch => {
        const request = new RegistryCorrectorTypes.UpdateIdentifier({
            itemId: itemId.toUpperCase(),
            newIdentifier: { taxId: newTaxId.toUpperCase().trim(), taxRegion: taxRegion, vatNumber: newVatNumber }
        });
        const toast = {
            onOk: true,
            onOkText: "Rettifica Identificativo effettuata con successo",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: REPLACE_IDENTIFIER_START,
            request: request
        });

        callWithRetry(
            registryCorrectorClient,
            registryCorrectorClient.updateItemIdentifier,
            getAuthWithAppName(auth),
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: REPLACE_IDENTIFIER_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: REPLACE_IDENTIFIER_SUCCESS,
                        payload: result
                    });
                }
            },
            toast
        );
    };
}
