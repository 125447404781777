import { combineReducers } from "redux";

import {
    document,
    postSignSearch,
    getSignDocumentStatuses,
    getSignDocumentTypes,
    getSignDocumentDownload,
    signersList,
    getSignerDetails,
    downloadSigner,
    getSignerExtraData
} from "./read";

import { updateCertificateId } from "./write";

const read = combineReducers({
    document,
    postSignSearch,
    getSignDocumentStatuses,
    getSignDocumentTypes,
    getSignDocumentDownload,
    signersList,
    getSignerDetails,
    downloadSigner,
    getSignerExtraData
});

const write = combineReducers({
    updateCertificateId
});

export default combineReducers({ read, write });
