import styled from "styled-components";

export const CenterContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const Container = styled.div`
    height: 90vh;
    min-height: 700px;
    min-width: 400px;
    background: white;
    padding: 24px;
    overflow: auto;
`;

export const NoData = styled.div`
    font-size: 22px;
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const TitleAction = styled.div`
    font-size: 25px;
    right: 20px;
    position: absolute;
    margin-top: -10px;
    z-index: 1;
    cursor: pointer;
`;
