import styled from "styled-components";

export const CenteredDiv = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const Content = styled.div`
    background: #f7f7f7;
    border-radius: 5px;
    padding: 10px 10px 8px;
`;
