import React from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { errorBoundary } from "../../components/general/error-boundary";
import { TSIHBOIframe } from "../../components/tsih-bo";

import { IframeContainer } from "./styled";

const TSIHBOView = ({ token }) => {
    return (
        <IframeContainer>
            <TSIHBOIframe token={token} />
        </IframeContainer>
    );
};

const mapStateToProps = state => ({
    token: state.auth.loginAuth.idToken
});

const composedHoc = compose(
    connect(mapStateToProps),
    injectIntl,
    errorBoundary
);
export default composedHoc(TSIHBOView);
