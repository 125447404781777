import styled from "styled-components";

export const SpinnerContainer = styled.div`
    margin-top: 50%;
    text-align: center;
`;

export const PDFContainer = styled.div`
    border: 1px dashed #0090d1;
    height: 85vh;
    margin: 0px 15px 15px 15px;
`;
