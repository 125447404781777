import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { restrictToPermits } from "../../components/general/hoc";
import { errorBoundary } from "../../components/general/error-boundary";

import Spid from "../../components/spid";

const SpidView = ({ admin, history }) => {
    return (
        <div>
            <Spid admin={admin} history={history} />
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin
});
const actions = {};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-sideMenu-spid")
);
export default composedHoc(SpidView);
