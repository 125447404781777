import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button, Divider, Checkbox, Collapse } from "antd";
import "./style.css";
import TemplateCard from "../template-card";
export const ChannelsEnum = {
    email: "EMAIL",
    pec: "PEC",
    log: "LOG",
    sms: "SMS",
    web: "WEB",
    webhook: "WEBHOOK"
};

const Channels = "Channels";

const LockedChannels = "Locked channels";

class GroupTemplateCard extends Component {
    static propTypes = {
        notifications: PropTypes.array.isRequired,
        onChannelClick: PropTypes.func.isRequired,
        appCode: PropTypes.string.isRequired
    };

    render() {
        const { notifications, appCode } = this.props;
        return (
            <div>
                <Collapse style={{ marginTop: 20 }} expandIconPosition={"right"}>
                    <Collapse.Panel header={appCode} key={`${appCode}appCodePanel`}>
                        <span>
                            {notifications.map((notification, index) => {
                                const data = [
                                    {
                                        key: `${appCode}${index}1`,
                                        name: Channels,
                                        channels: notification.channels,
                                        templates: notification.templates
                                    },
                                    {
                                        key: `${appCode}${index}2`,
                                        name: LockedChannels,
                                        channels: notification.lockedChannels
                                    }
                                ];

                                const columns = [
                                    {
                                        title: "",
                                        dataIndex: "name",
                                        key: `${appCode}${index}0`
                                    }
                                ];

                                Object.entries(ChannelsEnum).forEach(([key, value], i) => {
                                    columns.push({
                                        title: value,
                                        dataIndex: "channels",
                                        key: (i + 1).toString(),
                                        render: (channels, record) => (
                                            <span>
                                                <Checkbox
                                                    disabled
                                                    checked={channels != null && channels.includes(value)}
                                                />
                                                {record.name === Channels ? (
                                                    <span>
                                                        <Divider type="vertical" />
                                                        <Button
                                                            type="primary"
                                                            shape="circle"
                                                            icon="eye"
                                                            size="small"
                                                            disabled={
                                                                record.templates == null ||
                                                                !record.templates.some(e => e.type === value)
                                                            }
                                                            onClick={() =>
                                                                this.props.onChannelClick(
                                                                    record.templates.filter(e => e.type === value)[0],
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                ) : null}
                                            </span>
                                        )
                                    });
                                });

                                return (
                                    <div key={`${appCode}${index}CollapsePanel`}>
                                        <TemplateCard notification={notification} columns={columns} data={data} />
                                    </div>
                                );
                            })}
                        </span>
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    }
}

export default GroupTemplateCard;
