import {
    CREATE_LAYER_START,
    CREATE_LAYER_ERROR,
    CREATE_LAYER_SUCCESS,
    DELETE_LAYER_START,
    DELETE_LAYER_ERROR,
    DELETE_LAYER_SUCCESS,
    UPDATE_LAYER_START,
    UPDATE_LAYER_ERROR,
    UPDATE_LAYER_SUCCESS,
    ENABLE_LAYER_START,
    ENABLE_LAYER_ERROR,
    ENABLE_LAYER_SUCCESS,
    DISABLE_LAYER_START,
    DISABLE_LAYER_ERROR,
    DISABLE_LAYER_SUCCESS
} from "../../actions/company/layers";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function createLayer(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CREATE_LAYER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CREATE_LAYER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CREATE_LAYER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function deleteLayer(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DELETE_LAYER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DELETE_LAYER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DELETE_LAYER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function updateLayer(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_LAYER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_LAYER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_LAYER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function enableLayer(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ENABLE_LAYER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ENABLE_LAYER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case ENABLE_LAYER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function disableLayer(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DISABLE_LAYER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DISABLE_LAYER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DISABLE_LAYER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
