import styled from "styled-components";

export const MeteringLynfaServiceContent = styled.div`
    overflow: auto;
    height: 78vh;
`;

export const MeteringLynfaTitle = styled.div`
    text-align: right;
`;

export const MeteringLynfaServiceSpinner = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;
