function getTagFromXml(dom, tag, getFromIndex = -1, getInnerHTML = -1) {
    try {
        if (!dom || (Object.prototype.toString.call(dom) === "[object HTMLCollection]" && dom.length === 0)) {
            return;
        }
        let tagFromDom =
            parseFloat(getFromIndex) > -1
                ? dom && dom[getFromIndex] && dom[getFromIndex].getElementsByTagName(tag)
                : dom && dom.getElementsByTagName(tag);

        tagFromDom = tagFromDom && tagFromDom.length > 0 ? tagFromDom : undefined;
        let innerHTML =
            getInnerHTML > -1 && tagFromDom && tagFromDom.length >= getInnerHTML + 1
                ? tagFromDom[getInnerHTML].innerHTML
                : undefined;
        return getInnerHTML > -1 && innerHTML ? innerHTML : tagFromDom;
    } catch (e) {
        return undefined;
    }
}

// PARSINGXML ONLY FOR GET ATTACHMENTS FROM XML
export function onParsingXML(content) {
    if (content !== undefined) {
        let parser = new DOMParser();
        let xmlDecoded = content && parser.parseFromString(content, "text/xml");
        let allegatiFatturaElettronica = getTagFromXml(xmlDecoded, "Allegati");
        if (allegatiFatturaElettronica !== undefined) {
            let list = [];
            for (let i = 0; i < allegatiFatturaElettronica.length; i++) {
                let element = {
                    name: getTagFromXml(allegatiFatturaElettronica, "NomeAttachment", i, 0),
                    formatoAttachment: getTagFromXml(allegatiFatturaElettronica, "FormatoAttachment", i, 0),
                    content: getTagFromXml(allegatiFatturaElettronica, "Attachment", i, 0),
                    algoritmoCompressione: getTagFromXml(allegatiFatturaElettronica, "AlgoritmoCompressione", i, 0),
                    descrizioneAttachment: getTagFromXml(allegatiFatturaElettronica, "DescrizioneAttachment", i, 0)
                };
                list.push(element);
            }
            return list;
        }
    }
}
