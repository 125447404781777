import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

import { OrderFilterContent, OrderFilterSelect, OrderFilterItems, OrderFilterAsyncSelect } from "./styled";

export const OrderFilter = ({
    companyOptions,
    disabledCompanyOptions,
    companySelected,
    handleCompanyChange,
    disabledClearFilter,
    handleClearFilter,
    handleLoadOptionsLazy,
    lazyMode = false,
    hideClearFilter = false,
    lazyInitialCompanyOptions,
    isSearchable = true
}) => {
    return (
        <OrderFilterContent>
            <span>
                {!lazyMode ? (
                    companyOptions.length && (
                        <OrderFilterSelect
                            disabled={disabledCompanyOptions}
                            value={companySelected}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleCompanyChange}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {companyOptions}
                        </OrderFilterSelect>
                    )
                ) : (
                    <OrderFilterAsyncSelect
                        options={lazyInitialCompanyOptions}
                        value={companySelected}
                        loadOptions={handleLoadOptionsLazy}
                        onChange={handleCompanyChange}
                        isSearchable={isSearchable}
                        placeholder={companySelected}
                        loadingMessage={() => <FormattedMessage id={"loading"} />}
                        noOptionsMessage={() => <FormattedMessage id="not_available" />}
                        isClearable={false}
                        additional={{
                            page: 0
                        }}
                    />
                )}
                {!hideClearFilter && (
                    <OrderFilterItems>
                        <Button
                            type="primary"
                            shape="round"
                            icon="filter"
                            disabled={disabledClearFilter}
                            onClick={handleClearFilter}
                        >
                            <FormattedMessage id="reset_filters" />
                        </Button>
                    </OrderFilterItems>
                )}
            </span>
        </OrderFilterContent>
    );
};

OrderFilter.propTypes = {
    disabledClearFilter: PropTypes.bool,
    handleClearFilter: PropTypes.func,
    companyOptions: PropTypes.array,
    disabledCompanyOptions: PropTypes.bool.isRequired,
    companySelected: PropTypes.any,
    handleCompanyChange: PropTypes.func.isRequired,
    hideClearFilter: PropTypes.bool,
    handleLoadOptionsLazy: PropTypes.func,
    lazyInitialCompanyOptions: PropTypes.any,
    lazyMode: PropTypes.bool,
    isSearchable: PropTypes.bool
};
