import styled from "styled-components";

export const ContainerAttached = styled.div`
    padding-top: 15px;
    min-width: 400px;
    width: 50%;
`;

export const ContainerMessage = styled.div`
    padding-top: 15px;
    min-width: 400px;
    padding-right: 15px;
    width: 50%;
`;

export const ContainerTitle = styled.span`
    font-weight: bold;
    font-size: 18px;
`;

export const ContainerField = styled.span`
    font-weight: bold;
`;

export const ActionCard = styled.div`
    color: #1890ff;
    cursor: pointer;
    padding-bottom: 15px;
`;
