import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./style.css";

import { Row, Col, Layout, Spin } from "antd";

import packageInfo from "../../../package.json";
import { login, logout, resetLogin, resetGetAuthInfo, getAuthInfo, sharedLogin } from "../../actions/auth";
import { updateAuthPsw } from "../../actions/user/write";
import { LOGO_COLOR, LOGO_TS_DIGITAL, BRANCH_NAME } from "../../config";

import LoginComponent from "../../components/login";
import LoginRetry from "../../components/login/retry";
import ExpiredPassword from "../../components/login/expired-password";
import UserNotEnable from "../../components/general/user-not-enable";

const LoginView = ({
    admin,
    auth,
    history,
    login,
    sharedLogin,
    logout,
    resetLogin,
    resetGetAuthInfo,
    updateAuthPsw,
    getAuthInfo
}) => {
    let codes = ["403.1"];
    let admStatus = admin.adminInfo.status;
    const getLogin = useLogin(auth, admin, BRANCH_NAME, getAuthInfo);

    if (getLogin.loading) {
        return (
            <div className="Login-spinner-box">
                <Spin size="large" />
            </div>
        );
    }

    if (getLogin.isLogined) {
        return <Redirect to="/dashboard" />;
    }

    let content = "";

    if (getLogin.error) {
        content = (
            <UserNotEnable
                auth={auth}
                logout={auth => {
                    getLogin.handleClearError();
                    logout(auth);
                }}
            />
        );
    } else if (admStatus.error && admStatus.errorInfo.code === 403)
        content = <UserNotEnable auth={auth} logout={logout} />;
    else if (auth.loginAuth.status.error && auth.loginAuth.status.errorInfo.code === "403.1")
        content = (
            <ExpiredPassword
                admin={admin}
                auth={auth}
                resetGetAuthInfo={resetGetAuthInfo}
                resetLogin={resetLogin}
                updateAuthPsw={updateAuthPsw}
            />
        );
    else if (
        (auth.loginAuth.status.error && !codes.includes(auth.loginAuth.status.errorInfo.code)) ||
        (admStatus.error && !codes.includes(admStatus.errorInfo.code))
    )
        content = (
            <LoginRetry
                errorMsg={
                    auth.loginAuth.status.errorInfo
                        ? auth.loginAuth.status.errorInfo.message
                        : admStatus.errorInfo.message
                }
                resetLogin={() => {
                    sessionStorage.clear();
                    resetLogin();
                }}
            />
        );
    else if (!admStatus.error && !auth.loginAuth.status.error)
        content = (
            <LoginComponent
                history={history}
                login={login}
                tsLogin={data => sharedLogin(data.id, data.token, data.refreshToken, data.idToken)}
            />
        );

    return (
        <div className="loginBackground">
            <img src={LOGO_TS_DIGITAL} className="Login-header-background-img" alt="logo-background" />
            <div className="Login">
                <div>
                    <Row justify="center" type="flex">
                        <Col>
                            <div className="Login-header">
                                <img src={LOGO_COLOR} className="Login-header-img" alt="logo" />
                            </div>
                        </Col>
                    </Row>

                    {content}

                    <Layout.Footer className="root-footer">
                        <p>{"TS Digital BackOffice 2020 - v" + packageInfo.version}</p>
                    </Layout.Footer>
                </div>
            </div>
        </div>
    );
};

LoginView.propTypes = {
    admin: PropTypes.object,
    auth: PropTypes.object.isRequired,
    history: PropTypes.object,
    login: PropTypes.func.isRequired,
    sharedLogin: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    resetLogin: PropTypes.func.isRequired,
    resetGetAuthInfo: PropTypes.func.isRequired,
    getAuthInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    user: state.user
});

const actions = {
    login,
    sharedLogin,
    logout,
    resetGetAuthInfo,
    resetLogin,
    updateAuthPsw,
    getAuthInfo
};

const composedHoc = connect(
    mapStateToProps,
    actions
);
export default composedHoc(LoginView);

const useLogin = (auth, admin, branchName, getAuthInfo) => {
    const [loading, setLoading] = useState(false);
    const [isLogined, setIsLogined] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (admin.adminInfo.status.started) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [admin.adminInfo.status]);

    useEffect(() => {
        if (auth && auth.loginAuth.securityToken && !auth.loginAuth.status.error && !admin.adminInfo.status.error) {
            if (branchName === "preprod") {
                const asyncGetAuth = async () => {
                    const result = await getAuthInfo(auth.loginAuth, encodeURIComponent(auth.loginAuth.id));
                    if (result && result.permits && result.permits.isPreProdUser) {
                        setIsLogined(true);
                    } else {
                        setError(true);
                        setIsLogined(false);
                    }
                };
                setError(false);
                asyncGetAuth();
            } else {
                setIsLogined(true);
            }
        } else {
            setIsLogined(false);
        }
    }, [admin.adminInfo.status.error, auth, branchName, getAuthInfo]);

    const handleClearError = () => {
        setError(false);
    };

    return {
        loading,
        error,
        isLogined,
        handleClearError
    };
};
