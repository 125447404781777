import { SERVICES_SUBSCRIPTION_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const FETCH_ACTIVE_AGYO_SERVICE_START = "FETCH_ACTIVE_AGYO_SERVICE_START";
export const FETCH_ACTIVE_AGYO_SERVICE_ERROR = "FETCH_ACTIVE_AGYO_SERVICE_ERROR";
export const FETCH_ACTIVE_AGYO_SERVICE_SUCCESS = "FETCH_ACTIVE_AGYO_SERVICE_SUCCESS";

export function fetchActiveAgyoServices(auth) {
    return async dispatch => {
        dispatch({
            type: FETCH_ACTIVE_AGYO_SERVICE_START
        });

        const url = `${SERVICES_SUBSCRIPTION_URL}/services`;

        try {
            const result = await getRestCall(url, auth, {}, dispatch, auth.refreshToken);

            dispatch({
                type: FETCH_ACTIVE_AGYO_SERVICE_SUCCESS,
                payload: result.agyoServices
            });
        } catch (e) {
            dispatch({
                type: FETCH_ACTIVE_AGYO_SERVICE_ERROR,
                error: e
            });
        }
    };
}
