import styled from "styled-components";
import { ChipCheckbox } from "@mondora/arc/antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Status = styled.div`
    float: right;
`;
export const SignerValue = styled.span`
    margin-left: 18px;
`;
export const SignerTitle = styled.span`
    font-weight: bold;
    color: #152935;
`;

export const ActionEnable = styled.span`
    color: #0090d1;
    cursor: pointer;
`;

export const ActionDisabled = styled(ActionEnable)`
    color: #c9d9e8;
    cursor: default;
`;

export const SignerContainer = styled.div`
    margin-top: 20px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 5px;
    margin-right: 45px;
`;

export const CheckCircleIcon = styled(FontAwesomeIcon)`
    color: #09822a;
`;

export const ChipsContainer = styled.div`
    margin: 20px 0px;
`;
export const Chip = styled(ChipCheckbox)`
    margin: 0px 5px;
`;
