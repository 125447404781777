export function getServiceInfo(type, company, services) {
    switch (type) {
        case "EMAIL-FLOW":
            return {
                serviceName: "EMAIL-FLOW",
                id: company.info.base.id,
                ownerId: company.info.base.id
            };
        case "SDI-FLOW":
            return {
                id: services.sdi.getSdiConfig.configs[0].itemId,
                ownerId: services.sdi.getSdiConfig.configs[0].ownerId
            };
        case "B2B-PASSIVE-FLOW":
            return {
                id: services.b2bPassive.configs[0].itemId,
                ownerId: services.b2bPassive.configs[0].ownerId
            };
        case "PRIVACY-FLOW":
            return {
                id: services.privacy.itemId,
                ownerId: services.privacy.ownerId
            };
        case "A-FISCALI-IVA-FLOW":
        case "B2B-FLOW":
        case "CCT-FLOW":
        case "GDPR-LOG":
        case "DIGITALBOX":
        case "SIGNATURE-FLOW":
        default:
            return {
                id: company.info.base.id,
                ownerId: company.info.base.id
            };
    }
}
