import { postRestCall } from "../../utils/rest-api-call";
import { METERING_REALTIME_API_URL } from "../../config";

export const POST_METERING_REALTIME_REASSIGNMENTS_START = "POST_METERING_REALTIME_REASSIGNMENTS_START";
export const POST_METERING_REALTIME_REASSIGNMENTS_SUCCESS = "POST_METERING_REALTIME_REASSIGNMENTS_SUCCESS";
export const POST_METERING_REALTIME_REASSIGNMENTS_ERROR = "POST_METERING_REALTIME_REASSIGNMENTS_ERROR";

/**
 * Reassignments metering usage.
 *
 *
 * @name postReassignments
 * @alias Reassignments usage
 * @param {Object} `auth` pass auth.loginAuth for authentication.
 * * @param {String} `ownerId` the company tax code.
 * @return {undefined}
 */
export const postReassignments = (auth, ownerId) => async dispatch => {
    dispatch({
        type: POST_METERING_REALTIME_REASSIGNMENTS_START
    });
    const toast = {
        onOk: true,
        onOkText: "Ricalcolo lanciato",
        onError: true,
        infoReq: { authId: auth.id, req: ownerId }
    };
    try {
        const result = await postRestCall(
            `${METERING_REALTIME_API_URL}/api/v1/reassignments/owner/${ownerId}`,
            auth,
            {},
            null,
            dispatch,
            auth.refreshToken,
            toast
        );

        dispatch({
            type: POST_METERING_REALTIME_REASSIGNMENTS_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: POST_METERING_REALTIME_REASSIGNMENTS_ERROR,
            error: e
        });
    }
};
