import React, { Component } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { connect } from "react-redux";
import "./style.css";

import { Card, Col, Divider, Row } from "antd";
class CompanyCard extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        content: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        contentTaxIdAdj: PropTypes.object,
        contentVatNumberAdj: PropTypes.object,
        contentClassifierAdj: PropTypes.object,
        contentLogoAdj: PropTypes.object,
        history: PropTypes.object.isRequired
    };

    render() {
        const {
            content,
            company,
            contentTaxIdAdj,
            contentVatNumberAdj,
            contentClassifierAdj,
            contentLogoAdj,
            history
        } = this.props;

        return (
            <div className="c-CompanyCard">
                <Card className="content">
                    <div>
                        <div onClick={() => history.push(`/item/${company.info.base.id}/dashboard`)} className="close">
                            <i className="far fa-times-circle close-icon" />
                        </div>
                        <br />
                    </div>
                    {!isNil(contentClassifierAdj) ? (
                        <div>
                            <Divider orientation="left">Rettifica Identificativo</Divider>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <div>{contentTaxIdAdj}</div>
                                </Col>
                                <Col span={8}>
                                    <div>{contentVatNumberAdj}</div>
                                </Col>
                            </Row>
                            <Divider orientation="left">Rettifica Tipologia</Divider>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <div>{contentClassifierAdj}</div>
                                </Col>
                            </Row>
                            <Divider orientation="left">Logo</Divider>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <div>{contentLogoAdj}</div>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    <div>{content}</div>
                </Card>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    company: state.company,
    services: state.services
});

const actions = {};

export default connect(
    mapStateToProps,
    actions
)(CompanyCard);
