import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Checkbox } from "antd";

export const CompanyEligibleInvoiceExpandedInfo = ({ data }) => {
    let columns = [
        {
            title: <FormattedMessage id={"id"} />,
            dataIndex: "id"
        },
        {
            title: <FormattedMessage id={"description"} />,
            dataIndex: "description"
        },
        {
            title: <FormattedMessage id={"notes"} />,
            dataIndex: "note"
        },
        {
            title: <FormattedMessage id={"attachments"} />,
            dataIndex: "type"
        }
    ];

    return (
        <div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="small"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={data.preparatoryInfo ? data.preparatoryInfo : []}
                    pagination={false}
                />
            </div>
            <p>
                <b>
                    <FormattedMessage id={"estimated_balance"} />:
                </b>
                {` ${data.estimatedBalance ? data.estimatedBalance + " €" : "N.D."}`}
                <br />
                <b>
                    <FormattedMessage id={"lv_int"} />:
                </b>
                {` ${data.tradingConfidenceLevel ? data.tradingConfidenceLevel : "N.D."}`}
                <br />
                <b>
                    <FormattedMessage id={"hubid"} />:
                </b>
                {` ${data.id ? data.id : "N.D."}`}
                <br />
                <b>
                    <FormattedMessage id={"platform_commissions"} />:
                </b>
                {` ${data.platformFees ? data.platformFees + " €" : "N.D."}`}
                <br />
                <b>
                    <FormattedMessage id={"relationship_between_supplier_and_customer"} />:
                </b>{" "}
                <Checkbox disabled={true} checked={data.supplierCustomerRelationshipInfoRequired} />
                <br />
                <b>
                    <FormattedMessage id={"acceptance_assignment"} />:
                </b>{" "}
                <Checkbox disabled={true} checked={data.acceptanceRequired} />
                <br />
                <b>
                    <FormattedMessage id={"inserted_in_a_draft"} />:
                </b>{" "}
                <Checkbox disabled={true} checked={data.addedInDraft} />
                <br />
            </p>
        </div>
    );
};

CompanyEligibleInvoiceExpandedInfo.propTypes = {
    data: PropTypes.object.isRequired
};
