import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Spin, Icon } from "antd";

import { BodyContainer, CenterContainer } from "./styled";

export const SignatureServiceDetails = ({ signer }) => {
    const showLoading = () => {
        return (
            <BodyContainer>
                <CenterContainer>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                </CenterContainer>
            </BodyContainer>
        );
    };

    const showEmptyData = () => {
        return (
            <BodyContainer>
                <CenterContainer>
                    <FormattedMessage id={"not_available"} />
                </CenterContainer>
            </BodyContainer>
        );
    };

    const showData = () => {
        return (
            <BodyContainer>
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id={"signatory_card"} />
                </span>
                <br />
                <FormattedMessage id={"general.firstName"} />/<FormattedMessage id={"general.lastName"} />:{" "}
                {signer.data.firstName} {signer.data.lastName}
                <br />
                <FormattedMessage id={"general.taxId"} />: {signer.data.fiscalCountry} {signer.data.fiscalCode}
                <br />
                <FormattedMessage id={"sex"} />: {signer.data.sex}
                <br />
                <FormattedMessage id={"birth_place"} />: {signer.data.birthCity} {signer.data.birthDistrict}{" "}
                {signer.data.birthCountry}
                <br />
                <FormattedMessage id={"general.birthday"} />: {new Date(signer.data.birthDate).toLocaleDateString()}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id={"general.address"} />:{" "}
                </span>
                {signer.data.address} {signer.data.city}
                {" - "}
                {signer.data.cap}
                {" - "}
                {signer.data.country}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id={"contacts"} />
                </span>
                <br />
                <FormattedMessage id={"email"} />: {signer.data.email}
                <br />
                <FormattedMessage id={"telephone_number"} />: {signer.data.cellNumber}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id={"document_information"} />
                </span>
                <br />
                <FormattedMessage id={"document_type"} />: {signer.data.documentType}
                <br />
                <FormattedMessage id={"released_by"} />: {signer.data.documentIssuer}
                <br />
                <FormattedMessage id={"document_number"} />: {signer.data.documentNumber}
                <br />
                <FormattedMessage id={"expiry_date"} />: {new Date(signer.data.documentEndDate).toLocaleDateString()}
                <br />
            </BodyContainer>
        );
    };
    return signer.status.started ? showLoading() : signer.data ? showData() : showEmptyData();
};

SignatureServiceDetails.propTypes = {
    signer: PropTypes.object.isRequired
};
