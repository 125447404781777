/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./thrift_types');
//HELPER FUNCTIONS AND STRUCTURES

var CompanyDocumentApi_health_args = function(args) {
};
CompanyDocumentApi_health_args.prototype = {};
CompanyDocumentApi_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_health_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
CompanyDocumentApi_health_result.prototype = {};
CompanyDocumentApi_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_health_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_count_args = function(args) {
  this.auth = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
  }
};
CompanyDocumentApi_count_args.prototype = {};
CompanyDocumentApi_count_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_count_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_count_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_count_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Counter(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_count_result.prototype = {};
CompanyDocumentApi_count_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Counter();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_count_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_count_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_pending_args = function(args) {
  this.auth = null;
  this.filter = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.filter !== undefined && args.filter !== null) {
      this.filter = new ttypes.Filter(args.filter);
    }
  }
};
CompanyDocumentApi_pending_args.prototype = {};
CompanyDocumentApi_pending_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.filter = new ttypes.Filter();
        this.filter.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_pending_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_pending_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.filter !== null && this.filter !== undefined) {
    output.writeFieldBegin('filter', Thrift.Type.STRUCT, 2);
    this.filter.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_pending_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Documents(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_pending_result.prototype = {};
CompanyDocumentApi_pending_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Documents();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_pending_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_pending_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_validated_args = function(args) {
  this.auth = null;
  this.filter = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.filter !== undefined && args.filter !== null) {
      this.filter = new ttypes.Filter(args.filter);
    }
  }
};
CompanyDocumentApi_validated_args.prototype = {};
CompanyDocumentApi_validated_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.filter = new ttypes.Filter();
        this.filter.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_validated_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_validated_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.filter !== null && this.filter !== undefined) {
    output.writeFieldBegin('filter', Thrift.Type.STRUCT, 2);
    this.filter.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_validated_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Documents(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_validated_result.prototype = {};
CompanyDocumentApi_validated_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Documents();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_validated_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_validated_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_rejected_args = function(args) {
  this.auth = null;
  this.filter = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.filter !== undefined && args.filter !== null) {
      this.filter = new ttypes.Filter(args.filter);
    }
  }
};
CompanyDocumentApi_rejected_args.prototype = {};
CompanyDocumentApi_rejected_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.filter = new ttypes.Filter();
        this.filter.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_rejected_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_rejected_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.filter !== null && this.filter !== undefined) {
    output.writeFieldBegin('filter', Thrift.Type.STRUCT, 2);
    this.filter.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_rejected_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Documents(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_rejected_result.prototype = {};
CompanyDocumentApi_rejected_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Documents();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_rejected_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_rejected_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_getDocuments_args = function(args) {
  this.auth = null;
  this.getDocumentsRequest = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.getDocumentsRequest !== undefined && args.getDocumentsRequest !== null) {
      this.getDocumentsRequest = new ttypes.GetDocumentsRequest(args.getDocumentsRequest);
    }
  }
};
CompanyDocumentApi_getDocuments_args.prototype = {};
CompanyDocumentApi_getDocuments_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.getDocumentsRequest = new ttypes.GetDocumentsRequest();
        this.getDocumentsRequest.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_getDocuments_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_getDocuments_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.getDocumentsRequest !== null && this.getDocumentsRequest !== undefined) {
    output.writeFieldBegin('getDocumentsRequest', Thrift.Type.STRUCT, 2);
    this.getDocumentsRequest.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_getDocuments_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Documents(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_getDocuments_result.prototype = {};
CompanyDocumentApi_getDocuments_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Documents();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_getDocuments_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_getDocuments_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_getFullInfoDocument_args = function(args) {
  this.auth = null;
  this.id = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
CompanyDocumentApi_getFullInfoDocument_args.prototype = {};
CompanyDocumentApi_getFullInfoDocument_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_getFullInfoDocument_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_getFullInfoDocument_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_getFullInfoDocument_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetFullInfoDocumentResponse(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_getFullInfoDocument_result.prototype = {};
CompanyDocumentApi_getFullInfoDocument_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetFullInfoDocumentResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_getFullInfoDocument_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_getFullInfoDocument_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_download_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.Download(args.data);
    }
  }
};
CompanyDocumentApi_download_args.prototype = {};
CompanyDocumentApi_download_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.Download();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_download_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_download_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApi_download_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.DocumentContent(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyDocumentApi_download_result.prototype = {};
CompanyDocumentApi_download_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.DocumentContent();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyDocumentApi_download_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyDocumentApi_download_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyDocumentApiClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
CompanyDocumentApiClient.prototype = {};
CompanyDocumentApiClient.prototype.seqid = function() { return this._seqid; };
CompanyDocumentApiClient.prototype.new_seqid = function() { return this._seqid += 1; };

CompanyDocumentApiClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

CompanyDocumentApiClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  var args = new CompanyDocumentApi_health_args();
  try {
    output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};

CompanyDocumentApiClient.prototype.count = function(auth, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_count(auth);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_count(auth);
  }
};

CompanyDocumentApiClient.prototype.send_count = function(auth) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth
  };
  var args = new CompanyDocumentApi_count_args(params);
  try {
    output.writeMessageBegin('count', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_count = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_count_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('count failed: unknown result');
};

CompanyDocumentApiClient.prototype.pending = function(auth, filter, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_pending(auth, filter);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_pending(auth, filter);
  }
};

CompanyDocumentApiClient.prototype.send_pending = function(auth, filter) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    filter: filter
  };
  var args = new CompanyDocumentApi_pending_args(params);
  try {
    output.writeMessageBegin('pending', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_pending = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_pending_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('pending failed: unknown result');
};

CompanyDocumentApiClient.prototype.validated = function(auth, filter, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_validated(auth, filter);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_validated(auth, filter);
  }
};

CompanyDocumentApiClient.prototype.send_validated = function(auth, filter) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    filter: filter
  };
  var args = new CompanyDocumentApi_validated_args(params);
  try {
    output.writeMessageBegin('validated', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_validated = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_validated_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('validated failed: unknown result');
};

CompanyDocumentApiClient.prototype.rejected = function(auth, filter, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_rejected(auth, filter);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_rejected(auth, filter);
  }
};

CompanyDocumentApiClient.prototype.send_rejected = function(auth, filter) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    filter: filter
  };
  var args = new CompanyDocumentApi_rejected_args(params);
  try {
    output.writeMessageBegin('rejected', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_rejected = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_rejected_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('rejected failed: unknown result');
};

CompanyDocumentApiClient.prototype.getDocuments = function(auth, getDocumentsRequest, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_getDocuments(auth, getDocumentsRequest);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_getDocuments(auth, getDocumentsRequest);
  }
};

CompanyDocumentApiClient.prototype.send_getDocuments = function(auth, getDocumentsRequest) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    getDocumentsRequest: getDocumentsRequest
  };
  var args = new CompanyDocumentApi_getDocuments_args(params);
  try {
    output.writeMessageBegin('getDocuments', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_getDocuments = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_getDocuments_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('getDocuments failed: unknown result');
};

CompanyDocumentApiClient.prototype.getFullInfoDocument = function(auth, id, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_getFullInfoDocument(auth, id);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_getFullInfoDocument(auth, id);
  }
};

CompanyDocumentApiClient.prototype.send_getFullInfoDocument = function(auth, id) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    id: id
  };
  var args = new CompanyDocumentApi_getFullInfoDocument_args(params);
  try {
    output.writeMessageBegin('getFullInfoDocument', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_getFullInfoDocument = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_getFullInfoDocument_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('getFullInfoDocument failed: unknown result');
};

CompanyDocumentApiClient.prototype.download = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_download(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_download(auth, data);
  }
};

CompanyDocumentApiClient.prototype.send_download = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyDocumentApi_download_args(params);
  try {
    output.writeMessageBegin('download', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyDocumentApiClient.prototype.recv_download = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyDocumentApi_download_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('download failed: unknown result');
};
var CompanyDocumentApiProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
CompanyDocumentApiProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
CompanyDocumentApiProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new CompanyDocumentApi_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health.bind(this._handler)
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_health_result({success: result});
      output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
      output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new CompanyDocumentApi_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_count = function(seqid, input, output) {
  var args = new CompanyDocumentApi_count_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.count.length === 1) {
    Q.fcall(this._handler.count.bind(this._handler),
      args.auth
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_count_result({success: result});
      output.writeMessageBegin("count", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_count_result(err);
        output.writeMessageBegin("count", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("count", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.count(args.auth, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_count_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("count", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("count", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_pending = function(seqid, input, output) {
  var args = new CompanyDocumentApi_pending_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.pending.length === 2) {
    Q.fcall(this._handler.pending.bind(this._handler),
      args.auth,
      args.filter
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_pending_result({success: result});
      output.writeMessageBegin("pending", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_pending_result(err);
        output.writeMessageBegin("pending", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("pending", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.pending(args.auth, args.filter, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_pending_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("pending", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("pending", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_validated = function(seqid, input, output) {
  var args = new CompanyDocumentApi_validated_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.validated.length === 2) {
    Q.fcall(this._handler.validated.bind(this._handler),
      args.auth,
      args.filter
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_validated_result({success: result});
      output.writeMessageBegin("validated", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_validated_result(err);
        output.writeMessageBegin("validated", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("validated", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.validated(args.auth, args.filter, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_validated_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("validated", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("validated", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_rejected = function(seqid, input, output) {
  var args = new CompanyDocumentApi_rejected_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.rejected.length === 2) {
    Q.fcall(this._handler.rejected.bind(this._handler),
      args.auth,
      args.filter
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_rejected_result({success: result});
      output.writeMessageBegin("rejected", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_rejected_result(err);
        output.writeMessageBegin("rejected", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("rejected", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.rejected(args.auth, args.filter, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_rejected_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("rejected", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("rejected", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_getDocuments = function(seqid, input, output) {
  var args = new CompanyDocumentApi_getDocuments_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.getDocuments.length === 2) {
    Q.fcall(this._handler.getDocuments.bind(this._handler),
      args.auth,
      args.getDocumentsRequest
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_getDocuments_result({success: result});
      output.writeMessageBegin("getDocuments", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_getDocuments_result(err);
        output.writeMessageBegin("getDocuments", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getDocuments", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.getDocuments(args.auth, args.getDocumentsRequest, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_getDocuments_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("getDocuments", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getDocuments", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_getFullInfoDocument = function(seqid, input, output) {
  var args = new CompanyDocumentApi_getFullInfoDocument_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.getFullInfoDocument.length === 2) {
    Q.fcall(this._handler.getFullInfoDocument.bind(this._handler),
      args.auth,
      args.id
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_getFullInfoDocument_result({success: result});
      output.writeMessageBegin("getFullInfoDocument", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_getFullInfoDocument_result(err);
        output.writeMessageBegin("getFullInfoDocument", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getFullInfoDocument", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.getFullInfoDocument(args.auth, args.id, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_getFullInfoDocument_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("getFullInfoDocument", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getFullInfoDocument", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyDocumentApiProcessor.prototype.process_download = function(seqid, input, output) {
  var args = new CompanyDocumentApi_download_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.download.length === 2) {
    Q.fcall(this._handler.download.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyDocumentApi_download_result({success: result});
      output.writeMessageBegin("download", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyDocumentApi_download_result(err);
        output.writeMessageBegin("download", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("download", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.download(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyDocumentApi_download_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("download", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("download", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
