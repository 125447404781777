/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Auth = module.exports.Auth = function(args) {
  this.id = null;
  this.securityToken = null;
  this.appName = null;
  this.requestId = null;
  this.correlationId = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.securityToken !== undefined && args.securityToken !== null) {
      this.securityToken = args.securityToken;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
    if (args.requestId !== undefined && args.requestId !== null) {
      this.requestId = args.requestId;
    }
    if (args.correlationId !== undefined && args.correlationId !== null) {
      this.correlationId = args.correlationId;
    }
  }
};
Auth.prototype = {};
Auth.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.securityToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.requestId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.correlationId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Auth.prototype.write = function(output) {
  output.writeStructBegin('Auth');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.securityToken !== null && this.securityToken !== undefined) {
    output.writeFieldBegin('securityToken', Thrift.Type.STRING, 2);
    output.writeString(this.securityToken);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 3);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  if (this.requestId !== null && this.requestId !== undefined) {
    output.writeFieldBegin('requestId', Thrift.Type.STRING, 4);
    output.writeString(this.requestId);
    output.writeFieldEnd();
  }
  if (this.correlationId !== null && this.correlationId !== undefined) {
    output.writeFieldBegin('correlationId', Thrift.Type.STRING, 5);
    output.writeString(this.correlationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AddPolicyRequest = module.exports.AddPolicyRequest = function(args) {
  this.id = null;
  this.policyId = null;
  this.policyType = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.policyId !== undefined && args.policyId !== null) {
      this.policyId = args.policyId;
    }
    if (args.policyType !== undefined && args.policyType !== null) {
      this.policyType = args.policyType;
    }
  }
};
AddPolicyRequest.prototype = {};
AddPolicyRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.policyId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.policyType = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AddPolicyRequest.prototype.write = function(output) {
  output.writeStructBegin('AddPolicyRequest');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.policyId !== null && this.policyId !== undefined) {
    output.writeFieldBegin('policyId', Thrift.Type.STRING, 2);
    output.writeString(this.policyId);
    output.writeFieldEnd();
  }
  if (this.policyType !== null && this.policyType !== undefined) {
    output.writeFieldBegin('policyType', Thrift.Type.STRING, 3);
    output.writeString(this.policyType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TryVerify = module.exports.TryVerify = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
TryVerify.prototype = {};
TryVerify.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TryVerify.prototype.write = function(output) {
  output.writeStructBegin('TryVerify');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Upload = module.exports.Upload = function(args) {
  this.id = null;
  this.description = null;
  this.content = null;
  this.policyType = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.content !== undefined && args.content !== null) {
      this.content = args.content;
    }
    if (args.policyType !== undefined && args.policyType !== null) {
      this.policyType = args.policyType;
    }
  }
};
Upload.prototype = {};
Upload.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.content = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.policyType = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Upload.prototype.write = function(output) {
  output.writeStructBegin('Upload');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 2);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.content !== null && this.content !== undefined) {
    output.writeFieldBegin('content', Thrift.Type.STRING, 3);
    output.writeString(this.content);
    output.writeFieldEnd();
  }
  if (this.policyType !== null && this.policyType !== undefined) {
    output.writeFieldBegin('policyType', Thrift.Type.STRING, 4);
    output.writeString(this.policyType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Download = module.exports.Download = function(args) {
  this.id = null;
  this.storageRef = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.storageRef !== undefined && args.storageRef !== null) {
      this.storageRef = args.storageRef;
    }
  }
};
Download.prototype = {};
Download.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.storageRef = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Download.prototype.write = function(output) {
  output.writeStructBegin('Download');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.storageRef !== null && this.storageRef !== undefined) {
    output.writeFieldBegin('storageRef', Thrift.Type.STRING, 2);
    output.writeString(this.storageRef);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Verify = module.exports.Verify = function(args) {
  this.id = null;
  this.token = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.token !== undefined && args.token !== null) {
      this.token = args.token;
    }
  }
};
Verify.prototype = {};
Verify.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Verify.prototype.write = function(output) {
  output.writeStructBegin('Verify');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.token !== null && this.token !== undefined) {
    output.writeFieldBegin('token', Thrift.Type.STRING, 2);
    output.writeString(this.token);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Validate = module.exports.Validate = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
Validate.prototype = {};
Validate.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Validate.prototype.write = function(output) {
  output.writeStructBegin('Validate');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PauseRequest = module.exports.PauseRequest = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
PauseRequest.prototype = {};
PauseRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PauseRequest.prototype.write = function(output) {
  output.writeStructBegin('PauseRequest');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Reject = module.exports.Reject = function(args) {
  this.id = null;
  this.message = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Reject.prototype = {};
Reject.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Reject.prototype.write = function(output) {
  output.writeStructBegin('Reject');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Pagination = module.exports.Pagination = function(args) {
  this.itemsPerPage = null;
  this.pageNumber = null;
  if (args) {
    if (args.itemsPerPage !== undefined && args.itemsPerPage !== null) {
      this.itemsPerPage = args.itemsPerPage;
    }
    if (args.pageNumber !== undefined && args.pageNumber !== null) {
      this.pageNumber = args.pageNumber;
    }
  }
};
Pagination.prototype = {};
Pagination.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.itemsPerPage = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.pageNumber = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Pagination.prototype.write = function(output) {
  output.writeStructBegin('Pagination');
  if (this.itemsPerPage !== null && this.itemsPerPage !== undefined) {
    output.writeFieldBegin('itemsPerPage', Thrift.Type.I32, 1);
    output.writeI32(this.itemsPerPage);
    output.writeFieldEnd();
  }
  if (this.pageNumber !== null && this.pageNumber !== undefined) {
    output.writeFieldBegin('pageNumber', Thrift.Type.I32, 2);
    output.writeI32(this.pageNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Filter = module.exports.Filter = function(args) {
  this.pagination = null;
  if (args) {
    if (args.pagination !== undefined && args.pagination !== null) {
      this.pagination = new ttypes.Pagination(args.pagination);
    }
  }
};
Filter.prototype = {};
Filter.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.pagination = new ttypes.Pagination();
        this.pagination.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Filter.prototype.write = function(output) {
  output.writeStructBegin('Filter');
  if (this.pagination !== null && this.pagination !== undefined) {
    output.writeFieldBegin('pagination', Thrift.Type.STRUCT, 1);
    this.pagination.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Counter = module.exports.Counter = function(args) {
  this.pending = null;
  this.validated = null;
  this.rejected = null;
  if (args) {
    if (args.pending !== undefined && args.pending !== null) {
      this.pending = args.pending;
    }
    if (args.validated !== undefined && args.validated !== null) {
      this.validated = args.validated;
    }
    if (args.rejected !== undefined && args.rejected !== null) {
      this.rejected = args.rejected;
    }
  }
};
Counter.prototype = {};
Counter.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.pending = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.validated = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.rejected = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Counter.prototype.write = function(output) {
  output.writeStructBegin('Counter');
  if (this.pending !== null && this.pending !== undefined) {
    output.writeFieldBegin('pending', Thrift.Type.I32, 1);
    output.writeI32(this.pending);
    output.writeFieldEnd();
  }
  if (this.validated !== null && this.validated !== undefined) {
    output.writeFieldBegin('validated', Thrift.Type.I32, 2);
    output.writeI32(this.validated);
    output.writeFieldEnd();
  }
  if (this.rejected !== null && this.rejected !== undefined) {
    output.writeFieldBegin('rejected', Thrift.Type.I32, 3);
    output.writeI32(this.rejected);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Document = module.exports.Document = function(args) {
  this.user = null;
  this.id = null;
  this.storageRef = null;
  this.description = null;
  this.createdAt = null;
  this.message = null;
  this.updatedAt = null;
  this.policyType = null;
  this.status = null;
  this.externallyValidated = null;
  this.paused = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = args.user;
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.storageRef !== undefined && args.storageRef !== null) {
      this.storageRef = args.storageRef;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.createdAt !== undefined && args.createdAt !== null) {
      this.createdAt = args.createdAt;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
    if (args.updatedAt !== undefined && args.updatedAt !== null) {
      this.updatedAt = args.updatedAt;
    }
    if (args.policyType !== undefined && args.policyType !== null) {
      this.policyType = args.policyType;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.externallyValidated !== undefined && args.externallyValidated !== null) {
      this.externallyValidated = args.externallyValidated;
    }
    if (args.paused !== undefined && args.paused !== null) {
      this.paused = args.paused;
    }
  }
};
Document.prototype = {};
Document.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.storageRef = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.createdAt = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.updatedAt = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.policyType = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.status = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.BOOL) {
        this.externallyValidated = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.BOOL) {
        this.paused = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Document.prototype.write = function(output) {
  output.writeStructBegin('Document');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRING, 1);
    output.writeString(this.user);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.storageRef !== null && this.storageRef !== undefined) {
    output.writeFieldBegin('storageRef', Thrift.Type.STRING, 3);
    output.writeString(this.storageRef);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 4);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.createdAt !== null && this.createdAt !== undefined) {
    output.writeFieldBegin('createdAt', Thrift.Type.I64, 5);
    output.writeI64(this.createdAt);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 6);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  if (this.updatedAt !== null && this.updatedAt !== undefined) {
    output.writeFieldBegin('updatedAt', Thrift.Type.I64, 7);
    output.writeI64(this.updatedAt);
    output.writeFieldEnd();
  }
  if (this.policyType !== null && this.policyType !== undefined) {
    output.writeFieldBegin('policyType', Thrift.Type.STRING, 8);
    output.writeString(this.policyType);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRING, 9);
    output.writeString(this.status);
    output.writeFieldEnd();
  }
  if (this.externallyValidated !== null && this.externallyValidated !== undefined) {
    output.writeFieldBegin('externallyValidated', Thrift.Type.BOOL, 10);
    output.writeBool(this.externallyValidated);
    output.writeFieldEnd();
  }
  if (this.paused !== null && this.paused !== undefined) {
    output.writeFieldBegin('paused', Thrift.Type.BOOL, 11);
    output.writeBool(this.paused);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Info = module.exports.Info = function(args) {
  this.user = null;
  this.state = null;
  this.createdAt = null;
  this.message = null;
  this.updatedAt = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = args.user;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.createdAt !== undefined && args.createdAt !== null) {
      this.createdAt = args.createdAt;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
    if (args.updatedAt !== undefined && args.updatedAt !== null) {
      this.updatedAt = args.updatedAt;
    }
  }
};
Info.prototype = {};
Info.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.state = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.createdAt = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.updatedAt = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Info.prototype.write = function(output) {
  output.writeStructBegin('Info');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRING, 1);
    output.writeString(this.user);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.STRING, 2);
    output.writeString(this.state);
    output.writeFieldEnd();
  }
  if (this.createdAt !== null && this.createdAt !== undefined) {
    output.writeFieldBegin('createdAt', Thrift.Type.I64, 3);
    output.writeI64(this.createdAt);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 4);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  if (this.updatedAt !== null && this.updatedAt !== undefined) {
    output.writeFieldBegin('updatedAt', Thrift.Type.I64, 5);
    output.writeI64(this.updatedAt);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Documents = module.exports.Documents = function(args) {
  this.documents = null;
  this.totalItems = null;
  this.pageNumber = null;
  if (args) {
    if (args.documents !== undefined && args.documents !== null) {
      this.documents = Thrift.copyList(args.documents, [ttypes.Document]);
    }
    if (args.totalItems !== undefined && args.totalItems !== null) {
      this.totalItems = args.totalItems;
    }
    if (args.pageNumber !== undefined && args.pageNumber !== null) {
      this.pageNumber = args.pageNumber;
    }
  }
};
Documents.prototype = {};
Documents.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.documents = [];
        var _rtmp31 = input.readListBegin();
        var _size0 = _rtmp31.size || 0;
        for (var _i2 = 0; _i2 < _size0; ++_i2) {
          var elem3 = null;
          elem3 = new ttypes.Document();
          elem3.read(input);
          this.documents.push(elem3);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.totalItems = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.pageNumber = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Documents.prototype.write = function(output) {
  output.writeStructBegin('Documents');
  if (this.documents !== null && this.documents !== undefined) {
    output.writeFieldBegin('documents', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents.length);
    for (var iter4 in this.documents) {
      if (this.documents.hasOwnProperty(iter4)) {
        iter4 = this.documents[iter4];
        iter4.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.totalItems !== null && this.totalItems !== undefined) {
    output.writeFieldBegin('totalItems', Thrift.Type.I32, 2);
    output.writeI32(this.totalItems);
    output.writeFieldEnd();
  }
  if (this.pageNumber !== null && this.pageNumber !== undefined) {
    output.writeFieldBegin('pageNumber', Thrift.Type.I32, 3);
    output.writeI32(this.pageNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DocumentContent = module.exports.DocumentContent = function(args) {
  this.content = null;
  if (args) {
    if (args.content !== undefined && args.content !== null) {
      this.content = args.content;
    }
  }
};
DocumentContent.prototype = {};
DocumentContent.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.content = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DocumentContent.prototype.write = function(output) {
  output.writeStructBegin('DocumentContent');
  if (this.content !== null && this.content !== undefined) {
    output.writeFieldBegin('content', Thrift.Type.STRING, 1);
    output.writeString(this.content);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DocumentFilter = module.exports.DocumentFilter = function(args) {
  this.policyType = null;
  this.fiscalCode = null;
  this.reasonCode = null;
  this.email = null;
  this.externallyValidated = null;
  this.paused = null;
  if (args) {
    if (args.policyType !== undefined && args.policyType !== null) {
      this.policyType = args.policyType;
    }
    if (args.fiscalCode !== undefined && args.fiscalCode !== null) {
      this.fiscalCode = args.fiscalCode;
    }
    if (args.reasonCode !== undefined && args.reasonCode !== null) {
      this.reasonCode = args.reasonCode;
    }
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.externallyValidated !== undefined && args.externallyValidated !== null) {
      this.externallyValidated = args.externallyValidated;
    }
    if (args.paused !== undefined && args.paused !== null) {
      this.paused = args.paused;
    }
  }
};
DocumentFilter.prototype = {};
DocumentFilter.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.policyType = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.fiscalCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.reasonCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.externallyValidated = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.BOOL) {
        this.paused = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DocumentFilter.prototype.write = function(output) {
  output.writeStructBegin('DocumentFilter');
  if (this.policyType !== null && this.policyType !== undefined) {
    output.writeFieldBegin('policyType', Thrift.Type.STRING, 1);
    output.writeString(this.policyType);
    output.writeFieldEnd();
  }
  if (this.fiscalCode !== null && this.fiscalCode !== undefined) {
    output.writeFieldBegin('fiscalCode', Thrift.Type.STRING, 2);
    output.writeString(this.fiscalCode);
    output.writeFieldEnd();
  }
  if (this.reasonCode !== null && this.reasonCode !== undefined) {
    output.writeFieldBegin('reasonCode', Thrift.Type.STRING, 3);
    output.writeString(this.reasonCode);
    output.writeFieldEnd();
  }
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 4);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.externallyValidated !== null && this.externallyValidated !== undefined) {
    output.writeFieldBegin('externallyValidated', Thrift.Type.BOOL, 5);
    output.writeBool(this.externallyValidated);
    output.writeFieldEnd();
  }
  if (this.paused !== null && this.paused !== undefined) {
    output.writeFieldBegin('paused', Thrift.Type.BOOL, 6);
    output.writeBool(this.paused);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetDocumentsRequest = module.exports.GetDocumentsRequest = function(args) {
  this.pagination = null;
  this.documentsStatus = null;
  this.filter = null;
  if (args) {
    if (args.pagination !== undefined && args.pagination !== null) {
      this.pagination = new ttypes.Pagination(args.pagination);
    }
    if (args.documentsStatus !== undefined && args.documentsStatus !== null) {
      this.documentsStatus = args.documentsStatus;
    }
    if (args.filter !== undefined && args.filter !== null) {
      this.filter = new ttypes.DocumentFilter(args.filter);
    }
  }
};
GetDocumentsRequest.prototype = {};
GetDocumentsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.pagination = new ttypes.Pagination();
        this.pagination.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.documentsStatus = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.filter = new ttypes.DocumentFilter();
        this.filter.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetDocumentsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetDocumentsRequest');
  if (this.pagination !== null && this.pagination !== undefined) {
    output.writeFieldBegin('pagination', Thrift.Type.STRUCT, 1);
    this.pagination.write(output);
    output.writeFieldEnd();
  }
  if (this.documentsStatus !== null && this.documentsStatus !== undefined) {
    output.writeFieldBegin('documentsStatus', Thrift.Type.STRING, 2);
    output.writeString(this.documentsStatus);
    output.writeFieldEnd();
  }
  if (this.filter !== null && this.filter !== undefined) {
    output.writeFieldBegin('filter', Thrift.Type.STRUCT, 3);
    this.filter.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetFullInfoDocumentResponse = module.exports.GetFullInfoDocumentResponse = function(args) {
  this.content = null;
  this.documentInfo = null;
  if (args) {
    if (args.content !== undefined && args.content !== null) {
      this.content = new ttypes.DocumentContent(args.content);
    }
    if (args.documentInfo !== undefined && args.documentInfo !== null) {
      this.documentInfo = new ttypes.Document(args.documentInfo);
    }
  }
};
GetFullInfoDocumentResponse.prototype = {};
GetFullInfoDocumentResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.content = new ttypes.DocumentContent();
        this.content.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.documentInfo = new ttypes.Document();
        this.documentInfo.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetFullInfoDocumentResponse.prototype.write = function(output) {
  output.writeStructBegin('GetFullInfoDocumentResponse');
  if (this.content !== null && this.content !== undefined) {
    output.writeFieldBegin('content', Thrift.Type.STRUCT, 1);
    this.content.write(output);
    output.writeFieldEnd();
  }
  if (this.documentInfo !== null && this.documentInfo !== undefined) {
    output.writeFieldBegin('documentInfo', Thrift.Type.STRUCT, 2);
    this.documentInfo.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "GenericError");
  this.name = "GenericError";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
  Thrift.TException.call(this, "AuthenticationException");
  this.name = "AuthenticationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthenticationException.prototype.write = function(output) {
  output.writeStructBegin('AuthenticationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ValidationException = module.exports.ValidationException = function(args) {
  Thrift.TException.call(this, "ValidationException");
  this.name = "ValidationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(ValidationException, Thrift.TException);
ValidationException.prototype.name = 'ValidationException';
ValidationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ValidationException.prototype.write = function(output) {
  output.writeStructBegin('ValidationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ttypes.VERSION = '1.2.1';
