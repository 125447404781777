import React from "react";
import PropTypes from "prop-types";

import { COMMON_LOGIN_URL } from "../../config";
import { LoginButton } from "@ts-digital/digital-login-component";
import { Line, Message, Or, OrContainer, Info, Title, LoginContainer } from "./styled";
import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";

import FormLogin from "../../components/general/form-login";
import { useFeature } from "../../utils/hook/use-feature";

const Login = ({ history, login, tsLogin }) => {
    const double_login = useFeature("Double_login");
    return double_login ? (
        <div>
            <Row justify="center" type="flex">
                <Col style={{ width: "80%" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <LoginButton
                            displayedAppName="TeamSystem Digital"
                            mode="popup"
                            buttonText={<FormattedMessage id={"c-form-login.tsid.button.text"} />}
                            loginUrl={COMMON_LOGIN_URL}
                            loginMethod="TSID"
                            onLoginSuccess={tsLogin}
                        />
                    </div>
                </Col>
            </Row>

            <Row justify="center" type="flex">
                <Col style={{ width: "80%" }}>
                    <OrContainer>
                        <Line />
                        <Or>Oppure</Or>
                    </OrContainer>
                </Col>
            </Row>
            <Row justify="center" type="flex">
                <Col style={{ width: "80%" }}>
                    <Message>{<FormattedMessage id={"c-form-login.message.text"} />}</Message>
                </Col>
            </Row>
            <Row justify="center" type="flex">
                <Col style={{ width: "80%" }}>
                    <Info>{<FormattedMessage id={"c-form-login.alert.text"} />}</Info>
                </Col>
            </Row>
            <Row justify="center" type="flex">
                <Col style={{ width: "80%" }}>
                    <FormLogin
                        buttonType="primary"
                        textSummit={<FormattedMessage id="c-form-login.button.text" />}
                        onSubmit={login}
                        isResetEnable={true}
                        reset={() => history.push("/forgotPassword")}
                    />
                </Col>
            </Row>
        </div>
    ) : (
        <LoginContainer>
            <Title>
                <FormattedMessage id={"c-form-login.tsid.button.text"} />
            </Title>
            <LoginButton
                displayedAppName="TeamSystem Digital"
                mode="popup"
                buttonText="Accedi"
                loginUrl={COMMON_LOGIN_URL}
                loginMethod="TSID"
                onLoginSuccess={tsLogin}
            />
        </LoginContainer>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    tsLogin: PropTypes.func.isRequired
};

export default Login;
