import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isNil, contains } from "ramda";
import { Select, Typography, message } from "antd";

import { userIsAuthorized } from "../../../../utils/get-roles";
import { InvoiceFilter } from "../../../../components/general/invoice-filter";
import { InvoicesReceivedTable } from "./invoices-received-table";
import { Container } from "./styled";
export const InvoicesReceived = ({
    auth,
    admin,
    company,
    invoices,
    getInvoices,
    getInvoice,
    setDocument,
    history,
    untrashInvoice,
    trashInvoice,
    deleteInvoice,
    expanded,
    invoiceReceivedFilter,
    handleChangeInvoiceReceivedFilter,
    toggleReceivedTabs,
    invoiceReceivedSort,
    handleChangeInvoiceReceivedSort
}) => {
    const [invoiceReceivedPageSize, setInvoiceReceivedPageSize] = useState("25");
    const [invoiceReceivedQuery, setInvoiceReceivedQuery] = useState("");
    const [companyOptions, setCompanyOptions] = useState([]);
    const [allLayers, setAllLayers] = useState(null);
    const [companySelected, setCompanySelected] = useState(null);
    const [sdiId, setSdiId] = useState("");
    const [invoiceAuthorized, setInvoiceAuthorized] = useState(false);
    const [invoiceDataSource, setInvoiceDataSource] = useState([]);
    const [invoiceReceivedRended, setInvoiceReceivedRended] = useState(false);
    const [invoiceReceivedFilterChanged, setInvoiceReceivedFilterChanged] = useState(false);
    const pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    useEffect(() => {
        if (admin.adminInfo != null) {
            let invoiceAuthorized = userIsAuthorized("c-companyInvoices-actions-column", admin.adminInfo.permits);
            setInvoiceAuthorized(invoiceAuthorized);
        } else {
            setInvoiceAuthorized(false);
        }
    }, [admin]);

    useEffect(() => {
        let arr_layers = [company.info.base.id];
        if (company.info.layers !== null) {
            for (var node of company.info.layers) {
                arr_layers.push(node.base.hierarchyId);
            }
        }
        arr_layers.sort();
        setAllLayers(arr_layers.join(","));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (invoices.status.ended && invoices._embedded != null) {
            setInvoiceDataSource(invoices._embedded.invoiceList);
        } else {
            setInvoiceDataSource([]);
        }
    }, [invoices]);

    useEffect(() => {
        if (allLayers) {
            let companyOptions = [];
            if (company.info.layers) {
                companyOptions.push(
                    <Select.Option value={allLayers} key={allLayers}>
                        {"Tutti gli uffici"}
                    </Select.Option>
                );
                companyOptions.push(
                    <Select.Option value={company.info.base.id} key={company.info.base.id}>
                        {company.info.base.details.description + " (" + company.info.base.id + ")"}
                    </Select.Option>
                );
                var sortedresult = company.info.layers.sort((a, b) =>
                    a.base.hierarchyId > b.base.hierarchyId ? 1 : b.base.hierarchyId > a.base.hierarchyId ? -1 : 0
                );
                sortedresult.forEach(layer => {
                    companyOptions.push(
                        <Select.Option value={layer.base.hierarchyId} key={layer.base.hierarchyId}>
                            {layer.base.details.description + " (" + layer.base.hierarchyId + ")"}
                        </Select.Option>
                    );
                });
            } else {
                companyOptions.push(
                    <Select.Option value={allLayers} key={allLayers}>
                        {company.info.base.details.description + " (" + company.info.base.id + ")"}
                    </Select.Option>
                );
            }
            setCompanyOptions(companyOptions);
            if (isNil(invoiceReceivedFilter)) {
                setCompanySelected(allLayers);
                let newFilters = {
                    ownerId: [allLayers],
                    active: [false],
                    trashed: [false]
                };
                handleGetDocuments(0, newFilters, invoiceReceivedSort);
            } else {
                setCompanySelected(invoiceReceivedFilter.ownerId);
                setSdiId(invoiceReceivedFilter.sdiId);
                handleGetDocuments(0, null, invoiceReceivedSort);
            }
            setInvoiceReceivedRended(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company.info, allLayers]);

    // Get documents for each navigation except first time
    useEffect(() => {
        if (invoiceReceivedRended) {
            handleGetDocuments(0, null, invoiceReceivedSort);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleReceivedTabs]);

    const handleInvoiceReceivedPageSize = value => {
        setInvoiceReceivedPageSize(value);
        getQueries(invoiceReceivedFilter, value, invoiceReceivedSort);
        let sort = sorterToSort(invoiceReceivedSort);
        getInvoices(auth.loginAuth, invoiceReceivedFilter, 0, value, sort);
        setInvoiceReceivedFilterChanged(invoiceReceivedFilterChanged ? false : true);
    };

    const handleLoadItem = item => {
        if (userIsAuthorized("c-companyInvoices-getInvoice", admin.adminInfo.permits)) {
            getInvoice(auth.loginAuth, { hubId: item.hubId }, 0, 10).then(result => {
                setDocument(result);
                history.push(`received/${item.hubId}`);
            });
        }
    };

    const managetrashInvoice = item => {
        if (invoiceReceivedFilter.trashed) {
            untrashInvoice(auth.loginAuth, item.hubId);
        } else {
            trashInvoice(auth.loginAuth, item.hubId);
        }
    };
    const managedeleteInvoice = item => {
        deleteInvoice(auth.loginAuth, item.hubId);
    };

    const handleGetDocuments = (page, filters, sorter) => {
        let filter = invoiceReceivedFilter || {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "currentStatusName") {
                        filter.statusName = filters[key][0];
                    } else if (key === "ownerId") {
                        filter.ownerId = filters[key][0];
                    } else if (key === "senderIds") {
                        filter.senderIds = filters[key][0];
                    } else if (key === "active") {
                        filter.active = filters[key][0];
                    } else if (key === "trashed") {
                        filter.trashed = filters[key][0];
                    } else if (key === "senderName") {
                        filter.senderName = filters[key][0];
                    } else if (key === "docId") {
                        filter.docId = filters[key][0];
                    } else if (key === "flowType") {
                        filter.flowTypes = filters[key][0];
                    } else if (key === "sdiId") {
                        filter.sdiId = filters[key][0];
                    }
                }
            }
        }
        let sort = sorterToSort(sorter);
        getQueries(filter, null, sorter);
        handleChangeInvoiceReceivedFilter(filter);
        handleChangeInvoiceReceivedSort(sorter);
        getInvoices(auth.loginAuth, filter, 0, invoiceReceivedPageSize, sort);
        setInvoiceReceivedFilterChanged(invoiceReceivedFilterChanged ? false : true);
    };

    const sorterToSort = sorter => {
        let sort = {};
        if (sorter) {
            if (sorter.columnKey === "docDate") {
                if (sorter.order === "ascend") {
                    sort = { sort: "invoiceDetail.dataDocumento,asc" };
                } else {
                    sort = { sort: "invoiceDetail.dataDocumento,desc" };
                }
            } else if (sorter.columnKey === "sdiReceiptDate") {
                if (sorter.order === "ascend") {
                    sort = { sort: "sdiReceiptDate,asc" };
                } else {
                    sort = { sort: "sdiReceiptDate,desc" };
                }
            }
        }
        return sort;
    };

    const getQueries = (currentFilters, pageSize, currentSort) => {
        let size = pageSize || invoiceReceivedPageSize;
        let listFilter = [];
        if (currentFilters) {
            let keys = Object.keys(currentFilters);
            for (let key of keys) {
                if (currentFilters[key] != null && currentFilters[key] !== "") {
                    if (contains("ownerId", key) && contains(",", currentFilters[key])) {
                        listFilter.push(key + "=ALL");
                    } else {
                        if (key === "statusName") {
                            let value =
                                currentFilters[key] === "CARICATO"
                                    ? "IN_ELABORAZIONE"
                                    : currentFilters[key] === "INVIATO"
                                    ? "EMESSA"
                                    : currentFilters[key];
                            listFilter.push(key + "=" + value);
                        } else {
                            listFilter.push(key + "=" + currentFilters[key]);
                        }
                    }
                }
            }
            listFilter.push("size=" + size);
            if (currentSort && currentSort.columnKey) {
                listFilter.push("sort=" + currentSort.columnKey + "," + currentSort.order);
            }
        }
        let nueva = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setInvoiceReceivedQuery(nueva);
    };

    const handleClearFilter = () => {
        invoiceReceivedFilter.senderName = null;
        invoiceReceivedFilter.docId = null;
        invoiceReceivedFilter.sdiId = null;
        invoiceReceivedFilter.flowTypes = null;
        invoiceReceivedFilter.statusName = null;
        invoiceReceivedFilter.ownerId = allLayers;
        invoiceReceivedFilter.active = false;
        invoiceReceivedFilter.trashed = false;
        setCompanySelected(allLayers);
        setSdiId("");
        handleChangeInvoiceReceivedFilter(invoiceReceivedFilter);
        // let sort = { columnKey: "docDate", order: "descend" };
        handleChangeInvoiceReceivedSort(null);
        handleGetDocuments(0, null, null);
        setInvoiceReceivedFilterChanged(invoiceReceivedFilterChanged ? false : true);
    };

    const handleCompanyChanged = value => {
        setCompanySelected(value);
        invoiceReceivedFilter.ownerId = value;
        handleChangeInvoiceReceivedFilter(invoiceReceivedFilter);
        handleGetDocuments(0, null, invoiceReceivedSort);
        setInvoiceReceivedFilterChanged(invoiceReceivedFilterChanged ? false : true);
    };

    const handleSdiIdChanging = e => {
        setSdiId(e.target.value);
    };

    const handleSdiIdChanged = e => {
        let searchValue = e.target.value;
        invoiceReceivedFilter.sdiId = null;
        handleChangeInvoiceReceivedFilter(invoiceReceivedFilter);
        if (searchValue !== "") {
            if (searchValue.length >= 6) {
                getInvoice(auth.loginAuth, { sdiId: searchValue.trim() }, 0, 10).then(response => {
                    if (response == null || response.page == null || response.page.size === 0) {
                        message.error("SdiId inserito non trovato.");
                    } else {
                        let result = response._embedded.invoiceList.find(
                            x => x.recipientId === company.info.base.id && x.active === false
                        );
                        if (result) {
                            handleLoadItem(result);
                        } else {
                            message.error("SdiId inserito non trovato.");
                        }
                    }
                });
            } else {
                message.error("SdiId inserito non valido.");
            }
        } else {
            message.error("SdiId inserito non valido.");
        }
    };

    const handleInvoiceTrashedChanged = value => {
        invoiceReceivedFilter.trashed = value;
        handleChangeInvoiceReceivedFilter(invoiceReceivedFilter);
        handleGetDocuments(0, null, invoiceReceivedSort);
        setInvoiceReceivedFilterChanged(invoiceReceivedFilterChanged ? false : true);
    };

    return (
        <Container>
            <InvoiceFilter
                disabledClearFilter={
                    invoiceReceivedFilter == null ||
                    (invoiceReceivedFilter.senderName == null &&
                        invoiceReceivedFilter.docId == null &&
                        invoiceReceivedFilter.sdiId == null &&
                        invoiceReceivedFilter.flowTypes == null &&
                        invoiceReceivedFilter.statusName == null &&
                        invoiceReceivedFilter.ownerId === allLayers &&
                        invoiceReceivedFilter.active === false &&
                        invoiceReceivedFilter.trashed === false)
                }
                handleClearFilter={handleClearFilter}
                companyOptions={companyOptions}
                disabledCompanyOptions={!company.info.layers}
                companySelected={companySelected}
                handleCompanyChanged={handleCompanyChanged}
                sdiId={sdiId}
                handleSdiIdChanging={handleSdiIdChanging}
                handleSdiIdChanged={handleSdiIdChanged}
                invoiceTrashed={invoiceReceivedFilter && invoiceReceivedFilter.trashed}
                handleInvoiceTrashedChanged={handleInvoiceTrashedChanged}
            />
            <InvoicesReceivedTable
                admin={admin}
                invoiceReceivedFilter={invoiceReceivedFilter}
                invoiceReceivedSort={invoiceReceivedSort}
                handleLoadItem={handleLoadItem}
                managetrashInvoice={managetrashInvoice}
                managedeleteInvoice={managedeleteInvoice}
                invoiceReceivedPageSize={invoiceReceivedPageSize}
                handleInvoiceReceivedPageSize={handleInvoiceReceivedPageSize}
                pageSizeOptions={pageSizeOptions}
                invoiceReceivedQuery={invoiceReceivedQuery}
                invoiceDataSource={invoiceDataSource}
                expanded={expanded}
                invoiceLoading={invoices.status.started && !invoices.status.error}
                handleGetDocuments={handleGetDocuments}
                invoiceAuthorized={invoiceAuthorized}
                invoiceReceivedFilterChanged={invoiceReceivedFilterChanged}
            />
        </Container>
    );
};

InvoicesReceived.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getInvoices: PropTypes.func.isRequired,
    getInvoice: PropTypes.func.isRequired,
    setDocument: PropTypes.func.isRequired,
    trashInvoice: PropTypes.func.isRequired,
    untrashInvoice: PropTypes.func.isRequired,
    deleteInvoice: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    invoices: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    invoiceReceivedFilter: PropTypes.object,
    handleChangeInvoiceReceivedFilter: PropTypes.func.isRequired,
    toggleReceivedTabs: PropTypes.bool.isRequired,
    invoiceReceivedSort: PropTypes.object,
    handleChangeInvoiceReceivedSort: PropTypes.func.isRequired
};
