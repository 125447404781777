import React, { Component } from "react";
import { LOGO_COLOR } from "../../../config";
class DeviceNotSupported extends Component {
    render() {
        return (
            <div style={{ textAlign: "center", marginTop: "20vh" }}>
                <div>
                    <img
                        alt=""
                        src={LOGO_COLOR}
                        style={{
                            height: "64px",
                            width: "180px"
                        }}
                    />
                </div>
                <br />
                <br />
                <i className="fas fa-mobile" style={{ fontSize: "130px" }} />
                <br />
                <br />
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>{"Dispositivo non supportato"}</p>
            </div>
        );
    }
}

export default DeviceNotSupported;
