import { INVOICES_URL } from "../../config";
import { getRestCall, postRestCall } from "../../utils/rest-api-call";

export const GET_UNASSIGNED_INVOICES_START = "GET_UNASSIGNED_INVOICES_START";
export const GET_UNASSIGNED_INVOICES_SUCCESS = "GET_UNASSIGNED_INVOICES_SUCCESS";
export const GET_UNASSIGNED_INVOICES_ERROR = "GET_UNASSIGNED_INVOICES_ERROR";

export function getUnassignedInvoices(auth, taxId, codeId, countryId) {
    return async dispatch => {
        const urlParams = {
            taxId: taxId,
            codeId: codeId,
            countryId: countryId
        };
        dispatch({
            type: GET_UNASSIGNED_INVOICES_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${INVOICES_URL}/invoices/unassigned`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_UNASSIGNED_INVOICES_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_UNASSIGNED_INVOICES_ERROR,
                error: e
            });
        }
    };
}

export const GET_NOTASSIGNABLED_INVOICES_START = "GET_NOTASSIGNABLED_INVOICES_START";
export const GET_NOTASSIGNABLED_INVOICES_SUCCESS = "GET_NOTASSIGNABLED_INVOICES_SUCCESS";
export const GET_NOTASSIGNABLED_INVOICES_ERROR = "GET_NOTASSIGNABLED_INVOICES_ERROR";

export function getNotAssignabledInvoices(auth, taxId, codeId, countryId) {
    return async dispatch => {
        const urlParams = {
            taxId: codeId,
            codeId: taxId,
            countryId: countryId
        };
        dispatch({
            type: GET_NOTASSIGNABLED_INVOICES_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${INVOICES_URL}/invoices/unassigned`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_NOTASSIGNABLED_INVOICES_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_NOTASSIGNABLED_INVOICES_ERROR,
                error: e
            });
        }
    };
}

export const POST_UNASSIGNED_INVOICES_START = "POST_UNASSIGNED_INVOICES_START";
export const POST_UNASSIGNED_INVOICES_SUCCESS = "POST_UNASSIGNED_INVOICES_SUCCESS";
export const POST_UNASSIGNED_INVOICES_ERROR = "POST_UNASSIGNED_INVOICES_ERROR";

export function postUnassignedInvoices(auth, taxId, codeId, countryId) {
    return async dispatch => {
        const urlParams = {
            taxId: taxId,
            codeId: codeId,
            countryId: countryId
        };
        dispatch({
            type: POST_UNASSIGNED_INVOICES_START,
            request: urlParams
        });

        const toast = {
            onOk: true,
            onOkText: "Richiesta assegnazione fatture presa in carico",
            onError: true,
            infoReq: { authId: auth.id, req: urlParams }
        };

        try {
            const result = await postRestCall(
                `${INVOICES_URL}/invoices/unassigned`,
                auth,
                urlParams,
                {},
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: POST_UNASSIGNED_INVOICES_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: POST_UNASSIGNED_INVOICES_ERROR,
                error: e
            });
        }
    };
}

export const POST_NOTASSIGNABLED_INVOICES_START = "POST_NOTASSIGNABLED_INVOICES_START";
export const POST_NOTASSIGNABLED_INVOICES_SUCCESS = "POST_NOTASSIGNABLED_INVOICES_SUCCESS";
export const POST_NOTASSIGNABLED_INVOICES_ERROR = "POST_NOTASSIGNABLED_INVOICES_ERROR";

export function postNotAssignabledInvoices(auth, taxId, codeId, countryId) {
    return async dispatch => {
        const urlParams = {
            taxId: codeId,
            codeId: taxId,
            countryId: countryId
        };
        dispatch({
            type: POST_NOTASSIGNABLED_INVOICES_START,
            request: urlParams
        });

        const toast = {
            onOk: true,
            onOkText: "Richiesta not assegnazione fatture presa in carico",
            onError: true,
            infoReq: { authId: auth.id, req: urlParams }
        };

        try {
            const result = await postRestCall(
                `${INVOICES_URL}/invoices/unassigned`,
                auth,
                urlParams,
                {},
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: POST_NOTASSIGNABLED_INVOICES_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: POST_NOTASSIGNABLED_INVOICES_ERROR,
                error: e
            });
        }
    };
}
