import { API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_PAYMENTS_START = "GET_PAYMENTS_START";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR";

export const getPayments = (auth, filter, size, sort) => async dispatch => {
    const urlParams = {
        ...sort,
        ...filter,
        size: size
    };
    dispatch({
        type: GET_PAYMENTS_START,
        request: urlParams
    });
    try {
        const result = await getRestCall(`${API_URL}/paymentRequest`, auth, urlParams, dispatch, auth.refreshToken);

        dispatch({
            type: GET_PAYMENTS_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_PAYMENTS_ERROR,
            error: e
        });
    }
};
