import React, { useEffect, useState } from "react";
import { isNil } from "ramda";
import { Select, Typography, message } from "antd";
import { saveAs } from "file-saver";

export const useBankLinkView = (
    auth,
    intl,
    history,
    getBankLinkRules,
    getBankLinkAssociation,
    getBankLinkAssociations,
    getBankLinkBanks,
    getBankLinkFiles,
    getBankLinkDownloadFile
) => {
    let defaultPageSize = "25";
    let pageSizeOptions = [
        <Select.Option key="10" value="10">
            10
        </Select.Option>,
        <Select.Option key="25" value="25">
            25
        </Select.Option>,
        <Select.Option key="50" value="50">
            50
        </Select.Option>,
        <Select.Option key="100" value="100">
            100
        </Select.Option>,
        <Select.Option key="250" value="250">
            250
        </Select.Option>
    ];

    // <----- Rules ----->
    const [rulesListLoading, setRulesListLoading] = useState(true);
    const [rulesList, setRulesList] = useState([]);
    const [rulesListPageSize, setRulesListPageSize] = useState(defaultPageSize);
    const [rulesListCurrentPage, setRulesListCurrentPage] = useState(1);
    const [rulesListHasNextPage, setRulesListHasNextPage] = useState(false);
    const [rulesListFilter, setRulesListFilter] = useState(null);
    const [rulesListExpanded, setRulesListExpanded] = useState(false);
    const [rulesListContinuationToken, setRulesListContinuationToken] = useState([null]);
    const [rulesListQuery, setRulesListQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        if (rulesListFilter && rulesListFilter.groupId) {
            listFilter.push("groupId=" + rulesListFilter.groupId);
        }
        listFilter.push("size=" + rulesListPageSize);
        if (rulesListContinuationToken && rulesListContinuationToken[rulesListCurrentPage - 1]) {
            listFilter.push("continuationToken=" + rulesListContinuationToken[rulesListCurrentPage - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setRulesListQuery(query);
    }, [rulesListCurrentPage, rulesListPageSize, rulesListFilter, rulesListContinuationToken]);

    useEffect(() => {
        const getRules = async () => {
            let filter = {
                ...rulesListFilter,
                continuationToken: rulesListContinuationToken[rulesListCurrentPage - 1]
            };
            let resultRules = await getBankLinkRules(auth, filter, rulesListPageSize);
            if (
                resultRules &&
                resultRules._embedded &&
                resultRules._embedded.extractionExpressionEntityList &&
                resultRules._embedded.extractionExpressionEntityList.length
            ) {
                setRulesList(resultRules._embedded.extractionExpressionEntityList);
                setRulesListHasNextPage(resultRules.page.hasNext);
                if (rulesListContinuationToken.length <= rulesListCurrentPage) {
                    rulesListContinuationToken.push(resultRules.page.continuationToken);
                }
            }
            setRulesListContinuationToken(rulesListContinuationToken);
            setRulesListLoading(false);
        };

        setRulesListLoading(true);
        setRulesList([]);
        getRules();
    }, [auth, rulesListContinuationToken, getBankLinkRules, rulesListCurrentPage, rulesListFilter, rulesListPageSize]);

    const rulesListHandlePageSizeChange = pageSize => {
        if (pageSize) {
            setRulesListCurrentPage(1);
            setRulesListPageSize(parseInt(pageSize));
            setRulesListContinuationToken([null]);
        }
    };

    const rulesListHandleFilterChange = filters => {
        let filter = {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "groupId") {
                        filter.groupId = filters[key][0];
                    }
                }
            }
        }
        setRulesListCurrentPage(1);
        setRulesListContinuationToken([null]);
        setRulesListFilter(filter);
    };

    const rulesListHandleExpandedChange = value => {
        setRulesListExpanded(value);
    };

    const rulesListHandleNextPage = () => {
        if (rulesListHasNextPage) {
            setRulesListCurrentPage(rulesListCurrentPage + 1);
        }
    };

    const rulesListHandlePreviousPage = () => {
        if (rulesListCurrentPage !== 1) {
            setRulesListCurrentPage(rulesListCurrentPage - 1);
        }
    };

    const rulesListHandleEditRule = item => {
        history.push(`bank-link/bank-link-rules/${item.id}`);
    };

    const rulesListHandleNewRule = () => {
        history.push(`bank-link/bank-link-rules/new`);
    };

    const rulesListHandleDeleteRule = item => {};

    //  <----- Associations ----->
    const [associationsListLoading, setAssociationsListLoading] = useState(true);
    const [associationsList, setAssociationsList] = useState([]);
    const [associationsListPageSize, setAssociationsListPageSize] = useState(defaultPageSize);
    const [associationsListCurrentPage, setAssociationsListCurrentPage] = useState(1);
    const [associationsListHasNextPage, setAssociationsListHasNextPage] = useState(false);
    const [associationsListFilter, setAssociationsListFilter] = useState(null);
    const [associationsListContinuationToken, setAssociationsListContinuationToken] = useState([null]);
    const [associationsListQuery, setAssociationsListQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        if (associationsListFilter && associationsListFilter.name) {
            listFilter.push("name=" + associationsListFilter.name);
        }
        if (associationsListFilter && associationsListFilter.id) {
            listFilter.push("id=" + associationsListFilter.id);
        }
        listFilter.push("size=" + associationsListPageSize);
        if (associationsListContinuationToken && associationsListContinuationToken[associationsListCurrentPage - 1]) {
            listFilter.push("continuationToken=" + associationsListContinuationToken[associationsListCurrentPage - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setAssociationsListQuery(query);
    }, [
        associationsListCurrentPage,
        associationsListPageSize,
        associationsListFilter,
        associationsListContinuationToken
    ]);

    useEffect(() => {
        const getAssociations = async () => {
            let filter = {
                ...associationsListFilter,
                continuationToken: associationsListContinuationToken[associationsListCurrentPage - 1]
            };
            let resultAssociations = await getBankLinkAssociations(auth, filter, associationsListPageSize);
            if (
                resultAssociations &&
                resultAssociations._embedded &&
                resultAssociations._embedded.associationEntityList &&
                resultAssociations._embedded.associationEntityList.length
            ) {
                setAssociationsList(resultAssociations._embedded.associationEntityList);
                setAssociationsListHasNextPage(resultAssociations.page.hasNext);
                if (associationsListContinuationToken.length <= associationsListCurrentPage) {
                    associationsListContinuationToken.push(resultAssociations.page.continuationToken);
                }
            }
            setAssociationsListContinuationToken(associationsListContinuationToken);
            setAssociationsListLoading(false);
        };

        const getAssociation = async id => {
            let resultAssociation = await getBankLinkAssociation(auth, id);
            if (resultAssociation) {
                setAssociationsList([resultAssociation]);
                setAssociationsListHasNextPage(false);
            }
            setAssociationsListLoading(false);
        };

        setAssociationsListLoading(true);
        setAssociationsList([]);
        if (associationsListFilter && associationsListFilter.id) {
            getAssociation(associationsListFilter.id);
        } else {
            getAssociations();
        }
    }, [
        auth,
        associationsListContinuationToken,
        getBankLinkAssociation,
        getBankLinkAssociations,
        associationsListCurrentPage,
        associationsListFilter,
        associationsListPageSize
    ]);

    const associationsListHandlePageSizeChange = pageSize => {
        if (pageSize) {
            setAssociationsListCurrentPage(1);
            setAssociationsListPageSize(parseInt(pageSize));
            setAssociationsListContinuationToken([null]);
        }
    };

    const associationsListHandleFilterChange = filters => {
        let filter = {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "name") {
                        filter.name = filters[key][0];
                    } else if (key === "id") {
                        filter.id = filters[key][0];
                    }
                }
            }
        }
        setAssociationsListCurrentPage(1);
        setAssociationsListContinuationToken([null]);
        setAssociationsListFilter(filter);
    };

    const associationsListHandleNextPage = () => {
        if (associationsListHasNextPage) {
            setAssociationsListCurrentPage(associationsListCurrentPage + 1);
        }
    };

    const associationsListHandlePreviousPage = () => {
        if (associationsListCurrentPage !== 1) {
            setAssociationsListCurrentPage(associationsListCurrentPage - 1);
        }
    };

    const associationsListHandleEditAssociation = item => {
        history.push(`bank-link/bank-link-associations/${item.id}`);
    };

    const associationsListHandleNewAssociation = () => {
        history.push(`bank-link/bank-link-associations/new`);
    };

    const associationsListHandleDeleteRule = item => {};

    //  <----- Banks ----->
    const [banksListLoading, setBanksListLoading] = useState(true);
    const [banksList, setBanksList] = useState([]);
    const [banksListPageSize, setBanksListPageSize] = useState(defaultPageSize);
    const [banksListCurrentPage, setBanksListCurrentPage] = useState(1);
    const [banksListHasNextPage, setBanksListHasNextPage] = useState(false);
    const [banksListFilter, setBanksListFilter] = useState(null);
    const [banksListContinuationToken, setBanksListContinuationToken] = useState([null]);
    const [banksListQuery, setBanksListQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        if (banksListFilter && banksListFilter.name) {
            listFilter.push("name=" + banksListFilter.name);
        }
        if (banksListFilter && banksListFilter.abi) {
            listFilter.push("abi=" + banksListFilter.abi);
        }
        if (banksListFilter && banksListFilter.associationId) {
            listFilter.push("associationId=" + banksListFilter.associationId);
        }
        listFilter.push("size=" + banksListPageSize);
        if (banksListContinuationToken && banksListContinuationToken[banksListCurrentPage - 1]) {
            listFilter.push("continuationToken=" + banksListContinuationToken[banksListCurrentPage - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setBanksListQuery(query);
    }, [banksListCurrentPage, banksListPageSize, banksListFilter, banksListContinuationToken]);

    useEffect(() => {
        const getBanks = async () => {
            let filter = {
                ...banksListFilter,
                continuationToken: banksListContinuationToken[banksListCurrentPage - 1]
            };
            let resultBanks = await getBankLinkBanks(auth, filter, banksListPageSize);
            if (
                resultBanks &&
                resultBanks._embedded &&
                resultBanks._embedded.bankEntityList &&
                resultBanks._embedded.bankEntityList.length
            ) {
                setBanksList(resultBanks._embedded.bankEntityList);
                setBanksListHasNextPage(resultBanks.page.hasNext);
                if (banksListContinuationToken.length <= banksListCurrentPage) {
                    banksListContinuationToken.push(resultBanks.page.continuationToken);
                }
            }
            setBanksListContinuationToken(banksListContinuationToken);
            setBanksListLoading(false);
        };

        setBanksListLoading(true);
        setBanksList([]);
        getBanks();
    }, [auth, banksListContinuationToken, getBankLinkBanks, banksListCurrentPage, banksListFilter, banksListPageSize]);

    const banksListHandlePageSizeChange = pageSize => {
        if (pageSize) {
            setBanksListCurrentPage(1);
            setBanksListPageSize(parseInt(pageSize));
            setBanksListContinuationToken([null]);
        }
    };

    const banksListHandleFilterChange = filters => {
        let filter = {};
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "name") {
                        filter.name = filters[key][0];
                    } else if (key === "abi") {
                        filter.abi = filters[key][0];
                    } else if (key === "associationId") {
                        filter.associationId = filters[key][0];
                    }
                }
            }
        }
        setBanksListCurrentPage(1);
        setBanksListContinuationToken([null]);
        setBanksListFilter(filter);
    };

    const banksListHandleNextPage = () => {
        if (banksListHasNextPage) {
            setBanksListCurrentPage(banksListCurrentPage + 1);
        }
    };

    const banksListHandlePreviousPage = () => {
        if (banksListCurrentPage !== 1) {
            setBanksListCurrentPage(banksListCurrentPage - 1);
        }
    };

    const banksListHandleEditBank = item => {
        history.push(`bank-link/bank-link-banks/${item.id}`);
    };

    const banksListHandleNewBank = () => {
        history.push(`bank-link/bank-link-banks/new`);
    };

    const banksListHandleDeleteRule = item => {};

    //  <----- Files ----->
    const [filesListLoading, setFilesListLoading] = useState(true);
    const [filesList, setFilesList] = useState([]);
    const [filesListPageSize, setFilesListPageSize] = useState(defaultPageSize);
    const [filesListCurrentPage, setFilesListCurrentPage] = useState(1);
    const [filesListHasNextPage, setFilesListHasNextPage] = useState(false);
    const [filesListFilter, setFilesListFilter] = useState({ withDefaultCbiReasons: true });
    const [filesListContinuationToken, setFilesListContinuationToken] = useState([null]);
    const [filesListQuery, setFilesListQuery] = useState(
        <Typography.Paragraph code={true} className="c-footer">
            <b>Current Query:</b>
        </Typography.Paragraph>
    );

    useEffect(() => {
        let listFilter = [];
        if (filesListFilter && filesListFilter.withDefaultCbiReasons) {
            listFilter.push("withDefaultCbiReasons=" + filesListFilter.withDefaultCbiReasons);
        }
        if (filesListFilter && filesListFilter.ownerId) {
            listFilter.push("ownerId=" + filesListFilter.ownerId);
        }
        listFilter.push("size=" + filesListPageSize);
        if (filesListContinuationToken && filesListContinuationToken[filesListCurrentPage - 1]) {
            listFilter.push("continuationToken=" + filesListContinuationToken[filesListCurrentPage - 1]);
        }
        let query = (
            <Typography.Paragraph code={true} className="c-footer">
                <b>Current Query:</b> {listFilter.join(" AND ")}
            </Typography.Paragraph>
        );
        setFilesListQuery(query);
    }, [filesListCurrentPage, filesListPageSize, filesListFilter, filesListContinuationToken]);

    useEffect(() => {
        const getFiles = async () => {
            let filter = {
                ...filesListFilter,
                continuationToken: filesListContinuationToken[filesListCurrentPage - 1]
            };
            let resultFiles = await getBankLinkFiles(auth, filter, filesListPageSize);
            if (
                resultFiles &&
                resultFiles._embedded &&
                resultFiles._embedded.fileUploadEntityList &&
                resultFiles._embedded.fileUploadEntityList.length
            ) {
                setFilesList(resultFiles._embedded.fileUploadEntityList);
                setFilesListHasNextPage(resultFiles.page.hasNext);
                if (filesListContinuationToken.length <= filesListCurrentPage) {
                    filesListContinuationToken.push(resultFiles.page.continuationToken);
                }
            }
            setFilesListContinuationToken(filesListContinuationToken);
            setFilesListLoading(false);
        };

        setFilesListLoading(true);
        setFilesList([]);
        getFiles();
    }, [auth, filesListContinuationToken, getBankLinkFiles, filesListCurrentPage, filesListFilter, filesListPageSize]);

    const filesListHandlePageSizeChange = pageSize => {
        if (pageSize) {
            setFilesListCurrentPage(1);
            setFilesListPageSize(parseInt(pageSize));
            setFilesListContinuationToken([null]);
        }
    };

    const filesListHandleFilterChange = filters => {
        let filter = { withDefaultCbiReasons: true };
        if (!isNil(filters)) {
            for (let key in filters) {
                if (filters[key]) {
                    if (key === "ownerId") {
                        filter.ownerId = filters[key][0];
                    }
                }
            }
        }
        setFilesListCurrentPage(1);
        setFilesListContinuationToken([null]);
        setFilesListFilter(filter);
    };

    const filesListHandleNextPage = () => {
        if (filesListHasNextPage) {
            setFilesListCurrentPage(filesListCurrentPage + 1);
        }
    };

    const filesListHandlePreviousPage = () => {
        if (filesListCurrentPage !== 1) {
            setFilesListCurrentPage(filesListCurrentPage - 1);
        }
    };

    const handleViewFileTransaction = item => {
        history.push(`bank-link/bank-link-files/${item.hubId}`);
    };

    const handleDownloadFile = async item => {
        if (item && item.hubId) {
            let resultFile = await getBankLinkDownloadFile(auth, item.hubId, item.fileType === "XLS");
            if (resultFile) {
                try {
                    if (item.fileType === "CBI") {
                        var download = document.createElement("a");
                        download.setAttribute(
                            "href",
                            "data:application/octet-stream;charset=utf-16le;base64," + window.btoa(resultFile)
                        );
                        download.setAttribute("download", `BankLinK_File_${item.hubId}.txt`);
                        download.click();
                    } else {
                        let blob = new Blob([resultFile], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        saveAs(blob, `BankLinK_File_${item.hubId}.xls`);
                    }
                    message.success(
                        intl.formatMessage({
                            id: "the_file_downloaded_successfully"
                        })
                    );
                } catch (e) {
                    message.error(
                        intl.formatMessage({
                            id: "can_not_download_file"
                        })
                    );
                }
            } else {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        }
    };

    const filesListHandleDelete = item => {};

    const handleUploadTxtFile = item => {};

    const handleUploadXlsFile = item => {};

    return {
        defaultPageSize,
        pageSizeOptions,
        // Rules
        rulesListLoading,
        rulesList,
        rulesListCurrentPage,
        rulesListPageSize,
        rulesListFilter,
        rulesListExpanded,
        rulesListHasNextPage,
        rulesListQuery,
        rulesListHandlePageSizeChange,
        rulesListHandleFilterChange,
        rulesListHandleExpandedChange,
        rulesListHandleNextPage,
        rulesListHandlePreviousPage,
        rulesListHandleEditRule,
        rulesListHandleNewRule,
        rulesListHandleDeleteRule,
        // Associations
        associationsListLoading,
        associationsList,
        associationsListCurrentPage,
        associationsListPageSize,
        associationsListFilter,
        associationsListHasNextPage,
        associationsListQuery,
        associationsListHandlePageSizeChange,
        associationsListHandleFilterChange,
        associationsListHandleNextPage,
        associationsListHandlePreviousPage,
        associationsListHandleEditAssociation,
        associationsListHandleNewAssociation,
        associationsListHandleDeleteRule,
        // Banks
        banksListLoading,
        banksList,
        banksListCurrentPage,
        banksListPageSize,
        banksListFilter,
        banksListHasNextPage,
        banksListQuery,
        banksListHandlePageSizeChange,
        banksListHandleFilterChange,
        banksListHandleNextPage,
        banksListHandlePreviousPage,
        banksListHandleEditBank,
        banksListHandleNewBank,
        banksListHandleDeleteRule,
        // Files
        filesListLoading,
        filesList,
        filesListCurrentPage,
        filesListPageSize,
        filesListFilter,
        filesListHasNextPage,
        filesListQuery,
        filesListHandlePageSizeChange,
        filesListHandleFilterChange,
        filesListHandleNextPage,
        filesListHandlePreviousPage,
        filesListHandleDelete,
        handleDownloadFile,
        handleUploadTxtFile,
        handleUploadXlsFile,
        handleViewFileTransaction
    };
};
