import React from "react";
import PropTypes from "prop-types";
import { isTablet } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { Card, Icon, Badge, Avatar } from "antd";

import { Link } from "react-router-dom";

export const MeteringLynfaCard = ({
    id,
    icon,
    color,
    background,
    title,
    description,
    status,
    onSettings,
    onChangeStatus
}) => {
    return (
        <div
            style={
                isTablet
                    ? { minWidth: "47%", maxWidth: "47%", margin: "10px 10px" }
                    : { minWidth: "31%", maxWidth: "31%", margin: "10px 10px" }
            }
        >
            <Card
                actions={[
                    <span>
                        {status ? (
                            <Badge status="success" text={<FormattedMessage id="c-meteringlynfacard.active" />} />
                        ) : (
                            <Badge status="error" text={<FormattedMessage id="c-meteringlynfacard.disabled" />} />
                        )}
                    </span>,
                    status ? (
                        <Link to={id}>
                            <Icon type="setting" key="setting" onClick={onSettings} />
                        </Link>
                    ) : (
                        <Icon type="setting" key="setting" style={{ cursor: "not-allowed", color: "#89898973" }} />
                    )
                ]}
            >
                <Card.Meta
                    avatar={
                        <Avatar
                            style={{
                                background: background,
                                color: color,
                                border: "1px solid" + color
                            }}
                        >
                            <i className={icon} />
                        </Avatar>
                    }
                    description={<div>{description}</div>}
                    title={title}
                />
            </Card>
        </div>
    );
};

MeteringLynfaCard.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    status: PropTypes.bool.isRequired,
    onSettings: PropTypes.func,
    onChangeStatus: PropTypes.func
};
