import { WAITING_LIST_READ_URL } from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_WAITING_LIST_START = "GET_WAITING_LIST_START";
export const GET_WAITING_LIST_SUCCESS = "GET_WAITING_LIST_SUCCESS";
export const GET_WAITING_LIST_ERROR = "GET_WAITING_LIST_ERROR";

export const getWaitingList = auth => async dispatch => {
    dispatch({
        type: GET_ITEM_WAITING_LIST_START
    });

    try {
        const result = await getRestCall(`${WAITING_LIST_READ_URL}/items`, auth, null, dispatch, auth.refreshToken);
        dispatch({
            type: GET_ITEM_WAITING_LIST_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_ITEM_WAITING_LIST_ERROR,
            error: e
        });
    }
};

export const GET_ITEM_WAITING_LIST_START = "GET_ITEM_WAITING_LIST_START";
export const GET_ITEM_WAITING_LIST_SUCCESS = "GET_ITEM_WAITING_LIST_SUCCESS";
export const GET_ITEM_WAITING_LIST_ERROR = "GET_ITEM_WAITING_LIST_ERROR";

export const getItemWaitingList = (auth, itemId) => async dispatch => {
    dispatch({
        type: GET_ITEM_WAITING_LIST_START
    });

    try {
        const result = await getRestCall(
            `${WAITING_LIST_READ_URL}/items/${itemId}`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );
        dispatch({
            type: GET_ITEM_WAITING_LIST_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: GET_ITEM_WAITING_LIST_ERROR,
            error: e
        });
    }
};
