import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import ServiceCard from "../../../components/general/ServiceCard/info";
import { errorBoundary } from "../../../components/general/error-boundary";
import { getB2bPassiveConfig } from "../../../actions/services/b2b-passive";
import { searchItems, searchCompany } from "../../../actions/search/company";
import { searchB2bPassiveConfig, getServicesForCompanySeached } from "../../../actions/search/services";
import { getInvoicingUsage } from "../../../actions/usage/invoices";
import { activateService, disableService } from "../../../actions/company/services";
import { addExemptionFlow } from "../../../actions/exemptionFlow/write";
import { getExemptionFlow } from "../../../actions/exemptionFlow/read";
import { postReassignments } from "../../../actions/usage/realtime";
import B2bPassiveService from "../../../components/services/b2b-passive";
import { userIsAuthorized } from "../../../utils/get-roles";
import { useFeature } from "../../../utils/hook/use-feature";

const B2bPassiveServiceView = ({
    auth,
    admin,
    company,
    activateService,
    addExemptionFlow,
    postReassignments,
    disableService,
    getB2bPassiveConfig,
    getExemptionFlow,
    history,
    searchB2bPassiveConfig,
    searchCompany,
    searchItems,
    services,
    usage,
    getServicesForCompanySeached
}) => {
    const viewModel = useB2bPassiveServiceViewModel(
        auth,
        admin,
        company,
        getB2bPassiveConfig,
        getExemptionFlow,
        searchItems,
        searchCompany,
        getInvoicingUsage
    );
    const meteringExtension = useFeature("Metering_Extension");
    return (
        <ServiceCard
            history={history}
            content={
                <B2bPassiveService
                    admin={admin}
                    activateService={activateService}
                    addExemptionFlow={addExemptionFlow}
                    auth={auth}
                    active={company.active}
                    company={company}
                    disableService={disableService}
                    postReassignments={postReassignments}
                    getB2bPassiveConfig={getB2bPassiveConfig}
                    getExemptionFlow={getExemptionFlow}
                    searchB2bPassiveConfig={searchB2bPassiveConfig}
                    searchCompany={searchCompany}
                    searchItems={searchItems}
                    services={services}
                    usage={usage}
                    getServicesForCompanySeached={getServicesForCompanySeached}
                    b2bConfig={viewModel.b2bConfig}
                    b2bConfigLoaded={viewModel.b2bConfigLoaded}
                    b2bShared={viewModel.b2bShared}
                    b2bSharedLoaded={viewModel.b2bSharedLoaded}
                    handleChangeB2bSharedPage={viewModel.handleChangeB2bSharedPage}
                    b2bSharedCurrentPage={viewModel.b2bSharedCurrentPage}
                    b2bSharedTotalItems={viewModel.b2bSharedTotalItems}
                    b2bSharedItemForPage={viewModel.b2bSharedItemForPage}
                    isHavePermission={viewModel.isHavePermission}
                    isExemptionFlowPermission={viewModel.isExemptionFlowPermission}
                    meteringExtension={meteringExtension}
                />
            }
            serviceInfo={{ name: "eRicevi", category: "EIP" }}
            statisticsEnable={false}
        />
    );
};

B2bPassiveServiceView.propTypes = {
    activateService: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    addExemptionFlow: PropTypes.func.isRequired,
    postReassignments: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object,
    disableService: PropTypes.func.isRequired,
    getB2bPassiveConfig: PropTypes.func.isRequired,
    getExemptionFlow: PropTypes.func.isRequired,
    getInvoicingUsage: PropTypes.func.isRequired,
    searchB2bPassiveConfig: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    services: PropTypes.object,
    usage: PropTypes.object,
    getServicesForCompanySeached: PropTypes.func.isRequired,
    meteringExtension: PropTypes.bool
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    services: state.services,
    usage: state.usage
});

const actions = {
    activateService,
    addExemptionFlow,
    disableService,
    postReassignments,
    getB2bPassiveConfig,
    getExemptionFlow,
    getInvoicingUsage,
    searchB2bPassiveConfig,
    searchCompany,
    searchItems,
    getServicesForCompanySeached
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(B2bPassiveServiceView);

const useB2bPassiveServiceViewModel = (
    auth,
    admin,
    company,
    getB2bPassiveConfig,
    getExemptionFlow,
    searchItems,
    searchCompany,
    getInvoicingUsage
) => {
    const b2bSharedItemForPage = 10;

    const [b2bConfig, setB2bConfig] = useState([]);
    const [b2bShared, setB2bShared] = useState([]);
    const [b2bConfigLoaded, setB2bConfigLoaded] = useState(true);
    const [b2bSharedLoaded, setB2bSharedLoaded] = useState(true);
    const [b2bSharedCurrentPage, setB2bSharedCurrentPage] = useState(0);
    const [b2bSharedTotalItems, setB2bSharedTotalItems] = useState(0);
    const [containB2bService, setContainB2bService] = useState(false);
    const [isHavePermission, setIsHavePermission] = useState(false);
    const [isExemptionFlowPermission, setIsExemptionFlowPermission] = useState(false);

    useEffect(() => {
        let permission = userIsAuthorized("enable-disable-service", admin.adminInfo.permits);
        setIsHavePermission(permission);
    }, [admin.adminInfo.permits]);

    useEffect(() => {
        let permission = userIsAuthorized("add-exemption-flow", admin.adminInfo.permits);
        setIsExemptionFlowPermission(permission);
    }, [admin.adminInfo.permits]);

    useEffect(() => {
        let containB2bService = false;
        if (company.info.base && company.active.services) {
            let configServices = company.active.services.filter(
                service => service.agyoService.id === "B2B-PASSIVE-FLOW"
            );
            if (configServices) {
                containB2bService = true;
            }
        }
        setContainB2bService(containB2bService);
    }, [company.active.services, company.info.base]);

    useEffect(() => {
        const startInvoicingUsage = () => {
            getInvoicingUsage(auth.loginAuth, company.info.base.id);
        };

        if (containB2bService) {
            startInvoicingUsage();
        }
    }, [auth.loginAuth, company.info.base.id, containB2bService, getInvoicingUsage]);

    useEffect(() => {
        const startB2bShared = async () => {
            setB2bShared([]);
            setB2bSharedLoaded(true);
            let b2bConfig = await getB2bPassiveConfig(
                auth.loginAuth,
                company.info.base.id,
                b2bSharedCurrentPage,
                b2bSharedItemForPage
            );
            setB2bSharedTotalItems(b2bConfig.totalElements ? b2bConfig.totalElements : 0);
            if (b2bConfig && b2bConfig.configs && b2bConfig.configs.length > 0) {
                let configForItemShared = b2bConfig.configs.filter(
                    config => config.ownerId === company.info.base.id && config.ownerId !== config.itemId
                );
                for (var i = 0; i < configForItemShared.length; i++) {
                    let exemption = await getExemptionFlow(
                        auth.loginAuth,
                        configForItemShared[i].ownerId,
                        configForItemShared[i].itemId
                    );
                    if (exemption.length > 0) configForItemShared[i].exemption = exemption[0].from;
                    else configForItemShared[i].exemption = null;
                }

                let idsToGetInfoShared = configForItemShared && configForItemShared.map(config => config.itemId);
                if (idsToGetInfoShared && idsToGetInfoShared !== []) {
                    searchItems(auth.loginAuth, idsToGetInfoShared, false, null, true).then(async idsInfo => {
                        configForItemShared = configForItemShared.map(config =>
                            idsInfo.items
                                ? {
                                      ...config,
                                      itemDesc: idsInfo.items.find(item => item.item.base.id === config.itemId).item
                                          .base.details.description,
                                      itemTaxId: idsInfo.items.find(item => item.item.base.id === config.itemId).item
                                          .base.identifier.taxId,
                                      itemVatNumber: idsInfo.items.find(item => item.item.base.id === config.itemId)
                                          .item.base.identifier.vatNumber
                                  }
                                : false
                        );

                        setB2bShared(configForItemShared);
                        setB2bSharedLoaded(false);
                    });
                } else {
                    setB2bSharedLoaded(false);
                }
            } else {
                setB2bSharedLoaded(false);
            }
        };

        if (containB2bService) {
            startB2bShared();
        }
    }, [
        auth.loginAuth,
        b2bSharedCurrentPage,
        company.info.base.id,
        containB2bService,
        getB2bPassiveConfig,
        getExemptionFlow,
        searchItems
    ]);

    useEffect(() => {
        const startB2bConfig = async () => {
            setB2bConfig([]);
            setB2bConfigLoaded(true);

            let b2bConfig = await getB2bPassiveConfig(auth.loginAuth, company.info.base.id);

            if (b2bConfig && b2bConfig.configs && b2bConfig.configs.length > 0) {
                let configForItem = b2bConfig.configs.filter(config => config.itemId === company.info.base.id);
                for (var i = 0; i < configForItem.length; i++) {
                    let exemption = await getExemptionFlow(
                        auth.loginAuth,
                        configForItem[i].ownerId,
                        configForItem[i].itemId
                    );
                    if (exemption.length > 0) configForItem[i].exemption = exemption[0].from;
                    else configForItem[i].exemption = null;
                    let companyData = await searchCompany(auth.loginAuth, configForItem[i].ownerId, "BASE", true);
                    let uuidOwner = companyData.item.base.uuid;
                    configForItem[i].ownerUuid = uuidOwner;
                }

                let idsToGetInfo = configForItem && configForItem.map(config => config.ownerId);
                if (idsToGetInfo && idsToGetInfo !== []) {
                    searchItems(auth.loginAuth, idsToGetInfo).then(async idsInfo => {
                        configForItem = configForItem.map(config =>
                            idsInfo.items
                                ? {
                                      ...config,

                                      ownerDesc: idsInfo.items.find(item => item.item.base.id === config.ownerId).item
                                          .base.details.description,
                                      ownerTaxId: idsInfo.items.find(item => item.item.base.id === config.ownerId).item
                                          .base.identifier.taxId,
                                      ownerVatNumber: idsInfo.items.find(item => item.item.base.id === config.ownerId)
                                          .item.base.identifier.vatNumber
                                  }
                                : false
                        );

                        setB2bConfig(configForItem);
                        setB2bConfigLoaded(false);
                    });
                } else {
                    setB2bConfigLoaded(false);
                }
            } else {
                setB2bConfigLoaded(false);
            }
        };

        if (containB2bService) {
            startB2bConfig();
        }
    }, [
        auth.loginAuth,
        company.info.base.id,
        containB2bService,
        getB2bPassiveConfig,
        searchCompany,
        searchItems,
        getExemptionFlow
    ]);

    const handleChangeB2bSharedPage = async page => {
        setB2bSharedCurrentPage(page);
    };

    return {
        b2bConfig,
        b2bShared,
        b2bConfigLoaded,
        b2bSharedLoaded,
        b2bSharedCurrentPage,
        b2bSharedTotalItems,
        handleChangeB2bSharedPage,
        b2bSharedItemForPage,
        isHavePermission,
        isExemptionFlowPermission
    };
};
