import { API_URL } from "../../../config";
import { postRestCall } from "../../../utils/rest-api-call";

export const ADD_EXEMPTION_FLOW_START = "ADD_EXEMPTION_FLOW_START";
export const ADD_EXEMPTION_FLOW_SUCCESS = "ADD_EXEMPTION_FLOW_SUCCESS";
export const ADD_EXEMPTION_FLOW_ERROR = "ADD_EXEMPTION_FLOW_ERROR";

export const addExemptionFlow = (auth, itemId, ownerId, startDate) => {
    return async dispatch => {
        const request = {
            item: itemId,
            owner: ownerId,
            startDate: startDate
        };
        const toast = {
            onOk: true,
            onOkText:
                "La coppia azienda: " + itemId + " con owner: " + ownerId + " è stata aggiunta al flusso di esenzione",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: ADD_EXEMPTION_FLOW_START,
            request: request
        });

        try {
            const result = await postRestCall(
                `${API_URL}/service-flow-exemptions/${itemId}/${ownerId}/${startDate}`,
                auth,
                null,
                {},
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: ADD_EXEMPTION_FLOW_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: ADD_EXEMPTION_FLOW_ERROR,
                error: e
            });
        }
    };
};
