import invoice from "../assets/xsl/fatturapa_pdf_v1.2.xsl";
import easy_invoice from "../assets/xsl/easy_fatturapa_pdf_v1.2.xsl";
import e_receipt from "../assets/xsl/e_receipt_pdf_v1.0.xsl";

import at from "../assets/xsl/AT_v1.1.xsl";
import dt from "../assets/xsl/DT_v1.0.xsl";
import ec from "../assets/xsl/EC_v1.0.xsl";
import mc_pa from "../assets/xsl/MC_PA_v1.0.xsl";
import mc_pr from "../assets/xsl/MC_PR_v1.0.xsl";
import mt from "../assets/xsl/MT_v1.0.xsl";
import ne from "../assets/xsl/NE_v1.0.xsl";
import ns from "../assets/xsl/NS_v1.0.xsl";
import rc_pr from "../assets/xsl/RC_PR_v1.0.xsl";
import rc_pa from "../assets/xsl/RC_PA_v1.0.xsl";
import se from "../assets/xsl/SE_v1.0.xsl";

export function getXsl(type) {
    switch (type) {
        case "INVOICE":
            return invoice;
        case "EASY_INVOICE":
            return easy_invoice;
        case "MCSDIPA":
            return mc_pa;
        case "MCSDI":
            return mc_pa;
        case "MCSDIPR":
            return mc_pr;
        case "MT":
            return mt;
        case "NS":
            return ns;
        case "RCSDIPA":
            return rc_pa;
        case "RCSDI":
            return rc_pa;
        case "RCSDIPR":
            return rc_pr;
        case "NE":
            return ne;
        case "SE":
            return se;
        case "AT":
            return at;
        case "DT":
            return dt;
        case "EC":
            return ec;
        case "ERECEIPTS":
            return e_receipt;
        default:
            return null;
    }
}
