import styled from "styled-components";

export const Container = styled.div`
    // display: flex;
    // flex-wrap: wrap;
`;

export const ContainerAction = styled.div`
    text-align: right;
    margin-top: 15px;
`;

export const ContainerTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
`;

export const ContainerField = styled.span`
    font-weight: bold;
`;

export const ContainerCopyIcon = styled.span`
    cursor: pointer;
    margin: 0px 5px;
`;

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SpinContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
`;
