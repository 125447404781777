import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { getXsl } from "../../../utils/xsl-finder";
import { Tabs, Spin } from "antd";
import { ContainerCenter, ContainerPDFViewer } from "./styled";

export const InvoicePreview = ({ base64, documentInfo, displayErrorToast, isInvoice, messageSelected }) => {
    const [activeTab, setActiveTab] = useState("pdf");
    const [pdfTemplate, setPdfTemplate] = useState(null);
    let loading = false;

    useEffect(() => {
        try {
            let documentType = isInvoice
                ? documentInfo.category === "FATTURA_SEMPLIFICATA"
                    ? getXsl("EASY_INVOICE")
                    : getXsl("INVOICE")
                : getXsl(
                      messageSelected.sdi.type === "RC" || messageSelected.sdi.type === "MC"
                          ? messageSelected.sdi.type + documentInfo.flowType
                          : messageSelected.sdi.type
                  );
            let pdfTemplate = base64 && (
                <div id="fattura_preview">
                    {new global.Transformation()
                        .setXml(base64)
                        .setXslt(documentType)
                        .transform("fattura_preview")}
                </div>
            );
            setPdfTemplate(pdfTemplate);
        } catch (error) {
            setPdfTemplate(null);
        }
    }, [base64, documentInfo, isInvoice, messageSelected]);

    const download = () => {
        try {
            let name = documentInfo.fileName;
            var download = document.createElement("a");
            download.setAttribute(
                "href",
                "data:application/octet-stream;charset=utf-16le;base64," + window.btoa(base64)
            );
            download.setAttribute("download", name);
            download.click();
        } catch (e) {
            displayErrorToast("Impossibile scaricare il file xml", true);
        }
    };

    return (
        <div>
            <div>
                <Tabs
                    onChange={value => setActiveTab(value)}
                    defaultActiveKey={activeTab}
                    tabBarExtraContent={
                        activeTab === "xml" && (
                            <i
                                className="fas fa-download"
                                onClick={() => download()}
                                style={{
                                    marginRight: "30px",
                                    cursor: "pointer"
                                }}
                            />
                        )
                    }
                >
                    <Tabs.TabPane tab={<FormattedMessage id={"revenue_agency"} />} key="pdf">
                        {loading ? (
                            <ContainerCenter>
                                <Spin size="large" />
                            </ContainerCenter>
                        ) : pdfTemplate ? (
                            <div>{pdfTemplate}</div>
                        ) : (
                            <ContainerCenter>
                                <FormattedMessage id={"not_available"} />
                            </ContainerCenter>
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<FormattedMessage id={"xml"} />} key="xml">
                        {loading ? (
                            <ContainerCenter>
                                <Spin size="large" />
                            </ContainerCenter>
                        ) : base64 !== "" ? (
                            <ContainerPDFViewer>
                                <div
                                    style={{
                                        whiteSpace: isInvoice ? "pre" : "unset",
                                        padding: "15px"
                                    }}
                                >
                                    {base64}
                                </div>
                            </ContainerPDFViewer>
                        ) : (
                            <ContainerCenter>
                                <FormattedMessage id={"not_available"} />
                            </ContainerCenter>
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};
InvoicePreview.propTypes = {
    base64: PropTypes.string,
    documentInfo: PropTypes.object,
    displayErrorToast: PropTypes.func.isRequired,
    isInvoice: PropTypes.bool.isRequired,
    messageSelected: PropTypes.object
};
