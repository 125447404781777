import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Badge, Dropdown, Button } from "antd";
// import { ItemContainer } from "../styled";

export const CCTConfigPersonal = ({ dataSource, loading }) => {
    const columns = [
        {
            title: <FormattedMessage id="c-cctconfigservice.cctuser" />,
            dataIndex: "cctUser",
            key: "cctUser"
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.created_at" />,
            dataIndex: "passwordCreatedAt",
            key: "passwordCreatedAt",
            render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.expiring_at" />,
            dataIndex: "passwordExpDate",
            key: "passwordExpDate",
            render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.updated_at" />,
            dataIndex: "passwordUpdatedAt",
            key: "passwordUpdatedAt",
            render: text => <span>{text !== null ? new Date(Number(text)).toLocaleString() : ""}</span>
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.state" />,
            dataIndex: "active",
            key: "active",
            render: text => (
                <span>
                    <Badge status={text ? "success" : "error"} />
                    {text ? (
                        <FormattedMessage id="c-cctconfigservice.active" />
                    ) : (
                        <FormattedMessage id="c-cctconfigservice.disabled" />
                    )}
                </span>
            )
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.actions" />,
            key: "actions",
            render: (text, record) => (
                <Dropdown placement="bottomRight" disabled={true}>
                    <Button shape="circle" size="small">
                        <i className="fas fa-ellipsis-v" />
                    </Button>
                </Dropdown>
            )
        }
    ];

    const columns2 = [
        {
            title: <FormattedMessage id="c-cctconfigservice.servicename" />,
            dataIndex: "name",
            key: "name"
        },
        {
            title: <FormattedMessage id="c-cctconfigservice.state" />,
            dataIndex: "active",
            key: "active_spec",
            render: text => (
                <span>
                    <Badge status={text ? "success" : "error"} />
                    {text ? (
                        <FormattedMessage id="c-cctconfigservice.active" />
                    ) : (
                        <FormattedMessage id="c-cctconfigservice.disabled" />
                    )}
                </span>
            )
        }
    ];

    return (
        <div>
            <Table
                rowKey="id"
                locale={{
                    emptyText: <FormattedMessage id="c-cctconfigservice.nullconfiguration" />
                }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={loading}
                expandedRowRender={record => (
                    <Table
                        size="small"
                        rowKey="name"
                        columns={columns2}
                        dataSource={Object.values(record.specs)}
                        align="left"
                        pagination={false}
                        style={{ marginBottom: "2vh" }}
                    />
                )}
                rowExpandable={record => record.id !== "Not Expandable"}
            />
        </div>
    );
};

CCTConfigPersonal.propTypes = {
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};
