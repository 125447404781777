import React from "react";
import PropTypes from "prop-types";
import { Progress } from "antd";
import { FormattedMessage } from "react-intl";
import { SpidSlotsBlock } from "./styled.js";
const SpidComponentUsage = ({ spidUsage }) => {
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <td>
                            <SpidSlotsBlock>
                                <p>
                                    <b>
                                        <FormattedMessage id="c-spid.spid-list.tab-usage-spid" />
                                    </b>
                                </p>
                                <p>
                                    <FormattedMessage id="c-spid.spid-list.tab-usage-spid-total" />
                                    {spidUsage.SPID.totalSlots}
                                </p>
                                <p>
                                    <FormattedMessage id="c-spid.spid-list.tab-usage-spid-used" />
                                    {spidUsage.SPID.usedSlots}
                                </p>
                                <p>
                                    <FormattedMessage id="c-spid.spid-list.tab-usage-spid-available" />
                                    {spidUsage.SPID.totalSlots - spidUsage.SPID.usedSlots}
                                </p>
                                <br></br>
                                <Progress
                                    strokeColor={{
                                        "0%": "#108ee9",
                                        "100%": "#87d068"
                                    }}
                                    strokeWidth={10}
                                    width={200}
                                    type="circle"
                                    percent={Math.round(
                                        (spidUsage.SPID.usedSlots / spidUsage.SPID.totalSlots) *
                                            spidUsage.SPID.totalSlots
                                    )}
                                />
                            </SpidSlotsBlock>
                        </td>
                        <td>
                            <SpidSlotsBlock>
                                <p>
                                    <b>
                                        <FormattedMessage id="c-spid.spid-list.tab-usage-spid-video" />
                                    </b>
                                </p>
                                <p>
                                    <FormattedMessage id="c-spid.spid-list.tab-usage-spid-total" />
                                    {spidUsage.SPID_VIDEO.totalSlots}
                                </p>
                                <p>
                                    <FormattedMessage id="c-spid.spid-list.tab-usage-spid-used" />
                                    {spidUsage.SPID_VIDEO.usedSlots}
                                </p>
                                <p>
                                    <FormattedMessage id="c-spid.spid-list.tab-usage-spid-available" />{" "}
                                    {spidUsage.SPID_VIDEO.totalSlots - spidUsage.SPID_VIDEO.usedSlots}
                                </p>
                                <br></br>
                                <Progress
                                    strokeColor={{
                                        "0%": "#108ee9",
                                        "100%": "#87d068"
                                    }}
                                    strokeWidth={10}
                                    width={200}
                                    type="circle"
                                    percent={Math.round(
                                        (spidUsage.SPID_VIDEO.usedSlots / 100) * spidUsage.SPID_VIDEO.totalSlots
                                    )}
                                />
                            </SpidSlotsBlock>
                        </td>
                    </tr>
                </thead>
            </table>
        </div>
    );
};

SpidComponentUsage.propTypes = {
    spidUsage: PropTypes.object
};
export default SpidComponentUsage;
