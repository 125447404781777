import React, { Component } from "react";
import PropTypes from "prop-types";
import { isUUID } from "validator";
import { Input, Select, Button, Form, Switch, Tooltip } from "antd";

import {
    translateValiationError,
    translateServiceCategory,
    translateUserRoleInGroupId,
    translateRolesForPolicyType,
    translateFeatureCodeForAppId
} from "../../../utils/translate";
import { applicationDefaultOptions, rolesDefaultOptions } from "../../../utils/constant";
import { userIsAuthorized } from "../../../utils/get-roles";

import { isTaxId, isVatNumber } from "@mondora/agyo-validations";

const { Option } = Select;
const FormItem = Form.Item;

class AddRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validateStatus: "validating",
            help: "",
            appId: null,
            actionKey: null,
            featureCode: null,
            policyType: null,
            isPolicyTypeSet: false,
            resourceId: null,
            isResourceIdSet: false,
            connectionId: null,
            isConnectionIdSet: false,
            roles: [],
            isRolesSet: false,
            appSelectDisabled: false,
            service: [],
            isServiceSet: false,
            featSelectDisabled: false,
            features: [],
            isFeatureSet: false,
            itemType: "manager",
            itemId: null,
            managedId: [],
            isManagedIdSet: false,
            isManagerIdSet: false,
            itemService: [],
            managedOptions: [],
            rolesOptions: [],
            serviceOptions: [],
            featureOptions: [],
            manualInsert: false,
            formIsLocked: true
        };
    }
    static propTypes = {
        admin: PropTypes.object.isRequired,
        handleGetCompanyInfo: PropTypes.func,
        handleGetServices: PropTypes.func,
        handleGetConnectionInfo: PropTypes.func
    };

    async handleValidate(itemId) {
        const { handleGetCompanyInfo } = this.props;

        let vatNumberError = false;
        let taxIdError = false;
        let uuidError = false;
        if (!isUUID(itemId)) {
            vatNumberError = isVatNumber("taxRegion", error =>
                this.setState({
                    help: translateValiationError(error),
                    validateStatus: "error"
                })
            )(itemId, { taxRegion: "IT" });
            taxIdError = isTaxId("taxRegion", "classifier", error =>
                this.setState({
                    help: translateValiationError(error),
                    validateStatus: "error"
                })
            )(itemId, { taxRegion: "IT" });
        }

        if (taxIdError === false || vatNumberError === false || uuidError === false) {
            let res = await handleGetCompanyInfo(itemId);

            if (res) {
                let company = res.item;
                let resource = null;

                if (this.state.policyType === "CONNECTION") {
                    this.handleConnections(company.base.uuid);
                    resource = company.base.uuid;
                } else {
                    this.handleItemServices(itemId);
                    resource = company.base.uuid;
                }

                this.setState(
                    {
                        help: "",
                        validateStatus: "success",
                        resourceId: resource,
                        itemId: itemId,
                        isResourceIdSet: true,
                        managedId: [],
                        connectionId: null,
                        itemService: [],
                        managedOptions: [],
                        service: [],
                        serviceOptions: [],
                        features: [],
                        featureOptions: [],
                        isConnectionIdSet: false,
                        isManagedIdSet: false,
                        isManagerIdSet: false,
                        isServiceSet: false,
                        isFeatureSet: false,
                        formIsLocked: true
                    },
                    () => this.handleFormLock()
                );
            } else {
                this.setState({
                    help: "L'azienda non è presente in AGYO",
                    validateStatus: "error"
                });
            }
        }
    }

    async handleConnections(uuid) {
        let itemType = "managed";
        let msg;
        let filter;

        if (itemType === "manager") {
            msg = "L'azienda non ha gestite";
            filter = {
                active: true,
                deleted: false,
                managerIds: uuid,
                page: 0,
                size: 1
            };
        } else {
            msg = "L'azienda non è gestita";
            filter = {
                active: true,
                deleted: false,
                managedIds: uuid,
                page: 0,
                size: 1
            };
        }

        const { handleGetConnectionInfo } = this.props;
        const res = await handleGetConnectionInfo(itemType, filter);

        if (res) {
            if (res.totalElements > 0) {
                this.handleManagedOption(res);
            } else {
                this.setState({
                    help: msg,
                    validateStatus: "error"
                });
            }
        } else {
            this.setState({
                help: "Errore nel recupero delle connessioni",
                validateStatus: "error"
            });
        }
    }

    async handleItemServices(itemId) {
        const { handleGetServices } = this.props;

        const res = await handleGetServices(itemId);

        if (res) {
            if (res) {
                this.handleItemOption(res);
            } else {
                this.setState({
                    help: res.msg,
                    validateStatus: "error"
                });
            }
        } else {
            this.setState({
                help: "Errore nel recupero dei servizi",
                validateStatus: "error"
            });
        }
    }

    handleChangePermission() {
        const { handleAddRolesWithPolicy } = this.props;
        const { policyType, roles, connectionId, appId, featureCode, resourceId } = this.state;
        const groupId = roles.map(r => ({
            role: r,
            groupId: translateUserRoleInGroupId(r)
        }));
        let rolesToAdd = [];
        if (featureCode) {
            rolesToAdd = featureCode.map(feature =>
                groupId.map(role => ({
                    groupId: role.groupId,
                    policyType: policyType,
                    policyId: policyType === "CONNECTION" ? connectionId : resourceId,
                    role: {
                        actionKey: role.role,
                        appId: appId,
                        featureCode: feature,
                        resourceId: resourceId
                    }
                }))
            );
        } else {
            rolesToAdd = groupId.map(role => ({
                groupId: role.groupId,
                policyType: policyType,
                policyId: policyType === "CONNECTION" ? connectionId : resourceId,
                role: {
                    actionKey: role.role,
                    appId: appId,
                    featureCode: null,
                    resourceId: resourceId
                }
            }));
        }
        let rolesToAddClean = Array.prototype.concat.apply([], rolesToAdd);
        handleAddRolesWithPolicy(rolesToAddClean);
    }

    handleManagedOption(connections) {
        let managedOptions = [];

        if (connections) {
            connections = connections.content;

            managedOptions = connections.map(c => ({
                key: c.managedId,
                value: c.managedId,
                label: c.managedCf + " - " + c.managedDescription,
                managerkey: c.managerId,
                managerValue: c.managerId,
                managerLabel: c.managerCf + " - " + c.managerDescription,
                services: c.connections.map(s => ({
                    connectionId: s.id,
                    appId: s.appId,
                    featureCode: s.featureCode
                }))
            }));
        }
        this.setState({
            managedOptions: managedOptions
        });
    }

    handleItemOption(services) {
        let itemOptions = [];
        let serviceWithOwnership = [];
        let serviceActiveOptions = [];
        let serviceOptions = [];
        if (services) {
            //filtro per i soli servizi di cui è owner
            serviceWithOwnership = services.filter(service => service.owner === true);
            //filtro i soli servizi di cui è owner attivi (per connection il filtro non serve perché è filtrata la request)
            serviceActiveOptions = serviceWithOwnership.filter(service => service.agyoService.active === true);
            itemOptions = {
                key: this.state.itemId,
                value: this.state.itemId,
                label: this.state.itemId + " - " + this.state.itemId,
                services: serviceActiveOptions.map(s => ({
                    connectionId: null,
                    appId: s.agyoService.appId,
                    featureCode: s.agyoService.featureCode
                }))
            };
            serviceOptions = itemOptions.services;
        }

        this.setState({
            managedOptions: itemOptions
        });

        this.handleServices(serviceOptions);
    }

    handleServices(services) {
        let serviceOptions = [];
        let serviceUnique = [];

        if (!services || this.state.manualInsert === true) {
            services = applicationDefaultOptions;
            serviceOptions = services.map(c => ({
                key: c.service_id,
                value: c.service_id,
                label: translateServiceCategory(c.service_id)
            }));
        } else {
            serviceUnique = services
                .map(item => item.appId)
                .filter((value, index, self) => self.indexOf(value) === index);
            serviceOptions = serviceUnique.map(c => ({
                key: c,
                value: c,
                label: translateServiceCategory(c)
            }));
        }

        if (serviceOptions.length > 0) {
            this.setState({
                serviceOptions: serviceOptions,
                itemService: services
            });
        } else {
            this.setState({
                help: "Nessun servizio attivo",
                validateStatus: "warning"
            });
        }
    }

    handleChangeService(value) {
        let serviceInfo = this.state.itemService;

        let selectedFeatureOptions = [];

        selectedFeatureOptions = serviceInfo
            .filter(c => c.appId === value)
            .map(c => ({
                connectionId: c.connectionId,
                appId: c.appId,
                featureCode: c.featureCode
            }));

        this.setState(
            {
                service: value,
                isServiceSet: true,
                appId: value,
                connectionId: selectedFeatureOptions[0] ? selectedFeatureOptions[0].connectionId : null,
                isConnectionIdSet: true,
                features: [],
                featureCode: null,
                featureOptions: [],
                isFeatureSet: false,
                formIsLocked: true
            },
            () => {
                this.handleFeatures(selectedFeatureOptions);
                this.handleFormLock();
            }
        );
    }

    handleChangePolicyType(value) {
        let policyType = value;

        let subSelectDisabled = false;

        if (policyType === "OWNERSHIP") {
            subSelectDisabled = true;
        }

        this.setState(
            {
                policyType: policyType,
                isPolicyTypeSet: true,
                roles: [],
                rolesOptions: [],
                itemId: null,
                resourceId: null,
                managedId: [],
                connectionId: null,
                itemService: [],
                managedOptions: [],
                appSelectDisabled: subSelectDisabled,
                service: [],
                serviceOptions: [],
                featSelectDisabled: subSelectDisabled,
                features: [],
                featureOptions: [],
                isConnectionIdSet: false,
                isResourceIdSet: false,
                isManagedIdSet: false,
                isManagerIdSet: false,
                isServiceSet: false,
                isFeatureSet: false,
                isRolesSet: false,
                formIsLocked: true
            },
            () => {
                this.handleRoles(policyType);
                this.handleFormLock();
            }
        );
    }

    handleFeatures(features) {
        let featureOptions = [];

        if (this.state.manualInsert === true) {
            features = translateFeatureCodeForAppId(this.state.appId);
            featureOptions = features.map(c => ({
                key: c.feature_id,
                value: c.feature_id,
                label: c.feature_id
            }));
        } else {
            if (features) {
                if (features[0].featureCode !== null) {
                    featureOptions = features.map(c => ({
                        key: c.connectionId,
                        value: c.featureCode,
                        label: c.featureCode
                    }));
                }
            }
        }
        this.setState({ featureOptions: featureOptions }, () => {
            this.handleFormLock();
        });
    }

    handleChangeFeature(value) {
        if (value && value !== undefined && value.length > 0) {
            this.setState({ features: value, featureCode: value, isFeatureSet: true, formIsLocked: true }, () => {
                this.handleFormLock();
            });
        } else {
            this.setState({ features: value, featureCode: value, isFeatureSet: false, formIsLocked: true }, () => {
                this.handleFormLock();
            });
        }
    }

    handleRoles(policyType) {
        let roles = rolesDefaultOptions;
        if (policyType) {
            roles = translateRolesForPolicyType(policyType);
        }

        let rolesOptions = roles.map(r => (
            <Select.Option key={r.role} value={r.role} disabled={r.disabled} groupid={r.groupId}>
                {`${r.role}`}
            </Select.Option>
        ));

        this.setState({
            rolesOptions: rolesOptions
        });
    }

    handleChangeRoles(value) {
        this.setState(
            {
                roles: value,
                isRolesSet: true,
                formIsLocked: true
            },
            () => {
                this.handleFormLock();
                this.rolesSelect.blur();
            }
        );
    }

    handleChangeManager(value) {
        let managedInfo = this.state.managedOptions.find(o => o.managerValue === value);
        let managedId = managedInfo.key;
        let serviceOptions = [];
        serviceOptions = managedInfo.services.map(c => ({
            connectionId: c.connectionId,
            appId: c.appId,
            featureCode: c.featureCode
        }));

        this.setState(
            {
                resourceId: managedId,
                managedId: managedId,
                managerId: value,
                isManagerIdSet: true,
                itemService: serviceOptions,
                connectionId: null,
                service: [],
                appId: null,
                features: [],
                featureCode: null,
                isConnectionIdSet: false,
                isResourceIdSet: true,
                isServiceSet: false,
                isFeatureSet: false,
                formIsLocked: true
            },
            () => {
                this.handleServices(serviceOptions);
                this.handleFormLock();
            }
        );
    }

    handleChangeManagerManaged(value) {
        this.setState({
            itemType: value,
            itemId: null,
            resourceId: null,
            managedId: [],
            connectionId: null,
            itemService: [],
            service: [],
            appId: null,
            features: [],
            featureCode: null,
            isConnectionIdSet: false,
            isResourceIdSet: false,
            isManagedIdSet: false,
            isManagerIdSet: false,
            isServiceSet: false,
            isFeatureSet: false,
            isRolesSet: false,
            formIsLocked: true
        });
    }

    handleChangeManualSwitch(value) {
        this.setState({
            manualInsert: value,
            itemId: null,
            resourceId: null,
            managedId: [],
            connectionId: null,
            itemService: [],
            service: [],
            appId: null,
            features: [],
            featureCode: null,
            roles: [],
            rolesOptions: [],
            featureOptions: [],
            isConnectionIdSet: false,
            isResourceIdSet: false,
            isManagedIdSet: false,
            isManagerIdSet: false,
            isServiceSet: false,
            isFeatureSet: false,
            isRolesSet: false,
            formIsLocked: true
        });
    }

    handleFormLock() {
        const {
            isPolicyTypeSet,
            isResourceIdSet,
            isConnectionIdSet,
            isServiceSet,
            featureOptions,
            isFeatureSet,
            isRolesSet,
            policyType
        } = this.state;
        let formIsLocked = true;

        let featureCheck = true;

        if (featureOptions && featureOptions !== undefined && featureOptions.length > 0 && !isFeatureSet) {
            featureCheck = false;
        }

        if (isPolicyTypeSet) {
            if (policyType === "OWNERSHIP") {
                if (isResourceIdSet && isRolesSet) {
                    formIsLocked = false;
                }
            } else if (policyType === "CONNECTION") {
                if (isResourceIdSet && isRolesSet && isConnectionIdSet && isServiceSet && featureCheck) {
                    formIsLocked = false;
                }
            } else if (policyType === "ADMINISTRATION") {
                if (
                    (isResourceIdSet && isRolesSet && !isServiceSet) ||
                    (isResourceIdSet && isRolesSet && isServiceSet && featureCheck)
                ) {
                    formIsLocked = false;
                }
            }
        }

        this.setState({
            formIsLocked: formIsLocked
        });
    }

    render() {
        const {
            admin: {
                adminInfo: { permits }
            }
        } = this.props;
        const {
            help,
            validateStatus,
            policyType,
            itemId,
            managerId,
            managedOptions,
            roles,
            rolesOptions,
            service,
            serviceOptions,
            features,
            featureOptions,
            appSelectDisabled,
            featSelectDisabled,
            manualInsert,
            formIsLocked,
            isPolicyTypeSet,
            isRolesSet,
            isResourceIdSet,
            isManagerIdSet,
            isServiceSet
        } = this.state;

        const userNotAuthorized = !userIsAuthorized("c-add-roles-form-components", permits);
        const autorizedForFreeApp = userIsAuthorized("c-user-add-roles-free-app-selection", permits);
        return (
            <div className="c-user-roles">
                <span>
                    {
                        "Inserisci l'appid(non obbligatorio), la risorsa e la tipologia di permesso che vuoi assegnare all'utente."
                    }
                </span>
                <br /> <br />
                {autorizedForFreeApp ? (
                    <div>
                        <Tooltip title="vengono mostrate tutte le app, anche se non attive">
                            <span>{"Inserimento manuale"}</span>
                        </Tooltip>
                        <Switch
                            style={{ float: "right" }}
                            checked={manualInsert}
                            checkedChildren="abilitato"
                            unCheckedChildren="non abilitato"
                            onChange={value => this.handleChangeManualSwitch(value)}
                        />
                    </div>
                ) : (
                    <div />
                )}
                <br /> <br />
                <span>{"Seleziona il tipo di ruolo"}</span>
                <Select
                    allowClear={true}
                    className="search-select"
                    onChange={value => this.handleChangePolicyType(value)}
                    style={{ width: "100%" }}
                    placeholder="Tipo Ruolo"
                    disabled={userNotAuthorized}
                >
                    <Option value="OWNERSHIP">OWNERSHIP</Option>
                    <Option value="ADMINISTRATION">ADMINISTRATION</Option>
                    <Option value="CONNECTION">CONNECTION</Option>
                </Select>
                <br /> <br />
                {isPolicyTypeSet ? (
                    <div>
                        <span>{"Seleziona i ruoli tra quelli disponibili per il tipo selezionato"}</span>
                        <Select
                            allowClear={true}
                            className="search-select"
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder="Ruoli"
                            onChange={values => this.handleChangeRoles(values)}
                            disabled={userNotAuthorized}
                            value={roles}
                            ref={select => (this.rolesSelect = select)}
                        >
                            {rolesOptions}
                        </Select>
                    </div>
                ) : null}
                <br /> <br />
                {isRolesSet ? (
                    policyType === "CONNECTION" ? (
                        <div>
                            <span>
                                {
                                    "Vengono visualizzate solo le connessioni ed i servizi attivi. Inserisci l'identificativo dell'Azienda Gestita"
                                }
                            </span>
                            <FormItem validateStatus={validateStatus} help={help}>
                                <Select
                                    defaultValue="managed"
                                    className="search-select"
                                    disabled={true}
                                    style={{ width: "20%" }}
                                >
                                    <Option value="managed">Gestita</Option>
                                </Select>
                                <Input
                                    style={{ width: "80%" }}
                                    placeholder="Indentificativo(CF/PIVA/UUID) dell'azienda"
                                    onChange={e => this.handleValidate(e.target.value)}
                                    disabled={userNotAuthorized}
                                    value={itemId}
                                />
                            </FormItem>
                            {isResourceIdSet ? (
                                <div>
                                    <span>{"Seleziona l'identificativo dello Studio"}</span>
                                    <FormItem validateStatus={validateStatus} help={help}>
                                        <Select
                                            defaultValue="manager"
                                            className="search-select"
                                            disabled={true}
                                            style={{ width: "20%" }}
                                        >
                                            <Option value="manager">Gestore</Option>
                                        </Select>
                                        <Select
                                            allowClear={true}
                                            validateStatus={validateStatus}
                                            help={help}
                                            className="search-select"
                                            onChange={val => this.handleChangeManager(val)}
                                            style={{ width: "80%" }}
                                            placeholder="Seleziona azienda Studio"
                                            disabled={userNotAuthorized}
                                            value={managerId}
                                        >
                                            {managedOptions
                                                ? managedOptions.map(item => (
                                                      <Select.Option key={item.managerKey} value={item.managerValue}>
                                                          {item.managerLabel}
                                                      </Select.Option>
                                                  ))
                                                : null}
                                        </Select>
                                    </FormItem>
                                    <br /> <br />
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <span>{"Inserisci l'identificativo dell'Azienda"}</span>
                            <FormItem validateStatus={validateStatus} help={help}>
                                <Input
                                    placeholder="Indentificativo(CF/PIVA/UUID) dell'azienda"
                                    onChange={e => this.handleValidate(e.target.value)}
                                    disabled={userNotAuthorized}
                                    value={itemId}
                                />
                            </FormItem>
                        </div>
                    )
                ) : null}
                {((policyType === "ADMINISTRATION" && isResourceIdSet) ||
                    (policyType === "CONNECTION" && isManagerIdSet)) &&
                (serviceOptions && serviceOptions !== undefined && serviceOptions.length > 0) ? (
                    <div>
                        <span>
                            {"Seleziona il servizio e l'eventuale feature (non obbligati per ruolo Amministratore)"}
                        </span>
                        <Select
                            allowClear={true}
                            className="search-select"
                            onChange={val => this.handleChangeService(val)}
                            style={{ width: "100%" }}
                            placeholder="Applicazione"
                            value={service}
                            disabled={userNotAuthorized || appSelectDisabled}
                        >
                            {serviceOptions.map(item => (
                                <Select.Option key={item.key} value={item.value}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                ) : null}
                <br /> <br />
                {isServiceSet && isServiceSet !== undefined && featureOptions.length > 0 ? (
                    <Select
                        allowClear={true}
                        mode="tags"
                        className="search-select"
                        onChange={val => this.handleChangeFeature(val)}
                        style={{ width: "100%" }}
                        placeholder="FeatureCode"
                        value={features}
                        disabled={userNotAuthorized || featSelectDisabled}
                    >
                        {featureOptions.map(item => (
                            <Select.Option key={item.key} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                ) : null}
                <br />
                <br />
                <div style={{ margin: "10px 0px", textAlign: "right" }}>
                    <Button
                        onClick={() => this.handleChangePermission()}
                        style={{ width: "100px" }}
                        type="primary"
                        disabled={formIsLocked || userNotAuthorized}
                    >
                        {"Salva"}
                    </Button>
                </div>
            </div>
        );
    }
}

export default AddRoles;
