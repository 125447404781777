import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { saveAs } from "file-saver";
import { message } from "antd";
import { injectIntl } from "react-intl";
import { restrictToPermits } from "../../components/general/hoc";
import { errorBoundary } from "../../components/general/error-boundary";
import { getColdirettiReport } from "../../actions/report";
import { ReportIV } from "../../components/report";

const ReportView = ({ admin, auth, intl, getColdirettiReport }) => {
    const viewModel = useReportView(auth, intl, getColdirettiReport);

    return (
        <div>
            <ReportIV admin={admin} handleDownloadCSV={viewModel.handleDownloadCSV} />
        </div>
    );
};

ReportView.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getColdirettiReport: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth
});
const actions = { getColdirettiReport };

const composedHoc = compose(
    injectIntl,
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-sideMenu-drawer-report")
);
export default composedHoc(ReportView);
const useReportView = (auth, intl, getColdirettiReport) => {
    const handleDownloadCSV = async () => {
        let resultFile = await getColdirettiReport(auth.loginAuth);
        if (resultFile) {
            try {
                let blob = new Blob([resultFile], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, `coldiretti.csv`);

                message.success(
                    intl.formatMessage({
                        id: "the_file_downloaded_successfully"
                    })
                );
            } catch (e) {
                message.error(
                    intl.formatMessage({
                        id: "can_not_download_file"
                    })
                );
            }
        } else {
            message.error(
                intl.formatMessage({
                    id: "can_not_download_file"
                })
            );
        }
    };

    return {
        handleDownloadCSV
    };
};
