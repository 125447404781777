import {
    ADD_ROLES_START,
    ADD_ROLES_ERROR,
    ADD_ROLES_SUCCESS,
    REMOVE_ROLES_START,
    REMOVE_ROLES_ERROR,
    REMOVE_ROLES_SUCCESS,
    ENABLE_USER_START,
    ENABLE_USER_ERROR,
    ENABLE_USER_SUCCESS,
    DISABLE_USER_START,
    DISABLE_USER_ERROR,
    DISABLE_USER_SUCCESS,
    CREATE_USER_START,
    CREATE_USER_ERROR,
    CREATE_USER_SUCCESS
} from "../actions/user/write";

import { GET_USER_START, GET_USER_SUCCESS, GET_USER_ERROR } from "../actions/user/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function infoU(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case GET_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function addRoles(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ADD_ROLES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case ADD_ROLES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case ADD_ROLES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function removeRoles(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REMOVE_ROLES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case REMOVE_ROLES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REMOVE_ROLES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function enableUser(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ENABLE_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case ENABLE_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case ENABLE_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function disableUser(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DISABLE_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case DISABLE_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DISABLE_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
export function createUser(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CREATE_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case CREATE_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CREATE_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
