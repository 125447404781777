import { API_URL } from "../../config";
import { postRestCall } from "../../utils/rest-api-call";

export const RUN_ASSIGN_NSO_START = "RUN_ASSIGN_NSO_START";
export const RUN_ASSIGN_NSO_SUCCESS = "RUN_ASSIGN_NSO_SUCCESS";
export const RUN_ASSIGN_NSO_ERROR = "RUN_ASSIGN_NSO_ERROR";

export const runAssignNso = auth => {
    return async dispatch => {
        const toast = {
            onOk: true,
            onOkText: "Riassegnazione sottomessa con successo",
            onError: true,
            infoReq: { authId: auth.id }
        };
        dispatch({
            type: RUN_ASSIGN_NSO_START
            //request: request
        });

        try {
            const result = await postRestCall(`${API_URL}/nso`, auth, null, null, dispatch, auth.refreshToken, toast);

            dispatch({
                type: RUN_ASSIGN_NSO_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: RUN_ASSIGN_NSO_ERROR,
                error: e
            });
        }
    };
};
