import { SHOW_TOAST, HIDE_TOAST } from "../actions/toast";

const defaultState = {
    show: false
};

export default function toast(state = defaultState, { type, payload }) {
    switch (type) {
        case SHOW_TOAST:
            return {
                ...state,
                show: payload.displayError,
                ...payload
            };
        case HIDE_TOAST:
            return {
                show: false
            };
        default:
            return state;
    }
}
