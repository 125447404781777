import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, message } from "antd";

import { errorBoundary } from "../../components/general/error-boundary";
import {
    getBankLinkRule,
    getBankLinkAssociation,
    getBankLinkAssociations,
    getBankLinkBanks
} from "../../actions/bank-link/read";
import { createBankLinkRule, updateBankLinkRule } from "../../actions/bank-link/write";
import PageHeader from "../../components/general/PageHeader/index";
import { BankLinkRuleInfo } from "../../components/bank-link/rule-info";
import { ContractWrapper } from "./styled";
import base64 from "react-native-base64";

const BankLinkRuleView = ({
    auth,
    history,
    getBankLinkRule,
    getBankLinkAssociation,
    createBankLinkRule,
    updateBankLinkRule,
    getBankLinkAssociations,
    getBankLinkBanks,
    match: {
        params: { hubId, description, abi }
    }
}) => {
    const viewModel = useBankLinkRuleView(
        auth.loginAuth,
        hubId,
        abi,
        description,
        getBankLinkRule,
        getBankLinkAssociation,
        createBankLinkRule,
        updateBankLinkRule,
        getBankLinkAssociations,
        getBankLinkBanks
    );

    return (
        <div>
            <Card style={{ height: "90vh" }}>
                <ContractWrapper>
                    <PageHeader history={history} labelId={`rules`} />
                    <BankLinkRuleInfo
                        loading={viewModel.loading}
                        isNewRoule={viewModel.isNewRoule}
                        ruleInfo={viewModel.ruleInfo}
                        handleUpdateRuleInfo={viewModel.handleUpdateRule}
                        handleDeleteRuleInfo={viewModel.handleDeleteRule}
                        handleCreateRuleInfo={viewModel.handleCreateRule}
                        handleLoadAssociationLazy={viewModel.handleLoadAssociationLazy}
                    />
                </ContractWrapper>
            </Card>
        </div>
    );
};

BankLinkRuleView.propTypes = {
    auth: PropTypes.object.isRequired,
    getBankLinkRule: PropTypes.func.isRequired,
    getBankLinkAssociation: PropTypes.func.isRequired,
    createBankLinkRule: PropTypes.func.isRequired,
    updateBankLinkRule: PropTypes.func.isRequired,
    getBankLinkAssociations: PropTypes.func.isRequired,
    getBankLinkBanks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = {
    getBankLinkRule,
    getBankLinkAssociation,
    createBankLinkRule,
    updateBankLinkRule,
    getBankLinkAssociations,
    getBankLinkBanks
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(BankLinkRuleView);

const useBankLinkRuleView = (
    auth,
    hubId,
    abi,
    description,
    getBankLinkRule,
    getBankLinkAssociation,
    createBankLinkRule,
    updateBankLinkRule,
    getBankLinkAssociations,
    getBankLinkBanks
) => {
    const [loading, setLoading] = useState(true);
    const [ruleInfo, setRuleInfo] = useState(null);
    const [refresh, setRefresh] = useState(false);
    /// Private
    const [associationOptions, setAssociationOptions] = useState([]);
    const [associationSearch, setAssociationSearch] = useState(null);
    const [associationHasMore, setAssociationHasMore] = useState(true);
    const [associationsListContinuationToken, setAssociationsListContinuationToken] = useState([null]);

    useEffect(() => {
        const getRule = async () => {
            let resultRule = await getBankLinkRule(auth, hubId);
            if (resultRule) {
                if (resultRule.groupId) {
                    let result = await getBankLinkAssociation(auth, resultRule.groupId);
                    if (result) {
                        resultRule.name = result.name;
                    }
                }
                setRuleInfo(resultRule);
            }
            setLoading(false);
        };

        const getNewRule = async () => {
            let ruleInfo = {};
            if (description) {
                ruleInfo.expression = description;
            }
            if (abi && base64.decode(abi)) {
                let resultBanks = await getBankLinkBanks(auth, { abi: base64.decode(abi) }, 10);
                if (
                    resultBanks &&
                    resultBanks._embedded &&
                    resultBanks._embedded.bankEntityList &&
                    resultBanks._embedded.bankEntityList.length
                ) {
                    let bankInfo = resultBanks._embedded.bankEntityList[0];
                    ruleInfo.groupId = bankInfo.associationId;
                    let resultAssociation = await getBankLinkAssociation(auth, bankInfo.associationId);
                    if (resultAssociation) {
                        ruleInfo.name = resultAssociation.name;
                    }
                }
            }
            setRuleInfo(ruleInfo);
            setLoading(false);
        };

        setLoading(true);
        setRuleInfo(null);
        if (hubId !== "new") {
            getRule();
        } else {
            getNewRule();
        }
    }, [auth, getBankLinkRule, getBankLinkAssociation, hubId, abi, refresh, description, getBankLinkBanks]);

    const handleUpdateRule = async item => {
        setLoading(true);
        let data = {
            id: item.id,
            associationId: item.groupId,
            regex: item.expression,
            description: item.description,
            cbiReason: item.meaning
        };
        let result = await updateBankLinkRule(auth, data, item.id);
        if (result.code) {
            message.success("Documento è aggiornato correttamente");
        } else {
            message.error(`Documento non è aggiornato correttamente. ${result.message ? result.message : ""}`);
        }
        setRefresh(!refresh);
    };

    const handleCreateRule = async item => {
        setLoading(true);
        let data = {
            associationId: item.groupId,
            regex: item.expression,
            description: item.description,
            cbiReason: item.meaning
        };

        let result = await createBankLinkRule(auth, data);
        if (result.code) {
            message.success("Documento è creato correttamente");
        } else {
            message.error(`Documento non è creato correttamente. ${result.message ? result.message : ""}`);
        }
        setLoading(false);
    };

    const handleDeleteRule = item => {};

    const handleLoadAssociationLazy = async (search, prevOptions, { page }) => {
        let newAssociationOptions = [];
        let options = [];
        let hasMore = false;
        if (search && search === associationSearch) {
            if (associationHasMore) {
                let result = await getAssociations(search, page);
                options = result.options;
                hasMore = result.hasMore;
            }
        } else {
            let result = await getAssociations(search, page);
            options = result.options;
            hasMore = result.hasMore;
        }

        if (options && options.length) {
            options.forEach(item => {
                if (!associationOptions.includes(x => x.value === item.value)) {
                    newAssociationOptions.push(item);
                }
            });
        }
        let searchAssociationOptions = [];
        if (search && newAssociationOptions.length) {
            searchAssociationOptions = newAssociationOptions.filter(({ label }) =>
                label.toLowerCase().includes(search)
            );
        }

        setAssociationOptions(newAssociationOptions);
        setAssociationHasMore(hasMore);
        setAssociationSearch(search);

        return {
            options: search ? searchAssociationOptions : options,
            hasMore: hasMore,
            additional: {
                page: page + 1
            }
        };
    };

    const getAssociations = async (search, page) => {
        let options = [];
        let hasMore = false;
        let filter = {
            name: search,
            continuationToken: associationsListContinuationToken[page - 1]
        };
        let resultAssociations = await getBankLinkAssociations(auth, filter, 10);
        if (
            resultAssociations &&
            resultAssociations._embedded &&
            resultAssociations._embedded.associationEntityList &&
            resultAssociations._embedded.associationEntityList.length
        ) {
            options = resultAssociations._embedded.associationEntityList.map(item => {
                return {
                    value: item.id,
                    label: `${item.name} (${item.id})`
                };
            });
            hasMore = resultAssociations.page.hasNext;
            if (associationsListContinuationToken.length <= page) {
                associationsListContinuationToken.push(resultAssociations.page.continuationToken);
            }
        } else {
            setAssociationsListContinuationToken([]);
        }
        setAssociationsListContinuationToken(associationsListContinuationToken);
        return { options, hasMore };
    };

    return {
        loading,
        ruleInfo,
        handleUpdateRule,
        handleCreateRule,
        handleDeleteRule,
        handleLoadAssociationLazy,
        isNewRoule: hubId === "new" ? true : false
    };
};
