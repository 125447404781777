import { getRestCall } from "../../../utils/rest-api-call";
import { API_URL } from "../../../config";

export const GET_CONFIG_ARCHIVE_START = "GET_CONFIG_ARCHIVE_START";
export const GET_CONFIG_ARCHIVE_SUCCESS = "GET_CONFIG_ARCHIVE_SUCCESS";
export const GET_CONFIG_ARCHIVE_ERROR = "GET_CONFIG_ARCHIVE_ERROR";

export const GET_CONFIG_FOR_HOLDER_ARCHIVE_START = "GET_CONFIG_FOR_HOLDER_ARCHIVE_START";
export const GET_CONFIG_FOR_HOLDER_ARCHIVE_SUCCESS = "GET_CONFIG_FOR_HOLDER_ARCHIVE_SUCCESS";
export const GET_CONFIG_FOR_HOLDER_ARCHIVE_ERROR = "GET_CONFIG_FOR_HOLDER_ARCHIVE_ERROR";

export const GET_DOWNLOAD_DRAFT_RDC_START = "GET_DOWNLOAD_DRAFT_RDC_START";
export const GET_DOWNLOAD_DRAFT_RDC_SUCCESS = "GET_DOWNLOAD_DRAFT_RDC_SUCCESS";
export const GET_DOWNLOAD_DRAFT_RDC_ERROR = "GET_DOWNLOAD_DRAFT_RDC_ERROR";

export const GET_INFO_DRAFT_RDC_START = "GET_INFO_DRAFT_RDC_START";
export const GET_INFO_DRAFT_RDC_SUCCESS = "GET_INFO_DRAFT_RDC_SUCCESS";
export const GET_INFO_DRAFT_RDC_ERROR = "GET_INFO_DRAFT_RDC_ERROR";

export const GET_LIST_DRAFT_RDC_START = "GET_LIST_DRAFT_RDC_START";
export const GET_LIST_DRAFT_RDC_SUCCESS = "GET_LIST_DRAFT_RDC_SUCCESS";
export const GET_LIST_DRAFT_RDC_ERROR = "GET_LIST_DRAFT_RDC_ERROR";

export const GET_CONFIG_FOR_SUBMITTER_ARCHIVE_START = "GET_CONFIG_FOR_SUBMITTER_ARCHIVE_START";
export const GET_CONFIG_FOR_SUBMITTER_ARCHIVE_SUCCESS = "GET_CONFIG_FOR_SUBMITTER_ARCHIVE_SUCCESS";
export const GET_CONFIG_FOR_SUBMITTER_ARCHIVE_ERROR = "GET_CONFIG_FOR_SUBMITTER_ARCHIVE_ERROR";

export function getNewArchiveConfig(auth, submitterId, holderId) {
    return async dispatch => {
        dispatch({
            type: GET_CONFIG_ARCHIVE_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/config/${submitterId}/${holderId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_CONFIG_ARCHIVE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_CONFIG_ARCHIVE_ERROR,
                error: e
            });
        }
    };
}

export function getNewArchiveConfigForHolder(auth, holderId, page, size, filterRdC) {
    return async dispatch => {
        dispatch({
            type: GET_CONFIG_FOR_HOLDER_ARCHIVE_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/holderConfig/${holderId}?page=${page}&size=${size}${
                    filterRdC ? `&filterRdC=${filterRdC}` : ""
                }`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_CONFIG_FOR_HOLDER_ARCHIVE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_CONFIG_FOR_HOLDER_ARCHIVE_ERROR,
                error: e
            });
        }
    };
}

export function getNewArchiveConfigForHolderSearch(auth, submitterdId, holderId, page, size, filterRdC) {
    return async dispatch => {
        dispatch({
            type: GET_CONFIG_FOR_SUBMITTER_ARCHIVE_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/holderConfig/${holderId}?page=${page}&size=${size}${
                    filterRdC ? `&filterRdC=${filterRdC}` : ""
                }`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            let hitsFiltered = [];

            if (result && result.hits && result.hits.length) {
                hitsFiltered = result.hits.filter(hit => hit.submitter.uuid === submitterdId);
            }

            const resultFiltered = { hits: hitsFiltered, totalResult: hitsFiltered.length };

            dispatch({
                type: GET_CONFIG_FOR_SUBMITTER_ARCHIVE_SUCCESS,
                payload: resultFiltered
            });
            return resultFiltered;
        } catch (e) {
            dispatch({
                type: GET_CONFIG_FOR_SUBMITTER_ARCHIVE_ERROR,
                error: e
            });
        }
    };
}

export function getNewArchiveConfigForSubmitter(auth, submitterId, page, size, filterRdC) {
    return async dispatch => {
        dispatch({
            type: GET_CONFIG_FOR_SUBMITTER_ARCHIVE_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/submitterConfig/${submitterId}?page=${page}&size=${size}${
                    filterRdC ? `&filterRdC=${filterRdC}` : ""
                }`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_CONFIG_FOR_SUBMITTER_ARCHIVE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_CONFIG_FOR_SUBMITTER_ARCHIVE_ERROR,
                error: e
            });
        }
    };
}

export function downloadDraftRDC(auth, submitterId, holderId) {
    return async dispatch => {
        dispatch({
            type: GET_DOWNLOAD_DRAFT_RDC_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/content/${submitterId}/${holderId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_DOWNLOAD_DRAFT_RDC_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_DOWNLOAD_DRAFT_RDC_ERROR,
                error: e
            });
        }
    };
}

export function getInfoRDC(auth, submitterId, holderId) {
    return async dispatch => {
        dispatch({
            type: GET_INFO_DRAFT_RDC_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/infoRDC/${submitterId}/${holderId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_INFO_DRAFT_RDC_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_INFO_DRAFT_RDC_ERROR,
                error: e
            });
        }
    };
}

export function getListDraftRDC(auth, status, page, size, submitterId, holderId) {
    return async dispatch => {
        dispatch({
            type: GET_LIST_DRAFT_RDC_START
        });

        try {
            const result = await getRestCall(
                `${API_URL}/archive/list/${status}?page=${page}&size=${size}${
                    submitterId ? `&submitterId=${submitterId}` : ""
                }${holderId ? `&holderId=${holderId}` : ""}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_LIST_DRAFT_RDC_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_LIST_DRAFT_RDC_ERROR,
                error: e
            });
        }
    };
}
