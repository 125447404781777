import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { message } from "antd";

import { GENERATED_REPORT_URL, GENERATED_SEMPLIFIED_REPORT_URL } from "../../config";

import TsCard from "../general/ts-card";

import { CardDownloadWrapper } from "./styled.js";

import { userIsAuthorized } from "../../utils/get-roles";
export const ReportIV = ({ admin, handleDownloadCSV }) => {
    const displaySuccessToast = () => {
        message.success("Download documento avviato");
    };

    return (
        <div>
            {userIsAuthorized("c-download-report", admin.adminInfo.permits) ? (
                <CardDownloadWrapper className="content">
                    <TsCard
                        title={<FormattedMessage id="download_report_iv" />}
                        icon="fas fa-download"
                        description={<FormattedMessage id="download_report_iv_description" />}
                        action={() => {
                            displaySuccessToast();
                            handleDownloadCSV();
                        }}
                        displaySuccessMessage={displaySuccessToast}
                        actionLink={GENERATED_REPORT_URL}
                    />
                </CardDownloadWrapper>
            ) : null}
            {userIsAuthorized("c-download-report", admin.adminInfo.permits) ? (
                <CardDownloadWrapper className="content">
                    <TsCard
                        title={<FormattedMessage id="download_report_iv_semplified" />}
                        icon="fas fa-download"
                        description={<FormattedMessage id="download_report_iv_description_semplified" />}
                        action={() => {
                            displaySuccessToast();
                            handleDownloadCSV();
                        }}
                        displaySuccessMessage={displaySuccessToast}
                        actionLink={GENERATED_SEMPLIFIED_REPORT_URL}
                    />
                </CardDownloadWrapper>
            ) : null}
        </div>
    );
};

ReportIV.propTypes = {
    handleDownloadCSV: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired
};
