import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ContainerChronology = styled.div`
    padding-left: 15px;
    padding-top: 15px;
    min-width: 400px;
    width: 33%;
`;

export const ContainerTitle = styled.span`
    font-weight: bold;
    font-size: 24px;
`;

export const ContainerField = styled.span`
    font-weight: bold;
`;

export const ContainerHistoryTitle = styled.span`
    font-weight: bold;
`;

export const ContainerHistoryDescription = styled.span`
    font-size: 11px;
`;
