import styled from "styled-components";

export const SlotContainer = styled.div`
    display: flex;
`;

export const ProgressContainer = styled.div`
    flex:1
    margin:12px
`;

export const UsageText = styled.div`
    text-align: right;
`;

export const ExtraDataContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

export const ExtraDataItem = styled.div`
    width: 340px;
    margin-right: 10px;
`;
