import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { contains } from "ramda";
import { Alert } from "antd";

export const InvoiceAlerts = ({ document }) => {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        let alerts = [];
        if (document.content.payload === null) {
            alerts.push({
                key: "1",
                status: "warning",
                message: "Anteprima non disponibile"
            });
        }
        if (contains("SDI", document.infoD.flowType)) {
            if (
                document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "CARICATO" ||
                document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "REGISTRATO" ||
                document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "CONTROLLATO" ||
                document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "VALIDATO"
            ) {
                alerts.push({
                    key: "2",
                    status: "warning",
                    message: "Fattura in elaborazione"
                });
            }
            if (document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "NESSUNA_RISPOSTA") {
                alerts.push({
                    key: "3",
                    status: "warning",
                    message: "Nessuna risposta"
                });
            }
            if (document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "SCARTATO") {
                alerts.push({
                    key: "4",
                    status: "error",
                    message: "Fattura scartata"
                });
            }
            if (
                document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "IN_GESTIONE" &&
                (document.message.messages === undefined || document.message.messages.length === 0)
            ) {
                alerts.push({
                    key: "5",
                    status: "warning",
                    message: "In attesa di risposta da SDI"
                });
            }
            if (document.infoD.status.HUB[document.infoD.status.HUB.length - 1].name === "IN_GESTIONE") {
                alerts.push({
                    key: "6",
                    status: "success",
                    message: "Fattura inviata correttamente a SDI"
                });
            }
            if (
                document.message.messages &&
                document.message.messages.length > 0 &&
                document.message.messages[document.message.messages.length - 1].sdi.type === "RC"
            ) {
                alerts.push({
                    key: "7",
                    status: "success",
                    message: "Consegnata da SDI"
                });
            }
        }
        if (document.infoD.status.CCT) {
            if (document.infoD.status.CCT[document.infoD.status.CCT.length - 1].name === "ERRORE") {
                alerts.push({
                    key: "8",
                    status: "error",
                    message: "Errore durante l'upload in CCT"
                });
            }
            if (document.infoD.status.CCT[document.infoD.status.CCT.length - 1].name === "IN_CARICO") {
                alerts.push({
                    key: "9",
                    status: "warning",
                    message: "Richiesta l'upload in CCT presa in carico"
                });
            }
        }
        if (document.invoiceDeadletter) {
            if (
                document.invoiceDeadletter.status.errorInfo &&
                document.invoiceDeadletter.status.errorInfo.code !== "404"
            ) {
                alerts.push({
                    key: "10",
                    status: "error",
                    message: document.invoiceDeadletter.status.errorInfo.message
                });
            }
            if (document.invoiceDeadletter.status.ended && Object.keys(document.invoiceDeadletter).length > 1) {
                for (var i = 0; i < Object.keys(document.invoiceDeadletter).length - 1; i++) {
                    alerts.push({
                        key: "11-" + i,
                        status: "error",
                        message:
                            document.invoiceDeadletter[i].properties.DeadLetterReason +
                            " - " +
                            document.invoiceDeadletter[i].properties.DeadLetterErrorDescription
                    });
                }
            }
        }
        setAlerts(alerts);
    }, [document]);
    return (
        <div>
            {alerts.length > 0 ? (
                alerts.map((alert, index) => (
                    <Alert
                        key={index}
                        message={alert.message}
                        description={alert.description}
                        type={alert.status}
                        showIcon
                        style={{ marginBottom: "8px" }}
                    />
                ))
            ) : (
                <Alert showIcon type="success" message="Nessun avviso" />
            )}
        </div>
    );
};

InvoiceAlerts.propTypes = {
    document: PropTypes.object.isRequired
};
