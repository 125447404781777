import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";
import { InvoiceFooter } from "../../../components/general/invoice-footer";
import { translatePaymentStatus } from "../../../utils/translate";

export const Payment = ({
    loading,
    paymentsEntityList,
    handleNextPage,
    currentPage,
    hasNextPage,
    handlePreviousPage,
    handlePageSizeChanged,
    defaultPageSize,
    query,
    pageSizeOptions
}) => {
    let columns = [
        {
            title: <FormattedMessage id={"customer"} />,
            width: "15%",
            dataIndex: "debtor.name"
        },
        {
            title: <FormattedMessage id={"causale"} />,
            width: "15%",
            dataIndex: "paymentReason"
        },
        {
            title: <FormattedMessage id={"importo_totale"} />,
            width: "15%",
            dataIndex: "totalAmount.amount"
        },
        {
            title: <FormattedMessage id={"richiesta_creata"} />,
            width: "15%",
            dataIndex: "createdAt"
        },

        {
            title: <FormattedMessage id={"stato_richiesta"} />,
            width: "15%",
            dataIndex: "status",
            render: status => translatePaymentStatus(status)
        }
    ];
    return (
        <div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="large"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={paymentsEntityList}
                    loading={loading}
                    pagination={false}
                    scroll={{ y: 540 }}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize.toString()}
                disabled={loading}
                handleInvoicePageSizeChanged={handlePageSizeChanged}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === 1}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

Payment.propTypes = {
    loading: PropTypes.bool.isRequired,
    paymentsEntityList: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageSizeChanged: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.any.isRequired,
    query: PropTypes.any.isRequired,
    pageSizeOptions: PropTypes.array.isRequired
};
