import {
    POST_METERING_REALTIME_REASSIGNMENTS_START,
    POST_METERING_REALTIME_REASSIGNMENTS_SUCCESS,
    POST_METERING_REALTIME_REASSIGNMENTS_ERROR
} from "../../actions/usage/realtime";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const postReassignments = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_METERING_REALTIME_REASSIGNMENTS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_METERING_REALTIME_REASSIGNMENTS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_METERING_REALTIME_REASSIGNMENTS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};
