/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./registry-corrector-api_types');
//HELPER FUNCTIONS AND STRUCTURES

var RegistryCorrectorApi_health_args = function(args) {
};
RegistryCorrectorApi_health_args.prototype = {};
RegistryCorrectorApi_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_health_args.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
RegistryCorrectorApi_health_result.prototype = {};
RegistryCorrectorApi_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_health_result.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceUserId_args = function(args) {
  this.auth = null;
  this.replaceUserId = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.replaceUserId !== undefined && args.replaceUserId !== null) {
      this.replaceUserId = new ttypes.ReplaceUserId(args.replaceUserId);
    }
  }
};
RegistryCorrectorApi_replaceUserId_args.prototype = {};
RegistryCorrectorApi_replaceUserId_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.replaceUserId = new ttypes.ReplaceUserId();
        this.replaceUserId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceUserId_args.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceUserId_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.replaceUserId !== null && this.replaceUserId !== undefined) {
    output.writeFieldBegin('replaceUserId', Thrift.Type.STRUCT, 2);
    this.replaceUserId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceUserId_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.registryException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.RegistryException) {
    this.registryException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.registryException !== undefined && args.registryException !== null) {
      this.registryException = args.registryException;
    }
  }
};
RegistryCorrectorApi_replaceUserId_result.prototype = {};
RegistryCorrectorApi_replaceUserId_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.registryException = new ttypes.RegistryException();
        this.registryException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceUserId_result.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceUserId_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.registryException !== null && this.registryException !== undefined) {
    output.writeFieldBegin('registryException', Thrift.Type.STRUCT, 3);
    this.registryException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceCompanyId_args = function(args) {
  this.auth = null;
  this.replaceCompanyId = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.replaceCompanyId !== undefined && args.replaceCompanyId !== null) {
      this.replaceCompanyId = new ttypes.ReplaceCompanyId(args.replaceCompanyId);
    }
  }
};
RegistryCorrectorApi_replaceCompanyId_args.prototype = {};
RegistryCorrectorApi_replaceCompanyId_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.replaceCompanyId = new ttypes.ReplaceCompanyId();
        this.replaceCompanyId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceCompanyId_args.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceCompanyId_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.replaceCompanyId !== null && this.replaceCompanyId !== undefined) {
    output.writeFieldBegin('replaceCompanyId', Thrift.Type.STRUCT, 2);
    this.replaceCompanyId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceCompanyId_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.registryException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.RegistryException) {
    this.registryException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.registryException !== undefined && args.registryException !== null) {
      this.registryException = args.registryException;
    }
  }
};
RegistryCorrectorApi_replaceCompanyId_result.prototype = {};
RegistryCorrectorApi_replaceCompanyId_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.registryException = new ttypes.RegistryException();
        this.registryException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceCompanyId_result.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceCompanyId_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.registryException !== null && this.registryException !== undefined) {
    output.writeFieldBegin('registryException', Thrift.Type.STRUCT, 3);
    this.registryException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceClassifier_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ReplaceClassifier(args.request);
    }
  }
};
RegistryCorrectorApi_replaceClassifier_args.prototype = {};
RegistryCorrectorApi_replaceClassifier_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ReplaceClassifier();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceClassifier_args.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceClassifier_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceClassifier_result = function(args) {
  this.genericError = null;
  this.authenticationException = null;
  this.registryException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.RegistryException) {
    this.registryException = args;
    return;
  }
  if (args) {
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.registryException !== undefined && args.registryException !== null) {
      this.registryException = args.registryException;
    }
  }
};
RegistryCorrectorApi_replaceClassifier_result.prototype = {};
RegistryCorrectorApi_replaceClassifier_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.registryException = new ttypes.RegistryException();
        this.registryException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceClassifier_result.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceClassifier_result');
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.registryException !== null && this.registryException !== undefined) {
    output.writeFieldBegin('registryException', Thrift.Type.STRUCT, 3);
    this.registryException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceVatNumber_args = function(args) {
  this.auth = null;
  this.replaceVatNumber = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.replaceVatNumber !== undefined && args.replaceVatNumber !== null) {
      this.replaceVatNumber = new ttypes.ReplaceVatNumber(args.replaceVatNumber);
    }
  }
};
RegistryCorrectorApi_replaceVatNumber_args.prototype = {};
RegistryCorrectorApi_replaceVatNumber_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.replaceVatNumber = new ttypes.ReplaceVatNumber();
        this.replaceVatNumber.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceVatNumber_args.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceVatNumber_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.replaceVatNumber !== null && this.replaceVatNumber !== undefined) {
    output.writeFieldBegin('replaceVatNumber', Thrift.Type.STRUCT, 2);
    this.replaceVatNumber.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_replaceVatNumber_result = function(args) {
  this.genericError = null;
  this.authenticationException = null;
  this.registryException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.RegistryException) {
    this.registryException = args;
    return;
  }
  if (args) {
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.registryException !== undefined && args.registryException !== null) {
      this.registryException = args.registryException;
    }
  }
};
RegistryCorrectorApi_replaceVatNumber_result.prototype = {};
RegistryCorrectorApi_replaceVatNumber_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.registryException = new ttypes.RegistryException();
        this.registryException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_replaceVatNumber_result.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_replaceVatNumber_result');
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.registryException !== null && this.registryException !== undefined) {
    output.writeFieldBegin('registryException', Thrift.Type.STRUCT, 3);
    this.registryException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_updateItemIdentifier_args = function(args) {
  this.auth = null;
  this.updateIdentifier = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.updateIdentifier !== undefined && args.updateIdentifier !== null) {
      this.updateIdentifier = new ttypes.UpdateIdentifier(args.updateIdentifier);
    }
  }
};
RegistryCorrectorApi_updateItemIdentifier_args.prototype = {};
RegistryCorrectorApi_updateItemIdentifier_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.updateIdentifier = new ttypes.UpdateIdentifier();
        this.updateIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_updateItemIdentifier_args.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_updateItemIdentifier_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.updateIdentifier !== null && this.updateIdentifier !== undefined) {
    output.writeFieldBegin('updateIdentifier', Thrift.Type.STRUCT, 2);
    this.updateIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApi_updateItemIdentifier_result = function(args) {
  this.genericError = null;
  this.authenticationException = null;
  this.registryException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.RegistryException) {
    this.registryException = args;
    return;
  }
  if (args) {
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.registryException !== undefined && args.registryException !== null) {
      this.registryException = args.registryException;
    }
  }
};
RegistryCorrectorApi_updateItemIdentifier_result.prototype = {};
RegistryCorrectorApi_updateItemIdentifier_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.registryException = new ttypes.RegistryException();
        this.registryException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryCorrectorApi_updateItemIdentifier_result.prototype.write = function(output) {
  output.writeStructBegin('RegistryCorrectorApi_updateItemIdentifier_result');
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.registryException !== null && this.registryException !== undefined) {
    output.writeFieldBegin('registryException', Thrift.Type.STRUCT, 3);
    this.registryException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryCorrectorApiClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
RegistryCorrectorApiClient.prototype = {};
RegistryCorrectorApiClient.prototype.seqid = function() { return this._seqid; };
RegistryCorrectorApiClient.prototype.new_seqid = function() { return this._seqid += 1; };

RegistryCorrectorApiClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

RegistryCorrectorApiClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  var args = new RegistryCorrectorApi_health_args();
  try {
    output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

RegistryCorrectorApiClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new RegistryCorrectorApi_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};

RegistryCorrectorApiClient.prototype.replaceUserId = function(auth, replaceUserId, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_replaceUserId(auth, replaceUserId);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_replaceUserId(auth, replaceUserId);
  }
};

RegistryCorrectorApiClient.prototype.send_replaceUserId = function(auth, replaceUserId) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    replaceUserId: replaceUserId
  };
  var args = new RegistryCorrectorApi_replaceUserId_args(params);
  try {
    output.writeMessageBegin('replaceUserId', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

RegistryCorrectorApiClient.prototype.recv_replaceUserId = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new RegistryCorrectorApi_replaceUserId_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.registryException) {
    return callback(result.registryException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('replaceUserId failed: unknown result');
};

RegistryCorrectorApiClient.prototype.replaceCompanyId = function(auth, replaceCompanyId, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_replaceCompanyId(auth, replaceCompanyId);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_replaceCompanyId(auth, replaceCompanyId);
  }
};

RegistryCorrectorApiClient.prototype.send_replaceCompanyId = function(auth, replaceCompanyId) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    replaceCompanyId: replaceCompanyId
  };
  var args = new RegistryCorrectorApi_replaceCompanyId_args(params);
  try {
    output.writeMessageBegin('replaceCompanyId', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

RegistryCorrectorApiClient.prototype.recv_replaceCompanyId = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new RegistryCorrectorApi_replaceCompanyId_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.registryException) {
    return callback(result.registryException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('replaceCompanyId failed: unknown result');
};

RegistryCorrectorApiClient.prototype.replaceClassifier = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_replaceClassifier(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_replaceClassifier(auth, request);
  }
};

RegistryCorrectorApiClient.prototype.send_replaceClassifier = function(auth, request) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    request: request
  };
  var args = new RegistryCorrectorApi_replaceClassifier_args(params);
  try {
    output.writeMessageBegin('replaceClassifier', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

RegistryCorrectorApiClient.prototype.recv_replaceClassifier = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new RegistryCorrectorApi_replaceClassifier_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.registryException) {
    return callback(result.registryException);
  }
  callback(null);
};

RegistryCorrectorApiClient.prototype.replaceVatNumber = function(auth, replaceVatNumber, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_replaceVatNumber(auth, replaceVatNumber);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_replaceVatNumber(auth, replaceVatNumber);
  }
};

RegistryCorrectorApiClient.prototype.send_replaceVatNumber = function(auth, replaceVatNumber) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    replaceVatNumber: replaceVatNumber
  };
  var args = new RegistryCorrectorApi_replaceVatNumber_args(params);
  try {
    output.writeMessageBegin('replaceVatNumber', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

RegistryCorrectorApiClient.prototype.recv_replaceVatNumber = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new RegistryCorrectorApi_replaceVatNumber_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.registryException) {
    return callback(result.registryException);
  }
  callback(null);
};

RegistryCorrectorApiClient.prototype.updateItemIdentifier = function(auth, updateIdentifier, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_updateItemIdentifier(auth, updateIdentifier);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_updateItemIdentifier(auth, updateIdentifier);
  }
};

RegistryCorrectorApiClient.prototype.send_updateItemIdentifier = function(auth, updateIdentifier) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    updateIdentifier: updateIdentifier
  };
  var args = new RegistryCorrectorApi_updateItemIdentifier_args(params);
  try {
    output.writeMessageBegin('updateItemIdentifier', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

RegistryCorrectorApiClient.prototype.recv_updateItemIdentifier = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new RegistryCorrectorApi_updateItemIdentifier_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.registryException) {
    return callback(result.registryException);
  }
  callback(null);
};
var RegistryCorrectorApiProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
RegistryCorrectorApiProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
RegistryCorrectorApiProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new RegistryCorrectorApi_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health.bind(this._handler)
    ).then(function(result) {
      var result_obj = new RegistryCorrectorApi_health_result({success: result});
      output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
      output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new RegistryCorrectorApi_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
RegistryCorrectorApiProcessor.prototype.process_replaceUserId = function(seqid, input, output) {
  var args = new RegistryCorrectorApi_replaceUserId_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.replaceUserId.length === 2) {
    Q.fcall(this._handler.replaceUserId.bind(this._handler),
      args.auth,
      args.replaceUserId
    ).then(function(result) {
      var result_obj = new RegistryCorrectorApi_replaceUserId_result({success: result});
      output.writeMessageBegin("replaceUserId", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result = new RegistryCorrectorApi_replaceUserId_result(err);
        output.writeMessageBegin("replaceUserId", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceUserId", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.replaceUserId(args.auth, args.replaceUserId, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result_obj = new RegistryCorrectorApi_replaceUserId_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("replaceUserId", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceUserId", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
RegistryCorrectorApiProcessor.prototype.process_replaceCompanyId = function(seqid, input, output) {
  var args = new RegistryCorrectorApi_replaceCompanyId_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.replaceCompanyId.length === 2) {
    Q.fcall(this._handler.replaceCompanyId.bind(this._handler),
      args.auth,
      args.replaceCompanyId
    ).then(function(result) {
      var result_obj = new RegistryCorrectorApi_replaceCompanyId_result({success: result});
      output.writeMessageBegin("replaceCompanyId", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result = new RegistryCorrectorApi_replaceCompanyId_result(err);
        output.writeMessageBegin("replaceCompanyId", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceCompanyId", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.replaceCompanyId(args.auth, args.replaceCompanyId, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result_obj = new RegistryCorrectorApi_replaceCompanyId_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("replaceCompanyId", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceCompanyId", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
RegistryCorrectorApiProcessor.prototype.process_replaceClassifier = function(seqid, input, output) {
  var args = new RegistryCorrectorApi_replaceClassifier_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.replaceClassifier.length === 2) {
    Q.fcall(this._handler.replaceClassifier.bind(this._handler),
      args.auth,
      args.request
    ).then(function(result) {
      var result_obj = new RegistryCorrectorApi_replaceClassifier_result({success: result});
      output.writeMessageBegin("replaceClassifier", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result = new RegistryCorrectorApi_replaceClassifier_result(err);
        output.writeMessageBegin("replaceClassifier", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceClassifier", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.replaceClassifier(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result_obj = new RegistryCorrectorApi_replaceClassifier_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("replaceClassifier", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceClassifier", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
RegistryCorrectorApiProcessor.prototype.process_replaceVatNumber = function(seqid, input, output) {
  var args = new RegistryCorrectorApi_replaceVatNumber_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.replaceVatNumber.length === 2) {
    Q.fcall(this._handler.replaceVatNumber.bind(this._handler),
      args.auth,
      args.replaceVatNumber
    ).then(function(result) {
      var result_obj = new RegistryCorrectorApi_replaceVatNumber_result({success: result});
      output.writeMessageBegin("replaceVatNumber", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result = new RegistryCorrectorApi_replaceVatNumber_result(err);
        output.writeMessageBegin("replaceVatNumber", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceVatNumber", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.replaceVatNumber(args.auth, args.replaceVatNumber, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result_obj = new RegistryCorrectorApi_replaceVatNumber_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("replaceVatNumber", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("replaceVatNumber", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
RegistryCorrectorApiProcessor.prototype.process_updateItemIdentifier = function(seqid, input, output) {
  var args = new RegistryCorrectorApi_updateItemIdentifier_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.updateItemIdentifier.length === 2) {
    Q.fcall(this._handler.updateItemIdentifier.bind(this._handler),
      args.auth,
      args.updateIdentifier
    ).then(function(result) {
      var result_obj = new RegistryCorrectorApi_updateItemIdentifier_result({success: result});
      output.writeMessageBegin("updateItemIdentifier", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result = new RegistryCorrectorApi_updateItemIdentifier_result(err);
        output.writeMessageBegin("updateItemIdentifier", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("updateItemIdentifier", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.updateItemIdentifier(args.auth, args.updateIdentifier, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.RegistryException) {
        result_obj = new RegistryCorrectorApi_updateItemIdentifier_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("updateItemIdentifier", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("updateItemIdentifier", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
