import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tag } from "antd";
import { translateDeniedFunctionality } from "../../../../utils/translate";
import { invoicesConfigValues } from "../../../../utils/constant";

export const InvoicesConfigExpanded = ({ dataSource }) => {
    const columns = [
        {
            title: <FormattedMessage id="functionality" />,
            dataIndex: "functionality",
            key: "functionality",
            render: text => translateDeniedFunctionality(text)
        },
        {
            title: <FormattedMessage id="not_allowed" />,
            dataIndex: "status",
            key: "status",
            render: text => (
                <span>
                    {text === "enabled" ? (
                        <Tag color="green">{<FormattedMessage id={"enabled"} />}</Tag>
                    ) : (
                        <Tag color="red">{<FormattedMessage id={"not_enabled"} />}</Tag>
                    )}
                </span>
            )
        }
    ];

    let data = [];
    let status = "enabled";
    invoicesConfigValues.forEach(element => {
        if (dataSource !== undefined) {
            let row = dataSource.find(x => x === element.id);
            if (row !== undefined) {
                status = "disabled";
                data.push({ ...element, status });
            } else data.push(element);
        }
    });

    return (
        <div>
            <Table
                rowKey="functionality"
                dataSource={data}
                columns={columns}
                size="middle"
                pagination={false}
                align="left"
            />
        </div>
    );
};

InvoicesConfigExpanded.propTypes = {
    dataSource: PropTypes.array
};
