import React, { Component } from "react";
import { Avatar, Dropdown, Button, Spin, Modal, Menu } from "antd";

import { ENV } from "../../../config";

import PropTypes from "prop-types";
import packageInfo from "../../../../package.json";
import marked from "marked";
import { userIsAuthorized } from "../../../utils/get-roles";
import "./style.css";
import { PointerIcon, CopyToClip } from "../../../styled";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

import { LogoutButton } from "@ts-digital/digital-login-component";
import { getProfileInfo } from "../../../utils/get-roles";
class UserBadge extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
        isConsoleAdmin: PropTypes.bool.isRequired,
        openAdminConsole: PropTypes.func.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = {
            mchangeLog: false
        };
    }

    componentDidMount() {
        const readmePath = require("../../../CHANGELOG.md");
        fetch(readmePath)
            .then(response => {
                return response.text();
            })
            .then(text => {
                this.setState({
                    markdown: marked(text)
                });
            });
    }

    render() {
        const {
            admin,
            admin: {
                adminInfo: {
                    permits: { isAdminUser, isSuperUser, isAdvancedUser, isExpertUser },
                    profile: { firstName, lastName }
                }
            },
            auth,
            logout,
            isConsoleAdmin,
            openAdminConsole
        } = this.props;
        const {
            adminInfo: { permits }
        } = admin;
        const { mchangeLog, markdown } = this.state;

        const menu = (
            <Menu>
                <Menu.Item disabled>
                    {"Utente: " + firstName + " " + lastName}
                    <CopyToClip text={auth.loginAuth.securityToken}>
                        <PointerIcon icon={faCopy} />
                    </CopyToClip>
                </Menu.Item>
                <Menu.Item disabled>Ruolo: {getProfileInfo(permits).text}</Menu.Item>
                <Menu.Item disabled>{"eMail: " + admin.adminInfo.profile.id}</Menu.Item>
                <Menu.Item disabled>{"TSID: " + admin.adminInfo.profile.tsid}</Menu.Item>
                <Menu.Divider />
                {userIsAuthorized("c-user-badge-console", permits) ? (
                    <Menu.Item>
                        <div onClick={openAdminConsole} style={{ width: "100%" }}>
                            <i className="fas fa-user-cog" style={{ position: "absolute", right: 15 }} />
                            {!isConsoleAdmin ? " Pannello di Controllo" : " Console"}
                        </div>
                    </Menu.Item>
                ) : null}
                <Menu.Item>
                    <div onClick={() => this.setState({ mchangeLog: true })}>
                        <i className="fas fa-tag" style={{ position: "absolute", right: 15 }} />
                        {"Versione " + packageInfo.version}
                    </div>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={logout} disabled>
                    {auth.loginAuth.idToken ? (
                        <LogoutButton
                            buttonText={"Esci"}
                            env={ENV === "preprod" || ENV === "master" ? "prod" : ENV}
                            mode="redirect"
                            idToken={auth.loginAuth.idToken}
                            redirectUrl={window.location.origin}
                            beforeLogout={() => sessionStorage.clear()}
                        />
                    ) : (
                        <Button icon="poweroff" onClick={logout} style={{ width: "100%" }} type="primary" ghost>
                            {"Esci"}
                        </Button>
                    )}
                </Menu.Item>
            </Menu>
        );

        const getAvatarColor = () => {
            if (isSuperUser) {
                return "su-icon";
            } else if (isAdminUser) {
                return "admin-icon";
            } else if (isAdvancedUser) {
                return "advanced-icon";
            } else if (isExpertUser) {
                return "expert-icon";
            } else {
                return "user-icon";
            }
        };

        return admin.adminInfo.profile ? (
            <div className="c-user-badge">
                <Dropdown overlay={menu} placement="bottomCenter" trigger={["hover", "click"]}>
                    <div>
                        <Avatar className={getAvatarColor()}>
                            {admin.adminInfo.profile.firstName.charAt(0).toUpperCase()}
                        </Avatar>
                    </div>
                </Dropdown>
                <Modal
                    footer={null}
                    onCancel={() => this.setState({ mchangeLog: false })}
                    width={800}
                    title={"CHANGE LOG"}
                    visible={mchangeLog}
                >
                    <section>
                        <article dangerouslySetInnerHTML={{ __html: markdown }} />
                    </section>
                </Modal>
            </div>
        ) : (
            <Spin />
        );
    }
}

export default UserBadge;
