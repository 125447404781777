import { METERING_PEOPLEAPP_URL } from "../../config";
import { postRestCall } from "../../utils/rest-api-call";

export const PEOPLEAPP_SERVICE_START = "PEOPLEAPP_SERVICE_START";
export const PEOPLEAPP_SERVICE_ERROR = "PEOPLEAPP_SERVICE_ERROR";
export const PEOPLEAPP_SERVICE_SUCCESS = "PEOPLEAPP_SERVICE_SUCCESS";

export function activatePeopleAppService(auth, cf, type) {
    return async dispatch => {
        dispatch({
            type: PEOPLEAPP_SERVICE_START
        });

        let params = {
            autoRenewal: true,
            channel: "OFFLINE",
            expiryDate: "2099-12-31T23:59:59.999Z",
            family: "PEOPLEAPP",
            lockable: false,
            name: "PEOPLEAPP",
            newYearRenewal: false,
            primaryUsageLimit: 0,
            procedureType: "AGYO",
            type: type
        };
        let request = {
            owner: cf,
            package: params
        };

        const toast = {
            onOk: true,
            onOkText: "People App attivato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await postRestCall(
                `${METERING_PEOPLEAPP_URL}`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                {},
                toast
            );

            dispatch({
                type: PEOPLEAPP_SERVICE_SUCCESS,
                payload: result
            });

            return result;
        } catch (e) {
            dispatch({
                type: PEOPLEAPP_SERVICE_ERROR,
                error: e
            });
        }
    };
}
