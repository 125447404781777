import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tabs, Switch } from "antd";
import { userIsAuthorized } from "../../../utils/get-roles";

import { InvoicesUnassigned } from "./invoices-unassigned";
import { InvoiceUsageDetails } from "./invoices-usage";
import { InvoicesReceived } from "./invoices-received";
import { InvoicesSend } from "./invoices-send";
import { InvoicesWebhook } from "./invoices-webhook";
import { InvoicesUserConfig } from "./invoices-config-user";
export const CompanyInvoices = ({
    admin,
    auth,
    company,
    getInvoices,
    getInvoice,
    trashInvoice,
    untrashInvoice,
    deleteInvoice,
    getInvoicingDetails,
    getInvoicingStatistics,
    getUnassignedInvoices,
    postUnassignedInvoices,
    postNotAssignabledInvoices,
    history,
    invoices,
    findOwnManagedConnections,
    invoicesConfigList,
    setDocument,
    usage,
    webhookList
}) => {
    const [typeDoc, setTypeDoc] = useState("usage");
    const [expanded, setExpanded] = useState(false);
    const [invoiceReceivedFilter, setInvoiceReceivedFilter] = useState(null);
    const [invoiceReceivedSort, setInvoiceReceivedSort] = useState(null);
    const [invoiceSendFilter, setInvoiceSendFilter] = useState(null);
    const [invoiceSendSort, setInvoiceSendSort] = useState(null);
    const [toggleReceivedTabs, setToggleReceivedTabs] = useState(false);
    const [toggleSendTabs, setToggleSendTabs] = useState(false);
    const [disableExpanded, setDisableExpanded] = useState(true);

    useEffect(() => {
        let expanded = localStorage.getItem("CompanyInvoices:expanded");
        setExpanded(expanded && expanded === "true" ? true : false);
        let companyId = localStorage.getItem("CompanyInvoices:companyId");
        if (companyId === company.info.base.id) {
            let invoiceReceivedFilter = localStorage.getItem("CompanyInvoices:invoiceReceivedFilter");
            setInvoiceReceivedFilter(JSON.parse(invoiceReceivedFilter));
            let invoiceSendFilter = localStorage.getItem("CompanyInvoices:invoiceSendFilter");
            setInvoiceSendFilter(JSON.parse(invoiceSendFilter));
        } else {
            localStorage.removeItem("CompanyInvoices:invoiceReceivedFilter");
            localStorage.removeItem("CompanyInvoices:invoiceSendFilter");
        }
        var paths = history.location.pathname.split("/");
        setTypeDoc(paths[paths.length - 1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let disableExpanded;
        if (
            typeDoc === "usage" ||
            typeDoc === "configurations" ||
            typeDoc === "unassigned" ||
            typeDoc === "invoices_configuration" ||
            !userIsAuthorized("c-companyInvoices-switch", admin.adminInfo.permits)
        ) {
            disableExpanded = true;
        } else {
            disableExpanded = false;
        }

        setDisableExpanded(disableExpanded);
    }, [admin.adminInfo.permits, typeDoc]);

    const handleChangeExpanded = value => {
        localStorage.setItem("CompanyInvoices:expanded", value);
        setExpanded(value);
    };

    const handleChangeInvoiceReceivedFilter = value => {
        localStorage.setItem("CompanyInvoices:invoiceReceivedFilter", JSON.stringify(value));
        localStorage.setItem("CompanyInvoices:companyId", company.info.base.id);
        setInvoiceReceivedFilter(value);
    };

    const handleChangeInvoiceReceivedSort = value => {
        setInvoiceReceivedSort(value);
    };

    const handleChangeInvoiceSendSort = value => {
        setInvoiceSendSort(value);
    };

    const handleChangeInvoiceSendFilter = value => {
        localStorage.setItem("CompanyInvoices:invoiceSendFilter", JSON.stringify(value));
        localStorage.setItem("CompanyInvoices:companyId", company.info.base.id);
        setInvoiceSendFilter(value);
    };

    return (
        <div>
            <Tabs
                variant="secondary"
                onChange={e => {
                    setTypeDoc(e);
                    history.push(e);
                    if (e === "received") {
                        setToggleReceivedTabs(!toggleReceivedTabs);
                    } else if (e === "send") {
                        setToggleSendTabs(!toggleSendTabs);
                    }
                }}
                tabBarExtraContent={
                    typeDoc !== "usage" &&
                    typeDoc !== "configurations" &&
                    typeDoc !== "unassigned" &&
                    typeDoc !== "invoices_configuration" && (
                        <Switch
                            defaultChecked={false}
                            checked={expanded}
                            checkedChildren={<FormattedMessage id="reduce" />}
                            unCheckedChildren={<FormattedMessage id="expand" />}
                            disabled={disableExpanded}
                            onChange={e => handleChangeExpanded(e)}
                            style={{
                                marginTop: "-9px",
                                marginRight: "30px"
                            }}
                        />
                    )
                }
            >
                <Tabs.TabPane tab={<FormattedMessage id="usages" />} key="usage">
                    <InvoiceUsageDetails
                        auth={auth}
                        company={company}
                        getInvoicingDetails={getInvoicingDetails}
                        getInvoicingStatistics={getInvoicingStatistics}
                        findOwnManagedConnections={findOwnManagedConnections}
                        usage={usage}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="receive" />} key="received">
                    <InvoicesReceived
                        auth={auth}
                        admin={admin}
                        company={company}
                        invoices={invoices}
                        getInvoices={getInvoices}
                        getInvoice={getInvoice}
                        setDocument={setDocument}
                        untrashInvoice={untrashInvoice}
                        trashInvoice={trashInvoice}
                        deleteInvoice={deleteInvoice}
                        history={history}
                        invoiceReceivedFilter={invoiceReceivedFilter}
                        handleChangeInvoiceReceivedFilter={handleChangeInvoiceReceivedFilter}
                        invoiceReceivedSort={invoiceReceivedSort}
                        handleChangeInvoiceReceivedSort={handleChangeInvoiceReceivedSort}
                        expanded={expanded}
                        toggleReceivedTabs={toggleReceivedTabs}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="issued" />} key="send">
                    <InvoicesSend
                        auth={auth}
                        admin={admin}
                        company={company}
                        invoices={invoices}
                        getInvoices={getInvoices}
                        getInvoice={getInvoice}
                        setDocument={setDocument}
                        untrashInvoice={untrashInvoice}
                        trashInvoice={trashInvoice}
                        deleteInvoice={deleteInvoice}
                        history={history}
                        invoiceSendFilter={invoiceSendFilter}
                        handleChangeInvoiceSendFilter={handleChangeInvoiceSendFilter}
                        invoiceSendSort={invoiceSendSort}
                        handleChangeInvoiceSendSort={handleChangeInvoiceSendSort}
                        expanded={expanded}
                        toggleSendTabs={toggleSendTabs}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="not_assigned" />} key="unassigned">
                    <InvoicesUnassigned
                        auth={auth}
                        admin={admin}
                        company={company}
                        expanded={expanded}
                        getUnassignedInvoices={getUnassignedInvoices}
                        postNotAssignabledInvoices={postNotAssignabledInvoices}
                        postUnassignedInvoices={postUnassignedInvoices}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="configurations" />} key="configurations">
                    <InvoicesWebhook webhookList={webhookList} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="invoices_configuration" />} key="invoices_configuration">
                    <InvoicesUserConfig invoicesConfigList={invoicesConfigList} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

CompanyInvoices.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    getInvoices: PropTypes.func.isRequired,
    getInvoice: PropTypes.func.isRequired,
    trashInvoice: PropTypes.func.isRequired,
    deleteInvoice: PropTypes.func.isRequired,
    untrashInvoice: PropTypes.func.isRequired,
    getInvoicingDetails: PropTypes.func.isRequired,
    getInvoicingStatistics: PropTypes.func.isRequired,
    getUnassignedInvoices: PropTypes.func.isRequired,
    postUnassignedInvoices: PropTypes.func.isRequired,
    postNotAssignabledInvoices: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    invoices: PropTypes.object.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    invoicesConfigList: PropTypes.array,
    setDocument: PropTypes.func.isRequired,
    usage: PropTypes.object.isRequired,
    webhookList: PropTypes.object,
    postInvoiceConservation: PropTypes.func.isRequired,
    invoiceConservation: PropTypes.object
};
