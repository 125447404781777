import { getRestCall } from "../../utils/rest-api-call";
import { API_URL, SIGNER_READ_URL } from "../../config";

export const GET_SIGNATURE_CONFIG_START = "GET_SIGNATURE_CONFIG_START";
export const GET_SIGNATURE_CONFIG_ERROR = "GET_SIGNATURE_CONFIG_ERROR";
export const GET_SIGNATURE_CONFIG_SUCCESS = "GET_SIGNATURE_CONFIG_SUCCESS";

export function getSignatureConfig(auth, companyUuid) {
    return async dispatch => {
        dispatch({
            type: GET_SIGNATURE_CONFIG_START,
            uuid: companyUuid
        });

        let urlParams = {
            uuid: companyUuid
        };

        try {
            const result = await getRestCall(`${API_URL}/signature`, auth, urlParams, dispatch, auth.refreshToken);

            dispatch({
                type: GET_SIGNATURE_CONFIG_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_SIGNATURE_CONFIG_ERROR,
                error: e
            });
        }
    };
}

export const GET_SIGNATURE_CONFIG_RESET = "GET_SIGNATURE_CONFIG_RESET";

export function resetSignatureConfig() {
    return {
        type: GET_SIGNATURE_CONFIG_RESET
    };
}

export const GET_SIGNATURE_SIGNER_START = "GET_SIGNATURE_SIGNER_START";
export const GET_SIGNATURE_SIGNER_SUCCESS = "GET_SIGNATURE_SIGNER_SUCCESS";
export const GET_SIGNATURE_SIGNER_ERROR = "GET_SIGNATURE_SIGNER_ERROR";

export function getSignatureSigner(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_SIGNATURE_SIGNER_START
        });

        const urlParameters = {
            serviceName: "SIGNATURE-FLOW",
            companyFiscalCode: itemId
        };

        try {
            const result = await getRestCall(
                `${SIGNER_READ_URL}/signers/listSigners`,
                auth,
                urlParameters,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_SIGNATURE_SIGNER_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_SIGNATURE_SIGNER_ERROR,
                error: e
            });
        }
    };
}
