export function serviceDecode(value) {
    let background;
    let icon;
    let title;
    let color;

    switch (value) {
        case "TEL":
            background = "#e6f7ff";
            color = "#00bcd4";
            icon = "fas fa-paper-plane";
            title = "Telematici";
            return { background, icon, title, color };

        case "EIP":
            background = "#fff2e8";
            color = "#f7ac1e";
            icon = "fas fa-file-alt";
            title = "Fatturazione";
            return { background, icon, title, color };

        case "EIT":
            background = "#fff2e8";
            color = "#f7ac1e";
            icon = "fas fa-file-alt";
            title = "Fatturazione";
            return { background, icon, title, color };

        case "ARC":
            background = "#f0f5ff";
            color = "#0083c3";
            icon = "fas fa-archive";
            title = "Archiviazione";
            return { background, icon, title, color };

        case "DBX":
            background = "#fff0f6";
            color = "#e9397a";
            icon = "fas fa-paste";
            title = "DigitalBox";
            return { background, icon, title, color };

        case "PRIVACY":
            background = "#f6ffed";
            color = "#48b74d";
            icon = "fas fa-key";
            title = "Privacy";
            return { background, icon, title, color };

        case "EXP":
            background = "#f9f0ff";
            color = "#673ab7";
            icon = "fas fa-sticky-note";
            title = "NoteSpese";
            return { background, icon, title, color };

        case "PPL":
            background = "#ebf0f6";
            color = "#607D8B";
            icon = "fas fa-paste";
            title = "PeopleApp";
            return { background, icon, title, color };

        case "SIG":
            background = "#fff2e8";
            color = "#c5765a";
            icon = "fas fa-pencil-alt";
            title = "Firma";
            return { background, icon, title, color };

        case "IMPORT":
            background = "#e6f7ff";
            color = "#00bcd4";
            icon = "fas fa-archive";
            title = "Servizio virtuale";
            return { background, icon, title, color };

        case "SPID":
            background = "#06c";
            color = "white";
            icon = "fas fa-user";
            title = "SPID";
            return { background, icon, title, color };

        default:
            background = "#fff2e8";
            color = "#8d4585";
            icon = "fas fa-file-alt";
            title = value;
            return { background, icon, title, color };
    }
}
