import {
    GET_UNASSIGNED_INVOICES_START,
    GET_UNASSIGNED_INVOICES_ERROR,
    GET_UNASSIGNED_INVOICES_SUCCESS,
    POST_UNASSIGNED_INVOICES_START,
    POST_UNASSIGNED_INVOICES_ERROR,
    POST_UNASSIGNED_INVOICES_SUCCESS,
    GET_NOTASSIGNABLED_INVOICES_START,
    GET_NOTASSIGNABLED_INVOICES_ERROR,
    GET_NOTASSIGNABLED_INVOICES_SUCCESS,
    POST_NOTASSIGNABLED_INVOICES_START,
    POST_NOTASSIGNABLED_INVOICES_SUCCESS,
    POST_NOTASSIGNABLED_INVOICES_ERROR
} from "../../actions/company/unassigned-invoices";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            message: ""
        }
    }
};

export function unassignedInvoices(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_UNASSIGNED_INVOICES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_UNASSIGNED_INVOICES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_UNASSIGNED_INVOICES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function notAssignabledInvoices(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_NOTASSIGNABLED_INVOICES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_NOTASSIGNABLED_INVOICES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_NOTASSIGNABLED_INVOICES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function postUnassignedInvoices(state = defaultState, { type, payload, error }) {
    switch (type) {
        case POST_UNASSIGNED_INVOICES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_UNASSIGNED_INVOICES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_UNASSIGNED_INVOICES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function postNotassignedInvoices(state = defaultState, { type, payload, error }) {
    switch (type) {
        case POST_NOTASSIGNABLED_INVOICES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_NOTASSIGNABLED_INVOICES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case POST_NOTASSIGNABLED_INVOICES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}
