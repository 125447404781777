import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { restrictToPermits } from "../../components/general/hoc";
import { errorBoundary } from "../../components/general/error-boundary";
import { getAdmUsers } from "../../actions/user/read";
import { getUser } from "../../actions/user/read";
import { displayErrorToast } from "../../actions/toast";

import AdmUsers from "../../components/control-pannel-users-backoffice";
import { ViewTitle } from "../../styled";
import { FormattedMessage } from "react-intl";

class ControlPannelUsersBackofficeView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        admUsers: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        displayErrorToast: PropTypes.func.isRequired,
        getAdmUsers: PropTypes.func.isRequired,
        getUser: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.updateAdminUserList(null);
    }

    updateAdminUserList(filter) {
        const { auth, getAdmUsers } = this.props;
        getAdmUsers(auth.loginAuth, filter);
    }

    render() {
        const { admin, admUsers, auth, displayErrorToast, getUser, history } = this.props;
        return (
            <div>
                <ViewTitle>
                    <FormattedMessage id="general.admUsers" />
                </ViewTitle>
                <AdmUsers
                    admin={admin}
                    admUsers={admUsers}
                    auth={auth}
                    displayErrorToast={displayErrorToast}
                    getUser={getUser}
                    history={history}
                    updateAdminUserList={filter => this.updateAdminUserList(filter)}
                />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    admUsers: state.admUsers,
    auth: state.auth
});

const actions = { displayErrorToast, getAdmUsers, getUser };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-control-pannel-users-backoffice-view")
);
export default composedHoc(ControlPannelUsersBackofficeView);
