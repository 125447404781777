import React, { Component } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";

class InfoSigner extends Component {
    static propTypes = {
        signer: PropTypes.object.isRequired,
        signerSelect: PropTypes.object
    };

    render() {
        const { signer, signerSelect } = this.props;
        let certType = signerSelect.certType === "FRM" ? "Firma Remota Massiva" : "Firma Sicura Mobile";
        let status = signerSelect.extradata !== undefined ? signerSelect.extradata.statusDescription : "";

        return !isNil(signer.data.formData) && !isNil(signerSelect) ? (
            <div>
                <span style={{ fontWeight: "bold" }}>{"Certificato"}</span>
                <br />
                {"Tipologia: " + certType}
                <br />
                {"Stato: "} {" " + status}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>{"Scheda Firmatario"}</span>
                <br />
                {"Nome/Cognome: " + signer.data.formData.signerFirstName + " " + signer.data.formData.signerLastName}
                <br />
                {"Codice Fiscale: " +
                    signer.data.formData.signerFiscalCountry +
                    " " +
                    signer.data.formData.signerFiscalCode}
                <br />
                {"Sesso: " + signer.data.formData.signerSex}
                <br />
                {"Luogo di nascita: "}
                {signer.data.formData.signerBirthCity} {signer.data.formData.signerBirthDistrict}{" "}
                {signer.data.formData.signerBirthCountry}
                <br />
                {"Data di Nascita: " + new Date(Number(signer.data.formData.signerBirthDate)).toLocaleString()}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>{"Indirizzo: "}</span>
                {signer.data.formData.signerAddress} {signer.data.formData.signerCity}
                {" - "}
                {signer.data.formData.signerCap}
                {" - "}
                {signer.data.formData.signerCountry}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>{"Contatti"}</span>
                <br />
                {"E-mail: " + signer.data.formData.signerEmail}
                <br />
                {"Numero di telefono: " + signer.data.formData.cellNumber}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>{"Informazioni documento"}</span>
                <br />
                {"Tipologia Documento: " + signer.data.formData.signerDocumentType}
                <br />
                {"Rilasciato da: " + signer.data.formData.signerDocumentIssuer}
                <br />
                {"Numero documento: " + signer.data.formData.signerDocumentNumber}
                <br />
                {"Data scadenza: " + new Date(Number(signer.data.formData.signerDocumentEndDate)).toLocaleString()}
                <br />
            </div>
        ) : null;
    }
}

export default InfoSigner;
