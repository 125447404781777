import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { AreaChart, Area, XAxis, Tooltip, YAxis, ResponsiveContainer } from "recharts";
// import { FormattedMessage } from "react-intl";

import { UsageConsumptionChartContent } from "./styled";

export const UsageConsumptionChart = ({ usage, dataType, dataForChart }) => {
    const columns = [
        {
            title: "Inviate",
            dataIndex: "sent"
        },
        {
            title: "Ricevute",
            dataIndex: "received"
        },
        {
            title: "Totale",
            dataIndex: "total"
        }
    ];

    return (
        <div>
            {dataType === "grap" ? (
                <UsageConsumptionChartContent>
                    <ResponsiveContainer>
                        <AreaChart
                            data={dataForChart}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 0,
                                bottom: 0
                            }}
                        >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="rgb(232, 255, 235)" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="rgb(232, 255, 235)" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="rgb(255, 242, 232)" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="rgb(255, 242, 232)" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorFicReceived" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#000" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#000" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorFicSent" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#ff0000" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#ff0000" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <Area
                                type="monotone"
                                dataKey="Ricevute"
                                stroke="#87d068"
                                fillOpacity={1}
                                fill="url(#colorUv)"
                            />
                            <Area
                                type="monotone"
                                dataKey="Emesse"
                                stroke="#f7ac1e"
                                fillOpacity={1}
                                fill="url(#colorPv)"
                            />
                            <Area
                                type="monotone"
                                dataKey="EmesseFic"
                                stroke="#ff0000"
                                fillOpacity={1}
                                fill="url(#colorUv)"
                            />
                            <Area
                                type="monotone"
                                dataKey="RicevuteFic"
                                stroke="#000"
                                fillOpacity={1}
                                fill="url(#colorFicReceived)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </UsageConsumptionChartContent>
            ) : (
                <UsageConsumptionChartContent>
                    <br />
                    <br />
                    <Table
                        rowKey={record => record.key}
                        columns={columns}
                        dataSource={
                            usage != null && usage.invoicingStatistics != null
                                ? usage.invoicingStatistics.statistics
                                : []
                        }
                        locale={{
                            emptyText: "Nessun consumo presente"
                        }}
                        bordered
                        size="small"
                        pagination={false}
                        loading={usage.invoicingStatistics.status.started}
                    />
                </UsageConsumptionChartContent>
            )}
        </div>
    );
};

UsageConsumptionChart.propTypes = {
    usage: PropTypes.object.isRequired,
    dataType: PropTypes.string.isRequired,
    dataForChart: PropTypes.array.isRequired
};
