import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const SpinContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
`;
export const RoutesContainer = styled.div`
    margin: 12px 24px;
`;

export const ActionIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    cursor: pointer;
    color: #0090d1;
    margin: 0px 2px;
`;

export const DisabledActionIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    cursor: auto;
    color: lightgrey;
    margin: 0px 2px;
`;

export const PointerIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;
export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;

export const CopyToClip = styled(CopyToClipboard)`
    float: right;
`;
