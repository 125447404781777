import styled from "styled-components";

export const Container = styled.div`
    min-height: 700px;
`;

export const SpinnerContainer = styled.div`
    margin-top: 30%;
    text-align: center;
`;
