import styled from "styled-components";

export const Body = styled.div`
    margin-left: 8px;
    margin-bottom: 8px;
    min-width: 250px;
    width: 22%;
`;

export const ActionContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 85%;
`;
