import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

import { InvoiceFooterContent, InvoicesReceivedTableSelect, InvoiceQuaryContent } from "./styled";

export const InvoiceFooter = ({
    invoicedDefaultPageSize,
    disabled,
    handleInvoicePageSizeChanged,
    pageSizeOptions,
    invoiceQuery,
    pagination = false,
    nextDisabled = false,
    previousDisabled = false,
    handleNextPage,
    handlePreviousPage
}) => {
    return (
        <InvoiceFooterContent>
            <div style={{ textAlign: "right" }}>
                <span style={{ marginRight: 5 }}>
                    <FormattedMessage id={"article_per_page"} />:
                </span>
                <InvoicesReceivedTableSelect
                    defaultValue={invoicedDefaultPageSize}
                    optionFilterProp="children"
                    disabled={disabled}
                    onChange={handleInvoicePageSizeChanged}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {pageSizeOptions}
                </InvoicesReceivedTableSelect>
                {pagination && (
                    <Button disabled={previousDisabled} onClick={handlePreviousPage} style={{ marginLeft: 10 }}>
                        <i className="fas fa-angle-left" />
                    </Button>
                )}
                {pagination && (
                    <Button disabled={nextDisabled} onClick={handleNextPage} style={{ marginLeft: 10 }}>
                        <i className="fas fa-angle-right" />
                    </Button>
                )}
            </div>
            <InvoiceQuaryContent>{invoiceQuery}</InvoiceQuaryContent>
        </InvoiceFooterContent>
    );
};

InvoiceFooter.propTypes = {
    invoicedDefaultPageSize: PropTypes.any,
    disabled: PropTypes.bool,
    handleInvoicePageSizeChanged: PropTypes.func,
    pageSizeOptions: PropTypes.array,
    invoiceQuery: PropTypes.any,
    pagination: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    previousDisabled: PropTypes.bool,
    handleNextPage: PropTypes.func,
    handlePreviousPage: PropTypes.func
};
