import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, Col, Icon, Input, Row, Switch, Table } from "antd";

import InfoDocument from "./info";
import { getOTStatusColor, getOTPeriodDescription } from "../../../utils/documents/obligations-tax-status";
import {
    obligationTaxStatusFilter,
    obligationTaxTypeFilter,
    obligationPeriodsFilter,
    obligationYearFilter
} from "../../../utils/constant";
import { isNil } from "ramda";
import { FormattedMessage } from "react-intl";
import isNumeric from "antd/es/_util/isNumeric";

const locale = {
    emptyText: "Nessun risultato"
};

const expandedRowRender = record => (
    <p>
        <b>HubId:</b> {record.hubId}
    </p>
);

class CompanyObligationsTax extends Component {
    constructor() {
        super();
        this.state = {
            info: false,
            expandedRowRender: null
        };
    }

    static propTypes = {
        company: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        admin: PropTypes.object.isRequired,
        document: PropTypes.object,
        downloadContent: PropTypes.func,
        history: PropTypes.object.isRequired,
        obligationsTax: PropTypes.object.isRequired,
        obligationsTaxDownload: PropTypes.func.isRequired,
        resetObligationsTax: PropTypes.func.isRequired,
        setDocument: PropTypes.func.isRequired
    };

    handleExpandChange = enable => {
        this.setState({
            expandedRowRender: enable ? expandedRowRender : undefined
        });
    };

    componentDidMount() {
        this.getDocuments(0);
    }

    handleLoadItem(item) {
        const { auth, setDocument, obligationsTaxDownload, document } = this.props;
        setDocument(item);
        if (item.ricevuta) {
            obligationsTaxDownload(
                item.ricevuta,
                auth.loginAuth.id,
                auth.loginAuth.securityToken,
                item.fileName,
                "preview",
                item.hubId
            );
        } else {
            document.content.status = {
                started: false,
                error: true,
                ended: false
            };
        }
        this.setState({ info: true });
    }

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ f: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="c-company-obt-columnSearch">
                <Input
                    className="input"
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    className="seach-button "
                >
                    Cerca
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" className="reset-button ">
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    });

    getDocuments = (page, filters, sorter) => {
        const { auth, company, getObligationsTax } = this.props;
        const { filterSave } = this.state;

        let filter_list = {};
        filter_list.cf = company.info.base.id;
        if (!isNil(filters)) {
            for (var key in filters) {
                var ok_key = key;
                filter_list[ok_key] = filters[key][0];
            }
        }

        getObligationsTax(
            auth.loginAuth,
            filter_list !== undefined ? filter_list : filterSave,
            isNumeric(page) ? page : page.current - 1,
            10
        );
    };

    renderList() {
        const { obligationsTax, document, admin } = this.props;
        const { info, expandedRowRender } = this.state;

        const columns = [
            {
                title: "Tipo",
                dataIndex: "tipo",
                align: "center",
                width: "15%",
                filters: obligationTaxTypeFilter,
                filterMultiple: false,
                render: (text, record) => (
                    <Avatar
                        style={{
                            color: text === "LI" ? "#0069f5" : "rgb(87, 200, 141)",
                            backgroundColor: text === "LI" ? "#cfeffc" : "#d5fccf"
                        }}
                    >
                        {text}
                    </Avatar>
                )
            },
            {
                title: "Periodo",
                dataIndex: "periodo",
                width: "45%",
                align: "center",
                filters: obligationPeriodsFilter,
                filterMultiple: false,
                render: (text, record) => <span>{getOTPeriodDescription(text)}</span>
            },
            {
                title: "Anno",
                dataIndex: "anno",
                width: "5%",
                align: "center",
                filters: obligationYearFilter,
                filterMultiple: false
            },
            {
                title: "Stato",
                dataIndex: "stato",
                align: "center",
                width: "10%",
                filters: obligationTaxStatusFilter,
                filterMultiple: false,
                render: (text, record) => (
                    <span>
                        <i
                            className="fas fa-circle"
                            style={{
                                fontSize: "10px",
                                margin: "5px",
                                color: getOTStatusColor(text)
                            }}
                        />
                        {text}
                    </span>
                )
            },

            {
                title: "Data",
                dataIndex: "timestamp",
                width: "25%",
                align: "center",
                render: (text, record) => <span>{new Date(Number(text)).toLocaleString()}</span>
            },
            {
                title: "Azioni",
                dataIndex: "actions",
                width: "5%",
                align: "center",
                render: (text, record) => (
                    <i className="fas fa-info-circle c-circle" onClick={() => this.handleLoadItem(record)} />
                )
            }
        ];
        const pagination = {
            pageSize: 10,
            current: obligationsTax.page ? obligationsTax.page.number + 1 : 0,
            total: obligationsTax.page ? obligationsTax.page.totalElements.valueOf() : 0,
            hideOnSinglePage: true
        };

        return !obligationsTax.status.error ? (
            <div>
                {info ? (
                    <InfoDocument exit={() => this.setState({ info: false })} document={document} info={info} />
                ) : null}
                {!info ? (
                    <div>
                        <Row type="flex" justify="space-between">
                            <Col span={8}>
                                <p className="a1">
                                    <FormattedMessage id={"c-company-obt-title"} />
                                </p>
                            </Col>
                            <Col span={2} offset={14}>
                                <Switch
                                    defaultChecked={false}
                                    checked={!!expandedRowRender}
                                    checkedChildren="Riduci"
                                    unCheckedChildren="Espandi"
                                    disabled={!admin.adminInfo.isAdminBoUser}
                                    onChange={this.handleExpandChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Table
                                pagination={pagination}
                                rowKey={record => record.hubId}
                                columns={columns}
                                size={"small"}
                                bordered={true}
                                locale={locale}
                                onChange={this.getDocuments}
                                expandedRowRender={expandedRowRender}
                                dataSource={obligationsTax.invioFileList}
                                loading={obligationsTax.status.started && !obligationsTax.status.error}
                            />
                        </Row>
                    </div>
                ) : null}
            </div>
        ) : (
            <div className="c-message-error">{"ERRORE DURANTE LA RICHIESTA"}</div>
        );
    }

    render() {
        return this.renderList();
    }
}

export default CompanyObligationsTax;
