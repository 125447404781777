import { SDI_CONFIG } from "../../config";
import { callWithRetry } from "../../utils/callWithRetry";
import cuid from "cuid";
import uuid from "uuid/v4";

import SdiConfig from "../../assets/thrift/sdi/SDIFlowConfig";
import SdiTypes from "../../assets/thrift/sdi/v2_types";

import thrift from "browser-thrift";

const sdiUrl = new URL(SDI_CONFIG);
const sdiClient = thrift.createXHRClient(
    SdiConfig,
    thrift.createXHRConnection(sdiUrl.hostname, 443, {
        useCors: true,
        path: sdiUrl.pathname,
        https: sdiUrl.protocol === "https:"
    })
);

export const GET_SDI_CONFIG_START = "GET_SDI_CONFIG_START";
export const GET_SDI_CONFIG_ERROR = "GET_SDI_CONFIG_ERROR";
export const GET_SDI_CONFIG_SUCCESS = "GET_SDI_CONFIG_SUCCESS";

export function getSdiConfig(auth, fiscalCode, page = 0, items = 10) {
    return async dispatch => {
        const authRegistry = new SdiTypes.Header({
            ...auth,
            accessToken: auth.securityToken,
            correlationId: uuid(),
            requestId: cuid()
        });

        let pagination = new SdiTypes.Pagination({
            page,
            items
        });
        let request = new SdiTypes.ListConfigsRequest({
            itemIds: [fiscalCode],
            pagination
        });
        dispatch({
            type: GET_SDI_CONFIG_START,
            request: request
        });
        try {
            let response = await callWithRetry(
                sdiClient,
                sdiClient.listConfigs,
                authRegistry,
                request,
                auth.refreshToken,
                dispatch
            );

            let res = {
                configs: response.configs,
                totalElements: parseInt(response.totalElements) ? parseInt(response.totalElements) : 0,
                totalPages: parseInt(response.totalPages) ? parseInt(response.totalPages) : 0
            };

            dispatch({
                type: GET_SDI_CONFIG_SUCCESS,
                payload: res
            });
            return res;
        } catch (e) {
            dispatch({
                type: GET_SDI_CONFIG_ERROR,
                error: e
            });
        }
    };
}
export const UPDATE_SDI_CONFIG_START = "UPDATE_SDI_CONFIG_START";
export const UPDATE_SDI_CONFIG_ERROR = "UPDATE_SDI_CONFIG_ERROR";
export const UPDATE_SDI_CONFIG_SUCCESS = "UPDATE_SDI_CONFIG_SUCCESS";

export function updateSdiConfig(auth, config) {
    return dispatch => {
        const authRegistry = new SdiTypes.Header({
            ...auth,
            accessToken: auth.securityToken,
            correlationId: uuid(),
            requestId: cuid()
        });

        let configs = new SdiTypes.Config({
            itemId: config.itemId,
            ownerId: config.ownerId,
            extraData: config.extraData
        });
        let request = new SdiTypes.UpdateConfigsRequest({
            configs: [configs]
        });

        const toast = {
            onOk: true,
            onOkText: "Firma SDI aggiornata correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: UPDATE_SDI_CONFIG_START,
            request: request
        });

        callWithRetry(
            sdiClient,
            sdiClient.updateConfigs,
            authRegistry,
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: UPDATE_SDI_CONFIG_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: UPDATE_SDI_CONFIG_SUCCESS,
                        payload: response
                    });
                }
            },
            toast
        );
    };
}

export const GET_SDI_CONFIG_RESET = "GET_SDI_CONFIG_RESET";

export function resetSdiConfig() {
    return {
        type: GET_SDI_CONFIG_RESET
    };
}
