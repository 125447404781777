import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Searchbar } from "@mondora/arc/antd/Searchbar";
import { displayErrorToast } from "../../../actions/toast";

const BankLinkHubIdSearch = ({ auth, history, displayErrorToast }) => {
    const onSearch = async hubIdSearched => {
        if (hubIdSearched.length === 24 || hubIdSearched.length === 36) {
            history.push(`/dashboard/BANK-LINK/${hubIdSearched.trim()}/`);
        } else {
            displayErrorToast("HubId inserito non valido.", true);
        }
    };

    return <Searchbar placeholder="Inserisci HubId" onSearch={v => onSearch(v.value.trim())} enterButton />;
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = { displayErrorToast };

BankLinkHubIdSearch.propTypes = {
    auth: PropTypes.object.isRequired,
    displayErrorToast: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    actions
)(BankLinkHubIdSearch);
