import {
    AUTO_UPLOAD_DOCUMENT_SUCCESS,
    AUTO_UPLOAD_DOCUMENT_START,
    AUTO_UPLOAD_DOCUMENT_ERROR,
    PUT_CCT_PROV_REQUEST_START,
    PUT_CCT_PROV_REQUEST_SUCCESS,
    PUT_CCT_PROV_REQUEST_ERROR,
    SET_CEDENTE_PRESTATORE_START,
    SET_CEDENTE_PRESTATORE_SUCCESS,
    SET_CEDENTE_PRESTATORE_ERROR,
    POST_CCT_PROV_REQUEST_START,
    POST_CCT_PROV_REQUEST_SUCCESS,
    POST_CCT_PROV_REQUEST_ERROR,
    POST_SUSPEND_CCT_PROV_REQUEST_START,
    POST_SUSPEND_CCT_PROV_REQUEST_SUCCESS,
    POST_SUSPEND_CCT_PROV_REQUEST_ERROR,
    POST_RESET_CCT_PASSWORD_START,
    POST_RESET_CCT_PASSWORD_SUCCESS,
    POST_RESET_CCT_PASSWORD_ERROR,
    DELTE_CCT_RDC_TO_BE_FIXED_START,
    DELTE_CCT_RDC_TO_BE_FIXED_SUCCESS,
    DELTE_CCT_RDC_TO_BE_FIXED_ERROR
} from "../../../actions/cct/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const autoUploadDocument = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case AUTO_UPLOAD_DOCUMENT_SUCCESS:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case AUTO_UPLOAD_DOCUMENT_START:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case AUTO_UPLOAD_DOCUMENT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};

export const putCctProvRequest = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case PUT_CCT_PROV_REQUEST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case PUT_CCT_PROV_REQUEST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case PUT_CCT_PROV_REQUEST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
};

export function setCedentePrestatore(state = defaultState, { type, payload, error }) {
    switch (type) {
        case SET_CEDENTE_PRESTATORE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case SET_CEDENTE_PRESTATORE_SUCCESS:
            return {
                ...payload,
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case SET_CEDENTE_PRESTATORE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export const postCctProvRequest = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_CCT_PROV_REQUEST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_CCT_PROV_REQUEST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };

        case POST_CCT_PROV_REQUEST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};

export const postSuspendCctProvisioningRequest = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_SUSPEND_CCT_PROV_REQUEST_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_SUSPEND_CCT_PROV_REQUEST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };

        case POST_SUSPEND_CCT_PROV_REQUEST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};

export const postCctPasswordReset = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case POST_RESET_CCT_PASSWORD_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case POST_RESET_CCT_PASSWORD_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };

        case POST_RESET_CCT_PASSWORD_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};

export const deleteCctRdcToBeFixed = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case DELTE_CCT_RDC_TO_BE_FIXED_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DELTE_CCT_RDC_TO_BE_FIXED_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };

        case DELTE_CCT_RDC_TO_BE_FIXED_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};
