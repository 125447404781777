import { API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_PRIVACY_USAGE_START = "GET_PRIVACY_USAGE_START";
export const GET_PRIVACY_USAGE_SUCCESS = "GET_PRIVACY_USAGE_SUCCESS";
export const GET_PRIVACY_USAGE_ERROR = "GET_PRIVACY_USAGE_ERROR";

export const getPrivacyUsage = (auth, itemId) => {
    return async dispatch => {
        const urlParams = {
            itemId: itemId
        };
        dispatch({
            type: GET_PRIVACY_USAGE_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(`${API_URL}/privacy`, auth, urlParams, dispatch, auth.refreshToken);
            dispatch({
                type: GET_PRIVACY_USAGE_SUCCESS,
                payload: result[0]
            });
            return result[0];
        } catch (e) {
            dispatch({
                type: GET_PRIVACY_USAGE_ERROR,
                error: e
            });
        }
    };
};
