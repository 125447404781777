import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../components/general/error-boundary";

import { getAppNames, deleteApp, addApp, editApp, getAppNameExport } from "../../actions/app-name";
import { restrictToPermits } from "../../components/general/hoc";

import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";

import { AppNameTable } from "../../components/app-name/app-name-table";
import { AppNameForm } from "../../components/app-name/app-name-form";

import { SectionContainer, ViewTitle, ViewSection } from "./styled";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

const AppNameView = ({
    auth,
    getAppNames,
    deleteApp,
    addApp,
    editApp,
    appNames,
    appNamesDelete,
    appNamesAdd,
    appNamesEdit,
    getAppNameExport,
    appNamesExport
}) => {
    // variabili per gestione cancellazione
    const [isConfirmModalDeleteVisible, setIsConfirmModalDeleteVisible] = useState(false);
    const [deletingAppName, setDeletingAppName] = useState({});

    // variabili per gestione aggiunta
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);

    // variabili per gestione modifica
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editingApp, setEditingApp] = useState({});

    // variabili per paginazione
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState({
        parameters: {}
    });

    useEffect(() => {
        getAppNames(
            auth.loginAuth,
            { page: page, size: pageSize },
            filter.parameters.app_name,
            filter.parameters.contact_mail,
            filter.parameters.type,
            filter.parameters.software_house
        );
    }, [
        auth.loginAuth,
        getAppNames,
        page,
        pageSize,
        filter.parameters.app_name,
        filter.parameters.contact_mail,
        filter.parameters.type,
        filter.parameters.software_house
    ]);

    const handleDownloadCSV = () => {
        getAppNameExport(auth.loginAuth);
    };

    // handlers per modale di cancellazione
    const handleModalDeleteCancel = () => {
        setIsConfirmModalDeleteVisible(false);
        setDeletingAppName({});
    };
    const handleModalDeleteConfirm = () => {
        deleteApp(auth.loginAuth, deletingAppName.id);
    };
    useEffect(() => {
        if (!appNamesDelete.status.started && appNamesDelete.status.ended) {
            setIsConfirmModalDeleteVisible(false);
            setDeletingAppName({});
            getAppNames(auth.loginAuth, { page: page, size: pageSize });
        }
    }, [appNamesDelete.status.started, appNamesDelete.status.ended, auth.loginAuth, getAppNames, page, pageSize]);

    // handlers per modale di aggiunta
    const handleModalAddCancel = () => {
        setIsAddModalVisible(false);
    };
    useEffect(() => {
        if (!appNamesAdd.status.started && appNamesAdd.status.ended) {
            setIsAddModalVisible(false);
        }
    }, [appNamesAdd.status.started, appNamesAdd.status.ended]);

    // handlers per modale di modifica
    const handleModalEditCancel = () => {
        setIsEditModalVisible(false);
        setEditingApp({});
    };
    useEffect(() => {
        if (!appNamesEdit.status.started && appNamesEdit.status.ended) {
            setIsEditModalVisible(false);
            setEditingApp({});
            getAppNames(auth.loginAuth, { page: page, size: pageSize });
        }
    }, [appNamesEdit.status.started, appNamesEdit.status.ended, auth.loginAuth, getAppNames, page, pageSize]);

    return (
        <>
            <div>
                <ViewTitle>
                    <FormattedMessage id="c-app-name.title" />
                </ViewTitle>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-app-name.configuredAppNames" />
                        <Button key="1" type="primary" onClick={() => setIsAddModalVisible(true)}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 3 }} />
                            <FormattedMessage id="c-app-name.add-app-name" />
                        </Button>
                    </ViewSection>
                    <AppNameTable
                        appNames={appNames.items}
                        onDelete={appRecord => {
                            setDeletingAppName(appRecord);
                            setIsConfirmModalDeleteVisible(true);
                        }}
                        onEdit={appRecord => {
                            setEditingApp({
                                id: appRecord.id,
                                appName: appRecord.app_name,
                                application: appRecord.application,
                                contactMail: appRecord.contact_mail,
                                contactName: appRecord.contact_name,
                                contactPhone: appRecord.contact_phone,
                                software: appRecord.software,
                                softwareHouse: appRecord.software_house,
                                type: appRecord.type
                            });

                            setIsEditModalVisible(true);
                        }}
                        handleFilter={(value, name) => {
                            let newFilter = filter.parameters;
                            newFilter[name] = value;
                            setPage(1);
                            setFilter({ parameters: newFilter });
                        }}
                        downloadCSVFile={handleDownloadCSV}
                        appNamesExport={appNamesExport}
                        currentPage={page}
                        currentPageSize={pageSize}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        totalItems={parseInt(appNames.total)}
                        loading={appNames.status && appNames.status.started}
                    />
                </SectionContainer>
                <br />
            </div>

            <Modal
                title={<FormattedMessage id="c-app-name.delete-modal-title" />}
                visible={isConfirmModalDeleteVisible}
                onCancel={handleModalDeleteCancel}
                footer={[
                    <Button key="0" type="secondary" onClick={handleModalDeleteCancel}>
                        <FormattedMessage id="general.cancel" />
                    </Button>,
                    <Button
                        key="1"
                        type="primary"
                        onClick={handleModalDeleteConfirm}
                        loading={appNamesDelete.status.started}
                    >
                        <FormattedMessage id="general.confirm" />
                    </Button>
                ]}
            >
                <FormattedMessage
                    id="c-app-name.delete-modal-text"
                    values={{
                        appName: deletingAppName.app_name
                    }}
                />
            </Modal>

            <Modal
                title={<FormattedMessage id="c-app-name.add-modal-title" />}
                visible={isAddModalVisible}
                onCancel={handleModalAddCancel}
                footer={null}
            >
                <AppNameForm
                    initParams={{}}
                    onConfirm={params => addApp(auth.loginAuth, params)}
                    onCancel={() => handleModalAddCancel()}
                    loading={appNamesAdd.status.started}
                />
            </Modal>

            <Modal
                title={<FormattedMessage id="c-app-name.edit-modal-title" />}
                visible={isEditModalVisible}
                onCancel={handleModalEditCancel}
                footer={null}
            >
                <AppNameForm
                    initParams={editingApp}
                    onConfirm={params => editApp(auth.loginAuth, params, editingApp.id)}
                    onCancel={() => handleModalEditCancel()}
                    loading={appNamesEdit.status.started}
                />
            </Modal>
        </>
    );
};

AppNameView.propTypes = {
    auth: PropTypes.object,
    getAppNameExport: PropTypes.func
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    appNames: state.appNames.getAppNames,
    appNamesDelete: state.appNames.deleteAppNames,
    appNamesAdd: state.appNames.addAppNames,
    appNamesEdit: state.appNames.editAppNames,
    appNamesExport: state.appNames.getAppNameExport
});

const actions = {
    getAppNames,
    deleteApp,
    addApp,
    editApp,
    getAppNameExport
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-app-name-view")
);

export default composedHoc(AppNameView);
