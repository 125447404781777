export const DOWNLOAD_CONTENT_START = "DOWNLOAD_CONTENT_START";
export const DOWNLOAD_CONTENT_SUCCESS = "DOWNLOAD_CONTENT_SUCCESS";
export const DOWNLOAD_CONTENT_ERROR = "DOWNLOAD_CONTENT_ERROR";

export const SET_DOCUMENT = "SET_DOCUMENT";

export function setDocument(document) {
    return dispatch =>
        dispatch({
            type: SET_DOCUMENT,
            payload: document
        });
}
