import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tag, Drawer, Button } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";

import { translateNCSAppCode } from "../../../../utils/translate";
import { UserPreferencesTable } from "../user-preferences-table";

export const NotificationPreferences = ({
    companyNcsId,
    ncsUsersPreferenceList,
    loading,
    handlePageChange,
    totalItems,
    currentPage,
    pageSize,
    handlePageSizeChange
}) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [userSelectDrawer, setUserSelectDrawer] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (selectedUser) {
            const preferenze = selectedUser.preferences.filter(pref => pref.groupId === companyNcsId);

            setData(preferenze);
        }
    }, [companyNcsId, ncsUsersPreferenceList, selectedUser, setData]);

    let columns = [
        {
            title: <FormattedMessage id={"app"} />,
            dataIndex: "appCode",
            render: text => <span>{translateNCSAppCode(text)}</span>
        },
        {
            title: <FormattedMessage id={"notification_type"} />,
            dataIndex: "templateType"
        },
        {
            title: <FormattedMessage id={"channels"} />,
            dataIndex: "channels",
            render: (text, record) => <span>{renderChannelsTag(text)}</span>
        }
    ];

    const renderChannelsTag = channels => {
        let items = [];
        if (channels && channels.length) {
            channels.forEach((item, index) =>
                items.push(
                    <Tag color="green" key={"channels" + index} style={{ marginBottom: 8 }}>
                        {item}
                    </Tag>
                )
            );
        }

        if (!items.length) {
            items.push(
                <Tag color="red" key={"1"} style={{ marginBottom: 8 }}>
                    <FormattedMessage id={"c-company-users.no-channels"} />
                </Tag>
            );
        }
        return items;
    };

    const extractEmails = (channels, template, companyNcsId) => {
        let currentCompanyConfiguredChannel = channels.filter(
            channel => channel.groupId === companyNcsId && channel.templateType === template
        );
        if (!currentCompanyConfiguredChannel || currentCompanyConfiguredChannel.length <= 0) {
            currentCompanyConfiguredChannel = channels.filter(
                channel => channel.groupId === undefined && channel.templateType === template
            );
        }

        let configuredChannels = currentCompanyConfiguredChannel && currentCompanyConfiguredChannel[0];

        if (!configuredChannels) {
            configuredChannels = channels.filter(channel => channel.templateType === undefined);

            configuredChannels = configuredChannels && configuredChannels[0];
        }

        return (
            configuredChannels &&
            configuredChannels.recipients &&
            configuredChannels.recipients.map(recipient => recipient.value).join(", ")
        );
    };

    return (
        <div style={{ marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button type="primary" loading={loading} onClick={() => setUserSelectDrawer(true)}>
                    <FontAwesomeIcon style={{ marginRight: 5 }} icon={faUsers} />
                    <FormattedMessage id={"c-company-users.user-select"} />
                </Button>
                {selectedUser && (
                    <div style={{ marginLeft: 10, paddingTop: 5 }}>
                        <FormattedMessage id={"c-company-users.selected-user"} /> <b>{selectedUser.email}</b>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 20, marginBottom: 10, overflow: "auto", height: "52vh" }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    loading={loading}
                    dataSource={data || []}
                    expandedRowRender={record =>
                        record.channels.includes("EMAIL") ? (
                            <div>
                                <b>
                                    <FormattedMessage id={"c-company-users.receipt-list-details"} />:{" "}
                                </b>
                                {extractEmails(selectedUser.channels, record.templateType, companyNcsId)}
                            </div>
                        ) : (
                            <div>
                                <b>
                                    <FormattedMessage id={"c-company-users.no-email-channels-details"} />
                                </b>
                            </div>
                        )
                    }
                />
            </div>
            <Drawer
                width={"70%"}
                title={<FormattedMessage id="c-company-users-drawer.title" />}
                onClose={() => setUserSelectDrawer(false)}
                visible={userSelectDrawer}
            >
                <UserPreferencesTable
                    userPreferences={ncsUsersPreferenceList}
                    onSelectUser={selectedUser => {
                        setSelectedUser(
                            ncsUsersPreferenceList
                                .filter(userPref => userPref !== null)
                                .filter(userPref => userPref.email === selectedUser)[0]
                        );
                        setUserSelectDrawer(false);
                    }}
                    onChange={page => handlePageChange(page.current - 1)}
                    pagination={{
                        total: totalItems,
                        current: currentPage + 1,
                        pageSize: pageSize,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "15"],
                        defaultPageSize: 10,
                        onShowSizeChange: (current, size) => {
                            handlePageSizeChange(size);
                        }
                    }}
                    loading={loading}
                />
            </Drawer>
        </div>
    );
};

NotificationPreferences.propTypes = {
    companyNcsId: PropTypes.string.isRequired,
    ncsUsersPreferenceList: PropTypes.array,
    handleViewChannel: PropTypes.func,
    loading: PropTypes.bool.isRequired
};
