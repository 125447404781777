import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "antd";
import { compose } from "redux";

import TextField from "@mondora/arc/antd/TextField";
import { form } from "@mondora/conv-redux-form";
import { injectIntl } from "react-intl";

import { vatNumberSchema } from "../../../utils/form-schema/vatNumber";

export class VatNumberForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired,
        formIsLocked: PropTypes.bool
    };

    render() {
        const { handleSubmit, formIsLocked } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Row gutter={8}>
                    <Col span={15}>
                        <TextField name="newVatNumber" placeholder="Nuova partita iva" disabled={formIsLocked} />
                    </Col>
                    <Col span={5}>
                        <Button disabled={formIsLocked} type="primary" htmlType="submit" className="adj-button">
                            Salva
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
const formDefinition = {
    asyncFields: ["newVatNumber"],
    form: "vat-number-form",
    schema: vatNumberSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(VatNumberForm);
