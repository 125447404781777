import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tabs, Spin, Icon } from "antd";
import { getXsl } from "../../../../utils/xsl-finder";
import { SpinnerContainer, ContainerPDFViewer } from "./styled";

export const EReceiptPreview = ({ documentInfo, hubId, loading, handleDownloadDocument }) => {
    const [activeTab, setActiveTab] = useState("pdf");
    const [pdfTemplate, setPdfTemplate] = useState(null);

    useEffect(() => {
        if (documentInfo && documentInfo !== "") {
            try {
                let documentType = getXsl("ERECEIPTS");
                let pdfTemplate = (
                    <div id="e_receipt_preview">
                        {new global.Transformation()
                            .setXml(documentInfo)
                            .setXslt(documentType)
                            .transform("e_receipt_preview")}
                    </div>
                );
                setPdfTemplate(pdfTemplate);
            } catch (error) {
                setPdfTemplate(null);
            }
        } else {
            setPdfTemplate(null);
        }
    }, [documentInfo]);

    return (
        <div>
            <Tabs
                onChange={value => setActiveTab(value)}
                defaultActiveKey={activeTab}
                tabBarExtraContent={
                    activeTab === "xml" && (
                        <i
                            className="fas fa-download"
                            onClick={() => handleDownloadDocument(hubId)}
                            style={{
                                marginRight: "30px",
                                cursor: "pointer"
                            }}
                        />
                    )
                }
            >
                <Tabs.TabPane tab={<FormattedMessage id={"revenue_agency"} />} key="pdf">
                    {loading ? (
                        <SpinnerContainer>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                        </SpinnerContainer>
                    ) : pdfTemplate ? (
                        <div>{pdfTemplate}</div>
                    ) : (
                        <SpinnerContainer>
                            <FormattedMessage id={"not_available"} />
                        </SpinnerContainer>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id={"xml"} />} key="xml">
                    {loading ? (
                        <SpinnerContainer>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                        </SpinnerContainer>
                    ) : documentInfo && documentInfo !== "" ? (
                        <ContainerPDFViewer>
                            <div
                                style={{
                                    whiteSpace: "pre",
                                    padding: "15px"
                                }}
                            >
                                {documentInfo}
                            </div>
                        </ContainerPDFViewer>
                    ) : (
                        <SpinnerContainer>
                            <FormattedMessage id={"not_available"} />
                        </SpinnerContainer>
                    )}
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
EReceiptPreview.propTypes = {
    documentInfo: PropTypes.string,
    handleDownloadDocument: PropTypes.func.isRequired
};
