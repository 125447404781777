import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tabs } from "antd";

import { NotificationChannelsEmail } from "../notification-channels-email";
import { NotificationChannelsWebHooks } from "../notification-channels-webhook";

export const NotificationChannels = ({ channelInfo }) => {
    return (
        <div style={{ margin: 10 }}>
            <Tabs>
                <Tabs.TabPane tab={<FormattedMessage id="email" />} key="email">
                    <NotificationChannelsEmail
                        defaultChannels={channelInfo.defaultChannels}
                        templateChannels={channelInfo.templateChannels}
                    />
                </Tabs.TabPane>
                {channelInfo.channels && channelInfo.channels.length && channelInfo.channels.includes("WEBHOOK") && (
                    <Tabs.TabPane tab={<FormattedMessage id="webhook" />} key="webhook">
                        <NotificationChannelsWebHooks
                            defaultChannels={channelInfo.defaultChannels}
                            templateChannels={channelInfo.templateChannels}
                        />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </div>
    );
};

NotificationChannels.propTypes = {
    channelInfo: PropTypes.object
};
