import styled from "styled-components";

export const SpidViewInfoBlock = styled.div`
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
`;
export const SpidTitleInfo = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: #0090d1;
`;
export const SpidUserInfo = styled.div`
    padding-left: 15px;
    font-size: 16px;
    font-weight: bold;
`;
