import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../../components/header";
import DeviceNotSupported from "../../components/general/device-not-supported";
import { Menu } from "../../components/menu";
import Routes from "../../routes";
import { Layout, Spin, Icon } from "antd";
import { RootContent } from "./styled";
import { SpinContainer } from "../../styled";

import { getAuthInfo, logout } from "../../actions/auth";
import { setElevio } from "../../utils/elevio";
import { userIsAuthorized } from "../../utils/get-roles";
import { isMobileOnly, isTablet } from "react-device-detect";

const RootView = ({ admin, auth, history, match, location, logout, session, getAuthInfo }) => {
    const [elevioState, setElevioState] = useState(false);
    const [isConsoleAdmin, setIsConsoleAdmin] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [drawer, setDrawer] = useState(false);
    const [loading, setLoading] = useState(true);
    const mediumScreen = useMedia("(max-width: 1150px)");

    useEffect(() => {
        if (
            match.url.includes("control-pannel-") &&
            admin &&
            admin.adminInfo &&
            admin.adminInfo.permits &&
            userIsAuthorized("c-user-badge-console", admin.adminInfo.permits)
        ) {
            setIsConsoleAdmin(true);
        } else {
            setIsConsoleAdmin(false);
        }

        if (!auth.loginAuth.id || auth.loginAuth.status.error || admin.adminInfo.status.error) {
            history.push("/login");
        }
        if (admin.adminInfo.profile) {
            setElevio(admin, elevioState, () => setElevioState(true));
        }
        if (
            auth.loginAuth.securityToken &&
            auth.loginAuth.status.ended &&
            !admin.adminInfo.status.started &&
            !admin.adminInfo.status.error &&
            !admin.adminInfo.profile
        ) {
            const asyncGetAuth = async () => {
                const result = await getAuthInfo(auth.loginAuth, encodeURIComponent(auth.loginAuth.id));
                if (result) {
                    setLoading(false);
                }
            };
            asyncGetAuth();
        } else {
            if (admin.adminInfo.profile && admin.adminInfo.status.ended) {
                setLoading(false);
            }
        }
    }, [auth, admin, elevioState, getAuthInfo, history, match]);

    const logoutAuth = () => {
        logout(auth.loginAuth);
        history.push("/login");
    };

    if (loading) {
        return (
            <SpinContainer>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </SpinContainer>
        );
    }
    if (isMobileOnly) {
        return <DeviceNotSupported />;
    }

    return (
        <Layout className="Root">
            <Menu
                menuItem={match.params.menuItem}
                isTablet={mediumScreen.matches || isTablet}
                admin={admin}
                isOpen={isCollapsed}
                isConsoleAdmin={isConsoleAdmin}
                location={location}
                drawerState={drawer}
                setDrawerState={val => setDrawer(val)}
            />

            <Layout>
                <Layout.Header
                    style={{
                        background: !isConsoleAdmin ? "white" : "#1e2427",
                        color: !isConsoleAdmin ? "" : "white"
                    }}
                >
                    <Header
                        isConsoleAdmin={isConsoleAdmin}
                        auth={auth}
                        admin={admin}
                        logout={logoutAuth}
                        location={location}
                        menuItem={match.params.menuItem}
                        isOpen={isCollapsed}
                        isTabletPort={mediumScreen.matches || isTablet}
                        openDrawer={() => setDrawer(!drawer)}
                        openMenu={() => setIsCollapsed(!isCollapsed)}
                        openAdminConsole={() => {
                            history.push({
                                pathname: isConsoleAdmin ? "/dashboard" : "/control-pannel-dashboard"
                            });
                            setIsConsoleAdmin(!isConsoleAdmin);
                        }}
                        session={session}
                    />
                </Layout.Header>
                <Layout.Content>
                    <RootContent>
                        <Routes />
                    </RootContent>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    session: state.session
});

const actions = { getAuthInfo, logout };

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    )
);
RootView.propTypes = {
    auth: PropTypes.object.isRequired
};
export default composedHoc(RootView);

const useMedia = query => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        let media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        let listener = () => setMatches(media.matches);
        media.addEventListener("change", listener);
        return () => media.removeEventListener("change", listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return { matches };
};
