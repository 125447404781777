import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Tooltip } from "antd";
import { compose } from "redux";

import TextField from "@mondora/arc/antd/TextField";
import { form } from "@mondora/conv-redux-form";
import { FormattedMessage, injectIntl } from "react-intl";

import { passwordSchema } from "../../../../utils/form-schema/password";

import "./style.css";

export class ResetPasswordForm extends Component {
    static propTypes = {
        errorMsg: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired,
        title: PropTypes.element
    };

    static defaultProps = {
        title: <FormattedMessage id={"c-reset-password.title"} />
    };

    render() {
        const { errorMsg, handleSubmit, intl, title } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <span className="update-password-title ">{title}</span>
                <br />
                <br />

                <Tooltip
                    title={
                        <div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip1"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip2"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip3"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip4"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip5"} />
                            </div>
                        </div>
                    }
                    placement="top"
                    overlayStyle={{ maxWidth: "600px" }}
                    trigger={["focus"]}
                >
                    <TextField
                        addonBefore={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                        name="password"
                        placeholder={intl.formatMessage({
                            id: "c-reset-password.password.placeholder"
                        })}
                        type="password"
                    />
                </Tooltip>

                <TextField
                    addonBefore={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                    name="confirmPassword"
                    placeholder={intl.formatMessage({
                        id: "c-reset-password.confirmPassword.placeholder"
                    })}
                    type="password"
                />

                <Button type="primary" htmlType="submit" className="root-button">
                    <FormattedMessage id="c-reset-password.confirm.button" />
                </Button>
                {errorMsg && errorMsg !== " " ? (
                    <div className="has-error-msg">
                        <FormattedMessage id="c-change-password.confirm.error" values={{ errMsg: errorMsg }} />
                    </div>
                ) : (
                    ""
                )}
            </form>
        );
    }
}

const formDefinition = {
    form: "reset-password-form",
    schema: passwordSchema
};

const composedHoc = compose(
    injectIntl,
    form(formDefinition)
);

export default composedHoc(ResetPasswordForm);
