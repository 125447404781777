import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";

export const NotificationChannelsWebHooks = ({ defaultChannels, templateChannels }) => {
    const [data, setData] = useState([]);
    const [defaultWebHook, setDefaultWebHook] = useState(null);

    useEffect(() => {
        let data = [];
        if (templateChannels && templateChannels.length) {
            templateChannels
                .filter(webhook => webhook.type === "WEBHOOK")
                .forEach(element => {
                    element.recipients.forEach(item => {
                        data.push({
                            value: item.value || item,
                            name: item.name,
                            authentication: item.authentication || { type: null, user: null }
                        });
                    });
                });
        }
        setData(data);
    }, [templateChannels]);

    useEffect(() => {
        let data = [];
        if (defaultChannels && defaultChannels.length) {
            data = defaultChannels
                .filter(webhook => webhook.type === "WEBHOOK")
                .map(item => {
                    return item.recipients.map(recipient => {
                        return {
                            value: recipient.value || recipient,
                            name: recipient.name,
                            authentication: item.authentication || { type: null, user: null }
                        };
                    });
                });
        }
        if (data && data.length) {
            setDefaultWebHook(...data[0]);
        } else {
            setDefaultWebHook(null);
        }
    }, [defaultChannels]);

    let columns = [
        {
            title: <FormattedMessage id={"recipient"} />,
            dataIndex: "value",
            render: (text, record) => <span>{text || record}</span>
        },
        {
            title: <FormattedMessage id={"name"} />,
            dataIndex: "name"
        },
        {
            title: <FormattedMessage id={"type"} />,
            dataIndex: "authentication.type"
        },
        {
            title: <FormattedMessage id={"general.user"} />,
            dataIndex: "authentication.user"
        }
    ];

    return (
        <div style={{ marginTop: 20, background: "white" }}>
            {(!data || !data.length) && defaultWebHook ? (
                <div>
                    <span style={{ marginLeft: 10 }}>
                        <FormattedMessage id={"no_specific_channels_configured"} />,{" "}
                        <FormattedMessage id={"notifications_are_sent_to"} />:{" "}
                    </span>
                    <span style={{ fontWeight: "bold" }}>{defaultWebHook.value}</span>
                </div>
            ) : (
                <FormattedMessage id={"no_default_channels_configured"} />
            )}
            <Table
                title={() => (
                    <div style={{ marginTop: 20, fontWeight: "bold" }}>
                        <FormattedMessage id={"webhook_configuration_list"} />:
                    </div>
                )}
                rowKey={(record, index) => index}
                size="middle"
                columns={columns}
                locale={{
                    emptyText: <FormattedMessage id={"no_result"} />
                }}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

NotificationChannelsWebHooks.propTypes = {
    defaultChannels: PropTypes.array,
    templateChannels: PropTypes.array
};
