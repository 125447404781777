import React from "react";
import { Modal } from "antd";

import { FormattedMessage } from "react-intl";

export const RefreshPackageModal = ({ refreshPackageModal, onCancel, handleRefreshPackage, loading }) => {
    const refreshPackage = () => {
        handleRefreshPackage();
    };
    return (
        <Modal
            visible={refreshPackageModal}
            title={<FormattedMessage id="c-metering-action.refresh-package" />}
            okText={<FormattedMessage id="general.confirm" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onCancel={onCancel}
            onOk={() => {
                refreshPackage();
            }}
            okButtonProps={{
                loading: loading
            }}
        >
            <div>{<FormattedMessage id="c-meteringtable.refresh-package" />}</div>
        </Modal>
    );
};
