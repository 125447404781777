import React, { Component } from "react";
import PropTypes from "prop-types";

import { Icon, Badge, Avatar, Popover, List } from "antd";

import UserBadge from "./user-badge/index.jsx";
import { LOGO_COLOR } from "../../config";

import "./style.css";
const locale = {
    emptyText: "Nessun notifica presente"
};
class Header extends Component {
    constructor() {
        super();
        this.state = {
            userEnableForAdminConsole: false,
            errorList: false
        };
    }
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        menuItem: PropTypes.string,
        openAdminConsole: PropTypes.func,
        openMenu: PropTypes.func.isRequired,
        openDrawer: PropTypes.func,
        isTabletPort: PropTypes.bool,
        isConsoleAdmin: PropTypes.bool,
        session: PropTypes.object.isRequired
    };

    render() {
        const {
            admin,
            auth,
            session,
            logout,
            isOpen,
            isTabletPort,
            openDrawer,
            openAdminConsole,
            openMenu,
            isConsoleAdmin
        } = this.props;

        const data = [];
        session.newVersionAvailable &&
            data.push({
                title: "Nuova versione disponibile ",
                type: "info",
                background: "#00bcd4",
                icon: "fas fa-book",
                description: "Aggiorna"
            });

        const listAction = (
            <div>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    locale={locale}
                    className="c-header-notification-list"
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        style={{
                                            background: item.background
                                        }}
                                    >
                                        <i className={item.icon} />
                                    </Avatar>
                                }
                                title={<span className="c-header-notification-title">{item.title}</span>}
                                description={
                                    item.type === "info" && (
                                        <span
                                            className="c-header-notification-description"
                                            onClick={() => window.location.reload(true)}
                                        >
                                            {item.description}
                                        </span>
                                    )
                                }
                            />
                        </List.Item>
                    )}
                />
                {data.length > 0 && (
                    <div>
                        <div
                            className="c-header-show-notification"
                            onClick={() =>
                                this.setState({
                                    errorList: true
                                })
                            }
                        >
                            {"Visualizza dettaglio notifiche"}
                        </div>
                    </div>
                )}
            </div>
        );
        return (
            <div className="c-header">
                <div>
                    <div style={{ display: "flex" }}>
                        <div className="logo">
                            {isTabletPort ? (
                                <img alt="" src={LOGO_COLOR} className="logo-color" />
                            ) : (
                                <Icon type={!isOpen ? "menu-fold" : "menu-unfold"} onClick={openMenu} />
                            )}
                        </div>
                        {isTabletPort ? (
                            <Icon
                                type="menu-unfold"
                                onClick={() => openDrawer()}
                                style={{ fontSize: "20px", marginTop: "15px" }}
                            />
                        ) : null}

                        <div className="user">
                            <Avatar onClick={() => window._elev.open()} className="elevio-avatar">
                                <i className="fas fa-question-circle elevio-icon" />
                            </Avatar>

                            <Popover
                                title={<span className="c-header-notification-popover-title">Notifiche</span>}
                                placement="bottomRight"
                                content={listAction}
                                trigger={["hover", "click"]}
                            >
                                <Badge count={data.length}>
                                    <Avatar size="small">
                                        <i className="fas fa-bell bell" />
                                    </Avatar>
                                </Badge>
                            </Popover>

                            <UserBadge
                                admin={admin}
                                auth={auth}
                                logout={logout}
                                isConsoleAdmin={isConsoleAdmin}
                                openAdminConsole={openAdminConsole}
                                userEnableForAdminConsole={this.state.userEnableForAdminConsole}
                                session={session}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
