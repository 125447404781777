import axios from "axios";
import cuid from "cuid";
import uuid from "uuid/v4";
import packageInfo from "../../package.json";

import { AUTH_REST_URL } from "../config";
import { logout, LOGIN_SUCCESS } from "../actions/auth";
import { displayErrorToast, displaySuccessToast } from "../actions/toast";

async function performCall(url, token, method, urlParams, body, authId, responseType) {
    let queryString = urlParams
        ? Object.entries(urlParams)
              .filter(e => e[1] !== null && e[1] !== undefined)
              .map(entry => `${entry[0]}=${entry[1]}`)
              .join("&")
        : "";

    queryString = queryString !== "" ? "?" + queryString : "";

    let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-App-Name": "BACKOFFICE",
        "X-App-Version": packageInfo.version,
        "X-User-Id": authId,
        "X-Item-Id": "item",
        "X-Request-Id": cuid(),
        "X-Correlation-Id": uuid()
    };
    return await axios({
        url: `${url}${queryString}`,
        method: method,
        data: body && JSON.stringify(body),
        headers: headers,
        responseType: responseType
    })
        .then(response => {
            return { ...response, ok: true };
        })
        .catch(error => {
            return { ...error.response, ok: false };
        });
}

export async function restApiCall(
    url,
    auth,
    method,
    urlParams,
    body,
    dispatch,
    refreshToken,
    toast = {
        onOk: false,
        onOkText: "Operazione effetuata correttamente",
        onError: false,
        infoReq: null
    },
    responseType
) {
    let response = await performCall(
        url,
        auth && auth.securityToken,
        method,
        urlParams,
        body,
        auth && auth.id,
        responseType
    );

    if (!response.ok) {
        if (response.status === 401 && auth) {
            const tokenResponse = await postRestCall(`${AUTH_REST_URL}/tokens`, null, null, {
                id: auth.id,
                digest: refreshToken
            });
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    loginAuth: {
                        securityToken: tokenResponse.accessToken,
                        id: auth.id,
                        appName: auth.appName,
                        refreshToken: tokenResponse.refreshToken
                    }
                }
            });

            response = await performCall(url, tokenResponse.accessToken, method, urlParams, body);

            if (!response.ok) {
                logout(auth);
            }
        } else if (response.status === 403 && response.data.code === "403.1") {
            dispatch({
                type: "LOGIN_ERROR",
                error: response.data,
                errorInfo: response.data
            });
        } else {
            toast.onError && displayErrorToast(response.data, toast.onError)(dispatch);

            const errResponse = {
                code: response.data.code ? response.data.code : response.status,
                message: response.data.message
            };
            throw errResponse;
        }
    }

    displaySuccessToast(toast.onOkText, toast.onOk, toast.infoReq)(dispatch);
    return response.data;
}

export function getRestCall(
    url,
    auth,
    urlParams,
    dispatch,
    refreshToken,
    toast = { onOk: false, onOkText: "", onError: false, infoReq: null },
    responseType
) {
    return restApiCall(url, auth, "GET", urlParams, null, dispatch, refreshToken, toast, responseType);
}

export function postRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshToken,
    toast = { onOk: false, onOkText: "", onError: false, infoReq: null }
) {
    return restApiCall(url, auth, "POST", urlParams, body, dispatch, refreshToken, toast);
}

export function deleteRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshToken,
    toast = { onOk: false, onOkText: "", onError: false, infoReq: null }
) {
    return restApiCall(url, auth, "DELETE", urlParams, body, dispatch, refreshToken, toast);
}

export function putRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshToken,
    toast = { onOk: false, onOkText: "", onError: false, infoReq: null }
) {
    return restApiCall(url, auth, "PUT", urlParams, body, dispatch, refreshToken, toast);
}

export function patchRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshToken,
    toast = { onOk: false, onOkText: "", onError: false, infoReq: null }
) {
    return restApiCall(url, auth, "PATCH", urlParams, body, dispatch, refreshToken, toast);
}
