import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, message } from "antd";

import { errorBoundary } from "../../components/general/error-boundary";
import { getBankLinkBank, getBankLinkAssociation, getBankLinkAssociations } from "../../actions/bank-link/read";
import { createBankLinkBank, updateBankLinkBank } from "../../actions/bank-link/write";
import PageHeader from "../../components/general/PageHeader/index";
import { BankLinkBankInfo } from "../../components/bank-link/bank-info";
import { ContractWrapper } from "./styled";

const BankLinkBankView = ({
    auth,
    history,
    getBankLinkBank,
    getBankLinkAssociation,
    createBankLinkBank,
    updateBankLinkBank,
    getBankLinkAssociations,
    match: {
        params: { hubId }
    }
}) => {
    const viewModel = useBankLinkBankView(
        auth.loginAuth,
        hubId,
        getBankLinkBank,
        getBankLinkAssociation,
        createBankLinkBank,
        updateBankLinkBank,
        getBankLinkAssociations
    );

    return (
        <div>
            <Card style={{ height: "90vh" }}>
                <ContractWrapper>
                    <PageHeader history={history} labelId={`bank`} />
                    <BankLinkBankInfo
                        loading={viewModel.loading}
                        isNewBank={viewModel.isNewBank}
                        bankInfo={viewModel.bankInfo}
                        handleUpdateBankInfo={viewModel.handleUpdateBank}
                        handleDeleteBankInfo={viewModel.handleDeleteBank}
                        handleCreateBankInfo={viewModel.handleCreateBank}
                        handleLoadAssociationLazy={viewModel.handleLoadAssociationLazy}
                    />
                </ContractWrapper>
            </Card>
        </div>
    );
};

BankLinkBankView.propTypes = {
    auth: PropTypes.object.isRequired,
    getBankLinkBank: PropTypes.func.isRequired,
    getBankLinkAssociation: PropTypes.func.isRequired,
    getBankLinkAssociations: PropTypes.func.isRequired,
    createBankLinkBank: PropTypes.func.isRequired,
    updateBankLinkBank: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const actions = {
    getBankLinkBank,
    getBankLinkAssociation,
    createBankLinkBank,
    updateBankLinkBank,
    getBankLinkAssociations
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(BankLinkBankView);

const useBankLinkBankView = (
    auth,
    hubId,
    getBankLinkBank,
    getBankLinkAssociation,
    createBankLinkRule,
    updateBankLinkRule,
    getBankLinkAssociations
) => {
    const [loading, setLoading] = useState(true);
    const [bankInfo, setBankInfo] = useState(null);
    const [refresh, setRefresh] = useState(false);
    /// Private
    const [associationOptions, setAssociationOptions] = useState([]);
    const [associationSearch, setAssociationSearch] = useState(null);
    const [associationHasMore, setAssociationHasMore] = useState(true);
    const [associationsListContinuationToken, setAssociationsListContinuationToken] = useState([null]);

    useEffect(() => {
        const getRule = async () => {
            let resultBank = await getBankLinkBank(auth, hubId);
            if (resultBank) {
                if (resultBank.associationId) {
                    let result = await getBankLinkAssociation(auth, resultBank.associationId);
                    if (result) {
                        resultBank.associationName = result.name;
                    }
                }
                setBankInfo(resultBank);
            }
            setLoading(false);
        };

        setLoading(true);
        setBankInfo(null);
        if (hubId !== "new") {
            getRule();
        } else {
            setLoading(false);
        }
    }, [auth, getBankLinkBank, getBankLinkAssociation, hubId, refresh]);

    const handleUpdateBank = async item => {
        setLoading(true);
        let data = {
            id: item.id,
            associationId: item.associationId,
            name: item.name,
            abi: item.abi
        };
        let result = await updateBankLinkRule(auth, data, item.id);
        if (result.code) {
            message.success("Documento è aggiornato correttamente");
        } else {
            message.error(`Documento non è aggiornato correttamente. ${result.message ? result.message : ""}`);
        }
        setRefresh(!refresh);
    };

    const handleCreateBank = async item => {
        setLoading(true);
        let data = {
            associationId: item.associationId,
            name: item.name,
            abi: item.abi
        };

        let result = await createBankLinkRule(auth, data);
        if (result.code) {
            message.success("Documento è creato correttamente");
        } else {
            message.error(`Documento non è creato correttamente. ${result.message ? result.message : ""}`);
        }
        setLoading(false);
    };

    const handleDeleteBank = item => {};

    const handleLoadAssociationLazy = async (search, prevOptions, { page }) => {
        let newAssociationOptions = [];
        let options = [];
        let hasMore = false;
        if (search && search === associationSearch) {
            if (associationHasMore) {
                let result = await getAssociations(search, page);
                options = result.options;
                hasMore = result.hasMore;
            }
        } else {
            let result = await getAssociations(search, page);
            options = result.options;
            hasMore = result.hasMore;
        }

        if (options && options.length) {
            options.forEach(item => {
                if (!associationOptions.includes(x => x.value === item.value)) {
                    newAssociationOptions.push(item);
                }
            });
        }
        let searchAssociationOptions = [];
        if (search && newAssociationOptions.length) {
            searchAssociationOptions = newAssociationOptions.filter(({ label }) =>
                label.toLowerCase().includes(search)
            );
        }

        setAssociationOptions(newAssociationOptions);
        setAssociationHasMore(hasMore);
        setAssociationSearch(search);

        return {
            options: search ? searchAssociationOptions : options,
            hasMore: hasMore,
            additional: {
                page: page + 1
            }
        };
    };

    const getAssociations = async (search, page) => {
        let options = [];
        let hasMore = false;
        let filter = {
            name: search,
            continuationToken: associationsListContinuationToken[page - 1]
        };
        let resultAssociations = await getBankLinkAssociations(auth, filter, 10);
        if (
            resultAssociations &&
            resultAssociations._embedded &&
            resultAssociations._embedded.associationEntityList &&
            resultAssociations._embedded.associationEntityList.length
        ) {
            options = resultAssociations._embedded.associationEntityList.map(item => {
                return {
                    value: item.id,
                    label: `${item.name} (${item.id})`
                };
            });
            hasMore = resultAssociations.page.hasNext;
            if (associationsListContinuationToken.length <= page) {
                associationsListContinuationToken.push(resultAssociations.page.continuationToken);
            }
        } else {
            setAssociationsListContinuationToken([]);
        }
        setAssociationsListContinuationToken(associationsListContinuationToken);
        return { options, hasMore };
    };

    return {
        loading,
        bankInfo,
        handleUpdateBank,
        handleCreateBank,
        handleDeleteBank,
        handleLoadAssociationLazy,
        isNewBank: hubId === "new" ? true : false
    };
};
