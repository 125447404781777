import { displayErrorToast, displaySuccessToast } from "../actions/toast";
import { logout } from "../actions/auth";
import { postRestCall } from "./rest-api-call";
import cuid from "cuid";
import uuid from "uuid/v4";

import UserWriteTypes from "../assets/thrift/user-write/user-write_types";
import AFiscaliTypes from "../assets/thrift/a-fiscali-iva/a-fiscali-iva-flow-config_types";
import { AUTH_REST_URL } from "../config";

export function getAuthWithAppNameForAdempimenti(auth, appName = "BACKOFFICE") {
    if (auth.loginAuth) {
        return new AFiscaliTypes.Auth({
            securityToken: auth.loginAuth.securityToken,
            id: auth.loginAuth.id,
            appName: appName,
            correlationId: uuid(),
            requestId: cuid()
        });
    } else if (auth.securityToken && auth.id) {
        return new AFiscaliTypes.Auth({
            securityToken: auth.securityToken,
            id: auth.id,
            appName: appName,
            correlationId: uuid(),
            requestId: cuid()
        });
    } else {
        console.error("# ERROR during LOGIN :: getAuthWithAppName ", auth);
    }
}

export function getAuthWithAppName(auth, appName = "BACKOFFICE") {
    if (auth.loginAuth) {
        return new UserWriteTypes.Auth({
            securityToken: auth.loginAuth.securityToken,
            id: auth.loginAuth.id,
            appName: appName
        });
    } else if (auth.securityToken && auth.id) {
        return new UserWriteTypes.Auth({
            securityToken: auth.securityToken,
            id: auth.id,
            appName: appName
        });
    } else {
        console.error("# ERROR during LOGIN :: getAuthWithAppName ", auth);
    }
}

export function callWithRetry(thriftClient, fn, auth, request, refreshToken, dispatch, callback, toast) {
    return retry(thriftClient, fn, auth, request, refreshToken, dispatch, callback, toast, logout);
}

export async function retry(
    thriftClient,
    fn,
    auth,
    request,
    refreshToken,
    dispatch,
    callback,
    toast = {
        onOk: false,
        onOkText: "Operazione effetuata correttamente",
        onError: false,
        infoReq: null
    },
    logout,
    codes = ["401"]
) {
    try {
        const result = await fn.call(thriftClient, auth, request);
        if (callback === undefined) return result;
        returnSuccess(callback, result, toast, dispatch);
    } catch (error) {
        if (codes.includes(error.code)) {
            try {
                const responseToken = await postRestCall(`${AUTH_REST_URL}/tokens`, null, null, {
                    id: auth.id,
                    digest: refreshToken
                });

                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: {
                        loginAuth: {
                            securityToken: responseToken.accessToken,
                            id: auth.id,
                            appName: auth.appName,
                            refreshToken: responseToken.refreshToken
                        }
                    }
                });

                auth.securityToken = responseToken.accessToken;
                auth.accessToken = responseToken.accessToken;
                const result = await fn.call(thriftClient, auth, request);

                returnSuccess(callback, result, toast, dispatch);
            } catch (e) {
                if (codes.includes(e.code)) {
                    logout(auth)(dispatch);
                } else {
                    returnError(callback, error, toast, dispatch);
                }
            }
        } else if (error.code === "403.1") {
            dispatch({
                type: "LOGIN_ERROR",
                error,
                errorInfo: error
            });
        } else {
            if (callback === undefined) throw error;
            returnError(callback, error, toast, dispatch);
        }
    }
}

function returnError(callback, error, toast, dispatch) {
    callback(error);

    if (toast && toast.onError) {
        displayErrorToast(error.message, toast.onError)(dispatch);
    }
}

function returnSuccess(callback, result, toast, dispatch) {
    callback(null, result);

    if (toast && toast.onOk) {
        displaySuccessToast(toast.onOkText, toast.onOk, toast.infoReq)(dispatch);
    }
}
