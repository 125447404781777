import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { isNil } from "ramda";
import { Card } from "antd";
import CompanyCreateForm from "../../../components/company/form/create";
import "./style.css";

import { createItem } from "../../../actions/item";
import { errorBoundary } from "../../../components/general/error-boundary";

class ItemsCreateView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object,
        createItem: PropTypes.func.isRequired
    };

    async handleCreateCompany(companyData) {
        const { auth, createItem, history } = this.props;
        if (!isNil(companyData)) {
            let dataToInsert;

            let address = {
                types: ["REGISTERED_OFFICE"],
                streetName: companyData.streetName,
                streetNumber: companyData.streetNumber,
                city: companyData.city,
                province: companyData.province,
                zipCode: companyData.zipCode,
                country: companyData.country
            };

            dataToInsert = {
                base: {
                    identifier: {
                        taxId: companyData.taxRegion !== "IT" ? companyData.vatNumber : companyData.taxId,
                        vatNumber: companyData.vatNumber,
                        taxRegion: companyData.taxRegion
                    },
                    details: {
                        classifier: companyData.classifier,
                        description: companyData.description,
                        addresses: [address]
                    }
                }
            };

            let item = await createItem(auth.loginAuth, dataToInsert, companyData.ownerId);
            if (item && item.id) {
                history.push(`/item/${item.id}/dashboard`);
            }
        }
    }

    render() {
        const { auth, history } = this.props;
        return (
            <div className="c-item-create">
                <span className="title">{"Creazione azienda"}</span>
                <Card>
                    <div onClick={() => history.goBack()} className="close">
                        <i className="far fa-times-circle close-icon" />
                    </div>
                    <br />
                    <CompanyCreateForm
                        initialValues={{
                            country: "IT",
                            taxRegion: "IT",
                            token: auth.loginAuth.securityToken
                        }}
                        onSubmit={val => this.handleCreateCompany(val)}
                    />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth
});

const actions = {
    createItem
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(ItemsCreateView);
