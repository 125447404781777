import { SERVICES_SUBSCRIPTION_URL, API_URL } from "../../config";
import { getRestCall, postRestCall, deleteRestCall } from "../../utils/rest-api-call";
import { isNil } from "ramda";

export const FETCH_ACTIVE_SERVICE_START = "FETCH_ACTIVE_SERVICE_START";
export const FETCH_ACTIVE_SERVICE_ERROR = "FETCH_ACTIVE_SERVICE_ERROR";
export const FETCH_ACTIVE_SERVICE_SUCCESS = "FETCH_ACTIVE_SERVICE_SUCCESS";

export function fetchActiveServices(auth, itemId) {
    return async dispatch => {
        dispatch({
            type: FETCH_ACTIVE_SERVICE_START,
            request: itemId
        });

        const url = `${SERVICES_SUBSCRIPTION_URL}/items`;

        try {
            const result = await getRestCall(url, auth, { itemIds: itemId }, dispatch, auth.refreshToken);

            dispatch({
                type: FETCH_ACTIVE_SERVICE_SUCCESS,
                payload: result.itemServices[itemId]
            });
        } catch (e) {
            dispatch({
                type: FETCH_ACTIVE_SERVICE_ERROR,
                error: e
            });
        }
    };
}

export const ACTIVATE_SERVICE_START = "ACTIVATE_SERVICE_START";
export const ACTIVATE_SERVICE_ERROR = "ACTIVATE_SERVICE_ERROR";
export const ACTIVATE_SERVICE_SUCCESS = "ACTIVATE_SERVICE_SUCCESS";

export function activateService(auth, fiscalCode, serviceName, extraData, ownerId, toastValues) {
    return async dispatch => {
        const request = {
            id: fiscalCode,
            ownerId,
            serviceId: serviceName,
            extraData
        };
        const toast = {
            onOk: true,
            onOkText:
                !isNil(toastValues) && !isNil(toastValues.onOkText)
                    ? toastValues.onOkText
                    : "Servizio attivato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: ACTIVATE_SERVICE_START,
            request: request
        });

        try {
            const result = await postRestCall(
                `${API_URL}/services`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: ACTIVATE_SERVICE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: ACTIVATE_SERVICE_ERROR,
                error: e
            });
        }
    };
}
export const DISABLE_SERVICE_START = "DISABLE_SERVICE_START";
export const DISABLE_SERVICE_ERROR = "DISABLE_SERVICE_ERROR";
export const DISABLE_SERVICE_SUCCESS = "DISABLE_SERVICE_SUCCESS";

export function disableService(auth, fiscalCode, serviceName, extraData, ownerId) {
    return async dispatch => {
        const request = {
            id: fiscalCode,
            ownerId,
            serviceId: serviceName,
            extraData
        };
        const toast = {
            onOk: true,
            onOkText: "Servizio disattivato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };
        dispatch({
            type: DISABLE_SERVICE_START,
            request: request
        });

        try {
            const result = await deleteRestCall(
                `${API_URL}/services`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                toast
            );

            dispatch({
                type: DISABLE_SERVICE_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: DISABLE_SERVICE_ERROR,
                error: e
            });
        }
    };
}
