import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import PropTypes from "prop-types";
import CompanyInfo from "../../company/info";
import { Alert, Button, Col, Row, Icon, Tabs, Select, Spin } from "antd";
import { PdfHandler } from "../../../components/general/pdf-handler";
import { cctProvisioningRejectMessages } from "../../../utils/constant";
import { ContractWrapper, PdfWrapper, InfoContainer, InfoTitle, CenteredDiv } from "./styled";
const { TabPane } = Tabs;
const { Option } = Select;

export const CertificationsNewArchive = ({
    infoRDC,
    loadingBtnValidate,
    loadingBtnReject,
    documentInfo,
    documentRDC,
    companyData,
    validateRDC,
    rejectRDC,
    managerData,
    preservation_manager,
    loadingInfoRDC,
    disabled
}) => {
    const [rejectMessage, setRejectMessage] = useState(null);

    const rejectOption = cctProvisioningRejectMessages.map(m => (
        <Option key={m} value={m}>
            <FormattedMessage id={`c-cct-provisioning.rejectMessage.${m}`} />
        </Option>
    ));
    if (loadingInfoRDC) {
        return (
            <CenteredDiv>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
            </CenteredDiv>
        );
    }
    return (
        <ContractWrapper>
            <Row gutter={24}>
                <Col span={12}>
                    <InfoContainer>
                        <div>
                            <Row gutter={24}>
                                <Col
                                    span={
                                        managerData && companyData && managerData.base.id !== companyData.base.id
                                            ? 12
                                            : 24
                                    }
                                >
                                    <InfoTitle>
                                        <FormattedMessage id="c-certifications.managed-info" />
                                    </InfoTitle>
                                    <div className="registry-content">
                                        <div className="registry-info">
                                            <CompanyInfo company={companyData} onlyInfo={true} noAction={true} />
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    {managerData && companyData && managerData.base.id !== companyData.base.id && (
                                        <div>
                                            <InfoTitle>
                                                <FormattedMessage id="c-certifications.manager-info" />
                                            </InfoTitle>
                                            <div className="registry-info">
                                                <CompanyInfo company={managerData} onlyInfo={true} noAction={true} />
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InfoTitle>
                                        <FormattedMessage id="c-certifications.rc-info" />
                                    </InfoTitle>
                                    {infoRDC.status === "REJECTED" && <Alert message={infoRDC.reject_reason} banner />}
                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lendername" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.first_name
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lastname" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.last_name
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lendercfcompany" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.tax_id
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lenderbirthplace" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.birth_place
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lendernationplace" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.tax_region
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lenderbirthday" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.birth_date
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lenderemail" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.email
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>

                                    <li>
                                        <FormattedMessage id="c-cctconfigservice.lenderpec" />
                                        <span>
                                            {preservation_manager ? (
                                                preservation_manager.email_is_pec ? (
                                                    "Si"
                                                ) : (
                                                    "No"
                                                )
                                            ) : (
                                                <FormattedMessage id="not_available" />
                                            )}
                                        </span>
                                    </li>
                                </Col>
                            </Row>
                            <br />
                            {infoRDC.status !== "REJECTED" && (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <div>
                                            <FormattedMessage id="c-certifications.addNoteForReject" />
                                            <Select style={{ width: "100%" }} onChange={key => setRejectMessage(key)}>
                                                {rejectOption}
                                            </Select>
                                            <br /> <br />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </InfoContainer>

                    <div style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            shape="round"
                            style={{ marginRight: 10 }}
                            hidden={true ? infoRDC.status === "REJECTED" : false}
                            onClick={() => rejectRDC(rejectMessage)}
                            loading={loadingBtnReject}
                            disabled={disabled}
                        >
                            <FormattedMessage id={"general.reject"} />
                        </Button>
                        <Button
                            type="primary"
                            shape="round"
                            style={{ marginRight: 10 }}
                            hidden={true ? infoRDC.status === "REJECTED" : false}
                            onClick={() => validateRDC()}
                            loading={loadingBtnValidate}
                            disabled={disabled}
                        >
                            <FormattedMessage id={"general.certify"} />
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={<FormattedMessage id={"c-archive-tab1-certification"} />} key="1">
                            <PdfWrapper>
                                <PdfHandler
                                    loading={documentInfo.status.started}
                                    content={!documentInfo ? null : documentInfo.content}
                                    fileName={"documento_contratto.pdf"}
                                />
                            </PdfWrapper>
                        </TabPane>
                        <TabPane tab={<FormattedMessage id={"c-archive-tab2-certification"} />} key="2">
                            <PdfWrapper>
                                <PdfHandler
                                    loading={documentRDC.status.started}
                                    content={documentRDC.contentB64Encoded}
                                    fileName={documentRDC.fileName}
                                />
                            </PdfWrapper>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </ContractWrapper>
    );
};

CertificationsNewArchive.propTypes = {
    companyData: PropTypes.object.isRequired,
    documentInfo: PropTypes.object.isRequired,
    documentRDC: PropTypes.object.isRequired,
    infoRDC: PropTypes.object.isRequired,
    loadingBtnReject: PropTypes.bool.isRequired,
    loadingBtnValidate: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    managerData: PropTypes.object,
    preservation_manager: PropTypes.object.isRequired,
    rejectRDC: PropTypes.func.isRequired,
    validateRDC: PropTypes.func.isRequired
};
