import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { TimedToastNotifications } from "@mondora/arc/antd/TimedToastNotifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faPen, faUser, faEnvelope, faFileContract } from "@fortawesome/pro-regular-svg-icons";

import {
    ActionDisabled,
    Status,
    SignerValue,
    SignerTitle,
    SignerContainer,
    ChipsContainer,
    Chip,
    CheckCircleIcon
} from "./styled";

export const SignatureInfoSigners = ({ document, resendEmail }) => {
    const [filter, setFilter] = useState("All");
    const [notifications, setNotifications] = useState([]);
    const [signers, setSigners] = useState([]);

    useEffect(() => {
        let signers = [];
        if (document.signers) {
            document.signers.forEach(element => {
                if (!filter || filter === "All") {
                    signers.push(element);
                } else {
                    if (!filter || filter === element.signerStatus.code) {
                        signers.push(element);
                    }
                }
            });
        }
        setSigners(signers);
    }, [document, filter]);

    return (
        <div>
            <TimedToastNotifications
                onRemoveNotification={() => setNotifications([])}
                notifications={notifications}
                offsetTop="60px"
            />
            <ChipsContainer>
                <Chip checked={filter === "All"} onChange={val => setFilter("All")}>
                    <FormattedMessage id={"all"} />
                </Chip>
                <Chip checked={filter === "Alla_Firma"} onChange={val => setFilter("Alla_Firma")}>
                    <FormattedMessage id={"to_signature"} />
                </Chip>
                <Chip checked={filter === "In_Elaborazione"} onChange={val => setFilter("In_Elaborazione")}>
                    <FormattedMessage id={"in_processing"} />
                </Chip>
                <Chip checked={filter === "Firmato"} onChange={val => setFilter("Firmato")}>
                    <FormattedMessage id={"signed"} />
                </Chip>
            </ChipsContainer>

            {signers.length > 0 ? (
                signers.map(signer => (
                    <SignerContainer key={signer.fiscalCode + signer.email}>
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: "8px" }} />
                        <SignerTitle>{signer.firstName + " " + signer.lastName}</SignerTitle>
                        {signer.hasCertificate && (
                            <Status>
                                <FormattedMessage id={"certified"} />
                                <CheckCircleIcon icon={faCheckCircle} style={{ marginLeft: "8px" }} />
                            </Status>
                        )}
                        <br />
                        <SignerValue>{signer.email}</SignerValue>
                        <Status>
                            {signer.signerStatus && (
                                <div>
                                    <FontAwesomeIcon
                                        icon={
                                            signer.signerStatus.title === "In Elaborazione"
                                                ? faSpinner
                                                : signer.signerStatus.title === "Alla firma"
                                                ? faPen
                                                : faFileContract
                                        }
                                        spin={signer.signerStatus.title === "In Elaborazione"}
                                        style={{ marginRight: "8px" }}
                                    />
                                    <FormattedMessage
                                        id={
                                            signer.signerStatus.title === "In Elaborazione"
                                                ? "in_processing"
                                                : signer.signerStatus.title === "Alla firma"
                                                ? "to_signature"
                                                : "signed"
                                        }
                                    />
                                </div>
                            )}
                        </Status>
                        <br />
                        <SignerValue> {signer.fiscalCode}</SignerValue>
                        <br />
                        <SignerValue> {signer.mobile}</SignerValue>
                        <Status>
                            {signer.canEmailBeSentAgain ? (
                                <ActionDisabled>
                                    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} />
                                    <FormattedMessage id={"email"} />
                                </ActionDisabled>
                            ) : (
                                <ActionDisabled>
                                    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} />
                                    <FormattedMessage id={"email"} />
                                </ActionDisabled>
                            )}
                        </Status>
                    </SignerContainer>
                ))
            ) : (
                <span>
                    <FormattedMessage id={"no_signatory"} />
                </span>
            )}
        </div>
    );
};

SignatureInfoSigners.propTypes = {
    document: PropTypes.object.isRequired,
    resendEmail: PropTypes.func.isRequired
};
