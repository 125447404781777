import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import ServiceCard from "../../../components/general/ServiceCard/info";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getSdiConfig, updateSdiConfig } from "../../../actions/services/sdi";
import { searchItems, searchCompany } from "../../../actions/search/company";
import { getInvoicingUsage } from "../../../actions/usage/invoices";
import { activateService, disableService } from "../../../actions/company/services";
import { findOwnManagerConnections } from "../../../actions/connections/read";
import { searchSdiConfig, getServicesForCompanySeached } from "../../../actions/search/services";
import SdiService from "../../../components/services/sdi";
import { userIsAuthorized } from "../../../utils/get-roles";
import { useFeature } from "../../../utils/hook/use-feature";

const SdiServiceView = ({
    auth,
    admin,
    company,
    connections,
    disableService,
    history,
    getSdiConfig,
    searchCompany,
    findOwnManagerConnections,
    searchItems,
    searchSdiConfig,
    services,
    updateSdiConfig,
    usage,
    activateService,
    getServicesForCompanySeached,
    getInvoicingUsage
}) => {
    const viewModel = useSdiServiceViewModel(
        auth,
        admin,
        company,
        getSdiConfig,
        searchItems,
        findOwnManagerConnections,
        getInvoicingUsage
    );
    const meteringExtension = useFeature("Metering_Extension");
    return (
        <ServiceCard
            history={history}
            content={
                <SdiService
                    activateService={activateService}
                    auth={auth}
                    admin={admin}
                    active={company.active}
                    company={company}
                    connections={connections}
                    disableService={disableService}
                    getSdiConfig={getSdiConfig}
                    searchCompany={searchCompany}
                    findOwnManagerConnections={findOwnManagerConnections}
                    searchItems={searchItems}
                    searchSdiConfig={searchSdiConfig}
                    services={services}
                    updateSdiConfig={updateSdiConfig}
                    usage={usage}
                    getServicesForCompanySeached={getServicesForCompanySeached}
                    sdiConfig={viewModel.sdiConfig}
                    sdiConfigLoaded={viewModel.sdiConfigLoaded}
                    sdiShared={viewModel.sdiShared}
                    sdiSharedLoaded={viewModel.sdiSharedLoaded}
                    handleChangeSdiSharedPage={viewModel.handleChangeSdiSharedPage}
                    sdiSharedCurrentPage={viewModel.sdiSharedCurrentPage}
                    sdiSharedTotalItems={viewModel.sdiSharedTotalItems}
                    sdiSharedItemForPage={viewModel.sdiSharedItemForPage}
                    isHavePermission={viewModel.isHavePermission}
                    meteringExtension={meteringExtension}
                />
            }
            serviceInfo={{ name: "Sdi", category: "EIP" }}
            statisticsEnable={false}
        />
    );
};
SdiServiceView.propTypes = {
    activateService: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object,
    connections: PropTypes.object.isRequired,
    disableService: PropTypes.func.isRequired,
    getInvoicingUsage: PropTypes.func.isRequired,
    getSdiConfig: PropTypes.func.isRequired,
    searchCompany: PropTypes.func.isRequired,
    searchItems: PropTypes.func.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    searchSdiConfig: PropTypes.func.isRequired,
    services: PropTypes.object,
    updateSdiConfig: PropTypes.func.isRequired,
    usage: PropTypes.object,
    getServicesForCompanySeached: PropTypes.func.isRequired,
    meteringExtension: PropTypes.bool
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    connections: state.connections.read,
    services: state.services,
    usage: state.usage
});

const actions = {
    activateService,
    disableService,
    getInvoicingUsage,
    getSdiConfig,
    searchCompany,
    findOwnManagerConnections,
    searchItems,
    searchSdiConfig,
    updateSdiConfig,
    getServicesForCompanySeached
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(SdiServiceView);

const useSdiServiceViewModel = (
    auth,
    admin,
    company,
    getSdiConfig,
    searchItems,
    findOwnManagerConnections,
    getInvoicingUsage
) => {
    const sdiSharedItemForPage = 10;

    const [sdiConfig, setSdiConfig] = useState([]);
    const [sdiShared, setSdiShared] = useState([]);
    const [sdiConfigLoaded, setSdiConfigLoaded] = useState(true);
    const [sdiSharedLoaded, setSdiSharedLoaded] = useState(true);
    const [sdiSharedCurrentPage, setSdiSharedCurrentPage] = useState(0);
    const [sdiSharedTotalItems, setSdiSharedTotalItems] = useState(0);
    const [containSdiService, setContainSdiService] = useState(false);
    const [isHavePermission, setIsHavePermission] = useState(false);

    useEffect(() => {
        let permission = userIsAuthorized("enable-disable-service", admin.adminInfo.permits);
        setIsHavePermission(permission);
    }, [admin.adminInfo.permits]);

    useEffect(() => {
        let containSdiService = false;
        if (company.info.base && company.active.services) {
            let configServices = company.active.services.filter(service => service.agyoService.id === "SDI-FLOW");
            if (configServices) {
                containSdiService = true;
            }
        }
        setContainSdiService(containSdiService);
    }, [company.active.services, company.info.base]);

    useEffect(() => {
        const startInvoicingUsage = () => {
            getInvoicingUsage(auth.loginAuth, company.info.base.id);
        };
        if (containSdiService) {
            startInvoicingUsage();
        }
    }, [auth.loginAuth, company.info.base.id, containSdiService, getInvoicingUsage]);

    useEffect(() => {
        const startSdiShared = async () => {
            setSdiShared([]);
            setSdiSharedLoaded(true);
            let sdiConfig = await getSdiConfig(
                auth.loginAuth,
                company.info.base.id,
                sdiSharedCurrentPage,
                sdiSharedItemForPage
            );
            setSdiSharedTotalItems(sdiConfig.totalElements ? sdiConfig.totalElements : 0);
            if (sdiConfig && sdiConfig.configs && sdiConfig.configs.length > 0) {
                let configForItemShared = sdiConfig.configs.filter(
                    config => config.ownerId === company.info.base.id && config.ownerId !== config.itemId
                );
                let idsToGetInfoShared = configForItemShared && configForItemShared.map(config => config.itemId);
                if (idsToGetInfoShared && idsToGetInfoShared !== []) {
                    searchItems(auth.loginAuth, idsToGetInfoShared).then(async idsInfo => {
                        configForItemShared = configForItemShared.map(config =>
                            idsInfo.items
                                ? {
                                      ...config,
                                      itemDesc: idsInfo.items.find(item => item.item.base.id === config.itemId).item
                                          .base.details.description,
                                      itemTaxId: idsInfo.items.find(item => item.item.base.id === config.itemId).item
                                          .base.identifier.taxId,
                                      itemVatNumber: idsInfo.items.find(item => item.item.base.id === config.itemId)
                                          .item.base.identifier.vatNumber
                                  }
                                : false
                        );
                        setSdiShared(configForItemShared);
                        setSdiSharedLoaded(false);
                    });
                } else {
                    setSdiSharedLoaded(false);
                }
            } else {
                setSdiSharedLoaded(false);
            }
        };

        if (containSdiService) {
            startSdiShared();
        }
    }, [auth.loginAuth, company.info.base.id, containSdiService, getSdiConfig, sdiSharedCurrentPage, searchItems]);

    useEffect(() => {
        const startSdiConfig = async () => {
            setSdiConfig([]);
            setSdiConfigLoaded(true);
            let sdiConfig = await getSdiConfig(auth.loginAuth, company.info.base.id);

            if (sdiConfig && sdiConfig.configs && sdiConfig.configs.length > 0) {
                let configForItem = sdiConfig.configs.filter(config => config.itemId === company.info.base.id);

                let idsToGetInfo = configForItem && configForItem.map(config => config.ownerId);
                if (idsToGetInfo && idsToGetInfo !== []) {
                    searchItems(auth.loginAuth, idsToGetInfo).then(async idsInfo => {
                        configForItem = configForItem.map(config =>
                            idsInfo.items
                                ? {
                                      ...config,
                                      ownerDesc: idsInfo.items.find(item => item.item.base.id === config.ownerId).item
                                          .base.details.description,
                                      ownerTaxId: idsInfo.items.find(item => item.item.base.id === config.ownerId).item
                                          .base.identifier.taxId,
                                      ownerVatNumber: idsInfo.items.find(item => item.item.base.id === config.ownerId)
                                          .item.base.identifier.vatNumber
                                  }
                                : false
                        );
                        let pagination = {
                            page: 0,
                            items: 10
                        };
                        const anyConnections = await findOwnManagerConnections(
                            auth.loginAuth,
                            { managedIds: company.info.base.uuid },
                            pagination
                        );
                        if (anyConnections.totalElements && anyConnections.totalElements > 0) {
                            let filter = {
                                managedIds: company.info.base.uuid,
                                featureCode: "SDI",
                                status: "VALIDATED"
                            };
                            const sdiConnections = await findOwnManagerConnections(auth.loginAuth, filter, pagination);
                            if (sdiConnections.totalElements && sdiConnections.totalElements > 0) {
                                configForItem = configForItem.map(config =>
                                    sdiConnections.content
                                        ? {
                                              ...config,
                                              editSignIsEnable: sdiConnections.content.some(
                                                  connection => connection.managerId === config.ownerId
                                              )
                                          }
                                        : false
                                );
                            } else {
                                configForItem = configForItem.map(
                                    config =>
                                        config && {
                                            ...config,
                                            editSignIsEnable: false
                                        }
                                );
                            }
                        } else {
                            configForItem = configForItem.map(
                                config =>
                                    config && {
                                        ...config,
                                        editSignIsEnable: true
                                    }
                            );
                        }

                        setSdiConfig(configForItem);
                        setSdiConfigLoaded(false);
                    });
                } else {
                    setSdiConfigLoaded(false);
                }
            } else {
                setSdiConfigLoaded(false);
            }
        };

        if (containSdiService) {
            startSdiConfig();
        }
    }, [
        auth.loginAuth,
        company.info.base.uuid,
        company.info.base.id,
        containSdiService,
        getSdiConfig,
        searchItems,
        findOwnManagerConnections
    ]);

    const handleChangeSdiSharedPage = async page => {
        setSdiSharedCurrentPage(page);
    };

    return {
        sdiConfig,
        sdiShared,
        sdiConfigLoaded,
        sdiSharedLoaded,
        sdiSharedCurrentPage,
        sdiSharedTotalItems,
        handleChangeSdiSharedPage,
        sdiSharedItemForPage,
        isHavePermission
    };
};
