import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Radio, DatePicker, Select } from "antd";
// import { FormattedMessage } from "react-intl";

import { UsageConsumptionBarContent, UsageConsumptionBarDatePicker } from "./styled";

export const UsageConsumptionBar = ({
    company,
    onChangeManaged,
    onChangeChartType,
    onChangeMonth,
    onChangeDataType,
    companyOptions,
    grapType,
    dataType
}) => {
    return (
        <div style={{ marginTop: 15 }}>
            <span className="a1">Consumi</span>
            <UsageConsumptionBarContent>
                {company.info.base != null &&
                    company.info.base.details != null &&
                    company.info.base.details.classifier === "STUDIO" && (
                        <Select
                            defaultValue={null}
                            showSearch
                            className="chart-tool-select"
                            placeholder="Seleziona l'azienda gestita di cui vuoi vedere i consumi"
                            optionFilterProp="children"
                            onChange={managed => onChangeManaged(managed)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {companyOptions}
                        </Select>
                    )}

                <Radio.Group
                    className="chart-tool-time"
                    defaultValue={grapType}
                    value={grapType}
                    buttonStyle="solid"
                    onChange={e => onChangeChartType(e.target.value)}
                >
                    <Radio.Button value="month">Mese</Radio.Button>
                    <Radio.Button value="year">Anno</Radio.Button>
                </Radio.Group>

                <Radio.Group
                    defaultValue={dataType}
                    value={dataType}
                    buttonStyle="solid"
                    onChange={e => onChangeDataType(e.target.value)}
                >
                    <Radio.Button value="table">
                        <i className="fas fa-table" />
                    </Radio.Button>
                    <Radio.Button value="grap">
                        <i className="fas fa-chart-area" />
                    </Radio.Button>
                </Radio.Group>
                <UsageConsumptionBarDatePicker>
                    <DatePicker.MonthPicker
                        className="chart-tool-picker"
                        defaultValue={moment(new Date(), "YYYY/MM")}
                        disabledDate={current => current > moment().endOf("month")}
                        onChange={(e, b) => onChangeMonth(e, b)}
                        disabled={grapType === "year"}
                        placeholder="Select Month"
                        allowClear={false}
                    />
                </UsageConsumptionBarDatePicker>
            </UsageConsumptionBarContent>
        </div>
    );
};

UsageConsumptionBar.propTypes = {
    company: PropTypes.object.isRequired,
    onChangeManaged: PropTypes.func.isRequired,
    onChangeChartType: PropTypes.func.isRequired,
    onChangeMonth: PropTypes.func.isRequired,
    onChangeDataType: PropTypes.func.isRequired,
    companyOptions: PropTypes.array.isRequired,
    grapType: PropTypes.string.isRequired,
    dataType: PropTypes.string.isRequired
};
