import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Tag } from "antd";

export const UserConsents = ({ userConsentsList }) => {
    let columns = [
        {
            title: <FormattedMessage id={"type"} />,
            dataIndex: "type"
        },
        {
            title: <FormattedMessage id={"description"} />,
            dataIndex: "description"
        },
        {
            title: <FormattedMessage id={"version"} />,
            dataIndex: "version"
        },
        {
            title: <FormattedMessage id={"latest_version"} />,
            dataIndex: "latestVersion"
        },
        {
            title: <FormattedMessage id={"accepted"} />,
            dataIndex: "accepted",
            render: text => (
                <span>
                    {text ? (
                        <Tag color="green">
                            <span>
                                <i className="fas fa-check-circle" style={{ marginRight: 8 }} />
                                <FormattedMessage id={"general.yes"} />
                            </span>
                        </Tag>
                    ) : (
                        <Tag color="red">
                            <span>
                                <i className="fas fa-times-circle" style={{ marginRight: 8 }} />
                                <FormattedMessage id={"general.no"} />
                            </span>
                        </Tag>
                    )}
                </span>
            )
        },
        {
            title: <FormattedMessage id={"date"} />,
            dataIndex: "timestamp",
            render: text => <span>{text ? new Date(Number(text)).toLocaleString() : ""}</span>
        }
    ];

    return (
        <div>
            <Table
                title={() => (
                    <div style={{ marginTop: 20, fontWeight: "bold" }}>
                        <FormattedMessage id={"user_consents_list"} />:
                    </div>
                )}
                rowKey={(record, index) => index}
                size="middle"
                columns={columns}
                locale={{
                    emptyText: <FormattedMessage id={"no_result"} />
                }}
                dataSource={userConsentsList}
                pagination={false}
            />
        </div>
    );
};

UserConsents.propTypes = {
    userConsentsList: PropTypes.array.isRequired
};
