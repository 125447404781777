import { COMPANY_CERTIFICATION_URL, COMPANY_CERTIFICATION_DOCUMENT_URL } from "../config";
import { getAuthWithAppName, callWithRetry } from "../utils/callWithRetry";
import CompanyCertifcaion from "../assets/thrift/company-certification/CompanyCertificationApi";
import CompanyDocumentApi from "../assets/thrift/company-certification/CompanyDocumentApi";
import CompanyCertifcaionTypes from "../assets/thrift/company-certification/thrift_types";
import thrift from "browser-thrift";

const companyCertificationUrl = new URL(COMPANY_CERTIFICATION_URL);
const companyCertificatioClient = thrift.createXHRClient(
    CompanyCertifcaion,
    thrift.createXHRConnection(companyCertificationUrl.hostname, 443, {
        useCors: true,
        path: companyCertificationUrl.pathname,
        https: companyCertificationUrl.protocol === "https:"
    })
);

const companyCertificationDocumentUrl = new URL(COMPANY_CERTIFICATION_DOCUMENT_URL);
const companyCertificatioDocumentClient = thrift.createXHRClient(
    CompanyDocumentApi,
    thrift.createXHRConnection(companyCertificationDocumentUrl.hostname, 443, {
        useCors: true,
        path: companyCertificationDocumentUrl.pathname,
        https: companyCertificationDocumentUrl.protocol === "https:"
    })
);

export const VALIDATING_CONTRACT_START = "VALIDATING_CONTRACT_START";
export const VALIDATING_CONTRACT_SUCCESS = "VALIDATING_CONTRACT_SUCCESS";
export const VALIDATING_CONTRACT_ERROR = "VALIDATING_CONTRACT_ERROR";

export function validatingContract(auth, idDocument) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            var request = new CompanyCertifcaionTypes.Validate({
                id: idDocument
            });
            dispatch({
                type: VALIDATING_CONTRACT_START,
                request: request
            });

            callWithRetry(
                companyCertificatioClient,
                companyCertificatioClient.validate,
                getAuthWithAppName(auth),
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: VALIDATING_CONTRACT_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: VALIDATING_CONTRACT_SUCCESS,
                            payload: result
                        });
                        resolve(result);
                    }
                }
            );
        });
    };
}

export const REJECTING_CONTRACT_START = "REJECTING_CONTRACT_START";
export const REJECTING_CONTRACT_SUCCESS = "REJECTING_CONTRACT_SUCCESS";
export const REJECTING_CONTRACT_ERROR = "REJECTING_CONTRACT_ERROR";

export function rejectingContract(auth, idDocument, note) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            var request = new CompanyCertifcaionTypes.Reject({
                id: idDocument,
                message: note
            });
            dispatch({
                type: REJECTING_CONTRACT_START,
                request: request
            });

            callWithRetry(
                companyCertificatioClient,
                companyCertificatioClient.reject,
                getAuthWithAppName(auth),
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: REJECTING_CONTRACT_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: REJECTING_CONTRACT_SUCCESS,
                            payload: result
                        });
                        resolve(result);
                    }
                }
            );
        });
    };
}

export const GET_DOCUMENTS_START = "GET_DOCUMENTS_START";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR";

export function getDocuments(auth, status, pagination, filter) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const { itemsPerPage, pageNumber } = pagination;
            var request = new CompanyCertifcaionTypes.GetDocumentsRequest({
                pagination: new CompanyCertifcaionTypes.Pagination({
                    itemsPerPage,
                    pageNumber
                }),
                documentsStatus: status,
                filter: filter
            });
            dispatch({
                type: GET_DOCUMENTS_START,
                request: request
            });

            callWithRetry(
                companyCertificatioDocumentClient,
                companyCertificatioDocumentClient.getDocuments,
                getAuthWithAppName(auth),
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: GET_DOCUMENTS_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: GET_DOCUMENTS_SUCCESS,
                            payload: result
                        });
                        resolve(result);
                    }
                }
            );
        });
    };
}

export const GET_FULL_INFO_DOCUMENT_START = "GET_FULL_INFO_DOCUMENT_START";
export const GET_FULL_INFO_DOCUMENT_SUCCESS = "GET_FULL_INFO_DOCUMENT_SUCCESS";
export const GET_FULL_INFO_DOCUMENT_ERROR = "GET_FULL_INFO_DOCUMENT_ERROR";

export function getFullInfoDocument(auth, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: GET_FULL_INFO_DOCUMENT_START,
                request: id
            });

            callWithRetry(
                companyCertificatioDocumentClient,
                companyCertificatioDocumentClient.getFullInfoDocument,
                getAuthWithAppName(auth),
                id,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: GET_FULL_INFO_DOCUMENT_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: GET_FULL_INFO_DOCUMENT_SUCCESS,
                            payload: result
                        });
                        resolve(result);
                    }
                }
            );
        });
    };
}

export const GET_EASY_DOCUMENT_START = "GET_EASY_DOCUMENT_START";
export const GET_EASY_DOCUMENT_SUCCESS = "GET_EASY_DOCUMENT_SUCCESS";
export const GET_EASY_DOCUMENT_ERROR = "GET_EASY_DOCUMENT_ERROR";

export function getEasyDocument(auth, id) {
    return async dispatch => {
        dispatch({
            type: GET_EASY_DOCUMENT_START,
            request: id
        });

        try {
            const result = await callWithRetry(
                companyCertificatioDocumentClient,
                companyCertificatioDocumentClient.getFullInfoDocument,
                getAuthWithAppName(auth),
                id,
                auth.refreshToken,
                dispatch
            );
            dispatch({
                type: GET_EASY_DOCUMENT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: GET_EASY_DOCUMENT_ERROR,
                error: e
            });
        }
    };
}

export const GET_FULL_INFO_DOCUMENT_START_ACTION = "@GET_FULL_INFO_DOCUMENT_START";
export const GET_FULL_INFO_DOCUMENT_SUCCESS_ACTION = "@GET_FULL_INFO_DOCUMENT_SUCCESS";
export const GET_FULL_INFO_DOCUMENT_ERROR_ACTION = "@GET_FULL_INFO_DOCUMENT_ERROR";

export function getFullInfoDocumentCall(auth, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: GET_FULL_INFO_DOCUMENT_START_ACTION,
                request: id
            });

            callWithRetry(
                companyCertificatioDocumentClient,
                companyCertificatioDocumentClient.getFullInfoDocument,
                getAuthWithAppName(auth),
                id,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: GET_FULL_INFO_DOCUMENT_ERROR_ACTION,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: GET_FULL_INFO_DOCUMENT_SUCCESS_ACTION,
                            payload: result
                        });
                        resolve(result);
                    }
                }
            );
        });
    };
}

export const PAUSE_CONTRACT_START = "PAUSE_CONTRACT_START";
export const PAUSE_CONTRACT_SUCCESS = "PAUSE_CONTRACT_SUCCESS";
export const PAUSE_CONTRACT_ERROR = "PAUSE_CONTRACT_ERROR";

export function pauseContract(auth, idDocument) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            var request = new CompanyCertifcaionTypes.PauseRequest({
                id: idDocument
            });
            dispatch({
                type: PAUSE_CONTRACT_START,
                request: request
            });

            callWithRetry(
                companyCertificatioClient,
                companyCertificatioClient.pause,
                getAuthWithAppName(auth),
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: PAUSE_CONTRACT_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: PAUSE_CONTRACT_SUCCESS,
                            payload: result
                        });
                        resolve(result);
                    }
                }
            );
        });
    };
}
