import { isVatNumber, vatNumberExists } from "@mondora/agyo-validations";
import { DOMAIN } from "../../config";
import { translateValiationError } from "../translate";

export const vatNumberSchema = {
    type: "object",
    properties: {
        newVatNumber: {
            type: "string",
            validate: (vatNumber, formValues) =>
                isVatNumber("taxRegion", error => translateValiationError(error))(vatNumber, formValues),
            asyncValidate: async (vatNumber, formValues) => {
                if (vatNumber !== formValues.taxId) {
                    let formValuesWithOutTaxId = {
                        ...formValues,
                        taxId: null
                    };
                    return await vatNumberExists("Partita IVA già registrata", DOMAIN, "BACKOFFICE")(
                        vatNumber,
                        formValuesWithOutTaxId
                    );
                }
            }
        },
        taxRegion: {
            type: "string"
        }
    }
};
