import styled from "styled-components";

export const ContainerTitle = styled.span`
    font-weight: bold;
    font-size: 15px;
    color: #282828;
    margin-bottom: 20px;
    margin-top: 50px;
`;

export const ContainerBody = styled.div`
    margin-top: 20px;
`;

export const ContainerItem = styled.div`
    margin-bottom: 10px;
`;
