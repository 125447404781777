import styled from "styled-components";

export const ContainerCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
`;

export const ContainerPDFViewer = styled.div`
    height: 90vh;
    border: 0px;
`;
