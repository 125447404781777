import React from "react";
import PropTypes from "prop-types";

import { MeteringInfoCardBody, MeteringInfoTitle, MeteringInfoCloseIcon, MeteringInfoSpinner } from "./styled";

import { Card, Avatar, Spin, Icon } from "antd";

export const MeteringInfoCard = ({ icon, background, color, title, history, content, loaded }) => {
    return (
        <MeteringInfoCardBody>
            <Card style={{ height: "100%" }}>
                <MeteringInfoCloseIcon>
                    <i className={"far fa-times-circle"} onClick={() => history.goBack()} />
                </MeteringInfoCloseIcon>
                <Avatar
                    style={{
                        background: background,
                        color: color,
                        border: "1px solid" + color
                    }}
                >
                    <i className={icon} />
                </Avatar>
                <MeteringInfoTitle>{title}</MeteringInfoTitle>
                <br />
                <br />
                {loaded ? (
                    <div>{content}</div>
                ) : (
                    <MeteringInfoSpinner>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                    </MeteringInfoSpinner>
                )}
            </Card>
        </MeteringInfoCardBody>
    );
};

MeteringInfoCard.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    loaded: PropTypes.bool.isRequired
};
