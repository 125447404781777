import { SIGNER_READ_URL, SIGNER_WRITE_URL } from "../config";
import { getRestCall, postRestCall } from "../utils/rest-api-call";

export const DOWNLOAD_FILLED_DOCUMENT_START = "DOWNLOAD_FILLED_DOCUMENT_START";
export const DOWNLOAD_FILLED_DOCUMENT_SUCCESS = "DOWNLOAD_FILLED_DOCUMENT_SUCCESS";
export const DOWNLOAD_FILLED_DOCUMENT_ERROR = "DOWNLOAD_FILLED_DOCUMENT_ERROR";

export function downloadFilledDocument(auth, signerId) {
    return async dispatch => {
        dispatch({
            type: DOWNLOAD_FILLED_DOCUMENT_START
        });

        try {
            const result = await getRestCall(
                `${SIGNER_READ_URL}/signers/${signerId}/downloadFilledDocument`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: DOWNLOAD_FILLED_DOCUMENT_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: DOWNLOAD_FILLED_DOCUMENT_ERROR,
                error: e
            });
        }
    };
}

export const GET_SIGNER_DATA_START = "GET_SIGNER_DATA_START";
export const GET_SIGNER_DATA_SUCCESS = "GET_SIGNER_DATA_SUCCESS";
export const GET_SIGNER_DATA_ERROR = "GET_SIGNER_DATA_ERROR";

export function getData(auth, signerId) {
    return async dispatch => {
        dispatch({
            type: GET_SIGNER_DATA_START
        });

        try {
            const result = await getRestCall(
                `${SIGNER_READ_URL}/signers/${signerId}/getSignerData`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_SIGNER_DATA_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_SIGNER_DATA_ERROR,
                error: e
            });
        }
    };
}

export const WITHDRAW_START = "WITHDRAW_START";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";

export function withdraw(auth, signerId) {
    return async dispatch => {
        const request = {
            signerId: signerId
        };
        dispatch({
            type: WITHDRAW_START,
            payload: request
        });

        const toast = {
            onOk: true,
            onOkText: "Certificato revocato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await postRestCall(
                `${SIGNER_WRITE_URL}/signers/withdraw`,
                auth,
                request,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: WITHDRAW_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: WITHDRAW_ERROR,
                error: e
            });
        }
    };
}

export const CANCEL_START = "CANCEL_START";
export const CANCEL_SUCCESS = "CANCEL_SUCCESS";
export const CANCEL_ERROR = "CANCEL_ERROR";

export function cancel(auth, signerId) {
    return async dispatch => {
        const request = {
            signerId: signerId
        };
        dispatch({
            type: CANCEL_START,
            payload: request
        });

        const toast = {
            onOk: true,
            onOkText: "Firmatario cancellato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await postRestCall(
                `${SIGNER_WRITE_URL}/signers/cancel`,
                auth,
                request,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: CANCEL_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: CANCEL_ERROR,
                error: e
            });
        }
    };
}

export const UPDATE_SIGNER_START = "UPDATE_SIGNER_START";
export const UPDATE_SIGNER_SUCCESS = "UPDATE_SIGNER_SUCCESS";
export const UPDATE_SIGNER_ERROR = "UPDATE_SIGNER_ERROR";

export function update(auth, signerId) {
    return async dispatch => {
        const request = {
            signerId: signerId
        };
        dispatch({
            type: UPDATE_SIGNER_START,
            payload: request
        });

        const toast = {
            onOk: true,
            onOkText: "Certificato sincronizzato correttamente",
            onError: true,
            infoReq: { authId: auth.id, req: request }
        };

        try {
            const result = await postRestCall(
                `${SIGNER_WRITE_URL}/signers/update`,
                auth,
                request,
                null,
                dispatch,
                auth.refreshToken,
                toast
            );
            dispatch({
                type: UPDATE_SIGNER_SUCCESS,
                payload: result
            });
            return result;
        } catch (e) {
            dispatch({
                type: UPDATE_SIGNER_ERROR,
                error: e
            });
        }
    };
}
