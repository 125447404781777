/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./thrift_types');
//HELPER FUNCTIONS AND STRUCTURES

var CompanyCertificationApi_health_args = function(args) {
};
CompanyCertificationApi_health_args.prototype = {};
CompanyCertificationApi_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_health_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
CompanyCertificationApi_health_result.prototype = {};
CompanyCertificationApi_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_health_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_tryVerify_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.TryVerify(args.data);
    }
  }
};
CompanyCertificationApi_tryVerify_args.prototype = {};
CompanyCertificationApi_tryVerify_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.TryVerify();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_tryVerify_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_tryVerify_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_tryVerify_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_tryVerify_result.prototype = {};
CompanyCertificationApi_tryVerify_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_tryVerify_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_tryVerify_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_upload_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.Upload(args.data);
    }
  }
};
CompanyCertificationApi_upload_args.prototype = {};
CompanyCertificationApi_upload_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.Upload();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_upload_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_upload_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_upload_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_upload_result.prototype = {};
CompanyCertificationApi_upload_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_upload_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_upload_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_verify_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.Verify(args.data);
    }
  }
};
CompanyCertificationApi_verify_args.prototype = {};
CompanyCertificationApi_verify_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.Verify();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_verify_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_verify_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_verify_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_verify_result.prototype = {};
CompanyCertificationApi_verify_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_verify_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_verify_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_validate_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.Validate(args.data);
    }
  }
};
CompanyCertificationApi_validate_args.prototype = {};
CompanyCertificationApi_validate_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.Validate();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_validate_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_validate_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_validate_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_validate_result.prototype = {};
CompanyCertificationApi_validate_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_validate_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_validate_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_reject_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.Reject(args.data);
    }
  }
};
CompanyCertificationApi_reject_args.prototype = {};
CompanyCertificationApi_reject_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.Reject();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_reject_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_reject_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_reject_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_reject_result.prototype = {};
CompanyCertificationApi_reject_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_reject_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_reject_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_info_args = function(args) {
  this.auth = null;
  this.id = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
CompanyCertificationApi_info_args.prototype = {};
CompanyCertificationApi_info_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_info_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_info_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_info_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Info(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_info_result.prototype = {};
CompanyCertificationApi_info_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Info();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_info_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_info_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_downloadContract_args = function(args) {
  this.auth = null;
  this.id = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
CompanyCertificationApi_downloadContract_args.prototype = {};
CompanyCertificationApi_downloadContract_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_downloadContract_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_downloadContract_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_downloadContract_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.DocumentContent(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyCertificationApi_downloadContract_result.prototype = {};
CompanyCertificationApi_downloadContract_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.DocumentContent();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_downloadContract_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_downloadContract_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_addPolicy_args = function(args) {
  this.auth = null;
  this.addPolicyRequest = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.addPolicyRequest !== undefined && args.addPolicyRequest !== null) {
      this.addPolicyRequest = new ttypes.AddPolicyRequest(args.addPolicyRequest);
    }
  }
};
CompanyCertificationApi_addPolicy_args.prototype = {};
CompanyCertificationApi_addPolicy_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.addPolicyRequest = new ttypes.AddPolicyRequest();
        this.addPolicyRequest.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_addPolicy_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_addPolicy_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.addPolicyRequest !== null && this.addPolicyRequest !== undefined) {
    output.writeFieldBegin('addPolicyRequest', Thrift.Type.STRUCT, 2);
    this.addPolicyRequest.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_addPolicy_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
CompanyCertificationApi_addPolicy_result.prototype = {};
CompanyCertificationApi_addPolicy_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_addPolicy_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_addPolicy_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_pause_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.PauseRequest(args.data);
    }
  }
};
CompanyCertificationApi_pause_args.prototype = {};
CompanyCertificationApi_pause_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.PauseRequest();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_pause_args.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_pause_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApi_pause_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.validationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
  }
};
CompanyCertificationApi_pause_result.prototype = {};
CompanyCertificationApi_pause_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyCertificationApi_pause_result.prototype.write = function(output) {
  output.writeStructBegin('CompanyCertificationApi_pause_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 3);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyCertificationApiClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
CompanyCertificationApiClient.prototype = {};
CompanyCertificationApiClient.prototype.seqid = function() { return this._seqid; };
CompanyCertificationApiClient.prototype.new_seqid = function() { return this._seqid += 1; };

CompanyCertificationApiClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

CompanyCertificationApiClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  var args = new CompanyCertificationApi_health_args();
  try {
    output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};

CompanyCertificationApiClient.prototype.tryVerify = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_tryVerify(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_tryVerify(auth, data);
  }
};

CompanyCertificationApiClient.prototype.send_tryVerify = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyCertificationApi_tryVerify_args(params);
  try {
    output.writeMessageBegin('tryVerify', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_tryVerify = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_tryVerify_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('tryVerify failed: unknown result');
};

CompanyCertificationApiClient.prototype.upload = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_upload(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_upload(auth, data);
  }
};

CompanyCertificationApiClient.prototype.send_upload = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyCertificationApi_upload_args(params);
  try {
    output.writeMessageBegin('upload', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_upload = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_upload_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('upload failed: unknown result');
};

CompanyCertificationApiClient.prototype.verify = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_verify(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_verify(auth, data);
  }
};

CompanyCertificationApiClient.prototype.send_verify = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyCertificationApi_verify_args(params);
  try {
    output.writeMessageBegin('verify', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_verify = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_verify_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('verify failed: unknown result');
};

CompanyCertificationApiClient.prototype.validate = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_validate(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_validate(auth, data);
  }
};

CompanyCertificationApiClient.prototype.send_validate = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyCertificationApi_validate_args(params);
  try {
    output.writeMessageBegin('validate', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_validate = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_validate_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('validate failed: unknown result');
};

CompanyCertificationApiClient.prototype.reject = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_reject(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_reject(auth, data);
  }
};

CompanyCertificationApiClient.prototype.send_reject = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyCertificationApi_reject_args(params);
  try {
    output.writeMessageBegin('reject', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_reject = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_reject_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('reject failed: unknown result');
};

CompanyCertificationApiClient.prototype.info = function(auth, id, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_info(auth, id);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_info(auth, id);
  }
};

CompanyCertificationApiClient.prototype.send_info = function(auth, id) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    id: id
  };
  var args = new CompanyCertificationApi_info_args(params);
  try {
    output.writeMessageBegin('info', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_info = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_info_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('info failed: unknown result');
};

CompanyCertificationApiClient.prototype.downloadContract = function(auth, id, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_downloadContract(auth, id);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_downloadContract(auth, id);
  }
};

CompanyCertificationApiClient.prototype.send_downloadContract = function(auth, id) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    id: id
  };
  var args = new CompanyCertificationApi_downloadContract_args(params);
  try {
    output.writeMessageBegin('downloadContract', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_downloadContract = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_downloadContract_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('downloadContract failed: unknown result');
};

CompanyCertificationApiClient.prototype.addPolicy = function(auth, addPolicyRequest, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_addPolicy(auth, addPolicyRequest);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_addPolicy(auth, addPolicyRequest);
  }
};

CompanyCertificationApiClient.prototype.send_addPolicy = function(auth, addPolicyRequest) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    addPolicyRequest: addPolicyRequest
  };
  var args = new CompanyCertificationApi_addPolicy_args(params);
  try {
    output.writeMessageBegin('addPolicy', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_addPolicy = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_addPolicy_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('addPolicy failed: unknown result');
};

CompanyCertificationApiClient.prototype.pause = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_pause(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_pause(auth, data);
  }
};

CompanyCertificationApiClient.prototype.send_pause = function(auth, data) {
  var output = new this.pClass(this.output);
  var params = {
    auth: auth,
    data: data
  };
  var args = new CompanyCertificationApi_pause_args(params);
  try {
    output.writeMessageBegin('pause', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CompanyCertificationApiClient.prototype.recv_pause = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CompanyCertificationApi_pause_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('pause failed: unknown result');
};
var CompanyCertificationApiProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
CompanyCertificationApiProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
CompanyCertificationApiProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new CompanyCertificationApi_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health.bind(this._handler)
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_health_result({success: result});
      output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
      output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new CompanyCertificationApi_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_tryVerify = function(seqid, input, output) {
  var args = new CompanyCertificationApi_tryVerify_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.tryVerify.length === 2) {
    Q.fcall(this._handler.tryVerify.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_tryVerify_result({success: result});
      output.writeMessageBegin("tryVerify", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_tryVerify_result(err);
        output.writeMessageBegin("tryVerify", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("tryVerify", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.tryVerify(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_tryVerify_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("tryVerify", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("tryVerify", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_upload = function(seqid, input, output) {
  var args = new CompanyCertificationApi_upload_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.upload.length === 2) {
    Q.fcall(this._handler.upload.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_upload_result({success: result});
      output.writeMessageBegin("upload", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_upload_result(err);
        output.writeMessageBegin("upload", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("upload", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.upload(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_upload_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("upload", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("upload", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_verify = function(seqid, input, output) {
  var args = new CompanyCertificationApi_verify_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.verify.length === 2) {
    Q.fcall(this._handler.verify.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_verify_result({success: result});
      output.writeMessageBegin("verify", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_verify_result(err);
        output.writeMessageBegin("verify", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("verify", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.verify(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_verify_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("verify", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("verify", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_validate = function(seqid, input, output) {
  var args = new CompanyCertificationApi_validate_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.validate.length === 2) {
    Q.fcall(this._handler.validate.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_validate_result({success: result});
      output.writeMessageBegin("validate", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_validate_result(err);
        output.writeMessageBegin("validate", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("validate", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.validate(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_validate_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("validate", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("validate", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_reject = function(seqid, input, output) {
  var args = new CompanyCertificationApi_reject_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.reject.length === 2) {
    Q.fcall(this._handler.reject.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_reject_result({success: result});
      output.writeMessageBegin("reject", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_reject_result(err);
        output.writeMessageBegin("reject", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("reject", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.reject(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_reject_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("reject", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("reject", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_info = function(seqid, input, output) {
  var args = new CompanyCertificationApi_info_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.info.length === 2) {
    Q.fcall(this._handler.info.bind(this._handler),
      args.auth,
      args.id
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_info_result({success: result});
      output.writeMessageBegin("info", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_info_result(err);
        output.writeMessageBegin("info", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("info", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.info(args.auth, args.id, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_info_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("info", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("info", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_downloadContract = function(seqid, input, output) {
  var args = new CompanyCertificationApi_downloadContract_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.downloadContract.length === 2) {
    Q.fcall(this._handler.downloadContract.bind(this._handler),
      args.auth,
      args.id
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_downloadContract_result({success: result});
      output.writeMessageBegin("downloadContract", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyCertificationApi_downloadContract_result(err);
        output.writeMessageBegin("downloadContract", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("downloadContract", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.downloadContract(args.auth, args.id, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyCertificationApi_downloadContract_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("downloadContract", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("downloadContract", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_addPolicy = function(seqid, input, output) {
  var args = new CompanyCertificationApi_addPolicy_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.addPolicy.length === 2) {
    Q.fcall(this._handler.addPolicy.bind(this._handler),
      args.auth,
      args.addPolicyRequest
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_addPolicy_result({success: result});
      output.writeMessageBegin("addPolicy", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result = new CompanyCertificationApi_addPolicy_result(err);
        output.writeMessageBegin("addPolicy", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("addPolicy", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.addPolicy(args.auth, args.addPolicyRequest, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new CompanyCertificationApi_addPolicy_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("addPolicy", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("addPolicy", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CompanyCertificationApiProcessor.prototype.process_pause = function(seqid, input, output) {
  var args = new CompanyCertificationApi_pause_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.pause.length === 2) {
    Q.fcall(this._handler.pause.bind(this._handler),
      args.auth,
      args.data
    ).then(function(result) {
      var result_obj = new CompanyCertificationApi_pause_result({success: result});
      output.writeMessageBegin("pause", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result = new CompanyCertificationApi_pause_result(err);
        output.writeMessageBegin("pause", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("pause", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.pause(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.ValidationException) {
        result_obj = new CompanyCertificationApi_pause_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("pause", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("pause", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
