import { getRestCall } from "../../utils/rest-api-call";
import { METERING_READ_API_URL, METERING_PACKAGE_READ_API_URL } from "../../config";

export const GET_METERING_READ_COMPANIES_START = "GET_METERING_READ_COMPANIES_START";
export const GET_METERING_READ_COMPANIES_SUCCESS = "GET_METERING_READ_COMPANIES_SUCCESS";
export const GET_METERING_READ_COMPANIES_ERROR = "GET_METERING_READ_COMPANIES_ERROR";

export const getCompanies = (auth, cf) => async dispatch => {
    dispatch({
        type: GET_METERING_READ_COMPANIES_START
    });

    try {
        const result = await getRestCall(
            `${METERING_READ_API_URL}/companies/${cf}`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_METERING_READ_COMPANIES_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_METERING_READ_COMPANIES_ERROR,
            error: e
        });
    }
};

export const GET_METERING_READ_COMPANIES_PACKAGES_START = "GET_METERING_READ_COMPANIES_PACKAGES_START";
export const GET_METERING_READ_COMPANIES_PACKAGES_SUCCESS = "GET_METERING_READ_COMPANIES_PACKAGES_SUCCESS";
export const GET_METERING_READ_COMPANIES_PACKAGES_ERROR = "GET_METERING_READ_COMPANIES_PACKAGES_ERROR";

export const getCompanyPackages = (auth, id) => async dispatch => {
    dispatch({
        type: GET_METERING_READ_COMPANIES_PACKAGES_START
    });

    try {
        const result = await getRestCall(
            `${METERING_READ_API_URL}/companies/${id}/packages`,
            auth,
            null,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_METERING_READ_COMPANIES_PACKAGES_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_METERING_READ_COMPANIES_PACKAGES_ERROR,
            error: e
        });
    }
};
export const GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_START = "GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_START";
export const GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_SUCCESS = "GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_SUCCESS";
export const GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_ERROR = "GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_ERROR";

export const getPackageSubscriptions = (auth, uuid, activeOnly) => async dispatch => {
    dispatch({
        type: GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_START
    });

    let urlParams = {
        activeOnly
    };
    try {
        const result = await getRestCall(
            `${METERING_PACKAGE_READ_API_URL}/company/${uuid}/packages`,
            auth,
            urlParams,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_SUCCESS,
            payload: result
        });
    } catch (e) {
        dispatch({
            type: GET_METERING_READ_PACKAGE_SUBSCRIPTIONS_ERROR,
            error: e
        });
    }
};
