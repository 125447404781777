import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { resetPassword } from "../../actions/user/write";
import ResetPasswordForm from "../../components/login/reset-password/form";
import { LOGO_COLOR } from "../../config";

export class ResetPasswordView extends Component {
    static propTypes = {
        admin: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        resetPassword: PropTypes.func.isRequired
    };

    handleSubmit(e) {
        const { resetPassword, match } = this.props;

        resetPassword(decodeURIComponent(match.params.userId), match.params.token, e.password);
    }

    render() {
        const { admin } = this.props;
        const resetIsOk = admin.password.resetPassword.status.ended && !admin.password.resetPassword.status.error;

        return resetIsOk ? (
            <Redirect to="/resetPassword/success" />
        ) : (
            <div className="loginBackground">
                <div className="Login">
                    <Row justify="center" type="flex">
                        <Col>
                            <div className="Login-header">
                                <img src={LOGO_COLOR} className="Login-header-img" alt="logo" />
                            </div>
                        </Col>
                    </Row>
                    <Row justify="center" type="flex">
                        <div style={{ width: "80%" }}>
                            <ResetPasswordForm
                                errorMsg={" "}
                                onSubmit={e => this.handleSubmit(e)}
                                title={<FormattedMessage id={"c-reset-password.title"} />}
                            />
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        admin: state.admin
    };
}

export default connect(
    mapStateToProps,
    { resetPassword }
)(ResetPasswordView);
