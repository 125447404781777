import React from "react";
import { Route } from "react-router-dom";
import { RoutesContainer } from "../styled";

import DashboardView from "../views/dashboard";
import CervedView from "../views/dashboard/cerved";
import ContractView from "../views/contract/index";
import InvoiceInfoView from "../views/invoice-info";
import BankLinkInfoView from "../views/bank-link-info";
import UserView from "../views/user";
import UserSearchView from "../views/user-search";
import ItemsView from "../views/items";
import ItemsCreateView from "../views/company/create";
import CompanyView from "../views/company";
import CertificationsView from "../views/certifications";
import CertificationListView from "../views/certifications/cerfications-list";
import CertificationProcessListView from "../views/certifications/certifications-list-process";
import CctUploadedView from "../views/certifications/cct-provisioning/uploaded";
import CertificationCctListView from "../views/certifications/cct-provisioning/list";
import AdmUsersView from "../views/control-pannel-users-backoffice";
import AdmToolsView from "../views/control-pannel-tools";
import TemplatesView from "../views/control-pannel-notification";
import SentinelView from "../views/control-pannel-sentinel";
import AppNameView from "../views/control-pannel-app-name";
import CctAutoUploadView from "../views/certifications/cct-autoupload";
import SignatureInfoView from "../views/signature-info";
import CctProvisioningView from "../views/cct-provisioning";
import AdmDashboardView from "../views/control-pannel-dashboard";
import BankLinkView from "../views/bank-link";
import BankLinkRuleView from "../views/bank-link-rule";
import BankLinkAssociationView from "../views/bank-link-association";
import BankLinkBankView from "../views/bank-link-bank";
import BankLinkFileTransactionsView from "../views/bank-link-file";
import ReportView from "../views/report";
import ServiceStatusView from "../views/service-status";
import ServiceStatusTSDigitalView from "../views/service-status/ts-digital";
import AssignNsoView from "../views/assign-nso";
import HackerMonitorView from "../views/hacker-monitor";
import TSIHView from "../views/tsih-bo";
import SpidView from "../views/spid";
import SpidList from "../views/spid/list";
import SpidIdView from "../views/spid/spidId";
import NewArchiveRdcDraftListView from "../views/new-archive/list";
import NewArchiveRdcDetailView from "../views/certifications/new-archive/detail";

const Routes = () => {
    return (
        <RoutesContainer>
            <Route exact path="/" component={DashboardView} />
            <Route exact path="/dashboard" component={DashboardView} />
            <Route path="/dashboard/invoice/:hubId" component={InvoiceInfoView} />
            <Route path="/dashboard/sign/:hubId" component={SignatureInfoView} />
            <Route
                exact
                path="/bank-link/bank-link-files/bank-link-rules/:hubId/:description/:abi"
                component={BankLinkRuleView}
            />
            <Route exact path="/dashboard/BANK-LINK/:hubId" component={BankLinkInfoView} />
            <Route exact path="/bank-link/bank-link-rules/:hubId" component={BankLinkRuleView} />
            <Route exact path="/bank-link/bank-link-associations/:hubId" component={BankLinkAssociationView} />
            <Route exact path="/bank-link/bank-link-banks/:hubId" component={BankLinkBankView} />
            <Route exact path="/bank-link/bank-link-files/:hubId" component={BankLinkFileTransactionsView} />
            <Route path="/dashboard/cerved/" component={CervedView} />
            <Route exact path="/control-pannel-dashboard" component={AdmDashboardView} />
            <Route exact path="/control-pannel-users-backoffice" component={AdmUsersView} />
            <Route exact path="/control-pannel-users-backoffice/:userId" component={UserView} />
            <Route exact path="/control-pannel-tools" component={AdmToolsView} />
            <Route path="/control-pannel-notification" component={TemplatesView} />
            <Route path="/control-pannel-sentinel" component={SentinelView} />
            <Route path="/control-pannel-app-name" component={AppNameView} />
            <Route exact path="/item" component={ItemsView} />
            <Route exact path="/item/create" component={ItemsCreateView} />
            <Route path="/item/:companyId" component={CompanyView} />
            <Route exact path="/user" component={UserSearchView} />
            <Route path="/user/:userId" component={UserView} />
            <Route exact path="/certifications" component={CertificationsView} />
            <Route exact path="/report" component={ReportView} />
            <Route exact path="/service-status" component={ServiceStatusView} />
            <Route exact path="/service-status/:type" component={ServiceStatusTSDigitalView} />
            <Route exact path="/cct-provisioning" component={CctProvisioningView} />
            <Route exact path="/bank-link" component={BankLinkView} />
            <Route exact path="/certifications/process" component={CertificationProcessListView} />
            <Route exact path="/certifications/list/:status" component={CertificationListView} />
            <Route exact path="/certifications/contract/:id" component={ContractView} />
            <Route exact path="/nso-riassign" component={AssignNsoView} />
            <Route exact path="/hacker-monitor" component={HackerMonitorView} />
            <Route exact path="/ih-bo" component={TSIHView} />
            <Route exact path="/spid" component={SpidView} />

            <Route
                exact
                path="/cct-provisioning/detail/:statusCct/:produttoreId/:titolareId"
                component={CctUploadedView}
            />
            <Route exact path="/cct-provisioning/list/:status" component={CertificationCctListView} />
            <Route exact path="/cct-provisioning/all/:cfProduttore" component={CertificationCctListView} />

            <Route
                path="/cct-provisioning/autoupload/cfMain=:cfMain&cfAzienda=:cfAzienda&idCoupon=:idCoupon"
                component={CctAutoUploadView}
                exact
            />

            <Route path="/cct-new-archive/list/:status" component={NewArchiveRdcDraftListView} exact />
            <Route path="/cct-new-archive/detail/:produttoreId/:titolareId" component={NewArchiveRdcDetailView} exact />

            <Route exact path="/spid/spid-list" component={SpidList} />
            <Route exact path="/spid/getSpid/:spidId" component={SpidIdView} />
        </RoutesContainer>
    );
};

export default Routes;
