import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { errorBoundary } from "../../components/general/error-boundary";

import { getTemplate, updateTemplate } from "../../actions/notification/templates";
import {
    addToMailingList,
    getMailingList,
    getSentinelGroups,
    deleteFromMailingList,
    notifyToSentinel,
    singleNotifyToSentinel
} from "../../actions/notification/sentinel";
import { restrictToPermits } from "../../components/general/hoc";
import { GroupTemplateCard, TemplateDrawer } from "../../components/control-pannel-notification";
import { Button, Collapse, Icon, Input, message, Modal, Radio, Select, Spin, Tag } from "antd";
import { FormattedMessage } from "react-intl";

import { UnControlled as CodeMirror } from "react-codemirror2";

import { CopyToClip } from "../../styled";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

import {
    ConfirmModalText,
    GroupSelectContainer,
    IconContainer,
    SectionContainer,
    SelectLabel,
    SendButtonContainer,
    SpinnerContainer,
    ViewTitle,
    ViewSection,
    TagContainer
} from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPaperPlane } from "@fortawesome/pro-solid-svg-icons";

require("codemirror/lib/codemirror.css");
require("codemirror/mode/xml/xml.js");
require("./style.css");

const SentinelView = ({
    auth,
    addToMailingList,
    deleteFromMailingList,
    getMailingList,
    getSentinelGroups,
    getTemplate,
    notifyToSentinel,
    notification,
    sentinelGroups,
    singleNotifyToSentinel,
    updateTemplate
}) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [templateSelected, setTemplateSelected] = useState(null);
    const [notificationSelected, setNotificationSelected] = useState(null);
    const [notificationAppCode, setNotificationAppCode] = useState([]);
    const [notificationTemplates, setNotificationTemplates] = useState([]);

    const [isConfirmModalDeleteVisible, setIsConfirmModalDeleteVisible] = useState(false);
    const [deletingNcsId, setDeletingNcsId] = useState({ email: null, ncsId: null });

    const [isConfirmModalAddVisible, setIsConfirmModalAddVisible] = useState(false);
    const [addingEmail, setAddingEmail] = useState(null);

    const [severityValue, setSeverityValue] = useState("");
    const [sentinelNumber, setsentinelNumber] = useState("");
    const [sentinelDescription, setSentinelDesription] = useState("");
    const [sentinelNextUpdate, setSentinelNextUpdate] = useState("");

    const [groupsOptions, setGroupsOptions] = useState([]);
    const [selectedGroupNcsid, setSelectedGroupNcsid] = useState(null);

    const [singleEmailSet, setSingleEmailSend] = useState(null);

    const [isSendConfirmModalVisible, setIsSendConfirmModalVisible] = useState(false);

    useEffect(() => {
        getTemplate(auth.loginAuth, true);
    }, [auth.loginAuth, getTemplate]);

    useEffect(() => {
        getSentinelGroups(auth.loginAuth);
    }, [auth.loginAuth, getSentinelGroups]);

    useEffect(() => {
        setGroupsOptions(
            sentinelGroups
                ? sentinelGroups.map(group => (
                      <Select.Option key={group.name} value={group.ncsId}>
                          {group.name}
                      </Select.Option>
                  ))
                : []
        );
    }, [sentinelGroups, setGroupsOptions]);

    useEffect(() => {
        let appCode = [];
        let notificationTemplates = [];
        if (notification.templates.getTemplate.status.ended) {
            notificationTemplates = notification.templates.getTemplate.payload;
            notification.templates.getTemplate.payload.forEach(notification => {
                let findAppCode = appCode.find(x => x === notification.appCode);
                if (findAppCode == null) {
                    appCode.push(notification.appCode);
                }
            });
        }
        setNotificationTemplates(notificationTemplates);
        setNotificationAppCode(appCode);
    }, [notification.templates.getTemplate]);

    const onSave = async () => {
        if (notificationSelected.updatedDate) {
            delete notificationSelected.updatedDate;
        }
        var result = await updateTemplate(auth.loginAuth, notificationSelected);
        if (result != null && result.appCode) {
            message.success("È stato modificato con successo");
        } else {
            message.error("Non è stato modificato con successo");
            getTemplate(auth.loginAuth, true);
        }
    };

    useEffect(() => {
        if (selectedGroupNcsid && selectedGroupNcsid !== "single") {
            getMailingList(auth.loginAuth, selectedGroupNcsid);
        }
    }, [auth.loginAuth, getMailingList, selectedGroupNcsid]);

    useEffect(() => {
        if (
            (!notification.sentinel.addToMailingList.status.started &&
                notification.sentinel.addToMailingList.status.ended) ||
            (!notification.sentinel.deleteFromMailingList.status.started &&
                notification.sentinel.deleteFromMailingList.status.ended)
        ) {
            getMailingList(auth.loginAuth, selectedGroupNcsid);
        }
    }, [
        notification.sentinel.addToMailingList.status.started,
        notification.sentinel.addToMailingList.status.ended,
        notification.sentinel.deleteFromMailingList.status.started,
        notification.sentinel.deleteFromMailingList.status.ended,
        auth.loginAuth,
        getMailingList,
        selectedGroupNcsid
    ]);

    // handlers per modale di cancellazione utente da mailing list
    const handleModalDeleteCancel = () => {
        setIsConfirmModalDeleteVisible(false);
        setDeletingNcsId({});
    };
    const handleModalDeleteConfirm = () => {
        deleteFromMailingList(auth.loginAuth, selectedGroupNcsid, deletingNcsId.ncsId);
    };
    useEffect(() => {
        if (
            !notification.sentinel.deleteFromMailingList.status.started &&
            notification.sentinel.deleteFromMailingList.status.ended
        ) {
            setIsConfirmModalDeleteVisible(false);
            setDeletingNcsId({});
        }
    }, [
        notification.sentinel.deleteFromMailingList.status.started,
        notification.sentinel.deleteFromMailingList.status.ended
    ]);

    // handlers per modale di aggiunta utente a mailing list
    const handleModalAddCancel = () => {
        setIsConfirmModalAddVisible(false);
        setAddingEmail(null);
    };
    const handleModalAddConfirm = () => {
        addToMailingList(auth.loginAuth, selectedGroupNcsid, addingEmail);
    };
    useEffect(() => {
        if (
            !notification.sentinel.addToMailingList.status.started &&
            notification.sentinel.addToMailingList.status.ended
        ) {
            setIsConfirmModalAddVisible(false);
            setAddingEmail(null);
        }
    }, [notification.sentinel.addToMailingList.status.started, notification.sentinel.addToMailingList.status.ended]);

    // handlers per modale di aggiunta utente a mailing list
    const handleSendConfirmModalCancel = () => {
        setIsSendConfirmModalVisible(false);
    };
    const handleSendConfirmModalConfirm = () => {
        handleNotify();
        setIsSendConfirmModalVisible(false);
    };

    const handleGroupSelectChange = value => {
        setSelectedGroupNcsid(value);
    };

    const handleNotify = () => {
        if (selectedGroupNcsid === "single") {
            singleNotifyToSentinel(auth.loginAuth, singleEmailSet, {
                severity: severityValue.toUpperCase(),
                sentinelnumber: sentinelNumber,
                description: sentinelDescription,
                nextupdate: sentinelNextUpdate,
                severityinformational: severityValue === "informational" ? true : undefined,
                severitylow: severityValue === "low" ? true : undefined,
                severitymedium: severityValue === "medium" ? true : undefined,
                severityhigh: severityValue === "high" ? true : undefined
            });
        } else {
            notifyToSentinel(auth.loginAuth, selectedGroupNcsid, {
                severity: severityValue.toUpperCase(),
                sentinelnumber: sentinelNumber,
                description: sentinelDescription,
                nextupdate: sentinelNextUpdate,
                severityinformational: severityValue === "informational" ? true : undefined,
                severitylow: severityValue === "low" ? true : undefined,
                severitymedium: severityValue === "medium" ? true : undefined,
                severityhigh: severityValue === "high" ? true : undefined
            });
        }
    };

    let divDestinatari = null;

    if (selectedGroupNcsid !== null) {
        if (selectedGroupNcsid === "single") {
            divDestinatari = (
                <Input
                    placeholder="email@email.it"
                    onChange={e => setSingleEmailSend(e.target.value)}
                    style={{ width: 350 }}
                />
            );
        } else {
            divDestinatari = (
                <>
                    <TagContainer>
                        {!notification.sentinel.getMailingList.status ||
                        notification.sentinel.getMailingList.status.started ? (
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
                        ) : (
                            notification.sentinel.getMailingList.mailingList &&
                            notification.sentinel.getMailingList.mailingList.map &&
                            notification.sentinel.getMailingList.mailingList.map(contact => (
                                <Tag
                                    key={contact.value}
                                    closable
                                    onClose={e => {
                                        e.preventDefault();
                                        setIsConfirmModalDeleteVisible(true);
                                        setDeletingNcsId({ email: contact.value, ncsId: contact.ncsId });
                                    }}
                                >
                                    {contact.value}
                                </Tag>
                            ))
                        )}
                    </TagContainer>
                    <Button type="secondary" onClick={() => setIsConfirmModalAddVisible(true)}>
                        <IconContainer>
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </IconContainer>{" "}
                        <FormattedMessage id="c-sentinel.add-user" />
                    </Button>
                    <CopyToClip
                        text={
                            notification.sentinel.getMailingList.mailingList &&
                            notification.sentinel.getMailingList.mailingList.map &&
                            notification.sentinel.getMailingList.mailingList.map(contact => contact.value).join(";")
                        }
                        onCopy={() => {
                            message.success("Mailing list copiata in clipboard");
                        }}
                    >
                        <Button type="secondary">
                            <IconContainer>
                                <FontAwesomeIcon icon={faCopy} />
                            </IconContainer>{" "}
                            <FormattedMessage id="c-sentinel.copy-users" />
                        </Button>
                    </CopyToClip>
                </>
            );
        }
    }

    return !notification.templates.getTemplate.status.started ? (
        <>
            <div>
                <ViewTitle>
                    <FormattedMessage id="c-sentinel.sentinelTitle" />
                </ViewTitle>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-sentinel.configuredTemplates" />
                    </ViewSection>

                    {notificationAppCode.length > 0 ? (
                        notificationAppCode.map((appCode, index) => (
                            <GroupTemplateCard
                                key={index}
                                notifications={notificationTemplates.filter(x => x.appCode === appCode)}
                                onChannelClick={(template, index) => {
                                    setTemplateSelected(template);
                                    setNotificationSelected(
                                        notificationTemplates.filter(x => x.appCode === appCode)[index]
                                    );
                                    setPreviewVisible(true);
                                }}
                                appCode={appCode}
                            />
                        ))
                    ) : (
                        <Collapse
                            defaultActiveKey={["1"]}
                            expandIconPosition={"right"}
                            style={{ marginBottom: "20px" }}
                        >
                            <Collapse.Panel header={"NCS"} key="1">
                                <p style={{ textAlign: "center" }}>Nessun Dato</p>
                            </Collapse.Panel>
                        </Collapse>
                    )}

                    {templateSelected != null ? (
                        <TemplateDrawer
                            visible={previewVisible}
                            template={templateSelected}
                            onClose={() => {
                                setPreviewVisible(false);
                                setTemplateSelected(null);
                                setNotificationSelected(null);
                            }}
                            onSave={() => onSave()}
                        />
                    ) : null}
                </SectionContainer>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-sentinel.sendTo" />
                    </ViewSection>
                    <br />
                    <GroupSelectContainer>
                        <SelectLabel>
                            <FormattedMessage id="c-sentinel.group-select" />
                        </SelectLabel>
                        <br />
                        <Select
                            placeholder="Seleziona destinatari"
                            style={{ width: 200 }}
                            onChange={value => handleGroupSelectChange(value)}
                        >
                            {groupsOptions}
                            <Select.Option key={3} value="single">
                                <FormattedMessage id="c-sentinel.single-email" />
                            </Select.Option>
                        </Select>
                    </GroupSelectContainer>
                    <br />
                    {divDestinatari}
                </SectionContainer>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-sentinel.sentinelNumber" />
                    </ViewSection>
                    <br />
                    <Input
                        placeholder="AAAA - XXX"
                        onChange={e => setsentinelNumber(e.target.value)}
                        style={{ width: 120 }}
                    />
                </SectionContainer>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-sentinel.severity" />
                    </ViewSection>
                    <br />
                    <Radio.Group onChange={e => setSeverityValue(e.target.value)} value={severityValue}>
                        <Radio key={1} value={"informational"}>
                            <FormattedMessage id="c-sentinel.informational" />
                        </Radio>
                        <Radio key={2} value={"low"}>
                            <FormattedMessage id="c-sentinel.low" />
                        </Radio>
                        <Radio key={3} value={"medium"}>
                            <FormattedMessage id="c-sentinel.medium" />
                        </Radio>
                        <Radio key={4} value={"high"}>
                            <FormattedMessage id="c-sentinel.high" />
                        </Radio>
                    </Radio.Group>
                </SectionContainer>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-sentinel.description" />
                    </ViewSection>
                    <CodeMirror
                        value={sentinelDescription.replace(/>/gi, ">\n")}
                        options={{
                            mode: "xml",
                            theme: "material",
                            lineNumbers: true
                        }}
                        style={{ height: "50px" }}
                        autoCursor={false}
                        onChange={e => {
                            setSentinelDesription(e.getValue().replace(/>\n/gi, ">"));
                        }}
                    />
                </SectionContainer>
                <br />
                <SectionContainer>
                    <ViewSection>
                        <FormattedMessage id="c-sentinel.nextupdate" />
                    </ViewSection>
                    <br />
                    <Input
                        placeholder="DD/MM/AAAA ore hh:mm"
                        onChange={e => setSentinelNextUpdate(e.target.value)}
                        style={{ width: 200 }}
                    />
                </SectionContainer>
                <br />
                <br />
                <SendButtonContainer>
                    <Button
                        type="primary"
                        loading={
                            notification.sentinel.notifyToSentinel.status.started ||
                            notification.sentinel.singleNotifyToSentinel.status.started
                        }
                        style={{ width: 200 }}
                        onClick={() => setIsSendConfirmModalVisible(true)}
                    >
                        <IconContainer>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </IconContainer>
                        <FormattedMessage id="c-sentinel.send-email" />
                    </Button>
                </SendButtonContainer>
                <br />
            </div>
            <Modal
                title={<FormattedMessage id="c-sentinel.send-confirm-title" />}
                visible={isSendConfirmModalVisible}
                onOk={handleSendConfirmModalConfirm}
                onCancel={handleSendConfirmModalCancel}
            >
                {selectedGroupNcsid === "single" ? (
                    <FormattedMessage
                        id="c-sentinel.send-confirm-single-text"
                        values={{
                            recipientEmail: singleEmailSet
                        }}
                    />
                ) : (
                    <ConfirmModalText>
                        <FormattedMessage
                            id="c-sentinel.send-confirm-group-text"
                            values={{
                                groupEmails: notification.sentinel.getMailingList.mailingList
                                    ? notification.sentinel.getMailingList.mailingList.map(contact => contact.value)
                                    : ""
                            }}
                        />
                    </ConfirmModalText>
                )}
            </Modal>
            <Modal
                title={<FormattedMessage id="c-sentinel.delete-modal-title" />}
                visible={isConfirmModalDeleteVisible}
                onCancel={handleModalDeleteCancel}
                footer={[
                    <Button key="0" type="secondary" onClick={handleModalDeleteCancel}>
                        <FormattedMessage id="general.cancel" />
                    </Button>,
                    <Button
                        key="1"
                        type="primary"
                        onClick={handleModalDeleteConfirm}
                        loading={notification.sentinel.deleteFromMailingList.status.started}
                    >
                        <FormattedMessage id="general.confirm" />
                    </Button>
                ]}
            >
                <FormattedMessage
                    id="c-sentinel.delete-modal-text"
                    values={{
                        email: deletingNcsId && deletingNcsId.email,
                        ncsId: deletingNcsId && deletingNcsId.ncsId
                    }}
                />
            </Modal>
            <Modal
                title={<FormattedMessage id="c-sentinel.add-modal-title" />}
                visible={isConfirmModalAddVisible}
                onCancel={handleModalAddCancel}
                footer={[
                    <Button key="0" type="secondary" onClick={handleModalAddCancel}>
                        <FormattedMessage id="general.cancel" />
                    </Button>,
                    <Button
                        key="1"
                        type="primary"
                        onClick={handleModalAddConfirm}
                        loading={notification.sentinel.addToMailingList.status.started}
                    >
                        <FormattedMessage id="general.confirm" />
                    </Button>
                ]}
            >
                <FormattedMessage id="c-sentinel.add-modal-text" />
                <br />
                <Input
                    placeholder="ind@email.com oppure ind@gmail,ind2@mail,ind3@gmail."
                    onChange={e => setAddingEmail(e.target.value)}
                    style={{ width: 350 }}
                />
            </Modal>
        </>
    ) : (
        <SpinnerContainer>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
        </SpinnerContainer>
    );
};

SentinelView.propTypes = {
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object,
    getTemplate: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    notification: PropTypes.object
};

const mapStateToProps = state => ({
    admin: state.admin,
    auth: state.auth,
    contract: state.contract,
    notification: state.notification,
    sentinelGroups: state.notification.sentinel.getSentinelGroups.groups
});

const actions = {
    addToMailingList,
    deleteFromMailingList,
    getMailingList,
    getSentinelGroups,
    getTemplate,
    notifyToSentinel,
    singleNotifyToSentinel,
    updateTemplate
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary,
    restrictToPermits(false, "c-sentinel-view")
);

export default composedHoc(SentinelView);
