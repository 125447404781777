import axios from "axios";
import { OBLIGATIONS_TAX_URL } from "../config.js";
import { isNil } from "ramda";
// import { getRestCall } from "../utils/rest-api-call";

export const GET_OBLIGATIONS_TAX_START = "GET_OBLIGATIONS_TAX_START";
export const GET_OBLIGATIONS_TAX_ERROR = "GET_OBLIGATIONS_TAX_ERROR";
export const GET_OBLIGATIONS_TAX_SUCCESS = "GET_OBLIGATIONS_TAX_SUCCESS";

export function getObligationsTax(auth, filters, page, size = 10) {
    var searchLink = OBLIGATIONS_TAX_URL + "/files";
    page = page ? page : 0;

    var semicolon = "";
    searchLink += "?page=" + page + "&size=" + size + "&sort=timestamp,desc";

    if (
        !isNil(filters.transmitter) ||
        !isNil(filters.hubId) ||
        !isNil(filters.cf) ||
        !isNil(filters.tipo) ||
        (!isNil(filters.periodo) && filters.periodo !== "0") ||
        (!isNil(filters.anno) && filters.anno !== "0") ||
        (!isNil(filters.stato) && filters.stato !== "0")
    )
        if (!isNil(filters.transmitter)) {
            searchLink += semicolon + "&transmitter=" + encodeURIComponent(filters.transmitter);
        }
    if (!isNil(filters.hubId)) {
        searchLink += semicolon + "&hubId=" + filters.hubId;
    }
    if (!isNil(filters.cf)) {
        searchLink += semicolon + "&codiceFiscale=" + filters.cf;
    }
    if (!isNil(filters.tipo)) {
        searchLink += semicolon + "&tipo=" + filters.tipo;
    }
    if (!isNil(filters.periodo) && filters.periodo !== 0) {
        searchLink += semicolon + "&periodo=" + filters.periodo;
    }
    if (!isNil(filters.anno) && filters.anno !== 0) {
        searchLink += semicolon + "&anno=" + filters.anno;
    }
    if (!isNil(filters.stato) && filters.stato !== "0") {
        searchLink += semicolon + "&stato=" + filters.stato;
    }
    let request = {
        method: "get",
        url: searchLink,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Token: auth.securityToken,
            Id: auth.id,
            "X-App-Name": "ADEMPIMENTI_AGYO"
        }
    };
    return function(dispatch) {
        dispatch({
            type: GET_OBLIGATIONS_TAX_START,
            request: request
        });

        axios(request)
            .then(result => {
                if (result.status === 200) {
                    let results = [];
                    let invioFileList = result.data._embedded ? result.data._embedded.invioFileList : [];
                    let requests = invioFileList.map(item => {
                        return new Promise(resolve => {
                            results.push(item);
                            resolve(item);
                        });
                    });
                    Promise.all(requests).then(() => {
                        dispatch({
                            type: GET_OBLIGATIONS_TAX_SUCCESS,
                            payload: {
                                invioFileList: results,
                                page: result.data.page
                            }
                        });
                    });
                } else {
                    dispatch({
                        type: GET_OBLIGATIONS_TAX_ERROR,
                        error: result.status === 200 ? "Non sono presenti richieste per " + filters.transmitter : result
                    });
                }
            })
            .catch(response => {
                dispatch({
                    type: GET_OBLIGATIONS_TAX_ERROR,
                    error: response
                });
            });
    };
}

/*export function getObligationsTax(auth, filter, page, size=10) {
    return async dispatch => {
        const urlParams = {
            ...filter,
            page: page,
            size: size
        };
        dispatch({
            type: GET_OBLIGATIONS_TAX_START,
            request: urlParams
        });

        try {
            const result = await getRestCall(
                `${OBLIGATIONS_TAX_URL}/files`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_OBLIGATIONS_TAX_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_OBLIGATIONS_TAX_ERROR,
                error: e
            });
        }
    };
}*/

export const GET_OBLIGATIONS_TAX_RESET = "GET_OBLIGATIONS_TAX_RESET";
export function resetObligationsTax() {
    return dispatch => {
        dispatch({
            type: GET_OBLIGATIONS_TAX_RESET
        });
    };
}

export const DOCUMENT_DOWNLOAD_START = "DOCUMENT_DOWNLOAD_START";
export const DOCUMENT_DOWNLOAD_ERROR = "DOCUMENT_DOWNLOAD_ERROR";
export const DOCUMENT_DOWNLOAD_SUCCESS = "DOCUMENT_DOWNLOAD_SUCCESS";
export const DOCUMENT_VIEW_START = "DOCUMENT_VIEW_START";
export const DOCUMENT_VIEW_ERROR = "DOCUMENT_VIEW_ERROR";
export const DOCUMENT_VIEW_SUCCESS = "DOCUMENT_VIEW_SUCCESS";

export function obligationsTaxDownload(link, id, token, fileName, type, hubId) {
    let request = {
        method: "post",
        url: OBLIGATIONS_TAX_URL + "/downloadNotification",

        headers: {
            "Content-Type": "application/json",
            Accept: "application/octet-stream",
            "X-App-Name": "ADEMPIMENTI_AGYO"
        },
        data: {
            id: id,
            token: token,
            storageRef: link,
            hubId: hubId
        }
    };
    return function(dispatch) {
        switch (type) {
            case "download":
                dispatch({
                    type: DOCUMENT_DOWNLOAD_START,
                    request: request
                });

                axios(request)
                    .then(result => {
                        if (result.status === 200) {
                            var byteCharacters = atob(result.data);
                            var byteNumbers = new Array(byteCharacters.length);
                            for (var i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            var byteArray = new Uint8Array(byteNumbers);

                            var blob = new Blob([byteArray], {
                                type: "application/octet-stream"
                            });

                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveBlob(blob, fileName);
                            } else {
                                const data = window.URL.createObjectURL(blob);
                                var element = document.createElement("a");
                                element.href = data;
                                element.download = fileName;
                                element.click();
                            }
                            dispatch({
                                type: DOCUMENT_DOWNLOAD_SUCCESS,
                                payload: result
                            });
                        } else {
                            dispatch({
                                type: DOCUMENT_DOWNLOAD_ERROR,
                                error: result
                            });
                        }
                    })
                    .catch(response => {
                        dispatch({
                            type: DOCUMENT_DOWNLOAD_ERROR,
                            error: response
                        });
                    });
                break;

            case "preview":
                dispatch({
                    type: DOCUMENT_VIEW_START
                });
                axios(request)
                    .then(result => {
                        if (result.status === 200) {
                            dispatch({
                                type: DOCUMENT_VIEW_SUCCESS,
                                payload: result.data
                            });
                        } else {
                            dispatch({
                                type: DOCUMENT_VIEW_ERROR,
                                error: result
                            });
                        }
                    })
                    .catch(response => {
                        dispatch({
                            type: DOCUMENT_VIEW_ERROR,
                            error: response
                        });
                    });
                break;
            default:
        }
    };
}
