import * as config from "../../../config";
import { getRestCall } from "../../../utils/rest-api-call";

export const GET_TS_PAY_CONFIG_START = "GET_TS_PAY_CONFIG_START";
export const GET_TS_PAY_CONFIG_SUCCESS = "GET_TS_PAY_CONFIG_SUCCESS";
export const GET_TS_PAY_CONFIG_ERROR = "GET_TS_PAY_CONFIG_ERROR";

export function getTsPayConfig(auth, itemId) {
    return async dispatch => {
        try {
            dispatch({ type: GET_TS_PAY_CONFIG_START, payload: { itemId } });

            const response = await getRestCall(
                `${config.TS_PAY_CONFIG_READ_API_URL}/config/${itemId}`,
                auth,
                null,
                dispatch,
                auth.refreshToken
            );

            dispatch({
                type: GET_TS_PAY_CONFIG_SUCCESS,
                payload: response
            });
            return response;
        } catch (err) {
            dispatch({ type: GET_TS_PAY_CONFIG_ERROR, error: err });
        }
    };
}
