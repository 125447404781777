import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPen,
    faFileUpload,
    faFileContract,
    faExclamationTriangle,
    faCloudUploadAlt,
    faSpinner
} from "@fortawesome/pro-regular-svg-icons";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";

import { certificationRejectMessages } from "../utils/constant";

export function translateClassifier(classifier) {
    switch (classifier) {
        case "COMPANY":
            return "Azienda";
        case "STUDIO":
            return "Studio";
        case "OFFICE":
            return "Ufficio";
        case "INTERMEDIARY":
            return "Intermediario";
        case "BUILDING":
            return "Condominio";
        case "PERSON":
            return "Persona";
        default:
            return classifier;
    }
}

export function translateTaxRegion(taxRegion) {
    switch (taxRegion) {
        case "IT":
            return "Italia";
        case "CH":
            return "Svizzera";
        default:
            return taxRegion;
    }
}

export function translateNcsEventsCode(event) {
    switch (event) {
        case "delivered":
            return "Mail consegnata";
        case "queued":
            return "Mail in coda";
        case "forwarded":
            return "Mail inoltrata";
        case "failed":
            return "Mail non recapitata";
        default:
            return event;
    }
}

export function translateProvisioningSource(event) {
    switch (event) {
        case "METERING":
            return "Avviata solo sottoscrizione";
        case "RDC_SMART":
            return "RDC Smart";
        case "RDC_MANUAL":
            return "RDC Manuale";
        default:
            return event;
    }
}

export function translateServiceCategory(serviceCategory) {
    switch (serviceCategory) {
        case "EIP":
            return "Fatturazione";
        case "TEL":
            return "Telematici";
        case "ARC":
            return "Archiviazione";
        case "DBX":
            return "DigitalBox";
        case "PRIVACY":
            return "Privacy";
        case "EXP":
            return "NoteSpese";
        case "PPL":
            return "PeopleApp";
        case "SIG":
            return "Firma";
        default:
            return serviceCategory;
    }
}
export function translateServiceName(serviceName) {
    switch (serviceName) {
        case "A-FISCALI-IVA-FLOW":
            return "Adempimenti";
        case "B2B-FLOW":
            return "B2B";
        case "B2B-PASSIVE-FLOW":
            return "eRicevi";
        case "CBCR-FLOW":
            return "Country-by-Country (CBCR)";
        case "CCT-FLOW":
            return "Conservazione sostitutiva (CCT)";
        case "DIGITALBOX":
            return "DigitalBox";
        case "EMAIL-FLOW":
            return "Email";
        case "GDPR-LOG":
            return "General Data Protection Regulation (GDPR)";
        case "NOTESPESE-FLOW":
            return "Note spese";
        case "PEC-FLOW":
            return "PEC";
        case "PEOPLEAPP":
            return "PeopleApp";
        case "POSTEL-FLOW":
            return "POSTEL";
        case "PRIVACY-FLOW":
            return "Agyo privacy";
        case "SDI-FLOW":
            return "SDI";
        case "SIGNATURE-FLOW":
            return "Firma";
        default:
            return serviceName;
    }
}
export function translateConnectionFeatureCode(connectionFeatureCode) {
    switch (connectionFeatureCode) {
        case "SDI":
            return "SDI (Fatturazione)";
        case "ERCV":
            return "eRicevi (Fatturazione)";
        default:
            return connectionFeatureCode;
    }
}
export function translateConnectionPermission(connectionPermission) {
    switch (connectionPermission) {
        case "WRITE":
            return "Scrittura";
        case "READ":
            return "Lettura";
        case "READ_WRITE":
            return "Lettura/Scrittura";
        case "SHARE":
            return "Condiviso";
        default:
            return connectionPermission;
    }
}
export function translateConnectionActive(connectionStatus) {
    let title =
        connectionStatus.active && !connectionStatus.deleted
            ? "Attiva"
            : !connectionStatus.active && !connectionStatus.deleted
            ? "In attesa"
            : "Cancellata";
    let status =
        connectionStatus.active && !connectionStatus.deleted
            ? "success"
            : !connectionStatus.active && !connectionStatus.deleted
            ? "warning"
            : "error";
    return { status, title };
}
export function translateConnectionStatus(connectionStatus) {
    let state = connectionStatus.certificationStatus
        ? connectionStatus.certificationStatus
        : connectionStatus.status
        ? connectionStatus.status
        : connectionStatus;
    let title;
    let status;
    switch (state) {
        //status
        case "VALIDATED":
            title = "Validata";
            status = "warning";
            return { status, title };
        case "PENDING_REQUEST":
            title = "In attesa";
            status = "warning";
            return { status, title };
        case "PENDING_VALIDATION":
            title = "In validazione";
            status = "warning";
            return { status, title };
        case "REQUEST_REJECTED":
            title = "Richiesta rigettata";
            status = "error";
            return { status, title };
        case "VALIDATION_REJECTED":
            title = "Validazione rigettata";
            status = "error";
            return { status, title };
        case "UNVERIFIED":
            title = "Non verificata";
            status = "warning";
            return { status, title };
        //certification_status
        case "AWAITING_APPROVAL":
            title = "Certificazione in attesa approvazione";
            status = "warning";
            return { status, title };
        case "AWAITING_UPLOAD":
            title = "Certificazione in attesa di caricamento";
            status = "warning";
            return { status, title };
        case "CERTIFIED":
            title = "Certificata";
            status = "success";
            return { status, title };
        default:
            title = connectionStatus;
            status = "default";
            return { status, title };
    }
}
export function translateInvoicesTitleAndStatus(invoiceStatus, wasManaged = false) {
    switch (invoiceStatus) {
        case "SCARTATO":
            if (wasManaged) return ["Fattura scartata da SDI", "success"];
            return ["Fattura scartata da AGYO", "error"];
        case "FIRMATO":
            return ["Fattura firmata da AGYO", "success"];
        case "CARICATO":
            return ["Fattura caricata", "success"];
        case "REGISTRATO":
            return ["Fattura registrata", "success"];
        case "CONTROLLATO":
            return ["Fattura verificata con successo", "success"];
        case "ERRORE":
            return ["Errore nell'elaborazione della fattura", "error"];
        case "ACCETTATO":
            return ["Fattura accettata", "success"];
        case "RICEVUTO":
            return ["Fattura ricevuta", "success"];
        case "RIFIUTATO":
            return ["Fattura rifiutata", "error"];
        case "IN_GESTIONE":
            return ["Fattura in gestione a SDI", "success"];
        case "INVIATO":
            return ["Fattura inviata", "success"];
        case "A_DISPOSIZIONE":
            return ["Fattura a disposizone", "success"];
        case "CONDIVISO":
            return ["Fattura condivisa", "success"];
        case "SCARICATO":
            return ["Fattura scaricata", "success"];
        case "NON_RECAPITATO":
            return ["Fatttura non recapitata", "warning"];
        case "NESSUNA_RISPOSTA":
            return ["Nessuna risposta", "success"];
        case "IN_CARICO":
            return ["Operazione in corso", "warning"];
        default:
            return [invoiceStatus, "success"];
    }
}
export function translateInvoiceStatus(invoiceStatus, wasManaged = false) {
    switch (invoiceStatus) {
        case "SCARTATO":
            if (wasManaged) return "Fattura scartata da SDI";
            return "Fattura scartata da AGYO";
        case "FIRMATO":
            return "Fattura firmata da AGYO";
        case "CARICATO":
            return "Fattura caricata";
        case "REGISTRATO":
            return "Fattura registrata";
        case "CONTROLLATO":
            return "Fattura verificata con successo";
        case "ERRORE":
            return "Errore nell'elaborazione della fattura";
        case "ACCETTATO":
            return "Fattura accettata";
        case "RICEVUTO":
            return "Fattura ricevuta";
        case "RIFIUTATO":
            return "Fattura rifiutata";
        case "IN_GESTIONE":
            return "Fattura in gestione a SDI";
        case "INVIATO":
            return "Fattura inviata";
        case "A_DISPOSIZIONE":
            return "Fattura a disposizone";
        case "CONDIVISO":
            return "Fattura condivisa";
        case "SCARICATO":
            return "Fattura scaricata";
        case "NON_RECAPITATO":
            return "Fatttura non recapitata";
        case "NESSUNA_RISPOSTA":
            return "Nessuna risposta";
        default:
            return invoiceStatus;
    }
}
export function translateInvoiceStatusShort(invoiceStatus) {
    switch (invoiceStatus) {
        case "SCARTATO":
            return "Scartato";
        case "INVIATO":
            return "Inviato";
        case "REGISTRATO":
            return "Registrato";
        case "A_DISPOSIZIONE":
            return "A disposizone";
        case "ACCETTATO":
            return "Accettato";
        case "ERRORE":
            return "Errore";
        case "CONTROLLATO":
            return "Controllato";
        case "RICEVUTO":
            return "Ricevuto";
        case "CARICATO":
            return "Caricato";
        case "RIFIUTATO":
            return "Rifiutato";
        case "CONDIVISO":
            return "Condiviso";
        case "SCARICATO":
            return "Scaricato";
        case "NON_RECAPITATO":
            return "Non recapitato";
        case "NESSUNA_RISPOSTA":
            return "Nessuna risposta";
        case "IN_GESTIONE":
            return "In gestione";

        default:
            return invoiceStatus;
    }
}

export function translateInvoiceFlowType(invoiceType) {
    switch (invoiceType) {
        case "SELFINV":
            return "AUTO FATTURA";
        case "SELFSEND":
            return "AUTO INVIO";
        default:
            return invoiceType;
    }
}

export function translateSdiSign(sdiSign) {
    switch (sdiSign) {
        case "NO_SIGN":
            return "Nessuna firma";
        case "TEAMSYSTEM":
            return "Firma TeamSystem";

        default:
            return sdiSign;
    }
}

export function translateValiationError(error) {
    switch (error) {
        case "validation.taxId.person.wrongLength":
            return "Il codice fiscale deve avere lunghezza 16 caratteri";
        case "validation.taxId.emptyOrNull":
            return "Il codice fiscale non può essere vuoto";
        case "validation.taxId.building.wrongLength":
            return "La partita iva di un condominio deve avere lunghezza 11 caratteri";
        case "validation.taxId.building.numbersOnly":
            return "La partita iva di un condominio può contenere solo numeri";
        case "validation.taxId.person.wrongFormat":
            return "Il formato del codice fiscale è errato";
        case "validation.taxId.person.wrongChecksum":
            return "Carattere di controllo non valido";
        case "validation.failed":
            return "Il valore inserito non è valido";
        case "validation.vatNumber.emptyOrNull":
            return "La partita IVA non può essere nulla";
        case "validation.vatNumber.wrongLength":
            return "La partita IVA deve essere di 11 cifre";
        case "validation.vatNumber.wrongFormat":
            return "La partita IVA può contenere solo cifre";
        case "validation.vatNumber.wrongChecksum":
            return "Carattere di controllo non valido";

        default:
            return error;
    }
}

export function translateNotification(notification) {
    switch (notification) {
        case "NEW_MANAGER_LINK_NOTIFICATION_REQUEST":
            return "Richiesta di Connessione";
        case "CONNECTION_REMOVED_NOTIFICATION_REQUEST":
            return "Connessione eliminata";
        case "NEW_MANAGER_LINK_NOTIFICATION_ACCEPT":
            return "Connessione Accettata";
        case "NEW_MANAGER_LINK_NOTIFICATION_REJECT":
            return "Connessione Rifiutata";
        default:
            return notification;
    }
}
export function translateCertifyStatus(certStatus, status) {
    if (certStatus === "AWAITING_UPLOAD" && status === "PENDING_VALIDATION") {
        return "Nuovo file caricato";
    } else if (certStatus === "AWAITING_UPLOAD" && status === "VALIDATION_REJECTED") {
        return "In attesa di nuovo documento";
    } else if (certStatus === "CERTIFIED") {
        return "Certificata";
    } else if (certStatus === null) {
        return "Da certificare";
    }
}
export function translateitemStatus(status) {
    switch (status) {
        case "PENDING":
            return "IN ATTESA";
        case "CERTIFIED":
            return "CERTIFICATO";
        case "REJECTED":
            return "VALIDAZIONE RIGETTATA";
        case "AWAITING_UPLOAD":
            return "CERTIFICAZIONE RIGETTATA";
        case "VALIDATED":
            return "VALIDATO";
        case null:
        case "":
            return "NON CERTIFICATO";
        default:
            return status;
    }
}

export function translateSignatureType(type) {
    switch (type) {
        case "BioSignature":
        case "FGA - BioSignature":
            return <FormattedMessage id={"bio_signature"} />;
        case "SecureCall":
            return <FormattedMessage id={"secure_call"} />;
        case "FSM":
            return <FormattedMessage id={"fsm"} />;
        case "Firma massiva qualificata":
            return <FormattedMessage id={"fmq"} />;
        default:
            return type;
    }
}

export function translateListCctStatus(status) {
    switch (status.statusCode) {
        case "Non_Conservato":
            return (
                <div>
                    <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"not_preserved"} />
                </div>
            );
        case "In_Conservazione":
            return (
                <div>
                    <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"in_storage"} />
                </div>
            );
        case "Errore_Conservazione":
        case "Errore":
            return (
                <div style={{ color: "red" }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"error"} />
                    {": "}
                    <Tooltip title={status.debugDescription}>
                        <i className="fas fa-info-circle" />
                    </Tooltip>
                    <div>{status.errorDescription}</div>
                </div>
            );
        case "Conservato":
            return (
                <div style={{ color: "#09822a" }}>
                    <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"preserved"} />
                </div>
            );

        default:
            return <FontAwesomeIcon icon={faCloudUploadAlt} />;
    }
}

export function translateListStatus(status) {
    switch (status.statusCode) {
        case "Caricato":
            return (
                <div>
                    <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"uploaded"} />
                </div>
            );
        case "Alla_Firma":
            return (
                <div style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faPen} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"to_signature"} />
                </div>
            );
        case "Parzialmente_Firmato":
            return (
                <div style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faPen} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"partially_signed"} />
                </div>
            );
        case "Firmato":
            return (
                <div style={{ color: "#09822a" }}>
                    <FontAwesomeIcon icon={faFileContract} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"signed"} />
                </div>
            );
        case "Elaborazione":
            return (
                <div>
                    <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"in_processing"} />
                </div>
            );
        case "Errore":
            return (
                <div style={{ color: "red" }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"error"} />
                    {": "}
                    <Tooltip title={status.debugDescription}>
                        <i className="fas fa-info-circle" />
                    </Tooltip>
                    <div>{status.errorDescription}</div>
                </div>
            );
        case "Eliminato":
            return (
                <div style={{ color: "red" }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: "8px" }} />
                    <FormattedMessage id={"removed"} />
                </div>
            );

        default:
            return status.statusCode;
    }
}

export const translateCertificationRejectOption = (intl, message) => {
    let result = "OTHER";
    certificationRejectMessages.forEach(element => {
        if (
            `${intl.formatMessage({
                id: `c-certifications.rejectMessage.${element.group}`
            })}: ${intl.formatMessage({
                id: `c-certifications.rejectMessage.${element.group}.${element.type}`
            })}` === message
        ) {
            result = `${element.group}.${element.type}`;
        }
    });
    return result;
};

export function decodeRejectMessage(message) {
    switch (message) {
        case "OTHER":
            return "Altro";
        case "SIGNATURE":
            return "Inserire la firma leggibile del Responsabile della Conservazione";
        case "UPLOAD_PAGES":
            return "Caricare tutte le pagine relative al Responsabile della Conservazione";
        case "NOT_COMPLIANT":
            return "Modulo non conforme (formato richiesto: .pdf max 5 MB )";
        case "NOT_COMPLETE":
            return "Il modulo è incompleto. Manca la compilazione del campo: luogo e data.";
        case "DATE_EMPTY":
            return "Inserire la data nel campo luogo e data.";
        case "PLACE_EMPTY":
            return "Inserire il luogo nel campo luogo e data.";
        case "NOT_CORRECT":
            return "Dati del titolare non corretti o non coerenti. Prego correggere i dati del Responsabile della Conservazione.";
        default:
            return "OTHER";
    }
}
export function translateRejectOption(message) {
    switch (message) {
        case "Il modulo è incompleto. Manca la compilazione del campo: luogo e data.":
            return "NOT_COMPLETE";
        case "Inserire la data nel campo luogo e data.":
            return "DATE_EMPTY";
        case "Inserire il luogo nel campo luogo e data.":
            return "PLACE_EMPTY";
        case "Modulo non conforme (formato richiesto: .pdf max 5 MB )":
            return "NOT_COMPLIANT";
        case "Modulo incompleto: Indicare la denominazione sociale anche manualmente.":
            return "TYPE_EMPTY";
        case "Modulo incompleto: Compilare la sezione del cliente impresa/società in tutte le sue parti.":
            return "COMPANY_NOT_COMPLETE";
        case "Dati errati: La PIVA indicata non coincide con quella registrata in anagrafica. Si invita a ricaricare nuovamente il contratto con i dati corretti.":
            return "PIVA_ERROR";
        case "Modulo incompleto: indicare nome, cognome e carica del firmatario.":
            return "SIGNER_NOT_COMPLETE";
        case "Modulo incompleto: mancano le firme.":
            return "SIGN_NOT_FOUND";
        case 'Compilare la parte relativa al trattamento dei dati personali scegliendo il flag “SI o NO"':
            return "PRIVACY_NOT_COMPLETE";
        case "Inserire la firma leggibile del Responsabile della Conservazione":
            return "SIGNATURE";
        case "Caricare tutte le pagine relative al Responsabile della Conservazione":
            return "UPLOAD_PAGES";
        case "Caricare il modulo di sottoscrizione alla piattaforma TS Digital":
            return "UPLOAD_DOC";
        case "Dati errati: Il Codice Fiscale indicato non coincide con quella registrato in anagrafica. Si invita a ricaricare nuovamente il contratto con i dati corretti.":
            return "ERROR_DATA_CF";
        case "Dati errati: Il Codice Fiscale e la Partita Iva indicati non coincidono con quelli registrati in anagrafica. Si invita a ricaricare nuovamente il contratto con i dati corretti.":
            return "UPLOAD_DATA_CF_PIVA";
        case "Dati del titolare non corretti o non coerenti. Prego correggere i dati del Responsabile della Conservazione.":
            return "NOT_CORRECT";
        case "Allegato 2 assente.":
            return "ABSENT_ATTACHED_2";
        case "Allegato 2 compilato in maniera errata.":
            return "ERROR_ATTACHED_2";
        default:
            return "OTHER";
    }
}

export function getIconClassifier(classifier) {
    switch (classifier) {
        case "COMPANY":
            return "fas fa-industry company-icon";
        case "STUDIO":
            return "fas fa-briefcase company-icon";
        case "BUILDING":
            return "fas fa-building company-icon";
        case "PERSON":
            return "fas fa-user company-icon";
        default:
            return "fas fa-bug company-icon";
    }
}

export function translateUserRoleInGroupId(roles) {
    switch (roles) {
        case "OWNER":
            return "OWNER";
        case "ADMIN":
            return "ADMIN";
        case "MANAGER":
            return "READ_WRITE";
        case "WRITE":
            return "READ_WRITE";
        case "READ":
            return "READ_WRITE";
        default:
            return null;
    }
}

export function translateRolesForPolicyType(policyType) {
    switch (policyType) {
        case "OWNERSHIP":
            return [
                {
                    role: "OWNER",
                    groupId: "OWNER",
                    disabled: false
                },
                {
                    role: "ADMIN",
                    groupId: "ADMIN",
                    disabled: true
                },
                {
                    role: "MANAGER",
                    groupId: "READ_WRITE",
                    disabled: true
                },
                {
                    role: "WRITE",
                    groupId: "READ_WRITE",
                    disabled: true
                },
                {
                    role: "READ",
                    groupId: "READ_WRITE",
                    disabled: true
                }
            ];
        case "CONNECTION":
            return [
                {
                    role: "OWNER",
                    groupId: "OWNER",
                    disabled: true
                },
                {
                    role: "ADMIN",
                    groupId: "ADMIN",
                    disabled: true
                },
                {
                    role: "MANAGER",
                    groupId: "READ_WRITE",
                    disabled: false
                },
                {
                    role: "WRITE",
                    groupId: "READ_WRITE",
                    disabled: false
                },
                {
                    role: "READ",
                    groupId: "READ_WRITE",
                    disabled: false
                }
            ];
        case "ADMINISTRATION":
            return [
                {
                    role: "OWNER",
                    groupId: "OWNER",
                    disabled: true
                },
                {
                    role: "ADMIN",
                    groupId: "ADMIN",
                    disabled: false
                },
                {
                    role: "MANAGER",
                    groupId: "READ_WRITE",
                    disabled: true
                },
                {
                    role: "WRITE",
                    groupId: "READ_WRITE",
                    disabled: true
                },
                {
                    role: "READ",
                    groupId: "READ_WRITE",
                    disabled: true
                }
            ];
        default:
            return null;
    }
}

export function translateFeatureCodeForAppId(appId) {
    switch (appId) {
        case "EIP":
            return [
                {
                    feature_id: "SDI",
                    feature: "SDI",
                    disabled: false
                },
                {
                    feature_id: "ERCV",
                    feature: "eRicevi",
                    disabled: false
                },
                {
                    feature_id: "NSO",
                    feature: "NSO",
                    disabled: false
                },
                {
                    feature_id: "CRT",
                    feature: "Corrispettivi RT",
                    disabled: false
                }
            ];
        case "EBS":
            return [
                {
                    feature_id: "POST",
                    feature: "Dispositive",
                    disabled: false
                },
                {
                    feature_id: "INFO",
                    feature: "Informative",
                    disabled: false
                }
            ];
        case "CRAM":
            return [
                {
                    feature_id: "MODECRAM",
                    feature: "Check Up Impresa",
                    disabled: false
                }
            ];
        case "DBX":
            return [
                {
                    feature_id: "BP",
                    feature: "Digitalbox Business Performance",
                    disabled: false
                },
                {
                    feature_id: "CORE",
                    feature: "DigitalBox",
                    disabled: false
                }
            ];
        case "FISC":
            return [
                {
                    feature_id: "F24",
                    feature: "F24 SaaS",
                    disabled: false
                },
                {
                    feature_id: "CORE",
                    feature: "Fiscali SaaS",
                    disabled: false
                }
            ];
        case "BDG":
            return [
                {
                    feature_id: "CORE",
                    feature: "TS Bilancio",
                    disabled: false
                }
            ];
        case "BIS":
            return [
                {
                    feature_id: "CORE",
                    feature: "Business Information",
                    disabled: false
                }
            ];
        case "NIUMABONUS":
            return [
                {
                    feature_id: "CORE",
                    feature: "TeamSystem Ecobonus",
                    disabled: false
                }
            ];
        case "OCA":
            return [
                {
                    feature_id: "CORE",
                    feature: "One Click Analysis",
                    disabled: false
                }
            ];
        case "RES":
            return [
                {
                    feature_id: "CORE",
                    feature: "Immobili SaaS",
                    disabled: false
                }
            ];
        case "ACCG":
            return [
                {
                    feature_id: "PRECOG",
                    feature: "TS Digital Accounting PreCog",
                    disabled: false
                }
            ];
        case "INSIGHTS":
            return [
                {
                    feature_id: "INSIGHTS",
                    feature: "TS Digital Insights",
                    disabled: false
                }
            ];
        case "NCS":
            return [
                {
                    feature_id: "EMAIL",
                    feature: "Notification Center Email",
                    disabled: false
                }
            ];

        default:
            return null;
    }
}

export const translateNCSAppCode = appCode => {
    switch (appCode) {
        case "TS_DIGITAL_AUTH":
            return "Anagrafica utenti";
        case "digital-box":
            return "TS Digital Box";
        case "TS_DIGITAL_REGISTRY":
            return "Anagrafica aziende";
        case "TS_DIGITAL_METERING":
            return "Metering";
        case "TS_DIGITAL_EIP_ERCV":
            return "TS Digital Invoice - eRicevi";
        case "TS_DIGITAL_EIP_SDI":
            return "TS Digital Invoice - SDI";
        case "TS_DIGITAL_SIG":
            return "TS Digital Signature";
        case "TS_DIGITAL_AGRO_CLOUD":
            return "Telematici Vitivinicoli";
        default:
            return appCode;
    }
};

export const translateUserConsents = type => {
    switch (type) {
        case "CORE_GENERAL_TERMS":
            return "TS Digital Core: Condizioni Generali";
        case "CORE_PRIVACY":
            return "TS Digital Core: Step 1. Informativa Privacy";
        case "CORE_DATA_PROCESSING":
            return "TS Digital Core: Step 2. Perché trattiamo i tuoi dati";
        case "CORE_MARKETING":
            return "TS Digital Core: Step 3. Le tue preferenze";
        case "EIP_MARKETING_TS":
            return "TS Digital Incassa Subito: Marketing TeamSystem";
        case "EIP_MARKETING_WHITE":
            return "TS Digital Incassa Subito: Marketing Whit-e";
        case "EIP_PRIVACY":
            return "TS Digital Incassa Subito: Informativa Privacy";
        default:
            return type;
    }
};

export const translateDeniedFunctionality = type => {
    switch (type) {
        case "uploadInvoices":
            return "Invio e caricamento fatture";
        case "manageActiveInvoices":
            return "Gestione fatture attive";
        case "readActiveInvoices":
            return "Lettura fatture attive";
        case "managePassiveInvoices":
            return "Gestione fatture passive";
        case "readPassiveInvoices":
            return "Lettura fatture passive";

        default:
            return type;
    }
};

export const translatePaymentStatus = status => {
    switch (status) {
        case "PAID":
            return "Pagato";
        case "NOT_PAID":
            return "Non Pagato";
        case "SCHEDULED":
            return "Schedulato";
        case "EXTERNAL_PAID":
            return "Pagato esternamente";

        default:
            return status;
    }
};

export const translatAppName = appName => {
    switch (appName) {
        case "TS000":
            return "DMS";
        case "TS010":
            return "Alyante - Gamma Ent";
        case "TS020":
            return "ACG Ent/3.8";
        case "TS030":
            return "Embyon - Evolus (Metodo)";
        case "TS040":
            return "E/ (E/Impresa - E/Ready)";
        case "TS050":
            return "E/satto";
        case "TS060":
            return "Diamante";
        case "TS070":
            return "Euro09";
        case "TS080":
            return "STR";
        case "TS110":
            return "ViaLibera - LyberaStudio (Console Pratiche)";
        case "TS120":
            return "Teamsystem CAF - Codyce";
        case "TS130":
            return "REVISO";
        case "TS140":
            return "E3";
        case "TS150":
            return "TargetCross/ADMinistrator";
        case "TS160":
            return "AV2000 (gestionale TS per area vinicola)";
        case "TS170":
            return "InforYou";
        case "TS180":
            return "FlipTonic";
        case "TS190":
            return "Evols - Alyante Hospitality";
        case "TS191":
            return "Evols - Nuconga";
        case "TS192":
            return "Evols - Web Hotel";
        case "TS200":
            return "Directory Analyzer";
        case "TS210":
            return "With-e";
        case "TS220":
            return "Telematici Cloud";
        case "TS230":
            return "Ex Prosystem - GIADA";
        case "TS240":
            return "Teamsystem CPM";
        case "TS250":
            return "Teamsystem (CRM Competence Center) CRM EnterpriseCRM in Cloud";
        case "TS251":
            return "Teamsystem CRM (Sviluppo Custom)";
        case "TS260":
            return "Bilancio in Cloud";
        case "TS270":
            return "Teamsystem Ecobonus - NIUMA";
        case "TS350":
            return "Teamsystem HR";
        case "TS400":
            return "Data Lake";
        case "TS500":
            return "Lynfa Azienda";
        case "TS600":
            return "Lynfa Studio - ConTE";
        case "TS700":
            return "Privacy";
        case "TS800":
            return "Area Associazioni";
        case "TS900":
            return "Easylex";
        case "TS910":
            return "Legal System";
        case "FIC":
            return "Fatture in Cloud";
        case "DNA":
            return "EasyFatt Danea";
        case "CNS":
            return "Cassanova";
        case "LEX":
            return "Netlex";
        case "DOM":
            return "Domustudio";
        case "VR010":
            return "DDM solutions";
        case "VR020":
            return "OneSolutions";
        case "VR030":
            return "Pansoft S.r.l.";
        case "VR040":
            return "Concretix Srl";
        case "VR050":
            return "Media Web";
        case "VR060":
            return "Aera S.r.l.";
        case "VR070":
            return "Alteanet";
        case "VR080":
            return "Pentana Solutions";
        case "VR090":
            return "MacroGroup";
        case "VR100":
            return "Alterna";
        case "VR110":
            return "GNS System News Spa";
        case "VR120":
            return "Confartigianato Prato";
        case "VR130":
            return "Prati S.p.a.";
        case "VR140":
            return "ADVENIAS";
        case "VR150":
            return "EDP Futura";
        case "VR160":
            return "Idecon";
        case "VR170":
            return "Sedna";
        case "VR180":
            return "Creso snc";
        case "VR190":
            return "Sara Sistemi";
        case "VR200":
            return "Teknoteam";
        case "VR210":
            return "BitService";
        case "VR220":
            return "Mecmatica";
        case "VR230":
            return "Alterna";
        case "VR231":
            return "Alterna";
        case "VR240":
            return "Beesy";
        case "VR250":
            return "GetYourBill";
        case "VR251":
            return "Innov@ctors srl";
        case "VR260":
            return "Intex System S.r.l.";
        case "VR270":
            return "Photosi";
        case "VR280":
            return "Cosa S.r.l.";
        case "VR300":
            return "Idele";
        case "VR310":
            return "Sinergia BCC";
        case "VR320":
            return "Present S.p.A.";
        case "VR330":
            return "TNX | web • app • design";
        case "VR340":
            return "Editoriale Domus S.p.A.";
        case "VR350":
            return "HERON Software";
        case "VR360":
            return "GAB TAMAGNINI SRL";
        case "VR370":
            return "Vis s.r.l.";
        case "VR380":
            return "TSV";
        case "VR390":
            return "Qlik";
        case "VR400":
            return "BluCloud Srl";
        case "VR410":
            return "Reti S.p.A.";
        case "VR420":
            return "KILIN";
        case "VR430":
            return "Italretail";
        case "VR440":
            return "Eurotec di Nicola Giacovelli";
        case "VR450":
            return "Equalis S.r.l.";
        case "VR460":
            return "Qwince LTD";
        case "VR470":
            return "Netrising";
        case "VR480":
            return "SoftwareEngine Srl";
        case "VR490":
            return "AND EMILI srl";
        case "VR500":
            return "Padosoft";
        case "VR510":
            return "Dominiando Srl";
        case "VR520":
            return "Sediva";
        case "VR530":
            return "Saemicard";
        case "VR540":
            return "MULTICASH SPA";
        case "VR550":
            return "MB Srl";
        case "VR560":
            return "Computer Store";
        case "VR570":
            return "Studiofarma";
        case "VR580":
            return "LEGANCE AVVOCATI ASSOCIATI";
        case "VR590":
            return "GSA";
        case "VR600":
            return "Farmaconsult";
        case "VR610":
            return "Lallemand";
        case "VR620":
            return "Kaitech srl";
        case "VR621":
            return "Kaitech srl";
        case "VR630":
            return "Avalon Consulting di Antonelli F.";
        case "VR640":
            return "Bellachioma";
        case "VR660":
            return "Men & Soft S.r.l.";
        case "VR650":
            return "SDR Consulenze Srl";
        case "VR670":
            return "Storm Open Solutions s.r.l.";
        case "VR680":
            return "Saver Srl";
        case "VR690":
            return "Iride srl";
        case "VR700":
            return "Lobra s.r.l.";
        case "VR710":
            return "Worth Wearing S.r.l. Società Benefit";
        case "VR720":
            return "ESTECO SpA";
        case "VR730":
            return "CMI Solutions S.r.l.";
        case "VR740":
            return "Evolve Srl per conto di Securitisation Services";
        case "VR750":
            return "NemesiX SRL";
        case "VR760":
            return "Sogesi";
        case "VR780":
            return "Silos di Foggia Srl/ Di Gioia Biagio";
        case "VR790":
            return "NO software house";
        case "VR800":
            return "Dipartimento IT della società Sinelco Italiana srl";
        case "VR810":
            return "Info Consulting srl";
        case "VR820":
            return "ErgonoTEC srl";
        case "VR830":
            return "Pharmaservice";
        case "VR840":
            return "Pharmaservice";
        case "VR850":
            return "Infolog S.p.A.";
        case "VR860":
            return "BluJay Solutions SRL.";
        case "VR870":
            return "Multigrafica ADV Srl";
        case "VR880":
            return "Datago s.r.l";
        case "VR890":
            return "CRM INFORMATICA SRL";
        case "VR900":
            return "Suppigorup snc";
        case "VR910":
            return "Top Consult S.r.l.";
        case "VR920":
            return "PA ABS";
        case "VR930":
            return "OMIS SpA";
        case "VR931":
            return "ENGAGE CONSULTING";
        case "VR932":
            return "4WD INFORMATICA s.r.l.";
        case "VR933":
            return "Galileo Progetti s.r.l.";
        case "VR934":
            return "Applicom srl";
        case "VR935":
            return "Boostar s.r.l.s.";
        case "VR936":
            return "UX Business S.r.l.";
        case "VR937":
            return "SicilyByCar S.p.A. ";
        case "VR938":
            return "AD HOC SRL";
        case "VR939":
            return "Fatturazione New School";
        case "VR940":
            return "X3 Solutions Srl";
        case "VR941":
            return "Pharmagest Italia";
        case "VR942":
            return "CALABRIA SHIPPING AGENCY";
        case "VR943":
            return "Diogenet s.r.l.";
        case "VR944":
            return "Starnet snc di Cinetto e Marini";
        case "VR945":
            return "Archeido srl";
        case "VR946":
            return "Meited srl";
        case "VR947":
            return "Optisoft";
        case "VR948":
            return "UBROKER s.r.l.";
        case "VR949":
            return "Above Bits LLC";
        case "VR950":
            return "Studio Bugari di Roberto Bugari & C.";
        case "VR951":
            return "DoveVivo Spa";
        case "VR952":
            return "GN s.r.l.";
        case "VR953":
            return "AG s.r.l.";
        case "VR954":
            return "8 Venti s.r.l.";
        case "VR955":
            return "Enel Global Services srl";
        case "VR956":
            return "Massimo Giambelli";
        case "VR957":
            return "Kluo s.r.l.";
        case "VR958":
            return "BROKER s.r.l.";
        case "VR959":
            return "ITALPOS s.r.l.";
        case "VR960":
            return "SoftwareEngine Srl";
        case "VR961":
            return "SAVE";
        case "VR962":
            return "Tecnikè s.r.l.";
        case "VR963":
            return "Tde s.r.l.";
        case "VR964":
            return "Report s.r.l.";
        case "VR965":
            return "ZetemaDoc";
        case "VR966":
            return "Capgemini";
        case "VR967":
            return "MD NET s.r.l.";
        case "VR968":
            return "Royal Imera s.r.l.";
        case "VR969":
            return "FitActive";
        case "VR970":
            return "ETT s.p.a. società del Gruppo SCAI";
        case "VR971":
            return "Compertis Srl";
        case "VR972":
            return "Hexa";
        case "VR973":
            return "Athenia-net";
        case "VR974":
            return "Horaizon s.r.l.";
        case "VR975":
            return "Area32 s.r.l.";
        case "VR976":
            return "Avanet srl";
        case "VR977":
            return "Valcom's S.p.A.";
        case "VR978":
            return "Flain srl";
        case "VR979":
            return "Ambrogio S.r.l.";
        case "VR980":
            return "TXT-H SPA";
        case "VR981":
            return "InnovaMarine";
        case "VR982":
            return "DATA PROGET SRL";
        case "VR983":
            return "Baasbox srl";
        case "VR984":
            return "Arket Srl";
        case "VR985":
            return "BITECH SOFTWARE & DIGITAL Srl";
        case "VR986":
            return "Niuma";
        case "VR987":
            return "e-Project srl";
        case "VR988":
            return "A2Z WORLD SRL";
        case "VR989":
            return "DotBlue S.r.l.";
        case "VR990":
            return "Teamsystem ERP";
        case "VR991":
            return "Shardana Multimedia srls";
        case "VR992":
            return "Italdata s.r.l.";
        case "VR993":
            return "Geniosoft s.r.l.";
        case "VR994":
            return "Re Mago Ltd - Real Srl";
        case "VR995":
            return "OPACC Software AG, Switzerland";
        case "VR996":
            return "Team Duemila";
        case "VR997":
            return "Konica Minolta";
        case "VR998":
            return "Netlevel s.r.l.";
        case "VR999":
            return "AGRONICA GROUP S.R.L.";
        case "TM001":
            return "Tecmarket Servizi S.p.A.";
        case "AUREO":
            return "EUROINFORMATICA Srl";
        default:
            return appName;
    }
};

export function translateStatusPackage(status) {
    switch (status) {
        case "EXPIRED":
            return <FormattedMessage id="metering_package_id_detail_expired" />;
        case "ACTIVE":
            return <FormattedMessage id="metering_package_id_detail_active" />;
        case "READONLY_EXPIRE_DATE_REACHED":
            return <FormattedMessage id="metering_package_id_detail_expire_date_reached" />;
        case "READONLY_CONSTRAINT_LIMIT_REACHED":
            return <FormattedMessage id="metering_package_id_detail_expire_limit_reached" />;
        case "READONLY_ADMIN_BLOCK":
            return <FormattedMessage id="metering_package_id_detail_expire_administrative_block" />;
        default:
            return status;
    }
}

export function translateProvisionigStatus(event) {
    switch (event) {
        case "INITIALIZED":
            return "Inizializzato";
        case "TOBE_CERTIFIED":
            return "In attesa di certificazione";
        case "REJECTED":
            return "Rigettato";
        default:
            return event;
    }
}
