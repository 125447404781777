import { isNil } from "ramda";
import sha256 from "sha256";

import { USER_WRITE } from "../../../config";
import { postRestCall, deleteRestCall, putRestCall } from "../../../utils/rest-api-call";
import { getAuthWithAppName } from "../../../utils/callWithRetry";

export const REMOVE_ROLES_START = "REMOVE_ROLES_START";
export const REMOVE_ROLES_SUCCESS = "REMOVE_ROLES_SUCCESS";
export const REMOVE_ROLES_ERROR = "REMOVE_ROLES_ERROR";
export const REMOVE_ROLES_RESET = "REMOVE_ROLES_RESET";

export const removeRoles = (auth, userId, roles, toastValues) => async dispatch => {
    const request = {
        userId: userId,
        roles
    };
    dispatch({
        type: REMOVE_ROLES_START,
        payload: request
    });

    const toast = {
        onOk: !isNil(toastValues) && !isNil(toastValues.onOk) ? toastValues.onOk : true,
        onOkText: "Ruoli cancellati correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: request }
    };
    try {
        const result = await deleteRestCall(
            `${USER_WRITE}/roles`,
            auth,
            null,
            request,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: REMOVE_ROLES_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: REMOVE_ROLES_ERROR,
            error: e
        });
    }
};

export const ADD_ROLES_START = "ADD_ROLES_START";
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_ERROR = "ADD_ROLES_ERROR";
export const ADD_ROLES_RESET = "ADD_ROLES_RESET";

export const addRoles = (auth, userId, roles, toastValues) => async dispatch => {
    const request = {
        userId: userId,
        roles
    };
    dispatch({
        type: ADD_ROLES_START,
        payload: request
    });

    const toast = {
        onOk: !isNil(toastValues) && !isNil(toastValues.onOk) ? toastValues.onOk : true,
        onOkText:
            !isNil(toastValues) && !isNil(toastValues.onOkText) ? toastValues.onOkText : "Ruoli aggiunti correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: request }
    };

    try {
        const result = await postRestCall(
            `${USER_WRITE}/roles`,
            auth,
            null,
            request,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: ADD_ROLES_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: ADD_ROLES_ERROR,
            error: e
        });
    }
};

export const ADD_ROLES_WITH_POLICY_START = "ADD_ROLES_START";
export const ADD_ROLES_WITH_POLICY_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_WITH_POLICY_ERROR = "ADD_ROLES_ERROR";
export const ADD_ROLES_WITH_POLICY_RESET = "ADD_ROLES_RESET";

export const addRolesWithPolicy = (auth, userId, roles, toastValues) => async dispatch => {
    const request = {
        userId: userId,
        roles: roles
    };
    dispatch({
        type: ADD_ROLES_WITH_POLICY_START,
        payload: request
    });

    const toast = {
        onOk: !isNil(toastValues) && !isNil(toastValues.onOk) ? toastValues.onOk : true,
        onOkText:
            !isNil(toastValues) && !isNil(toastValues.onOkText) ? toastValues.onOkText : "Ruoli aggiunti correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: request }
    };

    try {
        const result = await postRestCall(
            `${USER_WRITE}/rolesWithPolicy`,
            auth,
            null,
            request,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: ADD_ROLES_WITH_POLICY_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: ADD_ROLES_WITH_POLICY_ERROR,
            error: e
        });
    }
};

export const RESEND_WELCOM_EMAIL_START = "RESEND_WELCOM_EMAIL_START";
export const RESEND_WELCOM_EMAIL_SUCCESS = "RESEND_WELCOM_EMAIL_SUCCESS";
export const RESEND_WELCOM_EMAIL_ERROR = "RESEND_WELCOM_EMAIL_ERROR";
export const RESEND_WELCOM_EMAIL_RESET = " RESEND_WELCOM_EMAIL_RESET";

export const resendWelcomEmail = (auth, id) => async dispatch => {
    const request = {
        emailRecipient: id
    };
    dispatch({
        type: RESEND_WELCOM_EMAIL_START,
        payload: request
    });

    const toast = {
        onOk: true,
        onOkText: "Email inviata correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: request }
    };

    try {
        await postRestCall(
            `${USER_WRITE}/users/${id}/resendWelcomeEmail`,
            auth,
            null,
            request,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: RESEND_WELCOM_EMAIL_SUCCESS
        });
    } catch (e) {
        dispatch({
            type: RESEND_WELCOM_EMAIL_ERROR,
            error: e
        });
    }
};

export const DISABLE_USER_START = "DISABLE_USER_START";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";
export const DISABLE_USER_ERROR = "DISABLE_USER_ERROR";
export const DISABLE_USER_RESET = "DISABLE_USER_RESET";

export const disableUser = (auth, userId) => async dispatch => {
    dispatch({
        type: DISABLE_USER_START,
        payload: userId
    });

    const toast = {
        onOk: true,
        onOkText: "Utenza disattivata correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: userId }
    };
    try {
        const result = await putRestCall(
            `${USER_WRITE}/users/${userId}/disable`,
            auth,
            null,
            null,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: DISABLE_USER_ERROR,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: ENABLE_USER_ERROR,
            error: e
        });
    }
};

export const ENABLE_USER_START = "ENABLE_USER_START";
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";
export const ENABLE_USER_ERROR = "ENABLE_USER_ERROR";
export const ENABLE_USER_RESET = "ENABLE_USER_RESET";

export const enableUser = (auth, userId) => async dispatch => {
    dispatch({
        type: ENABLE_USER_START,
        payload: userId
    });

    const toast = {
        onOk: true,
        onOkText: "Utenza attivata correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: userId }
    };
    try {
        const result = await putRestCall(
            `${USER_WRITE}/users/${userId}/enable`,
            auth,
            null,
            null,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: ENABLE_USER_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: ENABLE_USER_ERROR,
            error: e
        });
    }
};

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_RESET = "CREATE_USER_RESET";

export const createUser = (auth, userData, roles) => async dispatch => {
    const request = {
        user: userData,
        roles: roles
    };
    dispatch({
        type: CREATE_USER_START,
        payload: request
    });
    const toast = {
        onOk: true,
        onOkText: "Utente creato correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: request }
    };
    try {
        const result = await postRestCall(
            `${USER_WRITE}/users`,
            auth,
            null,
            request,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: CREATE_USER_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: CREATE_USER_ERROR,
            error: e
        });
    }
};

export const CREATE_API_KEY_START = "CREATE_API_KEY_START";
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";
export const CREATE_API_KEY_ERROR = "CREATE_API_KEY_ERROR";
export const CREATE_API_KEY_RESET = "CREATE_API_KEY_RESET";

export const createApiKey = (auth, description) => async dispatch => {
    const request = {
        apiKey: {
            description
        },
        skipNotification: true
    };
    dispatch({
        type: CREATE_API_KEY_START,
        payload: request
    });

    const toast = {
        onOk: true,
        onOkText: "Utenza tecnica creata correttamente",
        onError: true,
        infoReq: { authId: auth.id, req: request }
    };
    try {
        const result = await postRestCall(
            `${USER_WRITE}/apiKeys`,
            auth,
            null,
            request,
            dispatch,
            auth.refreshToken,
            toast
        );
        dispatch({
            type: CREATE_API_KEY_SUCCESS,
            payload: result
        });
        return result;
    } catch (e) {
        dispatch({
            type: CREATE_API_KEY_ERROR,
            error: e
        });
    }
};

export const RESET_PWD_START = "RESET_PWD_START";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_ERROR = "RESET_PWD_ERROR";

export const resetPassword = (id, token, password) => async dispatch => {
    dispatch({
        type: RESET_PWD_START
    });

    id = id ? id.trim().toLowerCase() : id;
    const request = {
        sha256UserPassword: sha256(id + password)
    };

    try {
        await putRestCall(`${USER_WRITE}/users/${id}/password/reset/${token}`, null, null, request, dispatch);

        dispatch({
            type: RESET_PWD_SUCCESS
        });
    } catch (e) {
        dispatch({
            type: RESET_PWD_ERROR,
            error: e
        });
    }
};

export const UPDATE_AUTH_PASSWORD_START = "UPDATE_AUTH_PASSWORD_START";
export const UPDATE_AUTH_PASSWORD_SUCCESS = "UPDATE_AUTH_PASSWORD_SUCCESS";
export const UPDATE_AUTH_PASSWORD_ERROR = "UPDATE_AUTH_PASSWORD_ERROR";

export const updateAuthPsw = (auth, userId, psw) => async dispatch => {
    dispatch({
        type: UPDATE_AUTH_PASSWORD_START
    });

    const sha256UserPassword = sha256(userId + psw);
    const request = { sha256UserPassword };
    try {
        await putRestCall(
            `${USER_WRITE}/users/${userId}/password`,
            getAuthWithAppName(auth),
            null,
            request,
            dispatch,
            auth.refreshToken
        );

        dispatch({
            type: UPDATE_AUTH_PASSWORD_SUCCESS
        });
    } catch (e) {
        dispatch({
            type: UPDATE_AUTH_PASSWORD_ERROR,
            error: e
        });
    }
};

export const UPDATE_AUTH_PASSWORD_RESET = "UPDATE_AUTH_PASSWORD_RESET";

export const resetUpdateAuthPassword = () => dispatch => {
    dispatch({
        type: UPDATE_AUTH_PASSWORD_RESET
    });
};

export const RESET_PWD_RESET = "RESET_PWD_RESET";

export const resetResetAuthPassword = () => dispatch => {
    dispatch({
        type: RESET_PWD_RESET
    });
};
