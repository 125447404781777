import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table, Dropdown, Menu, Button, Switch, Tooltip } from "antd";
import { InvoiceFooter } from "../../../../components/general/invoice-footer";
import { bankLinkCbiReason } from "../../../../utils/constant";

export const BankLinkTransactions = ({
    loading,
    paymentTransactionEntityList,
    handleNextPage,
    currentPage,
    hasNextPage,
    handlePreviousPage,
    handlePageSizeChanged,
    defaultPageSize,
    query,
    pageSizeOptions,
    expanded,
    handleExpandedChange,
    handleShowPaymentTransaction
}) => {
    let columns = [
        {
            title: <FormattedMessage id={"source"} />,
            width: "10%",
            dataIndex: "source"
        },
        {
            title: <FormattedMessage id={"amount"} />,
            width: "15%",
            dataIndex: "amount"
        },
        {
            title: <FormattedMessage id={"value_date"} />,
            width: "15%",
            dataIndex: "valueDate"
        },
        {
            title: <FormattedMessage id={"transaction_date"} />,
            width: "15%",
            dataIndex: "transactionDate"
        },
        {
            title: <FormattedMessage id={"iban"} />,
            dataIndex: "iban"
        },
        {
            title: <FormattedMessage id="actions" />,
            key: "actions",
            width: "5%",
            align: "center",
            render: (text, record) => (
                <div>
                    <Dropdown overlay={renderActions(record)} trigger={["click"]} placement="bottomRight">
                        <Button shape="circle" size="small">
                            <i className="fas fa-ellipsis-v" />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];

    const renderActions = record => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleShowPaymentTransaction(record.id)}>
                    <i className="fas fa-info-circle" style={{ marginRight: 8 }} />
                    <FormattedMessage id={"show"} />
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div>
            <div style={{ textAlign: "right", padding: 8 }}>
                <Switch
                    className="notification-switch"
                    checked={expanded}
                    checkedChildren={<FormattedMessage id={"reduce"} />}
                    unCheckedChildren={<FormattedMessage id={"expand"} />}
                    onChange={handleExpandedChange}
                />
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Table
                    rowKey={(record, index) => index}
                    size="middle"
                    columns={columns}
                    locale={{
                        emptyText: <FormattedMessage id={"no_result"} />
                    }}
                    dataSource={paymentTransactionEntityList}
                    loading={loading}
                    expandedRowRender={
                        expanded
                            ? record => {
                                  let cbiReasonFind = record.cbiReason
                                      ? bankLinkCbiReason.find(x => x.value === record.cbiReason)
                                      : null;
                                  return (
                                      <p>
                                          <b>
                                              <FormattedMessage id={"id"} />:
                                          </b>
                                          {` ${record.id ? record.id : "N.D."}`}
                                          <br />
                                          <b>
                                              <FormattedMessage id={"upload_id"} />:
                                          </b>
                                          {` ${record.uploadId ? record.uploadId : "N.D."}`}
                                          <br />
                                          <Tooltip title={cbiReasonFind ? cbiReasonFind.description : null}>
                                              <b>
                                                  <FormattedMessage id={"cbi_causal"} />:
                                              </b>
                                              {` ${record.cbiReason ? record.cbiReason : "N.D."}`}
                                          </Tooltip>
                                          <br />
                                          <b>
                                              <FormattedMessage id={"description"} />:
                                          </b>
                                          {` ${record.description ? record.description : "N.D."}`}
                                          <br />
                                          <b>
                                              <FormattedMessage id={"sia"} />:
                                          </b>
                                          {` ${record.sia ? record.sia : "N.D."}`}
                                          <br />
                                      </p>
                                  );
                              }
                            : null
                    }
                    pagination={false}
                    scroll={{ y: 540 }}
                />
            </div>

            <InvoiceFooter
                invoicedDefaultPageSize={defaultPageSize.toString()}
                disabled={loading}
                handleInvoicePageSizeChanged={handlePageSizeChanged}
                pageSizeOptions={pageSizeOptions}
                invoiceQuery={query}
                pagination={true}
                nextDisabled={!hasNextPage}
                handleNextPage={handleNextPage}
                previousDisabled={currentPage === 1}
                handlePreviousPage={handlePreviousPage}
            />
        </div>
    );
};

BankLinkTransactions.propTypes = {
    loading: PropTypes.bool.isRequired,
    paymentTransactionEntityList: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageSizeChanged: PropTypes.func.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.any.isRequired,
    query: PropTypes.any.isRequired,
    pageSizeOptions: PropTypes.array.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired,
    handleShowPaymentTransaction: PropTypes.func.isRequired
};
