import React from "react";
import PropTypes from "prop-types";
import { Container, PageHeaderTitle, PageHeaderIcon } from "./styled";
import { FormattedMessage } from "react-intl";

const PageHeader = ({ labelId, history, customAction }) => {
    if (customAction) {
        return (
            <Container>
                <PageHeaderTitle onClick={() => customAction()}>
                    <PageHeaderIcon className="fas fa-arrow-left" /> <FormattedMessage id={labelId} />
                </PageHeaderTitle>
            </Container>
        );
    }
    return (
        <Container>
            <PageHeaderTitle onClick={() => history.goBack()}>
                <PageHeaderIcon className="fas fa-arrow-left" /> <FormattedMessage id={labelId} />
            </PageHeaderTitle>
        </Container>
    );
};

PageHeader.propTypes = {
    customAction: PropTypes.func,
    labelId: PropTypes.string.isRequired,
    history: PropTypes.object
};

export default PageHeader;
