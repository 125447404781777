import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Row, Col } from "antd";

import { errorBoundary } from "../../../../components/general/error-boundary";

import { downloadDraftRDC, getInfoRDC } from "../../../../actions/archive/read";
import { rejectRDC, validateRDC } from "../../../../actions/archive/write";
import PageHeader from "../../../../components/general/PageHeader/index";
import { decodeRejectMessage } from "../../../../utils/translate";
import { CertificationsNewArchive } from "../../../../components/certifications/new-archive";
import { findOwnManagerConnections } from "../../../../actions/connections/read";
import { getDocument } from "../../../../actions/documents";
import { ContractWrapper } from "../../styled";

const NewArchiveRdcDetailView = ({
    auth,
    infoRDC,
    validate,
    documentInfo,
    reject,
    documentRDC,
    history,
    downloadDraftRDC,
    getInfoRDC,
    rejectRDC,
    validateRDC,
    getDocument,
    findOwnManagerConnections,
    match: {
        params: { produttoreId, titolareId }
    }
}) => {
    const [disableButton, setDisableButton] = useState(false);
    useEffect(() => {
        const getInformationRDC = async () => {
            await getInfoRDC(auth.loginAuth, produttoreId, titolareId);
        };
        getInformationRDC();
    }, [auth, getInfoRDC, produttoreId, titolareId]);

    useEffect(() => {
        const downloadDocument = async () => {
            if (titolareId !== produttoreId) {
                let filter = {
                    managedIds: titolareId,
                    managerIds: produttoreId,
                    appId: ["EIP", "SIG"],
                    active: true,
                    deleted: false,
                    status: ["VALIDATED"]
                };
                let connResponse = await findOwnManagerConnections(auth.loginAuth, filter, { page: 0, items: 2000 });
                if (!(connResponse && connResponse.content && connResponse.content.length)) {
                    filter = {
                        managedIds: titolareId,
                        managerIds: produttoreId,
                        appId: ["EIP", "SIG"],
                        active: false,
                        deleted: true,
                        status: ["VALIDATED"]
                    };
                    connResponse = await findOwnManagerConnections(auth.loginAuth, filter, { page: 0, items: 2000 });
                }

                if (connResponse && connResponse.content && connResponse.content.length) {
                    let item = connResponse.content[0].connections.find(
                        element => element.status.certificationStatus === "CERTIFIED"
                    );
                    if (item && item.id) {
                        await getDocument(auth.loginAuth, item.id);
                    }
                }
            } else {
                await getDocument(auth.loginAuth, titolareId);
            }
        };
        downloadDocument();
    }, [auth, findOwnManagerConnections, getDocument, produttoreId, titolareId]);

    useEffect(() => {
        const download = async () => {
            await downloadDraftRDC(auth.loginAuth, produttoreId, titolareId);
        };

        download();
    }, [auth, downloadDraftRDC, produttoreId, titolareId]);

    const handleValidateRDC = async () => {
        await validateRDC(auth.loginAuth, produttoreId, titolareId);
        setDisableButton(true);
    };

    const handleRejectRDC = async rejectMessage => {
        await rejectRDC(auth.loginAuth, produttoreId, titolareId, decodeRejectMessage(rejectMessage));
        setDisableButton(true);
    };

    return (
        <ContractWrapper>
            <PageHeader history={history} labelId="c-certifications.validate-cct" />
            <Row gutter={24}>
                <Col span={24}>
                    <CertificationsNewArchive
                        infoRDC={infoRDC}
                        preservation_manager={infoRDC.preservation_manager}
                        loadingBtnValidate={validate.status.started}
                        loadingBtnReject={reject.status.started}
                        documentRDC={documentRDC}
                        documentInfo={documentInfo}
                        companyData={infoRDC.holder}
                        validateRDC={handleValidateRDC}
                        rejectRDC={handleRejectRDC}
                        managerData={infoRDC.submitter}
                        loadingInfoRDC={infoRDC.status.started}
                        disabled={disableButton}
                    />
                </Col>
            </Row>
        </ContractWrapper>
    );
};

NewArchiveRdcDetailView.propTypes = {
    auth: PropTypes.object.isRequired,
    downloadDraftRDC: PropTypes.func.isRequired,
    getInfoRDC: PropTypes.func.isRequired,
    rejectRDC: PropTypes.func.isRequired,
    validateRDC: PropTypes.func.isRequired,
    findOwnManagerConnections: PropTypes.func.isRequired,
    getDocument: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    infoRDC: state.newArchive.readNewArchive.newArchiveInfoRDC,
    validate: state.newArchive.writeNewArchive.validateRDC,
    reject: state.newArchive.writeNewArchive.rejectRDC,
    documentRDC: state.newArchive.readNewArchive.newArchiveDownloadRDC,
    documentInfo: state.company.documents.getDocument
});

const actions = {
    downloadDraftRDC,
    getInfoRDC,
    rejectRDC,
    validateRDC,
    findOwnManagerConnections,
    getDocument
};

const composedHoc = compose(
    connect(
        mapStateToProps,
        actions
    ),
    errorBoundary
);
export default composedHoc(NewArchiveRdcDetailView);
