import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Icon, Button, Input } from "antd";

export const ColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: "8px" }}>
            <FormattedMessage id={"search"} defaultMessage=" ">
                {placeholder => (
                    <Input
                        style={{ marginBottom: "8px", display: "block", width: "250px" }}
                        placeholder={`${placeholder} ${dataIndex}`}
                        value={selectedKeys ? selectedKeys[0] : ""}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                    />
                )}
            </FormattedMessage>
            <Button
                type="primary"
                onClick={() => confirm()}
                icon="search"
                size="small"
                style={{ width: "120px", marginRight: "10px" }}
            >
                <FormattedMessage id="search" />
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: "120px" }}>
                <FormattedMessage id="cancel" />
            </Button>
        </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
});

ColumnSearchProps.propTypes = {
    dataIndex: PropTypes.string.isRequired
};
