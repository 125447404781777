import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import TsModal from "../../../components/general/ts-modal";

export const ShowAPIKeyModal = ({ onCancel, visible, apiKey }) => {
    return (
        <div>
            <TsModal
                onCancel={onCancel}
                title={
                    <span>
                        <FormattedMessage id="api_key" />
                    </span>
                }
                visible={visible}
                cancelTextId="general.close"
                width="40%"
            >
                <div>
                    <p>
                        <FormattedMessage id="technical_utilities_created_correctly" />
                    </p>
                    <span>
                        <FormattedMessage id="id" />: {apiKey ? apiKey.id : <FormattedMessage id="not_available" />}
                    </span>
                    <br />
                    <span>
                        <FormattedMessage id="secret" />:{" "}
                        {apiKey ? apiKey.secret : <FormattedMessage id="not_available" />}
                    </span>
                    <br />
                </div>
            </TsModal>
        </div>
    );
};

ShowAPIKeyModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    apiKey: PropTypes.object
};
