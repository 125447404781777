import styled from "styled-components";

export const CenteredContainer = styled.div`
    text-align: center;
`;

export const ViewTitle = styled.span`
    font-size: 20px;
    color: #005075;
    font-weight: bold;
    text-transform: uppercase;
`;
