import styled from "styled-components";

export const AutoUploadSection = styled.div`
    background-color: white;
`;

export const DownloadButton = styled.div`
    position: absolute;
    right: 27px;
`;

export const PdfWrapper = styled.div`
    border: 1px dashed #0090d1;
    height: 85vh;
    margin: 0px 15px 15px 15px;
`;
